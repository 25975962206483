import React from 'react';
import PropTypes from 'prop-types';

import {Link} from '../link/Link';

import './dashboardFigure.scss';

/**
 * The DashboardFigure component.
 */
export class DashboardFigure extends React.Component {
  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <figure className="dashboard-figure">
        <img className="figure-image" src={this.props.image} alt="" />
        <Link
          to={this.props.url}
          params={this.props.urlParams}
          className="btn btn-secondary btn-block btn-rounded figure-button"
        >{this.props.linkLabel}</Link>
      </figure>
    );
  }
}

DashboardFigure.propTypes = {
  image: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  urlParams: PropTypes.object,
};

export default DashboardFigure;
