import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './searchForm.scss';

/**
 * The SearchForm component.
 */
export class SearchForm extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const disabled = this.props.disabled;
    const classes = {disabled};

    return (
      <form className="search-form" onSubmit={this.props.onSubmit}>
        <i className={classNames('fa fa-search icon-bold search-form-icon', classes)} />
        <input
          type="text"
          role="search"
          name="search-query"
          className="search-form-query"
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          disabled={disabled}
          value={this.props.searchText}
        />
        <input className="search-form-submit" type="submit" />
      </form>
    );
  }
}

SearchForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  searchText: PropTypes.string
};

export default SearchForm;
