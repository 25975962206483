import lodash from 'lodash';
import {action, observable, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import Toggle from 'react-toggle';

import ContentHeader from './components/contentHeader/ContentHeader';
import BrowserAlert from '../../common/browserAlert/BrowserAlert';
import ContentItems from '../../common/contentItems/ContentItems';
import ContentDirectory from '../../common/contentDirectory/ContentDirectory';
import inject from '../../hoc/injectHoc';
import RecoverContentModal from '../../modals/recoverContent/RecoverContentModal';
import {CREATED_CONTENT} from '../../../constants/libraryTypeConstants';

import './contentPage.scss';

/**
 * The library type for this page.
 * @const {number}
 */
const LIBRARY_TYPE = CREATED_CONTENT;

/**
 * The length of time (milliseconds) until a search request is made.
 *
 * @type {number}
 */
const SEARCH_TIMEOUT_LENGTH = 500;

/**
 * The ContentPage component.
 */
export class ContentPage extends React.Component {
  /**
   * Whether or not to only show editable content.
   *
   * @type {boolean}
   */
  @observable onlyShowEditable = false;

  /**
   * The active store key for the content.
   *
   * @type {?{key: string, filters: {}}}
   */
  @observable activeStoreKey = null;

  /**
   * Whether or not the search flow is debouncing.
   *
   * @type {boolean}
   */
  @observable isDebouncing = false;

  /**
   * Calls the content search request using Lodash's debounce.
   * Waits SEARCH_TIMEOUT_LENGTH milliseconds since the last request to fire.
   *
   * @type {function}
   */
  debouncedOnSearchContent = lodash.debounce(action(() => {
    this.loadContents(1);
    this.isDebouncing = false;
  }), SEARCH_TIMEOUT_LENGTH);

  /**
   * Triggered when the component is mounted to the page.
   */
  componentDidMount() {
    const {activeContentStore} = this.props;
    activeContentStore.setContentId(null);

    this.loadContents(1);
  }

  /**
   * Event handler when the search form changes
   *
   * @param {{}} changeEvent
   */
  @action onSearchFormChange = (changeEvent) => {
    const {activeContentStore} = this.props;
    const searchFilter = String(changeEvent.target.value);

    activeContentStore.setContentSearchFilter(LIBRARY_TYPE, searchFilter);

    if (searchFilter) {
      // Clear out the active category while searching.
      activeContentStore.setCategoryId(LIBRARY_TYPE, null);
    }

    this.isDebouncing = true;
    this.debouncedOnSearchContent();
  };

  /**
   * Changes the category.
   *
   * @param {number} newCategoryId
   */
  onChangeCategory = (newCategoryId) => {
    const {activeContentStore} = this.props;

    activeContentStore.setContentSearchFilter(LIBRARY_TYPE, '');
    activeContentStore.setCategoryId(LIBRARY_TYPE, newCategoryId);

    this.loadContents(1);
  };

  /**
   * Updates the content when the library changes.
   */
  @action onChangeLibrary = () => {
    const {activeContentStore} = this.props;

    activeContentStore.setContentSearchFilter(LIBRARY_TYPE, '');
    activeContentStore.setCategoryId(LIBRARY_TYPE, null);

    this.activeStoreKey = null;
  };

  /**
   * Toggle the editableOnly flag when the show editable toggle is switched.
   */
  @action onShowEditableToggle = () => {
    const {activeContentStore} = this.props;

    this.onlyShowEditable = !this.onlyShowEditable;

    activeContentStore.setOnlyShowEditable(this.onlyShowEditable);

    this.loadContents(1);
  };

  /**
   * Refreshes the current content.
   */
  onReloadContent = () => {
    this.loadContents(1, true);
  };

  /**
   * Load selected category's contents.
   *
   * @param {number} page
   * @param {boolean=} forceRefresh
   * @returns {Promise}
   */
  @action loadContents = (page, forceRefresh) => {
    const {activeContentStore, apiContentsStore} = this.props;

    const libraryId = activeContentStore.getLibraryId(LIBRARY_TYPE);
    const safePage = (page) ? Number(page) : 1;

    if (!libraryId) {
      this.activeStoreKey = null;
      return Promise.resolve(null);
    }

    const isSearching = Boolean(activeContentStore.getContentSearchFilter(LIBRARY_TYPE));
    if (isSearching) {
      return this.loadSearchContents(safePage, forceRefresh);
    }

    const categoryId = activeContentStore.getCategoryId(LIBRARY_TYPE);
    if (!categoryId) {
      return Promise.resolve(null);
    }

    const filters = {
      categoryId,
      libraryId,
      editableOnly: this.onlyShowEditable,
      page: safePage,
    };

    let storeKey = null;
    if (forceRefresh) {
      storeKey = apiContentsStore.refreshContents(filters);
    } else {
      storeKey = apiContentsStore.fetchContents(filters);
    }

    activeContentStore.setContentFilters(LIBRARY_TYPE, filters);
    activeContentStore.setContentStoreKey(LIBRARY_TYPE, storeKey);

    this.activeStoreKey = storeKey;

    return apiContentsStore.getPromise(storeKey);
  };

  /**
   * Loads (or refreshes) the search contents.
   *
   * @param {number} page
   * @param {boolean=} forceRefresh
   * @returns {Promise}
   */
  loadSearchContents = (page, forceRefresh) => {
    const {activeContentStore, apiContentSearchStore} = this.props;

    const libraryId = activeContentStore.getLibraryId(LIBRARY_TYPE);
    const searchTerm = activeContentStore.getContentSearchFilter(LIBRARY_TYPE);
    const safePage = (page) ? Number(page) : 1;

    const filters = {
      libraryId,
      searchTerm,
      editableOnly: this.onlyShowEditable,
      page: safePage,
    };
    const nonSearchFilters = {
      libraryId,
      editableOnly: this.onlyShowEditable,
      page: safePage,
    };

    let storeKey = null;
    if (forceRefresh) {
      storeKey = apiContentSearchStore.refreshContents(filters);
    } else {
      storeKey = apiContentSearchStore.fetchContents(filters);
    }

    activeContentStore.setContentFilters(LIBRARY_TYPE, nonSearchFilters);
    activeContentStore.setContentStoreKey(LIBRARY_TYPE, storeKey);

    runInAction('setActiveContentStoreKey', () => {
      this.activeStoreKey = storeKey;
    });

    return apiContentSearchStore.getPromise(storeKey);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      activeContentStore,
      apiCategoriesStore,
      apiContentSearchStore,
      apiContentsStore,
      apiProductsStore,
    } = this.props;

    const activeContentLibrary = activeContentStore.libraries[LIBRARY_TYPE];
    const activeCategory = activeContentLibrary.category;
    const activeContent = activeContentLibrary.content;
    const contentSearchText = activeContent.searchFilter;

    const category = apiCategoriesStore.getCategory(
      activeCategory.id,
      activeContentLibrary.id
    );
    const product = apiProductsStore.getActiveProduct() || {};

    const searchTerm = activeContentStore.getContentSearchFilter(LIBRARY_TYPE);
    const isSearching = Boolean(searchTerm);

    const contentStore = (isSearching) ? apiContentSearchStore : apiContentsStore;

    const storeKey = activeContentStore.getContentStoreKey(LIBRARY_TYPE) || this.activeStoreKey;

    return (
      <div id="content-page">
        <ContentHeader
          onReloadContent={this.onReloadContent}
          onShowEditableToggle={this.onShowEditableToggle}
          onSearchFormChange={this.onSearchFormChange}
          searchFormText={contentSearchText}
          onlyShowEditable={this.onlyShowEditable}
        />

        <div className="page-content">
          <aside className="page-sidebar">
            <ContentDirectory
              activeContentStore={activeContentStore}
              allowCategoryEdit={true}
              libraryType={LIBRARY_TYPE}
              onLibrarySelected={(libraryId) => this.onChangeLibrary(libraryId)}
              onCategorySelected={(categoryId) => this.onChangeCategory(categoryId)}
            />
          </aside>

          <div className="page-main container-fluid">
            <div className="page-heading">
              <div className="h4 heading-title">
                {(category) && (
                  <span>{category.categoryName}</span>
                )}

                {(isSearching) && (
                  <span>Searching For &quot;{searchTerm}&quot;</span>
                )}
              </div>

              <div className="heading-actions">
                <div className="action-control-group">
                  <button
                    type="button"
                    className="refresh-button w24-refresh-button fa fa-refresh"
                    onClick={this.onReloadContent}
                  />
                </div>
                <div className="action-control-group">
                  <Toggle
                    id="show-editable-check"
                    checked={this.onlyShowEditable}
                    onChange={this.onShowEditableToggle}
                    icons={false}
                  />
                  <label htmlFor="show-editable-check" className="toggle-label">Hide Non-Editable</label>
                </div>
              </div>
            </div>

            <ContentItems
              contentSize={{width: product.widthPx, height: product.heightPx}}
              contentStore={contentStore}
              isLoading={this.isDebouncing}
              libraryType={LIBRARY_TYPE}
              onLoadNextPage={this.loadContents}
              storeKey={storeKey}
            />
          </div>
        </div>

        <RecoverContentModal />
        <BrowserAlert />
      </div>
    );
  }
}

ContentPage.propTypes = {
  activeContentStore: MobxPropTypes.observableObject,
  apiCategoriesStore: MobxPropTypes.observableObject,
  apiContentSearchStore: MobxPropTypes.observableObject,
  apiContentsStore: MobxPropTypes.observableObject,
  apiProductsStore: MobxPropTypes.observableObject,
};

ContentPage.wrappedComponent = {};
ContentPage.wrappedComponent.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
  apiCategoriesStore: MobxPropTypes.observableObject.isRequired,
  apiContentSearchStore: MobxPropTypes.observableObject.isRequired,
  apiContentsStore: MobxPropTypes.observableObject.isRequired,
  apiProductsStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ContentPage)(
  observer(ContentPage)
);
