import {action} from 'mobx';

import SingleResourceBaseStore from './common/apiSingleResourceBaseStore';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN} from '../../constants/storeConstants';
import serverRequest, {cleanResponse} from '../../utils/serverRequest';
import {replaceText} from '../../utils/textReplacer';

/**
 * The feedFromCustom store.
 */
class FeedFromCustomStore extends SingleResourceBaseStore {
  /**
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Fetches feed data from a custom URL.
   *
   * @param {string} customFeedUrl
   */
  @action fetch(customFeedUrl) {
    const safeUrl = String(customFeedUrl);
    const safeId = safeUrl;
    const replacedUrl = replaceText(safeUrl);

    if (this.isResourceAvailable(safeId)) {
      return;
    }

    this.resourcesById.set(safeId, {
      state: STATE_PENDING,
      resource: null,
      error: null,
    });
    this.state = STATE_PENDING;

    serverRequest.request({
      url: replacedUrl,
      method: 'get',
      auth: false,
      withCredentials: false,
      skipCleaning: true,
      skipLogout: true,
      maxContentLength: 2000,
      responseType: 'json', // 'arraybuffer', 'json', 'text'
    }).then(
      action('getCustomFeedsSuccess', (feedData) => {
        this.resourcesById.set(safeId, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          resource: this.cleanFeedData(feedData),
          error: null,
        });
        this.state = STATE_FULFILLED;
      }),
      action('getCustomFeedsError', (error) => {
        this.resourcesById.set(safeId, {
          state: STATE_REJECTED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          resource: null,
          error,
        });
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Clean up the feed data.
   *
   * @param {Object|Array|string} feedData
   * @returns {Object|Array|string}
   */
  cleanFeedData(feedData) {
    if (!feedData) {
      return {};
    } else if (typeof feedData !== 'string') {
      return feedData;
    }

    const safeFeedData = feedData.trim();

    try {
      return cleanResponse(
        JSON.parse(safeFeedData)
      );
    } catch (parseError) {
      if (safeFeedData[0] === '{' || safeFeedData[0] === '[') {
        // The string should have parsed, but failed, so it is bad JSON.
        return {};
      }

      // The string could not be parsed as JSON, but maybe it was just a string in the first place.
    }

    return safeFeedData;
  }
}

export default new FeedFromCustomStore();
