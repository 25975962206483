import lodash from 'lodash';
import {extendObservable, isObservable, toJS} from 'mobx';

import {apiStore} from '../../../utils/apiStore';

/**
 * Base class for sortable/filterable stores
 *
 * To Use:  This class should be used as a base class.
 *  1) In derived Store's constructor:
 *    call 'super()' with an array of required filter property names & the name of the property to sort by:
 *    e.g.:  super( [ 'libraryId', 'userId' ], 'name' )
 *  2) In the Store's 'fetch' function you man use the 'storeKey' function to generate a cache-key from the passed in
 *     filters & (probably) return this key from the Store's 'fetch' function.
 *  3) In the extended Store's 'getFulfilled' method, use the 'filterAndSort' function to filter/sort the
 *    fulfilled data.
 */
class SortableFilterableStore {
  /**
   * @constructor
   * @param {string[]} requiredFilters - Filters required for this store to fetch its data.
   * @param {string} sortProperty - The property by which to sort the Store's items.
   */
  constructor(requiredFilters, sortProperty) {
    extendObservable(this, apiStore);
    this.requireFilters(requiredFilters || []);
    this.sortBy = sortProperty || null;
  }

  /**
   * Property used to sort Store's items
   *
   * @type {string}
   */
  sortBy = null;

  /**
   * The required filters (using an Object for easy contains checking).
   *
   * @type {{}}
   */
  requiredFilters = {};

  /**
   * Valid filter.method values.
   * 'contains' - does a case-insensitive 'contains' check.  Currently only works on strings.
   */
  validFilterMethods = ['contains'];

  /**
   * Validator for filters.  Checks passed in filters object against the required filters.
   *
   * filters should look like either:
   * {
   *   propertyName: value
   * }
   * (where 'propertyName' is the name of a property to filter on, and value is the exact value to match against)
   * OR
   * {
   *  propertyName : {
   *    method: 'contains',  // must be a value in validFilterMethods
   *    value: value
   *  }
   * }
   *
   * @param {{}} filters
   */
  validateFilters = (filters) => {
    const safeFilters = this.observableToJS(filters);

    // validate basic filter structure
    lodash.forEach(safeFilters, (prop) => {
      if (!lodash.isObject(safeFilters[prop])) {
        return;
      }

      if (!safeFilters[prop].method) {
        throw new Error(`"${prop}" must have a valid "method" property!`);
      }

      const method = safeFilters[prop].method;
      if (!lodash.includes(this.validFilterMethods, method)) {
        const filterMethodText = this.validFilterMethods.join(',');
        throw new Error(`filter method "${method}" is not valid. Valid methods are: ${filterMethodText}`);
      }
    });

    // validate required filters
    const requiredFilters = this.requiredFilters;

    lodash.forEach(requiredFilters, (value, filterName) => {
      if (!(filterName in safeFilters)) {
        throw new Error(`"${filterName}" is a required filter in ${this.constructor.name}!`);
      }
    });
  };

  /**
   * Checks that the filters are ok.
   *
   * @param {{}} givenFilters
   * @param {string[]} validFilterNames
   * @param {string[]} requiredFilterNames
   * @param {boolean} allowFalsey
   */
  checkFilters = (givenFilters, validFilterNames, requiredFilterNames, allowFalsey) => {
    if (!givenFilters) {
      return;
    }

    const safeFilters = this.observableToJS(givenFilters);
    const safeRequiredNames = requiredFilterNames || [];
    const safeFilterNames = [...(validFilterNames || []), ...safeRequiredNames];

    lodash.forEach(safeFilters, (filterValue, filterName) => {
      if (safeFilterNames.indexOf(filterName) === -1) {
        throw new Error(`Invalid filter value given; '${filterName}' is not a valid filter.`);
      }
    });

    lodash.forEach(safeRequiredNames, (requiredName) => {
      if (safeFilters[requiredName]) {
        return;
      }
      if (!allowFalsey) {
        throw new Error(`Required filter '${requiredName}' was falsey.`);
      }
      if (safeFilters[requiredName] === undefined) {
        throw new Error(`Required filter '${requiredName}' was not given.`);
      }
    });
  };

  /**
   * Filters and sorts the items.
   *
   * @param {Array<{}>} items
   * @param {{}} filters
   * @param {string=} sortByName
   * @returns {Array<{}>}
   */
  filterAndSort = (items, filters, sortByName) => {
    if (!items) {
      return [];
    }
    const safeFilters = this.observableToJS(filters);
    const filteredItems = items.filter(this.getFilterItemsFunction(safeFilters));

    if (sortByName) {
      return lodash.sortBy(filteredItems, (item) => item[sortByName]);
    }

    return lodash.sortBy( filteredItems, this.sortItems );
  };

  /**
   * Adds passed in filter names to the 'requiredFilters' object.
   *
   * @param {string[]} requiredFilters
   */
  requireFilters = (requiredFilters) => {
    requiredFilters.forEach((filterName) => {
      this.requiredFilters[filterName] = true;
    });
  }

  /**
   * Creates an object with a  '_' separated string from all sorted filter values.  For use as a Store cache key.
   *
   * @param {{}} filters
   * @returns {{key: string, filters: {}}}
   */
  getStoreKey = (filters) => {
    const safeFilters = this.observableToJS(filters);
    if (!lodash.isPlainObject(safeFilters)) {
      throw new Error('Invalid filter given to getStoreKey. Filters must be a plain javascript object.');
    }

    const sortedRequiredFilterNames = lodash.sortBy(lodash.keys(safeFilters), (x) => x);
    const sortedValues = sortedRequiredFilterNames.map((key) => `${key}-${safeFilters[key]}`);
    return {key: sortedValues.join('_'), filters: safeFilters};
  };

  /**
   * Parses a key string into its filters.
   *
   * @param {string} key
   * @returns {{}}
   */
  parseKeyToFilters = (key) => {
    if (!key) {
      return {};
    }

    const keyFilters = {};
    key.split('_').forEach((keyPart) => {
      const keyPieces = keyPart.split('-');
      const keyName = keyPieces[0];

      let keyValues = keyPieces[1];
      if (keyValues.indexOf(',') !== -1) {
        keyValues = keyValues.split(',');
      } else if (keyValues === 'true') {
        keyValues = true;
      } else if (keyValues === 'false') {
        keyValues = false;
      } else if (Number(keyValues) === parseInt(keyValues, 10)) {
        keyValues = Number(keyValues);
      }

      keyFilters[keyName] = keyValues;
    });

    return keyFilters;
  };

  /**
   * Function used to filter an Array of Store items (used in the filterAndSort function).
   *
   * @param {{}} filters
   * @returns {boolean}
   */
  getFilterItemsFunction = (filters) => {
    const safeFilters = this.observableToJS(filters);

    return (item) => {
      return lodash.every(safeFilters, (value, filterName) => {
        if (!(filterName in item)) {
          return true;
        }
        if (Array.isArray(value)) {
          return lodash.includes(value, item[filterName]);
        }

        if (lodash.isObject(value)) {
          switch (value.method) {
            case 'contains':
              return lodash.includes(String(item[filterName]).toLowerCase(), String(value.value).toLowerCase());
            default: // shouldn't ever happen due to filter validation...
              return true;
          }
        } else {
          return (String(item[filterName]) === String(value));
        }
      });
    };
  };

  /**
   * Changes an observable into a normal JS object.
   *
   * @param {*} item
   * @returns {*}
   */
  observableToJS = (item) => {
    if (!isObservable(item)) {
      return item;
    }
    return toJS(item);
  };

  /**
   * Function used to sort an Array of Store items (used in filterAndSort function).
   *
   * @param {{}} item
   * @returns {*}
   */
  sortItems = (item) => item[this.sortBy];
}

export default SortableFilterableStore;
