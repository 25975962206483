/**
 * The action aspect ratio component.
 *
 * @param {string} componentName
 * @param {string} newAspectRatio
 * @returns {{actionAspectRatio: {updatedComponents: Object.<string, {}>}}}
 */
export function actionAspectRatioComponent(componentName, newAspectRatio) {
  return {
    actionAspectRatio: {
      componentName,
      aspectRatio: newAspectRatio,
    },
  };
}
