import {
  COLOR_PREFIX,
  FONT_SIZE_PREFIX,
  LINE_HEIGHT_PREFIX,
  LETTER_SPACING_PREFIX,
  customStylesMap,
} from '../constants/styleContants';

/**
 * The color value (from #f0f0f0) when to invert.
 * @const {number}
 */
const DECIMAL_INVERT_GRAY = 720;

/**
 * The factor to convert hexadecimal numbers.
 * @const {number}
 */
const HEX_TO_DEC = 16;

/**
 * Gets all the currently active styles with the prefix.
 *
 * @param {{get: function}} editorState
 * @param {string} prefix
 * @returns {Object.<string, boolean>}
 */
export function getAllActiveStylesWithPrefix(editorState, prefix) {
  let activeStyles = {};
  editorState.getCurrentContent().get('blockMap').toArray().forEach((contentBlock) => {
    contentBlock.get('characterList').toArray().forEach((charItem) => {
      charItem.get('style').toArray().forEach((activeStyle) => {
        if (activeStyle.substr(0, prefix.length) === prefix) {
          activeStyles[activeStyle] = true;
        }
      });
    });
  });
  return activeStyles;
}

/**
 * Converts a color value (0-255) to a hexadecimal value (00-ff).
 *
 * @param {number} c
 * @returns {string}
 */
export function colorValueToHex(c) {
  const hex = Number(c).toString(HEX_TO_DEC);
  return (hex.length === 1) ? `0${hex}` : hex;
}

/**
 * Parses an rgb to hex.
 *
 * @param {number} r
 * @param {number} g
 * @param {number} b
 * @returns {string}
 */
export function rgbToHex(r, g, b) {
  return '#' + colorValueToHex(r) + colorValueToHex(g) + colorValueToHex(b);
}

/**
 * Turns a hexadecimal string into a summation value.
 *
 * @param {string} hex
 * @returns {number}
 */
export function hexToAddedValues(hex) {
  const red = parseInt(hex.substr(0, 2), HEX_TO_DEC);
  const green = parseInt(hex.substr(2, 2), HEX_TO_DEC);
  const blue = parseInt(hex.substr(4, 2), HEX_TO_DEC); // eslint-disable-line no-magic-numbers

  return (red + green + blue);
}

/**
 * Adds a new color to the custom styles map.
 *
 * @param {string} styleName
 * @param {string} color
 */
export function addColorToStyleMap(styleName, color) {
  if (customStylesMap[styleName]) {
    return;
  }

  let safeColor = String(color);
  if (safeColor[0] !== '#') {
    safeColor = '#' + safeColor;
  }

  const colorValue = hexToAddedValues(safeColor.substr(1));
  if (colorValue >= DECIMAL_INVERT_GRAY) {
    safeColor = '#D8D8D8';
  }

  customStylesMap[styleName] = {
    color: safeColor,
  };
}

/**
 * Adds a new font size to the custom styles map.
 *
 * @param {string} styleName
 * @param {number} fontSize
 */
export function addFontSizeToStyleMap(styleName, fontSize) {
  if (customStylesMap[styleName]) {
    return;
  }

  customStylesMap[styleName] = {
    fontSize: `${fontSize}px`,
  };
}

/**
 * Adds a new line height to the custom styles map.
 *
 * @param {string} styleName
 * @param {number} lineHeight
 */
export function addLineHeightToStyleMap(styleName, lineHeight) {
  if (customStylesMap[styleName]) {
    return;
  }

  customStylesMap[styleName] = {
    lineHeight: `${lineHeight}`,
  };
}

/**
 * Adds a new letter spacing to the custom styles map.
 *
 * @param {string} styleName
 * @param {number} letterSpacing
 */
export function addLetterSpacingToStyleMap(styleName, letterSpacing) {
  if (customStylesMap[styleName]) {
    return;
  }

  customStylesMap[styleName] = {
    letterSpacing: `${letterSpacing}px`,
  };
}

/**
 * Removes the style from the color map.
 *
 * @param {string} styleName
 */
export function removeFromStyleMap(styleName) {
  if (!customStylesMap[styleName]) {
    return;
  }

  const isColor = (styleName.substr(0, COLOR_PREFIX.length) === COLOR_PREFIX);
  const isFontSize = (styleName.substr(0, FONT_SIZE_PREFIX.length) === FONT_SIZE_PREFIX);
  const isLineHeight = (styleName.substr(0, LINE_HEIGHT_PREFIX.length) === LINE_HEIGHT_PREFIX);
  const isLetterSpacing = (styleName.substr(0, LETTER_SPACING_PREFIX.length) === LETTER_SPACING_PREFIX);
  if (!isColor && !isFontSize && !isLineHeight && !isLetterSpacing) {
    return;
  }

  delete customStylesMap[styleName];
}
