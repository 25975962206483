import {ContentState, Editor, EditorState, Modifier} from 'draft-js';
import keyCommandPlainBackspace from 'draft-js/lib/keyCommandPlainBackspace';
import keyCommandBackspaceWord from 'draft-js/lib/keyCommandBackspaceWord';
import keyCommandBackspaceToStartOfLine from 'draft-js/lib/keyCommandBackspaceToStartOfLine';
import keyCommandPlainDelete from 'draft-js/lib/keyCommandPlainDelete';
import keyCommandDeleteWord from 'draft-js/lib/keyCommandDeleteWord';
import emojiRegex from 'emoji-regex';
import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import AlignButtons from './components/alignButtons/AlignButtons';
import ColorSelect from './components/colorSelect/ColorSelect';
import FontSelect from './components/fontSelect/FontSelect';
import FontSizeSelect from './components/fontSizeSelect/FontSizeSelect';
import LetterSpacingSelect from './components/letterSpacingSelect/LetterSpacingSelect';
import StyleButtons from './components/styleButtons/StyleButtons';
import VerticalAlignButtons from './components/verticalAlignButtons/VerticalAlignButtons';
import {customStylesMap, styleToClassMap} from './constants/styleContants';
import {stateToMarkdown} from './utils/stateToMarkdown';
import {stateFromHtml} from './utils/stateFromHtml';
import EditTimelineControls from '../editTimeline/EditTimelineControls';
import {actionUpdateComponent} from '../../../display/components/action/actionUpdateComponent';
import {textComponent, parseMarkdownToHtml} from '../../../display/components/type/textComponent';

import 'draft-js/dist/Draft.css';
import './editTextControls.scss';

/**
 * The EditTextControls component.
 */
export class EditTextControls extends React.Component {
  /**
   * The rich text editor state.
   *
   * @type {?EditorState}
   */
  @observable textState = null;

  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);

    let text = {};
    if (this.props.entity && this.props.entity.has('text')) {
      text = this.props.entity.get('text');
    }

    this.buildTextState(text);
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.entity && this.props.entity && prevProps.entity.get('id') === this.props.entity.get('id')) {
      return;
    }

    let text = {};
    if (this.props.entity && this.props.entity.has('text')) {
      text = this.props.entity.get('text');
    }

    this.buildTextState(text);
  }

  /**
   * Builds a new text state.
   *
   * @param {{isHtml: boolean, value: string, rawValue: string}} text
   */
  @action buildTextState = (text) => {
    let startingContent = null;
    if (text.isHtml) {
      const startHtml = String(text.value || '');
      startingContent = stateFromHtml(startHtml);
    } else {
      startingContent = ContentState.createFromText(text.value || '');
    }

    this.textState = EditorState.createWithContent(startingContent);

    this.selectAll();
  };

  /**
   * Selects all text in the text editor.
   *
   * @returns {EditorState}
   */
  @action selectAll = () => {
    const currentSelection = this.textState.getSelection();
    const currentContent = this.textState.getCurrentContent();

    const firstBlock = currentContent.getFirstBlock();
    const lastBlock = currentContent.getLastBlock();
    const newSelection = currentSelection.merge({
      anchorKey: firstBlock.get('key'),
      anchorOffset: 0,
      focusKey: lastBlock.get('key'),
      focusOffset: lastBlock.get('text').length,
      hasFocus: true,
    });

    let newEditorState = EditorState.acceptSelection(this.textState, newSelection);
    newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());
    this.textState = newEditorState;

    return this.textState;
  };

  /**
   * Whether or not the editor has any text selected.
   *
   * @returns {boolean}
   */
  @action hasSelection = () => {
    const currentSelection = this.textState.getSelection();

    if (!currentSelection) {
      return false;
    }

    if (currentSelection.get('anchorKey') !== currentSelection.get('focusKey')) {
      return true;
    } else if (currentSelection.get('anchorOffset') !== currentSelection.get('focusOffset')) {
      return true;
    }

    return false;
  };

  /**
   * Forces focus on the currently selected item.
   *
   * @returns {EditorState}
   */
  @action forceSelectionFocus = () => {
    const currentSelection = this.textState.getSelection();

    if (!currentSelection) {
      return this.textState;
    } else if (currentSelection.get('hasFocus')) {
      return this.textState;
    }

    const newSelection = currentSelection.merge({
      hasFocus: true,
    });

    let newEditorState = EditorState.acceptSelection(this.textState, newSelection);
    newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());
    this.textState = newEditorState;

    return this.textState;
  };

  /**
   * Selects all the text if there is no selection.
   *
   * @param {boolean=} doNotForce
   * @returns {EditorState}
   */
  selectAllIfNoSelection = (doNotForce) => {
    if (!this.hasSelection()) {
      this.selectAll();
    } else if (!doNotForce) {
      this.forceSelectionFocus();
    }
    return this.textState;
  };

  /**
   * Updates the text state when it is changed.
   *
   * @param {EditorState} editorState
   */
  @action onChangeTextState = (editorState) => {
    const {entity, game} = this.props;
    this.textState = editorState;

    const newValue = stateToMarkdown(editorState);

    const currentText = entity.get('text');

    const actionParams = {
      entityId: entity.get('id'),
    };
    game.addAction(actionParams, actionUpdateComponent(
      textComponent({
        value: parseMarkdownToHtml(newValue),
        isHtml: true,
        rawValue: newValue,
      }, currentText))
    );
  };

  /**
   * Triggered when a block style changes.
   *
   * @param {{}} contentBlock
   * @returns {?string}
   */
  onChangeBlockStyle = (contentBlock) => {
    const type = contentBlock.getType();

    let newClassName = null;
    lodash.forEach(styleToClassMap, (className, style) => {
      if (type === style) {
        newClassName = className;
        return false;
      }
      return true;
    });

    return newClassName;
  };

  /**
   * Performs a delete/backspace key command while preserving inline styles.
   * This is to be used with handleKeyCommand().
   *
   * @param {{}} editorState
   * @param {function} commandFunction
   * @returns {string}
   */
  @action deleteAndPreserveStyle = (editorState, commandFunction) => {
    const currentStyle = editorState.getCurrentInlineStyle();

    // commandFunction needs to be the same logic the editor would have done (one of 'draft-js/lib/keyCommand*').
    const newEditorState = commandFunction(editorState);

    // Set the editorState to add the inline styles back in upon next insertion.
    // This prevents the styles from being erased when the text box is emptied.
    this.textState = EditorState.setInlineStyleOverride(newEditorState, currentStyle);

    // Tells the function to do nothing, everything was handled here.
    return 'handled';
  };

  /**
   * Catches characters being typed and is used to prevent emoji.
   *
   * @param {string} chars
   * @returns {string}
   */
  handleBeforeInput = (chars) => {
    const regex = emojiRegex();
    const match = regex.exec(chars);
    if (match) {
      return 'handled';
    }
    return 'not-handled';
  };

  /**
   * Catches key commands from the editor allowing custom flows.
   * Looks for backspace/delete and forces inline styles to be preserved after removing character/word/line.
   *
   * @param {string} command
   * @param {{}} editorState
   * @returns {string}
   */
  handleKeyCommand = (command, editorState) => {
    if (command === 'backspace') {
      return this.deleteAndPreserveStyle(editorState, keyCommandPlainBackspace);
    } else if (command === 'backspace-word') {
      return this.deleteAndPreserveStyle(editorState, keyCommandBackspaceWord);
    } else if (command === 'backspace-to-start-of-line') {
      return this.deleteAndPreserveStyle(editorState, keyCommandBackspaceToStartOfLine);
    } else if (command === 'delete') {
      return this.deleteAndPreserveStyle(editorState, keyCommandPlainDelete);
    } else if (command === 'delete-word') {
      return this.deleteAndPreserveStyle(editorState, keyCommandDeleteWord);
    }

    return 'not-handled';
  };

  /**
   * Catches pasted text to strip emoji.
   *
   * @param {string} text
   * @param {string} html
   * @param {EditorState} editorState
   * @returns {boolean}
   */
  handlePastedText = (text, html, editorState) => {
    const regex = emojiRegex();
    const newContent = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text.replace(regex, ''),
      editorState.getCurrentInlineStyle()
    );
    this.textState = EditorState.push(editorState, newContent, 'remove-range');
    this.onChangeTextState(this.textState);
    return true;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;

    return (
      <div className="edit-text-controls">
        <div className="text-group">
          <h5 className="sidebar-title">Text</h5>
          <div className="RichEditor-root">
            <div className="RichEditor-editor text-control-editor">
              <Editor
                blockStyleFn={this.onChangeBlockStyle}
                customStyleMap={customStylesMap}
                editorState={this.textState}
                handleKeyCommand={this.handleKeyCommand}
                handleBeforeInput={this.handleBeforeInput}
                handlePastedText={this.handlePastedText}
                onChange={this.onChangeTextState}
                spellCheck={true}
                stripPastedStyles={true}
              />
            </div>
          </div>
        </div>
        <div className="text-style control-group">
          <div className="group-header">
            <span className="group-header-label">Style</span>
          </div>
          <div className="group-controls">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="font-selector">Typeface</label>
                  <div className="type-color-controls">
                    <FontSelect
                      editorState={this.textState}
                      onChangeTextState={this.onChangeTextState}
                      beforeChange={this.selectAllIfNoSelection}
                    />
                    <ColorSelect
                      editorState={this.textState}
                      onChangeTextState={this.onChangeTextState}
                      beforeChange={this.selectAllIfNoSelection}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="font-size-selector">Size</label>
                  <FontSizeSelect
                    editorState={this.textState}
                    onChangeTextState={this.onChangeTextState}
                    beforeChange={this.selectAll}
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="form-group">
                  <div><label>Setting</label></div>
                  <StyleButtons
                    editorState={this.textState}
                    onChangeTextState={this.onChangeTextState}
                    beforeChange={this.selectAllIfNoSelection}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10">
                <div className="form-group">
                  <div><label>Alignment</label></div>
                  <AlignButtons
                    editorState={this.textState}
                    onChangeTextState={this.onChangeTextState}
                    beforeChange={this.forceSelectionFocus}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="form-group">
                  <div><label>Vertical Alignment</label></div>
                  <VerticalAlignButtons
                    entity={entity}
                    game={game}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="letter-spacing-selector">Spacing</label>
                  <LetterSpacingSelect
                    editorState={this.textState}
                    onChangeTextState={this.onChangeTextState}
                    beforeChange={this.selectAllIfNoSelection}
                  />
                </div>
              </div>
              {/* <div className="col">*/}
              {/*  <div className="form-group">*/}
              {/*    <label htmlFor="line-height-selector">Line Height</label>*/}
              {/*    <LineHeightSelect*/}
              {/*      editorState={this.textState}*/}
              {/*      onChangeTextState={this.onChangeTextState}*/}
              {/*      beforeChange={this.selectAllIfNoSelection}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/* </div>*/}
            </div>
          </div>
        </div>
        <EditTimelineControls entity={entity} game={game} />
      </div>
    );
  }
}

EditTextControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditTextControls);
