import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import LoadingIcon from '../loadingIcon/LoadingIcon';
import inject from '../../hoc/injectHoc';
import {STATE_FULFILLED} from '../../../constants/asyncConstants';
import {PLACEABLE_IMAGE} from '../../../constants/libraryTypeConstants';
import {PRODUCT_PLACEABLE_IMAGE_ID} from '../../../constants/productTypeConstants';

/**
 * The PreloadWriteableLibrary component.
 */
export class PreloadWriteableLibrary extends React.Component {
  /**
   * Whether or not the writeable library is loading.
   *
   * @type {boolean}
   */
  @observable isLoading = true;

  /**
   * An error that occurred while trying to load the writeable library.
   *
   * @type {string}
   */
  @observable loadingError = '';

  /**
   * Triggered when the component is first mounted to the page.
   */
  componentDidMount() {
    const {activeContentStore, apiContentLibrariesStore, libraryType, sessionStore} = this.props;

    const activeContentLibrary = activeContentStore.libraries[libraryType];
    const existingStoreKey = activeContentLibrary.storeKey;

    if (existingStoreKey && apiContentLibrariesStore.state === STATE_FULFILLED) {
      const contentLibraries = apiContentLibrariesStore.getFulfilled(existingStoreKey);

      const firstWriteable = lodash.find(contentLibraries, (library) => {
        return this.isLibraryWriteable(library);
      });

      if (firstWriteable) {
        // A writeable library exists, so display the children.
        this.stopLoading();
        return;
      }
    }

    const filters = {
      productId: this.getProductId(libraryType),
      clientId: sessionStore.clientId,
      userId: sessionStore.userId,
      contentLibraryTypeId: libraryType,
    };
    const newStoreKey = apiContentLibrariesStore.fetchContentLibraries(filters);

    apiContentLibrariesStore.getPromise(
      newStoreKey
    ).catch(() => {
      this.setLoadingError('An error occurred while trying to load the libraries.');
      return null;
    }).then(this.onLibrariesLoaded);
  }

  /**
   * Gets the product id.
   *
   * @param {number} libraryType
   * @returns {?number}
   */
  getProductId(libraryType) {
    if (libraryType === PLACEABLE_IMAGE) {
      return PRODUCT_PLACEABLE_IMAGE_ID;
    }

    const {sessionStore} = this.props;
    return sessionStore.productId;
  }

  /**
   * Triggered when the library loading call finishes.
   *
   * @param {Array.<{}>} existingLibraries
   */
  onLibrariesLoaded = (existingLibraries) => {
    if (!existingLibraries) {
      return;
    }

    const firstWriteable = lodash.find(existingLibraries, (library) => {
      return this.isLibraryWriteable(library);
    });

    if (firstWriteable) {
      // A writeable library exists, so display the children.
      this.stopLoading();
      return;
    }

    const {
      apiContentLibrariesStore,
      apiClientStore,
      apiSelectCategoryForCreateStore,
      libraryType,
      sessionStore
    } = this.props;
    const productId = this.getProductId(libraryType);
    const clientName = apiClientStore.getFulfilled(sessionStore.clientId).name;

    // Now we need to create a writeable library.
    apiSelectCategoryForCreateStore.createNewWritableLibrary(
      libraryType,
      productId,
      clientName
    ).then(() => {
      if (apiSelectCategoryForCreateStore.error) {
        this.setLoadingError('An error occurred while trying to create a writeable library.');
        return;
      }

      const filters = {
        productId,
        clientId: sessionStore.clientId,
        userId: sessionStore.userId,
        contentLibraryTypeId: libraryType,
      };
      apiContentLibrariesStore.expireCacheForFilters(filters);
      const newStoreKey = apiContentLibrariesStore.fetchContentLibraries(filters);

      apiContentLibrariesStore.getPromise(newStoreKey).then(() => {
        this.stopLoading();
      }, () => {
        this.stopLoading();
      });
    });
  };

  /**
   * Determines whether or not the given library is writeable.
   *
   * @param {{writeAccess: boolean, isGlobal: boolean}} library
   * @returns {boolean}
   */
  isLibraryWriteable = (library) => {
    const {apiLoginStore} = this.props;
    const currentUser = apiLoginStore.getFulfilled();
    return (library.writeAccess && (currentUser.canEditGlobals || !library.isGlobal));
  };

  /**
   * Sets the loading error message.
   *
   * @param {string} errorMessage
   */
  @action setLoadingError = (errorMessage) => {
    this.loadingError = String(errorMessage);
  };

  /**
   * Stops the loading and displays the children.
   */
  @action stopLoading = () => {
    this.isLoading = false;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {children} = this.props;

    if (!this.isLoading) {
      return children;
    }

    if (this.loadingError) {
      return (
        <div className="alert alert-warning">{this.loadingError}</div>
      );
    }

    return (
      <LoadingIcon size="lg" />
    );
  }
}

PreloadWriteableLibrary.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  libraryType: PropTypes.number.isRequired,

  apiClientStore: MobxPropTypes.observableObject,
  apiContentLibrariesStore: MobxPropTypes.observableObject,
  apiLoginStore: MobxPropTypes.observableObject,
  apiSelectCategoryForCreateStore: MobxPropTypes.observableObject,
  sessionStore: MobxPropTypes.observableObject,
};

PreloadWriteableLibrary.wrappedComponent = {};
PreloadWriteableLibrary.wrappedComponent.propTypes = {
  apiClientStore: MobxPropTypes.observableObject.isRequired,
  apiContentLibrariesStore: MobxPropTypes.observableObject.isRequired,
  apiLoginStore: MobxPropTypes.observableObject.isRequired,
  apiSelectCategoryForCreateStore: MobxPropTypes.observableObject.isRequired,
  sessionStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(PreloadWriteableLibrary)(
  observer(PreloadWriteableLibrary)
);
