import React from 'react';
import PropTypes from 'prop-types';

import './productItem.scss';

/**
 * The ProductItem component.
 */
export class ProductItem extends React.Component {
  /**
   * Gets the image URL based on the icon name
   *
   * @param {string} icon
   *
   * @returns {string}
   */
  getImageClass = (icon) => {
    switch (icon) {
      case 'poster-icon':
        return 'item-icon-poster';
      case 'fa-th-large':
        return 'item-icon-multi-screen';
      default:
        return 'item-icon-video';
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {product} = this.props;

    return (
      <figure className="product-item">
        <div className="item-icon-wrapper" onClick={() => (this.props.onClick(product))}>
          <div
            className={`item-icon ${this.getImageClass(product.dashIcon)}`}
          />
        </div>
        <button
          onClick={() => ( this.props.onClick(product) )}
          type="button"
          className="btn btn-block btn-secondary btn-rounded item-button"
        >{product.productName}</button>
      </figure>
    );
  }
}

ProductItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
};

export default ProductItem;
