import {detect} from 'detect-browser';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import React from 'react';
import {Alert} from 'reactstrap';

import './browserAlert.scss';

/**
 * The localStorage variable name that is set when this alert is closed.
 *
 * @const {string}
 */
const ACCEPTED_BROWSER_NAME = 'userAcceptedBrowser';

/**
 * The BrowserAlert component.
 */
export class BrowserAlert extends React.Component {
  /**
   * Whether or not this modal is open.
   *
   * @type {boolean}
   */
  @observable isOpen = false;

  /**
   * Triggered when this component is first added to the page.
   */
  componentDidMount() {
    this.initialize();
  }

  /**
   * Initializes whether or not this alert should be shown.
   */
  @action initialize = () => {
    if (this.getWasDismissed()) {
      this.isOpen = false;
      return;
    }

    const browser = detect();

    let isOpen = false;
    switch (browser && browser.name) {
      case 'chrome':
      case 'firefox':
        isOpen = false;
        break;

      default:
        isOpen = true;
    }

    this.isOpen = isOpen;
  };

  /**
   * Gets whether or not the alert was already dismissed.
   *
   * @returns {boolean}
   */
  getWasDismissed = () => {
    return Boolean(localStorage.getItem(ACCEPTED_BROWSER_NAME));
  };

  /**
   * Triggered when the alert is dismissed.
   */
  @action onDismissAlert = () => {
    localStorage.setItem(ACCEPTED_BROWSER_NAME, true);

    this.isOpen = false;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    if (!this.isOpen) {
      return null;
    }

    return (
      <Alert id="browser-alert" color="warning" isOpen={true} toggle={this.onDismissAlert}>
        <strong>It looks like you are using a web browser that is not fully supported.</strong>
        <br />
        <strong>
          Please consider using the latest{' '}
          <a
            href="https://www.google.com/chrome/"
            target="_blank"
            rel="noreferrer noopener"
          >Chrome</a>
          {' '}or{' '}
          <a
            href="https://www.mozilla.org/en-US/firefox/new/"
            target="_blank"
            rel="noreferrer noopener"
          >Firefox</a>
          {' '}browsers for a better experience.
        </strong>
      </Alert>
    );
  }
}

export default observer(BrowserAlert);
