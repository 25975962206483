import {observer, PropTypes} from 'mobx-react';
import React from 'react';

import inject from '../../hoc/injectHoc';
import DashboardHeader from '../../common/dashboardHeader/DashboardHeader';
import DashboardFigure from '../../common/dashboardFigure/DashboardFigure';
import stillImagePNG from '../../../assets/images/still-image.png';
import videoClipPNG from '../../../assets/images/video-clip.png';
import {dashboardRoute, newClipTypeRoute} from '../../routePaths';

import './newClipPage.scss';

/**
 * The NewClipPage component.
 */
export class NewClipPage extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div id="new-clip-page" className="system-page">
        <DashboardHeader title="Create New Clip" prevPage={dashboardRoute} />
        <div className="container">
          <div className="row">
            <div className="col-md-4 offset-md-2">
              <DashboardFigure
                image={stillImagePNG}
                linkLabel="Create Still Image"
                url={newClipTypeRoute}
                urlParams={{clipType: 'image', isLayout: ''}}
              />
            </div>
            <div className="col-md-4">
              <DashboardFigure
                image={videoClipPNG}
                linkLabel="Create Video Clip"
                url={newClipTypeRoute}
                urlParams={{clipType: 'video', isLayout: ''}}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NewClipPage.propTypes = {
  apiProjectsStore: PropTypes.observableObject
};

NewClipPage.wrappedComponent = {};
NewClipPage.wrappedComponent.propTypes = {
  apiProjectsStore: PropTypes.observableObject.isRequired,
};

export default inject(NewClipPage.wrappedComponent.propTypes)(
  observer(NewClipPage)
);
