import {action, observable, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import NewClipContentList from './components/newClipContentList/NewClipContentList';
import NewClipHeader from './components/newClipHeader/NewClipHeader';
import NoCategoryHeader from './components/noCategoryHeader/NoCategoryHeader';
import ContentDirectory from '../../common/contentDirectory/ContentDirectory';
import DashboardHeader from '../../common/dashboardHeader/DashboardHeader';
import PreloadWriteableLibrary from '../../common/preloadWriteableLibrary/PreloadWriteableLibrary';
import inject from '../../hoc/injectHoc';
import {editorRoute, homeRoute} from '../../routePaths';
import {CLIP_TYPE_IMAGE} from '../../../constants/clipTypeConstants';
import {EDITOR_NEW_BLANK, EDITOR_NEW_IMAGE, EDITOR_NEW_VIDEO} from '../../../constants/editorConstants';
import {BACKGROUND_STILL, BACKGROUND_VIDEO} from '../../../constants/libraryTypeConstants';

import './newClipTypePage.scss';

/**
 * The NewClipTypePage component.
 */
export class NewClipTypePage extends React.Component {
  /**
   * When changing from false to true, this will open the file uploading dialog.
   *
   * @type {boolean}
   */
  @observable openUploadDialog = false;

  /**
   * Called when the component is mounted.
   */
  componentDidMount() {
    const {sessionStore, activeContentStore, apiContentLibrariesStore} = this.props;

    const {userId, clientId, productId} = sessionStore;
    const libraryType = this.getLibraryType();

    const filters = {
      productId: productId,
      clientId: clientId,
      userId: userId,
      contentLibraryTypeId: libraryType,
    };

    const storeKey = apiContentLibrariesStore.fetchContentLibraries(filters);
    activeContentStore.setLibraryStoreKey(libraryType, storeKey);

    this.loadContents(1);
  }

  /**
   * Changes the category.
   *
   * @param {number} newCategoryId
   */
  onChangeCategory = (newCategoryId) => {
    const {activeContentStore} = this.props;
    const libraryType = this.getLibraryType();

    activeContentStore.setCategoryId(libraryType, newCategoryId);
  };

  /**
   * Opens the file upload dialog.
   */
  onOpenFileUploadDialog = () => {
    runInAction('openFileUploadDialog', () => {
      this.openUploadDialog = true;
    });

    setTimeout(action(() => {
      this.openUploadDialog = false;
    }));
  };

  /**
   * Load selected category's contents.
   *
   * @param {number} page
   * @returns {Promise}
   */
  loadContents = (page) => {
    const {activeContentStore, apiContentsStore} = this.props;
    const libraryType = this.getLibraryType();

    const libraryId = activeContentStore.getLibraryId(libraryType);
    const categoryId = activeContentStore.getCategoryId(libraryType);

    const safePage = (page) ? Number(page) : 1;

    if (!libraryId || !categoryId) {
      return Promise.resolve(null);
    }

    const storeKey = apiContentsStore.fetchContents({
      categoryId,
      libraryId,
      editableOnly: false,
      page: safePage,
    });

    return apiContentsStore.getPromise(storeKey);
  };

  /**
   * Returns whether or not the page is an image page.
   *
   * @returns {boolean}
   */
  isImage = () => {
    const clipType = String(this.props.match.params.clipType);
    return (clipType === CLIP_TYPE_IMAGE);
  };

  /**
   * Returns whether or not the clip type is for a layout.
   *
   * @returns {boolean}
   */
  isLayout = () => {
    return Boolean(this.props.match.params.isLayout);
  };

  /**
   * Gets the library type based on route params.
   *
   * @returns {number}
   */
  getLibraryType = () => {
    return this.isImage() ? BACKGROUND_STILL : BACKGROUND_VIDEO;
  };

  /**
   * Handles when a piece of content is selected for using as the background for new content.
   *
   * @param {{}} content
   */
  @action onBackgroundSelected = (content) => {
    const {routerStore} = this.props;

    const isImage = this.isImage();
    const isLayout = this.isLayout();

    routerStore.push(editorRoute, {
      params: {
        contentId: (isImage) ? EDITOR_NEW_IMAGE : EDITOR_NEW_VIDEO,
        fromContentId: content.id,
        isLayout: (isLayout) ? '1' : '',
      },
    });
  };

  /**
   * Handles when the blank canvas button is clicked.
   */
  @action onCreateBlankCanvasClick = () => {
    const {routerStore} = this.props;

    const isLayout = this.isLayout();

    routerStore.push(editorRoute, {
      params: {
        contentId: EDITOR_NEW_BLANK,
        fromContentId: '',
        isLayout: (isLayout) ? '1' : '',
      },
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {activeContentStore, apiProductsStore} = this.props;

    const libraryType = this.getLibraryType();
    const isImage = this.isImage();

    const activeContentLibrary = activeContentStore.libraries[libraryType];
    const activeContent = activeContentLibrary.content;
    const storeKey = activeContent.storeKey;

    const hasCategory = Boolean(activeContentStore.getCategoryId(libraryType));
    const isWriteable = activeContentStore.isActiveLibraryWritable(libraryType);

    const pageTitle = `Choose ${isImage ? 'Image' : 'Video'} Background`;

    const product = apiProductsStore.getActiveProduct() || {};
    const contentSize = {width: product.widthPx, height: product.heightPx};

    return (
      <div id="new-clip-type-page" className="system-page">
        <DashboardHeader title={pageTitle} prevPage={homeRoute} />

        <div className="new-clip-type-page-wrapper container">
          <PreloadWriteableLibrary activeContentStore={activeContentStore} libraryType={libraryType}>
            <div className="new-clip-type-page-content-dir">
              <ContentDirectory
                activeContentStore={activeContentStore}
                allowCategoryEdit={true}
                libraryType={libraryType}
                onCategorySelected={(categoryId) => this.onChangeCategory(categoryId)}
              />
            </div>

            <div className="new-clip-type-page-main">
              {(hasCategory) ? (
                <NewClipHeader
                  isWriteable={Boolean(isWriteable && storeKey)}
                  libraryType={libraryType}
                  onCreateBlank={(isImage) ? this.onCreateBlankCanvasClick : null}
                  onOpenFileUploadDialog={this.onOpenFileUploadDialog}
                />
              ) : (
                <NoCategoryHeader
                  onCreateBlank={(isImage) ? this.onCreateBlankCanvasClick : null}
                />
              )}

              <NewClipContentList
                contentSize={contentSize}
                libraryType={libraryType}
                onLoadNextPage={this.loadContents}
                onSelectContent={this.onBackgroundSelected}
                openUploadDialog={this.openUploadDialog}
                scrollElSelector="#new-clip-type-page"
              />
            </div>
          </PreloadWriteableLibrary>
        </div>
      </div>
    );
  }
}

NewClipTypePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      clipType: PropTypes.string,
      isLayout: PropTypes.string,
    }).isRequired,
  }).isRequired,

  activeContentStore: MobxPropTypes.observableObject,
  apiContentLibrariesStore: MobxPropTypes.observableObject,
  apiContentsStore: MobxPropTypes.observableObject,
  apiProductsStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
  sessionStore: MobxPropTypes.observableObject,
};

NewClipTypePage.wrappedComponent = {};
NewClipTypePage.wrappedComponent.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentLibrariesStore: MobxPropTypes.observableObject.isRequired,
  apiContentsStore: MobxPropTypes.observableObject.isRequired,
  apiProductsStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
  sessionStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(NewClipTypePage)(
  observer(NewClipTypePage)
);
