import interact from 'interactjs/src/index';
import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';

import {actionPositionComponent} from '../../display/components/action/actionPositionComponent';
import {
  getIsEntityTransitioning,
  getIsRotationLocked,
  getRotationFromElement,
  initEntitySize
} from '../../utils/dragDropHelper';

/**
 * Gets whether or not the current browser is IE11.
 *
 * @returns {boolean}
 */
function getIsIe() {
  return ('-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style);
}

/**
 * Rotates the entity.
 *
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @param {?number} rotate
 */
function rotateEntity(entity, game, rotate) {
  const safeRotate = (rotate || rotate === 0) ? rotate : null;

  game.addAction({entityId: entity.get('id')}, actionPositionComponent(
    false,
    null,
    null,
    safeRotate,
  ));
}

/**
 * Gets whether or not the entity is active.
 *
 * @param {ObservableMap} entity
 * @returns {boolean}
 */
function getIsActive(entity) {
  const interaction = entity.get('interaction');

  if (getIsRotationLocked(entity)) {
    return false;
  }

  return Boolean(interaction && interaction.isActive);
}

/**
 * Gets the target rotation element.
 *
 * @param {{}} rotationEvent
 * @returns {{}}
 */
function getTargetElement(rotationEvent) {
  let activeElement = rotationEvent.target;

  while (activeElement.id !== 'display-active' && activeElement.tagName !== 'body') {
    // Don't try to use parentElement, it won't work in IE11.
    activeElement = activeElement.parentNode;
  }

  const rotationGroupEl = activeElement.querySelector('.rotation-group');
  if (!rotationGroupEl) {
    return activeElement;
  }

  return rotationGroupEl;
}

/**
 * Gets the drag angle.
 *
 * @param {{}} element
 * @param {{}} dragEvent
 * @returns {number}
 */
function getDragAngle(element, dragEvent) {
  const startDegrees = parseFloat(element.dataset.degrees) || 0;
  const center = {
    x: parseFloat(element.dataset.centerX) || 0,
    y: parseFloat(element.dataset.centerY) || 0,
  };
  const angle = Math.atan2(
    center.y - dragEvent.clientY,
    center.x - dragEvent.clientX
  );
  const degrees = angle * (180 / Math.PI); // eslint-disable-line no-magic-numbers

  return Number(degrees - startDegrees);
}

/**
 * Shows or hides the degrees display.
 *
 * @param {HTMLElement} element
 * @param {boolean} show
 * @param {number} degrees
 */
function toggleDegreesDisplay(element, show, degrees) {
  const labelEls = element.querySelectorAll('.rotation-label');
  if (!labelEls || !labelEls.length) {
    return;
  }

  const labelEl = labelEls[0];
  if (!labelEl) {
    return;
  }

  if (show) {
    labelEl.style.display = 'block';
  } else {
    labelEl.style.display = 'none';
  }

  if (degrees || parseInt(degrees, 10) === 0) {
    const degreesContent = parseFloat(degrees).toFixed(1);
    if (getIsIe()) {
      labelEl.textContent = degreesContent + '°';
    } else {
      labelEl.innerHTML = degreesContent + '&deg;';
    }
  }
}

/**
 * Makes a degrees number positive and between 0 and 360.
 *
 * @param {number} degrees
 * @returns {number}
 */
function simplifyDegrees(degrees) {
  const fullCircle = 360;
  const baseDegrees = degrees % fullCircle;

  if (baseDegrees >= 0) {
    return baseDegrees;
  }

  return fullCircle + baseDegrees;
}

/**
 * A higher order component wrapper that handles making an entity rotatable.
 *
 * @param {Object} WrappedComponent
 * @returns {Object}
 */
export default function entityRotateHocWrapper(WrappedComponent) {
  /**
   * The EntityRotateHoc higher order component.
   */
  class EntityRotateHoc extends React.Component {
    /**
     * Whether or not this entity is currently active.
     * Used to track the change.
     *
     * @type {boolean}
     */
    @observable isActive = false;

    /**
     * The DOM element for the element.
     *
     * @type {HTMLElement}
     */
    @observable domEl = null;

    /**
     * The DOM element for the display element.
     *
     * @type {HTMLElement}
     */
    @observable displayEl = null;

    /**
     * The interactJS object initialized on the DOM element.
     *
     * @type {{draggable: function, resizable: function}}
     */
    @observable interaction = null;

    /**
     * Triggered right after the component mounts to the page.
     */
    componentDidMount() {
      this.checkActiveStateChange();
    }

    /**
     * Triggered when the component is about to unmount.
     */
    componentWillUnmount() {
      this.stopDragging();
    }

    /**
     * Triggered when an observed item updates.
     */
    componentWillReact = () => {
      this.checkActiveStateChange();
    };

    /**
     * Checks whether or not the active state has changed and either allows it to be dragged or stops it from
     * dragging.
     */
    @action checkActiveStateChange = () => {
      const isActive = getIsActive(this.props.entity);

      if (!this.isActive && isActive) {
        this.initInteraction();
        this.isActive = true;
      } else if (this.isActive && !isActive) {
        this.stopDragging();
        this.isActive = false;
      }
    };

    /**
     * Triggered right after the wrapped component is added to OR removed from the page.
     *
     * @param {{}} domEl
     */
    @action onChangeMount = (domEl) => {
      if (this.domEl) {
        return;
      }

      this.domEl = findDOMNode(domEl);
      this.displayEl = document.getElementById('display-source');
    };

    /**
     * Handles the start of the drag event.
     *
     * @param {{target: HTMLElement}} startEvent
     */
    onStart = (startEvent) => {
      const element = getTargetElement(startEvent);
      const rect = element.getBoundingClientRect();

      if (!element.dataset) {
        element.dataset = {};
      }

      // Store the center as the element has css `transform-origin: center center`.
      element.dataset.centerX = rect.left + rect.width / 2;
      element.dataset.centerY = rect.top + rect.height / 2;

      // Get the angle of the element when the drag starts.
      element.dataset.degrees = getDragAngle(element, startEvent);
      element.dataset.offset = simplifyDegrees(
        getRotationFromElement(element)
      );

      toggleDegreesDisplay(startEvent.target, true, element.dataset.offset);
    };

    /**
     * Handles the end of the drag event.
     *
     * @param {{target: HTMLElement}} endEvent
     */
    onEnd = (endEvent) => {
      const element = getTargetElement(endEvent);

      const finalDegrees = simplifyDegrees(
        getRotationFromElement(endEvent.target)
      );
      toggleDegreesDisplay(endEvent.target, false, finalDegrees);

      // Clear the angle on dragend.
      element.dataset.degrees = 0;
    };

    /**
     * Handles the rotate event.
     *
     * @param {{}} rotateEvent
     */
    onRotate = (rotateEvent) => {
      const element = getTargetElement(rotateEvent);
      const entity = this.props.entity;
      const game = this.props.game;

      if (getIsEntityTransitioning(entity, game.timer.elapsedTime)) {
        // TODO: Indicate to the user why dragging isn't working.
        return;
      }

      let degrees = getDragAngle(element, rotateEvent);
      if (element.dataset.offset) {
        degrees += Number(element.dataset.offset) || 0;
      }

      let simpleDegrees = simplifyDegrees(degrees);
      const lockRange = 1;
      lodash.forEach([0, 90, 180, 270, 360], (lockPoint) => { // eslint-disable-line no-magic-numbers
        if (simpleDegrees > lockPoint - lockRange && simpleDegrees < lockPoint + lockRange) {
          simpleDegrees = lockPoint;
          return false;
        }
        return true;
      });
      if (simpleDegrees === 360) { // eslint-disable-line no-magic-numbers
        simpleDegrees = 0;
      }

      toggleDegreesDisplay(rotateEvent.target, true, simpleDegrees);

      rotateEntity(entity, game, simpleDegrees);
    };

    /**
     * Starts the interactJS code.
     */
    @action initInteraction = () => {
      const {entity} = this.props;

      if (this.interaction) {
        // Make sure we don't have multiple dragging interactions on this element.
        this.interaction.unset();
      }

      if (getIsRotationLocked(entity)) {
        return;
      }

      const interaction = interact(this.domEl);
      interaction.draggable({
        enabled: true,
        onstart: this.onStart,
        onmove: this.onRotate,
        onend: this.onEnd,
      });

      initEntitySize(this.props.entity, this.props.game);

      this.interaction = interaction;
    };

    /**
     * Unbinds the dragging code.
     */
    @action stopDragging = () => {
      if (!this.interaction) {
        return;
      }

      this.interaction.unset();
      this.interaction = null;
    };

    /**
     * Renders the WrappedComponent.
     *
     * @returns {Object}
     */
    render() {
      if (!WrappedComponent) {
        return null;
      }

      if (this.props.ref) {
        throw new Error('EntityRotateHoc will override ref property given to the wrapped component.');
      }

      return (
        <WrappedComponent
          {...this.props}
          ref={this.onChangeMount}
        />
      );
    }
  }

  EntityRotateHoc.propTypes = {
    entity: MobxPropTypes.observableMap.isRequired,
    game: PropTypes.shape({
      addAction: PropTypes.func,
      setEntityComponents: PropTypes.func,
      timer: PropTypes.shape({
        elapsedTime: PropTypes.number,
      }),
    }).isRequired,
    ref: PropTypes.func,
  };

  return observer(EntityRotateHoc);
}
