import {action, extendObservable, observable} from 'mobx';
import lodash from 'lodash';

import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN} from '../../constants/storeConstants';
import {PRODUCT_STORAGE_KEY} from '../../constants/sessionConstants';
import serverApi from '../../utils/serverApi';
import {apiStore, getCase} from '../../utils/apiStore';
import sessionStore from '../active/sessionStore';

/**
 * The product ids store.
 */
class ProductsStore {
  /**
   * The clientId for which products are being searched.
   *
   * @type {number}
   */
  @observable clientId;

  /**
   * The map of each clients' product Ids.
   *
   * @type {ObservableMap<string, {projects: ?ObservableArray, error: ?Error}>}
   */
  @observable productsByClient = observable.map();

  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {?Array.<{}>}
   */
  getFulfilled() {
    const clientId = String(sessionStore.clientId);

    if (!this.productsByClient.has(clientId)) {
      return null;
    }
    return this.productsByClient.get(clientId).products;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    const clientId = String(sessionStore.clientId);

    if (!this.productsByClient.has(clientId)) {
      return null;
    }
    return this.productsByClient.get(clientId).error;
  }

  /**
   * Clears all the clientIds and projects.
   */
  @action clearAll() {
    this.productsByClient.clear();
    this.state = STATE_PRE;
  }

  /**
   * Gets the active product from the active client's list of products
   *
   * @returns {?{}}
   */
  getActiveProduct() {
    const clientId = String(sessionStore.clientId);
    const productId = String(sessionStore.productId);

    if (!clientId) {
      return null;
    }

    if (!productId) {
      return null;
    }

    if (!this.productsByClient.has(clientId)) {
      return null;
    }

    if (!this.productsByClient.get(clientId).products) {
      return null;
    }

    return this.productsByClient.get(clientId).products.find((item) => (String(item.id) === productId));
  }

  /**
   * expires store cache for a client id.
   *
   * @param {number} clientId
   */
  @action expireCacheForClient(clientId) {
    const safeId = String(clientId);

    if (this.productsByClient.has(safeId)) {
      this.productsByClient.get(safeId)[EXPIRE_TIME] = null;
    }
  }

  /**
   * Gets the current product data from persistent storage if it is available.
   *
   * @returns {Observable<{}>}
   */
  @action preloadActiveProduct() {
    const activeProduct = this.getActiveProduct();

    if (!lodash.isEmpty(activeProduct)) {
      return activeProduct;
    }

    const storedProduct = localStorage.getItem(PRODUCT_STORAGE_KEY);
    if (!storedProduct) {
      return null;
    }

    let parsedProduct = {};

    try {
      parsedProduct = JSON.parse(storedProduct);
    } catch (error) {
      sessionStore.setProduct(null);
      return null;
    }

    sessionStore.setProduct(parsedProduct);

    const productId = parsedProduct.id;

    const clientId = String(sessionStore.clientId);
    if (!clientId) {
      return null;
    }

    if (!this.productsByClient.has(clientId)) {
      this.productsByClient.set(clientId, { products: [] });
    }

    const products = this.productsByClient.get(clientId).products;
    const foundProduct = lodash.find(products, 'id', productId);
    if (!foundProduct) {
      this.productsByClient.set(clientId, {
        products: products.concat(parsedProduct),
        error: null
      });
    }

    return parsedProduct;
  }

  /**
   * Fetches products from the server by client ID.
   *
   * @param {number=} rawClientId
   */
  @action fetchProducts(rawClientId) {
    const clientId = String(rawClientId);

    let currentExpireTime = null;
    if (this.productsByClient.has(clientId)) {
      currentExpireTime = this.productsByClient.get(clientId)[EXPIRE_TIME];
    }

    if (currentExpireTime && currentExpireTime <= Date.now()) {
      this.productsByClient.delete(clientId);
      currentExpireTime = null;
    }

    if (currentExpireTime) {
      this.state = STATE_FULFILLED;
      return;
    }

    this.state = STATE_PENDING;

    serverApi.productIdsGetAll(clientId).then(
      action('fetchProductIdsSuccess', (productIds) => {
        const productPromises = productIds.map( (productId) => {
          return serverApi.productGetById(productId);
        });

        Promise.all(productPromises).then(
          action('fetchProductsSuccess', (products) => {
            this.productsByClient.set(clientId, {
              [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
              products: products.filter((product) => ( product.isVideoProduct )),
              error: null,
            });
            this.state = STATE_FULFILLED;
          }),
          action('fetchProductsError', (error) => {
            this.productsByClient.set(clientId, {
              products: [],
              error,
            });
            this.state = STATE_REJECTED;
          })
        );
      }),
      action('fetchProductIdsError', (error) => {
        this.productsByClient.set(clientId, {
          products: [],
          error,
        });
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => {
      return this.getFulfilled();
    };
    const getRejected = () => {
      return this.getRejected();
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }
}

export default new ProductsStore();
