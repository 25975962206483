/**
 * The name of a image clip type.
 * @const {string}
 */
export const CLIP_TYPE_IMAGE = 'image';

/**
 * The name of a video clip type.
 * @const {string}
 */
export const CLIP_TYPE_VIDEO = 'video';
