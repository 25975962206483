import {action, extendObservable, observable} from 'mobx';

import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {CREATED_CONTENT} from '../../../constants/libraryTypeConstants';
import serverApi from '../../../utils/serverApi';
import {apiStore, getCase} from '../../../utils/apiStore';
import apiContentsStore from '../apiContentsStore';
import apiContentSearchStore from '../apiContentSearchStore';
import activeContentStore from '../../active/activeContentStore';

/**
 * The UpdateContents store.
 */
class UpdateContentsStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * Stores the content state
   *
   * @type {Observable<{error: ?Observable}>}
   */
  @observable content = {
    error: null,
  };

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {boolean}
   */
  getFulfilled() {
    return true;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    return this.content.error;
  }

  /**
   * Updates the JSON source of the active content record.
   *
   * @param {{}} source
   * @param {{}} content
   * @param {number} feedId
   * @returns {Promise}
   */
  updateActiveContentSource(source, content, feedId) {
    const categoryId = activeContentStore.libraries[CREATED_CONTENT].category.id;
    const libraryId = activeContentStore.libraries[CREATED_CONTENT].id;

    if (!content || !content.id || !content.contentFiles) {
      return Promise.resolve(null);
    }

    const contentBody = {
      id: content.id,
    };

    const contentFileBody = {
      id: content.contentFiles[0].id,
      create24ProjectJson: JSON.stringify(source),
      feedId: feedId
    };

    return this.updateContent(contentBody, contentFileBody, categoryId, libraryId, CREATED_CONTENT);
  }

  /**
   * Updates content and content file from the server.
   *
   * @param {{}} content
   * @param {{}} contentFile
   * @param {number} categoryId
   * @param {number} libraryId
   * @param {number} libraryType
   * @returns {Promise}
   */
  @action updateContent(content, contentFile, categoryId, libraryId, libraryType) {
    this.state = STATE_PENDING;

    return serverApi.contentFilesPatch(contentFile).then(
      () => {
        return serverApi.contentsPatch(content);
      },
      action('patchContentFileError', (error) => {
        this.content.error = error;
        this.state = STATE_REJECTED;

        return {skip: true};
      })
    ).then(
      action('patchContentSuccess', (contentsResponse) => {
        if (contentsResponse && contentsResponse.skip) {
          return;
        }

        this.content.error = null;
        apiContentsStore.refreshCurrentContents({categoryId, libraryId});
        apiContentSearchStore.expireCacheForLibrary(libraryId);

        const searchTerm = activeContentStore.getContentSearchFilter(libraryType);
        if (searchTerm) {
          apiContentSearchStore.refreshCurrentContents({
            libraryId,
            searchTerm,
          });
        }

        this.state = STATE_FULFILLED;
      }),
      action('patchContentError', (error) => {
        this.content.error = error;
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Clears all the current info and states
   */
  @action clearAll() {
    this.content.error = null;
    this.state = STATE_PRE;
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => {
      return this.getFulfilled();
    };
    const getRejected = () => {
      return this.getRejected();
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }
}

export default new UpdateContentsStore();
