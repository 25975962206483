import {action, observable, runInAction, toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';

import ContentDirectory from '../../common/contentDirectory/ContentDirectory';
import PreloadWriteableLibrary from '../../common/preloadWriteableLibrary/PreloadWriteableLibrary';
import inject from '../../hoc/injectHoc';
import NewClipContentList from '../../pages/newClipType/components/newClipContentList/NewClipContentList';
import NewClipHeader from '../../pages/newClipType/components/newClipHeader/NewClipHeader';
import {PLACEABLE_IMAGE} from '../../../constants/libraryTypeConstants';
import {PRODUCT_PLACEABLE_IMAGE_ID} from '../../../constants/productTypeConstants';
import {THUMBNAIL_MAX_HEIGHT, THUMBNAIL_MAX_WIDTH} from '../../../utils/contentsHelper';
import {hasEmployeeAccess} from '../../../utils/writableLibrariesHelper';

import './selectImageModal.scss';

/**
 * The place-able image library type.
 *
 * @const {number}
 */
const libraryType = PLACEABLE_IMAGE;

/**
 * The product type id for placeable images;
 *
 * @const {number}
 */
const productTypeId = PRODUCT_PLACEABLE_IMAGE_ID;

/**
 * The SelectImageModal component.
 */
class SelectImageModal extends React.Component {
  /**
   * When changing from false to true, this will open the file uploading dialog.
   *
   * @type {boolean}
   */
  @observable openUploadDialog = false;

  /**
   * Triggered when the component just mounted onto the page.
   */
  componentDidMount() {
    if (this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{isOpen: boolean}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Initializes the modal by getting the valid content libraries.
   */
  initializeModal = () => {
    const {sessionStore, activeContentStore, apiContentLibrariesStore} = this.props;

    const {userId, clientId} = sessionStore;

    const filters = {
      productId: productTypeId,
      clientId: clientId,
      userId: userId,
      contentLibraryTypeId: libraryType,
    };

    const storeKey = apiContentLibrariesStore.fetchContentLibraries(filters);
    activeContentStore.setLibraryStoreKey(libraryType, storeKey);

    this.loadContents(1);
  };

  /**
   * Opens the file upload dialog.
   */
  onOpenFileUploadDialog = () => {
    runInAction('openFileUploadDialog', () => {
      this.openUploadDialog = true;
    });

    setTimeout(action(() => {
      this.openUploadDialog = false;
    }));
  };

  /**
   * Changes the category.
   *
   * @param {number} newCategoryId
   */
  onChangeCategory = (newCategoryId) => {
    const {activeContentStore} = this.props;

    activeContentStore.setCategoryId(libraryType, newCategoryId);
  };

  /**
   * Load selected category's contents.
   *
   * @param {number} page
   * @returns {Promise}
   */
  loadContents = (page) => {
    const {activeContentStore, apiContentsStore} = this.props;

    const libraryId = activeContentStore.getLibraryId(libraryType);
    const categoryId = activeContentStore.getCategoryId(libraryType);

    const safePage = (page) ? Number(page) : 1;

    if (!libraryId || !categoryId) {
      return Promise.resolve(null);
    }

    const storeKey = apiContentsStore.fetchContents({
      categoryId,
      libraryId,
      page: safePage,
    });

    return apiContentsStore.getPromise(storeKey);
  };

  /**
   * Triggered when the modal is closed without a chosen image.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete(null);
  };

  /**
   * Triggered when the modal is closed after choosing image.
   *
   * @param {{}} newContent
   */
  onCompleteModal = (newContent) => {
    const {onComplete} = this.props;

    onComplete(toJS(newContent));
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      isOpen,
      title,
      activeContentStore,
    } = this.props;

    const hasCategory = Boolean(activeContentStore.getCategoryId(libraryType));
    const isWriteable = activeContentStore.isActiveLibraryWritable(libraryType);

    const allowCategoryEdit = hasEmployeeAccess();

    // This will make the content src display show as a square.
    const contentSize = {
      width: THUMBNAIL_MAX_WIDTH,
      height: THUMBNAIL_MAX_HEIGHT,
    };

    return (
      <Modal isOpen={isOpen} backdrop="static" toggle={this.onCancelModal} className="select-image-modal">
        <ModalHeader toggle={this.onCancelModal}>{title || 'Select Image'}</ModalHeader>
        <ModalBody>
          <div className="modal-body-content-wrapper">
            <PreloadWriteableLibrary activeContentStore={activeContentStore} libraryType={libraryType}>
              <div className="content-directory-sidebar">
                <ContentDirectory
                  libraryType={libraryType}
                  onCategorySelected={this.onChangeCategory}
                  activeContentStore={activeContentStore}
                  allowCategoryEdit={allowCategoryEdit}
                />
              </div>
              <div className="select-image-main">
                <div className="container">
                  {(hasCategory) && (
                    <NewClipHeader
                      libraryType={libraryType}
                      isWriteable={isWriteable}
                      onOpenFileUploadDialog={this.onOpenFileUploadDialog}
                    />
                  )}

                  <NewClipContentList
                    contentSize={contentSize}
                    libraryType={libraryType}
                    onLoadNextPage={this.loadContents}
                    onSelectContent={this.onCompleteModal}
                    openUploadDialog={this.openUploadDialog}
                    scrollElSelector=".content-row"
                  />
                </div>
              </div>
            </PreloadWriteableLibrary>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

SelectImageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,

  activeContentStore: MobxPropTypes.observableObject,
  apiContentLibrariesStore: MobxPropTypes.observableObject,
  apiContentsStore: MobxPropTypes.observableObject,
  sessionStore: MobxPropTypes.observableObject,
  title: PropTypes.string,
};

SelectImageModal.defaultProps = {
  title: 'Select Image',
};

SelectImageModal.wrappedComponent = {};
SelectImageModal.wrappedComponent.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentLibrariesStore: MobxPropTypes.observableObject.isRequired,
  apiContentsStore: MobxPropTypes.observableObject.isRequired,
  sessionStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(SelectImageModal)(
  observer(SelectImageModal)
);
