import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import DisplayEntities from '../displayEntities/DisplayEntities';
import ErrorBoundary from '../../common/errorBoundary/ErrorBoundary';
import controlDropzoneHoc from '../../hoc/controlDropzoneHoc';
import {STATE_PLAYING} from '../../../constants/displayItemConstants';

import './displaySource.scss';

/**
 * The DisplaySource component.
 */
class RawDisplaySource extends React.Component {
  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {game, timer, zoomStore} = this.props;
    const gameResolution = game.resolution;

    const style = {
      width: gameResolution.width,
      height: gameResolution.height,
    };
    if (zoomStore && zoomStore.transformData) {
      const data = zoomStore.transformData;
      style.transform = `translate(${data.offsetX}px, ${data.offsetY}px) scale(${data.scale})`;
    }

    if (timer.state === STATE_PLAYING) {
      // Make sure items out of frame are hidden while the item is playing.
      style.overflow = 'hidden';
    }

    return (
      <div id="display-source" style={style}>
        <ErrorBoundary message={<h1>An error occurred while trying to render the video.</h1>}>
          <DisplayEntities game={game} />
        </ErrorBoundary>
      </div>
    );
  }
}

RawDisplaySource.propTypes = {
  game: PropTypes.shape({
    entities: PropTypes.objectOf(PropTypes.object),
    resolution: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }).isRequired,
  timer: MobxPropTypes.observableObject.isRequired,

  zoomStore: MobxPropTypes.observableObject,
};

export const DisplaySource = observer(RawDisplaySource);
export const DroppableDisplaySource = controlDropzoneHoc({
  dropItemSelector: '.add-button-control',
}, DisplaySource);
