import lodash from 'lodash';

import {TRANSITION_TIME_MS} from '../constants/displayConstants';
import {isLocked} from '../display/components/common/lockedComponent';
import {sizeComponent} from '../display/components/common/sizeComponent';

/**
 * Gets the current scale of the given element.
 * This checks the transform css style for the scale property.
 *
 * @param {HTMLElement} element
 * @returns {number}
 */
export function getScaleFromElement(element) {
  const transform = lodash.get(element, 'style.transform');
  if (!transform) {
    return 1;
  }

  let scale = 1;
  const scaleMatch = String(transform).match(/scale\(([0-9.]+)\)/i);
  if (scaleMatch && scaleMatch[1]) {
    scale = scaleMatch[1];
  }
  if (scale <= 0) {
    scale = 1;
  }

  return parseFloat(scale);
}

/**
 * Gets the current rotate degrees of the given element.
 * This checks the transform css style for the rotate property.
 *
 * @param {HTMLElement} element
 * @returns {number}
 */
export function getRotationFromElement(element) {
  const transform = lodash.get(element, 'style.transform');
  if (!transform) {
    return 0;
  }

  let rotation = 0;
  const rotateMatch = String(transform).match(/rotate\(([0-9.-]+)deg\)/i);
  if (rotateMatch && rotateMatch[1]) {
    rotation = rotateMatch[1];
  }

  return Number(rotation);
}

/**
 * Gets the current width and height of the given element (before transformations).
 * This checks the transform css style for the rotate property.
 *
 * @param {HTMLElement} element
 * @param {number=} scale
 * @returns {{width: number, height: number}}
 */
export function getDimensionsFromElement(element, scale) {
  let width = parseFloat(lodash.get(element, 'style.width')) || 0;
  let height = parseFloat(lodash.get(element, 'style.height')) || 0;

  if (scale) {
    width = width * scale;
    height = height * scale;
  }

  return {
    width,
    height,
  };
}

/**
 * Updates the entity size to a pixel value if it was set to 'auto' before.
 *
 * @param {ObservableMap} entity
 * @param {GameStore} game
 */
export function initEntitySize(entity, game) {
  const entityId = entity.get('id');
  const entityEl = document.getElementById(entityId);

  const currentSize = entity.get('size');
  if (!currentSize) {
    return;
  }

  let newWidth = null;
  let newHeight = null;
  if (currentSize.width === 'auto') {
    newWidth = entityEl.clientWidth;
  }
  if (currentSize.height === 'auto') {
    newHeight = entityEl.clientHeight;
  }

  const actionParams = {
    entityId,
    skipHistory: true,
  };

  if (newWidth || newHeight) {
    game.addAction(actionParams, sizeComponent({
      width: newWidth || currentSize.width,
      height: newHeight || currentSize.height,
    }));
  }
}

/**
 * Gets whether or not the position of the entity is locked.
 *
 * @param {ObservableMap} entity
 * @returns {boolean}
 */
export function getIsPositionLocked(entity) {
  return isLocked(entity, 'position');
}

/**
 * Gets whether or not the size of the entity is locked.
 *
 * @param {ObservableMap} entity
 * @returns {boolean}
 */
export function getIsSizeLocked(entity) {
  return isLocked(entity, 'size');
}

/**
 * Gets whether or not the rotation of the entity is locked.
 *
 * @param {ObservableMap} entity
 * @returns {boolean}
 */
export function getIsRotationLocked(entity) {
  if (isLocked(entity, 'rotation')) {
    return true;
  } else if (isLocked(entity, 'position')) {
    return true;
  }
  return false;
}

/**
 * Gets whether or not the cropping of the entity is locked.
 *
 * @param {ObservableMap} entity
 * @returns {boolean}
 */
export function getIsCroppingLocked(entity) {
  if (isLocked(entity, 'size')) {
    return true;
  } else if (isLocked(entity, 'position')) {
    return true;
  }
  return false;
}

/**
 * Gets whether or not the entity has an ongoing preset transitioning.
 *
 * @param {ObservableMap} entity
 * @param {number} currentTime
 * @returns {boolean}
 */
export function getIsEntityTransitioning(entity, currentTime) {
  if (!entity.has('transition')) {
    return false;
  }

  const time = entity.get('time');

  let isTransitioning = false;
  entity.get('transition').forEach((transition) => {
    if (!transition || !transition.preset || !transition.loadedPreset) {
      return;
    }
    const preset = transition.loadedPreset;

    if (preset.flow === 'in' && currentTime < (time.start + TRANSITION_TIME_MS)) {
      isTransitioning = true;
    } else if (preset.flow === 'out' && currentTime > (time.end - TRANSITION_TIME_MS)) {
      isTransitioning = true;
    }
  });

  return isTransitioning;
}
