import lodash from 'lodash';
import moment from 'moment';

import pascalCase from './pascalCase';
import requester from './serverRequest';
import {BACKGROUND_TYPES} from '../constants/libraryTypeConstants';
import config from '../config/main';

/**
 * Default number of items to show on a page.
 *
 * @type {number}
 */
const DEFAULT_PER_PAGE = 50;

const serverApi = {
  /**
   * dynamicImageGet request.
   *
   * @returns {Promise}
   */
  dynamicImageGet: function dynamicImageGet() {
    return requester.fetch({
      baseURL: config.api.content,
      url: '/File/Create24StaticImages',
      method: 'get'
    });
  },

  /**
   * loginPost
   *
   * @param {{username: string, password: string}} body
   * @returns {Promise}
   */
  loginPost: function loginPost(body) {
    return requester.fetch({
      baseURL: config.api.auth,
      url: '/login',
      method: 'post',
      data: body,
      auth: false,
    });
  },

  /**
   * passwordResetSend request
   *
   * @param {{string}} nameOrEmail
   * @returns {Promise}
   */
  passwordResetSend: function passwordResetSend(nameOrEmail) {
    const safeSearch = nameOrEmail.replace(/[^a-zA-Z0-9@.]/ig, '');
    return requester.fetch({
      baseURL: config.api.auth,
      url: `/passwordresetrequest/${safeSearch}`,
      method: 'get',
    });
  },

  /**
   * switchClient request.
   * Switches the auth token to use a different client.
   *
   * @param {number} newClientId
   * @param {boolean} ignoreFails
   * @returns {Promise}
   */
  switchClient: function switchClient(newClientId, ignoreFails) {
    const safeClientId = parseInt(newClientId, 10);
    return requester.fetch({
      baseURL: config.api.auth,
      url: `/login/switchclient/${safeClientId}`,
      method: 'get',
    }, {
      skipLogout: Boolean(ignoreFails),
    });
  },

  /**
   * userGetById request.
   *
   * @param {number} userId
   * @returns {Promise}
   */
  userGetById: function userGetById(userId) {
    return requester.fetch({
      baseURL: config.api.admin,
      url: `/CoreUsers/${userId}`,
      method: 'get',
    });
  },

  /**
   * clientGetById request.
   *
   * @param {number} clientId
   * @returns {Promise}
   */
  clientGetById: function clientGetById(clientId) {
    return requester.fetch({
      baseURL: config.api.admin,
      url: `/CoreClients/${clientId}`,
      method: 'get',
    });
  },

  /**
   * clientsGetBySearch request.
   *
   * @param {string} search
   * @returns {Promise}
   */
  clientsGetBySearch: function clientsGetByName(search) {
    const safeSearch = encodeURI(search.replace(/[^a-zA-Z0-9 ]/ig, ''));

    return requester.fetch({
      baseURL: config.api.admin,
      url: `/CoreClients/NameSearch/${safeSearch}`,
      method: 'get',
    });
  },

  /**
   * productGetById request.
   *
   * @param {number} productId
   * @returns {Promise}
   */
  productGetById: function productGetById(productId) {
    return requester.fetch({
      baseURL: config.api.admin,
      url: `/CoreProductTypes/${productId}`,
      method: 'get',
    });
  },

  /**
   * productIdsGetAll request.
   *
   * @param {number=} clientId
   * @returns {Promise}
   */
  productIdsGetAll: function productIdsGetAll(clientId) {
    return requester.fetch({
      baseURL: config.api.admin,
      url: `/CoreContracts/ContractedProducts/${clientId}`,
      method: 'get',
    });
  },

  /**
   * projectsGetAll request.
   * This is a demo call that is only used by a demo store and not the actual project.
   *
   * @param {number=} page
   * @returns {Promise}
   */
  projectsGetAll: function projectsGetAll(page) {
    return requester.fetch({
      url: '/projects',
      method: 'get',
      params: {
        page: (page && page > 0) ? page : 1
      }
    });
  },

  /**
   * projectGetById request.
   * This is a demo call that is only used by a demo store and not the actual project.
   *
   * @param {number} projectId
   * @returns {Promise}
   */
  projectGetById: function projectGetById(projectId) {
    return requester.fetch({
      url: `/projects/${projectId}`,
      method: 'get',
    });
  },

  /**
   * availableLibraries request.
   *
   * @param {{productId: number, clientId: number, userId: number, contentLibraryTypeId: number}} filters
   * @returns {Promise}
   */
  availableLibraries: function availableLibraries(filters) {
    return requester.fetch({
      baseURL: config.api.content,
      url: '/ContentLibraries/AvailableLibraries',
      method: 'get',
      params: filters
    });
  },

  /**
   * contentGetById request.
   *
   * @param {number} contentId
   * @returns {Promise}
   */
  contentGetById(contentId) {
    return requester.fetch({
      baseURL: config.api.content,
      url: `/Content/${contentId}`,
      method: 'get'
    });
  },

  /**
   * contentInUse request. True/false on whether the content is assigned to a playlist.
   *
   * @param {number} contentId
   * @returns {Promise}
   */
  contentInUse(contentId) {
    return requester.fetch({
      baseURL: config.api.content,
      url: `/Content/${contentId}/IsInUse`,
      method: 'get'
    });
  },

  /**
   * libraryGetById request.
   *
   * @param {number} libraryId
   * @returns {Promise}
   */
  libraryGetById(libraryId) {
    return requester.fetch({
      baseURL: config.api.content,
      url: `/ContentLibraries/${libraryId}`,
      method: 'get'
    });
  },

  /**
   * clientContentLibrariesGetByProduct request.
   *
   * @param {number} clientId
   * @returns {Promise}
   */
  contentLibrariesGetByClientId: function contentLibrariesGetByClientId(clientId) {
    return requester.fetch({
      baseURL: config.api.content,
      url: `/ClientContentLibraryBridge/Client/${clientId}/Libraries`,
      method: 'get'
    });
  },

  /**
   * clientContentLibrariesGetByProduct request.
   *
   * @param {number} clientId
   * @param {number} productId
   * @returns {Promise}
   */
  contentLibrariesGetByProduct: function contentLibrariesGetByProduct(clientId, productId) {
    return requester.fetch({
      baseURL: config.api.content,
      url: `/ClientContentLibraryBridge/Client/${clientId}/Product/${productId}`,
      method: 'get'
    });
  },

  /**
   * contentLibraryCreate request
   * This requires two REST calls, the first to create the Content Library
   * and the next to set permissions so that our app can read/write to the created Content Library.
   *
   * @param {string} libraryName
   * @param {{id: number, defaultDirectory: string}} client
   * @param {number} productTypeId
   * @param {number} libraryTypeId
   * @param {number} userId
   * @returns {Promise}
   */
  contentLibraryCreate: function contentLibraryCreate(libraryName, client, productTypeId, libraryTypeId, userId) {
    const clientId = client.id;

    let newLibrary = null;

    return requester.fetch({
      baseURL: config.api.content,
      url: `/ContentLibraries/Client/${clientId}`,
      method: 'post',
      data: {
        hideFromPortal: true,
        libraryName,
        productTypeId,
        contentLibraryTypeId: libraryTypeId,
        defaultDirectory: client.contentDirectory,
        isGlobal: false,
        isDeleted: false,
        updatedByUserId: userId,
        allowCreate24: true,
      },
      pascal: true
    }).then((createdLibrary) => {
      newLibrary = createdLibrary;

      return requester.fetch({
        baseURL: config.api.content,
        url: `/ContentLibraries/GrantFullAccess/Client/${clientId}/Library/${createdLibrary.id}`,
        method: 'post'
      });
    }).then(() => {
      return newLibrary;
    });
  },

  /**
   * categoriesByContentLibraryId request.
   *
   * @param {number} contentLibraryId
   * @returns {Promise}
   */
  categoriesByContentLibraryId: function categoriesByContentLibraryId(contentLibraryId) {
    return requester.fetch({
      baseURL: config.api.content,
      url: `/ContentLibraries/${contentLibraryId}/Categories`,
      method: 'get'
    }).then((foundCategories) => {
      if (!foundCategories || !foundCategories.length) {
        return foundCategories;
      }

      return foundCategories.reduce((activeCategories, foundCategory) => {
        if (foundCategory && !foundCategory.isDeleted) {
          activeCategories.push(foundCategory);
        }
        return activeCategories;
      }, []);
    });
  },

  /**
   * categoryCreate request
   *
   * @param {string} categoryName
   * @param {number} libraryId
   * @param {{parentCategoryId: number}=} extraProperties
   * @returns {Promise}
   */
  categoryCreate: function categoryCreate(categoryName, libraryId, extraProperties) {
    const safeExtraProperties = extraProperties || {};
    const safeData = {
      ...safeExtraProperties,
      categoryName,
      libraryId,
      isDeleted: false,
    };

    return requester.fetch({
      baseURL: config.api.content,
      url: 'ContentLibraryCategories',
      method: 'post',
      data: safeData,
      pascal: true
    });
  },

  /**
   * categoryDelete request
   *
   * @param {number} categoryId
   * @returns {Promise}
   */
  categoryDelete: function categoryDelete(categoryId) {
    const safeCategoryData = {
      isDeleted: true,
    };

    return requester.fetch({
      baseURL: config.api.content,
      url: `ContentLibraryCategories/${categoryId}/`,
      method: 'patch',
      data: safeCategoryData,
      pascal: true,
    });
  },

  /**
   * categoryPatchById request
   *
   * @param {number} categoryId
   * @param {{}} newCategoryData
   * @returns {Promise}
   */
  categoryPatchById: function categoryPatchById(categoryId, newCategoryData) {
    const safeCategoryData = {...newCategoryData};
    lodash.unset(safeCategoryData, 'id');

    return requester.fetch({
      baseURL: config.api.content,
      url: `ContentLibraryCategories/${categoryId}/`,
      method: 'patch',
      data: safeCategoryData,
      pascal: true,
    });
  },

  /**
   * contentsByCategoryId request.
   *
   * @param {number} categoryId
   * @param {number} page
   * @param {number} perPage
   * @returns {Promise}
   */
  contentsByCategoryId(categoryId, page, perPage) {
    let safePage = page || 1;
    if (safePage < 1) {
      safePage = 1;
    }

    let safePerPage = perPage || DEFAULT_PER_PAGE;
    if (safePerPage < 1) {
      safePerPage = DEFAULT_PER_PAGE;
    }

    return requester.fetch({
      baseURL: config.api.content,
      url: `/ContentLibraryCategories/${categoryId}/Content`,
      method: 'get',
      params: {
        pageNumber: safePage,
        pageSize: safePerPage,
      },
    }, {
      paginated: true, // Will change the response to contain the pagination data from the headers.
    });
  },

  /**
   * contentsByMultiCategoryIds request.
   *
   * @param {number[]} categoryIds
   * @param {{page: number, perPage: number, hideDeleted: boolean, editableOnly: boolean}=} options
   * @returns {Promise}
   */
  contentsByMultiCategoryIds(categoryIds, options) {
    const safeOptions = options || {};

    const safeCategoryIds = (categoryIds.forEach) ? categoryIds : [categoryIds];

    let safePage = safeOptions.page || 1;
    if (safePage < 1) {
      safePage = 1;
    }

    let safePerPage = safeOptions.perPage || DEFAULT_PER_PAGE;
    if (safePerPage < 1) {
      safePerPage = DEFAULT_PER_PAGE;
    }

    const params = {
      pageNumber: safePage,
      pageSize: safePerPage,
      includeUnsaved: false
    };

    if (safeOptions.hideDeleted) {
      params.hideDeleted = true;
    }
    if (safeOptions.editableOnly) {
      params.editableOnly = true;
    }

    return requester.fetch({
      baseURL: config.api.content,
      url: '/ContentLibraryCategories/ContentForCategories',
      method: 'post',
      data: safeCategoryIds,
      params,
    }, {
      paginated: true, // Will change the response to contain the pagination data from the headers.
    });
  },

  /**
   * Searches the content.
   *
   * @param {number} libraryId
   * @param {string} searchTerm
   * @param {{page: number, perPage: number, hideDeleted: boolean, editableOnly: boolean}=} options
   * @returns {Promise}
   */
  contentSearch(libraryId, searchTerm, options) {
    const safeOptions = options || {};
    const safeSearchTerm = encodeURI(String(searchTerm));

    let safePage = safeOptions.page || 1;
    if (safePage < 1) {
      safePage = 1;
    }

    let safePerPage = safeOptions.perPage || DEFAULT_PER_PAGE;
    if (safePerPage < 1) {
      safePerPage = DEFAULT_PER_PAGE;
    }

    const params = {
      pageNumber: safePage,
      pageSize: safePerPage,
    };

    if (safeOptions.hideDeleted) {
      params.hideDeleted = true;
    }
    if (safeOptions.editableOnly) {
      params.editableOnly = true;
    }

    return requester.fetch({
      baseURL: config.api.content,
      url: `/Content/SearchLibrary/${libraryId}/${safeSearchTerm}`,
      method: 'get',
      params,
    }, {
      paginated: true, // Will change the response to contain the pagination data from the headers.
    });
  },

  /**
   * contentsPatch request.
   *
   * @param {{}} content
   * @returns {Promise}
   */
  contentsPatch: function contentsPatch(content) {
    const contentId = content.id;
    const safeContent = {...content};
    lodash.unset(safeContent, 'id');

    return requester.fetch({
      baseURL: config.api.content,
      url: `/Content/${contentId}`,
      method: 'patch',
      data: safeContent,
      pascal: true,
    });
  },

  /**
   * contentsPost request.
   *
   * @param {{}} content
   * @returns {Promise}
   */
  contentsPost: function contentsPost(content) {
    const newContent = lodash.clone(content);
    lodash.unset(newContent, 'id');
    lodash.unset(newContent, 'contentChannelItems');
    lodash.unset(newContent, 'contentFiles');
    newContent.isDeleted = false;
    newContent.createDate = moment().toISOString();
    newContent.updateDate = newContent.createDate;

    return requester.fetch({
      baseURL: config.api.content,
      url: '/Content',
      method: 'post',
      data: newContent,
      pascal: true,
    });
  },

  /**
   * uploadContentFromFile request.
   *
   * Uploads multipart form in order to create a Content/ContentFile pair.
   *
   * @param {{}} file
   * @param {number} libraryType
   * @param {number} libraryId
   * @param {?number=} categoryId
   * @param {string=} title
   * @param {function=} onProgress
   * @param {function=} cancelable
   * @returns {Promise}
   */
  uploadContentFromFile(file, libraryType, libraryId, categoryId, title, onProgress, cancelable) {
    const data = new FormData();

    let resizeMethod = 'CENTER';
    if (lodash.includes(BACKGROUND_TYPES, libraryType)) {
      resizeMethod = 'STRETCH';
    }

    data.append('data', JSON.stringify(pascalCase({
      title: title || 'unknown',
      text: 'unknown',
      resizeMethod,
      targetLibraryId: libraryId,
      targetCategoryId: categoryId || null,
    })));
    data.append('file', file);

    const requestData = {
      baseURL: config.api.upload,
      headers: {'Content-Type': 'multipart/form-data'},
      method: 'post',
      data
    };

    if (onProgress) {
      requestData.onUploadProgress = onProgress;
    }
    if (cancelable) {
      requestData.cancelable = cancelable;
    }

    // We need to use request in order to support cancelable.
    return requester.request(requestData);
  },

  /**
   * contentFilesByContentId request.
   *
   * @param {number} contentId
   * @returns {Promise}
   */
  contentFilesByContentId: function contentFilesByContentId(contentId) {
    return requester.fetch({
      baseURL: config.api.content,
      url: `/Content/${contentId}/ContentFiles`,
      method: 'get'
    });
  },

  /**
   * contentFilesPatch request.
   *
   * @param {{}} contentFile
   * @returns {Promise}
   */
  contentFilesPatch: function contentFilesPatch(contentFile) {
    const contentFileId = contentFile.id;
    const safeContentFile = {...contentFile};
    lodash.unset(safeContentFile, 'id');

    return requester.fetch({
      baseURL: config.api.content,
      url: `/ContentFiles/${contentFileId}`,
      method: 'patch',
      data: safeContentFile,
      pascal: true,
    });
  },

  /**
   * contentFilesPost request.
   *
   * @param {{}} contentFile
   * @returns {Promise}
   */
  contentFilesPost: function contentFilesPost(contentFile) {
    const newContentFile = lodash.clone(contentFile);
    lodash.unset(newContentFile, 'id');
    newContentFile.isGlobal = false;

    return requester.fetch({
      baseURL: config.api.content,
      url: '/ContentFiles',
      method: 'post',
      data: newContentFile,
      pascal: true,
    });
  },

  /**
   * deleteContentById request.
   *
   * @param {number} contentId
   * @returns {Promise}
   */
  deleteContentById: function deleteContentById(contentId) {
    // We are doing a soft delete to make sure used content doesn't disappear.
    const safeContent = {
      id: contentId,
      isDeleted: true,
    };

    return requester.fetch({
      baseURL: config.api.content,
      url: `/Content/${contentId}`,
      method: 'patch',
      data: safeContent,
      pascal: true,
    });
  },

  /**
   * deleteContentFileById request.
   *
   * @param {number} contentFileId
   * @returns {Promise}
   */
  deleteContentFileById: function deleteContentFileById(contentFileId) {
    // We are doing a soft delete to make sure used content doesn't disappear.
    const safeContentFile = {
      id: contentFileId,
      isDeleted: true,
    };

    return requester.fetch({
      baseURL: config.api.content,
      url: `/ContentFiles/${contentFileId}`,
      method: 'patch',
      data: safeContentFile,
      pascal: true,
    });
  },

  /**
   * sourceRenderToVideo request.
   *
   * @param {{}} sourceJSON
   * @param {string} title
   * @param {{}} queueData
   * @param {{fromId: ?{}, fromApi: ?{}, fromCustom: ?{}}} feedData
   * @returns {Promise}
   */
  sourceRenderToVideo: function sourceRenderToVideo(sourceJSON, title, queueData, feedData) {
    const postData = {
      source: sourceJSON,
      title: String(title),
      queueData: pascalCase(queueData),
      feedData
    };

    return requester.fetch({
      baseURL: config.api.content,
      url: '/Renderer/Video',
      method: 'post',
      data: postData,
      pascal: false,
    });
  },

  /**
   * logCustomError request.
   *
   * @param {string} errorName
   * @param {string} errorDetail
   * @param {number} userId
   * @param {number} clientId
   * @param {boolean=} isRender
   * @returns {Promise}
   */
  logCustomError(errorName, errorDetail, userId, clientId, isRender) {
    const postData = {
      errorName,
      errorDetail,
      systemInfo: (isRender) ? 'Render Docker Image' : 'Front End',
      userId,
      clientId,
    };

    let baseURL = config.api.admin;
    if (isRender) {
      baseURL = baseURL.replace('https://', 'http://');
    }

    return requester.fetch({
      baseURL,
      url: '/ApplicationLog',
      method: 'post',
      data: postData,
      pascal: true,
    });
  },

  /**
   * productGetById request.
   *
   * @param {number} clientId
   * @param {bool} includeGlobals
   * @returns {Promise}
   */
  feedsGetByClientId: function feedsGetByClientId(clientId, includeGlobals) {
    const safeClientId = parseInt(clientId, 10);
    return requester.fetch({
      baseURL: config.api.content,
      url: `/Feeds/Summaries/Client/${safeClientId}`,
      method: 'get',
      params: {
        includeGlobals: Boolean(includeGlobals),
        feedItemCount: 5,
      }
    });
  },

  /**
   * feedGetByFeedId request.
   *
   * @param {number} feedId
   * @returns {Promise}
   */
  feedGetByFeedId: function feedGetByFeedId(feedId) {
    const safeFeedId = parseInt(feedId, 10);
    return requester.fetch({
      baseURL: config.api.content,
      url: `/Feeds/${safeFeedId}/Player/FeedItems`,
      method: 'get',
    });
  },
};

export default serverApi;
