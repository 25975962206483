import lodash from 'lodash';

import {loginRoute} from '../../components/routePaths';
import sessionStore from '../../stores/active/sessionStore';
import apiClientStore from '../../stores/api/apiClientStore';
import apiLoginStore from '../../stores/api/apiLoginStore';
import apiProductsStore from '../../stores/api/apiProductsStore';
import apiUserStore from '../../stores/api/apiUserStore';
import apiFeedSummaryStore from '../../stores/api/apiFeedSummaryStore';
import routerStore from '../../stores/active/routerStore';
import {getRedirectWithTo, routeHistory} from '../../utils/history';

/**
 * Forces a logout of the user.
 *
 * @param {boolean=} redirectWithTo If true, the login page will come back to the current location after login.
 */
export function logoutAction(redirectWithTo) {
  const currentPath = routeHistory.location.pathname;
  if (lodash.startsWith(currentPath, loginRoute)) {
    return;
  }

  // Unlock the any transition blocking.
  routerStore.unblockRouting();

  // Clear out all the stores.
  apiLoginStore.clearAll();
  apiUserStore.clearAll();
  apiClientStore.clearAll();
  apiFeedSummaryStore.clearAll();
  apiProductsStore.clearAll();
  sessionStore.clearAll();

  localStorage.clear();

  const toLoginPage = (redirectWithTo) ? getRedirectWithTo(loginRoute, currentPath) : loginRoute;
  routeHistory.push(toLoginPage);
}
