import lodash from 'lodash';
import markdownIt from 'markdown-it';

import {ALIGN_TOP} from '../../../components/controls/editText/constants/styleContants';
import {fontStylesPlugin} from '../../../utils/markdown/markdownStylesPlugin';
import {underlinePlugin} from '../../../utils/markdown/markdownUnderlinePlugin';

const markdownParser = markdownIt({
  html: false,
  xhtmlOut: true,
  breaks: false,
});

// Add the markdown-it plugins.
markdownParser.underlinePluginIsInitialized = false;
markdownParser.use(fontStylesPlugin);
markdownParser.use(underlinePlugin);

/**
 * The text component.
 *
 * @param {{}} feedTextValues
 * @param {string=} feedTextValues.value
 * @param {boolean=} feedTextValues.isHtml
 * @param {string=} feedTextValues.rawValue
 * @param {string=} feedTextValues.source
 * @param {number=} feedTextValues.repeatCount
 * @param {string=} feedTextValues.format
 * @param {string=} feedTextValues.verticalAlign
 * @param {{}=} importComponent
 * @returns {{feedText: {isHtml: boolean, rawValue: string, value: string, source: string, verticalAlign: string}}}
 */
export function feedTextComponent(feedTextValues, importComponent) {
  const newValues = lodash.defaults(feedTextValues || {}, lodash.pickBy(importComponent || {}));

  const parseToString = (item) => {
    return String(item || '');
  };

  const repeatCount = parseInt(newValues.repeatCount, 10) || 0;

  return {
    feedText: {
      isHtml: Boolean(newValues.isHtml),
      rawValue: newValues.rawValue,
      value: newValues.value,
      source: parseToString(newValues.source),
      repeatCount: (newValues.source) ? repeatCount : 0,
      format: parseToString(newValues.format),
      verticalAlign: newValues.verticalAlign || ALIGN_TOP,
    },
  };
}

/**
 * Gets the text component from the source item.
 *
 * @param {{feedText: {plaintext: ?string, markdown: ?string, source: string}}} item
 * @returns {{feedText: {isHtml: boolean, value: string, source: string}}}
 */
export function getFeedTextFromSource(item) {
  if (!item.feedText) {
    return {};
  }

  const text = item.feedText;

  let finalValue = text.plaintext || '';
  if (text.markdown) {
    finalValue = parseMarkdownToHtml(text.markdown);
  }

  return feedTextComponent({
    value: finalValue,
    isHtml: Boolean(text.markdown),
    rawValue: text.markdown || text.plaintext,
    source: text.source,
    format: text.format,
    repeatCount: text.repeatCount || 0,
    verticalAlign: text.verticalAlign || ALIGN_TOP,
  });
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getFeedTextForSource(entity) {
  if (!entity.has('feedText')) {
    return {};
  }

  const text = entity.get('feedText');
  const output = {
    source: text.source,
    verticalAlign: text.verticalAlign,
  };

  if (text.isHtml) {
    output.markdown = text.rawValue;
  } else {
    output.plaintext = text.value;
  }

  if (text.repeatCount) {
    output.repeatCount = text.repeatCount;
  }
  if (text.format) {
    output.format = text.format;
  }

  return {
    feedText: output,
  };
}

/**
 * Parses the given text from markdown to HTML.
 *
 * @param {string} markdownText
 * @returns {string}
 */
export function parseMarkdownToHtml(markdownText) {
  const html = markdownParser.render(markdownText);
  return lodash.trim(html).replace(/<p/g, '<div').replace(/<\/p/g, '</div');
}

/**
 * Replaces placeholder string with content
 *
 * @param {{}} component
 * @param {string} content
 * @returns {string}
 */
export function makeContentText(component, content) {
  if (!component) {
    return null;
  }

  let base;
  if (component.isHtml) {
    base = component.value;
  } else {
    base = component.rawValue || component.markdown;
  }

  if (!base) {
    return null;
  }

  return base.replace('[[content]]', content || 'Select Feed Source');
}
