import lodash from 'lodash';
import {action, observable, when} from 'mobx';

import apiContentsStore from '../apiContentsStore';
import apiContentSearchStore from '../apiContentSearchStore';
import editorGetContentStore from '../../editor/editorGetContentStore';
import {STATE_FULFILLED, STATE_PENDING, STATE_PRE, STATE_REJECTED} from '../../../constants/asyncConstants';
import {POLLING_CONTINUES} from '../../../constants/contentConstants';
import {getCase} from '../../../utils/apiStore';
import serverApi from '../../../utils/serverApi';

/**
 * The amount of time between polling calls.
 * @const {number[]}
 */
const POLLING_INTERVALS = [
  // eslint-disable-next-line no-magic-numbers
  10000
];

/**
 * The number of errors that will cancel a poll.
 * @const {number}
 */
const CANCEL_ERROR_COUNT = 4;

/**
 * The maximum number of times polling will occur.
 * @const {number}
 */
const MAX_POLL_COUNT = 180;

/**
 * The PollContentStore store.
 */
class PollContentStore {
  /**
   * The map of each content poll.
   *
   * @type {ObservableMap<string, {
   *   state: Symbol,
   *   count: number,
   *   content: {},
   *   errorCount: number,
   *   error: ?Error
   * }>}
   */
  @observable pollByContentId = observable.map();

  /**
   * The map of ids that can be used to cancel the active polling.
   *
   * @type {Object.<number, number>}
   */
  cancelTimeoutIds = {};

  /**
   * Clears all the resource info
   */
  @action clearAll() {
    lodash.forEach(this.cancelTimeoutIds, (timeoutId) => {
      clearTimeout(timeoutId);
    });

    this.pollByContentId.clear();
  }

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @param {number} contentId
   * @returns {?Array.<{}>}
   */
  getFulfilled(contentId) {
    const safeContentId = String(contentId);

    return this.pollByContentId.get(safeContentId);
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @param {number} contentId
   * @returns {?Error}
   */
  getRejected(contentId) {
    const safeContentId = String(contentId);
    const foundResource = this.pollByContentId.get(safeContentId);
    if (!foundResource || !foundResource.error) {
      return null;
    }
    return foundResource.error;
  }

  /**
   * Cancels any active polling for the given content id.
   *
   * @param {number} contentId
   */
  cancelPollForContent(contentId) {
    const safeContentId = String(contentId);

    const timeoutId = this.cancelTimeoutIds[safeContentId];
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    this.pollByContentId.delete(safeContentId);
  }

  /**
   * Gets the next poll interval.
   *
   * @param {number} currentCount
   * @returns {number}
   */
  getNextPollInterval = (currentCount) => {
    const safeCount = Number(currentCount);

    if (!currentCount || !safeCount) {
      return POLLING_INTERVALS[0];
    } else if (POLLING_INTERVALS[safeCount]) {
      return POLLING_INTERVALS[safeCount];
    }

    return lodash.last(POLLING_INTERVALS);
  };

  /**
   * Checks if content is already being polled.
   *
   * @param {number} contentId
   * @returns {boolean}
   */
  @action isPollingInProgress(contentId) {
    const safeContentId = String(contentId);

    const currentResource = this.pollByContentId.get(safeContentId);
    return (currentResource && currentResource.state === STATE_PENDING);
  }

  /**
   * Polls content from the server until the enters a good file state.
   *
   * @param {number} contentId
   */
  @action pollContentById(contentId) {
    const safeContentId = String(contentId);

    if (this.isPollingInProgress(safeContentId)) {
      return;
    }

    this.pollByContentId.set(safeContentId, {
      state: STATE_PENDING,
      count: 0,
      content: null,
      errorCount: 0,
      error: null,
      successError: false,
    });

    this.cancelTimeoutIds[safeContentId] = setTimeout(() => {
      this.pollingLoop(safeContentId);
    }, this.getNextPollInterval(0));
  }

  /**
   * Starts or continues a polling loop.
   *
   * @param {number} contentId
   */
  @action pollingLoop(contentId) {
    serverApi.contentFilesByContentId(contentId).then(
      action('getContentSuccess', (foundContentFiles) => {
        const firstContentFile = lodash.get(foundContentFiles, '[0]', {});

        const currentPoll = this.pollByContentId.get(contentId);
        const newCount = currentPoll.count + 1;

        const stateId = lodash.get(firstContentFile, 'contentFileStateId');
        if (stateId && !lodash.includes(POLLING_CONTINUES, stateId)) {
          delete this.cancelTimeoutIds[contentId];

          this.endPollingSuccess(contentId, foundContentFiles, newCount);
          return;
        }

        if (newCount >= MAX_POLL_COUNT) {
          delete this.cancelTimeoutIds[contentId];

          this.pollByContentId.set(contentId, {
            ...currentPoll,
            state: STATE_REJECTED,
            count: newCount,
            error: new Error('Polling max count reached.'),
          });
          return;
        }

        this.pollByContentId.set(contentId, {
          ...currentPoll,
          count: newCount,
        });

        this.cancelTimeoutIds[contentId] = setTimeout(() => {
          this.pollingLoop(contentId);
        }, this.getNextPollInterval(newCount));
      }),
      action('getContentError', (error) => {
        const currentPoll = this.pollByContentId.get(contentId);
        const newCount = currentPoll.errorCount + 1;

        if (newCount >= CANCEL_ERROR_COUNT) {
          delete this.cancelTimeoutIds[contentId];

          this.pollByContentId.set(contentId, {
            ...currentPoll,
            state: STATE_REJECTED,
            errorCount: newCount,
            error,
          });

          return;
        }

        this.pollByContentId.set(contentId, {
          ...currentPoll,
          state: STATE_REJECTED,
          errorCount: newCount,
          error,
        });

        this.cancelTimeoutIds[contentId] = setTimeout(() => {
          this.pollingLoop(contentId);
        }, this.getNextPollInterval(currentPoll.count));
      })
    );
  }

  /**
   * Finishes a polling as a success.
   *
   * @param {number} contentId
   * @param {Array.<{}>} contentFiles
   * @param {number} newCount
   */
  @action endPollingSuccess(contentId, contentFiles, newCount) {
    const currentPoll = this.pollByContentId.get(contentId);

    serverApi.contentGetById(contentId).then(
      action('getContentSuccess', (foundContent) => {
        const content = {...foundContent};
        content.contentFiles = contentFiles || [];

        this.pollByContentId.set(contentId, {
          ...currentPoll,
          state: STATE_FULFILLED,
          count: newCount,
          content: content,
          error: null,
        });
      }),
      action('getContentError', (error) => {
        this.pollByContentId.set(contentId, {
          ...currentPoll,
          state: STATE_REJECTED,
          content: null,
          error,
          successError: true,
        });
      })
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {number} contentId
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(contentId, handlers) {
    const safeContentId = String(contentId);

    const getFulfilled = () => {
      return this.getFulfilled(safeContentId);
    };
    const getRejected = () => {
      return this.getRejected(safeContentId);
    };

    let state = STATE_PRE;
    if (this.pollByContentId.has(safeContentId)) {
      state = this.pollByContentId.get(safeContentId).state;
    }

    return getCase(state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for this store.
   *
   * @param {string} contentId
   * @returns {Promise}
   */
  getPromise(contentId) {
    const safeContentId = String(contentId);

    const thisStore = this;

    return new Promise((resolve, reject) => {
      when(
        () => {
          const content = thisStore.pollByContentId.get(safeContentId);
          const state = (content) ? content.state : null;

          return (state === STATE_FULFILLED || state === STATE_REJECTED);
        },
        () => {
          const state = thisStore.pollByContentId.get(safeContentId).state;
          if (state === STATE_REJECTED) {
            reject(this.getRejected(safeContentId));
            return;
          }

          resolve(this.getFulfilled(safeContentId));
        },
        {name: 'apiPollContentStoreGetPromise'}
      );
    });
  }

  /**
   * Polls content and updates it in the other stores when it is finished processing.
   *
   * @param {number} contentId
   * @param {Object.<string, {key: string, filters: {}}>=} pollKeys
   */
  pollThenUpdateContent(contentId, pollKeys) {
    if (!pollKeys) {
      throw new Error('Polling requires poll keys in order to update content.');
    }

    this.pollContentById(contentId, pollKeys);

    this.getPromise(contentId).then((pollResults) => {
      const {content} = pollResults;
      if (!content) {
        return;
      }

      editorGetContentStore.expireCacheForContent(contentId);

      if (lodash.get(pollKeys, 'apiContentsStore.key')) {
        apiContentsStore.replaceContent(pollKeys.apiContentsStore, content);
      }
      if (lodash.get(pollKeys, 'apiContentSearchStore.key')) {
        apiContentSearchStore.replaceContent(pollKeys.apiContentSearchStore, content);
      }
    }).catch(() => {
      // We will just ignore polling errors.
    });
  }

  /**
   * Fetches whether or not a piece of content is currently in use.
   *
   * @param {number} contentId
   * @returns {Promise}
   */
  @action fetchContentUsageById(contentId) {
    return serverApi.contentInUse(contentId);
  }
}

export default new PollContentStore();
