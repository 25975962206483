import lodash from 'lodash';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import EditTimelineControls from '../editTimeline/EditTimelineControls';
import {actionUpdateComponent} from '../../../display/components/action/actionUpdateComponent';
import {circleComponent} from '../../../display/components/type/circleComponent';

import ColorSelector from '../../common/colorSelector/ColorSelector';
import ThicknessSelector from '../../common/thicknessSelector/ThicknessSelector';

import './editCircleControls.scss';

/**
 * The EditCircleControls component.
 */
export class EditCircleControls extends React.Component {
  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);
  }

  /**
   * Updates the circle entity when it is changed.
   *
   * @param {{
   *   color: string,
   *   opacity: number,
   *   borderColor: string,
   *   borderOpacity: number,
   *   borderWidth: number
   * }} changes
   */
  onChangeCircle = (changes) => {
    const {entity, game} = this.props;

    const currentCircle = entity.get('circle');

    const actionParams = {
      entityId: entity.get('id'),
    };

    const styleUpdates = lodash.defaults({
      color: changes.color,
      opacity: changes.opacity,
    }, currentCircle.style || {});

    const borderUpdates = lodash.defaults({
      color: changes.borderColor,
      opacity: changes.borderOpacity,
      width: changes.borderWidth,
    }, currentCircle.border || {});

    game.addAction(actionParams, actionUpdateComponent(
      circleComponent(
        styleUpdates,
        borderUpdates,
      )
    ));
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;

    const circle = entity.get('circle');
    const {border, style} = circle;

    return (
      <div className="edit-circle-controls">
        <div className="circle-group">
          <h5 className="sidebar-title">Circle</h5>
          <div className="group-header">
            <span className="group-header-label">Style</span>
          </div>
          <div className="group-controls">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="circle-color-input">Fill</label>
                  <ColorSelector
                    id="circle-color-input"
                    colorValue={style.color}
                    opacityValue={style.opacity}
                    onChangeColor={(color, opacity) => this.onChangeCircle({color, opacity})}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="circle-border-color-input">Border</label>
                  <ColorSelector
                    id="circle-border-color-input"
                    colorValue={border.color}
                    opacityValue={border.opacity}
                    onChangeColor={(color, opacity) => this.onChangeCircle({
                      borderColor: color,
                      borderOpacity: opacity
                    })}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="circle-border-width-input">Width</label>
                  <ThicknessSelector
                    id="circle-border-width-input"
                    allowZero={true}
                    thicknessValue={border.width}
                    onChangeThickness={(width) => this.onChangeCircle({borderWidth: width})}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <EditTimelineControls entity={entity} game={game} />
      </div>
    );
  }
}

EditCircleControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditCircleControls);
