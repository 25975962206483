import {action} from 'mobx';

import {STATE_FULFILLED, STATE_REJECTED} from '../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN} from '../../constants/storeConstants';
import serverApi from '../../utils/serverApi';
import SingleResourceBaseStore from './common/apiSingleResourceBaseStore';

/**
 * The user store.
 */
class UserStore extends SingleResourceBaseStore {
  /**
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Fetches user from the server.
   *
   * @param {number} rawUserId
   */
  @action fetchUserById(rawUserId) {
    const userId = String(rawUserId);

    if (!this.isResourceAvailable(userId)) {
      serverApi.userGetById(userId).then(
        action('getUserSuccess', (user) => {
          this.resourcesById.set(userId, {
            [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
            resource: user,
            error: null,
          });
          this.state = STATE_FULFILLED;
        }),
        action('getUserError', (error) => {
          this.resourcesById.set(userId, {
            [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
            resource: null,
            error,
          });
          this.state = STATE_REJECTED;
        })
      );
    }
  }
}

export default new UserStore();
