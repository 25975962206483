import lodash from 'lodash';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import EditTimeControl from '../editTime/EditTimeControl';
import EditTransitionControl from '../editTransition/EditTransitionControl';

import './editTimelineControls.scss';

/**
 * The EditTimelineControls component.
 */
export class EditTimelineControls extends React.Component {
  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;

    // If the game does not have a timeline, then always hide these control.
    if (!game.hasTimeLine()) {
      return null;
    }

    let isLocked = false;
    if (entity.has('locked')) {
      const entityLock = entity.get('locked');
      if (lodash.includes(entityLock, 'time')) {
        // If the entity has time locked, then do not allow them to adjust anything.
        isLocked = true;
      }
    }

    return (
      <div className="edit-timeline-controls control-group">
        <div className="group-header">
          <span className="group-header-label">Transitions</span>
        </div>

        {(isLocked) ? (
          <div className="can-not-edit">
            <span className="locked-text">
              <i className="fa fa-lock" />
              {' '}
              <span>Time Locked</span>
            </span>
          </div>
        ) : (
          <div className="group-controls">
            <div className="row">
              <div className="col">
                <EditTimeControl entity={entity} game={game} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <EditTransitionControl entity={entity} game={game} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

EditTimelineControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditTimelineControls);
