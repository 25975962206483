import lodash from 'lodash';
import {observable, action, extendObservable} from 'mobx';

import apiContentsStore from '../apiContentsStore';
import apiContentSearchStore from '../apiContentSearchStore';
import sessionStore from '../../active/sessionStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {CONTENT_TYPES} from '../../../constants/libraryTypeConstants';
import {apiStore, getCase} from '../../../utils/apiStore';
import serverApi from '../../../utils/serverApi';

/**
 * Manages Copy Content flow
 */
class ContentCopyStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * The content id of the moved/copied content.
   * @type {?number}
   */
  @observable contentId = null;

  /**
   * Error
   * @type {?Error}
   */
  @observable error = null;

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {?number}
   */
  getFulfilled() {
    return this.contentId;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    return this.error;
  }

  /**
   * Sets the contentId of the store.
   *
   * @param {?number} contentId
   */
  @action setContentId(contentId) {
    this.contentId = (contentId) ? Number(contentId) : null;
  }

  /**
   * Copy selected content to the specified Category
   *
   * @param {{id: number, categoryId: number, contentFiles: Array}} content
   * @param {{categoryId: number, libraryId: number}} destination
   * @param {number} libraryType
   * @param {boolean} isGlobal
   * @returns {Promise}
   */
  @action copy(content, destination, libraryType, isGlobal) {
    this.setContentId(null);
    this.state = STATE_PENDING;

    const userId = sessionStore.userId || null;
    const firstContentFile = lodash.get(content, 'contentFiles[0]');
    if (!firstContentFile) {
      return Promise.reject(new Error('Could not copy content as it has no content file record.'));
    } else if (!firstContentFile.create24ProjectJson) {
      return Promise.reject(new Error('Could not copy content as its content file record has no source JSON.'));
    }

    const newContent = {
      ...content,
      categoryId: destination.categoryId,
      libraryId: destination.libraryId,
      contentFiles: [],
      isEditable: true,
      isGlobal: Boolean(isGlobal),
      createdByUserId: userId,
      updatedByUserId: userId,
    };

    if (!content.contentTypeId) {
      newContent.contentTypeId = CONTENT_TYPES[libraryType];

      if (!newContent.contentTypeId) {
        this.error = new Error('No Content Type ID was set while trying to copy this content.');
        this.state = STATE_REJECTED;
        return Promise.reject(this.error);
      }
    }

    let newContentId = null;

    return serverApi.contentsPost(newContent).then(
      action('copyContentSuccess', (copyContentResponse) => {
        newContentId = copyContentResponse.id;
        this.setContentId(newContentId);

        const newContentFile = {
          ...firstContentFile,
          contentId: newContentId,
        };

        return serverApi.contentFilesPost(newContentFile);
      })
    ).then(
      action('copyContentFileSuccess', () => {
        const filters = {
          categoryId: destination.categoryId,
          libraryId: destination.libraryId,
        };

        apiContentsStore.refreshCurrentContents(filters);
        apiContentSearchStore.expireCacheForLibrary(destination.libraryId);

        this.error = null;
        this.state = STATE_FULFILLED;
      }),
      action('copyError', (copyError) => {
        this.error = copyError;
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => {
      return this.getFulfilled();
    };
    const getRejected = () => {
      return this.getRejected();
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }
}

export default new ContentCopyStore();
