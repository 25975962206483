import lodash from 'lodash';
import {action, observable, runInAction, when} from 'mobx';

import {STATE_FULFILLED, STATE_PENDING, STATE_PRE, STATE_REJECTED} from '../../constants/asyncConstants';
import {EDITOR_NEW_VIDEO, EDITOR_NEW_ALL, EDITOR_NEW_COPY} from '../../constants/editorConstants';
import {CREATED_CONTENT} from '../../constants/libraryTypeConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../constants/storeConstants';
import {getCase} from '../../utils/apiStore';
import {getEditableContentRecord} from '../../utils/contentsHelper';
import {toJSRecursive} from '../../utils/mobxHelper';
import serverApi from '../../utils/serverApi';

/**
 * The EditorGetContentStore store.
 */
class EditorGetContentStore {
  /**
   * The map of each page of resources.
   *
   * @type {ObservableMap<string, {
   *   expireTime: number,
   *   state: Symbol,
   *   content: ?ObservableArray,
   *   library: ?ObservableArray,
   *   error: ?Error
   * }>}
   */
  @observable contentsById = observable.map();

  /**
   * The map of products assigned to contentIds.
   * This is used when created new content in the editor.
   *
   * @type {ObservableMap<string, {widthPx: number, heightPx: number}>}
   */
  @observable productsById = observable.map();

  /**
   * Clears all the resource info
   */
  @action clearAll() {
    this.contentsById.clear();
    this.productsById.clear();
  }

  /**
   * Gets content that was already fetched by its id.
   *
   * @param {number} contentId
   * @returns {{content: {}, library: {}, product: ?{}}}
   */
  getContentById(contentId) {
    const safeContentId = String(contentId);
    const foundContent = this.contentsById.get(safeContentId);
    if (!foundContent) {
      return {
        content: null,
        library: null,
      };
    }

    const foundProduct = this.productsById.get(safeContentId);

    return {
      content: foundContent.content || null,
      library: foundContent.library || null,
      product: foundProduct || null,
    };
  }

  /**
   * Gets the source for the content.
   *
   * @param {number} contentId
   * @returns {?{}}
   */
  getSource(contentId) {
    const safeContentId = String(contentId);

    const {content} = this.getContentById(safeContentId);

    return this.parseSourceFromContent(content);
  }

  /**
   * Parses the source from the given content's project JSON.
   *
   * @param {{}} content
   * @returns {?{}}
   */
  parseSourceFromContent(content) {
    const projectJson = lodash.get(content, 'contentFiles[0].create24ProjectJson');

    if (!projectJson) {
      return null;
    }

    try {
      return JSON.parse(projectJson);
    } catch (error) {
      return null;
    }
  }

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @param {number} contentId
   * @returns {?Array.<{}>}
   */
  getFulfilled(contentId) {
    return this.getContentById(contentId);
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @param {number} contentId
   * @returns {?Error}
   */
  getRejected(contentId) {
    const safeContentId = String(contentId);
    const foundResource = this.contentsById.get(safeContentId);
    if (!foundResource || !foundResource.error) {
      return null;
    }
    return foundResource.error;
  }

  /**
   * Expires the cache for the given content id.
   *
   * @param {number} contentId
   */
  @action expireCacheForContent(contentId) {
    const safeContentId = String(contentId);

    this.contentsById.delete(safeContentId);
    this.productsById.delete(safeContentId);
  }

  /**
   * Sets the product for the given content id.
   *
   * @param {number|string} contentId
   * @param {{widthPx: number, heightPx: number}} product
   */
  @action setProductForContent(contentId, product) {
    const safeContentId = String(contentId);

    this.productsById.set(safeContentId, product);
  }

  /**
   * Checks if content is already available via unexpired cache.
   *
   * @param {number} contentId
   * @returns {boolean}
   */
  @action isContentAvailable(contentId) {
    const safeContentId = String(contentId);

    const currentResource = this.contentsById.get(safeContentId);
    if (currentResource && currentResource.state === STATE_PENDING) {
      return true;
    }

    if (currentResource && currentResource[EXPIRE_TIME] <= Date.now()) {
      this.contentsById.delete(safeContentId);
    } else if (currentResource && currentResource.state !== STATE_REJECTED) {
      return true;
    }

    return false;
  }

  /**
   * Fetches the given content id from the server.
   *
   * @private
   * @param {string|number} contentId
   * @returns {Promise<{id: number, contentFiles: Array}>}
   */
  _getContentFromServer(contentId) {
    const safeContentId = String(contentId);

    let content = null;
    return serverApi.contentGetById(
      safeContentId
    ).then((foundContent) => {
      content = {...foundContent};

      return serverApi.contentFilesByContentId(content.id);
    }).then((foundContentFiles) => {
      content.contentFiles = foundContentFiles || [];

      return content;
    });
  }

  /**
   * Fetches content from the server.
   *
   * @param {number} contentId
   */
  @action fetchContentById(contentId) {
    const safeContentId = String(contentId);

    if (this.isContentAvailable(safeContentId)) {
      return;
    }

    this.contentsById.set(safeContentId, {
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      state: STATE_PENDING,
      content: null,
      library: null,
      error: null,
    });

    let content = null;
    let library = null;
    this._getContentFromServer(
      safeContentId
    ).then((foundContent) => {
      content = {...foundContent};

      return serverApi.libraryGetById(content.libraryId);
    }).then((foundLibrary) => {
      library = foundLibrary;
    }).then(
      action('getContentSuccess', () => {
        this.contentsById.set(safeContentId, {
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          state: STATE_FULFILLED,
          content: content,
          library: library,
          error: null,
        });
      }),
      action('getContentError', (error) => {
        this.contentsById.set(safeContentId, {
          [EXPIRE_TIME]: null,
          state: STATE_REJECTED,
          content: null,
          library: null,
          error,
        });
      })
    );
  }

  /**
   * Sets the content for the given key to an error.
   *
   * @param {string} key
   * @param {Error} contentError
   * @param {boolean=} skipThrow
   * @returns {Error}
   * @throws {Error} If skipThrow is not set to true.
   */
  @action setContentError(key, contentError, skipThrow) {
    const safeKey = String(key);

    this.contentsById.set(safeKey, {
      [EXPIRE_TIME]: null,
      state: STATE_REJECTED,
      content: null,
      library: null,
      error: contentError,
    });

    if (!skipThrow) {
      throw contentError;
    }

    return contentError;
  }

  /**
   * Sets new content.
   *
   * @param {string} contentType - Must be an EDITOR_NEW_* constant.
   * @param {(number|string)=} fromContentId
   * @param {{widthPx: number, heightPx: number}} product
   * @param {{isLayout: boolean}} extraData
   */
  async setNewContent(contentType, fromContentId, product, extraData) {
    const safeType = String(contentType);
    const safeExtraData = extraData || {};

    runInAction(() => {
      this.contentsById.set(safeType, {
        [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
        state: STATE_PENDING,
        content: null,
        library: null,
        error: null,
      });
    });

    if (!lodash.includes(EDITOR_NEW_ALL, safeType)) {
      this.setContentError(safeType, new Error(
        'EditorGetContentStore: SetNewContent did not recognize the content type given.'
      ));
    }

    let fromContent = null;
    if (fromContentId) {
      fromContent = await this._getContentFromServer(fromContentId);

      if (!fromContent || !lodash.get(fromContent, 'contentFiles[0]')) {
        this.setContentError(safeType, new Error(
          'EditorGetContentStore: SetNewContent could not find content or content files.'
        ));
      }
    }

    let source;
    if (safeType === EDITOR_NEW_COPY) {
      source = this.parseSourceFromContent(fromContent);
    }

    let isImage = true;
    if (safeType === EDITOR_NEW_VIDEO) {
      isImage = false;
    } else if (source && source.type === 'video') {
      isImage = false;
    }

    let contentName = (isImage) ? 'New Image Project' : 'New Video Project';
    if (safeType === EDITOR_NEW_COPY && fromContent) {
      contentName = `Copy of ${fromContent.title}`;
    } else if (safeExtraData.isLayout) {
      contentName = 'New Layout Project';
    }

    const newLibrary = {
      id: null,
      contentLibraryTypeId: CREATED_CONTENT,
    };

    const safeCreateData = {
      categoryId: null,
      directory: null,
      libraryId: null,
      libraryType: newLibrary.contentLibraryTypeId,
      contentName,
      isImage,
      isLayout: Boolean(safeExtraData.isLayout),
      source,
    };

    const newContent = getEditableContentRecord(safeCreateData, product, fromContent);
    newContent.id = safeType;

    const delay = 500;

    // We have to slightly delay the promise or the display size won't load correctly.
    setTimeout(action('editorGetContentStoreSetNewContent', () => {
      this.contentsById.set(safeType, {
        [EXPIRE_TIME]: null,
        state: STATE_FULFILLED,
        content: newContent,
        library: newLibrary,
        error: null,
      });
    }), delay);
  }

  /**
   * Updates a new content record with the chosen library, category, etc.
   *
   * @param {string} contentType
   * @param {{
   *   library: {defaultDirectory: string},
   *   categoryId: number,
   *   libraryId: number,
   *   contentName: string,
   *   status: string,
   * }} contentData
   * @throws {Error} If no content is defined for the given contentType.
   * @throws {Error} If no content file in the content.
   */
  updateNewContent(contentType, contentData) {
    const safeType = String(contentType);

    const foundContent = toJSRecursive(
      this.contentsById.get(safeType)
    );

    if (!foundContent.content) {
      this.setContentError(safeType, new Error(
        'Could not update content because no content was found.'
      ));
    }

    const newContent = lodash.cloneDeep(foundContent.content);
    const contentFile = lodash.get(newContent, 'contentFiles[0]');

    if (!contentFile) {
      this.setContentError(safeType, new Error(
        'Could not update content directory because no content file was found.'
      ));
    }

    const directory = lodash.get(contentData, 'library.defaultDirectory');
    if (directory) {
      contentFile.directory = directory;
    }
    if (contentData.status) {
      contentFile.contentFileStateId = contentData.status;
    }

    if (contentData.contentName) {
      newContent.title = contentData.contentName;
    }
    if (contentData.libraryId) {
      newContent.libraryId = contentData.libraryId;
    }
    if (contentData.categoryId) {
      newContent.categoryId = contentData.categoryId;
    }

    runInAction('editorGetContentStoreUpdateNewContent', () => {
      this.contentsById.set(safeType, {
        [EXPIRE_TIME]: null,
        state: STATE_FULFILLED,
        content: newContent,
        library: contentData.library || {},
        error: null,
      });
    });
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {number} contentId
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(contentId, handlers) {
    const safeContentId = String(contentId);

    const getFulfilled = () => {
      return this.getFulfilled(safeContentId);
    };
    const getRejected = () => {
      return this.getRejected(safeContentId);
    };

    let state = STATE_PRE;
    if (this.contentsById.has(safeContentId)) {
      state = this.contentsById.get(safeContentId).state;
    }

    return getCase(state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for this store.
   *
   * @param {string} contentId
   * @returns {Promise}
   */
  getPromise(contentId) {
    const safeContentId = String(contentId);

    const thisStore = this;

    return new Promise((resolve, reject) => {
      when(
        () => {
          const content = thisStore.contentsById.get(safeContentId);
          const state = (content) ? content.state : null;

          return (state === STATE_FULFILLED || state === STATE_REJECTED);
        },
        () => {
          const state = thisStore.contentsById.get(safeContentId).state;
          if (state === STATE_REJECTED) {
            reject(this.getRejected(safeContentId));
            return;
          }

          resolve(this.getFulfilled(safeContentId));
        },
        {name: 'editorGetContentStoreGetPromise'}
      );
    });
  }
}

export default new EditorGetContentStore();
