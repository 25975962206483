import lodash from 'lodash';
import {observe} from 'mobx';

import {loginRoute, productSelectionRoute} from '../components/routePaths';
import routerStore from '../stores/active/routerStore';
import sessionStore from '../stores/active/sessionStore';

/**
 * The history object.
 *
 * @type {?{}}
 */
export let routeHistory = {
  push: function dummyHistoryPush() {
    throw new Error('The routeHistory object has not yet been registered.');
  }
};

/**
 * Syncs the history with the router store.
 *
 * @param {{}} theHistory
 * @returns {{}}
 */
export const syncHistoryWithStore = (theHistory) => {
  // Initialise store
  routerStore.history = theHistory;

  // Handle update from history object
  const handleLocationChange = (newLocation) => {
    routerStore._setLocation(newLocation);
  };

  const unsubscribeFromHistory = theHistory.listen(handleLocationChange);
  handleLocationChange(theHistory.location);

  theHistory.subscribe = (listener) => {
    const onStoreChange = () => {
      const rawLocation = {...routerStore.location};
      listener(rawLocation, theHistory.action);
    };

    // Listen for changes to location state in store
    const unsubscribeFromStore = observe(routerStore, 'location', onStoreChange);

    listener(routerStore.location, theHistory.action);

    return unsubscribeFromStore;
  };

  theHistory.unsubscribe = unsubscribeFromHistory;

  return theHistory;
};

/**
 * Registers a new routeHistory object.
 *
 * @param {{}} newHistory
 */
export function registerHistory(newHistory) {
  routeHistory = newHistory;

  syncHistoryWithStore(newHistory);
}

/**
 * Attaches a listener to the router history.
 */
export function attachListenerToHistory() {
  if (!routeHistory) {
    throw new Error('RouteHistory must be defined before you can attach listeners to it.');
  }

  routeHistory.listen(loginToProductSelectionIfLoggedIn);
  loginToProductSelectionIfLoggedIn(routeHistory.location);
}

/**
 * Forces the user back into the site if they hit the login page while logged in.
 *
 * @param {{pathname: string}} newLocation
 */
function loginToProductSelectionIfLoggedIn(newLocation) {
  if (!lodash.startsWith(newLocation.pathname, loginRoute)) {
    return;
  }

  if (sessionStore.userId) {
    redirect(productSelectionRoute);
  }
}

/**
 * Blocks navigation away from the page.
 *
 * @param {function} callback
 * @returns {function} Unblocks the transition.
 */
export function blockTransition(callback) {
  if (!routeHistory.block) {
    throw new Error('RouteHistory must be defined before you can block transitions.');
  }

  return routeHistory.block(callback);
}

/**
 * Whether or not the match route (or current route) matches one or more of the given routes.
 *
 * @param {string|string[]} otherRoutes
 * @param {?string} matchRoute
 * @returns {boolean}
 */
export function routeMatches(otherRoutes, matchRoute) {
  let safeOtherRoutes = otherRoutes;
  if (!Array.isArray(otherRoutes)) {
    safeOtherRoutes = [otherRoutes];
  }

  let safeMatchRoute = matchRoute;
  if (!matchRoute) {
    if (!routeHistory || !routeHistory.location) {
      throw new Error('Could not match route as no routeHistory location is defined.');
    }
    safeMatchRoute = routeHistory.location.pathname;
  }

  return lodash.some(safeOtherRoutes, (otherRoute) => {
    const hasParams = (otherRoute.indexOf(':') > -1);
    if (!hasParams) {
      return lodash.startsWith(safeMatchRoute, otherRoute);
    }

    const routeToRegexp = otherRoute.replace(/\/:[^/]+\?/ig, '/?[^/]*').replace(/:[^/]+/ig, '[^/]+');
    const otherRouteRegexp = new RegExp(routeToRegexp);
    return safeMatchRoute.match(otherRouteRegexp);
  });
}

/**
 * Redirects to the given route unless the `to` search param is set.
 *
 * @param {string} toRoute
 * @param {boolean} replaceIfTo
 */
export function redirect(toRoute, replaceIfTo) {
  const historyMethodName = (replaceIfTo && currentPathHasTo()) ? 'replace' : 'push';
  const routeTo = routeHistory[historyMethodName];

  if (!routeHistory.location) {
    routeTo(toRoute);
    return;
  }

  const search = routeHistory.location.search;
  if (search) {
    const to = parseSearchParams(search, 'to');
    if (to) {
      routeTo((to.substr(0, 1) === '/') ? to : `/${to}`);
      return;
    }
  }

  routeTo(toRoute);
}

/**
 * Parses the given search string or a routeHistory search string into a params object
 * or returns the value of the given key.
 *
 * @param {string|{location: {search: string}}} routeHistoryOrSearch
 * @param {string=} getKey If provided, returns only the value of the given key.
 * @returns {Object.<string, string>|string}
 */
export function parseSearchParams(routeHistoryOrSearch, getKey) {
  let search = routeHistoryOrSearch;
  if (lodash.has(routeHistoryOrSearch, 'location.search')) {
    search = routeHistory.location.search;
  }

  const searchParams = new URLSearchParams(String(search || ''));

  if (getKey) {
    return searchParams.get(getKey);
  }

  const allParams = {};
  for (let [paramKey, paramValue] of searchParams.entries()) {
    allParams[paramKey] = paramValue;
  }

  return allParams;
}

/**
 * Returns whether or not the current path has a `to` redirect.
 *
 * @returns {boolean}
 */
export function currentPathHasTo() {
  const to = parseSearchParams(routeHistory, 'to');
  return Boolean(to);
}

/**
 * Gets a redirection path.
 *
 * @param {string} newLocationRoute
 * @param {string=} overrideTo
 * @returns {{pathname: string, search: string}}
 */
export function getRedirectWithTo(newLocationRoute, overrideTo) {
  if (overrideTo) {
    const safeOverrideTo = String(overrideTo);
    const toWithoutPreSlash = (safeOverrideTo[0] === '/') ? safeOverrideTo.substr(1) : safeOverrideTo;
    return {
      pathname: newLocationRoute,
      search: `?to=${toWithoutPreSlash}`,
    };
  }

  const to = parseSearchParams(routeHistory, 'to');
  if (to) {
    const toWithoutPreSlash = (to[0] === '/') ? to.substr(1) : to;
    return {
      pathname: newLocationRoute,
      search: `?to=${toWithoutPreSlash}`,
    };
  }

  return newLocationRoute;
}
