import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import * as routePaths from '../../routePaths';
import works24SymbolPNG from '../../../assets/images/works24-symbol.png';

import './dashboardHeader.scss';

/**
 * The DashboardHeader component.
 */
export class DashboardHeader extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <header id="dashboard-header">
        <div>
          <img className="dashboard-header-logo" src={works24SymbolPNG} alt="Works 24 Logo" />
          <Link to={routePaths.homeRoute} className="fa fa-times dashboard-header-close" id="dashboard-close" />
          { this.props.prevPage &&
            <Link to={this.props.prevPage} className="fa fa-arrow-left dashboard-header-back" id="dashboard-back" />
          }
        </div>
        <h1 className="dashboard-header-title">{ this.props.title }</h1>
      </header>
    );
  }
}

DashboardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  prevPage: PropTypes.string,
};

export default DashboardHeader;
