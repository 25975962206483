import lodash from 'lodash';

/**
 * The logo placeholder type.
 * @const {string}
 */
export const TYPE_LOGO = 'logo';

/**
 * The clock placeholder type.
 * @const {string}
 */
export const TYPE_CLOCK = 'clock';

/**
 * The weather placeholder type.
 * @const {string}
 */
export const TYPE_WEATHER = 'weather';

/**
 * The clock & weather placeholder type.
 * @const {string}
 */
export const TYPE_CLOCK_WEATHER = 'clock-weather';

/**
 * All the valid types.
 * @const {string[]}
 */
export const ALL_TYPES = [TYPE_LOGO, TYPE_CLOCK, TYPE_WEATHER, TYPE_CLOCK_WEATHER];

/**
 * The placeholder component.
 * This component acts as a placeholder for a component that will be filled in elsewhere.
 *
 * @param {{}} placeholderValues
 * @param {string=} placeholderValues.type
 * @param {{}=} importComponent
 * @returns {{placeholder: {type: string}}}
 */
export function placeholderComponent(placeholderValues, importComponent) {
  const safeImport = parseImportComponent(importComponent);
  const newValues = lodash.defaults(placeholderValues || {}, safeImport);

  const safeType = String(newValues.type || TYPE_LOGO);
  if (!lodash.includes(ALL_TYPES, safeType)) {
    throw new Error('Invalid type given to placeholder component.');
  }

  return {
    placeholder: {
      type: safeType,
    },
  };
}

/**
 * Parses a component into an importable component.
 *
 * @param {{}} importComponent
 * @returns {{}}
 */
function parseImportComponent(importComponent) {
  return lodash.pickBy(importComponent || {});
}

/**
 * Gets the placeholder component from the source.
 *
 * @param {{placeholder: {type: string}}} item
 * @returns {{placeholder: {type: string}}}
 */
export function getPlaceholderFromSource(item) {
  if (!item.placeholder) {
    return {};
  }

  const placeholder = item.placeholder;

  return placeholderComponent({
    type: placeholder.type,
  });
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getPlaceholderForSource(entity) {
  if (!entity.has('placeholder')) {
    return {};
  }

  const placeholder = entity.get('placeholder');

  return {
    placeholder: {
      type: placeholder.type || null,
    },
  };
}
