import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import './noCategoryHeader.scss';

/**
 * The NoCategoryHeader component.
 */
export class NoCategoryHeader extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {onCreateBlank} = this.props;

    return (
      <aside className="no-category-header">
        <div className="header-buttons">
          {(onCreateBlank) && (
            <button
              type="button"
              onClick={onCreateBlank}
              className="control-btn blank-canvas-btn btn btn-outline-secondary"
            >
              Blank Canvas <i className="fa fa-plus" />
            </button>
          )}
        </div>
      </aside>
    );
  }
}

NoCategoryHeader.propTypes = {
  onCreateBlank: PropTypes.func,
};

export default observer(NoCategoryHeader);
