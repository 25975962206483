import lodash from 'lodash';

import {DEFAULT_WIDGET} from '../../../constants/contentConstants';

/**
 * The widget component.
 * This component acts as a placeholder for a widget of content.
 *
 * @param {{}} widgetValues
 * @param {number=} widgetValues.contentId
 * @param {string=} widgetValues.aspectRatio
 * @param {number=} widgetValues.refreshDuration
 * @param {{}=} importComponent
 * @returns {{widget: {contentId: number, aspectRatio: string}}}
 */
export function widgetComponent(widgetValues, importComponent) {
  const safeImport = parseImportComponent(importComponent);
  const newValues = lodash.defaults(widgetValues || {}, safeImport);

  return {
    widget: {
      contentId: newValues.contentId || DEFAULT_WIDGET,
      aspectRatio: newValues.aspectRatio || '9:16',
      refreshDuration: newValues.refreshDuration || 0,
    },
  };
}

/**
 * Parses a widget component into an importable component.
 *
 * @param {{}} importComponent
 * @returns {{}}
 */
function parseImportComponent(importComponent) {
  return lodash.pickBy(importComponent || {});
}

/**
 * Gets the widget component from the source.
 *
 * @param {{widget: {contentId: ?number}}} item
 * @returns {{widget: {contentId: ?number}}}
 */
export function getWidgetFromSource(item) {
  if (!item.widget) {
    return {};
  }

  const widget = item.widget;

  return widgetComponent({
    contentId: widget.contentId,
    aspectRatio: widget.aspectRatio,
    refreshDuration: widget.refreshDuration,
  });
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getWidgetForSource(entity) {
  if (!entity.has('widget')) {
    return {};
  }

  const widget = entity.get('widget');

  return {
    widget: {
      contentId: widget.contentId || DEFAULT_WIDGET,
      aspectRatio: widget.aspectRatio || '9:16',
      refreshDuration: widget.refreshDuration || 0,
    },
  };
}
