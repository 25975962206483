import {getFeedTextFromSource} from '../../../../../../display/components/type/feedTextComponent';

/**
 * The new text placeholder.
 * @const {string}
 */
const PLACEHOLDER_TEXT = '{\\}{%1}{40}{$1.00}{#000000}{arial}[[content]]{!arial}{!#000000}{!$1.00}{!40}{!%1}{!\\}';

/**
 * Gets the text data for a placeholder text.
 *
 * @returns {{text: {plaintext: string}}}
 */
export function getPlaceHolderFeedTextData() {
  return {
    feedText: {
      source: '',
      markdown: PLACEHOLDER_TEXT,
    }
  };
}

/**
 * Returns a source for when the feed text is reset.
 *
 * @param {{}=} currentFeedText
 * @returns {{feedText: {}}}
 */
export function resetFeedTextSource(currentFeedText) { // eslint-disable-line no-unused-vars
  return getPlaceHolderFeedTextData();
}

/**
 * Returns a new feed text component after resetting it.
 *
 * @param {{}=} currentFeedText
 * @returns {{feedText: {}}}
 */
export function resetFeedTextComponent(currentFeedText) {
  return getFeedTextFromSource(
    resetFeedTextSource(currentFeedText)
  );
}
