import {reaction} from 'mobx';

import {categorySearchChange, contentLibraryChange} from '../content/contentCommon';

/**
 * Initializes thunk
 */
export function init() {
}

/**
 * Init the thunk to watch the specified ActiveContentStore
 *
 * @param {{}} activeContentStore
 * @param {number} libraryType
 * @returns {Array.<function>}
 */
export function initStore(activeContentStore, libraryType) {
  return [
    reaction(
      () => activeContentStore.libraries[libraryType].id,
      (contentLibraryId) => {
        contentLibraryChange(contentLibraryId, libraryType, activeContentStore);
      }
    ),
    reaction(
      () => activeContentStore.libraries[libraryType].category.searchFilter,
      (searchFilter) => {
        categorySearchChange(searchFilter, libraryType, activeContentStore);
      }
    ),
  ];
}

/**
 * Call all the specified mobX disposer functions
 *
 * @param {Array.<function>} disposers
 */
export function disposeStore(disposers) {
  disposers.forEach((disposer) => {
    disposer();
  });
}

/**
 * Disposes of the thunk.
 * This is useful for hot-reloading purposes.
 */
export function dispose() {
}

export default init;
