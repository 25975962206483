import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import ConfirmModal from '../../modals/confirm/ConfirmModal';
import apiDeleteContentStore from '../../../stores/api/delete/apiDeleteContentStore';

/**
 * The ContentDelete component.
 */
export class ContentDelete extends React.Component {
  /**
   * Whether or not the delete modal is open.
   *
   * @type {boolean}
   */
  @observable isDeleteModalOpen = false;

  /**
   * The chosen content.
   *
   * @type {?{}}
   */
  activeContent = null;

  /**
   * Handles when a piece of content is selected for being deleted.
   *
   * @param {{}} content
   */
  @action onDeleteStart = (content) => {
    this.activeContent = content;
    this.isDeleteModalOpen = true;
  };

  /**
   * Handles when a content item will be deleted.
   *
   * @param {boolean} wasConfirmed
   */
  @action onDeleteContent = (wasConfirmed) => {
    this.isDeleteModalOpen = false;

    if (!wasConfirmed) {
      // The user said they did not want to delete the content or cancelled the modal.
      return;
    }

    const {libraryType} = this.props;
    const activeContent = this.activeContent;
    this.activeContent = null;

    apiDeleteContentStore.deleteContent(activeContent, libraryType);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div className="content-delete">
        {(this.isDeleteModalOpen) && (
          <ConfirmModal
            isOpen={true}
            onComplete={this.onDeleteContent}
            title="Delete File?"
            confirmText={'Are you sure you want to delete this file?'}
            isYesNo={true}
          />
        )}
      </div>
    );
  }
}

ContentDelete.propTypes = {
  libraryType: PropTypes.number.isRequired,
};

export default observer(ContentDelete);
