import lodash from 'lodash';

/**
 * The number to divide by to convert to a percentage.
 *
 * @const {number}
 */
const TO_PERCENT = 100;

/**
 * Parses a size attribute.
 *
 * @param {string|number} attribute
 * @returns {number}
 */
export function parseSize(attribute) {
  if (attribute === 'auto') {
    return 'auto';
  }
  return parseFloat(attribute) || 0;
}

/**
 * The size component.
 *
 * @param {{}} sizeValues
 * @param {number=} sizeValues.width
 * @param {number=} sizeValues.height
 * @param {number=} sizeValues.minSizeMultiplier
 * @param {{}=} importComponent
 * @returns {{size: {width: number, height: number}}}
 */
export function sizeComponent(sizeValues, importComponent) {
  const safeImport = parseImportComponent(importComponent);
  const newValues = lodash.defaults(sizeValues || {}, safeImport);

  return {
    size: {
      width: parseSize(newValues.width),
      height: parseSize(newValues.height),
      minSizeMultiplier: newValues.minSizeMultiplier || null,
    },
  };
}

/**
 * Parses a size component into an importable component.
 *
 * @param {{}} importComponent
 * @returns {{}}
 */
function parseImportComponent(importComponent) {
  return lodash.pickBy(importComponent || {});
}

/**
 * Gets the size component from the source item.
 *
 * @param {{setup: {size: {width: number, height: number}}}} item
 * @param {{width: number, height: number}} resolution
 * @returns {{size: {width: number, height: number}}}
 */
export function getSizeFromSource(item, resolution) {
  if (!item.setup || !item.setup.size) {
    return {};
  }
  const itemSetup = item.setup || {};
  const safeSize = Object.assign({}, itemSetup.size || {});

  if (String(safeSize.width).indexOf('%') !== -1) {
    safeSize.width = Math.ceil((parseInt(safeSize.width, 10) / TO_PERCENT) * resolution.width);
    if (safeSize.width > resolution.width) {
      safeSize.width = resolution.width;
    }
  }
  if (String(safeSize.height).indexOf('%') !== -1) {
    safeSize.height = Math.ceil((parseInt(safeSize.height, 10) / TO_PERCENT) * resolution.height);
    if (safeSize.height > resolution.height) {
      safeSize.height = resolution.height;
    }
  }

  return sizeComponent({
    width: safeSize.width || 'auto',
    height: safeSize.height || 'auto',
    minSizeMultiplier: safeSize.minSizeMultiplier,
  });
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getSizeForSource(entity) {
  if (!entity.has('size')) {
    return {};
  }

  const size = entity.get('size');

  const outputSize = {
    width: size.width,
    height: size.height,
  };
  if (size.minSizeMultiplier) {
    outputSize.minSizeMultiplier = size.minSizeMultiplier;
  }

  return {
    setup: {
      size: outputSize,
    },
  };
}
