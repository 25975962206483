import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import PlaceholderType from './components/placeholderType/PlaceholderType';
import EditTimelineControls from '../editTimeline/EditTimelineControls';

import './editPlaceholderControls.scss';

/**
 * The EditPlaceholderControls component.
 */
export class EditPlaceholderControls extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;

    return (
      <div className="edit-viewport-controls">
        <div className="viewport-group">
          <h5 className="sidebar-title">Placeholder</h5>

          <div className="group-header">
            <span className="group-header-label">Options</span>
          </div>

          <div className="group-controls">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="placeholder-type">Type</label>
                  <PlaceholderType entity={entity} game={game} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <EditTimelineControls entity={entity} game={game} />
      </div>
    );
  }
}

EditPlaceholderControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditPlaceholderControls);
