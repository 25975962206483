const lodash = require('lodash');

/**
 * Matches variables in the feed path ([n], [m], etc).
 * @const {RegExp}
 */
const VARIABLE_REGEXP = /\[([a-zA-Z]+)\]/g;

/**
 * Parses the feed path into a `lodash.get` path.
 *
 * @param {string} feedPath
 * @param {*} feedData
 * @param {{slideNumber: number}} metaData
 * @param {Object<string, number>=} variableValues
 * @returns {*|null|undefined}
 */
export function getFeedPathValue(feedPath, feedData, metaData, variableValues) {
  const safeSlideNumber = (metaData) ? (metaData.slideNumber || 0) : 0;
  const safeVariableValues = variableValues || {};

  // Any `*`s in the feedId must be replaced with the current slide's number.
  const pathWithNoStars = String(feedPath).replace('*', String(safeSlideNumber));

  const orderedVariables = getVariableOrder(pathWithNoStars, safeVariableValues);

  if (!orderedVariables.length) {
    // If there are no variables in the feed path, then just use `lodash.get` to get the value.
    return lodash.get(feedData, pathWithNoStars);
  }

  return parseFeedPathVariables(pathWithNoStars, feedData, safeVariableValues, orderedVariables);
}

/**
 * Gets the order of the variables ([n], [m], etc) in the feed path.
 *
 * @param {string} feedPath
 * @returns {string[]}
 */
function getVariableOrder(feedPath) {
  const matches = String(feedPath).match(VARIABLE_REGEXP);
  if (!matches) {
    return [];
  }

  const positionsByVariables = matches.reduce((final, itemMatch, matchPosition) => {
    // Remove the []s from the item match.
    const variableName = itemMatch.substring(1, itemMatch.length - 1);

    if (!final[variableName]) {
      final[variableName] = [];
    }

    // Keep a record of the order each variable was found in the path.
    final[variableName].push(matchPosition);

    return final;
  }, {});

  const variablesAndPositions = lodash.toPairs(positionsByVariables);

  // To change the increment order, just update this sortBy.
  return lodash.sortBy(variablesAndPositions, ([_variableName, positions]) => {
    // Sort the variables by their first appearance in the feed path.
    return positions[0];
  }).map(([variableName]) => {
    return variableName;
  });
}

/**
 * Parses the feed path variables (`[n][m]`) into their value.
 * This method can be recursive.
 *
 * @param {string} feedPath
 * @param {*} feedData
 * @param {Object<string, number>} variableValues
 * @param {string[]} orderedVariables
 * @param {string=} activeVariable The index that is being incremented.
 * @returns {*|null}
 */
function parseFeedPathVariables(feedPath, feedData, variableValues, orderedVariables, activeVariable) {
  const safeActiveVariable = activeVariable || lodash.last(orderedVariables);

  // Replace characters inside brackets will their current value.
  const withNoIndexes = String(feedPath).replace(VARIABLE_REGEXP, (fullMatch, listIndex) => {
    const listIndexCount = lodash.get(variableValues, listIndex, 0);

    return `[${listIndexCount}]`;
  });

  const pathValue = lodash.get(feedData, withNoIndexes);

  // The path was valid, so increment and return.
  if (pathValue !== undefined) {
    const lastVariable = lodash.last(orderedVariables);

    // Increment the last variable.
    if (!variableValues[lastVariable]) {
      variableValues[lastVariable] = 0;
    }
    variableValues[lastVariable] += 1;

    return pathValue;
  }

  // Update the variable values for the activeVariable (and all that come after it) to zero.
  let previousVariable = null;
  let wasFound = false;
  orderedVariables.forEach((variableName) => {
    if (variableName === safeActiveVariable) {
      wasFound = true;
    }

    if (wasFound) {
      variableValues[variableName] = 0;
    } else {
      previousVariable = variableName;
    }
  });

  if (!previousVariable) {
    // We have hit the end of the line. There will be no further matches, but the variable values will all be reset.
    return null;
  }

  if (!variableValues[previousVariable]) {
    variableValues[previousVariable] = 0;
  }
  variableValues[previousVariable] += 1;

  return parseFeedPathVariables(feedPath, feedData, variableValues, orderedVariables, previousVariable);
}

/**
 * Determines whether or not the feed path has any variables.
 *
 * @param {string} feedPath
 * @returns {boolean}
 */
export function hasVariables(feedPath) {
  if (!feedPath) {
    return false;
  }

  const matches = String(feedPath).match(VARIABLE_REGEXP);

  return Boolean(matches);
}
