import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * What to divide by to turn milliseconds into seconds.
 * @const {number}
 */
const TO_SECONDS = 1000;

/**
 * The number of decimals to show for the time.
 * @const {number}
 */
const SHOW_DECIMALS = 4;

/**
 * The DisplayTimer component.
 */
export class DisplayTimer extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {style, entity, className} = this.props;

    const entityId = entity.get('id');
    const timer = entity.get('timer');

    return (
      <div id={entityId} className={classNames('display-timer', className)} style={style}>
        <span>{(timer.time / TO_SECONDS).toFixed(SHOW_DECIMALS)}</span>
      </div>
    );
  }
}

DisplayTimer.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  style: PropTypes.object.isRequired,

  className: PropTypes.string,
};

export default observer(DisplayTimer);
