export const systemProrities = {
  entity: 5,
  visibility: 10,
  aligning: 15,
  positioning: 20,
  cropping: 30,
  tracking: 100,
  transition: 200,
  typeUpdate: 300,
  cleanup: 1000,
};
