import lodash from 'lodash';

/**
 * The image component.
 *
 * @param {{}} feedImageValues
 * @param {string=} feedImageValues.source
 * @param {number=} feedImageValues.imageTop
 * @param {number=} feedImageValues.imageLeft
 * @param {number=} feedImageValues.imageWidth
 * @param {number=} feedImageValues.imageHeight
 * @param {{}} importComponent
 * @returns {{feedImage: {source: string, top: number, left: number, width: number, height: number}}}
 */
export function feedImageComponent(feedImageValues, importComponent) {
  const newValues = lodash.defaults(feedImageValues || {}, lodash.pickBy(importComponent || {}));

  return {
    feedImage: {
      source: newValues.source,
      top: newValues.top,
      left: newValues.left,
      width: newValues.width,
      height: newValues.height,
    },
  };
}

/**
 * Gets the feed image component from the source item.
 *
 * @param {{image: {source: string, top: number, left: number, width: number, height: number}}} item
 * @returns {{image: {source: string, top: number, left: number, width: number, height: number}}}
 */
export function getFeedImageFromSource(item) {
  if (!item.feedImage) {
    return {};
  }

  const feedImage = item.feedImage;

  return feedImageComponent({
    source: String(feedImage.source),
    top: Number(feedImage.top),
    left: Number(feedImage.left),
    width: Number(feedImage.width),
    height: Number(feedImage.height),
  });
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getFeedImageForSource(entity) {
  if (!entity.has('feedImage')) {
    return {};
  }

  const image = entity.get('feedImage');
  return {
    feedImage: {
      source: image.source,
      top: image.top,
      left: image.left,
      width: image.width,
      height: image.height,
    },
  };
}
