import {action} from 'mobx';

import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../constants/asyncConstants';
import serverApi from '../../utils/serverApi';
import sessionStore from '../active/sessionStore';
import SingleResourceBaseStore from './common/apiSingleResourceBaseStore';

/**
 * Store to switch clients
 */
class ClientSwitchStore extends SingleResourceBaseStore {
  /**
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Fetches new auth token for client
   *
   * @param {number} newClientId
   * @param {boolean} ignoreFail
   */
  @action getNewClientToken(newClientId, ignoreFail) {
    const clientId = String(newClientId);
    this.state = STATE_PENDING;

    serverApi.switchClient(clientId, ignoreFail).then(
      action('switchClientSuccess', () => {
        this.state = STATE_FULFILLED;
        sessionStore.setClientId(clientId);
      }),
      action('switchClientError', () => {
        this.state = STATE_REJECTED;
      })
    );
  }
}

export default new ClientSwitchStore();
