import {toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import Select from '../../common/select/Select';
import {ACTIVE_DELAY_MS} from '../../../constants/displayConstants';
import {actionUpdateComponent} from '../../../display/components/action/actionUpdateComponent';
import {timeComponent} from '../../../display/components/common/timeComponent';
import {transitionComponent} from '../../../display/components/common/transitionComponent';
import {visibleComponent} from '../../../display/components/common/visibleComponent';
import {getIsPositionLocked} from '../../../utils/dragDropHelper';

import './editTransitionControl.scss';

// Note: All transitionIn values must end with In.
const inTransitions = [
  {id: 1, label: 'None', value: ''},
  {id: 2, label: 'Fade', value: 'fadeIn'},
  {id: 3, label: 'Fly From Top', value: 'topFlyIn'},
  {id: 4, label: 'Fly From Right', value: 'rightFlyIn'},
  {id: 5, label: 'Fly From Bottom', value: 'bottomFlyIn'},
  {id: 6, label: 'Fly From Left', value: 'leftFlyIn'},
];

// Note: All transitionOut values must end with Out.
const outTransitions = [
  {id: 101, label: 'None', value: ''},
  {id: 102, label: 'Fade', value: 'fadeOut'},
  {id: 103, label: 'Fly To Top', value: 'topFlyOut'},
  {id: 104, label: 'Fly To Right', value: 'rightFlyOut'},
  {id: 105, label: 'Fly To Bottom', value: 'bottomFlyOut'},
  {id: 106, label: 'Fly To Left', value: 'leftFlyOut'},
];

/**
 * Finds the active transition for the entity.
 *
 * @param {Array.<{preset: string}>} entityTransitions
 * @param {RegExp} typeRegExp
 * @returns {string}
 */
function findActiveTransition(entityTransitions, typeRegExp) {
  if (!entityTransitions || !entityTransitions.find) {
    return '';
  }

  const foundPreset = entityTransitions.find((transition) => {
    if (!transition.preset) {
      return false;
    }
    return Boolean(transition.preset.match(typeRegExp));
  });

  return (foundPreset) ? foundPreset.preset : '';
}

/**
 * The EditTransitionControl component.
 */
export class EditTransitionControl extends React.Component {
  /**
   * Triggered when the transitionIn changes.
   *
   * @param {number} newValue
   */
  onChangeTransitionIn = (newValue) => {
    const validPresetMatch = /In$/;
    if (newValue && !newValue.match(validPresetMatch)) {
      throw new Error(`Invalid transition in preset value: ${newValue}`);
    }

    this.updateTransition(newValue, validPresetMatch);
    this.updateActiveTime(newValue);
  };

  /**
   * Triggered when the transitionOut changes.
   *
   * @param {number} newValue
   */
  onChangeTransitionOut = (newValue) => {
    const validPresetMatch = /Out$/;
    if (newValue && !newValue.match(validPresetMatch)) {
      throw new Error(`Invalid transition out preset value: ${newValue}`);
    }

    this.updateTransition(newValue, validPresetMatch);
  };

  /**
   * Updates the active time of the entity.
   *
   * @param {string} newValue
   */
  updateActiveTime = (newValue) => {
    const {entity, entities, game} = this.props;

    let allEntities = [];
    if (entities) {
      allEntities = [...entities];
    } else {
      allEntities = [entity];
    }

    allEntities.forEach((entityItem) => {
      const entityTime = entityItem.get('time');
      const actionParams = {
        entityId: entityItem.get('id'),
      };

      let newActive = null;
      if (newValue) {
        newActive = entityTime.start + ACTIVE_DELAY_MS;
      } else {
        newActive = entityTime.start;
      }

      game.addAction(actionParams, actionUpdateComponent(
        timeComponent(entityTime.start, entityTime.end, newActive)
      ));
    });
  };

  /**
   * Updates the transition for the entity.
   *
   * @param {number} newValue
   * @param {RegExp} validPresetMatch
   */
  updateTransition = (newValue, validPresetMatch) => {
    const {entity, entities, game} = this.props;

    let allEntities = [];
    if (entities) {
      allEntities = [...entities];
    } else {
      allEntities = [entity];
    }

    allEntities.forEach((entityItem) => {
      const transitions = toJS(entityItem.get('transition') || []);
      const visible = entityItem.get('visible');
      const actionParams = {
        entityId: entityItem.get('id'),
      };

      if (getIsPositionLocked(entityItem)) {
        return;
      }

      let foundPreset = false;
      transitions.forEach((transition) => {
        if (!transition.preset || !transition.preset.match(validPresetMatch)) {
          return;
        }
        foundPreset = true;
        transition.preset = newValue;
      });

      if (!foundPreset) {
        transitions.push({
          preset: newValue
        });
      }

      const filteredTransitions = transitions.filter((value) => {
        if (value.preset === undefined) {
          return true;
        }
        return Boolean(value.preset);
      });

      game.addAction(actionParams, actionUpdateComponent({
        ...transitionComponent(filteredTransitions),
        ...visibleComponent(visible.isVisible, 1)
      }));
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, entities} = this.props;

    const allInTransitions = [...inTransitions];
    const allOutTransitions = [...outTransitions];

    let inValue = '';
    let outValue = '';
    if (entities) {
      inValue = null;
      outValue = null;

      allInTransitions.push({id: 0, label: 'Mixed', value: null});
      allOutTransitions.push({id: 100, label: 'Mixed', value: null});

      entities.forEach((subEntity, index) => {
        const entityTransitions = subEntity.get('transition');
        const entityInValue = findActiveTransition(entityTransitions, /In$/);
        const entityOutValue = findActiveTransition(entityTransitions, /Out$/);

        if (!index) {
          inValue = entityInValue;
          outValue = entityOutValue;
          return;
        }

        if (inValue === null || entityInValue !== inValue) {
          inValue = null;
        }
        if (outValue === null || entityOutValue !== outValue) {
          outValue = null;
        }
      });
    } else {
      const entityTransitions = entity.get('transition');
      inValue = findActiveTransition(entityTransitions, /In$/);
      outValue = findActiveTransition(entityTransitions, /Out$/);
    }

    return (
      <div className="edit-transition-control">
        <div className="form-group">
          <label htmlFor="edit-transition-in">Transition In</label>
          <Select
            id="edit-transition-in"
            theme="dark"
            value={inValue}
            onChange={this.onChangeTransitionIn}
            options={allInTransitions}
          />
        </div>

        <div className="form-group">
          <label htmlFor="edit-transition-out">Transition Out</label>
          <Select
            id="edit-transition-out"
            theme="dark"
            value={outValue}
            onChange={this.onChangeTransitionOut}
            options={allOutTransitions}
          />
        </div>
      </div>
    );
  }
}

EditTransitionControl.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,

  entities: PropTypes.arrayOf(MobxPropTypes.observableMap),
  entity: MobxPropTypes.observableMap,
};

export default observer(EditTransitionControl);
