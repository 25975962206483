import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

import SearchForm from '../../../../common/searchForm/SearchForm';
import inject from '../../../../hoc/injectHoc';
import {newClipTypeRoute} from '../../../../routePaths';
import templatesPNG from '../../../../../assets/images/templates.png';
import {CLIP_TYPE_IMAGE, CLIP_TYPE_VIDEO} from '../../../../../constants/clipTypeConstants';
import {hasEmployeeAccess} from '../../../../../utils/writableLibrariesHelper';

import './contentHeader.scss';

/**
 * The ContentHeader component.
 */
export class ContentHeader extends React.Component {
  /**
   * Whether or not the create new dropdown is open.
   *
   * @type {boolean}
   */
  @observable isCreateNewDropdownOpen = false;

  /**
   * Toggles the create new dropdown.
   */
  @action onToggleCreateNewDropdown = () => {
    this.isCreateNewDropdownOpen = !this.isCreateNewDropdownOpen;
  };

  /**
   * Navigates to the create new image page.
   *
   * @param {boolean} isLayout
   * @returns {function}
   */
  onCreateNewImage = (isLayout) => {
    const {routerStore} = this.props;

    return () => {
      routerStore.push(newClipTypeRoute, {
        params: {
          clipType: CLIP_TYPE_IMAGE,
          isLayout: (isLayout) ? '1' : '',
        },
      });
    };
  };

  /**
   * Navigates to the create new video page.
   *
   * @param {boolean} isLayout
   * @returns {function}
   */
  onCreateNewVideo = (isLayout) => {
    const {routerStore} = this.props;

    return () => {
      routerStore.push(newClipTypeRoute, {
        params: {
          clipType: CLIP_TYPE_VIDEO,
          isLayout: (isLayout) ? '1' : '',
        },
      });
    };
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      onSearchFormChange,
      searchFormText,
    } = this.props;

    const isEmployee = hasEmployeeAccess();

    return (
      <div id="content-header">
        <div className="sub-header sub-header-main">
          <div className="sub-header-logo">
            <img className="logo" src={templatesPNG} />
            <h1 className="sub-header-title">Content/Templates</h1>
          </div>
          <div className="sub-header-search-wrapper">
            <SearchForm
              placeholder="Search Library Content..."
              onChange={onSearchFormChange}
              onSubmit={(submitEvent) => submitEvent.preventDefault()}
              searchText={searchFormText}
            />
          </div>
        </div>
        <div className="sub-header sub-header-actions">
          <div className="header-control-group">
            <ButtonDropdown
              group={false}
              isOpen={this.isCreateNewDropdownOpen}
              toggle={this.onToggleCreateNewDropdown}
            >
              <DropdownToggle color="primary">
                Create New <i className="fa fa-plus icon-bold" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={this.onCreateNewImage(false)}>Create Still Image</DropdownItem>
                <DropdownItem onClick={this.onCreateNewVideo(false)}>Create Video Clip</DropdownItem>

                {(isEmployee) && (
                  <>
                    <DropdownItem onClick={this.onCreateNewImage(true)}>Create Image Layout</DropdownItem>
                    <DropdownItem onClick={this.onCreateNewVideo(true)}>Create Video Layout</DropdownItem>
                  </>
                )}
              </DropdownMenu>
            </ButtonDropdown>
          </div>
        </div>
      </div>
    );
  }
}

ContentHeader.propTypes = {
  onSearchFormChange: PropTypes.func.isRequired,

  routerStore: MobxPropTypes.observableObject,
  searchFormText: PropTypes.string,
};

ContentHeader.wrappedComponent = {};
ContentHeader.wrappedComponent.propTypes = {
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ContentHeader)(
  observer(ContentHeader)
);
