import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityActivateHoc from '../../hoc/entityActivateHoc';

/**
 * The DisplayWidget component.
 */
export class DisplayWidget extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {style} = this.props;

    style.backgroundColor = 'rgb(0, 120, 0)';
    style.border = '3px dashed white';

    // style.mixBlendMode = 'exclusion';

    return (
      <div className="display-widget" style={style}>
        <span>Widget Placeholder</span>
      </div>
    );
  }
}

DisplayWidget.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,
};

export default entityActivateHoc(
  observer(DisplayWidget)
);
