/* eslint-disable no-magic-numbers */

import lodash from 'lodash';
import {runInAction, toJS} from 'mobx';
import uuidv4 from 'uuid/v4';

import {actionComponent} from '../components/common/actionComponent';
import {getCropFromSource} from '../components/common/cropComponent';
import {getCropShapeFromSource} from '../components/common/cropShapeComponent';
import {getElementFromSource} from '../components/common/elementComponent';
import {getGroupFromSource} from '../components/common/groupComponent';
import {getInteractionFromSource} from '../components/common/interactionComponent';
import {getLockedFromSource} from '../components/common/lockedComponent';
import {getNameFromSource} from '../components/common/nameComponent';
import {getPositionFromSource} from '../components/common/positionComponent';
import {getSizeFromSource} from '../components/common/sizeComponent';
import {getTimeFromSource} from '../components/common/timeComponent';
import {getTransitionFromSource} from '../components/common/transitionComponent';
import {getVisibleFromSource} from '../components/common/visibleComponent';

import {getAudioFromSource} from '../components/type/audioComponent';
import {getCircleFromSource} from '../components/type/circleComponent';
import {getFeedImageFromSource} from '../components/type/feedImageComponent';
import {getFeedTextFromSource} from '../components/type/feedTextComponent';
import {getIconFromSource} from '../components/type/iconComponent';
import {getImageFromSource} from '../components/type/imageComponent';
import {getLineFromSource, parsePositionFromLine, parseSizeFromLine} from '../components/type/lineComponent';
import {getPlaceholderFromSource} from '../components/type/placeholderComponent';
import {getPlaylistFromSource} from '../components/type/playlistComponent';
import {getRectangleFromSource} from '../components/type/rectangleComponent';
import {getTextFromSource} from '../components/type/textComponent';
import {getTimerFromSource, timerComponent} from '../components/type/timerComponent';
import {getVideoFromSource} from '../components/type/videoComponent';
import {getViewportFromSource} from '../components/type/viewportComponent';
import {getWidgetFromSource} from '../components/type/widgetComponent';

import {mobxMerge} from '../../utils/mobxHelper';

/**
 * Maps components to their parse-from-source function.
 *
 * @type {Object<string, function>}
 */
const fromSourceMap = {
  audio: getAudioFromSource,
  circle: getCircleFromSource,
  feedImage: getFeedImageFromSource,
  feedText: getFeedTextFromSource,
  icon: getIconFromSource,
  image: getImageFromSource,
  line: getLineFromSource,
  placeholder: getPlaceholderFromSource,
  playlist: getPlaylistFromSource,
  rectangle: getRectangleFromSource,
  text: getTextFromSource,
  timer: getTimerFromSource,
  video: getVideoFromSource,
  viewport: getViewportFromSource,
  widget: getWidgetFromSource,
};

/**
 * Parses the item into common components.
 *
 * @param {{}} item
 * @param {number} order
 * @param {{getEndTime: function, getFps: function, getResolution: function}} game
 * @returns {{}}
 */
function getCommonComponents(item, order, game) {
  return Object.assign(
    {},
    getElementFromSource(item),
    getCropFromSource(item),
    getCropShapeFromSource(item),
    getGroupFromSource(item),
    getInteractionFromSource(item),
    getLockedFromSource(item),
    getNameFromSource(item),
    getPositionFromSource(item, order),
    getSizeFromSource(item, game.resolution),
    getTimeFromSource(item, game.fps),
    (game.hasTimeLine()) ? getTransitionFromSource(item, game.fps) : {},
    getVisibleFromSource(item)
  );
}

/**
 * Gets an entity from the source file item.
 *
 * @param {{}} item
 * @param {number} order
 * @param {GameStore} game
 * @returns {{}}
 */
export function getEntityFromSourceItem(item, order, game) { // eslint-disable-line complexity
  const fps = game.fps;
  if (fps < 10 || fps > 60) {
    throw new Error('Invalid fps given to entity helper, value must be between 10 and 60 (inclusive).');
  }

  // Make sure all entities have a start and end time.
  if (!item.startTime) {
    item.startTime = 0;
  }
  if (!item.endTime) {
    item.endTime = game.endTime;
  }

  const entity = {
    id: uuidv4()
  };

  const commonComponents = getCommonComponents(item, order, game);

  const itemType = String(item.type);
  const getFromSource = fromSourceMap[itemType];
  if (!getFromSource) {
    throw new Error(`Invalid entity type '${itemType}' found.`);
  }

  const typeComponents = getFromSource(item);

  return Object.assign(
    entity,
    commonComponents,
    typeComponents
  );
}

/**
 * Gets an action entity for the game.
 *
 * @param {{entityId: (string|string[])}} actionParams
 * @param {Object.<string, {}>} components
 * @returns {{id: string, element: string}}
 */
export function getActionEntity(actionParams, components) {
  return Object.assign({
    id: uuidv4(),
    element: 'action',
  }, components || {}, actionComponent(actionParams));
}

/**
 * Gets a timer entity for the game.
 *
 * @param {number} maxTime
 * @returns {{id: string, element: string, timer: {time: number, state: string}}}
 */
export function getTimerEntity(maxTime) {
  return Object.assign({
    id: uuidv4(),
    element: 'timer',
  }, timerComponent(maxTime));
}

/**
 * Creates a new entity with all the same components.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function cloneEntity(entity) {
  const newEntity = toJS(entity);
  newEntity.id = uuidv4();
  delete newEntity.interaction;

  return newEntity;
}

/**
 * Updates the entity with the given values.
 *
 * @param {{get: function(string)}} entity
 * @param {string} componentName
 * @param {{}} newValues
 */
export function updateEntity(entity, componentName, newValues) {
  if (!entity.get(componentName)) {
    return;
  }

  runInAction('updateEntity', () => {
    if (!newValues) {
      entity.delete(componentName);
      return;
    }

    // This will mutate the component.
    mobxMerge(entity.get(componentName), newValues);
  });
}

/**
 * Clears the caching for a transition.
 *
 * @param {{}} entity
 */
export function clearTransitionCache(entity) {
  if (!entity.has('transition')) {
    return;
  }

  runInAction('updateEntity', () => {
    entity.get('transition').forEach((transition) => {
      if (transition.loadedPreset) {
        transition.loadedPreset = null;
      }
    });
  });
}

/**
 * Sets one or more components on an entity.
 *
 * @param {{set: function}} entity
 * @param {Object.<string, {}>} newComponents
 */
export function setEntityComponents(entity, newComponents) {
  runInAction('setEntityComponents', () => {
    lodash.forEach(newComponents, (componentData, componentName) => {
      entity.set(componentName, componentData);
    });
  });
}

/**
 * Gets an entity position object.
 *
 * @param {{}} entity
 * @returns {{top: number, left: number}}
 */
export function getEntityPosition(entity) {
  const element = entity.get('element');
  if (element === 'image') {
    return entity.get('image');
  } else if (element === 'feedImage') {
    return entity.get('feedImage');
  } else if (element === 'line') {
    return parsePositionFromLine(entity.get('line'));
  }
  return entity.get('position');
}

/**
 * Gets an entity size object.
 *
 * @param {{}} entity
 * @returns {{width: number, height: number}}
 */
export function getEntitySize(entity) {
  const element = entity.get('element');
  if (element === 'image') {
    return entity.get('image');
  } else if (element === 'feedImage') {
    return entity.get('feedImage');
  } else if (element === 'line') {
    return parseSizeFromLine(entity.get('line'));
  }
  return entity.get('size');
}
