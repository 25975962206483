import lodash from 'lodash';

/**
 * The list of possible lock types.
 *
 * @const {string[]}
 */
const LOCK_TYPES = [
  'aspectRatio',
  'order',
  'position',
  'rotation',
  'size',
  'time',
  'toEdge',
];

/**
 * The locked component.
 *
 * @param {string[]} lockedTypes
 * @returns {{locked: string[]}}
 */
export function lockedComponent(lockedTypes) {
  if (!lockedTypes || !lockedTypes.forEach) {
    throw new Error('Invalid locked type given to lockedComponent.');
  }

  const safeLockedTypes = lockedTypes.map((type) => {
    return lodash.camelCase(type);
  });

  return {
    locked: lodash.intersection(LOCK_TYPES, safeLockedTypes),
  };
}

/**
 * Gets whether or not an item is locked in the given entity.
 *
 * @param {ObservableMap} entity
 * @param {string} lockType
 * @returns {boolean}
 */
export function isLocked(entity, lockType) {
  if (!entity) {
    return false;
  }

  let locked;
  if (entity.get) {
    locked = entity.get('locked');
  } else {
    locked = entity.locked;
  }

  if (!locked) {
    return false;
  }

  const safeLockType = String(lockType);
  if (LOCK_TYPES.indexOf(safeLockType) === -1) {
    throw new Error('Invalid lock type given to isLocked.');
  }

  return (locked.indexOf(safeLockType) !== -1);
}

/**
 * Gets the locked component from the source item.
 *
 * @param {{locked: string[]}} item
 * @returns {{locked: string[]}}
 */
export function getLockedFromSource(item) {
  const itemSetup = item.setup || {};
  if (!itemSetup.locked || !itemSetup.locked.forEach || !itemSetup.locked.length) {
    return {};
  }
  return lockedComponent(itemSetup.locked);
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getLockedForSource(entity) {
  if (!entity.has('locked')) {
    return {};
  }

  const locked = entity.get('locked');
  if (!locked || !locked.slice) {
    return {};
  }

  return {
    setup: {
      locked: locked.slice(),
    },
  };
}
