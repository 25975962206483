import lodash from 'lodash';
import {action, observable, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import EditorEntityAddButton from './components/entityAddButton/EditorEntityAddButton';
import EditorEntityList from './components/entityList/EditorEntityList';
import EditorEntityNav from './components/entityNav/EditorEntityNav';
import ConfirmModal from '../../modals/confirm/ConfirmModal';
import CreateContentModal from '../../modals/createContent/CreateContentModal';
import inject from '../../hoc/injectHoc';
import {getSourceFromGame} from '../../../display/game';
import {READY} from '../../../constants/contentConstants';
import {EDITOR_NEW_ALL} from '../../../constants/editorConstants';
import {CREATED_CONTENT} from '../../../constants/libraryTypeConstants';
import {ENTITY_MAX_LENGTH} from '../../../constants/uiConstants';
import {createNewContent} from '../../../utils/contentsHelper';

import './editorSidebar.scss';

/**
 * The EditorSidebar component.
 */
export class EditorSidebar extends React.Component {
  /**
   * Whether or not the create modal is open.
   *
   * @type {boolean}
   */
  @observable isCreateModalOpen = false;

  /**
   * Whether or not the alert modal for the render button is open.
   *
   * @type {boolean}
   */
  @observable isRenderAlertModalOpen = false;

  /**
   * Whether or not the confirm modal for the render process is open.
   *
   * @type {boolean}
   */
  @observable isRenderConfirmModalOpen = false;

  /**
   * Whether or not the tooltip for the render button is open.
   *
   * @type {boolean}
   */
  @observable isRenderTooltipOpen = false;

  /**
   * Whether or not the dropdown for the save options is open.
   *
   * @type {boolean}
   */
  @observable isSaveDropdownOpen = false;

  /**
   * The message to display in the render alert modal.
   *
   * @type {string}
   */
  @observable renderAlertMessage = '';

  /**
   * Opens the confirm dialog for the render process.
   */
  @action beforeSaveVideoAndRender = () => {
    this.isRenderConfirmModalOpen = true;
  };

  /**
   * Closes the render confirm modal.
   * If the user accepted, then starts the render process.
   *
   * @param {boolean} didAccept
   */
  @action closeRenderConfirmModal = (didAccept) => {
    this.isRenderConfirmModalOpen = false;

    if (!didAccept) {
      return;
    }

    const {activeContentStore, isLayout} = this.props;
    const contentId = activeContentStore.contentId;

    if (lodash.includes(EDITOR_NEW_ALL, String(contentId))) {
      this.isCreateModalOpen = true;
      return;
    }

    if (isLayout) {
      this.onSaveVideoClick();
      return;
    }

    this.onRenderVideo(contentId);
  };

  /**
   * Closes the render alert modal.
   */
  @action closeRenderAlertModal = () => {
    this.isRenderAlertModalOpen = false;
  };

  /**
   * Renders the modal help text.
   * @returns {*}
   */
  renderHelpText = () => {
    return (
      <div>
        This is about to be published <strong>live</strong>. This will take up to 30 minutes to render a
        preview. You will <strong>not</strong> be able to edit the content while it is rendering.
      </div>
    );
  };

  /**
   * Triggered when the user clicks to render the video.
   *
   * @param {number=} contentId
   */
  onRenderVideo = (contentId) => {
    const {
      apiContentsStore,
      apiRenderSourceStore,
      editorGetContentStore,
      gameCheckpointStore,
      routerStore,
      game
    } = this.props;

    const contentData = editorGetContentStore.getContentById(contentId);

    const content = contentData.content;
    const categoryId = content.categoryId;
    const source = getSourceFromGame(game);

    apiRenderSourceStore.renderSourceToVideo(
      source,
      content,
      game.feed
    ).then(() => {
      if (!apiRenderSourceStore.error) {
        gameCheckpointStore.clearCheckpoint();

        editorGetContentStore.expireCacheForContent(contentId);
        editorGetContentStore.fetchContentById(contentId);
        apiContentsStore.expireCacheForCategory(categoryId);

        routerStore.unblockRouting();
        this.props.goHome(true);
        return;
      }

      const renderError = apiRenderSourceStore.error;

      runInAction('renderVideoErrorToAlert', () => {
        if (renderError.isProcessing) {
          this.renderAlertMessage = 'This content is already processing and can not be rendered until it is finished.'
            + ' Please try again in about 10 minutes.';
        } else {
          this.renderAlertMessage = 'An error occurred while trying to render this content.';
        }
        this.isRenderAlertModalOpen = true;
      });
    });
  };

  /**
   * Saves the source JSON to the database.
   *
   * @returns {Promise}
   */
  saveVideo = () => {
    const {
      game,
      activeContentStore,
      apiUpdateContentsStore,
      editorGetContentStore,
      gameCheckpointStore
    } = this.props;

    const source = getSourceFromGame(game);

    const contentId = activeContentStore.contentId;
    const content = editorGetContentStore.getContentById(contentId).content;

    return apiUpdateContentsStore.updateActiveContentSource(
      source,
      content,
      game.feed.feedId
    ).then(() => {
      gameCheckpointStore.clearCheckpoint();

      editorGetContentStore.expireCacheForContent(contentId);
      editorGetContentStore.fetchContentById(contentId);
    });
  };

  /**
   * Triggered when the user clicks to save the video.
   */
  onSaveVideoClick = () => {
    const {apiUpdateContentsStore, routerStore} = this.props;

    this.saveVideo().then(() => {
      if (!apiUpdateContentsStore.content || !apiUpdateContentsStore.content.error) {
        routerStore.unblockRouting();
        this.props.goHome(true);
        return;
      }

      runInAction('saveVideoErrorToAlert', () => {
        this.renderAlertMessage = 'An error occurred while trying to save this video.'
          + ' Please wait a few minutes and try again.';
        this.isRenderAlertModalOpen = true;
      });
    });
  };

  /**
   * Handles when the create modal is finished and the content is ready to be created.
   *
   * @param {{
   *   categoryId: number,
   *   libraryId: number,
   *   libraryStoreKey: {key: string, filters: {}},
   *   libraryType: number,
   *   contentName: string,
   *   isGlobal: boolean,
   * }} createData
   */
  @action onCreateContent = (createData) => {
    this.isCreateModalOpen = false;

    if (!createData.libraryId || !createData.categoryId) {
      // The user must have cancelled the modal.
      return;
    }

    const {
      isLayout,
      /** @type {ActiveContentStore} */ activeContentStore,
      /** @type {ContentLibrariesStore} */ apiContentLibrariesStore,
      /** @type {EditorGetContentStore} */ editorGetContentStore,
    } = this.props;

    const library = apiContentLibrariesStore.getLibrary(
      createData.libraryStoreKey,
      createData.libraryId
    );

    const contentId = activeContentStore.contentId;

    const contentSource = editorGetContentStore.getSource(contentId);
    const isImage = (contentSource && contentSource.type === 'video') ? 'video' : 'image';

    const layoutUpdates = {};
    if (isLayout) {
      layoutUpdates.status = READY;
    }

    editorGetContentStore.updateNewContent(contentId, {
      ...createData,
      isImage,
      directory: library.defaultDirectory,
      ...layoutUpdates,
    });

    let newContentId;

    editorGetContentStore.getPromise(contentId).then(({content}) => {
      return createNewContent(content, createData.isGlobal);
    }).catch(() => {
      runInAction('createContentErrorToAlert', () => {
        this.renderAlertMessage = 'An error occurred while trying to create this content.';
        this.isRenderAlertModalOpen = true;
      });

      return null;
    }).then((createdContentId) => {
      if (!createdContentId) {
        return null;
      }

      newContentId = createdContentId;

      editorGetContentStore.fetchContentById(newContentId);
      return editorGetContentStore.getPromise(newContentId);
    }).then(() => {
      activeContentStore.setContentId(newContentId);

      if (isLayout) {
        this.onSaveVideoClick();
        return;
      }

      this.onRenderVideo(newContentId);
    });
  };

  /**
   * Toggles the render tooltip.
   */
  @action toggleRenderTooltip = () => {
    this.isRenderTooltipOpen = !this.isRenderTooltipOpen;
  };

  /**
   * Toggles the save options dropdown.
   */
  @action toggleSaveDropdown = () => {
    this.isSaveDropdownOpen = !this.isSaveDropdownOpen;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isLayout, game, timer} = this.props;

    return (
      <div id="editor-sidebar" className="system-sidebar">
        <div className="sidebar-top">
          <h3 className="h6 sidebar-title">Layers</h3>
          <div className="sidebar-controls">
            <EditorEntityNav game={game} timer={timer} />
            {(game.entities && game.entities.length < ENTITY_MAX_LENGTH) && (
              <EditorEntityAddButton game={game} timer={timer} isLayout={isLayout} />
            )}
          </div>
        </div>

        <div className="sidebar-wrapper">
          <EditorEntityList game={game} />
        </div>

        <div className="sidebar-actions">
          <button
            id="render-button"
            className="btn btn-primary btn-lg render-button"
            type="button"
            onClick={this.beforeSaveVideoAndRender}
          ><i className="fad fad-external-link icon-bold" /> Publish Now</button>
        </div>

        {(this.isRenderAlertModalOpen) && (
          <ConfirmModal
            isOpen={true}
            confirmText={this.renderAlertMessage}
            isYesNo={false}
            title="Render Process Error"
            onComplete={this.closeRenderAlertModal}
          />
        )}

        {(this.isRenderConfirmModalOpen) && (
          <ConfirmModal
            isOpen={true}
            confirmText="Publish Now"
            helpText={this.renderHelpText()}
            isYesNo={true}
            yesText="Publish Now"
            noText="Cancel"
            title="Publish Now?"
            onComplete={this.closeRenderConfirmModal}
          />
        )}

        {(this.isCreateModalOpen) && (
          <CreateContentModal
            isOpen={true}
            libraryType={CREATED_CONTENT}
            title="Save Content"
            onComplete={this.onCreateContent}
          />
        )}
      </div>
    );
  }
}

EditorSidebar.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,
  goHome: PropTypes.func.isRequired,
  isLayout: PropTypes.bool.isRequired,
  timer: MobxPropTypes.observableObject.isRequired,

  activeContentStore: MobxPropTypes.observableObject,
  apiContentLibrariesStore: MobxPropTypes.observableObject,
  apiContentsStore: MobxPropTypes.observableObject,
  apiRenderSourceStore: MobxPropTypes.observableObject,
  apiUpdateContentsStore: MobxPropTypes.observableObject,
  editorGetContentStore: MobxPropTypes.observableObject,
  gameCheckpointStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
};

EditorSidebar.wrappedComponent = {};
EditorSidebar.wrappedComponent.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentLibrariesStore: MobxPropTypes.observableObject.isRequired,
  apiContentsStore: MobxPropTypes.observableObject.isRequired,
  apiRenderSourceStore: MobxPropTypes.observableObject.isRequired,
  apiUpdateContentsStore: MobxPropTypes.observableObject.isRequired,
  editorGetContentStore: MobxPropTypes.observableObject.isRequired,
  gameCheckpointStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(EditorSidebar)(
  observer(EditorSidebar)
);
