import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityActivateHoc from '../../../hoc/entityActivateHoc';

/**
 * The LineItem component.
 */
export class LineItem extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity} = this.props;

    const line = entity.get('line');
    const {startPoint, endPoint, thickness} = line;
    const lineStyle = line.style;

    return (
      <line
        className="line-item"
        x1={startPoint.x}
        y1={startPoint.y}
        x2={endPoint.x}
        y2={endPoint.y}
        strokeWidth={thickness}
        stroke={lineStyle.color}
        strokeLinecap="round"
        pointerEvents="visible"
      />
    );
  }
}

LineItem.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: PropTypes.object.isRequired,
};

export default entityActivateHoc(
  observer(LineItem)
);
