import {runInAction} from 'mobx';

import {clearTransitionCache, setEntityComponents, updateEntity} from '../ecs/entityHelper';

/**
 * The name of the system.
 * @const {string}
 */
export const ACTION_ASPECT_RATIO_SYSTEM = 'actionAspectRatioSystem';

/**
 * Gets a new instance of the action aspect ratio system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function actionAspectRatioSystem(game) {
  /**
   * Called right before the game loop updates.
   *
   * @param {ObservableArray} actions
   */
  function systemRunActions(actions) {
    actions.forEach((actionEntity) => {
      // First check for required components.
      if (!actionEntity.has('actionAspectRatio')) {
        return;
      }

      const action = actionEntity.get('action');
      const actionAspectRatio = actionEntity.get('actionAspectRatio');
      const entity = game.getEntity(action.entityId);
      if (!entity) {
        return;
      }

      const {aspectRatio, componentName} = actionAspectRatio;

      const component = entity.get(componentName);
      if (!component) {
        return;
      }

      updateEntityWithParams(entity, componentName, {
        aspectRatio,
      });

      updateSizeToMatchAspectRatio(entity, aspectRatio);

      clearTransitionCache(entity);
    });
  }

  /**
   * Updates the entity size to match the new aspect ratio.
   *
   * @param {{}} entity
   * @param {string} aspectRatio
   */
  function updateSizeToMatchAspectRatio(entity, aspectRatio) {
    const position = entity.get('position').default;
    const size = entity.get('size');
    const gameResolution = game.resolution;

    const ratioParts = String(aspectRatio).split(':');

    const newAspectMultiplier = ratioParts[0] / ratioParts[1];

    const minHeight = size.width / newAspectMultiplier;
    const finalHeight = minHeight + ((size.height - minHeight) / 2);
    const finalWidth = finalHeight * newAspectMultiplier;

    const newPosition = {
      top: position.top,
      left: position.left,
    };

    const edgePadding = 5;

    if (position.top + size.height >= gameResolution.height - edgePadding) {
      newPosition.top += (size.height - finalHeight);
    }
    if (position.left + size.width >= gameResolution.width - edgePadding) {
      newPosition.left += (size.width - finalWidth);
    }

    updateEntityWithParams(entity, 'size', {
      height: finalHeight,
      width: finalWidth,
    });

    updateEntityWithParams(entity, 'position', {
      ...newPosition,
      default: {
        ...newPosition,
      },
    });
  }

  /**
   * Updates the given entity with the differences given.
   *
   * @param {{}} entity
   * @param {string} componentName
   * @param {{}} params
   */
  function updateEntityWithParams(entity, componentName, params) {
    runInAction('aspectRatioSystemUpdateEntity', () => {
      if (entity.has(componentName)) {
        updateEntity(entity, componentName, params);
      } else if (params !== null) {
        setEntityComponents(entity, {[componentName]: params});
      }
    });
  }

  return {
    name: ACTION_ASPECT_RATIO_SYSTEM,
    runActions: systemRunActions
  };
}
