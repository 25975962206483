import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityActivateHoc from '../../hoc/entityActivateHoc';
import {VIEWPORT_TYPE_IMAGE, VIEWPORT_TYPE_VIDEO} from '../../../display/components/type/viewportComponent';
import {DisplayViewportVideo} from './DisplayViewportVideo';
import {DisplayViewportImage} from './DisplayViewportImage';

/**
 * The DisplayViewport component.
 */
export class DisplayViewport extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {style, entity, game} = this.props;

    const viewport = entity.get('viewport');

    return (
      <div className="display-viewport">
        {(viewport.type === VIEWPORT_TYPE_VIDEO) && (
          <DisplayViewportVideo entity={entity} style={style} />
        )}

        {(viewport.type === VIEWPORT_TYPE_IMAGE) && (
          <DisplayViewportImage entity={entity} game={game} style={style} />
        )}
      </div>
    );
  }
}

DisplayViewport.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,

  className: PropTypes.string,
};

export default entityActivateHoc(
  observer(DisplayViewport)
);
