import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import ImageGroup from './components/ImageGroup';

import './displayImage.scss';

/**
 * The DisplayImage component.
 */
export class DisplayImage extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game, style, className} = this.props;

    const entityId = entity.get('id');
    const image = entity.get('image');
    if (!image.fileId) {
      return null;
    }

    // Disable rotation on the svg element.
    const svgStyle = {
      ...style,
      transform: '',
      WebkitTransform: '',
    };

    return (
      <svg
        id={entityId}
        className={classNames('display-image', className)}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        fill="none"
        stroke="none"
        preserveAspectRatio="none"
        pointerEvents="none"
        viewBox={`0 0 ${svgStyle.width} ${svgStyle.height}`}
        style={svgStyle}
      >
        <ImageGroup entity={entity} game={game} style={style} />
      </svg>
    );
  }
}

DisplayImage.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,

  className: PropTypes.string,
};

export default observer(DisplayImage);
