import {observable, action, extendObservable} from 'mobx';

import {getWritableLibrariesAndCategories} from '../../../utils/writableLibrariesHelper';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {apiStore, getCase} from '../../../utils/apiStore';
import activeContentStore from '../../../stores/active/activeContentStore';
import apiClientStore from '../../../stores/api/apiClientStore';
import sessionStore from '../../../stores/active/sessionStore';
import serverApi from '../../../utils/serverApi';
import {BACKGROUND_STILL, BACKGROUND_VIDEO, PLACEABLE_IMAGE} from '../../../constants/libraryTypeConstants';
import apiCategoriesStore from '../apiCategoriesStore';

/**
 * Manages selecting a category for content create flow
 */
class SelectCategoryForCreateStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * id of the chosen Content Library
   * @type {?number}
   */
  @observable contentLibraryId = null;

  /**
   * Error
   * @type {?Error}
   */
  @observable error = null;

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {{contentLibraryId: number, categoryId: ?number}}
   */
  getFulfilled() {
    return {libraryId: this.contentLibraryId};
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    return this.error;
  }

  /**
   * Sets the library.
   *
   * @param {number} contentLibraryId
   */
  @action setContentLibrary(contentLibraryId) {
    this.contentLibraryId = contentLibraryId;
    this.error = null;
    this.state = STATE_FULFILLED;
  }

  /**
   * Gets the client object.
   *
   * @param {number} clientId
   * @returns {Promise.<{contentDirectory: string}>}
   */
  getClient(clientId) {
    const fulfilledClient = apiClientStore.getFulfilled(clientId);
    if (fulfilledClient && fulfilledClient.id) {
      return Promise.resolve(fulfilledClient);
    }

    return serverApi.clientGetById(clientId);
  }

  /**
   * Create a new writable library of the specified type & automatically choose it.
   * Helper function that should only be called in _chooseWritableLibrary.
   *
   * @param {number} libraryType
   * @param {?number} productId
   * @param {?string} clientName
   * @returns {Promise}
   */
  @action createNewWritableLibrary(libraryType, productId, clientName) {
    // else there there aren't any writable libraries, so create one automatically & choose it
    const {clientId, userId} = sessionStore;
    const libraryPrefix = clientName ? clientName + ' ' : 'My ';

    let libraryName = 'Content';
    if (libraryType === BACKGROUND_VIDEO) {
      libraryName = 'Background Videos';
    } else if (libraryType === BACKGROUND_STILL) {
      libraryName = 'Background Images';
    } else if (libraryType === PLACEABLE_IMAGE) {
      libraryName = 'Placeable Images';
    }
    libraryName = libraryPrefix + libraryName;

    const firstCategoryName = 'Recent Uploads';

    let contentLibraryId = null;

    return this.getClient(clientId).then((client) => {
      return serverApi.contentLibraryCreate(libraryName, client, productId, libraryType, userId);
    }).then(
      (createLibraryResponse) => {
        contentLibraryId = createLibraryResponse.id;
        this.setContentLibrary(contentLibraryId);

        return serverApi.categoryCreate(firstCategoryName, contentLibraryId);
      },
      action('createContentLibraryError', (error) => {
        this.error = error;
        this.state = STATE_REJECTED;
      })
    ).then(
      action('createLibraryCategorySuccess', () => {
        if (contentLibraryId) {
          apiCategoriesStore.expireCacheForLibrary(contentLibraryId);
          apiCategoriesStore.fetchCategories({contentLibraryId});
        }
      }),
      action('createLibraryCategoryError', (error) => {
        this.error = error;
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * See if we can automatically choose a category based on the user's writable libraries.
   * Should only be used in chooseCategory.
   *
   * @param {number} libraryType
   * @param {?number} productId
   * @private
   */
  @action _chooseWritableLibrary(libraryType, productId) {
    getWritableLibrariesAndCategories(libraryType, productId).then(
      (result) => {
        if (result.libraries.length) {
          this.setContentLibrary(result.libraries[0].id);
        } else {
          this.createNewWritableLibrary(libraryType, productId);
        }
      },
      action('getWritableLibrariesAndCategoriesError', (error) => {
        this.error = error;
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * See if we can automatically choose a library/category, otherwise get the user to choose one.
   *
   * @param {number} libraryType
   * @param {?number} productId
   */
  @action chooseCategory(libraryType, productId) {
    this.state = STATE_PENDING;

    // first see if the currently active library is writable.  If so, create content into this library.
    if (activeContentStore.isActiveLibraryWritable(libraryType)) {
      const activeLibrary = activeContentStore.libraries[libraryType];

      this.setContentLibrary(activeLibrary.id);
    } else {
      // otherwise see if there are any writable libraries/categories of the specified type.
      this._chooseWritableLibrary(libraryType, productId);
    }
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => {
      return this.getFulfilled();
    };
    const getRejected = () => {
      return this.getRejected();
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }
}

export default new SelectCategoryForCreateStore();
