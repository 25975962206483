import React from 'react';

import {logoutAction} from '../../../actions/session/logoutAction';

import './logoutPage.scss';

/**
 * The LogoutPage component.
 */
export class LogoutPage extends React.Component {
  /**
   * Logs out the user when the page is loaded/mounted.
   */
  componentDidMount() {
    logoutAction(false);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div id="logout-page" className="system-page row no-gutters full-height">
        <h1>Logging You Out</h1>
      </div>
    );
  }
}

export default LogoutPage;
