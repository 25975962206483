import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import EditorEntityListItem from '../entityListItem/EditorEntityListItem';

import './editorEntityList.scss';

/**
 * The EditorEntityList component.
 *
 * @constructor
 */
export class EditorEntityList extends React.Component {
  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {game} = this.props;

    const entities = (game && game.entities) ? game.entities : [];

    const filteredEntities = entities.reverse().filter((entity) => {
      return (entity && entity.has('element') && entity.has('visible'));
    });

    return (
      <div id="editor-entity-list">
        <div className="list-group">
          {filteredEntities.map((entity, index) => {
            const isLast = (index === (filteredEntities.length - 1));
            return (
              <EditorEntityListItem
                key={entity.get('id')}
                entity={entity}
                entityOrder={index + 1}
                disableOrdering={isLast}
                game={game}
                isLast={isLast}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

EditorEntityList.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditorEntityList);
