import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

import './confirmModal.scss';

/**
 * The ConfirmModal component.
 */
class ConfirmModal extends React.Component {
  /**
   * Triggered when the modal is cancelled.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete(false);
  };

  /**
   * Triggered when the modal is completed.
   */
  onCompleteModal = () => {
    const {onComplete} = this.props;

    onComplete(true);
  };

  /**
   * Returns cancel button text.
   *
   * @returns {string}
   */
  getNoText = () => {
    if (this.props.noText) {
      return this.props.noText;
    }
    return this.props.isYesNo ? 'No' : 'Cancel';
  };

  /**
   * Returns cancel button text.
   *
   * @returns {string}
   */
  getYesText = () => {
    if (this.props.yesText) {
      return this.props.yesText;
    }
    return this.props.isYesNo ? 'Yes' : 'Okay';
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isOpen, helpText, confirmText} = this.props;

    return (
      <Modal className="confirm-modal" isOpen={isOpen} backdrop="static" toggle={this.onCancelModal}>
        <ModalBody>
          <p className="w24-label">{confirmText}</p>

          {(helpText) && (
            <small className="text-muted">
              {helpText}
            </small>
          )}
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-default" onClick={this.onCancelModal}>
            {this.getNoText()}
          </button>
          <button type="button" className="btn btn-primary" onClick={this.onCompleteModal}>
            {this.getYesText()}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

ConfirmModal.propTypes = {
  confirmText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,

  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isYesNo: PropTypes.bool,
  noText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  yesText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

ConfirmModal.defaultProps = {
  helpText: null,
  isYesNo: false,
};

export default ConfirmModal;
