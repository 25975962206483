import {action, observable, when} from 'mobx';
import lodash from 'lodash';

import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../constants/storeConstants';
import serverApi from '../../utils/serverApi';
import {getCase} from '../../utils/apiStore';
import SortableFilterableStore from './common/apiSortableFilterableStore';
import apiCategoriesStore from './apiCategoriesStore';

/**
 * The valid filters for this store.
 * @const {string[]}
 */
const VALID_FILTERS = ['categoryId', 'libraryId', 'hideDeleted', 'editableOnly', 'page', 'perPage'];

/**
 * The valid filters for this store.
 * @const {string[]}
 */
const REQUIRED_FILTERS = ['categoryId', 'libraryId'];

/**
 * The default number of contents to get on each page.
 * @const {number}
 */
const DEFAULT_PER_PAGE = 24;

/**
 * The content ids store.
 */
class ContentsStore extends SortableFilterableStore {
  /**
   * The map of each categories' content Ids.
   *
   * @type {ObservableMap<string, {
   *   expireTime: Date,
   *   state: string,
   *   contents: ?ObservableArray,
   *   pagination: ?Observable,
   *   error: ?Error
   * }>}
   */
  @observable contentsByCategory = observable.map();

  /**
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @param {{key: string, filters: {}}} storeKey
   * @returns {?Array.<{}>}
   */
  getFulfilled(storeKey) {
    if (!storeKey) {
      return null;
    }

    const safeStoreKey = String(storeKey.key);
    if (!this.contentsByCategory.has(safeStoreKey)) {
      return null;
    }

    const allContents = this.contentsByCategory.get(safeStoreKey).contents;

    return allContents.map(this.observableToJS);
  }

  /**
   * Gets the pagination data for the given store key.
   *
   * @param {{key: string, filters: {}}} storeKey
   * @returns {{pages: number, total: number}}
   */
  getPaginationData(storeKey) {
    if (!storeKey) {
      return null;
    }

    const safeStoreKey = String(storeKey.key);
    if (!this.contentsByCategory.has(safeStoreKey)) {
      return null;
    }
    return this.contentsByCategory.get(safeStoreKey).pagination;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @param {{key: string, filters: {}}} storeKey
   * @returns {?Error}
   */
  getRejected(storeKey) {
    const safeStoreKey = String(storeKey.key);
    if (!this.contentsByCategory.has(safeStoreKey)) {
      return null;
    }
    return this.contentsByCategory.get(safeStoreKey).error;
  }

  /**
   * Clears all the contents.
   */
  @action clearAll() {
    this.contentsByCategory.clear();
  }

  /**
   * Replaces a piece of content for the given storeKey.
   *
   * @param {{key: string, filters: {}}} storeKey
   * @param {{id: number}} newContent
   */
  @action replaceContent(storeKey, newContent) {
    const safeStoreKey = String(storeKey.key);
    if (!newContent || !newContent.id) {
      return;
    } else if (!this.contentsByCategory.has(safeStoreKey)) {
      return;
    }

    const allContents = this.contentsByCategory.get(safeStoreKey).contents;
    if (!allContents || !allContents.length) {
      return;
    }

    const safeNewContentId = String(newContent.id);
    const replaceIndex = allContents.findIndex((content) => {
      return (safeNewContentId === String(content.id));
    });

    if (replaceIndex === -1) {
      return;
    }

    allContents.splice(replaceIndex, 1, newContent);
  }

  /**
   * Gets all the keys that have the given categoryId.
   *
   * @param {number|string} categoryId
   * @param {(number|string)=} libraryId Optional libraryId matching.
   * @returns {Array}
   */
  getKeysContainingCategory(categoryId, libraryId) {
    const safeCategoryId = String(categoryId);
    const safeLibraryId = (libraryId) ? String(libraryId) : null;
    const storeKeys = [];

    const categoryIdName = 'categoryId';
    const libraryIdName = 'libraryId';

    this.contentsByCategory.forEach((unused, key) => {
      let shouldBeCleared = 0;
      key.split('_').forEach((keyPart) => {
        if (keyPart.substr(0, categoryIdName.length) === categoryIdName) {
          const keyCategoryIds = keyPart.split('-')[1].split(',');
          if (keyCategoryIds.indexOf(safeCategoryId) !== -1) {
            shouldBeCleared += 1;
          }
        } else if (keyPart.substr(0, libraryIdName.length) === libraryIdName) {
          const keyLibraryId = keyPart.split('-')[1];
          if (keyLibraryId === safeLibraryId) {
            shouldBeCleared += 1;
          }
        }
      });

      if (shouldBeCleared) {
        if (!safeLibraryId) {
          storeKeys.push(key);
        } else if (shouldBeCleared >= 2) {
          storeKeys.push(key);
        }
      }
    });

    return storeKeys;
  }

  /**
   * Expires store cache for a category id.
   *
   * @param {number} categoryId
   */
  @action expireCacheForCategory(categoryId) {
    this.getKeysContainingCategory(categoryId).forEach((storeKey) => {
      this.contentsByCategory.get(storeKey)[EXPIRE_TIME] = null;
    });
  }

  /**
   * Removes the cached contents for the given filters and reloads them from the server.
   *
   * @param {{}} filters
   * @returns {{key: string, filters: {}}}
   */
  @action refreshContents(filters) {
    const safeFilters = this.addPagination(
      this.addChildCategories(filters)
    );

    safeFilters.hideDeleted = (safeFilters.hideDeleted !== false);
    if (filters.editableOnly) {
      safeFilters.editableOnly = true;
    }

    this.checkFilters(safeFilters, VALID_FILTERS, REQUIRED_FILTERS);

    const storeKey = this.getStoreKey(safeFilters);

    if (this.contentsByCategory.has(storeKey.key)) {
      this.contentsByCategory.get(storeKey.key)[EXPIRE_TIME] = null;
    }

    this.getKeysContainingCategory(safeFilters.categoryId).forEach((categoryKey) => {
      this.contentsByCategory.get(categoryKey)[EXPIRE_TIME] = null;
    });

    return this.fetchContents(safeFilters);
  }

  /**
   * Removes the cached contents for existing filters and reloads them from the server.
   *
   * @param {{}} filters
   */
  @action refreshCurrentContents(filters) {
    this.checkFilters(filters, VALID_FILTERS, REQUIRED_FILTERS);

    this.getKeysContainingCategory(filters.categoryId).forEach((categoryKey) => {
      this.contentsByCategory.get(categoryKey)[EXPIRE_TIME] = null;
    });

    this.getKeysContainingCategory(
      filters.categoryId,
      filters.libraryId
    ).forEach((categoryKey) => {
      this.fetchContents(
        this.parseKeyToFilters(categoryKey)
      );
    });
  }

  /**
   * Checks if content is already available via unexpired cache.
   *
   * @param {{key: string, filters: {}}} storeKey
   * @returns {boolean}
   */
  @action isContentAvailable(storeKey) {
    const safeStoreKey = String(storeKey.key);

    const currentResource = this.contentsByCategory.get(safeStoreKey);
    if (currentResource && currentResource.state === STATE_PENDING) {
      return true;
    }

    if (currentResource && currentResource[EXPIRE_TIME] <= Date.now()) {
      this.contentsByCategory.delete(safeStoreKey);
    } else if (currentResource && currentResource.state !== STATE_REJECTED) {
      return true;
    }

    return false;
  }

  /**
   * Adds pagination parameters to the filters.
   *
   * @param {{}} filters
   * @returns {{}}
   */
  addPagination(filters) {
    if (!filters) {
      return {};
    }

    const newFilters = {...filters};

    if (newFilters.page) {
      newFilters.page = Number(newFilters.page);
      if (!newFilters.page || newFilters.page < 1) {
        newFilters.page = 1;
      }
    } else {
      newFilters.page = 1;
    }

    if (newFilters.perPage) {
      newFilters.perPage = Number(newFilters.perPage);
      if (!newFilters.perPage || newFilters.perPage < 1) {
        newFilters.page = DEFAULT_PER_PAGE;
      }
    } else {
      newFilters.perPage = DEFAULT_PER_PAGE;
    }

    return newFilters;
  }

  /**
   * Adds child categories to the filters.
   *
   * @param {{}} filters
   * @returns {{}}
   */
  addChildCategories(filters) {
    if (!filters) {
      return {};
    }

    const categoriesStoreKey = apiCategoriesStore.getStoreKey({contentLibraryId: filters.libraryId});
    const activeCategories = apiCategoriesStore.getFulfilled(categoriesStoreKey);

    if (!activeCategories) {
      return filters;
    }

    const category = lodash.find(activeCategories, {id: filters.categoryId});
    if (!category) {
      return filters;
    }

    const childrenIds = category.children.map((childCategory) => childCategory.id);

    const filtersWithChildren = {...filters};
    if (filtersWithChildren.categoryId.forEach) {
      filtersWithChildren.categoryId = [...filtersWithChildren.categoryId, ...childrenIds];
    } else {
      filtersWithChildren.categoryId = [filtersWithChildren.categoryId, ...childrenIds];
    }

    return filtersWithChildren;
  }

  /**
   * Fetches contents from the server by category id.
   *
   * @param {{}} filters
   * @returns {{key: string, filters: {}}}
   */
  @action fetchContents(filters) {
    const safeFilters = this.addPagination(
      this.addChildCategories(filters)
    );

    safeFilters.hideDeleted = (safeFilters.hideDeleted !== false);
    safeFilters.editableOnly = Boolean(filters.editableOnly);

    this.checkFilters(safeFilters, VALID_FILTERS, REQUIRED_FILTERS);

    const storeKey = this.getStoreKey(safeFilters);
    if (this.isContentAvailable(storeKey)) {
      return storeKey;
    }

    this.contentsByCategory.set(storeKey.key, {
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      state: STATE_PENDING,
      contents: null,
      error: null,
    });

    serverApi.contentsByMultiCategoryIds(
      safeFilters.categoryId,
      safeFilters
    ).then(
      action('fetchContentsSuccess', ({pagination, response}) => {
        const safeContents = response || [];

        const contentsMap = {};
        safeContents.forEach((content) => {
          content.contentFiles = [];
          contentsMap[content.id] = content;
        });
        const contentFilesPromises = safeContents.map((content) => {
          return serverApi.contentFilesByContentId(content.id);
        });

        Promise.all(contentFilesPromises).then(
          action('fetchContentFilesSuccess', (contentFileGroups) => {
            const contentFiles = lodash.flatten( contentFileGroups );
            contentFiles.forEach((contentFile) => {
              if (!safeFilters.isDeleted && contentFile.isDeleted) {
                return;
              }

              // should be safe to access contentFiles without checking null || undefined,
              // since we are only getting content files for the specified content
              contentsMap[contentFile.contentId].contentFiles.push( contentFile );
            });

            this.contentsByCategory.set(storeKey.key, {
              [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
              state: STATE_FULFILLED,
              contents: safeContents,
              pagination,
              error: null,
            });
          }),
          action('fetchContentFilesError', (error) => {
            this.contentsByCategory.set(storeKey.key, {
              [EXPIRE_TIME]: null,
              state: STATE_REJECTED,
              contents: [],
              pagination: {},
              error,
            });
          })
        );
      }),
      action('fetchContentsError', (error) => {
        this.contentsByCategory.set(storeKey.key, {
          [EXPIRE_TIME]: null,
          state: STATE_REJECTED,
          contents: [],
          error,
        });
      })
    );
    return storeKey;
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{key: string, filters: {}}} storeKey
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(storeKey, handlers) {
    const getFulfilled = () => {
      return this.getFulfilled(storeKey);
    };
    const getRejected = () => {
      return this.getRejected(storeKey);
    };

    let state = STATE_PRE;
    if (storeKey && this.contentsByCategory.has(storeKey.key)) {
      state = this.contentsByCategory.get(storeKey.key).state;
    }

    return getCase(state, getFulfilled, getRejected, handlers);
  }

  /**
   * Runs handlers based on pagination changes in the state.
   *
   * @param {{key: string, filters: {}}} storeKey
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  paginationCase(storeKey, handlers) {
    if (!storeKey || !storeKey.filters) {
      return getCase(STATE_PRE, null, null, handlers);
    }

    const filters = storeKey.filters;

    let checkPage = 1;
    const itemsByPage = [];

    while (true) { // eslint-disable-line no-constant-condition
      const pageStoreKey = this.getStoreKey({...filters, page: checkPage});

      if (!this.contentsByCategory.has(pageStoreKey.key)) {
        break;
      }

      const pageState = this.contentsByCategory.get(pageStoreKey.key).state;

      itemsByPage.push({
        state: pageState,
        page: checkPage,
        storeKey: pageStoreKey,
      });

      checkPage += 1;
    }

    if (!itemsByPage.length) {
      return getCase(STATE_PRE, null, null, handlers);
    }

    let state = STATE_REJECTED;
    if (lodash.some(itemsByPage, (item) => (item.state === STATE_FULFILLED))) {
      // If any item is fulfilled, then the case is fulfilled.
      state = STATE_FULFILLED;
    } else if (lodash.some(itemsByPage, (item) => (item.state === STATE_PENDING))) {
      // If any item is pending, but none are fulfilled, then the case is pending.
      state = STATE_PENDING;
    }

    const getFulfilled = this.getPaginationFulfillment(itemsByPage);
    const getRejected = () => {
      return new Error('An error occurred while trying to load the content.');
    };

    return getCase(state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets the fulfillment function for the pagination case.
   *
   * @param {Array.<{page: number, state: string, storeKey: {key: string}}>} itemsByPage
   * @returns {function}
   */
  getPaginationFulfillment(itemsByPage) {
    const uniqueSpecial = -1000;

    return () => {
      let pagesCount = 1;
      let lastPage = 1;
      let preventNextPage = false;

      const fulfilled = itemsByPage.reduce((allContent, pageItem) => {
        if (pageItem.state === STATE_FULFILLED) {
          const fulfilledContent = this.getFulfilled(pageItem.storeKey);
          const paginationData = this.getPaginationData(pageItem.storeKey);

          if (paginationData && paginationData.pages > pagesCount) {
            pagesCount = paginationData.pages;
          }

          if (pageItem.page > lastPage) {
            lastPage = pageItem.page;
          }

          if (fulfilledContent) {
            return allContent.concat(fulfilledContent);
          }
        } else if (pageItem.state === STATE_PENDING) {
          const id = uniqueSpecial * pageItem.page;

          allContent.push({...pageItem, id, notContent: true, isPending: true});

          preventNextPage = true;
        } else if (pageItem.state === STATE_REJECTED) {
          const id = uniqueSpecial * pageItem.page;

          allContent.push({...pageItem, id, notContent: true, isRejected: true});

          preventNextPage = true;
        }

        return allContent;
      }, []);

      const canLoadMore = !(preventNextPage || lastPage >= pagesCount);

      return {
        canLoadMore,
        pagesCount,
        fulfilled,
      };
    };
  }

  /**
   * Gets a promise for this store.
   *
   * @param {string} storeKey
   * @returns {Promise}
   */
  getPromise(storeKey) {
    const thisStore = this;
    const coreKey = storeKey.key;

    return new Promise((resolve, reject) => {
      when(
        () => {
          const categoryData = thisStore.contentsByCategory.get(coreKey);
          const state = (categoryData) ? categoryData.state : null;
          return (state === STATE_FULFILLED || state === STATE_REJECTED);
        },
        () => {
          const state = thisStore.contentsByCategory.get(coreKey).state;
          if (state === STATE_REJECTED) {
            reject(this.getRejected(storeKey));
            return;
          }

          resolve(this.getFulfilled(storeKey));
        },
        {name: 'apiContentsStoreGetPromise'}
      );
    });
  }
}

export default new ContentsStore();
