import lodash from 'lodash';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import MovableCorner from '../common/MovableCorner';
import MovablePath from '../common/MovablePath';
import * as resizeCorner from '../../../constants/resizeConstants';
import {getEntityPosition, getEntitySize} from '../../../display/ecs/entityHelper';

import './displayMultiActive.scss';

/**
 * Active entities need have the highest (except for crop) zIndex in the display.
 * So this needs to be a very high number and higher than any other zIndex (except for the crop layer).
 *
 * @const {number}
 */
const ACTIVE_ENTITY_ZINDEX = 1000001;

/**
 * The size of the interaction control.
 * @const {number}
 */
const CONTROL_SIZE = 10;

/**
 * The fill color for the interaction control.
 * @const {string}
 */
const CONTROL_COLOR = '#0096fd';

/**
 * The fill outline color for the interaction control.
 * @const {string}
 */
const CONTROL_ALT_COLOR = '#ffffff';

/**
 * Gets the most horizontal edges of the entities.
 *
 * @param {Array.<{}>} activeEntities
 * @param {number} positionProp
 * @param {number} sizeProp
 * @returns {{position: number, size: number}}
 */
function getBoundaries(activeEntities, positionProp, sizeProp) {
  const allValues = activeEntities.map((entity) => getEntityPosition(entity)[positionProp]);

  const start = {
    [positionProp]: lodash.min(allValues)
  };

  const end = activeEntities.reduce((final, entity) => {
    const position = getEntityPosition(entity);
    const size = getEntitySize(entity);

    const currentTotal = position[positionProp] + size[sizeProp];
    const previousTotal = final[positionProp] + final[sizeProp];

    if (currentTotal > previousTotal) {
      return {
        [positionProp]: position[positionProp],
        [sizeProp]: size[sizeProp]
      };
    }

    return final;
  }, {[positionProp]: 0, [sizeProp]: 0});

  const positionValue = start[positionProp];
  const sizeValue = end[positionProp] + end[sizeProp] - start[positionProp];

  return {position: positionValue, size: sizeValue};
}

/**
 * The DisplayMultiActive component.
 */
class DisplayMultiActive extends React.Component {
  /**
   * Gets the styles from the entity.
   *
   * @param {Array.<{}>} entities
   * @returns {{}}
   */
  getStylesFromEntities = (entities) => {
    const xBoundaries = getBoundaries(entities, 'left', 'width');
    const yBoundaries = getBoundaries(entities, 'top', 'height');

    return {
      zIndex: ACTIVE_ENTITY_ZINDEX,
      position: 'absolute',
      width: xBoundaries.size,
      height: yBoundaries.size,
      left: xBoundaries.position,
      top: yBoundaries.position,
    };
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {activeEntities, game} = this.props;

    const safeEntities = activeEntities;

    const style = this.getStylesFromEntities(safeEntities);
    const padding = 10;

    const outlinePath = [
      `M ${padding},${padding}`,
      `H ${style.width + padding}`,
      `V ${style.height + padding}`,
      `H ${padding}`,
      'Z',
    ];

    const outlineWidth = 3;
    const outlineStartX = padding;
    const outlineStartY = padding;
    const outlineMiddleX = (style.width / 2) + padding;
    const outlineMiddleY = (style.height / 2) + padding;
    const outlineFarX = style.width + padding;
    const outlineFarY = style.height + padding;
    const controlOffset = 0 - (CONTROL_SIZE / 2);

    style.top -= padding;
    style.left -= padding;
    style.width += (padding * 2);
    style.height += (padding * 2);

    /**
     * Gets an interaction control.
     *
     * @param {number} x
     * @param {number} y
     * @param {number} offset
     * @param {string} resizePosition
     * @returns {object}
     */
    function getControl(x, y, offset, resizePosition) {
      return (
        <MovableCorner
          entities={safeEntities}
          fillColor={CONTROL_COLOR}
          game={game}
          isRotated={false}
          resizePosition={resizePosition}
          size={CONTROL_SIZE}
          startX={x + offset}
          startY={y + offset}
          strokeColor={CONTROL_ALT_COLOR}
          strokeWidth={1}
        />
      );
    }

    return (
      <svg
        id="display-multi-active"
        style={style}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        fillRule="evenodd"
        fill="none"
        stroke="none"
        strokeLinecap="square"
        strokeMiterlimit="10"
        overflow="hidden"
        preserveAspectRatio="none"
        pointerEvents="none"
        viewBox={`0 0 ${style.width} ${style.height}`}
      >
        <g pointerEvents="visiblePainted">
          <MovablePath
            entities={safeEntities}
            game={game}
            path={outlinePath.join(' ')}
            strokeColor={CONTROL_COLOR}
            strokeWidth={outlineWidth}
          />
        </g>

        <g pointerEvents="visiblePainted">
          {getControl(outlineStartX, outlineStartY, controlOffset, resizeCorner.TOP_LEFT)}

          {getControl(outlineMiddleX, outlineStartY, controlOffset, resizeCorner.TOP_MIDDLE)}

          {getControl(outlineFarX, outlineStartY, controlOffset, resizeCorner.TOP_RIGHT)}

          {getControl(outlineStartX, outlineMiddleY, controlOffset, resizeCorner.MIDDLE_LEFT)}

          {getControl(outlineFarX, outlineMiddleY, controlOffset, resizeCorner.MIDDLE_RIGHT)}

          {getControl(outlineStartX, outlineFarY, controlOffset, resizeCorner.BOTTOM_LEFT)}

          {getControl(outlineMiddleX, outlineFarY, controlOffset, resizeCorner.BOTTOM_MIDDLE)}

          {getControl(outlineFarX, outlineFarY, controlOffset, resizeCorner.BOTTOM_RIGHT)}
        </g>
      </svg>
    );
  }
}

DisplayMultiActive.propTypes = {
  activeEntities: PropTypes.arrayOf(MobxPropTypes.observableMap).isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(DisplayMultiActive);
