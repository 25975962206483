import classNames from 'classnames';
import {toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import {actionInteractionComponent} from '../../../../../display/components/action/actionInteractionComponent';

import './editorEntityNav.scss';

/**
 * The EditorEntityNav component.
 *
 * @constructor
 */
export class EditorEntityNav extends React.Component {
  /**
   * Activates the entity.
   *
   * @param {{}} newEntity
   */
  activateEntity = (newEntity) => {
    if (!newEntity) {
      return;
    }

    const {game, timer} = this.props;

    const actionParams = {
      entityId: newEntity.get('id'),
      skipHistory: true,
    };

    game.addAction(actionParams, actionInteractionComponent(true, false, false));

    const activeTime = newEntity.get('time').active;
    timer.setTime(activeTime);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {game} = this.props;

    const entitiesClone = toJS(game.entities || [], {exportMapsAsObjects: false});
    const visibleEntities = entitiesClone.filter((entity) => {
      return (entity && entity.has('element') && entity.has('visible'));
    });
    const activeEntityIndex = visibleEntities.findIndex((entity) => {
      return entity.has('interaction');
    });
    const isPrevDisabled = (activeEntityIndex === 0);
    const isNextDisabled = (activeEntityIndex >= visibleEntities.length - 1);

    const nextIndex = activeEntityIndex + 1;
    let prevIndex = activeEntityIndex - 1;
    if (activeEntityIndex < 0) {
      prevIndex = visibleEntities.length - 1;
    }

    return (
      <div className="entity-nav btn-group" role="group" aria-label="Previous and Next Entity">
        <button
          type="button"
          className={classNames('btn btn-sm btn-secondary', {'is-disabled': isNextDisabled})}
          onClick={() => this.activateEntity(visibleEntities[nextIndex])}
          disabled={isNextDisabled}
        >
          <i className="fad fad-caret-left" />
        </button>
        <button
          type="button"
          className={classNames('btn btn-sm btn-secondary', {'is-disabled': isPrevDisabled})}
          onClick={() => this.activateEntity(visibleEntities[prevIndex])}
          disabled={isPrevDisabled}
        >
          <i className="fad fad-caret-right" />
        </button>
      </div>
    );
  }
}

EditorEntityNav.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,
  timer: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditorEntityNav);
