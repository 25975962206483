import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import WidgetAspectRatio from './components/widgetAspectRatio/WidgetAspectRatio';
import WidgetRefreshDuration from './components/widgetRefreshDuration/WidgetRefreshDuration';
import EditTimelineControls from '../editTimeline/EditTimelineControls';

import './editWidgetControls.scss';

/**
 * The EditWidgetControls component.
 */
export class EditWidgetControls extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;

    return (
      <div className="edit-viewport-controls">
        <div className="viewport-group">
          <h5 className="sidebar-title">Widget</h5>

          <div className="group-header">
            <span className="group-header-label">Options</span>
          </div>

          <div className="group-controls">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="widget-aspect-ratio-input">Aspect Ratio</label>
                  <WidgetAspectRatio id="widget-aspect-ratio-input" entity={entity} game={game} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="widget-refresh-duration">Refresh Duration</label>
                  <WidgetRefreshDuration entity={entity} game={game} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <EditTimelineControls entity={entity} game={game} />
      </div>
    );
  }
}

EditWidgetControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditWidgetControls);
