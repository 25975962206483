import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import inject from '../../../../hoc/injectHoc';

import './productNavItem.scss';

/**
 * The product nav item.
 *
 * @param {{}} param
 * @param {{}} param.apiProductsStore
 * @param {{}} param.apiLoginStore
 * @param {{}} param.sessionStore
 * @returns {{}}
 */
const ProductNavItem = ({
  apiProductsStore,
  apiLoginStore,
  sessionStore
}) => {
  const products = apiProductsStore.getFulfilled();
  if (!products || !products.length) {
    return null;
  } else if (!apiLoginStore.getFulfilled()) {
    // If the user isn't logged in, then do not show this.
    return null;
  }

  /**
   * The active product.
   *
   * @type {?{}}
   */
  const activeProduct = apiProductsStore.getActiveProduct();

  /**
   * Navigates to the select product page by clearing the product from the session.
   */
  function onSelectProductClick() {
    sessionStore.setProduct(null);
  }

  return (
    <div id="product-nav-item">
      <button
        type="button"
        id="product-nav-button"
        className="w24-navbar-control w24-transparent-button"
        onClick={onSelectProductClick}
      >
        {(!activeProduct) ? (
          <span className="product-nav-label">Select Product</span>
        ) : (
          <span className="product-nav-label">
            <div className="product-name-label">Select Product</div>
            <div className="product-name">{activeProduct.productName}</div>
          </span>
        )}
      </button>
    </div>

  );
};

ProductNavItem.propTypes = {
  apiLoginStore: MobxPropTypes.observableObject,
  apiProductsStore: MobxPropTypes.observableObject,
  sessionStore: MobxPropTypes.observableObject,
};

ProductNavItem.wrappedComponent = {
  propTypes: {
    apiLoginStore: MobxPropTypes.observableObject.isRequired,
    apiProductsStore: MobxPropTypes.observableObject.isRequired,
    sessionStore: MobxPropTypes.observableObject.isRequired,
  }
};

export default inject(ProductNavItem)(
  observer(ProductNavItem)
);
