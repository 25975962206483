import assignDeep from 'assign-deep';
import {toJS} from 'mobx';
import lodash from 'lodash';

import {getCropForSource} from '../components/common/cropComponent';
import {getCropShapeForSource} from '../components/common/cropShapeComponent';
import {getElementForSource} from '../components/common/elementComponent';
import {getGroupForSource} from '../components/common/groupComponent';
import {getInteractionForSource} from '../components/common/interactionComponent';
import {getLockedForSource} from '../components/common/lockedComponent';
import {getNameForSource} from '../components/common/nameComponent';
import {getPositionForSource} from '../components/common/positionComponent';
import {getSizeForSource} from '../components/common/sizeComponent';
import {getTimeForSource} from '../components/common/timeComponent';
import {getTransitionForSource} from '../components/common/transitionComponent';
import {getVisibleForSource} from '../components/common/visibleComponent';

import {getAudioForSource} from '../components/type/audioComponent';
import {getCircleForSource} from '../components/type/circleComponent';
import {getIconForSource} from '../components/type/iconComponent';
import {imageComponent, getImageForSource} from '../components/type/imageComponent';
import {getLineForSource} from '../components/type/lineComponent';
import {getPlaceholderForSource} from '../components/type/placeholderComponent';
import {getPlaylistForSource} from '../components/type/playlistComponent';
import {getRectangleForSource, getRectangleFromSource} from '../components/type/rectangleComponent';
import {getFeedImageForSource} from '../components/type/feedImageComponent';
import {getFeedTextForSource} from '../components/type/feedTextComponent';
import {getTextForSource} from '../components/type/textComponent';
import {getTimerForSource} from '../components/type/timerComponent';
import {videoComponent, getVideoForSource} from '../components/type/videoComponent';
import {getViewportForSource} from '../components/type/viewportComponent';
import {getWidgetForSource} from '../components/type/widgetComponent';

import {GAME_TYPE_IMAGE, GAME_TYPE_VIDEO} from '../../stores/game/gameStore';

/**
 * Maps components to their parse-for-source function.
 *
 * @type {Object<string, function>}
 */
const forSourceMap = {
  audio: getAudioForSource,
  circle: getCircleForSource,
  feedImage: getFeedImageForSource,
  feedText: getFeedTextForSource,
  icon: getIconForSource,
  image: getImageForSource,
  line: getLineForSource,
  placeholder: getPlaceholderForSource,
  playlist: getPlaylistForSource,
  rectangle: getRectangleForSource,
  text: getTextForSource,
  timer: getTimerForSource,
  video: getVideoForSource,
  viewport: getViewportForSource,
  widget: getWidgetForSource,
};

/**
 * Parses the final source JSON from the entities.
 *
 * @param {GameStore} game
 * @param {{forHistory: boolean}=} options
 * @returns {{resolution: {width: number, height: number}, endTime: number, entities: Array.<{type: string}>}}
 */
export function parseSourceFromGame(game, options) {
  const safeOptions = options || {};
  const entities = game.entities;

  safeOptions.hasTimeLine = game.hasTimeLine();

  let sourceEntities = [];
  if (entities && entities.map) {
    sourceEntities = entities.map((entity) => {
      return getSourceFromEntity(entity, safeOptions);
    }).filter((entity) => {
      if (safeOptions && safeOptions.forHistory) {
        return entity;
      }

      // Only keep entities that have a position, otherwise they will never show and so should not be saved.
      return Boolean(entity && entity.setup && entity.setup.position);
    });
  }

  return {
    type: game.type,
    isLayout: game.isLayout,
    resolution: {
      height: game.resolution.height,
      width: game.resolution.width,
    },
    endTime: game.endTime,
    feedId: game.feed.feedId,
    feedSource: game.feed.feedSource,
    feedIndex: game.feed.feedIndex || 0,
    entities: sourceEntities
  };
}

/**
 * Parses the source JSON from the entity.
 *
 * @param {ObservableMap} entity
 * @param {{forHistory: boolean, hasTimeLine: boolean}=} options
 * @returns {{}}
 */
function getSourceFromEntity(entity, options) {
  if (!entity || !entity.has) {
    return {};
  }

  const commonSource = parseCommonComponents(entity, options);

  const entityElement = String(entity.get('element'));
  if (!entityElement) {
    return null;
  }

  const getForSource = forSourceMap[entityElement];
  if (!getForSource) {
    throw new Error(`Invalid entity type '${entityElement}' found.`);
  }

  const typeSource = getForSource(entity);

  // NOTE: Because of a bug in assign-deep, you must clone the response from assign deep.
  // @see https://github.com/jonschlinkert/assign-deep/issues/18
  return lodash.cloneDeep(
    toJS(
      assignDeep(
        commonSource,
        typeSource
      )
    )
  );
}

/**
 * Parses the item into common components.
 *
 * @param {ObservableMap} entity
 * @param {{forHistory: boolean, hasTimeLine: boolean}=} options
 * @returns {{}}
 */
function parseCommonComponents(entity, options) {
  return assignDeep(
    {},
    getElementForSource(entity),
    getCropForSource(entity),
    getCropShapeForSource(entity),
    getLockedForSource(entity),
    getGroupForSource(entity),
    getNameForSource(entity),
    getPositionForSource(entity),
    getSizeForSource(entity),
    getTimeForSource(entity),
    (options && options.hasTimeLine) ? getTransitionForSource(entity) : {},
    getVisibleForSource(entity),
    (options && options.forHistory) ? getInteractionForSource(entity) : {},
  );
}

/**
 * Creates the starting JSON for a new game/project.
 *
 * @param {number} width
 * @param {number} height
 * @param {{video: {source: string, type: string}, image: {source: string}}} background
 * @param {number=} endTime
 * @param {boolean=} isLayout
 * @returns {string}
 */
export function parseSourceForNewGame(width, height, background, endTime, isLayout) {
  const VALID_TYPES = ['video', 'image', 'rectangle'];

  if (!lodash.includes(VALID_TYPES, background.type)) {
    throw new Error('Invalid background type given, must be a video, image, or rectangle.');
  } else if (!width || !height || width < 1 || height < 1) {
    throw new Error('Invalid dimensions given, width and height must be positive integers.');
  }

  /**
   * Generates the source based on background properties.
   *
   * @returns {{}}
   */
  function generateSource() {
    switch (background.type) {
      case 'video':
        return videoComponent(background.fileId, null);
      case 'image':
        return imageComponent(background.fileId, 0, 0, width, height);
      default:
        return getRectangleFromSource({
          rectangle: {}
        });
    }
  }

  const defaultEndTIme = 10000;
  const safeEndTime = (endTime || endTime === 0) ? endTime : defaultEndTIme;
  const contentSource = generateSource();

  return JSON.stringify({
    type: (!safeEndTime) ? GAME_TYPE_IMAGE : GAME_TYPE_VIDEO,
    isLayout: Boolean(isLayout),
    resolution: {
      height,
      width,
    },
    endTime: safeEndTime,
    entities: [{
      type: background.type,
      startTime: 0,
      setup: {
        locked: ['order', 'time', 'size', 'position'],
        size: {
          width: `${width}px`,
          height: `${height}px`
        },
        position: {
          top: 0,
          left: 0,
        },
        opacity: 1
      },
      ...contentSource
    }]
  });
}
