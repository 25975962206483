import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import Select from '../../common/select/Select';

import './feedIndexSelector.scss';

/**
 * The FeedIndexSelector component.
 */
export class FeedIndexSelector extends React.Component {
  /**
   * Sets the new feedIndex value on all the content.
   *
   * @param {number} newIndex
   */
  onChangeFeedIndex = (newIndex) => {
    const {game, onChange} = this.props;

    const safeNewIndex = parseInt(newIndex, 10) || 0;

    if (onChange) {
      onChange(safeNewIndex);
    } else if (game) {
      game.feed.setFeedIndex(safeNewIndex);
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {game, label} = this.props;

    const {feedSummary} = game.feed;
    const feedData = feedSummary.data;

    if (!feedData || !feedData.length || !feedData.map) {
      return null;
    }

    const currentDataIndex = game.feed.feedIndex || 0;

    const options = feedData.map((unused, feedDataIndex) => {
      return {value: String(feedDataIndex), label: String(feedDataIndex)};
    });

    if (currentDataIndex > (options.length - 1)) {
      options.push({value: String(currentDataIndex), label: `${currentDataIndex} (Invalid)`});
    }

    return (
      <div className="feed-index-selector">
        <label htmlFor="choose-feed-label sr-only">{label}</label>
        <Select
          theme="dark"
          value={String(currentDataIndex)}
          onChange={this.onChangeFeedIndex}
          options={options}
        />
      </div>
    );
  }
}

FeedIndexSelector.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,

  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default observer(FeedIndexSelector);
