import classNames from 'classnames';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityActivateHoc from '../../hoc/entityActivateHoc';
import {STATE_PAUSED, STATE_PLAYING, STATE_STOPPED} from '../../../constants/displayItemConstants';
import {getMainUrlByFileId} from '../../../utils/contentsHelper';

/**
 * The amount of time that is allowed to drift before the video currentTime will be corrected.
 * @const {number}
 */
const ALLOWED_TIME_DRIFT = 99;

/**
 * The time conversion from seconds to milliseconds.
 * @const {number}
 */
const SECONDS_TO_MILLSECONDS = 1000;

/**
 * The DisplayVideo component.
 */
export class DisplayVideo extends React.Component {
  /**
   * The current playback state of the video element.
   */
  @observable videoState = STATE_STOPPED;

  /**
   * A reference to the video player element.
   *
   * @type {{current: HTMLElement}}
   */
  playerRef = React.createRef();

  /**
   * Triggered when the observed items (video) in the component update.
   */
  componentWillReact = () => {
    const {entity} = this.props;
    const video = entity.get('video');
    const videoEl = this.playerRef.current;
    if (!videoEl) {
      return;
    }

    this.updateVideoTime(video, videoEl);
    this.updateVideoState(video, videoEl);
  };

  /**
   * Updates the video element time if it drifts too far from the video entity time.
   *
   * @param {{time: number}} video
   * @param {HTMLElement} videoEl
   */
  updateVideoTime = (video, videoEl) => {
    const driftAmount = Math.abs((videoEl.currentTime * SECONDS_TO_MILLSECONDS) - video.time);

    // If the video time and the video element's time has drifted too much, then force the video element time.
    if (driftAmount > ALLOWED_TIME_DRIFT) {
      videoEl.currentTime = video.time / SECONDS_TO_MILLSECONDS;
    }
  };

  /**
   * Updates the state (paused/playing/stopped) of the video from the video entity state.
   *
   * @param {{state: string}} video
   * @param {HTMLElement} videoEl
   */
  @action updateVideoState = (video, videoEl) => {
    const currentState = this.videoState;
    const entityState = video.state;

    // If the state of the video has not changed, then we don't need to do anything.
    if (currentState === entityState) {
      return;
    }

    if (currentState === STATE_STOPPED) {
      if (entityState === STATE_PLAYING) {
        const playResponse = videoEl.play();
        if (playResponse.catch) {
          playResponse.catch(() => {
            // Do nothing but catching here prevent an annoying error message from being logged.
          });
        }
      } else if (entityState === STATE_PAUSED) {
        videoEl.pause();
      }
    } else if (currentState === STATE_PLAYING) {
      videoEl.pause();

      if (entityState === STATE_STOPPED) {
        videoEl.currentTime = 0;
      }
    } else if (currentState === STATE_PAUSED) {
      if (entityState === STATE_STOPPED) {
        videoEl.currentTime = 0;
      } else if (entityState === STATE_PLAYING) {
        const playResponse = videoEl.play();
        if (playResponse.catch) {
          playResponse.catch(() => {
            // Do nothing but catching here prevent an annoying error message from being logged.
          });
        }
      }
    }

    this.videoState = entityState;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {style, entity, className} = this.props;

    const entityId = entity.get('id');
    const video = entity.get('video');
    if (!video.fileId) {
      return null;
    }

    let videoType = String(video.type).trim();
    if (['mp4', 'webm', 'ogg'].indexOf(videoType) === -1) {
      videoType = 'video/mp4';
    }

    let videoSource = getMainUrlByFileId(video.fileId);

    /*
     * This is very important!
     * MobX won't fire the componentWillReact method when the state or time changes
     * unless it is referenced in this render().
     */
    video.state; // eslint-disable-line no-unused-expressions
    video.time; // eslint-disable-line no-unused-expressions

    return (
      <div id={entityId} className={classNames('display-video', className)} style={style}>
        <video
          id="display-video-el"
          height={style.height}
          width={style.width}
          loop={Boolean(video.loop)}
          preload="auto"
          ref={this.playerRef}
        >
          <source
            src={videoSource}
            type={videoType}
          />

          Your browser is not supported for playing this video. Please use the latest Chrome or Firefox browser.
        </video>
      </div>
    );
  }
}

DisplayVideo.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  style: PropTypes.object.isRequired,

  className: PropTypes.string,
};

export default entityActivateHoc(
  observer(DisplayVideo)
);
