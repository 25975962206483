import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';

import {getPlaceHolderCircleData} from './entities/placeholderCircle';
import {getPlaceHolderImageData} from './entities/placeholderImage';
import {getPlaceHolderLineData} from './entities/placeholderLine';
import {getPlaceHolderPlaylistData} from './entities/placeholderPlaylist';
import {getPlaceHolderRectangleData} from './entities/placeholderRectangle';
import {getPlaceHolderTextData} from './entities/placeholderText';
import {getPlaceHolderFeedImageData} from './entities/placeholderFeedImage';
import {getPlaceHolderFeedTextData} from './entities/placeholderFeedText';
import {getPlaceHolderPlaceholderData} from './entities/placeholderPlaceholder';
import {getPlaceHolderViewportData} from './entities/placeholderViewport';
import {getPlaceHolderWidgetData} from './entities/placeholderWidget';
import {ACTIVE_DELAY_MS} from '../../../../../constants/displayConstants';
import SelectContentModal from '../../../../modals/selectContent/SelectContentModal';
import SelectImageModal from '../../../../modals/selectImage/SelectImageModal';
import {actionAddEntityComponent} from '../../../../../display/components/action/actionAddEntityComponent';
import {actionInteractionComponent} from '../../../../../display/components/action/actionInteractionComponent';
import {decimalToFraction} from '../../../../../utils/mathHelper';

import './editorEntityAddButton.scss';

/**
 * The circle shape.
 * @const {string}
 */
const CIRCLE = 'circle';

/**
 * The rectangle shape.
 * @const {string}
 */
const RECTANGLE = 'rectangle';

/**
 * The line shape.
 * @const {string}
 */
const LINE = 'line';

/**
 * The EditorEntityAddButton component.
 *
 * @constructor
 */
export class EditorEntityAddButton extends React.Component {
  /**
   * Whether or not the choose content modal is open or not.
   *
   * @type {boolean}
   */
  @observable isContentModalOpen = false;

  /**
   * Whether or not the image choose/upload modal is open or not.
   *
   * @type {boolean}
   */
  @observable isImageModalOpen = false;

  /**
   * Whether or not the menu is open
   *
   * @type {boolean}
   */
  @observable isOpen = false;

  /**
   * Adds a new entity to the game.
   *
   * @param {{}} entityType
   * @param {{}|function} entityComponent
   */
  addNewEntity(entityType, entityComponent) {
    const {game, timer} = this.props;

    const startEndPadding = 500;

    const start = (game.endTime > startEndPadding) ? startEndPadding : 0;
    let end = (game.endTime > 0) ? game.endTime - startEndPadding : 0;
    if (end < 0) {
      end = game.endTime;
    }

    const activeDelay = ACTIVE_DELAY_MS + startEndPadding;
    const activeTime = (start + activeDelay > end) ? 0 : start + activeDelay;
    const size = {
      width: 200,
      height: 200,
    };

    const resolution = game.resolution;
    const centerOfDisplay = {
      width: (resolution.width / 2),
      height: (resolution.height / 2),
    };
    const position = {
      top: centerOfDisplay.height - (size.height / 2),
      left: centerOfDisplay.width - (size.width / 2),
    };

    const transitions = [];
    if (end > 0) {
      transitions.push({preset: 'fadeIn'});
      transitions.push({preset: 'fadeOut'});
    }

    const safeComponent = (typeof entityComponent === 'function') ? entityComponent({
      resolution,
      position,
      size,
      game,
    }) : entityComponent;

    const newEntitySource = lodash.defaultsDeep({}, safeComponent, {
      type: entityType,
      startTime: start,
      endTime: end,
      activeTime,
      setup: {
        size: {
          width: `${size.width}px`,
          height: `${size.height}px`,
        },
        position,
      },
      transitions: null,
    });

    const newEntityComponent = actionAddEntityComponent(newEntitySource, true);
    const actionComponents = Object.assign(
      {},
      newEntityComponent,
      actionInteractionComponent(true, false, false)
    );

    // Now activate the new item.
    const actionParams = {
      entityId: true
    };
    game.addAction(actionParams, actionComponents);

    timer.setTime(activeTime);
  }

  /**
   * Adds a new image entity when the button is clicked.
   */
  @action onAddImage = () => {
    this.closeMenu();
    this.isImageModalOpen = true;
  };

  /**
   * Triggered when the new image is selected by the modal.
   *
   * @param {{contentFiles: Array.<{id: number}>}} newContent
   */
  @action onImageSelected = (newContent) => {
    this.isImageModalOpen = false;

    if (newContent && lodash.has(newContent, 'contentFiles[0].id')) {
      this.addNewEntity('image', getPlaceHolderImageData(newContent.contentFiles[0].id));
    }
  };

  /**
   * Adds a new shape entity when the button is clicked.
   *
   * @param {string} shapeType
   * @returns {function}
   */
  onAddShape = (shapeType) => {
    return () => {
      this.closeMenu();
      switch (shapeType) {
        case CIRCLE:
          this.addNewEntity(CIRCLE, getPlaceHolderCircleData);
          break;
        case LINE:
          this.addNewEntity(LINE, getPlaceHolderLineData);
          break;
        case RECTANGLE:
          this.addNewEntity(RECTANGLE, getPlaceHolderRectangleData);
          break;
        default:
          // Do nothing.
          break;
      }
    };
  };

  /**
   * Adds a new text entity when the button is clicked.
   */
  onAddText = () => {
    this.closeMenu();
    this.addNewEntity('text', getPlaceHolderTextData());
  };

  /**
   * Adds new feed image entity.
   */
  @action onAddFeedImage = () => {
    this.closeMenu();

    const {
      /** @type {GameStore} */ game,
    } = this.props;

    const source = lodash.get(game, 'feed.feedSummary.images[0]', null);

    this.addNewEntity('feedImage', getPlaceHolderFeedImageData(source));
  };

  /**
   * Adds new feed text entity.
   */
  @action onAddFeedText = () => {
    this.closeMenu();
    this.addNewEntity('feedText', getPlaceHolderFeedTextData);
  };

  /**
   * Add a new placeholder entity.
   */
  @action onAddPlaceholder = () => {
    this.closeMenu();
    this.addNewEntity('placeholder', getPlaceHolderPlaceholderData);
  };

  /**
   * Adds new viewport entity.
   */
  @action onAddViewport = () => {
    this.closeMenu();
    this.isContentModalOpen = true;
  };

  /**
   * Triggered when the new viewport content is selected by the modal.
   *
   * @param {{contentFiles: Array.<{id: number}>}} newContent
   */
  @action onViewportContentSelected = (newContent) => {
    this.isContentModalOpen = false;

    const contentFile = lodash.get(newContent, 'contentFiles[0]');
    if (!contentFile || !contentFile.id) {
      return;
    }

    const isImage = ((contentFile.durationMs || 0) <= 0);
    const fileType = String(contentFile.masterFileName).split('.')[1];

    let aspectRatio;
    try {
      const source = JSON.parse(contentFile.create24ProjectJson);
      const height = lodash.get(source, 'resolution.height');
      const width = lodash.get(source, 'resolution.width');

      if (height && width) {
        aspectRatio = decimalToFraction(width / height);
      }
    } catch (parseError) {
      // Do nothing and just treat the aspect ratio as undefined.
    }

    this.addNewEntity('viewport', getPlaceHolderViewportData(
      newContent.id,
      isImage,
      contentFile.id,
      `video/${fileType}`,
      aspectRatio
    ));
  };

  /**
   * Adds a new playlist entity when the button is clicked.
   */
  onAddPlaylist = () => {
    this.closeMenu();
    this.addNewEntity('playlist', getPlaceHolderPlaylistData());
  };

  /**
   * Adds a new widget entity when the button is clicked.
   */
  onAddWidget = () => {
    this.closeMenu();
    this.addNewEntity('widget', getPlaceHolderWidgetData());
  };

  /**
   * Detects whether or not to close the main dropdown
   *
   * @param {{}} e
   */
  @action toggleMainDropdown = (e) => {
    if (!e.currentTarget.classList || !e.currentTarget.classList.contains('child-dropdown')) {
      this.isOpen = !this.isOpen;
    }
  };

  /**
   * Close menu
   */
  @action closeMenu = () => {
    this.isOpen = false;
  };

  /**
   * Renders the add feed options if feeds are available.
   *
   * @returns {?{}}
   */
  renderFeedOptions = () => {
    const {
      /** @type {GameStore} */ game,
    } = this.props;

    if (!game.feed.feedId && !game.feed.feedSource) {
      return null;
    }

    return (
      <DropdownItem tag="div" className="child-dropdown">
        <UncontrolledDropdown direction="right">
          <DropdownToggle tag="div">
            <i className="fa fa-feed" />
            Feed
            <i className="fad fad-caret-right pull-right" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={this.onAddFeedImage} tag="div">
              <i className="fa fa-picture-o" />
              Image
            </DropdownItem>
            <DropdownItem onClick={this.onAddFeedText} tag="div">
              <i className="fa fa-font" />
              Text
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </DropdownItem>
    );
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isLayout} = this.props;

    const canSeeViewports = isLayout;

    return (
      <div className="entity-add-button">
        <Dropdown direction="right" toggle={this.toggleMainDropdown} isOpen={this.isOpen}>
          <DropdownToggle size="sm">
            <i className="fa fa-plus" />
          </DropdownToggle>

          <DropdownMenu tag="div">
            <DropdownItem onClick={this.onAddImage}>
              <i className="fa fa-picture-o" />
              Image
            </DropdownItem>

            <DropdownItem tag="div" className="child-dropdown">
              <UncontrolledDropdown direction="right">
                <DropdownToggle tag="div">
                  <i className="fa fa-circle" />
                  Shape
                  <i className="fad fad-caret-right pull-right" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this.onAddShape(CIRCLE)} tag="div">
                    <i className="fa fa-circle" />
                    Circle
                  </DropdownItem>
                  <DropdownItem onClick={this.onAddShape(RECTANGLE)} tag="div">
                    <i className="fa fa-square" />
                    Rectangle
                  </DropdownItem>
                  <DropdownItem onClick={this.onAddShape(LINE)} tag="div">
                    <i className="fa fa-arrows-h" />
                    Line
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </DropdownItem>

            <DropdownItem onClick={this.onAddText}>
              <i className="fa fa-font" />
              Text
            </DropdownItem>

            {this.renderFeedOptions()}

            {(canSeeViewports) && (
              <>
                <DropdownItem onClick={this.onAddViewport}>
                  <i className="fa fa-television" />
                  Viewport (Layout)
                </DropdownItem>
                <DropdownItem onClick={this.onAddPlaceholder}>
                  <i className="fa fa-map-pin" />
                  Placeholder (Layout)
                </DropdownItem>
                <DropdownItem onClick={this.onAddPlaylist}>
                  <i className="fa fa-list-ul" />
                  Playlist (Layout)
                </DropdownItem>
                <DropdownItem onClick={this.onAddWidget}>
                  <i className="fa fa-cog" />
                  Widget (Layout)
                </DropdownItem>
              </>
            )}
          </DropdownMenu>
        </Dropdown>

        <SelectImageModal
          isOpen={this.isImageModalOpen}
          onComplete={this.onImageSelected}
        />

        {(canSeeViewports) && (
          <SelectContentModal
            isOpen={this.isContentModalOpen}
            onComplete={this.onViewportContentSelected}
          />
        )}
      </div>
    );
  }
}

EditorEntityAddButton.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,
  timer: MobxPropTypes.observableObject.isRequired,

  isLayout: PropTypes.bool,
};

EditorEntityAddButton.defaultProps = {
  isLayout: false,
};

export default observer(EditorEntityAddButton);
