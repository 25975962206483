/**
 * The time component.
 *
 * @param {number} start
 * @param {?number} end
 * @param {?number} active
 * @returns {{time: {start: number, end: ?number, active: number}}}
 */
export function timeComponent(start, end, active) {
  return {
    time: {
      start,
      end,
      active: (active || active === 0) ? active : start,
    },
  };
}

/**
 * Gets the time component from the source item.
 * If no startTime is given, the item will start on the first (0) frame.
 *
 * @param {{startTime: number, endTime: number, activeTime: ?number}} item
 * @returns {{time: {start: number, end: ?number, active: number}}}
 */
export function getTimeFromSource(item) {
  return timeComponent(
    item.startTime || 0,
    item.endTime || null,
    item.activeTime || null
  );
}

/**
 * Gets the time component for a transition item.
 * If no startTime is given, the item will start on the first (0) frame.
 *
 * @param {{startTime: number, endTime: number}} transition
 * @returns {{time: {start: number, end: ?number}}}
 */
export function getTransitionTime(transition) {
  return getTimeFromSource(transition);
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getTimeForSource(entity) {
  if (!entity.has('time')) {
    return {};
  }

  const time = entity.get('time');
  const output = {
    startTime: time.start,
  };

  if (time.end) {
    output.endTime = time.end;
  }
  if (time.active !== time.start) {
    output.activeTime = time.active;
  }

  return output;
}

/**
 * Parses a transition back into source JSON.
 *
 * @param {{time: {}}} transition
 * @returns {{}}
 */
export function getTimeForTransitionSource(transition) {
  if (!transition || !transition.time) {
    return {};
  }

  const time = transition.time;
  if (!time.start && !time.end) {
    return {};
  }

  return {
    startTime: time.start,
    endTime: time.end,
  };
}
