/* eslint-disable import/default */

import {createBrowserHistory as createHistory} from 'history';
import {configure} from 'mobx';
import {Provider} from 'mobx-react';
import React from 'react';
import {render} from 'react-dom';
import {Router} from 'react-router-dom';
import {AppContainer} from 'react-hot-loader';

// Polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'url-search-params-polyfill';
import 'whatwg-fetch';
import 'objectFitPolyfill';

import App from './components/app/App';
import stores, {hotReloadStores} from './stores/index';
import {checkStartState} from './preloads/checkState';
import preloadData from './preloads/index';
import {initThunks, hotReloadThunks} from './thunks/index';
import {enableMobXLogger} from './utils/debugHelper';
import {attachListenerToHistory, registerHistory} from './utils/history';
import env from './config/env';

const routerHistory = createHistory();
registerHistory(routerHistory);

if (env === 'development') {
  configure({enforceActions: 'observed'});
}

enableMobXLogger(true);

preloadData();

attachListenerToHistory();

initThunks();

checkStartState();

hotReloadStores(stores);
hotReloadThunks();

const renderApplication = (Component) => {
  render(
    <AppContainer>
      <Provider {...stores}>
        <Router history={routerHistory}>
          <Component />
        </Router>
      </Provider>
    </AppContainer>,
    document.getElementById('app')
  );
};

renderApplication(App);
