import {ContentState, Editor, EditorState} from 'draft-js';
import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import FeedSourceControls from './components/feedSourceControls/FeedSourceControls';
import AlignButtons from '../editText/components/alignButtons/AlignButtons';
import ColorSelect from '../editText/components/colorSelect/ColorSelect';
import FontSelect from '../editText/components/fontSelect/FontSelect';
import FontSizeSelect from '../editText/components/fontSizeSelect/FontSizeSelect';
import LetterSpacingSelect from '../editText/components/letterSpacingSelect/LetterSpacingSelect';
import StyleButtons from '../editText/components/styleButtons/StyleButtons';
import VerticalAlignButtons from '../editText/components/verticalAlignButtons/VerticalAlignButtons';
import {customStylesMap, styleToClassMap} from '../editText/constants/styleContants';
import {stateToMarkdown} from '../editText/utils/stateToMarkdown';
import {stateFromHtml} from '../editText/utils/stateFromHtml';
import EditTimelineControls from '../editTimeline/EditTimelineControls';
import {actionUpdateComponent} from '../../../display/components/action/actionUpdateComponent';
import {
  feedTextComponent,
  makeContentText,
  parseMarkdownToHtml
} from '../../../display/components/type/feedTextComponent';

import 'draft-js/dist/Draft.css';
import './editFeedTextControls.scss';

/**
 * The EditFeedTextControls component.
 */
export class EditFeedTextControls extends React.Component {
  /**
   * The rich text editor state.
   *
   * @type {?EditorState}
   */
  @observable textState = null;

  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);

    let text = {};
    if (this.props.entity && this.props.entity.has('feedText')) {
      text = this.props.entity.get('feedText');
    }

    this.buildTextState(text);
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.entity && this.props.entity && prevProps.entity.get('id') === this.props.entity.get('id')) {
      return;
    }

    let text = {};
    if (this.props.entity && this.props.entity.has('feedText')) {
      text = this.props.entity.get('feedText');
    }

    this.buildTextState(text);
  }

  /**
   * Builds a new text state.
   *
   * @param {{isHtml: boolean, value: string, rawValue: string}} text
   */
  @action buildTextState = (text) => {
    let startingContent = null;
    if (text.isHtml) {
      const startHtml = String(text.value || '');
      startingContent = stateFromHtml(startHtml);
    } else {
      startingContent = ContentState.createFromText(text.value || '');
    }

    this.textState = EditorState.createWithContent(startingContent);
  };

  /**
   * Clear selection
   */
  @action selectNone = () => {
    const currentSelection = this.textState.getSelection();
    const currentContent = this.textState.getCurrentContent();

    const firstBlock = currentContent.getFirstBlock();
    const newSelection = currentSelection.merge({
      anchorKey: firstBlock.get('key'),
      anchorOffset: 0,
      focusKey: firstBlock.get('key'),
      focusOffset: 0,
      hasFocus: false,
    });

    let newEditorState = EditorState.acceptSelection(this.textState, newSelection);
    newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());
    this.textState = newEditorState;
  };

  /**
   * Selects all text in the text editor.
   *
   * @returns {EditorState}
   */
  @action selectAll = () => {
    const currentSelection = this.textState.getSelection();
    const currentContent = this.textState.getCurrentContent();

    const firstBlock = currentContent.getFirstBlock();
    const lastBlock = currentContent.getLastBlock();
    const newSelection = currentSelection.merge({
      anchorKey: firstBlock.get('key'),
      anchorOffset: 0,
      focusKey: lastBlock.get('key'),
      focusOffset: lastBlock.get('text').length,
      hasFocus: true,
    });

    let newEditorState = EditorState.acceptSelection(this.textState, newSelection);
    newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());
    this.textState = newEditorState;

    return this.textState;
  };

  /**
   * Whether or not the editor has any text selected.
   *
   * @returns {boolean}
   */
  @action hasSelection = () => {
    const currentSelection = this.textState.getSelection();

    if (!currentSelection) {
      return false;
    }

    if (currentSelection.get('anchorKey') !== currentSelection.get('focusKey')) {
      return true;
    } else if (currentSelection.get('anchorOffset') !== currentSelection.get('focusOffset')) {
      return true;
    }

    return false;
  };

  /**
   * Forces focus on the currently selected item.
   *
   * @returns {EditorState}
   */
  @action forceSelectionFocus = () => {
    const currentSelection = this.textState.getSelection();

    if (!currentSelection) {
      return this.textState;
    } else if (currentSelection.get('hasFocus')) {
      return this.textState;
    }

    const newSelection = currentSelection.merge({
      hasFocus: true,
    });

    let newEditorState = EditorState.acceptSelection(this.textState, newSelection);
    newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());
    this.textState = newEditorState;

    return this.textState;
  };

  /**
   * Selects all the text if there is no selection.
   *
   * @param {boolean=} doNotForce
   * @returns {EditorState}
   */
  selectAllIfNoSelection = (doNotForce) => {
    if (!this.hasSelection()) {
      this.selectAll();
    } else if (!doNotForce) {
      this.forceSelectionFocus();
    }
    return this.textState;
  };

  /**
   * Updates the text state when it is changed.
   *
   * @param {EditorState} editorState
   */
  @action onChangeTextState = (editorState) => {
    const {entity, game} = this.props;
    this.textState = editorState;
    const currentFeedText = entity.get('feedText');

    const newValue = stateToMarkdown(editorState);

    const actionParams = {
      entityId: entity.get('id'),
    };
    game.addAction(actionParams, actionUpdateComponent(
      feedTextComponent({
        value: parseMarkdownToHtml(newValue),
        isHtml: true,
        rawValue: newValue,
      }, currentFeedText)
    ));
    this.selectNone();
  };

  /**
   * Triggered when a block style changes.
   *
   * @param {{}} contentBlock
   * @returns {?string}
   */
  onChangeBlockStyle = (contentBlock) => {
    const type = contentBlock.getType();

    let newClassName = null;
    lodash.forEach(styleToClassMap, (className, style) => {
      if (type === style) {
        newClassName = className;
        return false;
      }
      return true;
    });

    return newClassName;
  };

  /**
   * Get text-replaced textState to push to editor
   *
   * @returns {*}
   */
  getRenderText() {
    const {game, entity} = this.props;

    const feedText = entity.get('feedText');
    if (!feedText) {
      return null;
    }

    const feedContent = game.feed.getFeedData(feedText, {
      replace: false,
      default: 'Select Feed Source',
    });

    const fixed = makeContentText(feedText, feedContent.join('<br />'));
    let startingContent = null;
    if (feedText.isHtml) {
      startingContent = stateFromHtml(fixed);
    } else {
      startingContent = ContentState.createFromText(fixed);
    }

    return EditorState.createWithContent(startingContent);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;

    const renderable = this.getRenderText();

    return (
      <div className="edit-feed-text-controls">
        <div className="text-group">
          <h5 className="sidebar-title">Text</h5>
          <div className="RichEditor-root">
            <div className="RichEditor-editor text-control-editor">
              <Editor
                blockStyleFn={this.onChangeBlockStyle}
                customStyleMap={customStylesMap}
                editorState={renderable}
                onChange={this.onChangeTextState}
                readOnly={true}
              />
            </div>
          </div>
          <FeedSourceControls entity={entity} game={game} />
        </div>
        <div className="text-style control-group">
          <div className="group-header">
            <span className="group-header-label">Style</span>
          </div>
          <div className="group-controls">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="font-selector">Typeface</label>
                  <div className="type-color-controls">
                    <FontSelect
                      editorState={this.textState}
                      onChangeTextState={this.onChangeTextState}
                      beforeChange={this.selectAllIfNoSelection}
                      afterChange={this.selectNone}
                    />
                    <ColorSelect
                      editorState={this.textState}
                      onChangeTextState={this.onChangeTextState}
                      beforeChange={this.selectAllIfNoSelection}
                      afterChange={this.selectNone}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="font-size-selector">Size</label>
                  <FontSizeSelect
                    editorState={this.textState}
                    onChangeTextState={this.onChangeTextState}
                    beforeChange={this.selectAll}
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="form-group">
                  <div><label>Setting</label></div>
                  <StyleButtons
                    editorState={this.textState}
                    onChangeTextState={this.onChangeTextState}
                    beforeChange={this.selectAllIfNoSelection}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <div><label>Alignment</label></div>
                  <AlignButtons
                    editorState={this.textState}
                    onChangeTextState={this.onChangeTextState}
                    beforeChange={this.forceSelectionFocus}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="form-group">
                  <div><label>Vertical Alignment</label></div>
                  <VerticalAlignButtons
                    entity={entity}
                    game={game}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="letter-spacing-selector">Spacing</label>
                  <LetterSpacingSelect
                    editorState={this.textState}
                    onChangeTextState={this.onChangeTextState}
                    beforeChange={this.selectAllIfNoSelection}
                  />
                </div>
              </div>
              {/* <div className="col">*/}
              {/*  <div className="form-group">*/}
              {/*    <label htmlFor="line-height-selector">Line Height</label>*/}
              {/*    <LineHeightSelect*/}
              {/*      editorState={this.textState}*/}
              {/*      onChangeTextState={this.onChangeTextState}*/}
              {/*      beforeChange={this.selectAllIfNoSelection}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/* </div>*/}
            </div>
          </div>
        </div>
        <EditTimelineControls entity={entity} game={game} />
      </div>
    );
  }
}

EditFeedTextControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditFeedTextControls);
