import classNames from 'classnames';
import {RichUtils} from 'draft-js';
import PropTypes from 'prop-types';
import React from 'react';

import {BOLD, ITALIC, UNDERLINE} from '../../constants/styleContants';

import './styleButtons.scss';

/**
 * The list of style buttons.
 *
 * @type {Array.<{label: string, style: string}>}
 */
const STYLE_BUTTON_TYPES = [
  {label: 'Bold', style: BOLD, icon: 'fa-bold'},
  {label: 'Italic', style: ITALIC, icon: 'fa-italic'},
  {label: 'Underline', style: UNDERLINE, icon: 'fa-underline'},
];

/**
 * The StyleButtons component.
 *
 * @param {{}} params
 * @param {{}} params.editorState
 * @param {function(EditorState)} params.onChangeTextState
 * @param {function()=} params.beforeChange
 * @returns {React.Component}
 */
export const StyleButtons = ({editorState, onChangeTextState, beforeChange}) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  /**
   * Updates the editor state with a new style when a button is clicked.
   *
   * @param {string} clickedStyle
   * @returns {function()}
   */
  const onClick = (clickedStyle) => {
    return () => {
      let changeState = editorState;
      if (beforeChange) {
        changeState = beforeChange();
      }

      const newEditorState = RichUtils.toggleInlineStyle(changeState, clickedStyle);
      onChangeTextState(newEditorState);
    };
  };

  return (
    <div className="style-buttons form-buttons">
      {STYLE_BUTTON_TYPES.map((styleButton) => {
        const classes = {
          'active': currentStyle.has(styleButton.style)
        };

        return (
          <button
            className={classNames('btn btn-sm btn-dark form-button', classes)}
            type="button"
            key={styleButton.label}
            onClick={onClick(styleButton.style)}
          >
            <i className={`fa ${styleButton.icon}`} />
          </button>
        );
      })}
    </div>
  );
};

StyleButtons.propTypes = {
  editorState: PropTypes.object.isRequired,
  onChangeTextState: PropTypes.func.isRequired,

  beforeChange: PropTypes.func,
};

export default StyleButtons;
