import lodash from 'lodash';

import {positionComponent} from '../common/positionComponent';
import {sizeComponent} from '../common/sizeComponent';

/**
 * The default line color.
 * @const {string}
 */
const DEFAULT_COLOR = '#000000';

/**
 * The default line thickness.
 * @const {number}
 */
const DEFAULT_THICKNESS = 3;

/**
 * The line component.
 *
 * @param {{x: number, y: number}} startPoint
 * @param {{x: number, y: number}} endPoint
 * @param {number} thickness
 * @param {{color: string}=} style
 * @returns {{line: {}}}
 */
export function lineComponent(startPoint, endPoint, thickness, style) {
  return {
    line: {
      startPoint,
      endPoint,
      thickness,
      style
    },
  };
}

/**
 * Gets the line component from the source item.
 *
 * @param {{line: {startPoint: {}, endPoint: {}, thickness: number, style: {}}}} item
 * @returns {{line: {startPoint: {}, endPoint: {}, thickness: number, style: {}}}}
 */
export function getLineFromSource(item) {
  if (!item.line) {
    return {};
  }

  const line = lodash.defaultsDeep({}, item.line, {
    style: {
      color: DEFAULT_COLOR,
    },
    thickness: DEFAULT_THICKNESS,
  });

  return lineComponent(
    line.startPoint,
    line.endPoint,
    line.thickness,
    line.style,
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getLineForSource(entity) {
  if (!entity.has('line')) {
    return {};
  }

  const line = entity.get('line');
  return {
    line: {
      startPoint: line.startPoint,
      endPoint: line.endPoint,
      thickness: line.thickness,
      style: line.style,
    },
  };
}

/**
 * Parses a line component into an equivalent position component.
 *
 * @param {{startPoint: {}, endPoint: {}}} line
 * @returns {{position: {top: number, left: number}}}
 */
export function parsePositionFromLine(line) {
  if (!line || !line.startPoint || !line.endPoint) {
    return null;
  }

  const {startPoint, endPoint} = line;

  const coords = {
    left: 0,
    top: 0,
  };

  if (startPoint.x < endPoint.x) {
    if (startPoint.y <= endPoint.y) {
      // Start point is the top left corner of the line box.
      coords.left = startPoint.x;
      coords.top = startPoint.y;
    } else {
      // Start point is the bottom left corner of the line box.
      coords.left = startPoint.x;
      coords.top = endPoint.y;
    }
  } else {
    if (endPoint.y <= startPoint.y) { // eslint-disable-line no-lonely-if
      // End point is the top left corner of the line box.
      coords.left = endPoint.x;
      coords.top = endPoint.y;
    } else {
      // End point is the bottom left corner of the line box.
      coords.left = endPoint.x;
      coords.top = startPoint.y;
    }
  }

  return positionComponent({
    topPosition: coords.top,
    leftPosition: coords.left,
  }).position;
}

/**
 * Parses a line component into an equivalent size component.
 *
 * @param {{startPoint: {}, endPoint: {}}} line
 * @returns {{size: {width: number, height: number}}}
 */
export function parseSizeFromLine(line) {
  if (!line || !line.startPoint || !line.endPoint) {
    return null;
  }

  const {startPoint, endPoint} = line;

  const width = Math.abs(endPoint.x - startPoint.x);
  const height = Math.abs(endPoint.y - startPoint.y);

  return sizeComponent({width, height}).size;
}

/**
 * Parses a new position into a new line component start point and end point.
 *
 * @param {{}} currentLine
 * @param {{}} newPosition
 * @returns {?{endPoint: {x: number, y: number}, startPoint: {x: number, y: number}}}
 */
export function parsePositionToLineUpdates(currentLine, newPosition) { // eslint-disable-line complexity
  if (!currentLine || !currentLine.startPoint || !currentLine.endPoint) {
    return null;
  }

  if (newPosition.top == null && newPosition.left == null) {
    return null;
  }

  const hasLeft = (newPosition.left != null);
  const hasTop = (newPosition.top != null);

  const {startPoint, endPoint} = currentLine;

  const newStartPoint = {x: 0, y: 0};
  const newEndPoint = {x: 0, y: 0};

  const width = Math.abs(endPoint.x - startPoint.x);
  const height = Math.abs(endPoint.y - startPoint.y);

  if (startPoint.x < endPoint.x) {
    if (startPoint.y <= endPoint.y) {
      // Start point is the top left corner of the line box.
      newStartPoint.x = (hasLeft) ? newPosition.left : startPoint.x;
      newStartPoint.y = (hasTop) ? newPosition.top : startPoint.y;
      newEndPoint.x = newStartPoint.x + width;
      newEndPoint.y = newStartPoint.y + height;
    } else {
      // Start point is the bottom left corner of the line box.
      newStartPoint.x = (hasLeft) ? newPosition.left : startPoint.x;
      newEndPoint.y = (hasTop) ? newPosition.top : endPoint.y;
      newEndPoint.x = newStartPoint.x + width;
      newStartPoint.y = newEndPoint.y + height;
    }
  } else {
    if (endPoint.y <= startPoint.y) { // eslint-disable-line no-lonely-if
      // End point is the top left corner of the line box.
      newEndPoint.x = (hasLeft) ? newPosition.left : endPoint.x;
      newEndPoint.y = (hasTop) ? newPosition.top : endPoint.y;
      newStartPoint.x = newEndPoint.x + width;
      newStartPoint.y = newEndPoint.y + height;
    } else {
      // End point is the bottom left corner of the line box.
      newEndPoint.x = (hasLeft) ? newPosition.left : endPoint.x;
      newStartPoint.y = (hasTop) ? newPosition.top : startPoint.y;
      newStartPoint.x = newEndPoint.x + width;
      newEndPoint.y = newStartPoint.y + height;
    }
  }

  return {
    startPoint: newStartPoint,
    endPoint: newEndPoint,
  };
}
