import lodash from 'lodash';

import activeContentStore from '../stores/active/activeContentStore';
import apiClientStore from '../stores/api/apiClientStore';
import apiContentLibrariesStore from '../stores/api/apiContentLibrariesStore';
import apiLoginStore from '../stores/api/apiLoginStore';
import apiProductsStore from '../stores/api/apiProductsStore';
import apiFeedSummaryStore from '../stores/api/apiFeedSummaryStore';
import apiUserStore from '../stores/api/apiUserStore';
import sessionStore from '../stores/active/sessionStore';
import {CREATED_CONTENT} from '../constants/libraryTypeConstants';

/**
 * Preloads the initial seed data.
 */
function preloadData() {
  const user = apiLoginStore.preloadCurrentUser();
  apiProductsStore.preloadActiveProduct();

  const {userId, clientId, productId} = sessionStore;

  if (userId) {
    apiUserStore.fetchUserById(userId);
  }

  if (clientId) {
    apiClientStore.fetchClientById(clientId);
    apiProductsStore.fetchProducts(clientId);
    const includeGlobals = lodash.includes(
      ['Administrator', 'CSR', 'Video Content Contractor', 'Video Content Creator'],
      lodash.get(user, 'user.role')
    );
    apiFeedSummaryStore.fetchFeedsByClientId(clientId, includeGlobals);
  }

  if (userId && clientId && productId) {
    const filters = {
      productId: productId,
      clientId: clientId,
      userId: userId,
      contentLibraryTypeId: CREATED_CONTENT,
    };

    const storeKey = apiContentLibrariesStore.fetchContentLibraries(filters);
    activeContentStore.setLibraryStoreKey(CREATED_CONTENT, storeKey);
  }
}

export default preloadData;
