import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import LoadingIcon from '../../../loadingIcon/LoadingIcon';
import inject from '../../../../hoc/injectHoc';
import {STATE_PENDING} from '../../../../../constants/asyncConstants';

import './employeeClientDropdown.scss';

/**
  * The minimum length that a search query must be.
  *
  * @type {number}
  */
const MIN_SEARCH_LENGTH = 2;

/**
  * The length of time (milliseconds) until a search request is made.
  *
  * @type {number}
  */
const SEARCH_TIMEOUT_LENGTH = 250;

/**
 * The EmployeeClientDropdown component.
 */
export class EmployeeClientDropdown extends React.Component {
  /**
  * The search text for the client dropdown input.
  *
  * @type {Observable<string>}
  */
  @observable clientSearchText = '';

  /**
   * Track the last client ID sent to the API
   *
   * @type {number}
   */
  @observable lastClientId = 0;

  /**
   * Calls the client search request using Lodash's debounce.
   * Waits SEARCH_TIMEOUT_LENGTH milliseconds since the last request to fire.
   *
   * @type {function}
   */
  debounceClientsSearch = lodash.debounce(() => {
    this.fetchClientsBySearch();
  }, SEARCH_TIMEOUT_LENGTH);

  /**
   * Handles the selection of a new client.
   *
   * @param {{}} client
   */
  @action onClientChange = (client) => {
    this.lastClientId = client.id;
    this.props.apiClientSwitchStore.getNewClientToken(client.id);
  };

  /**
   * Fetches the clients by search term.
   */
  fetchClientsBySearch = () => {
    this.props.apiClientsBySearchStore.fetchClientsBySearch(this.clientSearchText);
  };

  /**
   * Handles when the client search input changes.
   * The search query is rate limited so we don't fire requests on every change.
   *
   * @param {{}} changeEvent
   */
  @action onClientSearchChange = (changeEvent) => {
    this.clientSearchText = changeEvent.target.value;

    if (this.clientSearchText.length < MIN_SEARCH_LENGTH) {
      return;
    }

    this.debounceClientsSearch();
  };

  /**
   * Render a loading section if state is pending, null otherwise
   *
   * @returns {{}}
   */
  renderLoader() {
    const {apiClientSwitchStore} = this.props;

    if (apiClientSwitchStore.getState(this.lastClientId) !== STATE_PENDING) {
      return null;
    }

    return (
      <div id="employee-client-dropdown" className="dropdown-menu" aria-labelledby="client-button">
        <LoadingIcon />
      </div>
    );
  }

  /**
   * Render main content
   *
   * @returns {{}}
   */
  renderMain() {
    const searchTextLength = this.clientSearchText.length;

    return (
      <div id="employee-client-dropdown" className="dropdown-menu" aria-labelledby="client-button">
        <div className="client-input-wrapper">
          <input
            type="text"
            className="client-input"
            onChange={this.onClientSearchChange}
            value={this.clientSearchText}
            placeholder="Search clients..."
          />
        </div>

        {this.props.apiClientsBySearchStore.case(this.clientSearchText, {
          pre: () => (<p className="status-text">{`${MIN_SEARCH_LENGTH} Characters Required to Search`}</p>),
          pending: () => (<LoadingIcon size="xs" />),
          rejected: () => (
            <p className="w24-error small-text">There was an error searching for clients.</p>
          ),
          fulfilled: (clients) => (
            (clients.length === 0) ? (
              <p className="status-text">
                {(searchTextLength < MIN_SEARCH_LENGTH) ? (
                  `${MIN_SEARCH_LENGTH} Characters Required to Search`
                ) : (
                  'No clients match your search'
                )}
              </p>
            ) : (
              clients.map((client) => (
                <button
                  type="button"
                  key={client.id}
                  className="dropdown-item"
                  onClick={() => this.onClientChange(client)}
                >{client.name}</button>
              ))
            )
          )
        })}
      </div>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return this.renderLoader() || this.renderMain();
  }
}

EmployeeClientDropdown.propTypes = {
  apiClientSwitchStore: MobxPropTypes.observableObject,
  apiClientsBySearchStore: MobxPropTypes.observableObject,
  sessionStore: MobxPropTypes.observableObject,
};

EmployeeClientDropdown.wrappedComponent = {};
EmployeeClientDropdown.wrappedComponent.propTypes = {
  apiClientSwitchStore: MobxPropTypes.observableObject.isRequired,
  apiClientsBySearchStore: MobxPropTypes.observableObject.isRequired,
  sessionStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(EmployeeClientDropdown.wrappedComponent.propTypes)(
  observer(EmployeeClientDropdown)
);
