/* eslint-disable react/no-multi-comp, react/display-name */
import React from 'react';
import {Route, Switch} from 'react-router-dom';

import MainLayout from './layouts/mainLayout/MainLayout';

import DashboardPage from './pages/dashboard/DashboardPage';
import ProductSelectionPage from './pages/productSelection/ProductSelectionPage';
import EditorPage from './pages/editor/EditorPage';
import ContentPage from './pages/content/ContentPage';
import LoginPage from './pages/login/LoginPage';
import LogoutPage from './pages/logout/LogoutPage';
import NewClipPage from './pages/newClip/NewClipPage';
import NewClipTypePage from './pages/newClipType/NewClipTypePage';
import NotFoundPage from './pages/notFound/NotFoundPage';
import RenderPage from './pages/render/RenderPage';
import TransitionWrapper from './layouts/transitionWrapper/TransitionWrapper';

import * as routePaths from './routePaths';

/**
 * Builds a page using the main layout.
 *
 * @param {{}} PageComponent
 * @returns {function}
 */
function layoutPage(PageComponent) {
  return (props) => {
    return (
      <MainLayout
        page={<PageComponent {...props} />}
        {...props}
      />
    );
  };
}

/**
 * Allows smooth transitions on components.
 *
 * @param {{}} PageComponent
 * @returns {function}
 */
function transition(PageComponent) {
  return (props) => (
    <TransitionWrapper>
      <PageComponent {...props} />
    </TransitionWrapper>
  );
}

/**
 * Builds the routes for the app.
 *
 * @returns {*}
 */
export function RoutesSwitch() {
  return (
    <Switch>
      <Route exact path={routePaths.homeRoute} render={layoutPage(ContentPage)} />
      <Route path={routePaths.dashboardRoute} render={layoutPage(transition(DashboardPage))} />
      <Route path={routePaths.productSelectionRoute} render={layoutPage(ProductSelectionPage)} />
      <Route path={routePaths.editorRoute} render={layoutPage(EditorPage)} />
      <Route path={routePaths.loginRoute} component={LoginPage} />
      <Route path={routePaths.logoutRoute} component={LogoutPage} />
      <Route path={routePaths.newClipTypeRoute} component={layoutPage(NewClipTypePage)} />
      <Route path={routePaths.passwordResetRoute} component={LoginPage} />
      <Route path={routePaths.newClipRoute} component={layoutPage(transition(NewClipPage))} />
      <Route path={routePaths.renderRoute} component={RenderPage} />
      <Route path="*" render={layoutPage(NotFoundPage)} />
    </Switch>
  );
}

export default RoutesSwitch;
