import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import inject from '../../../../hoc/injectHoc';
import {STATE_FULFILLED} from '../../../../../constants/asyncConstants';
import EmployeeClientDropdown from '../employeeClientDropdown/EmployeeClientDropdown';

import './clientNavItem.scss';

/**
 * The client nav item
 *
 * @param {{}} param
 * @param {{}} param.apiClientStore
 * @param {{}} param.apiLoginStore
 * @param {{}} param.sessionStore
 * @returns {{}}
 */
const ClientNavItem = ({
  apiClientStore,
  apiLoginStore,
  sessionStore
}) => {
  if (apiClientStore.state !== STATE_FULFILLED) {
    return null;
  }

  if (apiLoginStore.state !== STATE_FULFILLED) {
    return null;
  }

  /**
   * The active client.
   *
   * @type {{}}
   */
  const activeClient = apiClientStore.getFulfilled(sessionStore.clientId);

  /**
   * The current user.
   *
   * @type {{}}
   */
  const currentUser = apiLoginStore.getFulfilled();

  if (!activeClient || !currentUser) {
    return null;
  }

  /**
   * Handles the selection of a new client.
   *
   * @param {{}} client
   */
  function onClientChange(client) {
    sessionStore.setClientId(client.id);
  }

  if (!currentUser.isEmployee) {
    if (currentUser.assignedClients.length > 1) {
      // If the user is not an employee, and the client has multiple products.
      const clients = currentUser.assignedClients;

      return (
        <div id="client-nav-item">
          <button
            type="button"
            id="client-button"
            className="w24-navbar-control w24-transparent-button dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {activeClient.name}
          </button>
          <div className="dropdown-menu" aria-labelledby="client-button">
            {clients.map((client) => (
              <button
                type="button"
                key={client.id}
                className="dropdown-item"
                onClick={() => onClientChange(client)}
              >{client.name}</button>
            ))}
          </div>
        </div>
      );
    }

    // If the user is not an employee, and only has one product.
    return (
      <div id="client-nav-item">
        <p className="w24-navbar-control">{activeClient.name}</p>
      </div>
    );
  }

  // If the user is an employee.
  return (
    <div id="client-nav-item">
      <button
        type="button"
        id="client-button"
        className="w24-navbar-control w24-transparent-button dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {activeClient.name}
      </button>
      <EmployeeClientDropdown />
    </div>
  );
};

ClientNavItem.propTypes = {
  apiClientStore: MobxPropTypes.observableObject,
  apiLoginStore: MobxPropTypes.observableObject,
  sessionStore: MobxPropTypes.observableObject,
};

ClientNavItem.wrappedComponent = {
  propTypes: {
    apiClientStore: MobxPropTypes.observableObject.isRequired,
    apiLoginStore: MobxPropTypes.observableObject.isRequired,
    sessionStore: MobxPropTypes.observableObject.isRequired,
  }
};

export default inject(ClientNavItem)(
  observer(ClientNavItem)
);
