import {action, extendObservable, observable} from 'mobx';

import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import {apiStore, getCase} from '../../../utils/apiStore';
import apiContentsStore from '../apiContentsStore';
import apiContentSearchStore from '../apiContentSearchStore';
import activeContentStore from '../../active/activeContentStore';

/**
 * The content ids store.
 */
class DeleteContentStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * Track the error status of the store
   *
   * @type {Observable<{error: ?Error}>}
   */
  @observable content = {
    error: null
  };

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {?Array.<{}>}
   */
  getFulfilled() {
    return true;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    return this.content.error;
  }

  /**
   * Deletes Content & Content's ContentFiles from the server.
   *
   * This strategy may not work if the content files of the content have changed (especially if new ones have been
   * added) since the client has requested the content, since there is a foreign-key constraint on the Content's
   * ContentFiles.
   *
   * @param {{id: number, categoryId: number, contentFiles: Array.<{id: number}>}} content
   * @param {number} libraryType
   */
  @action deleteContent(content, libraryType) {
    this.state = STATE_PENDING;

    let deleteContentFilesPromises = [];
    if (content.contentFiles) {
      deleteContentFilesPromises = content.contentFiles.map( (contentFile) => {
        return serverApi.deleteContentFileById(contentFile.id);
      });
    }

    Promise.all(deleteContentFilesPromises).then(
      () => {
        return serverApi.deleteContentById(content.id);
      },
      action('deleteContentFilesError', (error) => {
        this.content.error = error;
        this.state = STATE_REJECTED;

        return {skip: true};
      })
    ).then(
      action('deleteContentSuccess', (deleteResponse) => {
        if (deleteResponse && deleteResponse.skip) {
          return;
        }

        this.content.error = null;

        apiContentsStore.expireCacheForCategory(content.categoryId);
        apiContentsStore.refreshCurrentContents({
          categoryId: content.categoryId,
          libraryId: content.libraryId,
        });

        apiContentSearchStore.expireCacheForLibrary(content.libraryId);

        const searchTerm = activeContentStore.getContentSearchFilter(libraryType);
        if (searchTerm) {
          apiContentSearchStore.refreshCurrentContents({
            libraryId: content.libraryId,
            searchTerm,
          });
        }

        this.state = STATE_FULFILLED;
      }),
      action('deleteContentError', (error) => {
        this.content.error = error;
        this.state = STATE_REJECTED;
      }),
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => {
      return this.getFulfilled();
    };
    const getRejected = () => {
      return this.getRejected();
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }
}

export default new DeleteContentStore();
