import PropTypes from 'prop-types';
import React from 'react';
import {observer} from 'mobx-react';

import MainNavBar from '../../common/mainNavBar/MainNavBar';

import './mainLayout.scss';

/**
 * The MainLayout component.
 */
export class MainLayout extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div id="main-layout">
        <MainNavBar
          location={this.props.location}
          match={this.props.match}
        />

        <div id="main-content">
          <main id="main-page" role="main">
            {this.props.page}
          </main>
        </div>
      </div>
    );
  }
}

MainLayout.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object,
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  page: PropTypes.element.isRequired,
};

export default observer(MainLayout);
