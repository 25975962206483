import {action, observable} from 'mobx';

import apiUpdateCategoryStore from '../api/update/apiUpdateCategoryStore';

/**
 * The category edit store.
 * This store helps with editing a category.
 */
class CategoryEditStore {
  /**
   * The category id that is being moved.
   *
   * @type {?{id: number, categoryName: string}}}
   */
  @observable moveCategory = null;

  /**
   * Sets the category that will be moved.
   *
   * @param {{id: number, categoryName: string}} moveCategory
   */
  @action setMoveCategory(moveCategory) {
    this.moveCategory = moveCategory;
  }

  /**
   * Triggered when a category is being moved.
   *
   * @param {{categoryId: number, libraryId: number, libraryType: string}} destination
   */
  @action onMoveCategory(destination) {
    const movingCategoryId = this.moveCategory.id;
    const fromParentCategoryId = this.moveCategory.parentCategoryId;

    this.moveCategory = null;

    if (!destination || !destination.libraryId) {
      return;
    } else if (!destination.categoryId && destination.categoryId !== false) {
      return;
    }

    const newCategoryId = destination.categoryId || null;
    if (fromParentCategoryId === newCategoryId) {
      return;
    } else if (String(movingCategoryId) === String(newCategoryId)) {
      // Don't let a category be moved into itself.
      return;
    }

    apiUpdateCategoryStore.updateCategory(movingCategoryId, {
      parentCategoryId: newCategoryId,
      libraryId: destination.libraryId,
    }, destination.libraryId);
  }
}

export default new CategoryEditStore();
