import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import lineInteractHOC from '../../hoc/lineInteractHoc';

/**
 * The DisplayLineHandle component.
 */
export class DisplayLineHandle extends React.Component {
  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {radius, x, y} = this.props;

    const style = {opacity: 0};
    if (this.props.show) {
      style.opacity = 0.3;
    }

    return (
      <circle
        className="display-line-handle"
        cx={x}
        cy={y}
        r={radius}
        {...style}
        pointerEvents="visible"
      />
    );
  }
}

DisplayLineHandle.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: PropTypes.object.isRequired,
  isDrag: PropTypes.bool.isRequired,
  isStart: PropTypes.bool.isRequired,
  radius: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

export default lineInteractHOC(
  observer(DisplayLineHandle)
);
