import {action, extendObservable, observable} from 'mobx';

import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN} from '../../constants/storeConstants';
import serverApi from '../../utils/serverApi';
import {apiStore, getCase} from '../../utils/apiStore';

/**
 * The project store.
 */
class ProjectStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * The project items by id.
   *
   * @type {ObservableMap<string, {expireTime: number, project: {}, error: ?Error}>}
   */
  @observable projectsById = observable.map();

  /**
   * Clears all the projects.
   */
  @action clearAll() {
    this.projectsById.clear();
    this.state = STATE_PRE;
  }

  /**
   * Gets an project by id.
   *
   * @param {number} rawProjectId
   * @returns {({id: number}|null)}
   */
  getProjectById(rawProjectId) {
    const projectId = String(rawProjectId);
    const foundProject = this.projectsById.get(projectId);
    if (!foundProject || !foundProject.project) {
      return null;
    }
    return foundProject.project;
  }

  /**
   * Gets the fulfilled value for the given projectId.
   * This is used in case().
   *
   * @param {number} projectId
   * @returns {({id: number}|null)}
   */
  getFulfilled(projectId) {
    return this.getProjectById(projectId);
  }

  /**
   * Gets the rejected value for the given projectId.
   * This is used in case().
   *
   * @param {number} rawProjectId
   * @returns {?Error}
   */
  getRejected(rawProjectId) {
    const projectId = String(rawProjectId);
    const foundProject = this.projectsById.get(projectId);
    if (!foundProject || !foundProject.error) {
      return null;
    }
    return foundProject.error;
  }

  /**
   * Fetches projects from the server.
   *
   * @param {number} rawProjectId
   */
  @action fetchProjectById(rawProjectId) {
    const projectId = String(rawProjectId);
    let currentProject = this.projectsById.get(projectId);
    if (currentProject && currentProject[EXPIRE_TIME] <= Date.now()) {
      this.projectsByPage.delete(projectId);
    } else if (currentProject) {
      this.state = STATE_FULFILLED;
      return;
    }

    this.state = STATE_PENDING;

    serverApi.projectGetById(projectId).then(
      action('fetchSuccess', (foundProject) => {
        this.projectRegistry.set(projectId, {
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          project: foundProject,
          error: null,
        });
        this.state = STATE_FULFILLED;
      }),
      action('fetchError', (error) => {
        this.projectRegistry.set(projectId, {
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          project: null,
          error,
        });
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {number} projectId
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(projectId, handlers) {
    const getFulfilled = () => {
      return this.getFulfilled(projectId);
    };
    const getRejected = () => {
      return this.getRejected(projectId);
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }
}

export default new ProjectStore();
