import {action} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {actionUpdateComponent} from '../../../../../display/components/action/actionUpdateComponent';
import {widgetComponent} from '../../../../../display/components/type/widgetComponent';

/**
 * The WidgetRefreshDuration component.
 *
 * @param {{}} params
 * @param {ObservableMap} params.entity
 * @param {GameStore} params.game
 * @returns {React.Component}
 */
export const WidgetRefreshDuration = ({entity, game}) => {
  /**
   * Updates the refresh duration in the widget component.
   *
   * @params {{}} changeEvent
   */
  const onChangeRefreshDuration = action((changeEvent) => {
    const actionParams = {
      entityId: entity.get('id'),
    };

    const newValue = changeEvent.target.value || 0;
    const newRefreshDuration = parseInt(newValue, 10) || 0;

    const currentViewPort = entity.get('widget');
    const newViewPort = widgetComponent({
      refreshDuration: newRefreshDuration,
    }, currentViewPort);

    game.addAction(actionParams, actionUpdateComponent(newViewPort));
  });

  const widget = entity.get('widget');

  return (
    <div className="widget-refresh-duration">
      <input
        type="number"
        className="form-control form-control-sm"
        value={widget.refreshDuration}
        min={0}
        onChange={onChangeRefreshDuration}
      />
    </div>
  );
};

WidgetRefreshDuration.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: PropTypes.object.isRequired,
};

export default observer(WidgetRefreshDuration);
