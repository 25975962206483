import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import ClientNavItem from './components/clientNavItem/ClientNavItem';
import EditorNavItem from './components/editorNavItem/EditorNavItem';
import FeedDropdown from './components/feedDropdown/FeedDropdown';
import ProductNavItem from './components/productNavItem/ProductNavItem';
import LoadingIcon from '../loadingIcon/LoadingIcon';
import Link from '../../common/link/Link';
import inject from '../../hoc/injectHoc';
import {editorRoute, logoutRoute, productSelectionRoute} from '../../routePaths';
import works24SymbolPNG from '../../../assets/images/works24-symbol.png';
import {routeMatches, routeHistory} from '../../../utils/history';

import './mainNavBar.scss';

/**
 * The MainNavBar component.
 */
export class MainNavBar extends React.Component {
  /**
   * When the home logo button is clicked
   */
  onHomeClick = () => {
    routeHistory.push(productSelectionRoute);
  };

  /**
   * Checks if the current route is the editor page route.
   *
   * @returns {boolean}
   */
  isEditorRoute = () => {
    return routeMatches(editorRoute);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiLoginStore} = this.props;

    return (
      <header id="main-navbar">
        {(this.isEditorRoute()) ? (
          <div className="navbar-left">
            <EditorNavItem />
          </div>
        ) : (
          <div className="navbar-left">
            <button
              className="navbar-logo w24-transparent-button"
              type="button"
              onClick={this.onHomeClick}
            >
              <img className="logo-img" src={works24SymbolPNG} alt="Works24 Logo" />
            </button>

            <ClientNavItem />

            <ProductNavItem />
          </div>
        )}

        <div className="navbar-center">
          {apiLoginStore.case({
            fulfilled: (user) => (
              <FeedDropdown user={user} />
            ),
          })}
        </div>

        <div className="navbar-right">
          {apiLoginStore.case({
            pending: () => (<LoadingIcon size="xs" />),
            rejected: (error) => (<div>{error.message}</div>),
            fulfilled: (user) => (
              <div className="user-controls">
                <button
                  type="button"
                  className="w24-navbar-control w24-transparent-button dropdown-toggle"
                  id="user-button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {user && user.userName}
                </button>
                <div className="dropdown-menu" aria-labelledby="user-button">
                  <Link
                    className="dropdown-item"
                    to={logoutRoute}
                  >Sign Out</Link>
                </div>
              </div>
            ),
          })}
        </div>
      </header>
    );
  }
}

MainNavBar.propTypes = {
  apiLoginStore: MobxPropTypes.observableObject,
  apiUserStore: MobxPropTypes.observableObject,
  displayEditorStore: MobxPropTypes.observableObject,
};

MainNavBar.wrappedComponent = {};
MainNavBar.wrappedComponent.propTypes = {
  apiLoginStore: MobxPropTypes.observableObject.isRequired,
  apiUserStore: MobxPropTypes.observableObject.isRequired,
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(MainNavBar)(
  observer(MainNavBar)
);
