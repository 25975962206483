import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import './verticalAlignButtons.scss';

import {ALIGN_TOP, ALIGN_MIDDLE, ALIGN_BOTTOM} from '../../constants/styleContants';
import {actionUpdateComponent} from '../../../../../display/components/action/actionUpdateComponent';
import {feedTextComponent} from '../../../../../display/components/type/feedTextComponent';
import {textComponent} from '../../../../../display/components/type/textComponent';

/**
 * The list of vertical alignment buttons.
 *
 * @type {Array.<{label: string, style: string}>}
 */
const VERTICAL_ALIGN_BUTTON_TYPES = [
  {label: 'Top', style: ALIGN_TOP, icon: 'fa-toggle-up'},
  {label: 'Middle', style: ALIGN_MIDDLE, icon: 'fa-toggle-left'},
  {label: 'Bottom', style: ALIGN_BOTTOM, icon: 'fa-toggle-down'},
];

/**
 * The VerticalAlignButtons component.
 *
 * @param {{}} params
 * @param {{}} params.entity
 * @param {{}} params.game
 * @returns {React.Component}
 */
export const VerticalAlignButtons = ({entity, game}) => {
  let component;
  if (entity.has('feedText')) {
    component = entity.get('feedText');
  } else {
    component = entity.get('text');
  }

  /**
   * Updates the vertical alignment in the entity.
   *
   * @param {string} clickedStyle
   * @returns {function()}
   */
  const onClick = (clickedStyle) => {
    return () => {
      const currentVerticalAlign = component.verticalAlign;
      if (String(clickedStyle) === String(currentVerticalAlign)) {
        return;
      }

      let componentUpdates;
      if (entity.has('feedText')) {
        componentUpdates = feedTextComponent({
          verticalAlign: clickedStyle,
        }, component);
      } else {
        componentUpdates = textComponent({
          verticalAlign: clickedStyle
        }, component);
      }

      game.addAction({entityId: entity.get('id')}, actionUpdateComponent(componentUpdates));
    };
  };

  const currentVerticalAlign = String(component.verticalAlign);

  return (
    <div className="vertical-align-buttons form-buttons">
      {VERTICAL_ALIGN_BUTTON_TYPES.map((alignButton) => {
        const classes = {
          'active': (currentVerticalAlign === String(alignButton.style))
        };

        return (
          <button
            className={classNames('btn btn-sm btn-dark form-button', classes)}
            type="button"
            key={alignButton.label}
            onClick={onClick(alignButton.style)}
          >
            <i className={`fa ${alignButton.icon}`} />
          </button>
        );
      })}
    </div>
  );
};

VerticalAlignButtons.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: PropTypes.shape({
    addAction: PropTypes.func.isRequired,
  }).isRequired,
};

export default observer(VerticalAlignButtons);
