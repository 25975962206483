import lodash from 'lodash';

import {loginRoute, productSelectionRoute, renderRoute} from '../components/routePaths';
import apiLoginStore from '../stores/api/apiLoginStore';
import sessionStore from '../stores/active/sessionStore';
import {routeHistory, getRedirectWithTo, currentPathHasTo} from '../utils/history';

/**
 * Checks to see if the user is logged in and redirects them if they aren't.
 */
export function checkStartState() {
  if (!routeHistory || !routeHistory.location) {
    throw new Error('CheckForLogin could not find routeHistory.');
  }

  const routeLocation = routeHistory.location.pathname;
  if (lodash.startsWith(routeLocation, loginRoute)) {
    return;
  } else if (lodash.startsWith(routeLocation, renderRoute)) {
    return;
  }

  if (!sessionStore.userId) {
    routeHistory.push(
      getRedirectWithTo(loginRoute, routeLocation)
    );
    return;
  }

  validateClient();

  const isOnProductSelectionPage = lodash.startsWith(routeLocation, productSelectionRoute);
  if (!sessionStore.productId && !isOnProductSelectionPage) {
    routeHistory.push(
      getRedirectWithTo(productSelectionRoute, routeLocation)
    );
  }
}

/**
 * Checks the state of the application after login is complete.
 *
 * @returns {boolean} True if the state is ok, false if the user has been redirected.
 */
export function checkPostLoginState() {
  if (!apiLoginStore.currentUser.user) {
    // We don't want this code to get them in an infinite loop so just bail out.
    // 401s from not being logged in should redirect them back to login.
    return true;
  }

  validateClient();

  const routeLocation = routeHistory.location.pathname;
  const isOnProductSelectionPage = lodash.startsWith(routeLocation, productSelectionRoute);
  if (!sessionStore.productId && !isOnProductSelectionPage) {
    const toProductSelectionPage = getRedirectWithTo(productSelectionRoute);
    if (currentPathHasTo()) {
      routeHistory.replace(toProductSelectionPage);
    } else {
      routeHistory.push(toProductSelectionPage);
    }
    return false;
  }

  return true;
}

/**
 * Sets the client if it is not already set.
 */
function validateClient() {
  if (sessionStore.clientId) {
    return;
  }

  // This should be set because we are logged in.
  const currentUser = apiLoginStore.getFulfilled();

  if (currentUser) {
    if (currentUser.clientId) {
      sessionStore.setClientId(currentUser.clientId);
    } else if (lodash.get(currentUser, 'assignedClients[0]')) {
      sessionStore.setClientId(currentUser.assignedClients[0]);
    }
  }
}
