/**
 * The default circle color.
 * @const {string}
 */
const DEFAULT_COLOR = '#000000';

/**
 * The default circle opacity.
 * @const {number}
 */
const DEFAULT_OPACITY = 1;

/**
 * The circle component.
 *
 * @param {{color: string, opacity: number}=} style
 * @param {{color: string, opacity: number, width: number}=} border
 * @returns {{circle: {}}}
 */
export function circleComponent(style, border) {
  return {
    circle: {
      style,
      border
    },
  };
}

/**
 * Gets the circle component from the source item.
 *
 * @param {{circle: {style: {}, border: {}}}} item
 * @returns {{circle: {style: {}, border: {}}}}
 */
export function getCircleFromSource(item) {
  if (!item.circle) {
    return {};
  }

  const circle = Object.assign({}, item.circle);
  if (!circle.style) {
    circle.style = {};
  }
  if (!circle.border) {
    circle.border = {};
  }

  const style = {
    color: circle.style.color || DEFAULT_COLOR,
    opacity: getOpacity(circle.style),
  };
  const border = {
    color: circle.border.color || DEFAULT_COLOR,
    opacity: getOpacity(circle.border),
    width: circle.border.width || 0,
  };

  return circleComponent(
    style,
    border,
  );
}

/**
 * Gets the opacity or the default.
 *
 * @param {{opacity: number}} item
 * @returns {number}
 */
function getOpacity(item) {
  if (!item || item.opacity === undefined) {
    return DEFAULT_OPACITY;
  }

  const safeOpacity = Number(item.opacity);
  if (!safeOpacity && safeOpacity !== 0) {
    return DEFAULT_OPACITY;
  }

  return safeOpacity;
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getCircleForSource(entity) {
  if (!entity.has('circle')) {
    return {};
  }

  const circle = entity.get('circle');
  return {
    circle: {
      style: circle.style,
      border: circle.border,
    },
  };
}
