import classNames from 'classnames';
import DOMPurify from 'dompurify';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {ALIGN_BOTTOM, ALIGN_MIDDLE} from '../../controls/editText/constants/styleContants';
import entityActivateHoc from '../../hoc/entityActivateHoc';
import replaceText from '../../../utils/textReplacer';

import './displayText.scss';

/**
 * The DisplayText component.
 */
export class DisplayText extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {style, entity, className} = this.props;

    const entityId = entity.get('id');
    const text = entity.get('text');

    const replacedValue = replaceText(text.value);
    const cleanedValue = DOMPurify.sanitize(replacedValue);

    // Use table and table-cell in order to do vertical alignment that is supported in IE.
    const tableStyle = {
      display: 'table',
      width: style.width,
      height: style.height,
    };

    const preStyle = {
      display: 'table-cell',
    };
    if (text.verticalAlign === ALIGN_MIDDLE) {
      preStyle.verticalAlign = 'middle';
    } else if (text.verticalAlign === ALIGN_BOTTOM) {
      preStyle.verticalAlign = 'bottom';
    } else {
      preStyle.verticalAlign = 'top';
    }

    return (
      <div id={entityId} className={classNames('display-text', className)} style={style}>
        <div style={tableStyle}>
          {(text.isHtml) ? (
            <div
              className="display-text-pre"
              style={preStyle}
              dangerouslySetInnerHTML={{__html: cleanedValue}} // eslint-disable-line react/no-danger
            />
          ) : (
            <div className="display-text-pre" style={preStyle}>{replacedValue}</div>
          )}
        </div>
      </div>
    );
  }
}

DisplayText.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  style: PropTypes.object.isRequired,

  className: PropTypes.string,
};

export default entityActivateHoc(
  observer(DisplayText)
);
