import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import PlayerControls from '../player/PlayerControls';
import TimeControl from '../time/TimeControl';
import {timeFormat} from '../../../utils/timeFormat';

import './playbackControls.scss';

/**
 * The PlaybackControls component.
 */
export class PlaybackControls extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {game, timer} = this.props;

    return (
      <div className="playback-controls">
        <div className="controls-wrapper player-controls-wrapper">
          <PlayerControls game={game} timer={timer} />
        </div>
        <div className="controls-wrapper time-control-wrapper">
          <TimeControl timer={timer} />
        </div>
        <div className="controls-wrapper time-tracker-wrapper">
          <div className="timer">
            {(timer) && (
              <div>{timeFormat(timer.time)}s</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PlaybackControls.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,
  timer: MobxPropTypes.observableObject,
};

export default observer(PlaybackControls);
