import {reaction} from 'mobx';

import apiUserStore from '../../stores/api/apiUserStore';
import sessionStore from '../../stores/active/sessionStore';

/**
 * The disposer function for the mobX reaction().
 *
 * @type {?function}
 */
let reactionDispose = null;

/**
 * Initializes reaction for login.
 */
export function init() {
  reactionDispose = reaction(
    () => sessionStore.userId,
    onUserChange
  );
}

/**
 * Handles the change in the session's userId.
 *
 * @param {?number} userId
 */
function onUserChange(userId) {
  if (userId) {
    apiUserStore.fetchUserById(userId);
  }
}

/**
 * Disposes of the thunk.
 * This is useful for hot-reloading purposes.
 */
export function dispose() {
  if (reactionDispose) {
    reactionDispose();
  }
}

export default init;
