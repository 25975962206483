import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import SelectContentModal from '../../../../modals/selectContent/SelectContentModal';
import {actionUpdateComponent} from '../../../../../display/components/action/actionUpdateComponent';
import {
  VIEWPORT_TYPE_IMAGE,
  VIEWPORT_TYPE_VIDEO,
  viewportComponent
} from '../../../../../display/components/type/viewportComponent';
import {getThumbnailUrlByFileId} from '../../../../../utils/contentsHelper';
import {decimalToFraction} from '../../../../../utils/mathHelper';

import './viewportChangeSource.scss';

/**
 * The ViewportChangeSource component.
 *
 * @param {{}} params
 * @param {ObservableMap} params.entity
 * @param {GameStore} params.game
 * @returns {React.Component}
 */
export class ViewportChangeSource extends React.Component {
  /**
   * Whether or not the source choose/upload modal is open.
   *
   * @type {boolean}
   */
  @observable isModalOpen = false;

  /**
   * Triggered when the source selector modal should open.
   */
  @action onOpenSelector = () => {
    this.isModalOpen = true;
  };

  /**
   * Triggered when the new source is selected by the modal.
   *
   * @param {{contentFiles: Array.<{id: number}>}} newContent
   */
  @action onSourceSelected = (newContent) => {
    this.isModalOpen = false;

    const contentFile = lodash.get(newContent, 'contentFiles[0]');
    if (!contentFile || !contentFile.id) {
      return;
    }

    const {entity, game} = this.props;

    const isImage = ((contentFile.durationMs || 0) <= 0);
    const fileType = String(contentFile.masterFileName).split('.')[1];

    let aspectRatio;
    try {
      const source = JSON.parse(contentFile.create24ProjectJson);
      const height = lodash.get(source, 'resolution.height');
      const width = lodash.get(source, 'resolution.width');

      if (height && width) {
        aspectRatio = decimalToFraction(width / height);
      }
    } catch (parseError) {
      // Do nothing and just treat the aspect ratio as undefined.
    }

    const currentViewPort = entity.get('viewport');
    const newViewPort = viewportComponent({
      contentId: newContent.id,
      type: (isImage) ? VIEWPORT_TYPE_IMAGE : VIEWPORT_TYPE_VIDEO,
      fileId: contentFile.id,
      fileType: `video/${fileType}`,
      fileAspectRatio: aspectRatio,
    }, currentViewPort);

    const actionParams = {
      entityId: entity.get('id'),
    };

    game.addAction(actionParams, actionUpdateComponent(newViewPort));
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity} = this.props;
    const viewport = entity.get('viewport');

    const sourceUrl = getThumbnailUrlByFileId(viewport.file.id);

    return (
      <div className="viewport-change-source">
        <div className="source-selector">
          <div className="source-wrapper">
            <button className="btn btn-link source-change-button" type="button" onClick={this.onOpenSelector}>
              <img alt="Source Thumbnail" className="source-thumbnail" src={sourceUrl} />

              <span className="source-edit-icon">
                <i className="fa fa-pencil" />
              </span>
            </button>

            <button
              type="button"
              className="btn btn-sm btn-dark direct-change-button"
              onClick={this.onOpenSelector}
            >
              Change Source
            </button>
          </div>
        </div>

        <SelectContentModal
          isOpen={this.isModalOpen}
          onComplete={this.onSourceSelected}
        />
      </div>
    );
  }
}

ViewportChangeSource.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: PropTypes.object.isRequired,
};

export default observer(ViewportChangeSource);
