import {action, extendObservable, observable} from 'mobx';

import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import serverApi from '../../../utils/serverApi';
import {apiStore, getCase} from '../../../utils/apiStore';
import apiCategoriesStore from '../apiCategoriesStore';

/**
 * The UpdateCategory store.
 */
class UpdateCategoryStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * Stores the category state
   *
   * @type {Observable<{error: ?Observable}>}
   */
  @observable category = {
    error: null,
  };

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {boolean}
   */
  getFulfilled() {
    return true;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    return this.category.error;
  }

  /**
   * Clears all the current info and states
   */
  @action clearAll() {
    this.category.error = null;
    this.state = STATE_PRE;
  }

  /**
   * Updates a category from the server.
   *
   * @param {number} categoryId
   * @param {{}} categoryData
   * @param {number=} libraryId
   */
  @action updateCategory(categoryId, categoryData, libraryId) {
    this.state = STATE_PENDING;

    serverApi.categoryPatchById(categoryId, categoryData).then(
      action('patchCategorySuccess', () => {
        if (libraryId) {
          apiCategoriesStore.expireCacheForLibrary(libraryId);
          apiCategoriesStore.fetchCategories({contentLibraryId: libraryId});
        }

        this.category.error = null;
        this.state = STATE_FULFILLED;
      }),
      action('patchCategoryError', (error) => {
        this.category.error = error;
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => {
      return this.getFulfilled();
    };
    const getRejected = () => {
      return this.getRejected();
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }
}

export default new UpdateCategoryStore();
