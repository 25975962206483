import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

import {editorRoute} from '../../routePaths';
import GameCheckpointStore from '../../../stores/game/gameCheckpointStore';
import {routeHistory} from '../../../utils/history';
import {replaceRouteParams} from '../../../utils/routeHelper';

import './recoverContentModal.scss';

/**
 * The RecoverContentModal component.
 */
class RecoverContentModal extends React.Component {
  /**
   * Whether or not this modal is open.
   *
   * @type {boolean}
   */
  @observable isOpen = false;

  /**
   * Triggered when the component mounts to the page.
   */
  componentDidMount() {
    GameCheckpointStore.sync();
  }

  /**
   * Triggered when the modal is cancelled.
   */
  @action onCancelModal = () => {
    const {onComplete} = this.props;

    GameCheckpointStore.clearCheckpoint();

    if (onComplete) {
      onComplete(false);
    }
  };

  /**
   * Triggered when the modal is completed.
   */
  onCompleteModal = () => {
    const {onComplete} = this.props;

    const checkpoint = GameCheckpointStore.getCheckpoint();

    const toRoute = replaceRouteParams(editorRoute, {
      contentId: checkpoint.contentId,
      fromContentId: '',
      isLayout: '',
    });

    routeHistory.push(toRoute);

    if (onComplete) {
      onComplete(true);
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    if (!GameCheckpointStore.checkpointExists) {
      return null;
    }

    const checkpoint = GameCheckpointStore.getCheckpoint();
    if (!checkpoint) {
      return null;
    }

    let title = 'You have work in progress';
    if (checkpoint.contentTitle) {
      title += ` for '${checkpoint.contentTitle}'`;
    }
    title += '. Would you like to recover it?';

    return (
      <Modal className="recover-content-modal" isOpen={true} backdrop="static" toggle={this.onCancelModal}>
        <ModalBody>
          <p className="w24-label">
            {title}
          </p>

          <small className="text-muted">
            If you do not recover it, all progress will be deleted.
          </small>
        </ModalBody>

        <ModalFooter>
          <button type="button" className="btn btn-default" onClick={this.onCancelModal}>
            No
          </button>

          <button type="button" className="btn btn-primary" onClick={this.onCompleteModal}>
            Yes
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

RecoverContentModal.propTypes = {
  onComplete: PropTypes.func,
};

export default observer(RecoverContentModal);
