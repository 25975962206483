import {when} from 'mobx';
import moment from 'moment';
import lodash from 'lodash';

import config from '../config/main';
import {STATE_FULFILLED, STATE_REJECTED} from '../constants/asyncConstants';
import {
  BACKGROUND_STILL,
  BACKGROUND_VIDEO,
  CONTENT_TYPES,
  CREATED_CONTENT,
  LAYOUT_CONTENT_TYPES
} from '../constants/libraryTypeConstants';
import {ERROR, IN_PROCESS, NEW, QUEUED} from '../constants/contentConstants';
import {parseSourceForNewGame} from '../display/ecs/sourceHelper';
import apiContentMoverStore from '../stores/api/move/apiContentMoverStore';
import apiContentCopyStore from '../stores/api/create/apiContentCopyStore';
import apiCreateContentStore from '../stores/api/create/apiCreateContentStore';
import apiUpdateContentStateStore from '../stores/api/update/apiUpdateContentStateStore';
import apiContentsStore from '../stores/api/apiContentsStore';
import apiContentSearchStore from '../stores/api/apiContentSearchStore';
import apiProductsStore from '../stores/api/apiProductsStore';
import activeContentStore from '../stores/active/activeContentStore';

/**
 * The max height allowed for the image thumbnail.
 * @const {number}
 */
export const THUMBNAIL_MAX_HEIGHT = 159;

/**
 * The max width allowed for the image thumbnail.
 * @const {number}
 */
export const THUMBNAIL_MAX_WIDTH = 220;

/**
 * Gets the url to the content's main image/video using the content file id.
 *
 * @param {number} contentFileId
 * @returns {string}
 */
export function getMainUrlByFileId(contentFileId) {
  return `${config.api.content}/File/Video/${contentFileId}/Master`;
}

/**
 * Gets the url to the content's preview image/video using the content file id.
 *
 * @param {number} contentFileId
 * @returns {string}
 */
export function getPreviewUrlByFileId(contentFileId) {
  return `${config.api.content}/File/Video/${contentFileId}/Preview`;
}

/**
 * Gets the url to the content's preview image/video using the content file id.
 *
 * @param {number} contentFileId
 * @returns {string}
 */
export function getThumbnailUrlByFileId(contentFileId) {
  return `${config.api.content}/File/Video/${contentFileId}/Thumbnail`;
}

/**
 * Gets the url to a static file.
 *
 * @param {string|number} path
 * @param {boolean=} isFull
 * @returns {string}
 */
export function getStaticFileUrl(path, isFull) {
  const safePath = lodash.trim(path, '/ ');

  if (isFull) {
    return `${config.api.content}/${safePath}`;
  }

  if (String(parseInt(safePath, 10)) === String(safePath)) {
    // Path is an integer.
    return `${config.api.content}/${safePath}`;
  }

  return `${config.api.content}/StaticFile/Named/${safePath}`;
}

/**
 * Gets whether or not the given content file record has the file key.
 *
 * @param {{}} contentFile
 * @param {string} fileKey
 * @returns {boolean}
 */
export function getContentFileHasFile(contentFile, fileKey) {
  if (!contentFile || !contentFile[fileKey]) {
    return false;
  }
  return (String(contentFile[fileKey]).toLowerCase() !== 'unknown');
}

/**
 * Gets the url to the content's preview image/video.
 *
 * @param {{contentFiles: Array.<{id: number}>}} content
 * @param {boolean} isVideo
 * @returns {{isImage: boolean, url: ?string}}
 */
export function getPreviewUrlForContent(content, isVideo) {
  if (!content.contentFiles || !content.contentFiles.length || !content.contentFiles[0]) {
    return {
      isImage: true,
      url: null,
    };
  }

  const contentFile = content.contentFiles[0];

  const hasThumbnail = getContentFileHasFile(contentFile, 'thumbnailFileName');
  const hasBgContent = Boolean(contentFile.create24BgContentId);

  // Processing videos must show an image (either their thumbnail or the thumbnail of their bg content).
  if (isVideo && (isProcessing(content) || hasProcessingError(content))) {
    if (hasThumbnail) {
      return {
        isImage: true,
        url: getThumbnailUrlByFileId(contentFile.id),
      };
    }

    return {
      isImage: true,
      url: (hasBgContent) ? getThumbnailUrlByFileId(contentFile.create24BgContentId) : null,
    };
  }

  const hasPreviewFile = getContentFileHasFile(contentFile, 'previewFileName');
  const hasImageMasterFile = getContentFileHasFile(contentFile, 'masterFileName');

  // Order of display masterFile/previewFile > thumbnail > bgContentFile

  if (!isVideo && hasImageMasterFile) {
    return {
      isImage: true,
      url: getMainUrlByFileId(contentFile.id),
    };
  } else if (isVideo && hasPreviewFile) {
    return {
      isImage: false,
      url: getPreviewUrlByFileId(contentFile.id),
    };
  } else if (hasThumbnail) {
    return {
      isImage: true,
      url: getThumbnailUrlByFileId(contentFile.id),
    };
  } else if (!hasBgContent) {
    return {
      isImage: true,
      url: null,
    };
  }

  const bgContentFileId = contentFile.create24BgContentId;
  return {
    isImage: !isVideo,
    url: getMainUrlByFileId(bgContentFileId),
  };
}

/**
 * Gets the url to the content's thumbnail.
 *
 * @param {{contentFiles: Array.<{id: number}>}} content
 * @returns {?string}
 */
export function getThumbnailUrlForContent(content) {
  if (!content.contentFiles || !content.contentFiles.length) {
    return null;
  }

  const firstContentFile = content.contentFiles[0];

  const hasThumbnail = getContentFileHasFile(firstContentFile, 'thumbnailFileName');
  const hasBgContent = Boolean(firstContentFile.create24BgContentId);

  if (hasThumbnail) {
    return getThumbnailUrlByFileId(firstContentFile.id);
  } else if (!hasBgContent) {
    return null;
  }

  return getThumbnailUrlByFileId(firstContentFile.create24BgContentId);
}

/**
 * Watches for when a content is moved or copied.
 *
 * @param {{state: Symbol}} store
 * @param {number} libraryType
 * @param {number} libraryId
 * @param {number} categoryId
 * @param {number=} oldCategoryId
 * @param {number=} oldLibraryId
 */
function onContentAdjusted(store, libraryType, libraryId, categoryId, oldCategoryId, oldLibraryId) {
  when(
    () => store.state === STATE_FULFILLED || store.state === STATE_REJECTED,
    () => {
      if (store.state === STATE_REJECTED) {
        return;
      }

      activeContentStore.setLibraryId(libraryType, libraryId);

      // Need a delay after setting the active library id before we set the
      // active category id - running into race conditions with the thunks
      setTimeout(() => {
        apiContentsStore.expireCacheForCategory(categoryId);
        apiContentSearchStore.expireCacheForLibrary(libraryId);

        if (oldCategoryId) {
          apiContentsStore.expireCacheForCategory(oldCategoryId);
        }
        if (oldLibraryId) {
          apiContentSearchStore.expireCacheForLibrary(oldLibraryId);
        }

        activeContentStore.setCategoryId(libraryType, categoryId);
      });
    },
    {name: 'contentAdjusted'}
  );
}

/**
 * Moves the content to a new category.
 *
 * @param {{id: number}} content
 * @param {{categoryId: number, library: {}, libraryId: number, libraryType: number}} newLocationData
 */
export function moveContentLocation(content, newLocationData) {
  const {categoryId, library, libraryId, libraryType} = newLocationData;
  const oldCategoryId = content.categoryId;
  const oldLibraryId = content.libraryId;

  const contentFile = lodash.get(content, 'contentFiles[0]', null);

  let contentFileId = null;
  if (contentFile && contentFile.directory !== library.defaultDirectory) {
    // Only send the content file id if we need to update the directory.
    contentFileId = contentFile.id;
  }

  apiContentMoverStore.move(content.id, categoryId, contentFileId, library);
  onContentAdjusted(apiContentMoverStore, libraryType, libraryId, categoryId, oldCategoryId, oldLibraryId);
}

/**
 * Clones the content to a new category.
 *
 * @param {{id: number}} content
 * @param {{
 *   categoryId: number,
 *   library: {},
 *   libraryId: number,
 *   libraryType: number,
 *   contentName: string,
 *   isGlobal: boolean
 * }} newLocationData
 * @returns {Promise.<number>}
 */
export function cloneContent(content, newLocationData) {
  const {categoryId, contentName, library, libraryId, libraryType, isGlobal} = newLocationData;
  const oldCategoryId = content.categoryId;
  const oldLibraryId = content.libraryId;
  const libraryDirectory = library.defaultDirectory;

  let newContentFile = null;

  const firstContentFile = lodash.get(content, 'contentFiles[0]', {});
  if (firstContentFile.create24ProjectJson) {
    newContentFile = buildEditableContentFile(
      firstContentFile.create24BgContentId,
      firstContentFile.create24ProjectJson,
      libraryDirectory,
      firstContentFile.durationMs
    );
  } else {
    // Note: I don't this flow will come up in the current flows.
    let activeProduct = apiProductsStore.getActiveProduct();
    if (!activeProduct) {
      throw new Error('Could not clone content as no active product was found.');
    }

    const safeCreateData = {
      categoryId,
      libraryId,
      libraryType,
      contentName: contentName || content.title,
      isImage: !(Number(firstContentFile.durationMs) > 0),
      directory: libraryDirectory,
    };

    const newContentRecord = getEditableContentRecord(safeCreateData, activeProduct, content);

    newContentFile = newContentRecord.contentFiles[0];
  }

  const copyContent = {...content, title: contentName || content.title};
  copyContent.contentFiles = [newContentFile];

  const destination = {
    categoryId,
    libraryId,
  };
  return apiContentCopyStore.copy(copyContent, destination, libraryType, isGlobal).then(() => {
    onContentAdjusted(apiContentCopyStore, libraryType, libraryId, categoryId, oldCategoryId, oldLibraryId);

    return apiContentCopyStore.contentId;
  });
}

/**
 * Creates a new piece of content.
 *
 * @param {{
 *   categoryId: number,
 *   contentTypeId: number,
 *   contentFiles: Array.<{}>,
 *   libraryId: number,
 *   title: string
 * }} content
 * @param {boolean=} isGlobal
 * @returns {Promise.<number>}
 */
export function createNewContent(content, isGlobal) {
  const {categoryId, libraryId} = content;

  const destination = {
    categoryId,
    libraryId,
  };

  return apiContentCopyStore.copy(
    content,
    destination,
    CREATED_CONTENT,
    Boolean(isGlobal)
  ).then(() => {
    onContentAdjusted(apiContentCopyStore, CREATED_CONTENT, libraryId, categoryId);

    return apiContentCopyStore.contentId;
  });
}

/**
 * Forces the content into a new state.
 *
 * @param {{id: number}} content
 * @param {number} newState
 *
 * @returns {Promise}
 */
export function forceContentState(content, newState) {
  if (!content || !content.id) {
    return Promise.resolve(false);
  }

  const safeState = newState || QUEUED;

  apiUpdateContentStateStore.updateContentState(content, safeState);
  return apiUpdateContentStateStore.getPromise(content.id);
}

/**
 * Uploads and creates Content/ContentFile pairs from the specified files.
 * Automatically finds library/category for the Content if possible, otherwise prompts user to choose.
 *
 * @param {Array.<{}>} files
 * @param {number} libraryType
 * @param {?number} productId
 * @returns {Promise}
 */
export function uploadContent(files, libraryType, productId) {
  const activeContentLibrary = activeContentStore.libraries[libraryType];

  const libraryId = activeContentLibrary.id;
  const categoryId = activeContentLibrary.category.id;

  if (!libraryId) {
    throw new Error('Could not upload content as no active library has been set.');
  } else if (!categoryId) {
    throw new Error('Could not upload content as no active category has been set.');
  }

  apiCreateContentStore.createContent(files, libraryType, libraryId, productId, categoryId);
  return apiCreateContentStore.getUploadPromise();
}

/**
 * Determines if a content is currently processing.
 *
 * @param {{}} content
 * @param {{allowNew: boolean}=} options
 * @returns {boolean}
 */
export function isProcessing(content, options) {
  if (!content.contentFiles || !content.contentFiles.length) {
    return false;
  }

  const stateId = Number(content.contentFiles[0].contentFileStateId);

  const safeOptions = options || {};
  if (safeOptions.allowNew && stateId === NEW) {
    return false;
  }

  return lodash.includes(IN_PROCESS, stateId);
}

/**
 * Determines if a content threw an error during processing.
 *
 * @param {{}} content
 * @returns {boolean}
 */
export function hasProcessingError(content) {
  if (!content.contentFiles || !content.contentFiles.length) {
    return false;
  }
  return (ERROR === content.contentFiles[0].contentFileStateId);
}

/**
 * Determines whether or not the processing for content has expired, meaning that it is unlikely to finish.
 *
 * @param {{}} content
 * @returns {boolean}
 */
export function hasProcessingExpired(content) {
  if (!content.contentFiles || !content.contentFiles.length) {
    return false;
  } else if (!isProcessing(content, {allowNew: true})) {
    return false;
  }

  const firstContentFile = content.contentFiles[0];
  const lastUpdated = moment(firstContentFile.updateDate);
  const oneDayAgo = moment().subtract(1, 'day');

  return (lastUpdated.isBefore(oneDayAgo));
}

/**
 * Creates new editable content either from background content or a blank canvas.
 *
 * @param {{
 *   categoryId: ?number,
 *   directory: ?string,
 *   isImage: boolean,
 *   isLayout: boolean,
 *   libraryId: ?number,
 *   libraryType: number,
 *   contentName: ?string,
 *   source: ?{}
 * }} createData
 * @param {{}} product
 * @param {{}=} fromContent If no content is provided, then a blank canvas record will be returned.
 * @returns {{}} The new content record.
 * @throws {Error} If bad createData is given.
 */
export function getEditableContentRecord(createData, product, fromContent) {
  if (!createData || createData.libraryId === undefined || createData.categoryId === undefined) {
    throw new Error('ContentsHelper: Can not get editable content record because invalid createData was given.');
  } else if (!product || !product.widthPx || !product.heightPx) {
    throw new Error('ContentsHelper: Can not get editable content record because invalid product was given.');
  }

  const {isImage, isLayout} = createData;

  const contentFile = lodash.get(fromContent, 'contentFiles[0]', {});
  const clipLength = (isImage) ? 0 : lodash.get(contentFile, 'durationMs', 0);

  let backgroundType = (isImage) ? 'image' : 'video';
  if (!fromContent) {
    backgroundType = 'rectangle';
  }

  let contentFileId = (fromContent) ? lodash.get(contentFile, 'id', null) : null;
  if (contentFile.create24BgContentId) {
    // The content is itself a project with a source, so use its background as the content file id.
    contentFileId = contentFile.create24BgContentId;
  }

  let source;
  if (createData.source) {
    source = JSON.stringify(createData.source);
  } else {
    const background = {
      type: backgroundType,
      fileId: contentFileId,
    };

    source = parseSourceForNewGame(product.widthPx, product.heightPx, background, clipLength, isLayout);
  }

  const newContentFile = buildEditableContentFile(contentFileId, source, createData.directory, clipLength);

  let contentTypeId;
  if (isLayout) {
    contentTypeId = (isImage) ? LAYOUT_CONTENT_TYPES[BACKGROUND_STILL] : LAYOUT_CONTENT_TYPES[BACKGROUND_VIDEO];
  } else {
    contentTypeId = (isImage) ? CONTENT_TYPES[BACKGROUND_STILL] : CONTENT_TYPES[BACKGROUND_VIDEO];
  }

  return {
    title: createData.contentName || 'New Project',
    text: 'unknown',
    isEditable: true,
    isLayout: Boolean(isLayout),
    libraryId: createData.libraryId,
    categoryId: createData.categoryId,
    contentTypeId,
    contentFiles: [newContentFile],
  };
}

/**
 * Builds a new content file record for a new editable content file.
 *
 * @param {?number} bgContentFileId The content file id for the background content.
 * @param {string} source The stringified source JSON.
 * @param {string} libraryDirectory The library's directory.
 * @param {number} clipLength The length of the video clip or zero for images.
 * @returns {{}}
 */
export function buildEditableContentFile(bgContentFileId, source, libraryDirectory, clipLength) {
  return {
    contentId: null, // This will be filled in after the content is created.
    create24BgContentId: bgContentFileId, // Setting this to the content FILE id in order to make previewing easier.
    create24ProjectJson: source,
    directory: libraryDirectory,
    durationMs: clipLength,
    masterFileName: 'unknown',
    previewFileName: 'unknown',
    thumbnailFileName: 'unknown',
    isDeleted: false,
    contentFileStateId: NEW,
    lastAccessDate: new Date()
  };
}

/**
 * Gets a width and height for the image that maintains the aspect ratio but stays within the max width and height.
 *
 * @param {number} productWidth
 * @param {number} productHeight
 * @param {number} maxWidth
 * @param {number} maxHeight
 * @returns {{height: number, width: number}}
 */
export function getAspectRatioFit(productWidth, productHeight, maxWidth, maxHeight) {
  const ratio = Math.min(maxWidth / productWidth, maxHeight / productHeight);

  const MAX_DECIMALS = 4;
  return {
    height: Number.parseFloat(productHeight * ratio).toFixed(MAX_DECIMALS),
    width: Number.parseFloat(productWidth * ratio).toFixed(MAX_DECIMALS),
  };
}
