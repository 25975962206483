import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityActivateHoc from '../../hoc/entityActivateHoc';

import './displayRectangle.scss';

/**
 * The DisplayRectangle component.
 */
export class DisplayRectangle extends React.Component {
  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, style, className} = this.props;

    const entityId = entity.get('id');

    const rectangle = entity.get('rectangle');
    const rectangleStyle = rectangle.style;
    const rectangleBorder = rectangle.border;

    const width = parseInt(style.width, 10);
    const height = parseInt(style.height, 10);

    const rounded = rectangleStyle.rounded || 0;
    const borderSize = parseInt(rectangleBorder.width, 10) || 0;
    const halfBorderSize = borderSize / 2;

    return (
      <div id={entityId} className={classNames('display-rectangle', className)} style={style}>
        <svg viewBox={`0 0 ${width} ${height}`}>
          <rect
            x={halfBorderSize}
            y={halfBorderSize}
            rx={rounded}
            ry={rounded}
            width={width - borderSize}
            height={height - borderSize}
            fill={rectangleStyle.color}
            fillOpacity={rectangleStyle.opacity}
            stroke={rectangleBorder.color}
            strokeOpacity={rectangleBorder.opacity}
            strokeWidth={borderSize}
          />
        </svg>
      </div>
    );
  }
}

DisplayRectangle.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  style: PropTypes.object.isRequired,

  className: PropTypes.string,
};

export default entityActivateHoc(
  observer(DisplayRectangle)
);
