import { action, computed, observable } from 'mobx';
import { Link } from 'react-router-dom';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import React from 'react';
import { Alert } from 'reactstrap';
import Toggle from 'react-toggle';

import TransitionWrapper from '../../../../layouts/transitionWrapper/TransitionWrapper';
import inject from '../../../../hoc/injectHoc';
import * as routePaths from '../../../../routePaths';
import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';
import {checkPostLoginState} from '../../../../../preloads/checkState';
import {redirect} from '../../../../../utils/history';

import './loginForm.scss';

/**
 * The LoginForm component.
 */
export class LoginForm extends React.Component {
  /**
   * The flag for if the user has "Remember Me" checked.
   *
   * @type {boolean}
   */
  @observable rememberMe = false;

  /**
   * Username form value
   *
   * @type {string}
   */
  @observable username = '';

  /**
   * Password form value
   *
   * @type {string}
   */
  @observable password = '';

  /**
   * Invoked when the component mounts.
   */
  @action componentDidMount() {
    const username = localStorage.getItem('rememberMe');
    if (!username) {
      return;
    }

    this.username = username;
    this.rememberMe = true;
  }

  /**
   * Tracks if the form is disabled.
   *
   * @returns {boolean}
   */
  @computed get isSubmitDisabled() {
    return Boolean(!this.username || !this.password);
  }

  /**
   * Triggered when the login error alert is dismissed.
   */
  onDismiss = () => {
    this.props.apiLoginStore.clearAll();
  };

  /**
   * Login click handler
   *
   * @param {{}} clickEvent
   */
  loginClick = (clickEvent) => {
    clickEvent.preventDefault();

    const {apiLoginStore} = this.props;

    const loginBody = {
      username: this.username,
      password: this.password,
    };

    apiLoginStore.postLogin(loginBody);

    apiLoginStore.getPromise().then(() => {
      this.loginSuccess();
    });
  };

  /**
   * Update property [key] to be [value]
   * @param {string} key
   * @param {*} value
   */
  @action updateProperty = (key, value) => {
    this[key] = (typeof value === 'string') ? value.trim() : value;
  };

  /**
   * Login input onChange handler
   *
   * @param {{}} changeEvent
   */
  onChange = (changeEvent) => {
    const target = changeEvent.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.updateProperty(target.name, value);
  };

  /**
   * Callback after a successful login
   */
  loginSuccess = () => {
    if (this.rememberMe) {
      localStorage.setItem('rememberMe', this.username);
    } else {
      localStorage.removeItem('rememberMe');
    }

    if (!checkPostLoginState()) {
      return;
    }

    redirect(routePaths.productSelectionRoute, true);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const apiLoginStore = this.props.apiLoginStore;

    return (
      <form id="login-form" onSubmit={this.loginClick}>
        <TransitionWrapper>
          <div>
            <div className="form-header">
              <h2 className="main-header">Create24</h2>
              <h3 className="sub-header">Put Your Message In Motion</h3>
            </div>

            <div className="form-group">
              <label className="sr-only" htmlFor="username">Username</label>
              <input
                type="text"
                value={this.username}
                name="username"
                onChange={this.onChange}
                className="form-control"
                id="username"
                placeholder="Username"
              />
            </div>
            <div className="form-group">
              <label className="sr-only" htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                value={this.password}
                onChange={this.onChange}
                className="form-control"
                id="password"
                placeholder="Password"
              />
            </div>
            <input
              type="submit"
              disabled={this.isSubmitDisabled}
              className="btn btn-submit btn-primary btn-block"
              value="Sign In"
            />
            {apiLoginStore.case({
              pending: () => (<LoadingIcon size="sm" />),
              rejected: (loginError) => (
                <Alert color="danger" isOpen={true} toggle={this.onDismiss}>
                  {loginError.message}
                </Alert>
              ),
            })}
            <footer className="login-form-footer">
              <div className="remember-me-wrapper">
                <Toggle
                  id="remember-me"
                  name="rememberMe"
                  checked={this.rememberMe}
                  icons={false}
                  onChange={this.onChange}
                />
                <label className="remember-me-label" htmlFor="remember-me">Remember Me</label>
              </div>
              <Link to={routePaths.passwordResetRoute}>Forgot password?</Link>
            </footer>
          </div>
        </TransitionWrapper>
      </form>
    );
  }
}

LoginForm.propTypes = {
  apiLoginStore: MobxPropTypes.observableObject,
};

LoginForm.wrappedComponent = {
  propTypes: {
    apiLoginStore: MobxPropTypes.observableObject.isRequired,
  }
};

export default inject(LoginForm)(
  observer(LoginForm)
);
