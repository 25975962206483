import {runInAction} from 'mobx';

import {updateEntity} from '../ecs/entityHelper';
import {STATE_PAUSED, STATE_PLAYING, STATE_STOPPED} from '../../constants/displayItemConstants';

/**
 * The name of the system.
 * @const {string}
 */
export const TRACKING_SYSTEM = 'trackingSystem';

/**
 * Gets a new instance of the tracking system.
 *
 * @returns {{name: string, update: systemUpdate}}
 */
export function trackingSystem() {
  /**
   * Called when the game loop updates.
   *
   * @param {Array.<{}>} entities
   * @param {number} time
   * @param {{timer: {}}} extras
   * @throws {Error} If no timer is provided.
   */
  function systemUpdate(entities, time, extras) {
    const timer = extras.timer;
    if (!timer) {
      throw new Error('No timer provided to trackingSystem.update().');
    }

    entities.forEach((entity) => {
      // First check for required components.
      if (!entity.has('video') && !entity.has('audio') && !entity.has('timer') && !entity.get('viewport')) {
        return;
      }

      let state = STATE_STOPPED;
      if (timer.isPaused) {
        state = STATE_PAUSED;
      } else if (timer.isStarted) {
        state = STATE_PLAYING;
      }

      // Now update the entity.
      runInAction('trackingSystemUpdateEntity', () => {
        let diffTime = time;
        if (entity.has('time')) {
          // We want the time on the entity to be the difference between when the entity first
          // appears and the current time. If we just sent time, it would always be the video time.
          const entityTime = entity.get('time');
          diffTime = time - entityTime.start;
        }

        if (entity.has('video')) {
          const currentVideo = entity.get('video');
          if (currentVideo.state !== state || currentVideo.time !== diffTime) {
            updateEntity(entity, 'video', {state, time: diffTime});
          }
        }

        if (entity.has('audio')) {
          const currentAudio = entity.get('audio');
          if (currentAudio.state !== state || currentAudio.time !== diffTime) {
            updateEntity(entity, 'audio', {state, time: diffTime});
          }
        }

        if (entity.has('timer')) {
          const currentTimer = entity.get('timer');
          if (currentTimer.state !== state || currentTimer.time !== diffTime) {
            updateEntity(entity, 'timer', {state, time: diffTime});
          }
        }

        if (entity.has('viewport')) {
          const currentViewport = entity.get('viewport');
          const playback = currentViewport.playback;
          if (playback && (playback.state !== state || playback.time !== diffTime)) {
            updateEntity(entity, 'viewport', {playback: {state, time: diffTime}});
          }
        }
      });
    });
  }

  return {
    name: TRACKING_SYSTEM,
    update: systemUpdate
  };
}
