import {action, extendObservable, observable} from 'mobx';

import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN} from '../../constants/storeConstants';
import serverApi from '../../utils/serverApi';
import {apiStore, getCase} from '../../utils/apiStore';

/**
 * The clients by search store.
 */
class ClientsBySearchStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * The map of each search's list of client records
   *
   * @type {ObservableMap<string, {clients: Array<{id: string, name: string}>, error: ?Error}>}
   */
  @observable clientsBySearch = observable.map();

  /**
   * Gets a safe search term.
   *
   * @param {string} searchTerm
   * @returns {string}
   */
  getSafeSearch(searchTerm) {
    return String(searchTerm);
  }

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @param {string} search
   * @returns {Array.<{}>}
   */
  getFulfilled(search) {
    const safeSearch = this.getSafeSearch(search);

    if (!this.clientsBySearch.has(safeSearch)) {
      return [];
    }
    return this.clientsBySearch.get(safeSearch).clients;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @param {string} search
   * @returns {?Error}
   */
  getRejected(search) {
    const safeSearch = this.getSafeSearch(search);

    if (!this.clientsBySearch.has(safeSearch)) {
      return null;
    }
    return this.clientsBySearch.get(safeSearch).error;
  }

  /**
   * Clears everything in the store.
   * Sets the state to pre.
   */
  @action clearAll() {
    this.clientsBySearch.clear();
    this.state = STATE_PRE;
  }

  /**
   * Fetches clients from the server by search phrase.
   *
   * @param {string=} search
   */
  @action fetchClientsBySearch(search) {
    const safeSearch = this.getSafeSearch(search);

    let currentExpireTime = null;
    if (this.clientsBySearch.has(safeSearch)) {
      currentExpireTime = this.clientsBySearch.get(safeSearch)[EXPIRE_TIME];
    }

    if (currentExpireTime && currentExpireTime <= Date.now()) {
      this.clientsBySearch.delete(safeSearch);
      currentExpireTime = null;
    }

    if (currentExpireTime) {
      this.state = STATE_FULFILLED;
      return;
    }

    this.state = STATE_PENDING;

    serverApi.clientsGetBySearch(safeSearch).then(
      action('fetchClientsBySearchSuccess', (clients) => {
        this.clientsBySearch.set(safeSearch, {
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          clients,
          error: null,
        });
        this.state = STATE_FULFILLED;
      }),
      action('fetchClientsBySearchError', (error) => {
        this.clientsBySearch.set(safeSearch, {
          clients: [],
          error,
        });
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {string} search
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(search, handlers) {
    const getFulfilled = () => {
      return this.getFulfilled(search);
    };
    const getRejected = () => {
      return this.getRejected(search);
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }
}

export default new ClientsBySearchStore();
