import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {actionAspectRatioComponent} from '../../../../../display/components/action/actionAspectRatioComponent';

/**
 * The PlaylistAspectRatio component.
 */
export class PlaylistAspectRatio extends React.Component {
  /**
   * The aspect ratio.
   *
   * @type {string}
   */
  @observable aspectRatio = '';

  /**
   * Triggered when the component is first mounted to the page.
   */
  componentDidMount() {
    this.initializeAspectRatio();
  }

  /**
   * Triggered when the component props update.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.entity && this.props.entity && prevProps.entity.get('id') === this.props.entity.get('id')) {
      return;
    }

    this.initializeAspectRatio();
  }

  /**
   * Initializes the aspect ratio from the entity.
   */
  @action initializeAspectRatio = () => {
    const {entity} = this.props;
    if (entity && entity.has('playlist')) {
      this.aspectRatio = entity.get('playlist').aspectRatio || '';
    }
  };

  /**
   * Updates the aspect ratio value in the playlist component.
   *
   * @param {string} newAspectRatio
   */
  @action saveAspectRatio = (newAspectRatio) => {
    if (!newAspectRatio || !newAspectRatio.match(/^[1-9]+[0-9]*:[1-9]+[0-9]*$/)) {
      this.initializeAspectRatio();
      return;
    }

    const {entity, game} = this.props;

    const actionParams = {
      entityId: entity.get('id'),
    };

    game.addAction(actionParams, actionAspectRatioComponent(
      'playlist',
      String(newAspectRatio)
    ));
  };

  /**
   * Stores the new aspectRatio value.
   *
   * @param {{target: {value: string}}} changeEvent
   */
  @action onChange = (changeEvent) => {
    this.aspectRatio = changeEvent.target.value;
  };

  /**
   * Sends updates with the new aspectRatio value.
   *
   * @param {{target: {value: string}}} blurEvent
   */
  onBlur = (blurEvent) => {
    this.saveAspectRatio(blurEvent.target.value);
  };

  /**
   * Updates the aspect ratio when the enter key is pressed.
   *
   * @param {{key: string}} keyboardEvent
   */
  onKeyDown = (keyboardEvent) => {
    if (keyboardEvent.key === 'Enter') {
      keyboardEvent.preventDefault();

      this.saveAspectRatio(this.aspectRatio);
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {id} = this.props;

    return (
      <div className="playlist-aspect-ratio">
        <input
          id={id || 'playlist-aspect-ratio-input'}
          className="form-control form-control-sm"
          value={this.aspectRatio}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
        />
      </div>
    );
  }
}

PlaylistAspectRatio.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: PropTypes.object.isRequired,

  id: PropTypes.string,
};

export default observer(PlaylistAspectRatio);
