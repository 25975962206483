import classNames from 'classnames';
import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

import EditTimelineControls from '../editTimeline/EditTimelineControls';
import SelectImageModal from '../../modals/selectImage/SelectImageModal';
import {actionUpdateComponent} from '../../../display/components/action/actionUpdateComponent';
import {cropComponent} from '../../../display/components/common/cropComponent';
import {interactionComponent} from '../../../display/components/common/interactionComponent';
import {
  cropShapeComponent, CIRCLE, ELLIPSE, RECTANGLE, TRIANGLE
} from '../../../display/components/common/cropShapeComponent';
import {imageComponent} from '../../../display/components/type/imageComponent';
import {getMainUrlByFileId} from '../../../utils/contentsHelper';

import './editImageControls.scss';

/**
 * The EditImageControls component.
 */
export class EditImageControls extends React.Component {
  /**
   * Whether or not the image choose/upload modal is open.
   *
   * @type {boolean}
   */
  @observable isModalOpen = false;

  /**
   * Whether or not the crop dropdown is open.
   *
   * @type {boolean}
   */
  @observable isCropDropdownOpen = false;

  /**
   * Updates the image entity when it is changed.
   *
   * @param {number} newFileId
   */
  onChangeImage = (newFileId) => {
    if (!newFileId) {
      return;
    }

    const {entity, game} = this.props;

    const currentImage = entity.get('image');

    const actionParams = {
      entityId: entity.get('id'),
    };

    game.addAction(actionParams, actionUpdateComponent(
      imageComponent(newFileId, currentImage.top, currentImage.left, currentImage.width, currentImage.height)
    ));
  };

  /**
   * Triggered when the new image is selected by the modal.
   *
   * @param {{contentFiles: Array.<{id: number}>}} newContent
   */
  @action onImageSelected = (newContent) => {
    this.isModalOpen = false;

    if (newContent && lodash.has(newContent, 'contentFiles[0].id')) {
      this.onChangeImage(newContent.contentFiles[0].id);
    }
  };

  /**
   * Triggered when the image selector modal should open.
   */
  @action onOpenSelector = () => {
    this.isModalOpen = true;
  };

  /**
   * Activates or deactivates the crop tool.
   *
   * @param {string} shape
   * @returns {function}
   */
  onClickCrop = (shape) => {
    return action(() => {
      const {entity, game} = this.props;

      const currentInteraction = entity.get('interaction');
      if (!currentInteraction.isActive) {
        return;
      }

      const newIsCropping = (!shape) ? !currentInteraction.isCropping : true;

      const currentImage = entity.get('image');
      const actionParams = {
        entityId: entity.get('id'),
      };

      let components = interactionComponent(true, newIsCropping);

      if (!entity.get('crop')) {
        components = {
          ...components,
          ...cropComponent(currentImage.top, currentImage.left, currentImage.width, currentImage.height),
        };
      }

      if (shape) {
        components = {
          ...components,
          ...cropShapeComponent(shape)
        };
      }

      game.addAction(actionParams, actionUpdateComponent(components));
    });
  };

  /**
   * Removes the cropping on the image.
   */
  onRemoveCrop = () => {
    const {entity, game} = this.props;

    const currentInteraction = entity.get('interaction');
    if (!currentInteraction.isActive) {
      return;
    }

    const currentImage = entity.get('image');
    const actionParams = {
      entityId: entity.get('id'),
    };

    let components = {
      ...interactionComponent(true, false),
      ...cropComponent(currentImage.top, currentImage.left, currentImage.width, currentImage.height),
      ...cropShapeComponent(RECTANGLE),
    };

    game.addAction(actionParams, actionUpdateComponent(components));
  };

  /**
   * Toggles the crop dropdown.
   */
  @action toggleCropDropdown = () => {
    this.isCropDropdownOpen = !this.isCropDropdownOpen;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;
    const image = entity.get('image');

    const imageSource = getMainUrlByFileId(image.fileId);

    let isLocked = false;
    let isCropLocked = false;
    if (entity.has('locked')) {
      const entityLock = entity.get('locked');
      if (lodash.includes(entityLock, 'time')) {
        // If the entity has time locked, then do not allow them to adjust anything.
        isLocked = true;
      }
      if (lodash.includes(entityLock, 'position') || lodash.includes(entityLock, 'size')) {
        isCropLocked = true;
      }
    }

    const interaction = entity.get('interaction');
    const cropButtonClasses = {
      active: (interaction) ? interaction.isCropping : false,
    };

    return (
      <div className="edit-image-controls">
        <div className="image-group">
          <h5 className="sidebar-title">Image</h5>

          {(isLocked) ? (
            <div className="can-not-edit">
              <span className="locked-text">
                <i className="fa fa-lock" />
                {' '}
                <span>Image Locked</span>
              </span>
            </div>
          ) : (
            <div className="image-selector">
              <div className="image-wrapper">
                <button className="btn btn-link image-change-button" type="button" onClick={this.onOpenSelector}>
                  <img className="image-thumbnail" src={imageSource} />
                  <span className="image-edit-icon">
                    <i className="fa fa-pencil" />
                  </span>
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-dark direct-change-button"
                  onClick={this.onOpenSelector}
                >
                  Change Image
                </button>
              </div>
            </div>
          )}
        </div>

        {(!isCropLocked) && (
          <div className="text-style control-group">
            <div className="group-header">
              <span className="group-header-label">Tools</span>
            </div>
            <div className="group-controls">
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <div><label>Crop</label></div>
                    <div className="align-buttons form-buttons">
                      <div className="btn-group">
                        <button
                          type="button"
                          className={classNames('btn btn-sm btn-dark form-button', cropButtonClasses)}
                          onClick={this.onClickCrop(null)}
                        >
                          <i className="fa fa-crop" />
                        </button>
                        <Dropdown isOpen={this.isCropDropdownOpen} toggle={this.toggleCropDropdown}>
                          <DropdownToggle
                            className={classNames('btn btn-sm btn-dark form-button crop-dropdown')}
                          >
                            <i className="fad fad-caret-down" />
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem
                              onClick={this.onClickCrop(RECTANGLE)}
                              toggle={false}
                            >Rectangle</DropdownItem>
                            <DropdownItem
                              onClick={this.onClickCrop(ELLIPSE)}
                              toggle={false}
                            >Ellipse</DropdownItem>
                            <DropdownItem
                              onClick={this.onClickCrop(CIRCLE)}
                              toggle={false}
                            >Circle</DropdownItem>
                            <DropdownItem
                              onClick={this.onClickCrop(TRIANGLE)}
                              toggle={false}
                            >Triangle</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <div><label>Reset Crop</label></div>
                    <div className="align-buttons form-buttons">
                      <div className="btn-group">
                        <button
                          type="button"
                          className={classNames('btn btn-sm btn-dark form-button')}
                          onClick={this.onRemoveCrop}
                        >
                          <i className="fa fa-ban" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <EditTimelineControls entity={entity} game={game} />

        <SelectImageModal
          isOpen={this.isModalOpen}
          onComplete={this.onImageSelected}
        />
      </div>
    );
  }
}

EditImageControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditImageControls);
