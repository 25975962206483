import classNames from 'classnames';
import DOMPurify from 'dompurify';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {ALIGN_BOTTOM, ALIGN_MIDDLE} from '../../controls/editText/constants/styleContants';
import entityActivateHoc from '../../hoc/entityActivateHoc';
import {makeContentText} from '../../../display/components/type/feedTextComponent';

import './displayFeedText.scss';

/**
 * The DisplayFeedText component.
 */
export class DisplayFeedText extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game, style, className} = this.props;

    const entityId = entity.get('id');
    const feedText = entity.get('feedText');

    // Use table and table-cell in order to do vertical alignment that is supported in IE.
    const tableStyle = {
      display: 'table',
      width: style.width,
      height: style.height,
    };

    const preStyle = {
      display: 'table-cell',
    };
    if (feedText.verticalAlign === ALIGN_MIDDLE) {
      preStyle.verticalAlign = 'middle';
    } else if (feedText.verticalAlign === ALIGN_BOTTOM) {
      preStyle.verticalAlign = 'bottom';
    } else {
      preStyle.verticalAlign = 'top';
    }

    const feedContent = game.feed.getFeedData(feedText, {
      replace: true,
      default: 'Select Feed Source',
    });

    const feedDom = makeContentText(feedText, feedContent.join('<br />'));

    const safeDom = DOMPurify.sanitize(feedDom);
    return (
      <div id={entityId} className={classNames('display-feed-text', className)} style={style}>
        <div style={tableStyle}>
          {(feedText.isHtml) ? (
            <div
              className="display-text-pre"
              style={preStyle}
              dangerouslySetInnerHTML={{__html: safeDom}} // eslint-disable-line react/no-danger
            />
          ) : (
            <div className="display-text-pre" style={preStyle}>{safeDom}</div>
          )}
        </div>
      </div>
    );
  }
}

DisplayFeedText.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,

  className: PropTypes.string,
};

export default entityActivateHoc(
  observer(DisplayFeedText)
);
