import {getEntityPosition, getEntitySize} from './entityHelper';

/**
 * The linear easing.
 * @const {string}
 */
export const EASING_LINEAR = 'linear';

/**
 * The quad in easing.
 * @const {string}
 */
export const EASING_QUAD_IN = 'quadIn';

/**
 * The name of the parameter that will be replaced with the off screen position.
 * @const {string}
 */
export const PARSE_OFF = 'off';

/**
 * The name of the parameter that will be replaced with the entity position.
 * @const {string}
 */
export const PARSE_POSITION = 'position';

/**
 * All the available transition presets.
 * @type {{}}
 */
export const transitionPresets = {
  fadeIn: {
    name: 'fadeIn',
    flow: 'in',
    easing: EASING_LINEAR,
    details: {opacity: [0, 1]}
  },
  fadeOut: {
    name: 'fadeOut',
    flow: 'out',
    easing: EASING_LINEAR,
    details: {opacity: [1, 0]}
  },
  topFlyIn: {
    name: 'topFlyIn',
    flow: 'in',
    easing: EASING_QUAD_IN,
    parse: true,
    details: {
      top: [`${PARSE_OFF}.top`, `${PARSE_POSITION}.top`],
    }
  },
  topFlyOut: {
    name: 'topFlyOut',
    flow: 'out',
    easing: EASING_QUAD_IN,
    parse: true,
    details: {
      top: [`${PARSE_POSITION}.top`, `${PARSE_OFF}.top`],
    }
  },
  bottomFlyIn: {
    name: 'bottomFlyIn',
    flow: 'in',
    easing: EASING_QUAD_IN,
    parse: true,
    details: {
      top: [`${PARSE_OFF}.bottom`, `${PARSE_POSITION}.bottom`],
    }
  },
  bottomFlyOut: {
    name: 'bottomFlyOut',
    flow: 'out',
    easing: EASING_QUAD_IN,
    parse: true,
    details: {
      top: [`${PARSE_POSITION}.bottom`, `${PARSE_OFF}.bottom`],
    }
  },
  leftFlyIn: {
    name: 'leftFlyIn',
    flow: 'in',
    easing: EASING_QUAD_IN,
    parse: true,
    details: {
      left: [`${PARSE_OFF}.left`, `${PARSE_POSITION}.left`],
    }
  },
  leftFlyOut: {
    name: 'leftFlyOut',
    flow: 'out',
    easing: EASING_QUAD_IN,
    parse: true,
    details: {
      left: [`${PARSE_POSITION}.left`, `${PARSE_OFF}.left`],
    }
  },
  rightFlyIn: {
    name: 'rightFlyIn',
    flow: 'in',
    easing: EASING_QUAD_IN,
    parse: true,
    details: {
      left: [`${PARSE_OFF}.right`, `${PARSE_POSITION}.right`],
    }
  },
  rightFlyOut: {
    name: 'rightFlyOut',
    flow: 'out',
    easing: EASING_QUAD_IN,
    parse: true,
    details: {
      left: [`${PARSE_POSITION}.right`, `${PARSE_OFF}.right`],
    }
  },
};

/**
 * Parses the off parameter.
 *
 * @param {string} value
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @returns {number}
 */
export function parseOff(value, entity, game) {
  const direction = value.substr(`${PARSE_OFF}.`.length);
  const entitySize = getEntitySize(entity);

  if (direction === 'top') {
    return 0 - entitySize.height;
  } else if (direction === 'right') {
    return game.resolution.width;
  } else if (direction === 'bottom') {
    return game.resolution.height;
  } else if (direction === 'left') {
    return 0 - entitySize.width;
  }
  return 0;
}

/**
 * Parses the position parameter.
 *
 * @param {string} value
 * @param {ObservableMap} entity
 * @returns {number}
 */
export function parsePosition(value, entity) {
  const positionTrait = value.substr(`${PARSE_POSITION}.`.length);
  const entityPosition = getEntityPosition(entity);

  const safePosition = entityPosition.default || entityPosition;
  if (positionTrait === 'top') {
    return safePosition.top;
  } else if (positionTrait === 'right') {
    return safePosition.left;
  } else if (positionTrait === 'bottom') {
    return safePosition.top;
  } else if (positionTrait === 'left') {
    return safePosition.left;
  }
  return 0;
}
