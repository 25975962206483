/**
 * The visibility component.
 *
 * @param {boolean} isVisible
 * @param {number} opacity
 * @returns {{visible: {isVisible: number, opacity: number}}}
 */
export function visibleComponent(isVisible, opacity) {
  return {
    visible: {
      isVisible: Boolean(isVisible),
      opacity,
      default: {
        opacity,
      },
    },
  };
}

/**
 * Gets the visible component from the source item.
 *
 * @param {{startTime: number, opacity: ?number}} item
 * @returns {{visible: {isVisible: number, opacity: number}}}
 */
export function getVisibleFromSource(item) {
  if (!item.setup || !item.setup.position) {
    return {};
  }

  const itemSetup = item.setup || {};

  let opacity = itemSetup.opacity;
  if (!itemSetup.opacity && itemSetup.opacity !== 0) {
    opacity = 1;
  }

  return visibleComponent(
    Boolean(item.startTime <= 0),
    opacity
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getVisibleForSource(entity) {
  if (!entity.has('visible')) {
    return {};
  }

  const defaultVisible = entity.get('visible').default;
  if (defaultVisible.opacity === 1) {
    return {};
  }

  return {
    setup: {
      opacity: defaultVisible.opacity
    },
  };
}
