import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import React from 'react';

import LoginForm from './components/loginForm/LoginForm';
import PasswordResetForm from './components/passwordResetForm/PasswordResetForm';
import BrowserAlert from '../../common/browserAlert/BrowserAlert';
import {passwordResetRoute} from '../../routePaths';
import loginImage from '../../../assets/images/login-image.jpg';
import Works24LSmallLogoSvg from '../../../assets/images/works24-small-logo.png';
import {routeHistory} from '../../../utils/history';

import './loginPage.scss';

/**
 * The LoginPage component.
 */
export class LoginPage extends React.Component {
  /**
   * Login form view: either password reset or login
   *
   * @type {boolean}
   */
  @observable loginView = true;

  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);

    this.setLoginView();
  }

  /**
   * Triggered when the component updated (e.g. due to URL change).
   */
  componentDidUpdate() {
    this.setLoginView();
  }

  /**
   * Page view change handler
   *
   */
  @action setLoginView = () => {
    const currentPathName = routeHistory.location.pathname;
    this.loginView = (currentPathName !== passwordResetRoute);
  };

  /**
   * Update property [key] to be [value]
   * @param {string} key
   * @param {*} value
   */
  updateProperty = (key, value) => {
    this[key] = value;
  };

  /**
   * Login input onChange handler
   *
   * @param {{}} changeEvent
   */
  onChange = (changeEvent) => {
    this.updateProperty(changeEvent.target.name, changeEvent.target.value);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div id="login-page" className="system-page row no-gutters full-height">
        <div className="full-height col-md-6">
          <div
            className="login-page-bg-image"
            style={{backgroundImage: `url('${loginImage}')`}}
          />
        </div>
        <div className="full-height col-md-6 login-col-right">
          <div className="login-page-content">
            {(this.loginView) ? (
              <LoginForm />
            ) : (
              <PasswordResetForm />
            )}
          </div>
          <div className="bottom-logo">
            <img src={Works24LSmallLogoSvg} className="login-page-logo" alt="Works24" />
          </div>
        </div>

        <BrowserAlert />
      </div>
    );
  }
}

export default observer(LoginPage);
