import * as mathJS from 'mathjs';
import utils from 'interactjs/src/utils';

import {getDimensionsFromElement, getRotationFromElement} from '../../dragDropHelper';
import {RAD_TO_DEGREES} from '../../mathHelper';

/**
 * Checks if an edge is a valid/active edge.
 *
 * @param {string} edgeName
 * @param {*} value
 * @param {{x: number, y: number}} page
 * @param {HTMLElement} element
 * @param {{}} interactableElement
 * @param {{}} rect
 * @param {{topLeft: number[], topRight: number[], bottomLeft: number[], bottomRight: number[]}} corners
 * @param {number} margin
 * @param {number} scale
 * @returns {boolean}
 */
export function checkForEdge(edgeName, value, page, element, interactableElement, rect, corners, margin, scale) {
  if (!value) {
    return false;
  }

  // true value, use pointer coords and element rect
  if (value === true) {
    return isPointerNearEdge(edgeName, corners, page, rect, margin, scale);
  }

  // the remaining checks require an element
  if (!utils.is.element(element)) {
    return false;
  }

  // the value is an element to use as a rotate handle
  // otherwise check if element matches value as selector
  return utils.is.element(value) ? (
    value === element
  ) : utils.matchesUpTo(element, value, interactableElement);
}

/**
 * Determines whether or not the pointer is near the rectangle edge.
 *
 * @param {string} edgeName
 * @param {{topLeft: number[], topRight: number[], bottomLeft: number[], bottomRight: number[]}} corners
 * @param {{x: number, y: number}} page
 * @param {{width: number, height: number, top: number, right: number, bottom: number, left: number}} rect
 * @param {number} margin
 * @param {number} scale
 * @returns {boolean}
 */
export function isPointerNearEdge(edgeName, corners, page, rect, margin, scale) {
  const point = [page.x, page.y];
  const scaledMargin = margin * scale;

  // if dimensions are negative, "switch" edges
  const width = utils.is.number(rect.width) ? rect.width : rect.right - rect.left;
  const height = utils.is.number(rect.height) ? rect.height : rect.bottom - rect.top;

  let safeEdgeName = edgeName;
  if (width < 0) {
    if (safeEdgeName === 'left') {
      safeEdgeName = 'right';
    } else if (safeEdgeName === 'right') {
      safeEdgeName = 'left';
    }
  }
  if (height < 0) {
    if (safeEdgeName === 'top') {
      safeEdgeName = 'bottom';
    } else if (safeEdgeName === 'bottom') {
      safeEdgeName = 'top';
    }
  }

  if (safeEdgeName === 'left') {
    return (distanceBetweenPointAndLine(point, corners.topLeft, corners.bottomLeft) <= scaledMargin);
  } else if (safeEdgeName === 'top') {
    return (distanceBetweenPointAndLine(point, corners.topLeft, corners.topRight) <= scaledMargin);
  } else if (safeEdgeName === 'right') {
    return (distanceBetweenPointAndLine(point, corners.topRight, corners.bottomRight) <= scaledMargin);
  } else if (safeEdgeName === 'bottom') {
    return (distanceBetweenPointAndLine(point, corners.bottomLeft, corners.bottomRight) <= scaledMargin);
  }

  return false;
}

/**
 * Calculates a rotation edge.
 *
 * @param {{}} rect
 * @param {HTMLElement} element
 * @param {number} scale
 * @returns {{topLeft: number[], topRight: number[], bottomLeft: number[], bottomRight: number[]}}
 */
export function calculateElementPoints(rect, element, scale) { // eslint-disable-line complexity
  const rotationRad = getRotationFromElement(element) / RAD_TO_DEGREES;
  const dimensions = getDimensionsFromElement(element, scale);
  const center = {
    x: rect.left + (rect.width / 2),
    y: rect.top + (rect.height / 2),
  };

  const rightAngleRad = (90 / RAD_TO_DEGREES); // eslint-disable-line no-magic-numbers

  const cornerAngle = (mathJS.atan(dimensions.width / dimensions.height));
  const offsetAngle = rotationRad - mathJS.abs(cornerAngle);
  const horizontal = (1 / 2) * mathJS.hypot(dimensions.width, dimensions.height);

  const fromCenter = {
    x: horizontal * mathJS.sin(offsetAngle),
    y: horizontal * mathJS.cos(offsetAngle),
  };

  const topLeftCorner = {
    x: center.x + fromCenter.x,
    y: center.y - fromCenter.y,
  };
  const bottomRightCorner = {
    x: center.x - fromCenter.x,
    y: center.y + fromCenter.y,
  };

  const secondAngle = rightAngleRad - rotationRad;
  const topRightCorner = {
    x: topLeftCorner.x + (dimensions.width * mathJS.sin(secondAngle)),
    y: topLeftCorner.y + (dimensions.width * mathJS.cos(secondAngle)),
  };

  const bottomLeftCorner = {
    x: topLeftCorner.x - (dimensions.height * mathJS.sin(rotationRad)),
    y: topLeftCorner.y + (dimensions.height * mathJS.cos(rotationRad)),
  };

  return {
    topLeft: [topLeftCorner.x, topLeftCorner.y],
    topRight: [topRightCorner.x, topRightCorner.y],
    bottomLeft: [bottomLeftCorner.x, bottomLeftCorner.y],
    bottomRight: [bottomRightCorner.x, bottomRightCorner.y],
  };
}

/**
 * Calculates the distance between a point and a line defined by two points.
 *
 * @param {number[]} point
 * @param {number[]} lineOne
 * @param {number[]} lineTwo
 * @returns {number}
 */
export function distanceBetweenPointAndLine(point, lineOne, lineTwo) {
  const lineXDelta = (lineTwo[0] - lineOne[0]);
  const lineYDelta = (lineTwo[1] - lineOne[1]);

  const lineLength = mathJS.hypot(lineYDelta, lineXDelta);

  // Formula from: {@url https://en.wikipedia.org/wiki/Distance_from_a_point_to_a_line}
  return mathJS.abs(
    (lineYDelta * point[0]) - (lineXDelta * point[1]) + (lineTwo[0] * lineOne[1]) - (lineTwo[1] * lineOne[0])
  ) / lineLength;
}
