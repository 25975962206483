import lodash from 'lodash';
import {action, observable, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {FeedIndexSelector} from '../../../feedIndexSelector/FeedIndexSelector';
import Select from '../../../select/Select';
import inject from '../../../../hoc/injectHoc';
import ConfirmModal from '../../../../modals/confirm/ConfirmModal';
import FeedSelectModal from '../../../../modals/feedSelect/FeedSelectModal';
import {actionUpdateComponent} from '../../../../../display/components/action/actionUpdateComponent';
import {FEED_TYPE_API, FEED_TYPE_CUSTOM} from '../../../../../stores/game/gameFeedStore';
import {
  resetFeedImageComponent
} from '../../../../sidebars/editor/components/entityAddButton/entities/placeholderFeedImage';
import {
  resetFeedTextComponent
} from '../../../../sidebars/editor/components/entityAddButton/entities/placeholderFeedText';

import './feedDropdown.scss';

/**
 * The value that when selected will open the feed select modal.
 *
 * @const {string}
 */
const VALUE_FEED_SELECT = 'feedSelect';

/**
 * Dropdown for selecting the feed.
 */
export class FeedDropdown extends React.Component {
  /**
   * Whether or not the feed select modal is open.
   *
   * @type {boolean}
   */
  @observable isFeedSelectOpen = false;

  /**
   * Whether or not the confirm modal is open.
   *
   * @type {boolean}
   */
  @observable isConfirmOpen = false;

  /**
   * The value to set after the confirm modal.
   *
   * @type {?string}
   */
  @observable afterConfirmValue = null;

  /**
   * Opens the feed select modal.
   */
  @action openFeedSelect = () => {
    this.isFeedSelectOpen = true;
  };

  /**
   * Opens the confirm modal.
   *
   * @param {string=} newValue
   */
  @action openConfirmModal = (newValue) => {
    this.isConfirmOpen = true;

    this.afterConfirmValue = newValue || null;
  };

  /**
   * Closes the feed select modal after it is finished.
   *
   * @param {?{type: Symbol, apiUrl: string, apiType: string, customUrl: string}} newFeedSource
   */
  @action onFeedSelectCompleted = (newFeedSource) => {
    this.isFeedSelectOpen = false;

    if (!newFeedSource) {
      return;
    }

    const {game} = this.props.displayEditorStore;
    if (!game) {
      return;
    }

    if (newFeedSource.apiUrl) {
      const feedSource = `${newFeedSource.apiType}/${newFeedSource.apiUrl}`;
      game.feed.setFeedSource(feedSource, newFeedSource.type);
      game.feed.fetchFeedDataFromSource(true);
    } else if (newFeedSource.customUrl) {
      const feedSource = newFeedSource.customUrl;
      game.feed.setFeedSource(feedSource, newFeedSource.type);
      game.feed.fetchFeedDataFromSource(true);
    }

    this.clearFeedEntities();
  };

  /**
   * Clears the data from the existing feed entities.
   *
   * @param {boolean=} indexOnly If true, only resets the indexes to zero, instead of clearing all fields.
   */
  clearFeedEntities = (indexOnly) => {
    const {
      /* @type GameStore */ game,
    } = this.props.displayEditorStore;

    if (!game) {
      return;
    }

    // Also reset the feed index when the feeds change.
    runInAction('updateFeedIndexOnChangeEntities', () => {
      game.feed.feedIndex = 0;
    });

    if (indexOnly) {
      return;
    }

    game.entities.forEach((entity) => {
      const entityType = entity.get('element');
      if (entityType !== 'feedText' && entityType !== 'feedImage') {
        return;
      }

      const actionParams = {entityId: entity.get('id')};

      if (entityType === 'feedText') {
        const current = entity.get('feedText');

        const newData = resetFeedTextComponent(current);

        game.addAction(actionParams, actionUpdateComponent(newData));
      } else if (entityType === 'feedImage') {
        const current = entity.get('feedImage');

        const newData = resetFeedImageComponent(current, game);

        game.addAction(actionParams, actionUpdateComponent(newData));
      }
    });
  };

  /**
   * Sets FeedId value on active content
   *
   * @param {number} newValue
   */
  onChangeFeedSelection = (newValue) => {
    if (newValue === VALUE_FEED_SELECT) {
      this.openConfirmModal();
      return;
    }

    const {game} = this.props.displayEditorStore;
    if (!game) {
      return;
    }

    if (game.feed.feedType === FEED_TYPE_API || game.feed.feedType === FEED_TYPE_CUSTOM) {
      this.openConfirmModal(newValue);
      return;
    }

    game.feed.setFeedId(newValue || null);

    this.clearFeedEntities(true);
  };

  /**
   * Opens the feed select modal after the confirmation.
   *
   * @param {boolean} wasConfirmed
   */
  @action onConfirmSwitchFeed = (wasConfirmed) => {
    this.isConfirmOpen = false;

    if (!wasConfirmed) {
      return;
    }

    if (this.afterConfirmValue) {
      const {game} = this.props.displayEditorStore;

      if (game) {
        game.feed.setFeedId(this.afterConfirmValue || null);

        this.clearFeedEntities();
      }
    } else {
      this.openFeedSelect();
    }
  };

  /**
   * Builds the options for the react select.
   *
   * @param {Object[]} feeds
   * @param {boolean} isCustomAllowed
   * @param {string=} feedSource
   * @returns {Array.<{label: string, value: string}>}
   */
  buildOptions = (feeds, isCustomAllowed, feedSource) => {
    const options = [{label: 'None', value: ''}];

    if (feeds) {
      lodash.sortBy(feeds, (feed) => {
        return feed.name;
      }).forEach((feed) => {
        // if (feed.isTicker) {
        //   // We don't want to list ticker feeds here.
        //   return;
        // }

        options.push({
          label: feed.name,
          value: String(feed.id),
        });
      });
    }

    if (feedSource) {
      options.push({
        label: feedSource,
        value: String(feedSource),
      });
    }

    if (isCustomAllowed) {
      options.push({
        label: 'Create New Feed',
        value: VALUE_FEED_SELECT,
      });
    }

    return options;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {ActiveContentStore} */ activeContentStore,
      /** @type {FeedSummaryStore} */ apiFeedSummaryStore,
      /** @type {DisplayEditorStore} */ displayEditorStore,
      /** @type {SessionStore} */ sessionStore,
      /** @type {?{canEditGlobals: boolean}} */ user,
    } = this.props;

    const {game} = displayEditorStore;

    if (!game || !sessionStore || !activeContentStore.contentId) {
      return null;
    }

    const isCustomAllowed = (user && user.canEditGlobals);

    const feeds = apiFeedSummaryStore.getFulfilled(sessionStore.clientId);
    if (!feeds && !isCustomAllowed) {
      return null;
    }

    const {feedId, feedSource} = game.feed;

    const currentValue = String(feedSource || feedId || '');

    const options = this.buildOptions(feeds, isCustomAllowed, feedSource);

    return (
      <div className="feed-dropdown">
        <span>Feed: </span>
        <Select
          theme="dark"
          options={options}
          value={currentValue}
          onChange={this.onChangeFeedSelection}
        />

        <FeedIndexSelector
          game={game}
          label="Choose Feed"
        />

        {(this.isFeedSelectOpen) && (
          <FeedSelectModal
            isOpen={true}
            currentValue={feedSource}
            onComplete={this.onFeedSelectCompleted}
            title="Create New Feed"
          />
        )}

        <ConfirmModal
          isOpen={this.isConfirmOpen}
          onComplete={this.onConfirmSwitchFeed}
          confirmText={
            'Switching feeds will clear all the Feed Text and Feed Image data. Are you sure you want to switch feeds?'
          }
        />
      </div>
    );
  }
}

FeedDropdown.propTypes = {
  activeContentStore: MobxPropTypes.observableObject,
  apiFeedFromApiStore: MobxPropTypes.observableObject,
  apiFeedFromCustomStore: MobxPropTypes.observableObject,
  apiFeedSummaryStore: MobxPropTypes.observableObject,
  displayEditorStore: MobxPropTypes.observableObject,
  sessionStore: MobxPropTypes.observableObject,
  user: PropTypes.object,
};

FeedDropdown.wrappedComponent = {};
FeedDropdown.wrappedComponent.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
  apiFeedFromApiStore: MobxPropTypes.observableObject.isRequired,
  apiFeedFromCustomStore: MobxPropTypes.observableObject.isRequired,
  apiFeedSummaryStore: MobxPropTypes.observableObject.isRequired,
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
  sessionStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(FeedDropdown)(
  observer(FeedDropdown)
);
