import lodash from 'lodash';

import {w24FontStyles} from './editorFonts';

/**
 * The bold text style.
 * @const {string}
 */
export const BOLD = 'BOLD';

/**
 * The italic text style.
 * @const {string}
 */
export const ITALIC = 'ITALIC';

/**
 * The underline text style.
 * @const {string}
 */
export const UNDERLINE = 'UNDERLINE';

/**
 * The align left text style.
 * @const {string}
 */
export const ALIGN_LEFT = 'ALIGN_LEFT';

/**
 * The align center text style.
 * @const {string}
 */
export const ALIGN_CENTER = 'ALIGN_CENTER';

/**
 * The align right text style.
 * @const {string}
 */
export const ALIGN_RIGHT = 'ALIGN_RIGHT';

/**
 * The align justify (spread text across width) text style.
 * @const {string}
 */
export const ALIGN_JUSTIFY = 'ALIGN_JUSTIFY';

/**
 * The vertical align top text style.
 * @const {string}
 */
export const ALIGN_TOP = 'ALIGN_TOP';

/**
 * The vertical align middle text style.
 * @const {string}
 */
export const ALIGN_MIDDLE = 'ALIGN_MIDDLE';

/**
 * The vertical align bottom text style.
 * @const {string}
 */
export const ALIGN_BOTTOM = 'ALIGN_BOTTOM';

/**
 * The prefix before the color code in the style name.
 * @const {string}
 */
export const COLOR_PREFIX = 'COLOR-';

/**
 * The prefix before the font size in the style name.
 * @const {string}
 */
export const FONT_SIZE_PREFIX = 'FONT-SIZE-';

/**
 * The minimum font size.
 * @const {number}
 */
export const MIN_FONT_SIZE = 15;

/**
 * The maximum font size.
 * @const {number}
 */
export const MAX_FONT_SIZE = 400;

/**
 * The prefix before the line height in the style name.
 * @const {string}
 */
export const LINE_HEIGHT_PREFIX = 'LINE-HEIGHT-';

/**
 * The minimum line height.
 * @const {number}
 */
export const MIN_LINE_HEIGHT = 0;

/**
 * The maximum line height.
 * @const {number}
 */
export const MAX_LINE_HEIGHT = 500;

/**
 * The line height step.
 * @type {number}
 */
export const LINE_HEIGHT_STEP = 10;

/**
 * The actual line height will be the value divided by this number.
 * @const {number}
 */
export const LINE_HEIGHT_DIVISOR = 100;

/**
 * The parseFloat toFixed() value for line height.
 * @const {number}
 */
export const LINE_HEIGHT_FIXED = String(LINE_HEIGHT_DIVISOR).length - 1;

/**
 * The prefix before the letter spacing in the style name.
 * @const {string}
 */
export const LETTER_SPACING_PREFIX = 'LETTER-SPACING-';

/**
 * The minimum line height.
 * @const {number}
 */
export const MIN_LETTER_SPACING = 0;

/**
 * The maximum line height.
 * @const {number}
 */
export const MAX_LETTER_SPACING = 200;

/**
 * The list of alignment styles.
 *
 * @type {Object.<string, {}>}
 */
export const customAlignStyles = {
  [ALIGN_LEFT]: {
    textAlign: 'left',
  },
  [ALIGN_CENTER]: {
    textAlign: 'center',
  },
  [ALIGN_RIGHT]: {
    textAlign: 'right',
  },
  [ALIGN_JUSTIFY]: {
    textAlign: 'justify',
  },
};

/**
 * The list of available font styles.
 *
 * @type {Object.<string, {}>}
 */
export const customFontStyles = Object.assign({
  'FONT_ARIAL': {
    name: 'Arial',
    fontFamily: 'Arial, Helvetica, sans-serif',
  },
  'FONT_COURIER': {
    name: 'Courier New',
    fontFamily: '"Courier New", Courier, monospace',
  },
  'FONT_GEORGIA': {
    name: 'Georgia',
    fontFamily: 'Georgia, serif',
  },
  'FONT_IMPACT': {
    name: 'Impact',
    fontFamily: 'Impact, Charcoal, sans-serif',
  },
  'FONT_LUCIDA': {
    name: 'Lucida Sans',
    fontFamily: '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
  },
  'FONT_PALATINO': {
    name: 'Palatino Linotype',
    fontFamily: '"Palatino Linotype", "Book Antiqua", Palatino, serif',
  },
  'FONT_TAHOMA': {
    name: 'Tahoma',
    fontFamily: 'Tahoma, Geneva, sans-serif',
  },
  'FONT_TIMES': {
    name: 'Times New Roman',
    fontFamily: '"Times New Roman", Times, serif',
  },
  'FONT_VERDANA': {
    name: 'Verdana',
    fontFamily: 'Verdana, Geneva, sans-serif',
  },
}, w24FontStyles);

/**
 * Style Maps
 */

/**
 * The custom draftJS styles.
 *
 * @type {Object.<string, {}>}
 */
export const customStylesMap = Object.assign(
  {},
  customAlignStyles,
  customFontStyles,
);

/**
 * The map of html classes to draftJs styles.
 *
 * @type {Object.<string, string>}
 */
export const classToStyleMap = {
  'md-align-left': ALIGN_LEFT,
  'md-align-center': ALIGN_CENTER,
  'md-align-right': ALIGN_RIGHT,
  'md-align-justify': ALIGN_JUSTIFY,
  'md-underline': UNDERLINE,
  'md-color': COLOR_PREFIX,
  'md-font-size': FONT_SIZE_PREFIX,
  'md-line-height': LINE_HEIGHT_PREFIX,
  'md-letter-spacing': LETTER_SPACING_PREFIX,
};

/**
 * The map of html classes to draftJs styles.
 *
 * @type {Object.<string, string>}
 */
export const styleToClassMap = {
  [ALIGN_LEFT]: 'md-align-left',
  [ALIGN_CENTER]: 'md-align-center',
  [ALIGN_RIGHT]: 'md-align-right',
  [ALIGN_JUSTIFY]: 'md-align-justify',
  [UNDERLINE]: 'md-underline',
};

/**
 * The map of draftJs styles to Markdown symbols.
 *
 * @type {Object.<string, string>}
 */
export const styleToMarkdownMap = {
  [ALIGN_CENTER]: ['{|}', '{!|}'],
  [ALIGN_RIGHT]: ['{/}', '{!/}'],
  [ALIGN_LEFT]: ['{\\}', '{!\\}'],
  [ALIGN_JUSTIFY]: ['{-}', '{!-}'],
  [COLOR_PREFIX]: ['{#}', '{!#}'],
  [FONT_SIZE_PREFIX]: ['{0}', '{!0}'],
  [LINE_HEIGHT_PREFIX]: ['{$}', '{!$}'],
  [LETTER_SPACING_PREFIX]: ['{%}', '{!%}'],
};

/**
 * The map of Markdown symbols to html classes.
 *
 * @type {Object.<string, string>}
 */
export const markdownToClassMap = {
  '{\\}': 'md-align-left',
  '{|}': 'md-align-center',
  '{/}': 'md-align-right',
  '{-}': 'md-align-justify',
  '{#}': 'md-color',
  '{0}': 'md-font-size',
  '{$}': 'md-line-height',
  '{%}': 'md-letter-spacing',
  '++': 'md-underline',
};

// Populate the style/class/markdown maps with font items.
lodash.forEach(customFontStyles, (unused, fontStyle) => {
  const fontName = fontStyle.replace('FONT_', '').toLowerCase();
  const className = `md-font-${fontName}`;
  const markdownSymbol = `{${fontName}}`;
  const closeMdSymbol = `{!${fontName}}`;

  classToStyleMap[className] = fontStyle;
  styleToClassMap[fontStyle] = className;
  styleToMarkdownMap[fontStyle] = [markdownSymbol, closeMdSymbol];
  markdownToClassMap[markdownSymbol] = className;
});
