import lodash from 'lodash';
import {generatePath} from 'react-router-dom';

import {routeHistory} from './history';

/**
 * Parses the list of param values out of the given pathname.
 *
 * @param {string} pathname
 * @param {string} routePath
 * @returns {{}}
 */
export function parseParamsFromRoute(pathname, routePath) {
  const currentBits = String(pathname).split('/');
  const paramBits = String(routePath).split('/');

  const params = {};
  paramBits.forEach((paramBit, index) => {
    if (paramBit[0] !== ':') {
      return;
    }
    const paramName = paramBit.substring(1);
    params[paramName] = currentBits[index];
  });

  return params;
}

/**
 * Replaces the route params with their values.
 *
 * @param {string} route
 * @param {object} params
 * @param {boolean=} routerReplace
 * @returns {string}
 */
export function replaceRouteParams(route, params, routerReplace) {
  const safeParams = params || {};
  const safeRoute = String(route || '');

  if (routerReplace) {
    return generatePath(safeRoute, safeParams);
  }

  return lodash.reduce(safeParams, (newRoute, paramValue, paramName) => {
    return newRoute.replace(new RegExp(`:${paramName}\\??`), paramValue);
  }, safeRoute);
}

/**
 * Parses the given search string or a routeHistory search string into a params object
 * or returns the value of the given key.
 *
 * @param {string|{location: {search: string}}} routeHistoryOrSearch
 * @param {string=} getKey If provided, returns only the value of the given key.
 * @returns {Object.<string, string>|string}
 */
export function parseSearchParams(routeHistoryOrSearch, getKey) {
  let search = routeHistoryOrSearch;
  if (lodash.has(routeHistoryOrSearch, 'location.search')) {
    search = routeHistory.location.search;
  }

  const searchParams = new URLSearchParams(String(search || ''));

  if (getKey) {
    return searchParams.get(getKey);
  }

  const allParams = {};
  for (let [paramKey, paramValue] of searchParams.entries()) {
    allParams[paramKey] = paramValue;
  }

  return allParams;
}
