import axios from 'axios';
import assignDeep from 'assign-deep';
import lodash from 'lodash';
import {fromPromise} from 'mobx-utils';

import pascalCase from './pascalCase';
import {logoutAction} from '../actions/session/logoutAction';

/**
 * The key used to store the current user in local storage or a cookie.
 *
 * @const {string}
 */
export const AUTH_STORAGE_KEY = 'w24t';

/**
 * The name of the header containing the api token.
 *
 * @const {string}
 */
const API_TOKEN_KEY = 'w24-token';

/**
 * The HTTP status code for an unauthorized request.
 * Usually means the token is missing or expired.
 *
 * @const {number}
 */
const UNAUTHORIZED_STATUS_CODE = 401;

/**
 * The default Axios options to make for each request unless overridden.
 *
 * @type {{}}
 */
const defaultOptions = {
  headers: {},
  withCredentials: true,
};

/**
 * The default csrf Axios options to send the csrf token.
 *
 * @type {{}}
 */
const csrfOptions = {
  xsrfCookieName: 'csrf',
  xsrfHeaderName: 'X-CSRF-Token',
};

/**
 * Makes a general request to the server based on the given options.
 *
 * @param {{}} options See Axios Options.
 * @returns {Promise}
 */
function serverRequest(options) {
  // Only send csrf token for non-get requests.
  const requestCsrfOptions = (options.method && options.method !== 'get') ? csrfOptions : {};

  // NOTE: Because of a bug in assign-deep, you must clone everything you send in or it will mutate your objects.
  // @see https://github.com/jonschlinkert/assign-deep/issues/18
  const combinedOptions = lodash.cloneDeep(assignDeep(
    {},
    lodash.cloneDeep(defaultOptions),
    lodash.cloneDeep(requestCsrfOptions),
    lodash.cloneDeep(options)
  ));

  // Persist the original data because assignDeep turns everything into an
  // object. This is a problem if sending multipart/form-data bodies.
  if (combinedOptions.data) {
    combinedOptions.data = options.data;
  }

  const authToken = localStorage.getItem(AUTH_STORAGE_KEY);
  if (authToken && options.auth !== false) {
    if (!combinedOptions.headers) {
      combinedOptions.headers = {};
    }
    combinedOptions.headers.Authorization = `Bearer ${authToken}`;
  }

  // Process a cancelable callback.
  if (combinedOptions.cancelable) {
    const cancelTokenSource = axios.CancelToken.source();
    combinedOptions.cancelToken = cancelTokenSource.token;
    combinedOptions.cancelable(cancelTokenSource.cancel);

    delete combinedOptions.cancelable;
  }

  // PascalCase the body keys
  if (combinedOptions.pascal && combinedOptions.data) {
    combinedOptions.data = pascalCase(combinedOptions.data);
  }

  return fromPromise(
    axios(combinedOptions).catch((err) => {
      if (axios.isCancel(err)) {
        const canceledError = new Error('canceled');
        canceledError.canceled = true;
        throw canceledError;
      }

      if (!combinedOptions.skipLogout) {
        if (err.response && err.response.status === UNAUTHORIZED_STATUS_CODE) {
          logoutAction(true);
        }
      }

      let safeError = {
        type: 'UnknownServerError',
        message: 'An unknown internal server error occurred.',
      };
      if (err.response && err.response.data) {
        if (typeof err.response.data === 'string') {
          safeError = {
            type: 'ServerError',
            message: err.response.data
          };
        } else {
          safeError = err.response.data;
        }
      }

      throw safeError;
    }).then((response) => {
      const safeResponse = response || {};

      let finalData = safeResponse.data || null;
      if (!combinedOptions.skipCleaning) {
        finalData = cleanResponse(finalData);
      }

      if (options.paginated) {
        const paginationData = {
          pages: Number(lodash.get(safeResponse, 'headers.x-page-count', 0)),
          total: Number(lodash.get(safeResponse, 'headers.x-result-count', 0)),
        };

        finalData = {
          pagination: paginationData,
          response: finalData,
        };
      }

      if (safeResponse.headers && safeResponse.headers[API_TOKEN_KEY] !== undefined) {
        localStorage.setItem(AUTH_STORAGE_KEY, safeResponse.headers[API_TOKEN_KEY]);

        return new Promise((resolve) => {
          // In order to prevent bad calls, give the auth token time to store in local storage.
          setTimeout(() => {
            resolve(finalData);
          });
        });
      }

      return finalData;
    })
  );
}

/**
 * Converts all object keys into lower camel case.
 *
 * @param {*} response
 * @returns {*}
 */
export function cleanResponse(response) {
  if (!response || (!lodash.isPlainObject(response) && !Array.isArray(response))) {
    return response;
  }

  if (Array.isArray(response)) {
    return response.map( cleanResponse );
  }

  return lodash.reduce(response, (reduced, itemValue, itemName) => {
    const newName = lodash.camelCase(itemName);
    reduced[newName] = cleanResponse(itemValue);
    return reduced;
  }, {});
}

/**
 * Makes a fetch call to the server based on the given options.
 *
 * @param {{
 *   auth: boolean,
 *   baseURL: string,
 *   url: string,
 *   data: object,
 *   params: object,
 *   pascal: boolean,
 * }} apiOptions See Fetch request options.
 * @param {{paginated: boolean, skipCleaning: boolean, skipLogout: boolean}=} parseOptions
 * @returns {Promise}
 */
function serverFetch(apiOptions, parseOptions) {
  const safeParseOptions = parseOptions || {};
  const {url, options} = parseFetchOptions(apiOptions);

  return fromPromise(
    fetch(url, options).then((response) => {
      if (response.ok) {
        return parseFetchSuccess(response, options, safeParseOptions);
      }

      return parseFetchError(response, options, safeParseOptions).then((responseError) => {
        throw responseError;
      });
    })
  );
}

/**
 * Parses the options into fetch options.
 *
 * @param {{
 *   auth: boolean,
 *   baseURL: string,
 *   url: string,
 *   data: object,
 *   params: object,
 *   pascal: boolean,
 * }} apiOptions
 * @returns {{url: string, options: {}}}
 */
function parseFetchOptions(apiOptions) {
  const {auth, baseURL, url, data, params, pascal, ...fetchOptions} = (apiOptions || {});

  const slash = (url[0] !== '/') ? '/' : '';

  const safeUrl = new URL(`${baseURL}${slash}${url}`);
  const headers = new Headers(fetchOptions.headers || {});

  if (fetchOptions.cancelable) {
    throw new Error('ServerFetch does not support cancelable requests, use ServerRequest instead.');
  }

  parseFetchParams(fetchOptions, params, safeUrl);

  parseFetchBody(fetchOptions, data, headers, {pascal});

  parseAuth({auth}, headers);

  /* Cookies */
  if (!fetchOptions.credentials) {
    fetchOptions.credentials = 'omit';
  }

  fetchOptions.headers = headers;

  // Make sure the method is all uppercased.
  fetchOptions.method = (fetchOptions.method) ? String(fetchOptions.method).toUpperCase() : 'GET';

  return {
    url: safeUrl.toString(),
    options: fetchOptions,
  };
}

/**
 * Parses the url query params into the given url object.
 *
 * @param {{}} fetchOptions
 * @param {{}} params
 * @param {URL} safeUrl
 */
function parseFetchParams(fetchOptions, params, safeUrl) {
  if (!params) {
    return;
  }

  lodash.forEach(params, (value, key) => {
    if (Array.isArray(value)) {
      value.forEach((subValue) => {
        safeUrl.searchParams.append(`${key}[]`, subValue);
      });
    } else {
      safeUrl.searchParams.set(key, value);
    }
  });
}

/**
 * Parses the body content for the fetch request.
 *
 * @param {{}} fetchOptions
 * @param {{}} data
 * @param {Header} headers
 * @param {{pascal: boolean}} options
 */
function parseFetchBody(fetchOptions, data, headers, options) {
  if (!data) {
    return;
  }

  const safeOptions = options || {};

  if (data instanceof FormData) {
    // By not setting the Content-Type header, the browser should detect FormData and add the boundary for us.
    fetchOptions.body = data;
    return;
  }

  let safeData = data;
  if (safeOptions.pascal) {
    // PascalCase the body keys.
    safeData = pascalCase(safeData);
  }

  headers.set('Content-Type', 'application/json; charset=utf-8');
  fetchOptions.body = JSON.stringify(safeData);

  if (safeData && !fetchOptions.body) {
    throw new Error(
      'ServerFetch only supports FormData and JSON body data, use ServerRequest for other types of data.'
    );
  }
}

/**
 * Parses the Auth token into the headers.
 *
 * @param {{auth: boolean}} options
 * @param {Header} headers
 */
function parseAuth(options, headers) {
  if (!options || options.auth === false) {
    return;
  }

  const authToken = localStorage.getItem(AUTH_STORAGE_KEY);
  if (authToken) {
    headers.set('Authorization', `Bearer ${authToken}`);
  }
}

/**
 * Parses the response object into its body data.
 *
 * @param {Response} response
 * @returns {Promise}
 */
function parseResponseToData(response) {
  const type = String(response.headers.get('Content-Type'));

  if (!type || type === 'null' || type === 'undefined') {
    return null;
  } else if (type.indexOf('text/html') !== -1) {
    return response.text();
  } else if (type.indexOf('application/json') !== -1) {
    return response.json();
  } else if (type.indexOf('multipart/form-data') !== -1) {
    return response.formData();
  }

  let final = null;
  try {
    final = response.json();
  } catch (parseError) {
    final = response.text();
  }

  return final;
}

/**
 * Parses the response to a fetch success.
 *
 * @param {Response} response
 * @param {{}} fetchOptions
 * @param {{paginated: boolean, skipCleaning: boolean}} parseOptions
 * @returns {Promise}
 */
async function parseFetchSuccess(response, fetchOptions, parseOptions) {
  const responseHeaders = response.headers;

  if (responseHeaders.has(API_TOKEN_KEY)) {
    localStorage.setItem(AUTH_STORAGE_KEY, responseHeaders.get(API_TOKEN_KEY));
  }

  let finalData = await parseResponseToData(response);
  if (!parseOptions.skipCleaning) {
    finalData = cleanResponse(finalData);
  }

  if (parseOptions.paginated) {
    const paginationData = {
      pages: Number(responseHeaders.get('x-page-count')) || 0,
      total: Number(responseHeaders.get('x-result-count')) || 0,
    };

    finalData = {
      pagination: paginationData,
      response: finalData,
    };
  }

  return new Promise((resolve) => {
    // In order to prevent bad calls, give the auth token time to store in local storage.
    setTimeout(() => {
      resolve(finalData);
    });
  });
}

/**
 * Parses a bad fetch response into an api error.
 *
 * @param {Response} response
 * @param {{}} fetchOptions
 * @param {{skipLogout: boolean}} parseOptions
 * @returns {{type: string, message: string}}
 */
async function parseFetchError(response, fetchOptions, parseOptions) {
  const apiError = await parseResponseToData(response);

  if (!parseOptions.skipLogout) {
    if (response && response.status === UNAUTHORIZED_STATUS_CODE) {
      logoutAction(true);
    }
  }

  let safeError = {
    type: 'UnknownServerError',
    message: 'An unknown internal server error occurred.',
  };
  if (apiError) {
    if (typeof apiError === 'string') {
      safeError = {
        type: 'ServerError',
        message: apiError
      };
    } else {
      safeError = apiError;
    }
  }

  return safeError;
}

/**
 * Builds the url for the given request.
 *
 * @param {{}} options See Axios Options.
 * @returns {string}
 */
function buildUrl(options) {
  const combinedOptions = assignDeep({}, defaultOptions, options);
  let baseUrl = String(combinedOptions.baseURL);
  if (baseUrl.substr(-1) === '/') {
    baseUrl = baseUrl.substring(0, baseUrl.length - 1);
  }

  let url = combinedOptions.url;
  if (url[0] === '/') {
    url = url.substr(1);
  }

  return baseUrl + '/' + url;
}

export default {
  all: axios.all,
  buildUrl: buildUrl,
  fetch: serverFetch,
  request: serverRequest,
};
