import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import {Progress, UncontrolledTooltip} from 'reactstrap';

import inject from '../../hoc/injectHoc';
import {between} from '../../../utils/mathHelper';

import './fileUploadingProgress.scss';

/**
 * The maximum progress value.
 * @const {number}
 */
const MAX_PROGRESS = 100;

/**
 * The FileUploadingProgress component.
 */
export class FileUploadingProgress extends React.Component {
  /**
   * Cancels the upload in progress for the given fileKey.
   *
   * @param {string} fileKey
   * @returns {function}
   */
  cancelUpload = (fileKey) => {
    return () => {
      const {apiCreateContentStore} = this.props;

      apiCreateContentStore.cancelUpload(fileKey);
    };
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiCreateContentStore} = this.props;

    if (!apiCreateContentStore.uploadingFiles.size) {
      return (
        <div className="file-uploading-progress">
          <div className="uploading-file upload-empty">
            <h4 className="h5">All uploads completed.</h4>
          </div>
        </div>
      );
    }

    let renderFiles = [];
    apiCreateContentStore.uploadingFiles.forEach((uploadData, fileKey) => {
      const uniqueKey = fileKey.replace(/[^a-zA-Z0-9]/ig, '');
      const progressValue = between(uploadData.progress, 0, MAX_PROGRESS);

      apiCreateContentStore.fileCase(fileKey, {
        pending: () => {
          renderFiles.push(
            <div className="uploading-file upload-pending" key={uniqueKey}>
              <div className="file-name">{uploadData.file.name}</div>
              <div className="file-progress">
                <div className="file-progress-cancel">
                  <button
                    type="button"
                    className="btn btn-sm btn-link fa fa-ban icon-bold"
                    id={`cancel-${uniqueKey}`}
                    onClick={this.cancelUpload(fileKey)}
                  />

                  <UncontrolledTooltip target={`cancel-${uniqueKey}`}>
                    Cancel Uploading of &quot;{uploadData.file.name}&quot;
                  </UncontrolledTooltip>
                </div>

                <Progress className="file-progress-bar" value={progressValue} striped={true} />
                <span className="file-progress-text">{progressValue}%</span>
              </div>
            </div>
          );
        },
        fulfilled: () => {
          renderFiles.push(
            <div className="uploading-file upload-fulfilled" key={uniqueKey}>
              <div className="file-name">{uploadData.file.name}</div>
              <div className="file-progress">
                <Progress className="file-progress-bar" value={100} />
                <span className="file-progress-text">100%</span>
              </div>
              <div>Upload Complete</div>
            </div>
          );
        },
        rejected: () => {
          const wasCanceled = Boolean(uploadData.error.canceled);

          renderFiles.push(
            <div className="uploading-file upload-rejected" key={uniqueKey}>
              <div className="file-name">{uploadData.file.name}</div>
              {(wasCanceled) ? (
                <div className="file-progress file-progress-canceled">
                  <Progress className="file-progress-bar" value={100} color="warning" />
                  <span className="file-progress-text">Canceled</span>
                </div>
              ) : (
                <div className="file-progress">
                  <Progress className="file-progress-bar" value={100} color="danger" />
                  <span className="file-progress-text">Error</span>
                </div>
              )}
            </div>
          );
        },
      });
    });

    return (
      <div className="file-uploading-progress">
        {renderFiles}
      </div>
    );
  }
}

FileUploadingProgress.propTypes = {
  apiCreateContentStore: MobxPropTypes.observableObject,
};

FileUploadingProgress.wrappedComponent = {};
FileUploadingProgress.wrappedComponent.propTypes = {
  apiCreateContentStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(FileUploadingProgress)(
  observer(FileUploadingProgress)
);
