import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Tooltip} from 'reactstrap';

import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';
import inject from '../../../../hoc/injectHoc';
import InputStringModal from '../../../../modals/inputString/InputStringModal';

import './categoryAddButton.scss';

/**
 * The category add button.
 *
 * @returns {{}}
 */
export class CategoryAddButton extends React.Component {
  /**
   * Whether or not the modal is open.
   *
   * @type {boolean}
   */
  @observable isNameModalOpen = false;

  /**
   * Whether or not the tooltip is open.
   *
   * @type {boolean}
   */
  @observable isTooltipOpen = false;

  /**
   * Triggered when the add button is clicked.
   */
  @action onClickAdd = () => {
    this.isNameModalOpen = true;
  };

  /**
   * When the name of the new category has been entered.
   *
   * @param {string} newCategoryName
   */
  @action onNameCreated = (newCategoryName) => {
    if (!newCategoryName) {
      this.isNameModalOpen = false;
      return;
    }

    const {apiCreateCategoryStore, libraryId} = this.props;

    apiCreateCategoryStore.createCategory(
      newCategoryName,
      libraryId
    ).then(action(() => {
      this.isNameModalOpen = false;
    }));
  };

  /**
   * Toggles the tooltip.
   */
  @action toggleTooltip = () => {
    this.isTooltipOpen = !this.isTooltipOpen;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiCreateCategoryStore, libraryId, libraryName} = this.props;

    const loading = () => (<LoadingIcon size="em" />);
    const notLoading = () => (<i className="fa fa-plus" />);

    return (
      <div className="category-add-button">
        <button
          type="button"
          className="btn btn-sm btn-default"
          id="category-add-btn"
          onClick={this.onClickAdd}
          disabled={!libraryId}
        >
          {apiCreateCategoryStore.case({
            pre: notLoading,
            pending: loading,
            fulfilled: notLoading,
            rejected: notLoading,
          })}
        </button>

        <Tooltip
          placement="right"
          isOpen={this.isTooltipOpen}
          target="category-add-btn"
          toggle={this.toggleTooltip}
        >
          {(libraryName) ? `Add Category to '${libraryName}'` : 'Add Category'}
        </Tooltip>

        <InputStringModal
          isOpen={this.isNameModalOpen}
          onComplete={this.onNameCreated}
          placeholder="New Category Name"
          title="New Category Name"
          helpText={(libraryName) ? `This category will be added to the '${libraryName}' library.` : null}
        />
      </div>
    );
  }
}

CategoryAddButton.propTypes = {
  apiCreateCategoryStore: MobxPropTypes.observableObject,
  libraryId: PropTypes.number,
  libraryName: PropTypes.string,
};

CategoryAddButton.defaultProps = {
  libraryId: null,
  libraryName: null,
};

CategoryAddButton.wrappedComponent = {};
CategoryAddButton.wrappedComponent.propTypes = {
  apiCreateCategoryStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(CategoryAddButton)(
  observer(CategoryAddButton)
);
