import classNames from 'classnames';
import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import ClipActions from './components/clipActions/ClipActions';
import DisplayContentSrc from '../../common/displayContentSrc/DisplayContentSrc';
import inject from '../../hoc/injectHoc';
import PreviewContentModal from '../../modals/previewContent/PreviewContentModal';
import {VIDEO_CONTENT_TYPES} from '../../../constants/libraryTypeConstants';
import {isProcessing as getIsProcessing} from '../../../utils/contentsHelper';

import './clipItem.scss';

/**
 * The ClipItem component.
 */
export class ClipItem extends React.Component {
  /**
   * Whether or not the preview is open.
   *
   * @type {boolean}
   */
  @observable isPreviewOpen = false;

  /**
   * Triggered when the component is mounted to the page.
   */
  componentDidMount() {
    this.checkForProcessing();
  }

  /**
   * Triggered when the component updates.
   */
  componentDidUpdate() {
    this.checkForProcessing();
  }

  /**
   * Triggered when the component is about to be removed from the page.
   */
  componentWillUnmount() {
    const {apiPollContentStore, content} = this.props;

    apiPollContentStore.cancelPollForContent(content.id);
  }

  /**
   * Checks to see if the content is processing, and starts polling it if true.
   */
  checkForProcessing() {
    const {apiPollContentStore, content, pollKeys} = this.props;

    const isProcessing = getIsProcessing(content, {allowNew: true});
    if (!isProcessing) {
      return;
    }

    const firstContentFile = lodash.get(content, 'contentFiles[0]', null);
    if (!firstContentFile) {
      return;
    }

    const {updateDate} = firstContentFile;
    const updated = moment(updateDate);
    const limit = moment().subtract(2, 'hours');

    if (!updated.isAfter(limit)) {
      return;
    }

    apiPollContentStore.pollThenUpdateContent(content.id, pollKeys);
  }

  /**
   * Handler for previewing a content item.
   *
   * @param {{}} clickEvent
   */
  @action onPreview = (clickEvent) => {
    clickEvent.stopPropagation();

    this.isPreviewOpen = true;
  };

  /**
   * Closes the preview modal.
   */
  @action onClosePreview = () => {
    this.isPreviewOpen = false;
  };

  /**
   * Handler for selecting a content item.
   */
  onSelect = () => {
    const {onClick, content} = this.props;

    // Don't do anything if the content is still processing
    if (getIsProcessing(content)) {
      return;
    }

    onClick(content);
  };

  /**
   * Stops the propagation of click events to elements above this one.
   *
   * @param {{stopPropagation: function}} clickEvent
   */
  stopClickPropagation = (clickEvent) => {
    clickEvent.stopPropagation();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {content, contentSize, libraryType, isWriteable} = this.props;

    const isContentProcessing = getIsProcessing(content);

    const classes = {
      'is-processing': isContentProcessing
    };

    const isContentAnImage = !lodash.includes(VIDEO_CONTENT_TYPES, Number(content.contentTypeId));

    let didProcessingTimeout = false;
    if (isContentProcessing) {
      const firstFile = content.contentFiles[0];
      const lastUpdated = moment(firstFile.updateDate);
      const oneDayAgo = moment().subtract(1, 'day');

      if (lastUpdated.isBefore(oneDayAgo)) {
        didProcessingTimeout = true;
      }
    }

    const showActions = Boolean(isWriteable) || didProcessingTimeout;

    return (
      <figure className={classNames('clip-item', classes)}>
        <DisplayContentSrc
          content={content}
          contentSize={contentSize}
          isImage={isContentAnImage}
          libraryType={libraryType}
          stretch={true}
          thumbnail={true}
        />
        <div className="clip-item-overlay" onClick={this.onSelect}>
          <div className="clip-item-overlay-text" onClick={this.onPreview}>Preview</div>
          <div className="clip-item-overlay-text">Select</div>

          {(showActions) && (
            <div className="content-menu" onClick={this.stopClickPropagation}>
              <ClipActions
                content={content}
                libraryType={libraryType}
                hideMove={didProcessingTimeout}
              />
            </div>
          )}
        </div>

        {(this.isPreviewOpen) && (
          <PreviewContentModal
            contentSize={contentSize}
            isOpen={true}
            onComplete={this.onClosePreview}
            content={content}
            isImage={isContentAnImage}
            libraryType={libraryType}
            title="Preview"
          />
        )}

      </figure>
    );
  }
}

ClipItem.propTypes = {
  content: PropTypes.shape({
    contentFiles: PropTypes.oneOfType([MobxPropTypes.observableArray, PropTypes.array]),
    contentTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    id: PropTypes.number
  }).isRequired,
  contentSize: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  libraryType: PropTypes.number.isRequired,
  pollKeys: PropTypes.object.isRequired,

  apiPollContentStore: MobxPropTypes.observableObject,
  isWriteable: PropTypes.bool,
  onClick: PropTypes.func,
};

ClipItem.defaultProps = {
  isWriteable: false,
  onClick: () => null,
};

ClipItem.wrappedComponent = {
  propTypes: {
    apiPollContentStore: MobxPropTypes.observableObject.isRequired,
  }
};

export default inject(ClipItem)(
  observer(ClipItem)
);
