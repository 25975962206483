import {action} from 'mobx';

import SingleResourceBaseStore from './common/apiSingleResourceBaseStore';
import config from '../../config/main';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN} from '../../constants/storeConstants';
import serverRequest, {cleanResponse} from '../../utils/serverRequest';
import {replaceText} from '../../utils/textReplacer';

/**
 * Maps the api types to their url.
 *
 * @const {Object.<string, string>}
 */
const API_TYPE_TO_URL_MAP = {
  'contentapi': config.api.content,
  'deliveryapi': config.api.delivery,
  'adminapi': config.api.admin,
};

/**
 * The feedFromApi store.
 */
class FeedFromApiStore extends SingleResourceBaseStore {
  /**
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Fetches feed data from the API.
   *
   * @param {string} feedApiUrl
   */
  @action fetch(feedApiUrl) {
    const safeUrl = String(feedApiUrl);
    const safeId = safeUrl;
    const replacedUrl = replaceText(safeUrl);

    const urlParts = replacedUrl.split('/');
    const apiType = this.mapApiTypeToBaseURL(urlParts.shift());
    const serverUrl = urlParts.join('/');

    if (!apiType || this.isResourceAvailable(safeId)) {
      return;
    }

    this.resourcesById.set(safeId, {
      state: STATE_PENDING,
      resource: null,
      error: null,
    });
    this.state = STATE_PENDING;

    serverRequest.request({
      baseURL: apiType,
      url: serverUrl,
      method: 'get',
      skipCleaning: true,
      skipLogout: true,
      maxContentLength: 2000,
      responseType: 'json', // 'arraybuffer', 'json', 'text'
    }).then(
      action('getApiFeedsSuccess', (feedData) => {
        this.resourcesById.set(safeId, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          resource: this.cleanFeedData(feedData),
          error: null,
        });
        this.state = STATE_FULFILLED;
      }),
      action('getApiFeedsError', (error) => {
        this.resourcesById.set(safeId, {
          state: STATE_REJECTED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          resource: null,
          error,
        });
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Maps the apiType to its base url.
   *
   * @param {string} apiType
   * @returns {?string}
   */
  mapApiTypeToBaseURL(apiType) {
    return API_TYPE_TO_URL_MAP[apiType] || null;
  }

  /**
   * Clean up the feed data.
   *
   * @param {Object|Array|string} feedData
   * @returns {Object|Array|string}
   */
  cleanFeedData(feedData) {
    if (!feedData) {
      return {};
    } else if (typeof feedData !== 'string') {
      return feedData;
    }

    const safeFeedData = feedData.trim();

    try {
      return cleanResponse(
        JSON.parse(safeFeedData)
      );
    } catch (parseError) {
      if (safeFeedData[0] === '{' || safeFeedData[0] === '[') {
        // The string should have parsed, but failed, so it is bad JSON.
        return {};
      }

      // The string could not be parsed as JSON, but maybe it was just a string in the first place.
    }

    return safeFeedData;
  }
}

export default new FeedFromApiStore();
