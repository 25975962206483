/**
 * The user role that identifies the user as a salesperson.
 * @const {string}
 */
export const USER_ROLE_SALES = 'Salesperson';

/**
 * The client id for the `user demo account` client.
 * @const {number}
 */
export const CLIENT_USER_DEMO_ACCOUNT_ID = 4582;
