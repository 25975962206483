import * as mathJS from 'mathjs';

/**
 * Converts radians to degrees if multiplied, degrees to rad if divided.
 * @const {number}
 */
export const RAD_TO_DEGREES = (180 / mathJS.pi); // eslint-disable-line no-magic-numbers

/**
 * Returns the checkValue if it is between the minValue or maxValue, otherwise it returns the
 * nearest border value.
 *
 * @param {number} checkValue
 * @param {number} minValue
 * @param {number} maxValue
 * @returns {number}
 */
export function between(checkValue, minValue, maxValue) {
  return Math.min(Math.max(checkValue, minValue), maxValue);
}

/**
 * Sets the value to zero if it is below the minimum value.
 * This is to prevent values such as 1.00e-10.
 *
 * @param {number} value
 * @param {number} minValue
 * @returns {number}
 */
export function limitMinValue(value, minValue) {
  const defaultMinValue = 0.001;
  const safeMinValue = minValue || defaultMinValue;
  if (Math.abs(value) < safeMinValue) {
    return 0;
  }
  return value;
}

/**
 * Maps values from a fixed reference frame onto a rotated reference frame.
 *
 * @param {number} rotationDegrees
 * @param {{
 *   top: number,
 *   left: number,
 *   width: number,
 *   height: number,
 *   dx: number,
 *   dy: number,
 *   x: number,
 *   y: number
 * }} values
 * @param {{x: number, y: number}=} originCoords
 * @returns {{}}
 */
export function mapToRotatedFrame(rotationDegrees, values, originCoords) {
  if (!rotationDegrees) {
    return values;
  }

  const rotateRads = (rotationDegrees / RAD_TO_DEGREES);

  // If the item has been rotated, map the deltas onto the rotated reference frame.
  // @see {@url https://en.wikipedia.org/wiki/Rotation_of_axes#Derivation} for mapping formulas.
  const rotatedValues = {};

  if (values.top !== undefined || values.left !== undefined) {
    const newValues = mapRotatedValues(values.left, values.top, rotateRads, originCoords);

    rotatedValues.left = newValues.x;
    rotatedValues.top = newValues.y;
  }

  if (values.width !== undefined || values.height !== undefined) {
    const newValues = mapRotatedValues(values.width, values.height, rotateRads);

    rotatedValues.width = newValues.x;
    rotatedValues.height = newValues.y;
  }

  if (values.dx !== undefined || values.dy !== undefined) {
    const newValues = mapRotatedValues(values.dx, values.dy, rotateRads);

    rotatedValues.dx = newValues.x;
    rotatedValues.dy = newValues.y;
  }

  if (values.x !== undefined || values.y !== undefined) {
    const newValues = mapRotatedValues(values.x, values.y, rotateRads, originCoords);

    rotatedValues.x = newValues.x;
    rotatedValues.y = newValues.y;
  }

  return rotatedValues;
}

/**
 * Maps a defining corner point of a shape onto a rotated frame.
 *
 * @param {number} rotationDegrees
 * @param {{
 *   top: number,
 *   left: number,
 *   width: number,
 *   height: number,
 * }} shape
 * @param {{x: number, y: number}=} originCoords
 * @returns {*}
 */
export function mapShapeToRotatedFrame(rotationDegrees, shape, originCoords) {
  if (!rotationDegrees) {
    return shape;
  }

  const rotateRads = (rotationDegrees / RAD_TO_DEGREES);

  // If the item has been rotated, map the point onto the rotated reference frame.
  // @see {@url https://en.wikipedia.org/wiki/Rotation_of_axes#Derivation} for mapping formulas.
  const rotatedValues = {};

  if (shape.top === undefined || shape.left === undefined) {
    throw new Error('A top and left value must be provided to mapShapeToRotatedFrame.');
  } else if (shape.width === undefined || shape.height === undefined) {
    throw new Error('A width and height value must be provided to mapShapeToRotatedFrame.');
  }

  const newPosValues = mapRotatedValues(shape.left, shape.top, 0 - rotateRads, originCoords);
  const newSizeValues = mapRotatedValues(shape.width, shape.height, 0 - rotateRads);

  rotatedValues.left = newPosValues.x;
  rotatedValues.top = newPosValues.y;
  rotatedValues.width = newSizeValues.x;
  rotatedValues.height = newSizeValues.y;

  if (rotatedValues.width < 0) {
    rotatedValues.left += rotatedValues.width;
    rotatedValues.width *= -1;
  }
  if (rotatedValues.height < 0) {
    rotatedValues.top += rotatedValues.height;
    rotatedValues.height *= -1;
  }

  return rotatedValues;
}

/**
 * Maps x and y values onto a rotated reference frame.
 *
 * @see {@url https://en.wikipedia.org/wiki/Rotation_of_axes#Derivation} for mapping formulas.
 *
 * @param {number} x
 * @param {number} y
 * @param {number} rotateRads
 * @param {{x: number, y: number}=} originCoords
 * @returns {{x: number, y: number}}
 */
export function mapRotatedValues(x, y, rotateRads, originCoords) {
  let xValue = x || 0;
  let yValue = y || 0;

  const sinTheta = Math.sin(rotateRads);
  const cosTheta = Math.cos(rotateRads);

  if (originCoords) {
    xValue -= originCoords.x;
    yValue -= originCoords.y;
  }

  const rotated = {
    x: limitMinValue((xValue * cosTheta) + (yValue * sinTheta)),
    y: limitMinValue(((0 - xValue) * sinTheta) + (yValue * cosTheta)),
  };

  if (originCoords) {
    rotated.x += originCoords.x;
    rotated.y += originCoords.y;
  }

  return rotated;
}

/**
 * Parses a decimal into a fraction.
 *
 * @param {number} decimal
 * @returns {{topValue: number, bottomValue: number, display: string, ratio: string}}
 */
export function decimalToFraction(decimal) {
  const fraction = mathJS.format(mathJS.fraction(decimal));
  const fractionParts = fraction.split('/');

  return {
    topValue: fractionParts[0],
    bottomValue: fractionParts[1],
    display: fraction,
    ratio: `${fractionParts[0]}:${fractionParts[1]}`,
  };
}
