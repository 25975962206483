import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

import inject from '../../../../hoc/injectHoc';
import ConfirmModal from '../../../../modals/confirm/ConfirmModal';
import InputStringModal from '../../../../modals/inputString/InputStringModal';

import './categoryActions.scss';

/**
 * The category which shall not be named.
 * @const {string}
 */
const FORBIDDEN_NAME = 'My Uploads';

/**
 * Gets whether or not the category can be moved.
 *
 * @param {{children: Array.<{}>}} category
 * @returns {boolean}
 */
function canCategoryBeMoved(category) {
  // Categories with children cannot be moved as this might cause grandchildren.
  return !(category.children && category.children.length);
}

/**
 * Gets whether or not the category can have a sub-category added to it.
 *
 * @param {{parentCategoryId: number}} category
 * @returns {boolean}
 */
function canHaveSubCategory(category) {
  // Categories with a parent cannot have a sub-category as this would cause grandchildren.
  return !(category.parentCategoryId);
}

/**
 * Gets whether or not the category can be deleted.
 *
 * @param {{}} category
 * @returns {boolean}
 */
function canDeleteCategory(category) {
  const hasChildren = Boolean(category.children && category.children.length);

  // Categories with children cannot be deleted.
  return (!hasChildren);
}

/**
 * The categoryActions component.
 */
export class CategoryActions extends React.Component {
  /**
   * Whether or not the current dropdown is open.
   *
   * @type {boolean}
   */
  @observable isOpen = false;

  /**
   * Whether or not the delete confirmation modal is open.
   *
   * @type {boolean}
   */
  @observable isDeleteModalOpen = false;

  /**
   * Whether or not the naming modal for a new category is open.
   *
   * @type {boolean}
   */
  @observable isNewNameModalOpen = false;

  /**
   * Whether or not the renaming modal is open.
   *
   * @type {boolean}
   */
  @observable isRenameModalOpen = false;

  /**
   * The delete error.
   *
   * @type {?Error}
   */
  @observable deleteModalError = null;

  /**
   * Triggered when the dropdown is closed.
   */
  @action onDropDownToggle = () => {
    const {isOpen} = this;
    const {onOpen, onClose} = this.props;

    if (isOpen) {
      this.isOpen = false;
      if (onClose) {
        onClose();
      }
    } else {
      this.isOpen = true;
      if (onOpen) {
        onOpen();
      }
    }
  };

  /**
   * Triggered when the rename button is clicked.
   */
  @action onRename = () => {
    this.isRenameModalOpen = true;
  };

  /**
   * Triggered when the move button is clicked.
   */
  onMove = () => {
    const {category, categoryEditStore} = this.props;
    if (!canCategoryBeMoved(category)) {
      return;
    }

    categoryEditStore.setMoveCategory(category);
  };

  /**
   * Triggered when the delete button is clicked.
   */
  @action onDelete = () => {
    this.isDeleteModalOpen = true;
  };

  /**
   * Triggered when the add sub category button is clicked.
   */
  @action onAddSubCategory = () => {
    this.isNewNameModalOpen = true;
  };

  /**
   * Triggered when the delete is confirmed.
   *
   * @param {boolean} wasConfirmed
   */
  @action onDeleteCompleted = (wasConfirmed) => {
    this.isDeleteModalOpen = false;

    if (!wasConfirmed) {
      return;
    }

    const {apiDeleteCategoryStore, category, libraryId} = this.props;

    if (String(category.categoryName).trim() === FORBIDDEN_NAME) {
      // This category is important and can not be casually deleted.
      this.deleteModalError = new Error('This category is protected and can not be deleted.');
      return;
    }

    apiDeleteCategoryStore.deleteCategory(category.id, libraryId);
    apiDeleteCategoryStore.getPromise().then(
      action('onDeleteSuccess', () => {
        this.deleteModalError = null;
      })
    ).catch(
      action('onDeleteError', () => {
        this.deleteModalError = new Error('An error occurred while trying to delete this category.');
      })
    );
  };

  /**
   * Closes the delete error modal.
   */
  @action onDeleteErrorCompleted = () => {
    this.deleteModalError = null;
  };

  /**
   * Triggered when the name of the new category has been entered.
   *
   * @param {string} newCategoryName
   */
  @action onNewNameCreated = (newCategoryName) => {
    if (!newCategoryName) {
      this.isNewNameModalOpen = false;
      return;
    } else if (newCategoryName === FORBIDDEN_NAME) {
      this.isNewNameModalOpen = false;
      return;
    }

    const {apiCreateCategoryStore, category, libraryId} = this.props;

    apiCreateCategoryStore.createCategory(
      String(newCategoryName),
      libraryId,
      {parentCategoryId: category.id},
    ).then(() => {
      this.isNewNameModalOpen = false;
    });
  };

  /**
   * Triggered when the new name for the category rename has been chosen.
   *
   * @param {string} newCategoryName
   */
  @action onRenameCompleted = (newCategoryName) => {
    if (!newCategoryName) {
      this.isRenameModalOpen = false;
      return;
    } else if (newCategoryName === FORBIDDEN_NAME) {
      this.isRenameModalOpen = false;
      return;
    }

    const {apiUpdateCategoryStore, category, libraryId} = this.props;

    apiUpdateCategoryStore.updateCategory(
      category.id,
      {categoryName: String(newCategoryName)},
      libraryId
    );
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {category} = this.props;
    const categoryName = category.categoryName;

    const canBeDeleted = canDeleteCategory(category);
    const canBeMoved = canCategoryBeMoved(category);
    const canHaveSubcategory = canHaveSubCategory(category);

    return (
      <div className="category-actions">
        <Dropdown isOpen={this.isOpen} toggle={this.onDropDownToggle}>
          <DropdownToggle className="btn btn-link btn-icon category-actions-open" tag="button">
            <i className="fa fa-ellipsis-v" />
          </DropdownToggle>
          <span className="dropdown-filler" />
          <DropdownMenu right>
            <DropdownItem onClick={this.onRename}>
              <i className="fa fa-font" />
              Rename
            </DropdownItem>

            {(canBeMoved) && (
              <DropdownItem onClick={this.onMove}>
                <i className="fa fa-sign-out" />
                Move
              </DropdownItem>
            )}

            {(canHaveSubcategory) && (
              <DropdownItem onClick={this.onAddSubCategory}>
                <i className="fad fad-folder" />
                Add Category
              </DropdownItem>
            )}

            {(canBeDeleted) && (
              <DropdownItem onClick={this.onDelete}>
                <i className="fa fa-trash" />
                Delete
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>

        {(this.isNewNameModalOpen) && (
          <InputStringModal
            isOpen={true}
            onComplete={this.onNewNameCreated}
            placeholder="Category Name"
            title="New Sub Category Name"
            helpText={(categoryName) ? `This category will be added to the '${categoryName}' category.` : null}
          />
        )}

        {(this.isRenameModalOpen) && (
          <InputStringModal
            isOpen={true}
            onComplete={this.onRenameCompleted}
            placeholder="Category Name"
            title="Rename Category"
            helpText={(categoryName) ? `Choose a new name for the '${categoryName}' category.` : null}
          />
        )}

        {(this.isDeleteModalOpen) && (
          <ConfirmModal
            isOpen={true}
            onComplete={this.onDeleteCompleted}
            title="Delete Category?"
            confirmText={`Are you sure you want to delete the '${categoryName}' category?`}
            isYesNo={true}
          />
        )}

        {(this.deleteModalError) && (
          <ConfirmModal
            isOpen={true}
            onComplete={this.onDeleteErrorCompleted}
            title="Error Deleting Category"
            confirmText={this.deleteModalError.message}
            isYesNo={false}
          />
        )}
      </div>
    );
  }
}

CategoryActions.propTypes = {
  category: PropTypes.shape({
    categoryName: PropTypes.string,
    children: PropTypes.array,
    id: PropTypes.number,
  }).isRequired,
  libraryId: PropTypes.number.isRequired,
  libraryType: PropTypes.number.isRequired,

  apiContentsStore: MobxPropTypes.observableObject,
  apiCreateCategoryStore: MobxPropTypes.observableObject,
  apiDeleteCategoryStore: MobxPropTypes.observableObject,
  apiUpdateCategoryStore: MobxPropTypes.observableObject,
  categoryEditStore: MobxPropTypes.observableObject,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
};

CategoryActions.wrappedComponent = {};
CategoryActions.wrappedComponent.propTypes = {
  apiContentsStore: MobxPropTypes.observableObject.isRequired,
  apiCreateCategoryStore: MobxPropTypes.observableObject.isRequired,
  apiDeleteCategoryStore: MobxPropTypes.observableObject.isRequired,
  apiUpdateCategoryStore: MobxPropTypes.observableObject.isRequired,
  categoryEditStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(CategoryActions)(
  observer(CategoryActions),
);
