import {RichUtils} from 'draft-js';
import PropTypes from 'prop-types';
import React from 'react';

import {COLOR_PREFIX} from '../../constants/styleContants';
import {addColorToStyleMap, getAllActiveStylesWithPrefix, removeFromStyleMap} from '../../utils/styleHelper';
import ColorSelector from '../../../../common/colorSelector/ColorSelector';

import './colorSelect.scss';

/**
 * The default color to fall back to.
 * @const {string}
 */
const DEFAULT_COLOR = '#000000';

/**
 * Converts a color code into the draftJS style name.
 *
 * @param {string} colorCode
 * @returns {string}
 */
function convertColorToStyleName(colorCode) {
  let safeColorCode = String(colorCode).replace('#', '');

  return `${COLOR_PREFIX}${safeColorCode}`;
}

/**
 * Gets the active color from a list of styles.
 *
 * @param {string[]} allStyles
 * @param {string} defaultValue
 * @returns {string} The hex code for the color.
 */
function getColorFromStyles(allStyles, defaultValue) {
  if (!allStyles || !allStyles.forEach) {
    return defaultValue;
  }

  let foundColor = defaultValue;
  allStyles.forEach((style) => {
    if (style.substr(0, COLOR_PREFIX.length) === COLOR_PREFIX) {
      foundColor = '#' + style.substr(COLOR_PREFIX.length);
    }
  });
  return foundColor;
}

/**
 * The FontSizeSelect component.
 *
 * @param {{}} params
 * @param {{}} params.editorState
 * @param {function(EditorState)} params.onChangeTextState
 * @param {function()=} params.beforeChange
 * @returns {React.Component}
 */
export const ColorSelect = ({editorState, onChangeTextState, beforeChange}) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  const currentValue = getColorFromStyles(currentStyle.toArray(), DEFAULT_COLOR);

  /**
   * Updates the editor state with a new style when a button is clicked.
   *
   * @param {string} newColor
   */
  const onChange = (newColor) => {
    let changeState = editorState;
    if (beforeChange) {
      changeState = beforeChange(true);
    }

    const newStyleName = convertColorToStyleName(newColor);

    const beforeChangeStyle = changeState.getCurrentInlineStyle();
    const beforeChangeValue = getColorFromStyles(beforeChangeStyle.toArray(), null);

    // First remove any colors currently set.
    let newEditorState = changeState;
    if (beforeChangeValue) {
      const previousStyleName = convertColorToStyleName(beforeChangeValue);
      newEditorState = RichUtils.toggleInlineStyle(newEditorState, previousStyleName);

      const activeColors = getAllActiveStylesWithPrefix(newEditorState, COLOR_PREFIX);
      if (!activeColors[previousStyleName]) {
        // In order to prevent a memory build up, remove this style if it is no longer in use.
        removeFromStyleMap(previousStyleName);
      }
    }

    addColorToStyleMap(newStyleName, newColor);

    // Now add the new font size.
    newEditorState = RichUtils.toggleInlineStyle(newEditorState, newStyleName);
    onChangeTextState(newEditorState);
  };

  return (
    <div className="color-select">
      <ColorSelector
        id="color-selector"
        colorValue={currentValue}
        onChangeColor={onChange}
        skipAlpha={true}
      />
    </div>
  );
};

ColorSelect.propTypes = {
  editorState: PropTypes.object.isRequired,
  onChangeTextState: PropTypes.func.isRequired,

  beforeChange: PropTypes.func,
};

export default ColorSelect;
