import {action, observable, when} from 'mobx';
import lodash from 'lodash';

import SortableFilterableStore from './common/apiSortableFilterableStore';
import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../constants/storeConstants';
import serverApi from '../../utils/serverApi';
import {getCase} from '../../utils/apiStore';

/**
 * The ContentLibraries store.
 */
class ContentLibrariesStore extends SortableFilterableStore {
  /**
   * @constructor
   */
  constructor() {
    // declare the filters that are required for this store to fetch from the server.
    // declare the default sort property.
    super(['productId', 'clientId', 'userId', 'contentLibraryTypeId'], 'libraryName');
  }

  /**
   * The currently active contentLibraryId.
   *
   * @type {number}
   */
  @observable activeContentLibraryId = 0;

  /**
   * The map of each storeKey to content libraries.
   *
   * @type {ObservableMap<string, {contentLibraries: ?ObservableArray, error: ?Error}>}
   */
  @observable fulfilledContentLibraries = observable.map();

  /**
   * Gets an item from the store's cache with the specified id.
   * @param {{key: string, filters: {}}} storeKey
   * @param {number} id
   * @returns {?{}}
   */
  getLibrary(storeKey, id) {
    if (!storeKey) {
      return null;
    }

    const safeStoreKey = String(storeKey.key);
    if (!this.fulfilledContentLibraries.has(safeStoreKey)) {
      return null;
    }
    const libraries = this.fulfilledContentLibraries.get(safeStoreKey).contentLibraries;
    return lodash.find(libraries, {id});
  }

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   * @param {{key: string, filters: {}}} storeKey
   * @returns {?Array.<{}>}
   */
  getFulfilled(storeKey) {
    if (!storeKey) {
      return null;
    }

    const safeStoreKey = String(storeKey.key);
    if (!this.fulfilledContentLibraries.has(safeStoreKey)) {
      return null;
    }

    return this.filterAndSort(
      this.fulfilledContentLibraries.get(safeStoreKey).contentLibraries,
      storeKey.filters
    );
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   * @param {{key: string, filters: {}}} storeKey
   * @returns {?Error}
   */
  getRejected(storeKey) {
    const safeStoreKey = String(storeKey.key);
    if (!this.fulfilledContentLibraries.has(safeStoreKey)) {
      return null;
    }
    return this.fulfilledContentLibraries.get(safeStoreKey).error;
  }

  /**
   * Clears all the cached content libraries.
   */
  @action clearAll() {
    this.fulfilledContentLibraries.clear();
    this.state = STATE_PRE;
  }

  /**
   * Sets the active contentLibrary number.
   *
   * @param {number} contentLibraryId
   */
  @action setActiveContentLibraryId(contentLibraryId) {
    this.activeContentLibraryId = contentLibraryId;
  }

  /**
   * expires store cache for filters.
   *
   * @param {{}} filters
   */
  @action expireCacheForFilters(filters) {
    // Never ever show allowCreate24 libraries.
    const safeFilters = {...filters, allowCreate24: true};

    const storeKey = this.getStoreKey(safeFilters);

    if (this.fulfilledContentLibraries.has(storeKey.key)) {
      this.fulfilledContentLibraries.get(storeKey.key)[EXPIRE_TIME] = null;
    }
  }

  /**
   * Fetches contentLibraries from the server by client ID & product ID.
   *
   * @param {{}} filters
   * @returns {{key: string, filters: {}}}
   */
  @action fetchContentLibraries(filters) {
    this.validateFilters(filters);

    // Never ever show allowCreate24 libraries.
    const safeFilters = {...filters, allowCreate24: true};

    const storeKey = this.getStoreKey(safeFilters);
    let currentLibraries = null;
    let currentExpireTime = null;

    if (this.fulfilledContentLibraries.has(storeKey.key)) {
      currentLibraries = this.fulfilledContentLibraries.get(storeKey.key);
      currentExpireTime = currentLibraries[EXPIRE_TIME];
    }

    if (currentLibraries && currentLibraries.state === STATE_PENDING) {
      // If this store key is already pending, then do nothing.
      return storeKey;
    }

    if (currentExpireTime && currentExpireTime <= Date.now()) {
      this.fulfilledContentLibraries.delete(storeKey.key);
      currentExpireTime = null;
    }

    if (currentExpireTime) {
      this.state = STATE_FULFILLED;
      return storeKey;
    }

    this.state = STATE_PENDING;

    this.fulfilledContentLibraries.set(storeKey.key, {
      state: STATE_PENDING,
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      contentLibraries: [],
      error: null,
    });

    serverApi.availableLibraries(safeFilters).then(
      action('fetchContentLibrariesSuccess', (contentLibraries) => {
        this.fulfilledContentLibraries.set(storeKey.key, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          contentLibraries: contentLibraries,
          error: null,
        });
        this.state = STATE_FULFILLED;
      }),
      action('fetchContentLibrariesError', (error) => {
        this.fulfilledContentLibraries.set(storeKey.key, {
          state: STATE_REJECTED,
          contentLibraries: [],
          error,
        });
        this.state = STATE_REJECTED;
      }),
    );
    return storeKey;
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{key: string, filters: {}}} storeKey
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(storeKey, handlers) {
    const getFulfilled = () => {
      return this.getFulfilled(storeKey);
    };
    const getRejected = () => {
      return this.getRejected(storeKey);
    };

    let state = STATE_PRE;
    if (storeKey && this.fulfilledContentLibraries.has(storeKey.key)) {
      state = this.fulfilledContentLibraries.get(storeKey.key).state;
    }

    return getCase(state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for this store.
   *
   * @param {string} storeKey
   * @returns {Promise}
   */
  getPromise(storeKey) {
    const thisStore = this;
    const coreKey = storeKey.key;

    return new Promise((resolve, reject) => {
      when(
        () => {
          const libraries = thisStore.fulfilledContentLibraries.get(coreKey);
          const state = (libraries) ? libraries.state : null;
          return (state === STATE_FULFILLED || state === STATE_REJECTED);
        },
        () => {
          const state = thisStore.fulfilledContentLibraries.get(coreKey).state;
          if (state === STATE_REJECTED) {
            reject(this.getRejected(storeKey));
            return;
          }

          resolve(this.getFulfilled(storeKey));
        },
        {name: 'apiContentLibrariesStoreGetPromise'}
      );
    });
  }
}

export default new ContentLibrariesStore();
