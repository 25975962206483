import Pickr from '@simonwep/pickr/src/js/pickr';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import '@simonwep/pickr/dist/themes/monolith.min.css';
import './colorSelector.scss';

/**
 * The default color to fall back to.
 * @const {string}
 */
const DEFAULT_COLOR = '#000000';

/**
 * The default opacity to fall back to.
 * @const {string}
 */
const DEFAULT_OPACITY = 1;

/**
 * The factor to convert to a percentage.
 * @const {number}
 */
const TO_PERCENTAGE = 100;

/**
 * Maps an opacity value (0 - 1) to a hex value.
 *
 * @param {number} opacityValue
 * @returns {string}
 */
function opacityToHex(opacityValue) {
  const safeOpacity = Math.max(0, Math.min(1, opacityValue));

  const maxHexValue = 255;
  const hexBase = 16;

  // Map opacity to nearest hex integer.
  const intValue = Math.round(safeOpacity * maxHexValue);

  let hexValue = intValue.toString(hexBase);

  // Format with leading 0 if needed.
  if (hexValue.length < 2) {
    hexValue = '0' + hexValue;
  }

  return hexValue.toUpperCase();
}

/**
 * The ColorSelector component.
 */
export class ColorSelector extends React.Component {
  /**
   * The picker object.
   *
   * @type {?Pickr}
   */
  @observable picker = null;

  /**
   * Triggered when the component has been added to the page.
   */
  componentDidMount() {
    this.initialize();
  }

  /**
   * Triggered when a component prop changes.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    let hasChanges = false;
    const checkProps = ['colorValue', 'opacityValue'];
    checkProps.forEach((propName) => {
      if (prevProps[propName] !== this.props[propName]) {
        hasChanges = true;
      }
    });

    if (!hasChanges) {
      return;
    }

    this.initialize();
  }

  /**
   * Triggered when the component is about to be removed from the page.
   */
  componentWillUnmount() {
    this.tearDown();
  }

  /**
   * Initializes the pickr object.
   */
  @action initialize = () => {
    const {colorValue, opacityValue, skipAlpha} = this.props;

    // Convert the default opacity to a hex value to append to the default color hex.
    const opacityHex = (!skipAlpha) ? opacityToHex(opacityValue) : '';

    const startingColorHexa = (colorValue || DEFAULT_COLOR) + opacityHex;

    if (this.picker) {
      this.picker.setColor(startingColorHexa);
      return;
    }

    this.picker = Pickr.create({
      el: '.color-pickr',
      theme: 'monolith',
      default: startingColorHexa,
      components: {
        // Main components
        preview: true,
        opacity: !skipAlpha,
        hue: true,

        // Input / output Options
        interaction: {
          cancel: true,
          input: true,
          save: true,
        },
      },
    });

    this.picker.on('save', this.onColorSave);
    this.picker.on('cancel', this.onColorCancel);
  };

  /**
   * Tears down the pickr object.
   */
  @action tearDown = () => {
    this.picker.off('save', this.onColorSave);
    this.picker.off('cancel', this.onColorCancel);
    this.picker.destroyAndRemove();

    this.picker = null;
  };

  /**
   * Triggered when the cancel button is pressed.
   */
  onColorCancel = () => {
    this.picker.hide();
  };

  /**
   * Triggered when the new color is saved.
   *
   * @param {{a: number, toHEXA: function}} colorData
   */
  onColorSave = (colorData) => {
    const {onChangeColor} = this.props;

    if (!onChangeColor) {
      return;
    }

    const newAlpha = colorData.a * TO_PERCENTAGE;

    const hexaParts = colorData.toHEXA().toString().match(/(#[a-zA-Z0-9]{6})[a-zA-Z0-9]*/);
    const newColor = hexaParts[1].toLowerCase();

    onChangeColor(newColor, newAlpha / TO_PERCENTAGE);

    this.picker.hide();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div className="color-pickr" />
    );
  }
}

ColorSelector.propTypes = {
  onChangeColor: PropTypes.func.isRequired,

  colorValue: PropTypes.string,
  id: PropTypes.string,
  onClose: PropTypes.func,
  opacityValue: PropTypes.number,
  skipAlpha: PropTypes.bool,
};

ColorSelector.defaultProps = {
  colorValue: DEFAULT_COLOR,
  id: '',
  opacityValue: DEFAULT_OPACITY,
  skipAlpha: false,
};

export default observer(ColorSelector);
