/**
 * The default rectangle color.
 * @const {string}
 */
const DEFAULT_COLOR = '#000000';

/**
 * The default rectangle opacity.
 * @const {number}
 */
const DEFAULT_OPACITY = 1;

/**
 * The rectangle component.
 *
 * @param {{opacity: number}=} style
 * @param {{opacity: number, width: number}=} border
 * @returns {{rectangle: {}}}
 */
export function rectangleComponent(style, border) {
  return {
    rectangle: {
      style,
      border
    },
  };
}

/**
 * Gets the rectangle component from the source item.
 *
 * @param {{rectangle: {style: {}, border: {}}}} item
 * @returns {{rectangle: {style: {}, border: {}}}}
 */
export function getRectangleFromSource(item) {
  if (!item.rectangle) {
    return {};
  }

  const rectangle = Object.assign({}, item.rectangle);
  if (!rectangle.style) {
    rectangle.style = {};
  }
  if (!rectangle.border) {
    rectangle.border = {};
  }

  const style = {
    color: rectangle.style.color || DEFAULT_COLOR,
    opacity: getOpacity(rectangle.style),
    rounded: rectangle.style.rounded || 0,
  };
  const border = {
    color: rectangle.border.color || DEFAULT_COLOR,
    opacity: getOpacity(rectangle.border),
    width: rectangle.border.width || 0,
  };

  return rectangleComponent(
    style,
    border,
  );
}

/**
 * Gets the opacity or the default.
 *
 * @param {{opacity: number}} item
 * @returns {number}
 */
function getOpacity(item) {
  if (!item || item.opacity === undefined) {
    return DEFAULT_OPACITY;
  }

  const safeOpacity = Number(item.opacity);
  if (!safeOpacity && safeOpacity !== 0) {
    return DEFAULT_OPACITY;
  }

  return safeOpacity;
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getRectangleForSource(entity) {
  if (!entity.has('rectangle')) {
    return {};
  }

  const rectangle = entity.get('rectangle');
  return {
    rectangle: {
      style: rectangle.style,
      border: rectangle.border,
    },
  };
}
