/* eslint-disable no-magic-numbers */

/**
 * Content that's been created/edited in the app.
 * @const {number}
 */
export const CREATED_CONTENT = 3;

/**
 * Content that represents background video for use in a new video.
 * @const {number}
 */
export const BACKGROUND_VIDEO = 6;

/**
 * Content that represents background stills for use in a new video.
 * @const {number}
 */
export const BACKGROUND_STILL = 5;

/**
 * Content that represents background stills for use in a new video.
 * @const {number}
 */
export const PLACEABLE_IMAGE = 7;

/**
 * The mapping of library types to content types.
 * @const {{}}
 */
export const CONTENT_TYPES = {
  [PLACEABLE_IMAGE]: 44,
  [BACKGROUND_VIDEO]: 42,
  [BACKGROUND_STILL]: 43
};

/**
 * The mapping of library types to layout content types.
 * @const {{}}
 */
export const LAYOUT_CONTENT_TYPES = {
  [BACKGROUND_VIDEO]: 42,
  [BACKGROUND_STILL]: 43
};

/**
 * The list of background library types.
 * @const {number[]}
 */
export const BACKGROUND_TYPES = [
  BACKGROUND_STILL,
  BACKGROUND_VIDEO,
];

/**
 * The list of dynamic video content video types.
 * @const {number[]}
 */
export const DYNAMIC_CONTENT_VIDEO_TYPES = [
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
];

/**
 * The content types that are videos.
 * @const {number[]}
 */
export const VIDEO_CONTENT_TYPES = [
  ...DYNAMIC_CONTENT_VIDEO_TYPES,
  BACKGROUND_VIDEO,
  CONTENT_TYPES[BACKGROUND_VIDEO],
  LAYOUT_CONTENT_TYPES[BACKGROUND_VIDEO],
];

/**
 * The list of library types.
 * @const {number[]}
 */
export const LIBRARY_TYPES = [
  CREATED_CONTENT,
  BACKGROUND_VIDEO,
  BACKGROUND_STILL,
  PLACEABLE_IMAGE,
];
