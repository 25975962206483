import {toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';

import ContentDirectory from '../../common/contentDirectory/ContentDirectory';
import inject from '../../hoc/injectHoc';
import NewClipContentList from '../../pages/newClipType/components/newClipContentList/NewClipContentList';
import NewClipHeader from '../../pages/newClipType/components/newClipHeader/NewClipHeader';
import {CREATED_CONTENT} from '../../../constants/libraryTypeConstants';
import {THUMBNAIL_MAX_HEIGHT, THUMBNAIL_MAX_WIDTH} from '../../../utils/contentsHelper';

import './selectContentModal.scss';

/**
 * The created content library type.
 *
 * @const {number}
 */
const libraryType = CREATED_CONTENT;

/**
 * The SelectContentModal component.
 */
class SelectContentModal extends React.Component {
  /**
   * Triggered when the component just mounted onto the page.
   */
  componentDidMount() {
    if (this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{isOpen: boolean}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Initializes the modal by getting the valid content libraries.
   */
  initializeModal = () => {
    const {sessionStore, activeContentStore, apiContentLibrariesStore} = this.props;

    const {userId, clientId, productId} = sessionStore;

    const filters = {
      productId,
      clientId: clientId,
      userId: userId,
      contentLibraryTypeId: libraryType,
    };

    const storeKey = apiContentLibrariesStore.fetchContentLibraries(filters);
    activeContentStore.setLibraryStoreKey(libraryType, storeKey);

    this.loadContents(1);
  };

  /**
   * Changes the category.
   *
   * @param {number} newCategoryId
   */
  onChangeCategory = (newCategoryId) => {
    const {activeContentStore} = this.props;

    activeContentStore.setCategoryId(libraryType, newCategoryId);
  };

  /**
   * Load selected category's contents.
   *
   * @param {number} page
   * @returns {Promise}
   */
  loadContents = (page) => {
    const {activeContentStore, apiContentsStore} = this.props;

    const libraryId = activeContentStore.getLibraryId(libraryType);
    const categoryId = activeContentStore.getCategoryId(libraryType);

    const safePage = (page) ? Number(page) : 1;

    if (!libraryId || !categoryId) {
      return Promise.resolve(null);
    }

    const storeKey = apiContentsStore.fetchContents({
      categoryId,
      libraryId,
      page: safePage,
    });

    return apiContentsStore.getPromise(storeKey);
  };

  /**
   * Triggered when the modal is closed without a chosen content.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete(null);
  };

  /**
   * Triggered when the modal is closed after choosing content.
   *
   * @param {{}} newContent
   */
  onCompleteModal = (newContent) => {
    const {onComplete} = this.props;

    onComplete(toJS(newContent));
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      isOpen,
      title,
      activeContentStore,
    } = this.props;

    const hasCategory = Boolean(activeContentStore.getCategoryId(libraryType));

    // This will make the content src display show as a square.
    const contentSize = {
      width: THUMBNAIL_MAX_WIDTH,
      height: THUMBNAIL_MAX_HEIGHT,
    };

    return (
      <Modal isOpen={isOpen} backdrop="static" toggle={this.onCancelModal} className="select-content-modal">
        <ModalHeader toggle={this.onCancelModal}>{title || 'Select Content'}</ModalHeader>
        <ModalBody>
          <div className="modal-body-content-wrapper">
            <div className="content-directory-sidebar">
              <ContentDirectory
                libraryType={libraryType}
                onCategorySelected={this.onChangeCategory}
                activeContentStore={activeContentStore}
                allowCategoryEdit={false}
              />
            </div>
            <div className="select-content-main">
              <div className="container">
                {(hasCategory) && (
                  <NewClipHeader
                    libraryType={libraryType}
                    isWriteable={false}
                  />
                )}

                <NewClipContentList
                  contentSize={contentSize}
                  libraryType={libraryType}
                  onLoadNextPage={this.loadContents}
                  onSelectContent={this.onCompleteModal}
                  openUploadDialog={this.openUploadDialog}
                  scrollElSelector=".content-row"
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

SelectContentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,

  activeContentStore: MobxPropTypes.observableObject,
  apiContentLibrariesStore: MobxPropTypes.observableObject,
  apiContentsStore: MobxPropTypes.observableObject,
  sessionStore: MobxPropTypes.observableObject,
  title: PropTypes.string,
};

SelectContentModal.defaultProps = {
  title: 'Select Content',
};

SelectContentModal.wrappedComponent = {};
SelectContentModal.wrappedComponent.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentLibrariesStore: MobxPropTypes.observableObject.isRequired,
  apiContentsStore: MobxPropTypes.observableObject.isRequired,
  sessionStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(SelectContentModal)(
  observer(SelectContentModal)
);
