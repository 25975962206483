import classNames from 'classnames';
import {action} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {actionUpdateComponent} from '../../../../../display/components/action/actionUpdateComponent';
import {viewportComponent} from '../../../../../display/components/type/viewportComponent';

/**
 * The ViewportOptionButtons component.
 *
 * @param {{}} params
 * @param {ObservableMap} params.entity
 * @param {GameStore} params.game
 * @returns {React.Component}
 */
export const ViewportOptionButtons = ({entity, game}) => {
  /**
   * Updates the maintain aspect ratio value in the viewport component.
   *
   * @returns {function()}
   */
  const onClickAspectRatio = () => {
    return action(() => {
      const actionParams = {
        entityId: entity.get('id'),
      };

      const currentViewPort = entity.get('viewport');
      const newViewPort = viewportComponent({
        maintainAspectRatio: !currentViewPort.maintainAspectRatio,
      }, currentViewPort);

      game.addAction(actionParams, actionUpdateComponent(newViewPort));
    });
  };

  const viewport = entity.get('viewport');

  const classes = {
    'active': Boolean(viewport.maintainAspectRatio),
  };

  return (
    <div className="viewport-option-buttons form-buttons">
      <button
        className={classNames('btn btn-sm btn-dark form-button', classes)}
        type="button"
        onClick={onClickAspectRatio()}
      >
        <i className="fad fad-window-restore" />
      </button>
    </div>
  );
};

ViewportOptionButtons.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: PropTypes.object.isRequired,
};

export default observer(ViewportOptionButtons);
