import {VIEWPORT_TYPE_IMAGE, VIEWPORT_TYPE_VIDEO} from '../../../../../../display/components/type/viewportComponent';

/**
 * Gets the source data for a placeholder viewport.
 *
 * @param {number} contentId
 * @param {boolean} isImage
 * @param {number} fileId
 * @param {string} fileType
 * @param {string} fileAspectRatio
 * @returns {{}}
 */
export function getPlaceHolderViewportData(contentId, isImage, fileId, fileType, fileAspectRatio) {
  return {
    viewport: {
      contentId,
      type: (isImage) ? VIEWPORT_TYPE_IMAGE : VIEWPORT_TYPE_VIDEO,
      file: {
        id: fileId,
        type: fileType,
        aspectRatio: fileAspectRatio,
      },
      maintainAspectRatio: true,
    },
  };
}
