/**
 * The list of works24 custom font styles.
 *
 * @type {Object.<string, {}>}
 */
export const w24FontStyles = {
  'FONT_AMATICSC': {
    name: 'AmaticSC',
    fontFamily: 'AmaticSC, sans-serif',
  },
  'FONT_ARCHITECTSDAUGHTER': {
    name: 'ArchitectsDaughter',
    fontFamily: 'ArchitectsDaughter, sans-serif',
  },
  'FONT_ARVO': {
    name: 'Arvo',
    fontFamily: 'Arvo, sans-serif',
  },
  'FONT_CENTURYGOTHIC': {
    name: 'CenturyGothic',
    fontFamily: 'CenturyGothic, sans-serif',
  },
  'FONT_FONDAMENTO': {
    name: 'Fondamento',
    fontFamily: 'Fondamento, sans-serif',
  },
  'FONT_GREATVIBES': {
    name: 'GreatVibes',
    fontFamily: 'GreatVibes, sans-serif',
  },
  'FONT_GOTHAMCONDENSEDMEDIUM': {
    name: 'Gotham Condensed Medium',
    fontFamily: 'GothamCondensedMedium, sans-serif',
  },
  'FONT_JURA': {
    name: 'Jura',
    fontFamily: 'Jura, sans-serif',
  },
  'FONT_LIFESAVERS': {
    name: 'LifeSavers',
    fontFamily: 'LifeSavers, sans-serif',
  },
  'FONT_LORA': {
    name: 'Lora',
    fontFamily: 'Lora, sans-serif',
  },
  'FONT_MERIENDA': {
    name: 'Merienda',
    fontFamily: 'Merienda, sans-serif',
  },
  'FONT_MERRIWEATHER': {
    name: 'Merriweather',
    fontFamily: 'Merriweather, sans-serif',
  },
  'FONT_MONTSERRAT': {
    name: 'Montserrat',
    fontFamily: 'Montserrat, sans-serif',
  },
  'FONT_OSWALD': {
    name: 'Oswald',
    fontFamily: 'Oswald, sans-serif',
  },
  'FONT_PTSANS': {
    name: 'PTSans',
    fontFamily: 'PTSans, sans-serif',
  },
  'FONT_PACIFICO': {
    name: 'Pacifico',
    fontFamily: 'Pacifico, sans-serif',
  },
  'FONT_PERMANENTMARKER': {
    name: 'PermanentMarker',
    fontFamily: 'PermanentMarker, sans-serif',
  },
  'FONT_PLAYBALL': {
    name: 'Playball',
    fontFamily: 'Playball, sans-serif',
  },
  'FONT_PLAYFAIRDISPLAY': {
    name: 'PlayfairDisplay',
    fontFamily: 'PlayfairDisplay, sans-serif',
  },
  'FONT_PROXIMANOVA': {
    name: 'ProximaNova',
    fontFamily: 'ProximaNova, sans-serif',
  },
  'FONT_PROXIMANOVABLACK': {
    name: 'ProximaNovaBlack',
    fontFamily: 'ProximaNovaBlack, sans-serif',
  },
  'FONT_PROXIMANOVASEMIBOLD': {
    name: 'ProximaNovaSemibold',
    fontFamily: 'ProximaNovaSemibold, sans-serif',
  },
  'FONT_QUICKSAND': {
    name: 'Quicksand',
    fontFamily: 'Quicksand, sans-serif',
  },
  'FONT_ROBOTO': {
    name: 'Roboto',
    fontFamily: 'Roboto, sans-serif',
  },
  'FONT_ROBOTOCONDENSED': {
    name: 'RobotoCondensed',
    fontFamily: 'RobotoCondensed, sans-serif',
  },
  'FONT_ROBOTOSLAB': {
    name: 'RobotoSlab',
    fontFamily: 'RobotoSlab, sans-serif',
  },
  'FONT_ROSARIO': {
    name: 'Rosario',
    fontFamily: 'Rosario, sans-serif',
  },
  'FONT_RUBIK': {
    name: 'Rubik',
    fontFamily: 'Rubik, sans-serif',
  },
  'FONT_SANCREEK': {
    name: 'Sancreek',
    fontFamily: 'Sancreek, sans-serif',
  },
  'FONT_SOURCESANSPRO': {
    name: 'SourceSansPro',
    fontFamily: 'SourceSansPro, sans-serif',
  },
  'FONT_SPECIALELITE': {
    name: 'SpecialElite',
    fontFamily: 'SpecialElite, sans-serif',
  },
  'FONT_YELLOWTAIL': {
    name: 'Yellowtail',
    fontFamily: 'Yellowtail, sans-serif',
  },
};
