import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityActivateHoc from '../../hoc/entityActivateHoc';

import './displayCircle.scss';

/**
 * The DisplayCircle component.
 */
export class DisplayCircle extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, style, className} = this.props;

    const entityId = entity.get('id');
    const circle = entity.get('circle');
    const circleStyle = circle.style;
    const circleBorder = circle.border;

    const width = parseInt(style.width, 10);
    const height = parseInt(style.height, 10);
    const borderSize = parseInt(circleBorder.width, 10) || 0;

    const middleWidth = Math.floor(width / 2);
    const middleHeight = Math.floor(height / 2);
    const radius = Math.min(middleHeight, middleWidth);

    return (
      <div id={entityId} className={classNames('display-circle', className)} style={style}>
        <svg viewBox={`0 0 ${width} ${height}`}>
          <circle
            cx={middleWidth}
            cy={middleHeight}
            r={radius - borderSize}
            fill={circleStyle.color}
            fillOpacity={circleStyle.opacity}
          />

          {borderSize && (
            <circle
              cx={middleWidth}
              cy={middleHeight}
              r={radius - (borderSize / 2)}
              fill="none"
              stroke={circleBorder.color}
              strokeOpacity={circleBorder.opacity}
              strokeWidth={borderSize}
            />
          )}
        </svg>
      </div>
    );
  }
}

DisplayCircle.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  style: PropTypes.object.isRequired,

  className: PropTypes.string,
};

export default entityActivateHoc(
  observer(DisplayCircle)
);
