import {reaction} from 'mobx';

import activeContentStore from '../../stores/active/activeContentStore';
import {CREATED_CONTENT} from '../../constants/libraryTypeConstants';
import {categorySearchChange} from '../content/contentCommon';

/**
 * The disposer function for the mobX reaction().
 *
 * @type {?function}
 */
let reactionDispose = null;

/**
 * Initializes reaction for created content changes.
 */
export function init() {
  reactionDispose = reaction(
    () => activeContentStore.libraries[CREATED_CONTENT].category.searchFilter,
    onCategorySearchChange
  );
}

/**
 * Performs logic when category search filter changes.
 *
 * @param {?number} searchFilter
 */
function onCategorySearchChange(searchFilter) {
  categorySearchChange(searchFilter, CREATED_CONTENT);
}

/**
 * Disposes of the thunk.
 * This is useful for hot-reloading purposes.
 */
export function dispose() {
  if (reactionDispose) {
    reactionDispose();
  }
}

export default init;
