import {observable, action, computed, runInAction, extendObservable, toJS, when} from 'mobx';

import apiContentsStore from '../apiContentsStore';
import apiContentSearchStore from '../apiContentSearchStore';
import apiContentLibrariesStore from '../apiContentLibrariesStore';
import sessionStore from '../../active/sessionStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED, STATE_PRE} from '../../../constants/asyncConstants';
import {BACKGROUND_STILL, BACKGROUND_VIDEO, PLACEABLE_IMAGE} from '../../../constants/libraryTypeConstants';
import {apiStore, getCase} from '../../../utils/apiStore';
import serverApi from '../../../utils/serverApi';

/**
 * Conversion factor to a percentage.
 * @const {number}
 */
const TO_PERCENT = 100;

/**
 * Manages Content create flow
 */
class CreateContentStore {
  /**
   * The map of each file that is being uploaded.
   *
   * @type {ObservableMap<string, {
   *   file: ?Observable,
   *   state: string,
   *   progress: number,
   *   error: ?Error,
   *   cancel: ?function,
   * }>}
   */
  @observable uploadingFiles = observable.map();

  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * Gets the state value for uploading as a whole.
   *
   * @returns {Symbol}
   */
  @computed get uploadingState() {
    let state = STATE_PRE;
    this.uploadingFiles.forEach((uploadData) => {
      if (!uploadData || !uploadData.state) {
        return;
      } else if (state === STATE_PENDING) {
        // If anything is pending, then all are pending.
        return;
      }

      if (state === STATE_PRE) {
        state = uploadData.state;
        return;
      } else if (uploadData.state === STATE_PENDING) {
        state = STATE_PENDING;
        return;
      }

      if (state === STATE_REJECTED) {
        return;
      } else if (uploadData.state === STATE_REJECTED) {
        state = STATE_REJECTED;
        return;
      }

      state = STATE_FULFILLED;
    });

    return state;
  }

  /**
   * Clears all uploading file data.
   */
  @action clearAll() {
    this.uploadingFiles.clear();
  }

  /**
   * Clears a single file uploading data.
   *
   * @param {string} fileKey
   */
  @action clearUpload(fileKey) {
    if (!fileKey) {
      return;
    }

    const safeFileKey = String(fileKey);
    if (!this.uploadingFiles.has(safeFileKey)) {
      return;
    }

    this.uploadingFiles.delete(safeFileKey);
  }

  /**
   * Cancels all files currently being uploaded.
   */
  @action cancelAll() {
    this.uploadingFiles.forEach((uploadingData) => {
      const cancel = uploadingData.cancel;
      if (!cancel || typeof cancel !== 'function') {
        return;
      }

      cancel();
    });
  }

  /**
   * Cancels an ongoing upload.
   *
   * @param {string} fileKey
   */
  @action cancelUpload(fileKey) {
    if (!fileKey) {
      return;
    }

    const safeFileKey = String(fileKey);
    if (!this.uploadingFiles.has(safeFileKey)) {
      return;
    }

    const cancel = this.uploadingFiles.get(safeFileKey).cancel;
    if (!cancel || typeof cancel !== 'function') {
      return;
    }

    cancel();
  }

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @param {string} fileKey
   * @returns {boolean}
   */
  getFulfilled(fileKey) {
    if (!fileKey) {
      return null;
    }

    const safeFileKey = String(fileKey);
    if (!this.uploadingFiles.has(safeFileKey)) {
      return null;
    }

    return toJS(this.uploadingFiles.get(safeFileKey).file);
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @param {string} fileKey
   * @returns {?Error}
   */
  getRejected(fileKey) {
    if (!fileKey) {
      return null;
    }

    const safeFileKey = String(fileKey);
    if (!this.uploadingFiles.has(safeFileKey)) {
      return null;
    }

    return this.uploadingFiles.get(safeFileKey).error;
  }

  /**
   * Create Content objects for each of the passed in files.
   *
   * @param {Array.<{}>} files
   * @param {number} libraryType
   * @param {number} libraryId
   * @param {?number} productId
   * @param {?number=} categoryId
   */
  @action createContent(files, libraryType, libraryId, productId, categoryId) {
    // Build the title for the content.
    const titleStart = (libraryType === BACKGROUND_VIDEO) ? 'video' : 'image';

    let titleMiddle = '';
    if (libraryType === BACKGROUND_VIDEO || libraryType === BACKGROUND_STILL) {
      titleMiddle = '_background';
    } else if (libraryType === PLACEABLE_IMAGE) {
      titleMiddle = '_placeable';
    }

    const title = `${titleStart}${titleMiddle}`;

    const fileUploadRequests = files.map((file) => {
      const fileKey = String(file.name);
      file.title = title;

      runInAction('addNewUploadingFile', () => {
        this.uploadingFiles.set(fileKey, {
          file: file,
          state: STATE_PENDING,
          progress: 0,
          error: null,
          cancel: null,
        });
      });

      const onProgress = action((progressEvent) => {
        this.uploadingFiles.get(fileKey).progress = Math.floor(
          (progressEvent.loaded * TO_PERCENT) / progressEvent.total
        );
      });

      const cancelable = action((cancelUpload) => {
        // Saves the cancel function that will stop the uploading request.
        this.uploadingFiles.get(fileKey).cancel = cancelUpload;
      });

      return serverApi.uploadContentFromFile(
        file,
        libraryType,
        libraryId,
        categoryId,
        title,
        onProgress,
        cancelable
      ).then(
        action('fileUploadedSuccess', () => {
          this.uploadingFiles.get(fileKey).state = STATE_FULFILLED;
        }),
        action('fileUploadedError', (uploadError) => {
          const uploadingData = this.uploadingFiles.get(fileKey);
          if (!uploadingData) {
            // It is possible that after canceling everything, then clearing the keys, this could happen.
            return;
          }

          uploadingData.state = STATE_REJECTED;
          uploadingData.error = uploadError;
        })
      );
    });

    Promise.all(fileUploadRequests).then(() => {
      const {userId, clientId} = sessionStore;

      const libraryFilters = {
        productId,
        clientId,
        userId,
        contentLibraryTypeId: libraryType,
      };

      apiContentLibrariesStore.expireCacheForFilters(libraryFilters);
      apiContentLibrariesStore.fetchContentLibraries(libraryFilters);

      apiContentsStore.refreshCurrentContents({categoryId, libraryId});
      apiContentSearchStore.expireCacheForLibrary(libraryId);
    });
  }

  /**
   * Runs handlers for uploading as a whole based on changes to the individual files' states.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  uploadCase(handlers) {
    const getFulfilled = () => {
      return true;
    };
    const getRejected = () => {
      return new Error('One or more uploads had an error.');
    };

    const state = this.uploadingState;
    return getCase(state, getFulfilled, getRejected, handlers);
  }

  /**
   * Runs handlers for a file based on changes in the file's state.
   *
   * @param {string} fileKey
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  fileCase(fileKey, handlers) {
    const getFulfilled = () => {
      return this.getFulfilled(fileKey);
    };
    const getRejected = () => {
      return this.getRejected(fileKey);
    };

    let state = STATE_PRE;
    if (fileKey && this.uploadingFiles.has(fileKey)) {
      state = this.uploadingFiles.get(fileKey).state;
    }

    return getCase(state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for all uploading files.
   *
   * @returns {Promise}
   */
  getUploadPromise() {
    const thisStore = this;

    return new Promise((resolve, reject) => {
      when(
        () => {
          const state = thisStore.uploadingState;
          return (state === STATE_FULFILLED || state === STATE_REJECTED);
        },
        () => {
          const state = thisStore.state || null;
          if (state === STATE_REJECTED) {
            reject(new Error('One or more uploads had an error.'));
            return;
          }

          resolve(true);
        },
        {name: 'apiCreateContentStoreGetUploadPromise'}
      );
    });
  }

  /**
   * Gets a promise for a single uploading file.
   *
   * @param {string} fileKey
   * @returns {Promise}
   */
  getFilePromise(fileKey) {
    const thisStore = this;

    return new Promise((resolve, reject) => {
      when(
        () => {
          const categoryData = thisStore.uploadingFiles.get(fileKey);
          const state = (categoryData) ? categoryData.state : null;
          return (state === STATE_FULFILLED || state === STATE_REJECTED);
        },
        () => {
          const state = thisStore.uploadingFiles.get(fileKey).state;
          if (state === STATE_REJECTED) {
            reject(this.getRejected(fileKey));
            return;
          }

          resolve(this.getFulfilled(fileKey));
        },
        {name: 'apiCreateContentStoreGetFilePromise'}
      );
    });
  }
}

export default new CreateContentStore();
