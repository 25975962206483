import {action, extendObservable, observable, when} from 'mobx';

import apiCategoriesStore from '../apiCategoriesStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {apiStore, getCase} from '../../../utils/apiStore';
import serverApi from '../../../utils/serverApi';

/**
 * The delete category store.
 */
class DeleteCategoryStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * The error status of the store.
   *
   * @type {Observable<{error: ?Error}>}
   */
  @observable category = {
    error: null
  };

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {?Array.<{}>}
   */
  getFulfilled() {
    return true;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    return this.category.error;
  }

  /**
   * Deletes the category from the server.
   *
   * @param {number} categoryId
   * @param {number=} libraryId
   * @returns {Promise}
   */
  @action deleteCategory(categoryId, libraryId) {
    this.state = STATE_PENDING;

    return serverApi.contentsByMultiCategoryIds([categoryId], {
      page: 1,
      perPage: 1,
      hideDeleted: true,
    }).then((foundContent) => {
      if (foundContent.pagination.total) {
        throw new Error('Category could not be deleted because it has active content.');
      }

      return serverApi.categoryDelete(categoryId);
    }).then(
      action('deleteCategorySuccess', () => {
        if (libraryId) {
          apiCategoriesStore.expireCacheForLibrary(libraryId);
          apiCategoriesStore.fetchCategories({contentLibraryId: libraryId});
        }

        this.category.error = null;
        this.state = STATE_FULFILLED;
      }),
      action('deleteCategoryError', (error) => {
        this.category.error = error;
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => {
      return this.getFulfilled();
    };
    const getRejected = () => {
      return this.getRejected();
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for this store.
   *
   * @returns {Promise}
   */
  getPromise() {
    const thisStore = this;

    return new Promise((resolve, reject) => {
      when(
        () => {
          const state = thisStore.state;
          return (state === STATE_FULFILLED || state === STATE_REJECTED);
        },
        () => {
          const state = thisStore.state;
          if (state === STATE_REJECTED) {
            reject(this.getRejected());
            return;
          }

          resolve(this.getFulfilled());
        },
        {name: 'apiDeleteCategoryStoreGetPromise'}
      );
    });
  }
}

export default new DeleteCategoryStore();
