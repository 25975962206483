import lodash from 'lodash';
import {runInAction} from 'mobx';

import {actionPositionComponent} from '../components/action/actionPositionComponent';
import {getEntityPosition, getEntitySize} from '../ecs/entityHelper';
import * as align from '../../constants/entityOptionConstants';

/**
 * The name of the system.
 * @const {string}
 */
export const ALIGNMENT_SYSTEM = 'alignmentSystem';

/**
 * Gets a new instance of the alignment system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function alignmentSystem(game) {
  /**
   * Called right before the game loop updates.
   *
   * @param {Array.<{}>} actions
   * @param {Array.<{}>} entities
   */
  function systemRunActions(actions, entities) {
    runInAction('alignmentSystemUpdateEntity', () => {
      actions.forEach((actionEntity) => {
        if (!actionEntity.has('actionAlign')) {
          return;
        }

        const action = actionEntity.get('action');
        const activeEntityIds = action.entityId;

        if (!activeEntityIds || typeof activeEntityIds === 'string') {
          return;
        } else if (activeEntityIds.length < 2) {
          return;
        }

        const activeEntities = lodash.intersectionBy(entities, activeEntityIds, (item) => {
          if (lodash.isString(item)) {
            return item;
          }
          return item.get('id');
        });

        if (!activeEntities || activeEntities.length < 2) {
          return;
        }

        const actionAlign = actionEntity.get('actionAlign');
        const alignment = actionAlign.alignment;
        const resolution = game.resolution;

        const positions = getNewPositions(activeEntities, alignment, resolution);
        if (!positions) {
          return;
        }

        positions.forEach(({entity, positionDelta}) => {
          game.addAction({
            entityId: entity.get('id'),
          }, actionPositionComponent(false, positionDelta, null, null, true));
        });
      });
    });
  }

  /**
   * Gets the new positions for the entities.
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{}} resolution
   * @returns {?Array.<{entity: {}, positionDelta: {}}>}
   */
  function getNewPositions(activeEntities, alignment, resolution) {
    if (lodash.includes([align.ALIGN_LEFT, align.ALIGN_TOP], alignment)) {
      return alignLeftOrTop(activeEntities, alignment);
    }
    if (lodash.includes([align.ALIGN_RIGHT, align.ALIGN_BOTTOM], alignment)) {
      return alignRightOrBottom(activeEntities, alignment);
    }
    if (lodash.includes([align.ALIGN_CENTER, align.ALIGN_MIDDLE], alignment)) {
      return alignCenterOrMiddle(activeEntities, alignment);
    }
    if (lodash.includes([align.CENTER_HORIZONTALLY, align.CENTER_VERTICALLY], alignment)) {
      return centerItemsOnPage(activeEntities, alignment, resolution);
    }
    if (lodash.includes([align.DISTRIBUTE_HORIZONTALLY, align.DISTRIBUTE_VERTICALLY], alignment)) {
      return distributeItems(activeEntities, alignment, resolution);
    }

    return null;
  }

  /**
   * Aligns the items to the far left or far top.
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function alignLeftOrTop(activeEntities, alignment) {
    const positionProp = (align.ALIGN_LEFT === alignment) ? 'left' : 'top';
    const allValues = activeEntities.map((entity) => getEntityPosition(entity)[positionProp]);

    const newValue = lodash.min(allValues);

    return activeEntities.map((entity) => {
      const position = getEntityPosition(entity);
      return {
        entity,
        positionDelta: {
          [positionProp]: (newValue - position[positionProp]),
        },
      };
    });
  }

  /**
   * Aligns the items to the far right or far bottom.
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function alignRightOrBottom(activeEntities, alignment) {
    const positionProp = (align.ALIGN_RIGHT === alignment) ? 'left' : 'top';
    const sizeProp = (align.ALIGN_RIGHT === alignment) ? 'width' : 'height';
    const allValues = activeEntities.map((entity) => getEntityPosition(entity)[positionProp]);

    const {end} = getBoundaries(activeEntities, allValues, positionProp, sizeProp);
    const farEnd = end[positionProp] + end[sizeProp];

    return activeEntities.map((entity) => {
      const position = getEntityPosition(entity);
      const size = getEntitySize(entity);
      const newValue = farEnd - size[sizeProp];
      return {
        entity,
        positionDelta: {
          [positionProp]: (newValue - position[positionProp]),
        },
      };
    });
  }

  /**
   * Aligns the items to the center or middle.
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function alignCenterOrMiddle(activeEntities, alignment) {
    const positionProp = (align.ALIGN_CENTER === alignment) ? 'left' : 'top';
    const sizeProp = (align.ALIGN_CENTER === alignment) ? 'width' : 'height';
    const allValues = activeEntities.map((entity) => getEntityPosition(entity)[positionProp]);

    const {start, end} = getBoundaries(activeEntities, allValues, positionProp, sizeProp);
    const farStart = start[positionProp];
    const farEnd = end[positionProp] + end[sizeProp];

    const newCenter = ((farEnd - farStart) / 2) + farStart;

    return activeEntities.map((entity) => {
      const position = getEntityPosition(entity);
      const size = getEntitySize(entity);
      const newValue = newCenter - (size[sizeProp] / 2);
      return {
        entity,
        positionDelta: {
          [positionProp]: (newValue - position[positionProp]),
        },
      };
    });
  }

  /**
   * Centers the items on the page either horizontally or vertically.
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{width: number, height: number}} resolution
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function centerItemsOnPage(activeEntities, alignment, resolution) {
    const positionProp = (align.CENTER_HORIZONTALLY === alignment) ? 'left' : 'top';
    const sizeProp = (align.CENTER_HORIZONTALLY === alignment) ? 'width' : 'height';

    const newCenter = (resolution[sizeProp] / 2);

    return activeEntities.map((entity) => {
      const position = getEntityPosition(entity);
      const size = getEntitySize(entity);
      const newValue = newCenter - (size[sizeProp] / 2);
      return {
        entity,
        positionDelta: {
          [positionProp]: (newValue - position[positionProp]),
        },
      };
    });
  }

  /**
   * Distributes the items evenly either horizontally or vertically.
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function distributeItems(activeEntities, alignment) {
    const positionProp = (align.DISTRIBUTE_HORIZONTALLY === alignment) ? 'left' : 'top';
    const sizeProp = (align.DISTRIBUTE_HORIZONTALLY === alignment) ? 'width' : 'height';
    const allValues = activeEntities.map((entity) => getEntityPosition(entity)[positionProp]);
    const allSizes = activeEntities.map((entity) => getEntitySize(entity)[sizeProp]);

    const {start, end} = getBoundaries(activeEntities, allValues, positionProp, sizeProp);
    const farStart = start[positionProp];
    const farEnd = end[positionProp] + end[sizeProp];

    const totalPadding = (farEnd - farStart) - lodash.sum(allSizes);
    const numberOfSpaces = activeEntities.length - 1;
    const newPadding = totalPadding / numberOfSpaces;

    const orderedEntities = lodash.sortBy(activeEntities, (entity) => {
      const positionFloor = Math.floor(getEntityPosition(entity)[positionProp]);
      const sizeFloor = Math.floor(getEntitySize(entity)[sizeProp]);
      const positionOffset = 100000;

      // Should still not overflow even for 32 bit computers unless the numbers are in the 50,000 range.
      // Allows for multi-sorting for position and size (sending array of values doesn't work for some reason).
      return (positionFloor * positionOffset) + sizeFloor;
    });

    let ongoingStart = 0;
    return orderedEntities.map((entity, index) => {
      const position = getEntityPosition(entity);
      const size = getEntitySize(entity);

      if (!index || index === numberOfSpaces) {
        ongoingStart += position[positionProp] + size[sizeProp];
        return {entity, positionDelta: {
          [positionProp]: 0,
        }};
      }

      const newValue = ongoingStart + newPadding;
      ongoingStart += newPadding + size[sizeProp];
      return {
        entity,
        positionDelta: {
          [positionProp]: (newValue - position[positionProp]),
        },
      };
    });
  }

  /**
   * Gets the most horizontal edges of the entities.
   *
   * @param {Array.<{}>} activeEntities
   * @param {number[]} allValues
   * @param {number} positionProp
   * @param {number} sizeProp
   * @returns {{
   *   start: {left: number, right: number},
   *   end: {left: number, right: number, width: number, height: number}
   * }}
   */
  function getBoundaries(activeEntities, allValues, positionProp, sizeProp) {
    const start = {
      [positionProp]: lodash.min(allValues)
    };

    const end = activeEntities.reduce((final, entity) => {
      const position = getEntityPosition(entity);
      const size = getEntitySize(entity);

      const currentTotal = position[positionProp] + size[sizeProp];
      const previousTotal = final[positionProp] + final[sizeProp];

      if (currentTotal > previousTotal) {
        return {
          [positionProp]: position[positionProp],
          [sizeProp]: size[sizeProp]
        };
      }

      return final;
    }, {[positionProp]: 0, [sizeProp]: 0});

    return {start, end};
  }

  return {
    name: ALIGNMENT_SYSTEM,
    runActions: systemRunActions
  };
}
