import {getLineFromSource} from '../../../../../../display/components/type/lineComponent';

/**
 * Gets the data for a placeholder line.
 *
 * @param {{}} param
 * @param {{width: number, height: number}} param.resolution
 * @returns {{line: {startPoint: {}, endPoint: {}}}}
 */
export function getPlaceHolderLineData({resolution}) {
  const thickness = 3;
  const widthAndHeight = 200;

  const centerOfDisplay = {
    width: (resolution.width / 2),
    height: (resolution.height / 2),
  };
  const start = {
    x: centerOfDisplay.width,
    y: centerOfDisplay.height,
  };

  const lineComponent = getLineFromSource({
    line: {
      startPoint: {
        x: start.x,
        y: start.y,
      },
      endPoint: {
        x: widthAndHeight,
        y: widthAndHeight,
      },
      thickness,
    }
  });

  return Object.assign(
    {},
    lineComponent,
    {
      setup: {
        position: {
          top: 0,
          left: 0,
        },
        size: {
          width: '100%',
          height: '100%',
        }
      },
    }
  );
}
