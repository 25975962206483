import lodash from 'lodash';

/**
 * The playlist component.
 * This component acts as a placeholder for a playlist of content.
 *
 * @param {{}} playlistValues
 * @param {number=} playlistValues.contentId
 * @param {string=} playlistValues.aspectRatio
 * @param {{}=} importComponent
 * @returns {{playlist: {contentId: number, aspectRatio: string}}}
 */
export function playlistComponent(playlistValues, importComponent) {
  const safeImport = parseImportComponent(importComponent);
  const newValues = lodash.defaults(playlistValues || {}, safeImport);

  return {
    playlist: {
      contentId: newValues.contentId || null,
      aspectRatio: newValues.aspectRatio || '16:9',
    },
  };
}

/**
 * Parses a playlist component into an importable component.
 *
 * @param {{}} importComponent
 * @returns {{}}
 */
function parseImportComponent(importComponent) {
  return lodash.pickBy(importComponent || {});
}

/**
 * Gets the playlist component from the source.
 *
 * @param {{playlist: {contentId: ?number}}} item
 * @returns {{playlist: {contentId: ?number}}}
 */
export function getPlaylistFromSource(item) {
  if (!item.playlist) {
    return {};
  }

  const playlist = item.playlist;

  return playlistComponent({
    contentId: playlist.contentId,
    aspectRatio: playlist.aspectRatio,
  });
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getPlaylistForSource(entity) {
  if (!entity.has('playlist')) {
    return {};
  }

  const playlist = entity.get('playlist');

  return {
    playlist: {
      contentId: playlist.contentId || null,
      aspectRatio: playlist.aspectRatio || null,
    },
  };
}
