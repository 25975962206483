import {action, observable, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import ClipItem from '../../../../common/clipItem/ClipItem';
import FileUpload from '../../../../common/fileUpload/FileUpload';
import InfiniteScroll from '../../../../common/infiniteScroll/InfiniteScroll';
import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';
import inject from '../../../../hoc/injectHoc';
import ConfirmModal from '../../../../modals/confirm/ConfirmModal';
import FileUploadingModal from '../../../../modals/fileUploading/FileUploadingModal';
import {STATE_PENDING} from '../../../../../constants/asyncConstants';
import {PLACEABLE_IMAGE} from '../../../../../constants/libraryTypeConstants';
import {uploadContent} from '../../../../../utils/contentsHelper';

import './newClipContentList.scss';

/**
 * The NewClipContentList component.
 */
export class NewClipContentList extends React.Component {
  /**
   * Whether or not there was an upload error.
   * @type {?Error}
   */
  @observable uploadError = null;

  /**
   * Whether or not the uploading modal is open.
   * This should match up directly to whether or not any files are uploading.
   * @type {boolean}
   */
  @observable isUploadingModalOpen = false;

  /**
   * Triggered when the component reacts to MobX changes.
   */
  componentWillReact() {
    const {apiCreateContentStore} = this.props;

    if (apiCreateContentStore.uploadingState === STATE_PENDING) {
      runInAction('openUploadingModal', () => {
        this.isUploadingModalOpen = true;
      });
    }
  }

  /**
   * Uploads the selected files.
   *
   * @param {Array<{}>} files
   */
  onSelectFiles = (files) => {
    const {libraryType, sessionStore} = this.props;
    const productId = (libraryType === PLACEABLE_IMAGE) ? null : sessionStore.productId;

    uploadContent(files, libraryType, productId).catch((uploadError) => {
      runInAction('showUploadError', () => {
        this.uploadError = uploadError;
      });
    });
  };

  /**
   * Dismisses the too many error alert.
   */
  @action onDismissError = () => {
    this.uploadError = null;
  };

  /**
   * Opens the uploading modal.
   */
  @action openUploadingModal = () => {
    this.isUploadingModalOpen = true;
  };

  /**
   * Closes the uploading modal.
   */
  @action closeUploadingModal = () => {
    this.isUploadingModalOpen = false;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      activeContentStore,
      apiContentsStore,
      apiCreateContentStore,
      contentSize,
      libraryType,
      onLoadNextPage,
      onSelectContent,
      openUploadDialog,
      scrollElSelector,
    } = this.props;

    const activeContentLibrary = activeContentStore.libraries[libraryType];
    const activeContent = activeContentLibrary.content;
    const storeKey = activeContent.storeKey;

    const isWriteable = activeContentStore.isActiveLibraryWritable(libraryType);

    const pollKeys = {
      apiContentsStore: storeKey,
    };

    // This is important! It causes this to re-render when the uploadingState changes.
    apiCreateContentStore.uploadingState; // eslint-disable-line no-unused-expressions

    return (
      <div className="new-clip-content-list">
        <section className="content-row">
          {apiContentsStore.paginationCase(storeKey, {
            pre: () => (
              <p className="w24-no-results">Please select a category.</p>
            ),
            pending: () => (<LoadingIcon />),
            rejected: () => (
              <p className="w24-error">There was an error loading content for this category.</p>
            ),
            fulfilled: ({canLoadMore, fulfilled}) => {
              if (!fulfilled || !fulfilled.length) {
                return (
                  <div>
                    {(isWriteable) && (
                      <div className="clearfix">
                        <div className="content-item-card">
                          <FileUpload onDrop={this.onSelectFiles} openUploadDialog={openUploadDialog} />
                        </div>
                      </div>
                    )}

                    <div>
                      <p className="w24-no-results">No content found.</p>
                    </div>
                  </div>
                );
              }

              return (
                <InfiniteScroll
                  className="content-items-scrollable clearfix"
                  hasMore={canLoadMore}
                  initialLoad={false}
                  loadMore={onLoadNextPage}
                  pageStart={1}
                  scrollElSelector={scrollElSelector}
                  useWindow={!scrollElSelector}
                >
                  {(isWriteable) && (
                    <div className="content-item-card">
                      <FileUpload onDrop={this.onSelectFiles} openUploadDialog={openUploadDialog} />
                    </div>
                  )}

                  {fulfilled.map((content) => {
                    if (content.notContent) {
                      if (content.isPending) {
                        return (
                          <div className="content-item-card-pending" key={content.id}>
                            <LoadingIcon size="md" />
                          </div>
                        );
                      }

                      return (
                        <div className="content-item-card is-rejected" key={content.id}>
                          <div className="alert alert-warning" role="alert">
                            Could not load content for page {content.page}.
                          </div>
                        </div>
                      );
                    }

                    return (
                      <div className="content-item-card" key={content.id}>
                        <ClipItem
                          content={content}
                          contentSize={contentSize}
                          libraryType={libraryType}
                          isWriteable={isWriteable}
                          onClick={onSelectContent}
                          pollKeys={pollKeys}
                        />
                      </div>
                    );
                  })}
                </InfiniteScroll>
              );
            }
          })}
        </section>

        {(this.uploadError) && (
          <ConfirmModal
            confirmText="An error occurred while trying to upload files. One or more may not have been uploaded."
            isOpen={true}
            onComplete={this.onDismissError}
          />
        )}

        {(this.isUploadingModalOpen) && (
          <FileUploadingModal isOpen={true} onComplete={this.closeUploadingModal} />
        )}
      </div>
    );
  }
}

NewClipContentList.propTypes = {
  contentSize: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  libraryType: PropTypes.number.isRequired,
  onLoadNextPage: PropTypes.func.isRequired,
  onSelectContent: PropTypes.func.isRequired,

  activeContentStore: MobxPropTypes.observableObject,
  apiContentsStore: MobxPropTypes.observableObject,
  apiCreateContentStore: MobxPropTypes.observableObject,
  openUploadDialog: PropTypes.bool,
  scrollElSelector: PropTypes.string,
  sessionStore: MobxPropTypes.observableObject,
};

NewClipContentList.defaultProps = {
  openUploadDialog: false,
  scrollElSelector: null,
};

NewClipContentList.wrappedComponent = {};
NewClipContentList.wrappedComponent.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentsStore: MobxPropTypes.observableObject.isRequired,
  apiCreateContentStore: MobxPropTypes.observableObject.isRequired,
  sessionStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(NewClipContentList)(
  observer(NewClipContentList)
);
