import lodash from 'lodash';
import {action} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import Select from '../../../../common/select/Select';
import {actionUpdateComponent} from '../../../../../display/components/action/actionUpdateComponent';
import {ALL_TYPES, placeholderComponent} from '../../../../../display/components/type/placeholderComponent';

const options = lodash.map(ALL_TYPES, (typeName) => {
  return {label: typeName, value: typeName};
});

/**
 * The PlaceholderType component.
 *
 * @param {{}} params
 * @param {observableMap} params.entity
 * @param {GameStore} params.game
 * @returns {React.Component}
 */
export const PlaceholderType = ({entity, game}) => {
  /**
   * Updates the type in the placeholder component.
   *
   * @params {string} newType
   */
  const onChangeType = action((newType) => {
    const actionParams = {
      entityId: entity.get('id'),
    };

    const currentViewPort = entity.get('placeholder');
    const newViewPort = placeholderComponent({
      type: newType,
    }, currentViewPort);

    game.addAction(actionParams, actionUpdateComponent(newViewPort));
  });

  const placeholder = entity.get('placeholder');

  return (
    <div className="placeholder-type">
      <Select
        theme="dark"
        value={String(placeholder.type)}
        onChange={onChangeType}
        options={options}
      />
    </div>
  );
};

PlaceholderType.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: PropTypes.object.isRequired,
};

export default observer(PlaceholderType);
