import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import CategoryAddButton from './components/categoryAddButton/CategoryAddButton';
import CategoryItem from './components/categoryItem/CategoryItem';
import LibraryDropdown from './components/libraryDropdown/LibraryDropdown';
import SearchForm from '../searchForm/SearchForm';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import inject from '../../hoc/injectHoc';
import SelectCategoryModal from '../../modals/selectCategory/SelectCategoryModal';

import './contentDirectory.scss';

/**
 * The ContentDirectory component.
 */
export class ContentDirectory extends React.Component {
  /**
   * Event handler when the search form changes
   *
   * @param {{}} changeEvent
   */
  searchFormChange = (changeEvent) => {
    const {libraryType, activeContentStore} = this.props;
    const searchFilter = changeEvent.target.value;

    activeContentStore.setCategorySearchFilter(libraryType, searchFilter);
  };

  /**
   * Event handler when the search form is submitted
   *
   * @param {{}} submitEvent
   */
  searchFormSubmit = (submitEvent) => {
    submitEvent.preventDefault();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      activeContentStore,
      allowNoCategory,
      apiContentLibrariesStore,
      apiCategoriesStore,
      categoryEditStore,
      libraryType,
      onLibrarySelected,
      skipCategoryIds,
      skipSubCategories,
    } = this.props;

    const {moveCategory} = categoryEditStore;

    const activeContentLibrary = activeContentStore.libraries[libraryType];
    const activeCategory = activeContentLibrary.category;
    const categorySearchText = activeCategory.searchFilter;

    const library = apiContentLibrariesStore.getLibrary(
      activeContentLibrary.storeKey,
      activeContentLibrary.id,
    );
    const libraryName = library ? library.libraryName : '';

    const allowCategoryEdit = (
      this.props.allowCategoryEdit
      && activeContentStore.isActiveLibraryWritable(libraryType)
    );
    const safeSkipCategoryIds = skipCategoryIds || [];

    return (
      <div className="content-directory">
        <LibraryDropdown
          libraryType={libraryType}
          activeContentStore={activeContentStore}
          onLibrarySelected={onLibrarySelected}
        />

        <div className="content-directory-search-wrapper">
          <div className="search-form-wrapper">
            <SearchForm
              placeholder={activeContentLibrary.id ? 'Search For Library...' : 'Choose a Library'}
              onChange={this.searchFormChange}
              onSubmit={this.searchFormSubmit}
              searchText={categorySearchText}
              disabled={!activeContentLibrary.id}
            />
          </div>

          {(allowCategoryEdit) && (
            <div className="add-category-wrapper">
              <CategoryAddButton
                libraryId={(library) ? library.id : null}
                libraryName={libraryName}
              />
            </div>
          )}
        </div>

        <ul className="content-directory-listing">
          {(allowNoCategory && libraryName) && (
            <li className="category-item-top-item">
              <div className="category-item-top-item-text">
                <button
                  type="button"
                  className={classNames('category-item-top-item-link', {active: activeCategory.id === false})}
                  onClick={() => this.props.onCategorySelected(false)}
                >
                  <i className="fad fad-folder category-item-top-item-icon" />
                  {libraryName}
                </button>
              </div>
            </li>
          )}

          {activeCategory.storeKey && apiCategoriesStore.case(activeCategory.storeKey, {
            pending: () => (<LoadingIcon size="sm" />),
            rejected: () => (
              <li className="w24-error small-text">There was an error loading the categories.</li>
            ),
            fulfilled: (categories) => {
              if (!categories) {
                return null;
              }

              if (categories.length === 0) {
                return <li className="w24-no-results small-text">No categories found.</li>;
              }

              return categories.map((category) => {
                if (safeSkipCategoryIds.indexOf(category.id) > -1) {
                  return null;
                }

                return (
                  <CategoryItem
                    key={category.id}
                    libraryType={libraryType}
                    activeContentStore={this.props.activeContentStore}
                    onCategorySelected={this.props.onCategorySelected}
                    category={category}
                    allowCategoryEdit={allowCategoryEdit}
                    skipSubCategories={skipSubCategories}
                  />
                );
              });
            }
          })}
        </ul>

        {(allowCategoryEdit) && (
          <SelectCategoryModal
            allowNoCategory={true}
            title={(moveCategory) ? `Move '${moveCategory.categoryName}' Category To` : 'Move Category To'}
            skipCategoryIds={(moveCategory) ? [moveCategory.id] : null}
            skipSubCategories={true}
            isOpen={Boolean(moveCategory)}
            libraryType={libraryType}
            onComplete={(chosen) => categoryEditStore.onMoveCategory(chosen)}
          />
        )}
      </div>
    );
  }
}

ContentDirectory.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
  libraryType: PropTypes.number.isRequired,
  onCategorySelected: PropTypes.func.isRequired,

  allowCategoryEdit: PropTypes.bool,
  allowNoCategory: PropTypes.bool,
  apiCategoriesStore: MobxPropTypes.observableObject,
  apiContentLibrariesStore: MobxPropTypes.observableObject,
  categoryEditStore: MobxPropTypes.observableObject,
  onLibrarySelected: PropTypes.func,
  skipCategoryIds: PropTypes.array,
  skipSubCategories: PropTypes.bool,
};

ContentDirectory.defaultProps = {
  allowCategoryEdit: false,
  allowNoCategory: false,
  skipCategoryIds: null,
  skipSubCategories: false,
};

ContentDirectory.wrappedComponent = {};
ContentDirectory.wrappedComponent.propTypes = {
  apiCategoriesStore: MobxPropTypes.observableObject.isRequired,
  apiContentLibrariesStore: MobxPropTypes.observableObject.isRequired,
  categoryEditStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ContentDirectory)(
  observer(ContentDirectory),
);
