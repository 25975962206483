import lodash from 'lodash';
import {action, computed, observable, transaction} from 'mobx';

import {GameCheckpointStore} from './gameCheckpointStore';
import {cloneEntity, getActionEntity, getEntityFromSourceItem} from '../../display/ecs/entityHelper';

/**
 * The minimum fps value.
 * @const {number}
 */
const MIN_FPS = 10;

/**
 * The maximum fps value.
 * @const {number}
 */
const MAX_FPS = 60;

/**
 * The default fps value.
 * @const {number}
 */
const DEFAULT_FPS = 60;

/**
 * Indicates this game is rendering/editing an image.
 * This will remove the timeline.
 *
 * @const {string}
 */
export const GAME_TYPE_IMAGE = 'image';

/**
 * Indicates this game is rendering/editing a video.
 * This will add in the timeline.
 *
 * @const {string}
 */
export const GAME_TYPE_VIDEO = 'video';

/**
 * The game store.
 */
export class GameStore {
  /**
   * Prevents this store from logging in the mobx logger.
   *
   * @type {{enabled: boolean}}
   */
  static mobxLoggerConfig = {
    enabled: false
  };

  /**
   * The game type, usually image or video.
   *
   * @type {string}
   */
  type = 'video';

  /**
   * Whether or not the content is a layout.
   *
   * @type {boolean}
   */
  isLayout = false;

  /**
   * The frames per second for the game.
   *
   * @type {number}
   */
  fps = DEFAULT_FPS;

  /**
   * Whether or not the game is playing during a render process.
   *
   * @type {boolean}
   */
  isRender = false;

  /**
   * The time (in milliseconds) when the game will finish.
   *
   * @type {number}
   */
  @observable endTime = null;

  /**
   * The resolution (width and height) of the game.
   *
   * @type {{width: number, height: number}}
   */
  @observable resolution = {width: 0, height: 0};

  /**
   * The list of systems.
   *
   * @type {Array.<{}>}
   */
  systems = [];

  /**
   * The list of actions.
   *
   * @type {Array.<{}>}
   */
  actions = [];

  /**
   * The list of entities.
   *
   * @type {ObservableArray}
   */
  @observable entities = [];

  /**
   * The game history.
   *
   * @type {?GameHistoryStore}
   */
  @observable history = null;

  /**
   * The game checkpoint store.
   *
   * @type {?GameCheckpointStore}
   */
  @observable checkpointStore = new GameCheckpointStore();

  /**
   * The game timer.
   *
   * @type {?GameTimerStore}
   */
  @observable timer = null;

  /**
   * The feed store.
   *
   * @type {?GameFeedStore}
   */
  @observable feed = null;

  /**
   * @constructor
   * @param {{type: string, endTime: number, fps: number, resolution: {width: number, height: number}}} gameConfig
   */
  constructor(gameConfig) {
    transaction(() => {
      this.type = gameConfig.type || GAME_TYPE_VIDEO;
      this.isLayout = Boolean(gameConfig.isLayout);
      this.fps = gameConfig.fps || DEFAULT_FPS;

      if (gameConfig.endTime !== undefined) {
        this.endTime = gameConfig.endTime;
      }

      if (gameConfig.resolution) {
        if (gameConfig.resolution.width) {
          this.resolution.width = gameConfig.resolution.width;
        }
        if (gameConfig.resolution.height) {
          this.resolution.height = gameConfig.resolution.height;
        }
      }
    });
  }

  /**
   * Sets whether or not the game is running in the render process.
   *
   * @param {boolean} isRender
   */
  setIsRender(isRender) {
    this.isRender = Boolean(isRender);
  }

  /**
   * Gets whether or not the game should have a time line.
   *
   * @returns {boolean}
   */
  hasTimeLine() {
    return (this.type !== GAME_TYPE_IMAGE);
  }

  /**
   * Gets an entity by the entity id.
   *
   * @param {string} entityId
   * @returns {{id: string}}
   */
  getEntity(entityId) {
    return lodash.find(this.entities, (entity) => {
      return (entity.get('id') === String(entityId));
    });
  }

  /**
   * Gets the first active entity.
   *
   * @returns {{id: string}}
   */
  @computed get activeEntity() {
    return lodash.find(this.entities, (entity) => {
      return (entity.has('interaction') && entity.get('interaction').isActive);
    });
  }

  /**
   * Gets all the active entities.
   *
   * @returns {{id: string}}
   */
  @computed get allActiveEntities() {
    return lodash.filter(this.entities, (entity) => {
      return (entity.has('interaction') && entity.get('interaction').isActive);
    });
  }

  /**
   * Adds an entity to the game.
   *
   * @param {{}} sourceEntity
   * @returns {ObservableMap}
   */
  @action addSourceEntity(sourceEntity) {
    const newOrder = this.entities.length;
    const entity = getEntityFromSourceItem(sourceEntity, newOrder, this);

    return this.addEntity(entity, true);
  }

  /**
   * Adds an entity to the game.
   *
   * @param {{id: string}} entity
   * @param {boolean=} makeObservable
   * @param {string=} observableName
   * @returns {ObservableArray}
   */
  @action addEntity(entity, makeObservable, observableName) {
    let newEntity = entity;
    if (makeObservable) {
      const newOrder = this.entities.length;
      const safeName = observableName || `entity-${newOrder}__${entity.element}`;
      newEntity = observable.map(entity, safeName);
    }

    this.entities.push(newEntity);

    return newEntity;
  }

  /**
   * Duplicates the given entity.
   *
   * @param {string|{id: string}} entityOrId
   * @returns {{id: string}}}
   */
  duplicateEntity(entityOrId) {
    let toCopyEntity = entityOrId;
    if (typeof entityOrId === 'string') {
      toCopyEntity = this.getEntity(entityOrId);
    }

    if (!toCopyEntity) {
      return null;
    }

    const newEntity = cloneEntity(toCopyEntity);
    this.addEntity(newEntity, true);

    return newEntity;
  }

  /**
   * Removes an entity from the game.
   *
   * @param {string|{id: string}} entityOrId
   */
  @action removeEntity(entityOrId) {
    let toRemoveEntity = entityOrId;
    if (typeof entityOrId === 'string') {
      toRemoveEntity = this.getEntity(entityOrId);
    }

    if (!toRemoveEntity) {
      return;
    }

    // Remove is a special observable array function.
    // @see {@url https://mobx.js.org/refguide/array.html}
    this.entities.remove(toRemoveEntity);
  }

  /**
   * Clears all entities from the game.
   */
  @action removeAllEntities() {
    // Clear is a special observable array function.
    // @see {@url https://mobx.js.org/refguide/array.html}
    this.entities.clear();
  }

  /**
   * Adds an action to the game.
   *
   * @param {{}} actionParams
   * @param {Object.<string, {}>} components
   */
  @action addAction(actionParams, components) {
    const entity = getActionEntity(actionParams, components);
    const newOrder = this.actions.length;

    this.actions.push(observable.map(entity, `action-${newOrder}`));
  }

  /**
   * Clears all actions from the game.
   */
  @action removeAllActions() {
    if (this.actions.length) {
      this.actions = [];
    }
  }

  /**
   * Gets a system by system name.
   *
   * @param {string} systemName
   * @returns {{name: string, update: function}}
   */
  getSystem(systemName) {
    return lodash.find(this.systems, {name: systemName});
  }

  /**
   * Adds a system to the game.
   *
   * @param {{}} system
   * @param {number} priority
   * @throws {Error} If priority is undefined.
   */
  @action addSystem(system, priority) {
    if (priority === undefined) {
      throw new Error('A priority is required when adding a system to the game.');
    }

    system.priority = parseInt(priority, 10);

    if (system.attach) {
      system.attach();
    }

    const newIndex = lodash.sortedIndexBy(this.systems, system, 'priority');
    this.systems.splice(newIndex, 0, system);
  }

  /**
   * Removes a system from the game.
   *
   * @param {string|{name: string}} systemOrName
   */
  @action removeSystem(systemOrName) {
    let index = -1;
    if (typeof systemOrName === 'string') {
      index = lodash.findIndex(this.systems, {name: systemOrName});
    } else {
      index = this.systems.indexOf(systemOrName);
    }

    if (index < 0) {
      return;
    }

    const system = this.systems[index];
    this.systems.splice(index, 1);

    if (system && system.detach) {
      system.detach();
    }
  }

  /**
   * Removes all systems from the game.
   */
  @action removeAllSystems() {
    this.systems.forEach(this.removeSystem);
  }

  /**
   * Adds an undo point by recording the current time and entities.
   *
   * @param {boolean=} isFromActions
   */
  @action addUndoPoint(isFromActions) {
    if (!this.history) {
      return;
    }

    if (isFromActions) {
      let skipHistory = true;
      this.actions.forEach((actionEntity) => {
        const actionComponent = actionEntity.get('action');
        if (!actionComponent.skipHistory) {
          skipHistory = false;
        }
      });

      if (skipHistory) {
        return;
      }
    }

    this.history.addPoint(this);
  }

  /**
   * Undoes the changes to the last history point.
   *
   * @returns {boolean}
   */
  @action undoToLastPoint() {
    if (!this.history) {
      return false;
    }

    this.history.startProcess(this);

    const previousPoint = this.history.popUndoPoint();
    if (!previousPoint) {
      return false;
    }

    this.resetAfterUndoRedo(previousPoint);

    return true;
  }

  /**
   * Restores the last recorded checkpoint.
   * This is designed to be used if the user was unable to save.
   *
   * @param {number=} contentId
   * @returns {boolean}
   */
  @action restoreCheckpoint(contentId) {
    if (!this.history) {
      return false;
    }

    const checkpoint = this.checkpointStore.getCheckpoint();
    if (!checkpoint) {
      return false;
    }

    // If content id was provided and it does not match the checkpoint content id, then do nothing.
    if (contentId && String(contentId) !== String(checkpoint.contentId)) {
      return false;
    }

    this.resetAfterUndoRedo(checkpoint);

    this.checkpointStore.clearCheckpoint();

    return true;
  }

  /**
   * Redoes the last recorded undo point.
   *
   * @returns {boolean}
   */
  @action redoLastUndo() {
    if (!this.history) {
      return false;
    }

    const redoPoint = this.history.popRedoHistory();
    if (!redoPoint) {
      return false;
    }

    this.resetAfterUndoRedo(redoPoint);

    return true;
  }

  /**
   * Resets the game entities and time by history point.
   *
   * @param {{gameTime: number, entities: Array.<{}>}} historyPoint
   */
  @action resetAfterUndoRedo(historyPoint) {
    this.timer.pause();

    this.removeAllEntities();
    historyPoint.entities.forEach((sourceEntity) => {
      this.addSourceEntity(sourceEntity);
    });

    const secondsToMilliseconds = 1000;
    const msPerFrame = Math.ceil(secondsToMilliseconds / this.fps);

    // Wait for the next frame to update the timer, otherwise the entities will not setup correctly.
    setTimeout(() => {
      this.timer.setTime(historyPoint.gameTime);
    }, msPerFrame);
  }

  /**
   * Sets the game feed store.
   *
   * @param {GameFeedStore} gameFeedStore
   */
  @action setFeed(gameFeedStore) {
    this.feed = gameFeedStore;
  }

  /**
   * Sets the game history object.
   *
   * @param {GameHistoryStore} gameHistory
   */
  @action setHistory(gameHistory) {
    this.history = gameHistory;
  }

  /**
   * Sets the game timer object.
   *
   * @param {GameTimerStore} gameTimer
   */
  @action setTimer(gameTimer) {
    this.timer = gameTimer;
  }

  /**
   * Sets the fps for the game.
   *
   * @param {number} newFps
   */
  @action setFps(newFps) {
    const safeFps = parseInt(newFps, 10);
    if (!safeFps) {
      throw new Error('Invalid fps given to game.setFps -- The value must a number.');
    } else if (this.fps < MIN_FPS || this.fps > MAX_FPS) {
      throw new Error('Invalid fps given to game.setFps -- The value must be between 10 and 60 (inclusive).');
    }

    this.fps = safeFps;
  }

  /**
   * Updates the game by calling all the systems.
   *
   * @param {number} time
   */
  @action update(time) {
    const updateHistory = (this.actions.length > 0);
    if (updateHistory) {
      this.addUndoPoint(true);
    }

    this.systems.forEach((system) => {
      if (system.runActions) {
        system.runActions(this.actions.slice(0), this.entities, time);
      }
    });
    this.systems.forEach((system) => {
      if (system.update) {
        system.update(this.entities, time, {timer: this.timer});
      }
    });

    if (updateHistory) {
      this.checkpointStore.updateCheckpoint(this);
    }
  }
}

/**
 * Parses game config into from the source file.
 *
 * @param {{type: string, endTime: number, resolution: {height: number, width: number}}} source
 * @returns {{type: string, endTime: number, fps: number, resolution: {height: number, width: number}}}
 */
export function parseGameConfigFromSource(source) {
  if (!source.endTime) {
    if (!source.type || source.type !== GAME_TYPE_IMAGE) {
      throw new Error('Invalid source file -- An endTime is required.');
    }
  } else if (!source.resolution) {
    throw new Error('Invalid source file -- A resolution object is required.');
  } else if (!source.resolution.height || !source.resolution.width) {
    throw new Error('Invalid source file -- The resolution must have a width and height.');
  }

  return {
    type: source.type || null,
    isLayout: Boolean(source.isLayout),
    endTime: source.endTime || 0,
    fps: DEFAULT_FPS,
    resolution: source.resolution,
  };
}

// Tells the system not to use this as an injectable store.
export const doNotInject = true;
