import classNames from 'classnames';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {STATE_PAUSED, STATE_PLAYING, STATE_STOPPED} from '../../../constants/displayItemConstants';
import {getMainUrlByFileId} from '../../../utils/contentsHelper';

/**
 * The amount of time that is allowed to drift before the viewport currentTime will be corrected.
 * @const {number}
 */
const ALLOWED_TIME_DRIFT = 99;

/**
 * The time conversion from seconds to milliseconds.
 * @const {number}
 */
const SECONDS_TO_MILLSECONDS = 1000;

/**
 * The DisplayViewportVideo component.
 */
export class DisplayViewportVideo extends React.Component {
  /**
   * The current playback state of the viewport element.
   */
  @observable viewportState = STATE_STOPPED;

  /**
   * A reference to the viewport player element.
   *
   * @type {{current: HTMLElement}}
   */
  playerRef = React.createRef();

  /**
   * Triggered when the observed items (viewport) in the component update.
   */
  componentWillReact = () => {
    const {entity} = this.props;
    const viewport = entity.get('viewport');
    const viewportVideoEl = this.playerRef.current;
    if (!viewportVideoEl) {
      return;
    }

    this.updateViewportTime(viewport, viewportVideoEl);
    this.updateViewportState(viewport, viewportVideoEl);
  };

  /**
   * Updates the viewport element time if it drifts too far from the viewport entity time.
   *
   * @param {{time: number}} viewport
   * @param {HTMLElement} viewportVideoEl
   */
  updateViewportTime = (viewport, viewportVideoEl) => {
    if (!viewport || !viewport.playback) {
      return;
    }

    const driftAmount = Math.abs((viewportVideoEl.currentTime * SECONDS_TO_MILLSECONDS) - viewport.playback.time);

    // If the viewport time and the viewport element's time has drifted too much, then force the viewport element time.
    if (driftAmount > ALLOWED_TIME_DRIFT) {
      viewportVideoEl.currentTime = viewport.playback.time / SECONDS_TO_MILLSECONDS;
    }
  };

  /**
   * Updates the state (paused/playing/stopped) of the viewport from the viewport entity state.
   *
   * @param {{state: string}} viewport
   * @param {HTMLElement} viewportVideoEl
   */
  @action updateViewportState = (viewport, viewportVideoEl) => {
    if (!viewport || !viewport.playback) {
      return;
    }

    const currentState = this.viewportState;
    const entityState = viewport.playback.state;

    // If the state of the viewport has not changed, then we don't need to do anything.
    if (currentState === entityState) {
      return;
    }

    if (currentState === STATE_STOPPED) {
      if (entityState === STATE_PLAYING) {
        const playResponse = viewportVideoEl.play();
        if (playResponse.catch) {
          playResponse.catch(() => {
            // Do nothing but catching here prevent an annoying error message from being logged.
          });
        }
      } else if (entityState === STATE_PAUSED) {
        viewportVideoEl.pause();
      }
    } else if (currentState === STATE_PLAYING) {
      viewportVideoEl.pause();

      if (entityState === STATE_STOPPED) {
        viewportVideoEl.currentTime = 0;
      }
    } else if (currentState === STATE_PAUSED) {
      if (entityState === STATE_STOPPED) {
        viewportVideoEl.currentTime = 0;
      } else if (entityState === STATE_PLAYING) {
        const playResponse = viewportVideoEl.play();
        if (playResponse.catch) {
          playResponse.catch(() => {
            // Do nothing but catching here prevent an annoying error message from being logged.
          });
        }
      }
    }

    this.viewportState = entityState;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {style, entity, className} = this.props;

    const entityId = entity.get('id');
    const viewport = entity.get('viewport');
    const {file, playback} = viewport;
    if (!file.id || !playback) {
      return null;
    }

    let viewportType = String(file.type || '').trim();
    if (!viewportType || ['mp4', 'webm', 'ogg'].indexOf(viewportType) === -1) {
      viewportType = 'video/mp4';
    }

    let viewportSource = getMainUrlByFileId(file.id);

    const videoStyles = {
      style: {objectFit: 'contain', width: '100%', height: '100%'},
      'data-object-fit': 'contain'
    };
    if (!viewport.maintainAspectRatio) {
      videoStyles.style.objectFit = 'fill';
      videoStyles['data-object-fit'] = 'fill';
    }

    /*
     * This is very important!
     * MobX won't fire the componentWillReact method when the state or time changes
     * unless it is referenced in this render().
     */
    playback.state; // eslint-disable-line no-unused-expressions
    playback.time; // eslint-disable-line no-unused-expressions

    return (
      <div id={entityId} className={classNames('display-viewport-video', className)} style={style}>
        <video
          id="display-viewport-video-el"
          height={style.height}
          width={style.width}
          loop={false}
          preload="auto"
          ref={this.playerRef}
          {...videoStyles}
        >
          <source
            src={viewportSource}
            type={viewportType}
          />

          Your browser is not supported for playing this video. Please use the latest Chrome or Firefox browser.
        </video>
      </div>
    );
  }
}

DisplayViewportVideo.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  style: PropTypes.object.isRequired,

  className: PropTypes.string,
};

export default observer(DisplayViewportVideo);
