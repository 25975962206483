import classNames from 'classnames';
import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import React from 'react';

import EditTimelineControls from '../editTimeline/EditTimelineControls';
import Select from '../../common/select/Select';
import {feedImageComponent} from '../../../display/components/type/feedImageComponent';
import {actionUpdateComponent} from '../../../display/components/action/actionUpdateComponent';
import {cropComponent} from '../../../display/components/common/cropComponent';
import {interactionComponent} from '../../../display/components/common/interactionComponent';
import {cropShapeComponent, CIRCLE, ELLIPSE, RECTANGLE, TRIANGLE}
  from '../../../display/components/common/cropShapeComponent';
import {getMainUrlByFileId, getStaticFileUrl} from '../../../utils/contentsHelper';
import {FEED_TYPE_API, FEED_TYPE_CUSTOM} from '../../../stores/game/gameFeedStore';

import './editFeedImageControls.scss';

/**
 * The EditFeedImageControls component.
 */
export class EditFeedImageControls extends React.Component {
  /**
   * Whether or not the image choose/upload modal is open.
   *
   * @type {boolean}
   */
  @observable isModalOpen = false;

  /**
   * Target Feed Path
   *
   * @type {string}
   */
  @observable targetFeedPath = '';

  /**
   * Whether or not the crop dropdown is open.
   *
   * @type {boolean}
   */
  @observable isCropDropdownOpen = false;

  /**
   * Triggered when the component first mounts to the page.
   */
  @action componentDidMount() {
    const {entity} = this.props;
    const image = entity.get('feedImage');
    const {source} = image;
    this.targetFeedPath = source;
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.entity && this.props.entity && prevProps.entity.get('id') === this.props.entity.get('id')) {
      return;
    }

    let feedPath = '';
    if (this.props.entity && this.props.entity.has('feedImage')) {
      feedPath = this.props.entity.get('feedImage').source || '';
    }

    this.setTargetFeedPath(feedPath);
  }

  /**
   * Updates the source in the feedText component.
   *
   * @param {string=} newValue
   */
  @action onChangeSourceSelection = (newValue) => {
    const {game, entity} = this.props;

    const currentFeedImage = entity.get('feedImage');
    const actionParams = {entityId: entity.get('id')};

    const newSource = (newValue) ? newValue : currentFeedImage.source;

    game.addAction(actionParams, actionUpdateComponent(
      feedImageComponent({source: newSource}, currentFeedImage)
    ));
  };

  /**
   * Updates targetFeedPath when the value changes.
   *
   * @param {{}} changeEvent
   */
  @action targetFeedPathUpdate = (changeEvent) => {
    this.targetFeedPath = changeEvent.target.value;
  };

  /**
   * Builds a new feed path state
   *
   * @param {string} feedPath
   */
  @action setTargetFeedPath = (feedPath) => {
    this.targetFeedPath = feedPath;
  };

  /**
   * Activates or deactivates the crop tool.
   *
   * @param {string} shape
   * @returns {function}
   */
  onClickCrop = (shape) => {
    return action(() => {
      const {entity, game} = this.props;

      const currentInteraction = entity.get('interaction');
      if (!currentInteraction.isActive) {
        return;
      }

      const newIsCropping = (!shape) ? !currentInteraction.isCropping : true;

      const currentImage = entity.get('image');
      const actionParams = {
        entityId: entity.get('id'),
      };

      let components = interactionComponent(true, newIsCropping);

      if (!entity.get('crop')) {
        components = {
          ...components,
          ...cropComponent(currentImage.top, currentImage.left, currentImage.width, currentImage.height),
        };
      }

      if (shape) {
        components = {
          ...components,
          ...cropShapeComponent(shape)
        };
      }

      game.addAction(actionParams, actionUpdateComponent(components));
    });
  };

  /**
   * Removes the cropping on the image.
   */
  onRemoveCrop = () => {
    const {entity, game} = this.props;

    const currentInteraction = entity.get('interaction');
    if (!currentInteraction.isActive) {
      return;
    }

    const currentImage = entity.get('image');
    const actionParams = {
      entityId: entity.get('id'),
    };

    let components = {
      ...interactionComponent(true, false),
      ...cropComponent(currentImage.top, currentImage.left, currentImage.width, currentImage.height),
      ...cropShapeComponent(RECTANGLE),
    };

    game.addAction(actionParams, actionUpdateComponent(components));
  };

  /**
   * Toggles the crop dropdown.
   */
  @action toggleCropDropdown = () => {
    this.isCropDropdownOpen = !this.isCropDropdownOpen;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;
    const {feedSummary, feedType} = game.feed;

    const feedImage = entity.get('feedImage');
    const {source} = feedImage;

    const feedContent = game.feed.getFeedData(feedImage, {
      replace: false,
      default: '',
    })[0];

    let imageUrl = null;
    if (feedContent && source === 'ContentFileId') {
      imageUrl = getMainUrlByFileId(feedContent, false);
    } else if (feedContent) {
      imageUrl = getStaticFileUrl(feedContent, true);
    }

    let imageContent = null;
    if (imageUrl) {
      imageContent = <img className="image-thumbnail" src={imageUrl} />;
    }

    let isLocked = false;
    let isCropLocked = false;
    if (entity.has('locked')) {
      const entityLock = entity.get('locked');
      if (lodash.includes(entityLock, 'time')) {
        // If the entity has time locked, then do not allow them to adjust anything.
        isLocked = true;
      }
      if (lodash.includes(entityLock, 'position') || lodash.includes(entityLock, 'size')) {
        isCropLocked = true;
      }
    }

    const interaction = entity.get('interaction');
    const cropButtonClasses = {
      active: (interaction) ? interaction.isCropping : false,
    };
    const options = feedSummary.images.map((value) => ({label: value, value: value}));
    const showAdvanced = (feedType === FEED_TYPE_API || feedType === FEED_TYPE_CUSTOM);

    return (
      <div className="edit-feed-image-controls">
        <div className="image-group">
          <h5 className="sidebar-title">Image</h5>
          {(isLocked) ? (
            <div className="can-not-edit">
              <span className="locked-text">
                <i className="fa fa-lock" />
                {' '}
                <span>Image Locked</span>
              </span>
            </div>
          ) : (
            <div className="image-selector">
              <div className="image-wrapper">
                {imageContent}
              </div>
              <div className="row">
                <div className="col">
                  {(showAdvanced) ? (
                    <div className="form-group">
                      <label htmlFor="choose-target">Feed Source Path</label>
                      <input
                        className="form-control form-control-sm"
                        id="choose-target"
                        value={this.targetFeedPath}
                        onChange={this.targetFeedPathUpdate}
                        onBlur={(changeEvent) => this.onChangeSourceSelection(changeEvent.target.value)}
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <label htmlFor="choose-source-label">Choose Feed Source</label>
                      <Select
                        theme="dark"
                        value={source}
                        onChange={this.onChangeSourceSelection}
                        options={options}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {(!isCropLocked) && (
          <div className="text-style control-group">
            <div className="group-header">
              <span className="group-header-label">Tools</span>
            </div>
            <div className="group-controls">
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <div><label>Crop</label></div>
                    <div className="align-buttons form-buttons">
                      <div className="btn-group">
                        <button
                          type="button"
                          className={classNames('btn btn-sm btn-dark form-button', cropButtonClasses)}
                          onClick={this.onClickCrop(null)}
                        >
                          <i className="fa fa-crop" />
                        </button>
                        <Dropdown isOpen={this.isCropDropdownOpen} toggle={this.toggleCropDropdown}>
                          <DropdownToggle
                            className={classNames('btn btn-sm btn-dark form-button crop-dropdown')}
                          >
                            <i className="fad fad-caret-down" />
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem
                              onClick={this.onClickCrop(RECTANGLE)}
                              toggle={false}
                            >Rectangle</DropdownItem>
                            <DropdownItem
                              onClick={this.onClickCrop(ELLIPSE)}
                              toggle={false}
                            >Ellipse</DropdownItem>
                            <DropdownItem
                              onClick={this.onClickCrop(CIRCLE)}
                              toggle={false}
                            >Circle</DropdownItem>
                            <DropdownItem
                              onClick={this.onClickCrop(TRIANGLE)}
                              toggle={false}
                            >Triangle</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <div><label>Reset Crop</label></div>
                    <div className="align-buttons form-buttons">
                      <div className="btn-group">
                        <button
                          type="button"
                          className={classNames('btn btn-sm btn-dark form-button')}
                          onClick={this.onRemoveCrop}
                        >
                          <i className="fa fa-ban" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <EditTimelineControls entity={entity} game={game} />
      </div>
    );
  }
}

EditFeedImageControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditFeedImageControls);
