import {action} from 'mobx';

import {STATE_FULFILLED, STATE_REJECTED} from '../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN} from '../../constants/storeConstants';
import serverApi from '../../utils/serverApi';
import SingleResourceBaseStore from './common/apiSingleResourceBaseStore';

/**
 * The client store.
 */
class ClientStore extends SingleResourceBaseStore {
  /**
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Fetches client from the server.
   *
   * @param {number} rawClientId
   */
  @action fetchClientById(rawClientId) {
    const clientId = String(rawClientId);

    if (!this.isResourceAvailable(clientId)) {
      serverApi.clientGetById(clientId).then(
        action('getClientSuccess', (client) => {
          this.resourcesById.set(clientId, {
            [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
            resource: client,
            error: null,
          });
          this.state = STATE_FULFILLED;
        }),
        action('getClientError', (error) => {
          this.resourcesById.set(clientId, {
            [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
            resource: null,
            error,
          });
          this.state = STATE_REJECTED;
        })
      );
    }
  }
}

export default new ClientStore();
