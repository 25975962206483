import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityActivateHoc from '../../hoc/entityActivateHoc';

/**
 * The DisplayPlaceholder component.
 */
export class DisplayPlaceholder extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, style} = this.props;

    const type = entity.get('placeholder').type;

    style.backgroundColor = 'rgb(120, 120, 120)';
    style.border = '3px dashed white';

    // style.mixBlendMode = 'exclusion';

    return (
      <div className="display-placeholder" style={style}>
        <span>{type} Placeholder</span>
      </div>
    );
  }
}

DisplayPlaceholder.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,
};

export default entityActivateHoc(
  observer(DisplayPlaceholder)
);
