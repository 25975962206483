import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import AlignEntitiesButtons from './components/alignEntitiesButtons/AlignEntitiesButtons';
import GroupEntitiesButtons from './components/groupEntitiesButtons/GroupEntitiesButtons';
import EditTransitionControl from '../editTransition/EditTransitionControl';

import './editMultipleControls.scss';

/**
 * The EditMultipleControls component.
 */
export class EditMultipleControls extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entities, game} = this.props;

    const hasTimeLine = game.hasTimeLine();

    return (
      <div className="edit-multiple-controls">
        <div className="multiple-group">
          <h5 className="sidebar-title">Multiple Selected</h5>
          <div className="group-header">
            <span className="group-header-label">Alignment</span>
          </div>
          <div className="group-controls">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="alignment-entities">Align</label>
                  <AlignEntitiesButtons
                    id="alignment-entities"
                    game={game}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <GroupEntitiesButtons game={game} />

        {(hasTimeLine) && (
          <div>
            <div className="group-header">
              <span className="group-header-label">Transitions</span>
            </div>
            <div className="group-controls">
              <div className="row">
                <div className="col">
                  <EditTransitionControl entities={entities} game={game} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

EditMultipleControls.propTypes = {
  entities: PropTypes.arrayOf(MobxPropTypes.observableMap).isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditMultipleControls);
