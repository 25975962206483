import classNames from 'classnames';
import lodash from 'lodash';
import {toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import {actionUpdateComponent} from '../../../../display/components/action/actionUpdateComponent';
import {lineComponent} from '../../../../display/components/type/lineComponent';
import {between} from '../../../../utils/mathHelper';

/**
 * A full radian boundary.
 * @const {number}
 */
const FULL_RAD = Math.PI / 2;

/**
 * Half of a radian boundary.
 * @const {number}
 */
const HALF_RAD = Math.PI / 4; // eslint-disable-line no-magic-numbers

/**
 * Negative full radian boundary.
 * @const {number}
 */
const NEG_FULL_RAD = 0 - FULL_RAD;

/**
 * Negative half of a radian boundary.
 * @const {number}
 */
const NEG_HALF_RAD = 0 - HALF_RAD;

/**
 * The degrees for the angle for a diagonal line.
 * @const {number}
 */
const DIAGONAL_DEGREES = 45;

/**
 * The list of radians that make up a half circle rotation.
 * @const {number[]}
 */
const ROTATION_RADIANS = [
  [NEG_FULL_RAD, NEG_HALF_RAD],
  [NEG_HALF_RAD, 0],
  [0, HALF_RAD],
  [HALF_RAD, FULL_RAD],
];

/**
 * Snaps the line to an axis or half-axis.
 *
 * @param {ObservableMap} entity
 * @param {GameStore} game
 */
function snapLineToAxis(entity, game) {
  const currentLine = entity.get('line');

  const startPoint = Object.assign({}, currentLine.startPoint);
  const endPoint = Object.assign({}, currentLine.endPoint);
  const resolution = game.resolution;

  const axisLengths = {
    x: (endPoint.x - startPoint.x),
    y: (endPoint.y - startPoint.y),
  };
  const centerPoint = {
    x: startPoint.x + (axisLengths.x / 2),
    y: startPoint.y + (axisLengths.y / 2),
  };
  const angleInRadians = 0 - Math.atan(axisLengths.x / axisLengths.y);

  let newRads = null;
  let skipChanges = false;
  lodash.forEach(ROTATION_RADIANS, ([startRads, endRads]) => {
    if (angleInRadians < startRads || angleInRadians > endRads) {
      return true;
    } else if (angleInRadians === startRads || angleInRadians === endRads) {
      skipChanges = true;
      return false;
    }

    if (Math.abs(angleInRadians - startRads) < Math.abs(endRads - angleInRadians)) {
      newRads = startRads;
    } else {
      newRads = endRads;
    }
    return false;
  });

  if (skipChanges) {
    // The line already matches an axis or half-axis, so return.
    return;
  }

  const {newStartPoint, newEndPoint} = getPointsForNewRadians(newRads, centerPoint, axisLengths);

  const componentUpdates = actionUpdateComponent(lineComponent(
    {x: between(newStartPoint.x, 0, resolution.width), y: between(newStartPoint.y, 0, resolution.height)},
    {x: between(newEndPoint.x, 0, resolution.width), y: between(newEndPoint.y, 0, resolution.height)},
    currentLine.thickness,
    toJS(currentLine.style),
  ));

  game.addAction({entityId: entity.get('id')}, componentUpdates);
}

/**
 * Gets the new points based on the new radians.
 *
 * @param {number} newRads
 * @param {{x: number, y: number}} centerPoint
 * @param {{x: number, y: number}} axisLengths
 * @returns {{newStartPoint: {x: number, y: number}, newEndPoint: {x: number, y: number}}}
 */
function getPointsForNewRadians(newRads, centerPoint, axisLengths) {
  const halfLength = Math.sqrt(Math.pow(axisLengths.x, 2) + Math.pow(axisLengths.y, 2)) / 2;

  let newStartPoint = {x: 0, y: 0};
  let newEndPoint = {x: 0, y: 0};

  if (newRads === 0) {
    newStartPoint = {x: centerPoint.x, y: centerPoint.y - halfLength};
    newEndPoint = {x: centerPoint.x, y: centerPoint.y + halfLength};
  } else if (Math.abs(newRads) === FULL_RAD) {
    newStartPoint = {x: centerPoint.x - halfLength, y: centerPoint.y};
    newEndPoint = {x: centerPoint.x + halfLength, y: centerPoint.y};
  } else if (newRads === NEG_HALF_RAD) {
    const diffX = (halfLength * Math.cos(DIAGONAL_DEGREES));
    const diffY = (halfLength * Math.sin(DIAGONAL_DEGREES));
    newStartPoint = {x: centerPoint.x - diffX, y: centerPoint.y - diffY};
    newEndPoint = {x: centerPoint.x + diffX, y: centerPoint.y + diffY};
  } else if (newRads === HALF_RAD) {
    const diffX = (halfLength * Math.cos(DIAGONAL_DEGREES));
    const diffY = (halfLength * Math.sin(DIAGONAL_DEGREES));
    newStartPoint = {x: centerPoint.x + diffX, y: centerPoint.y - diffY};
    newEndPoint = {x: centerPoint.x - diffX, y: centerPoint.y + diffY};
  }

  return {newStartPoint, newEndPoint};
}

/**
 * The SnapLineAngleButtons component.
 */
export class SnapLineAngleButtons extends React.Component {
  /**
   * Snaps the line to the nearest half-axis.
   */
  onClick = () => {
    const {entity, game} = this.props;

    snapLineToAxis(entity, game);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div className="snap-line-angle-buttons">
        <button
          type="button"
          className={classNames('btn btn-sm btn-dark form-button')}
          onClick={this.onClick}
        >
          <i className="fad fad-window-minimize" />
        </button>
      </div>
    );
  }
}

SnapLineAngleButtons.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(SnapLineAngleButtons);
