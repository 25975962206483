import {action, extendObservable, observable, when} from 'mobx';
import lodash from 'lodash';

import {STATE_PRE, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {QUEUED} from '../../../constants/contentConstants';
import serverApi from '../../../utils/serverApi';
import {apiStore, getCase} from '../../../utils/apiStore';

/**
 * The UpdateContents store.
 */
class UpdateContentsStore {
  /**
   * The map of each update flows to their content ids.
   *
   * @type {ObservableMap<string, {
   *   state: number,
   *   error: ?Error,
   *   updatedDate: Date,
   * }>}
   */
  @observable updateByContentId = observable.map();

  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @param {number} contentId
   * @returns {?number}
   */
  getFulfilled(contentId) {
    const safeContentId = String(contentId);
    if (!this.updateByContentId.has(safeContentId)) {
      return null;
    }

    return true;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @param {number} contentId
   * @returns {?Error}
   */
  getRejected(contentId) {
    const safeContentId = String(contentId);
    if (!this.updateByContentId.has(safeContentId)) {
      return null;
    }

    return this.updateByContentId.get(safeContentId).error;
  }

  /**
   * Clears all the current info and states
   */
  @action clearAll() {
    this.updateByContentId.clear();
  }

  /**
   * Updates the state of the content.
   *
   * @param {{id: number}} content
   * @param {number} newState
   * @returns {Promise}
   */
  @action updateContentState(content, newState) {
    if (!content || !content.id) {
      return Promise.resolve(false);
    }

    const safeContentId = String(content.id);
    const safeContentFileId = lodash.get(content, 'contentFiles[0].id');
    if (!safeContentFileId) {
      return Promise.resolve(false);
    }
    const safeState = newState || QUEUED;

    serverApi.contentFilesPatch({
      id: safeContentFileId,
      contentFileStateId: safeState,
    }).then(
      action('updateContentStateSuccess', () => {
        this.updateByContentId.set(safeContentId, {
          state: STATE_FULFILLED,
          error: null,
          updatedDate: Date.now(),
        });
      }),
      action('updateContentStateError', (error) => {
        this.updateByContentId.set(safeContentId, {
          state: STATE_REJECTED,
          error,
          updatedDate: Date.now(),
        });
      })
    );

    return safeContentId;
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {number} contentId
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(contentId, handlers) {
    const safeContentId = String(contentId);

    const getFulfilled = () => {
      return this.getFulfilled(safeContentId);
    };
    const getRejected = () => {
      return this.getRejected(safeContentId);
    };

    let state = STATE_PRE;
    if (safeContentId && this.updateByContentId.has(safeContentId)) {
      state = this.updateByContentId.get(safeContentId).state;
    }

    return getCase(state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for this store.
   *
   * @param {number} contentId
   * @returns {Promise}
   */
  getPromise(contentId) {
    const thisStore = this;
    const safeContentId = String(contentId);

    return new Promise((resolve, reject) => {
      when(
        () => {
          const updateData = thisStore.updateByContentId.get(safeContentId);
          const state = (updateData) ? updateData.state : null;
          return (state === STATE_FULFILLED || state === STATE_REJECTED);
        },
        () => {
          const state = thisStore.updateByContentId.get(safeContentId).state;
          if (state === STATE_REJECTED) {
            reject(this.getRejected(safeContentId));
            return;
          }

          resolve(this.getFulfilled(safeContentId));
        },
        {name: 'apiUpdateContentStateStoreGetPromise'}
      );
    });
  }
}

export default new UpdateContentsStore();
