/**
 * The content id that indicates new blank content.
 *
 * @const {string}
 */
export const EDITOR_NEW_BLANK = 'new-blank';

/**
 * The content id that indicates the new content is a clone of previous content.
 *
 * @const {string}
 */
export const EDITOR_NEW_COPY = 'new-copy';

/**
 * The content id that indicates new image content.
 *
 * @const {string}
 */
export const EDITOR_NEW_IMAGE = 'new-image';

/**
 * The content id that indicates new video content.
 *
 * @const {string}
 */
export const EDITOR_NEW_VIDEO = 'new-video';

/**
 * A list of all the editor new content types.
 *
 * @type {string[]}
 */
export const EDITOR_NEW_ALL = [
  EDITOR_NEW_BLANK,
  EDITOR_NEW_COPY,
  EDITOR_NEW_IMAGE,
  EDITOR_NEW_VIDEO
];
