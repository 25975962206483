import {action, observable, toJS, when} from 'mobx';
import lodash from 'lodash';

import SortableFilterableStore from './common/apiSortableFilterableStore';
import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN, EXPIRES_PENDING} from '../../constants/storeConstants';
import {getCase} from '../../utils/apiStore';
import serverApi from '../../utils/serverApi';

/**
 * The valid filters for this store.
 * @const {string[]}
 */
const VALID_FILTERS = ['contentLibraryId', 'categoryName'];

/**
 * The valid filters for this store.
 * @const {string[]}
 */
const REQUIRED_FILTERS = ['contentLibraryId'];

/**
 * The field name by which we will sort the results.
 * @const {string}
 */
const SORT_BY_FIELD = 'categoryName';

/**
 * The category ids store.
 */
class CategoriesStore extends SortableFilterableStore {
  /**
   * Map of each content library id to that content library's categories.
   *
   * @type {ObservableMap<string, {categories: ?ObservableArray, error: ?Error}>}
   */
  @observable categoriesByContentLibrary = observable.map();

  /**
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @param {{key: string, filters: {}}} storeKey
   * @returns {?Array.<{}>}
   */
  getFulfilled(storeKey) {
    if (!storeKey) {
      return null;
    }

    const safeStoreKey = String(storeKey.key);
    if (!this.categoriesByContentLibrary.has(safeStoreKey)) {
      return null;
    }

    const categories = this.categoriesByContentLibrary.get(safeStoreKey).categories;
    return this.sortAndFilterCategories(categories, storeKey.filters);
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @param {{key: string, filters: {}}} storeKey
   * @returns {?Error}
   */
  getRejected(storeKey) {
    const safeStoreKey = String(storeKey.key);
    if (!this.categoriesByContentLibrary.has(safeStoreKey)) {
      return null;
    }
    return this.categoriesByContentLibrary.get(safeStoreKey).error;
  }

  /**
   * Clears all the category data.
   */
  @action clearAll() {
    this.categoriesByContentLibrary.clear();
  }

  /**
   * Sorts and filters the categories.
   * Helper function used in getFulfilled().
   *
   * @param {Array.<{}>} categories
   * @param {{}} filters
   * @returns {Array.<{}>}
   */
  sortAndFilterCategories(categories, filters) {
    const sortedFilteredItems = this.filterAndSort(categories, filters, SORT_BY_FIELD).map((item) => toJS(item));

    // make map of categories by id
    const categoriesMap = {};
    sortedFilteredItems.forEach((category) => {
      category.children = [];
      categoriesMap[category.id] = category;
    });

    // find all the children by working backwards from the children's parent (children reference their parent in this
    // schema).
    sortedFilteredItems.forEach((category) => {
      if (category.parentCategoryId) {
        const parentCategory = categoriesMap[category.parentCategoryId];
        if (parentCategory) {
          parentCategory.children.push(category);
        }
      }
    });

    return sortedFilteredItems.filter((category) => {
      return (!category.parentCategoryId || !categoriesMap[category.parentCategoryId]);
    });
  }

  /**
   * Expires store cache for a content library id.
   *
   * @param {number} libraryId
   */
  @action expireCacheForLibrary(libraryId) {
    const storeKey = this.getStoreKey({contentLibraryId: libraryId});

    if (this.categoriesByContentLibrary.has(storeKey.key)) {
      this.categoriesByContentLibrary.get(storeKey.key)[EXPIRE_TIME] = null;
    }
  }

  /**
   * Gets the cached category by id and library id.
   *
   * @param {number} categoryId
   * @param {number} libraryId
   * @returns {?{}}
   */
  getCategory(categoryId, libraryId) {
    const filters = {contentLibraryId: libraryId};
    const storeKey = this.getStoreKey(filters);
    const safeStoreKey = String(storeKey.key);

    if (!this.categoriesByContentLibrary.has(safeStoreKey)) {
      return null;
    }

    const categories = this.categoriesByContentLibrary.get(safeStoreKey).categories;

    return lodash.find(categories, {id: categoryId});
  }

  /**
   * Checks if categories are already available via unexpired cache.
   *
   * @param {{key: string, filters: {}}} storeKey
   * @returns {boolean}
   */
  @action isContentAvailable(storeKey) {
    const safeStoreKey = String(storeKey.key);

    const currentResource = this.categoriesByContentLibrary.get(safeStoreKey);
    if (currentResource && currentResource.state === STATE_PENDING) {
      return true;
    }

    if (currentResource && currentResource[EXPIRE_TIME] <= Date.now()) {
      this.categoriesByContentLibrary.delete(safeStoreKey);
    } else if (currentResource && currentResource.state !== STATE_REJECTED) {
      return true;
    }

    return false;
  }

  /**
   * Fetches categories from the server by contentLibrary ID.
   *
   * @param {{}} filters
   * @returns {{key: string, filters: {}}}
   */
  @action fetchCategories(filters) {
    this.checkFilters(filters, VALID_FILTERS, REQUIRED_FILTERS);

    const storeKey = this.getStoreKey(filters);
    if (this.isContentAvailable(storeKey)) {
      return storeKey;
    }

    this.categoriesByContentLibrary.set(storeKey.key, {
      [EXPIRE_TIME]: Date.now() + EXPIRES_PENDING,
      state: STATE_PENDING,
      categories: null,
      error: null,
    });

    serverApi.categoriesByContentLibraryId(filters.contentLibraryId).then(
      action('fetchCategoriesSuccess', (categories) => {
        this.categoriesByContentLibrary.set(storeKey.key, {
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          state: STATE_FULFILLED,
          categories,
          error: null,
        });
      }),
      action('fetchCategoriesError', (error) => {
        this.categoriesByContentLibrary.set(storeKey.key, {
          [EXPIRE_TIME]: null,
          state: STATE_REJECTED,
          categories: [],
          error,
        });
      })
    );

    return storeKey;
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{key: string, filters: {}}} storeKey
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(storeKey, handlers) {
    const getFulfilled = () => {
      return this.getFulfilled(storeKey);
    };
    const getRejected = () => {
      return this.getRejected(storeKey);
    };

    let state = STATE_PRE;
    if (storeKey && this.categoriesByContentLibrary.has(storeKey.key)) {
      state = this.categoriesByContentLibrary.get(storeKey.key).state;
    }

    return getCase(state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for this store.
   *
   * @param {string} storeKey
   * @returns {Promise}
   */
  getPromise(storeKey) {
    const thisStore = this;
    const coreKey = storeKey.key;

    return new Promise((resolve, reject) => {
      when(
        () => {
          const categoryData = thisStore.categoriesByContentLibrary.get(coreKey);
          const state = (categoryData) ? categoryData.state : null;
          return (state === STATE_FULFILLED || state === STATE_REJECTED);
        },
        () => {
          const state = thisStore.categoriesByContentLibrary.get(coreKey).state;
          if (state === STATE_REJECTED) {
            reject(this.getRejected(storeKey));
            return;
          }

          resolve(this.getFulfilled(storeKey));
        },
        {name: 'apiCategoriesStoreGetPromise'}
      );
    });
  }
}

export default new CategoriesStore();
