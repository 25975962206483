import {toJS} from 'mobx';

import {getTransitionTime, getTimeForTransitionSource} from './timeComponent';

/**
 * The transition component.
 *
 * @param {Array.<{}>} transitions
 * @returns {{transition: Array.<{}>}}
 */
export function transitionComponent(transitions) {
  return {
    transition: transitions,
  };
}

/**
 * Gets the transition component from the source item.
 *
 * @param {{transitions: Array.<{}>}} item
 * @param {number} fps
 * @returns {{transition: Array.<{}>}}
 */
export function getTransitionFromSource(item, fps) {
  if (!fps) {
    throw new Error('transitionComponent: getTransitionFromSource requires an fps value.');
  }

  if (!item.transitions) {
    return {};
  } else if (!item.transitions.map) {
    throw new Error('Item transitions must be an array.');
  }

  const parsedTransitions = item.transitions.map((transition) => {
    return Object.assign({}, transition, getTransitionTime(transition, fps));
  });

  return transitionComponent(parsedTransitions);
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getTransitionForSource(entity) {
  if (!entity.has('transition')) {
    return {};
  }

  const transitions = entity.get('transition');
  if (!transitions || !transitions.map) {
    return {};
  }

  const output = transitions.map((transition) => {
    const raw = Object.assign({}, toJS(transition), getTimeForTransitionSource(transition));
    delete raw.time;
    delete raw.loadedPreset;

    return raw;
  });

  return {
    transitions: output,
  };
}
