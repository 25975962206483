import lodash from 'lodash';

/**
 * How much the starting z-index is offset from the source page.
 * @const {number}
 */
const Z_INDEX_OFFSET = 0;

/**
 * The difference in zIndex between elements.
 * @const {number}
 */
const Z_INDEX_PADDING = 100;

/**
 * Makes a degrees number positive and between 0 and 360.
 *
 * @param {number} degrees
 * @returns {number}
 */
export function simplifyDegrees(degrees) {
  if (!degrees) {
    return 0;
  }

  const fullCircle = 360;
  const baseDegrees = degrees % fullCircle;

  if (baseDegrees >= 0) {
    return baseDegrees;
  }

  return fullCircle + baseDegrees;
}

/**
 * Parses the entity order into the zIndex.
 *
 * @param {number} order
 * @returns {number}
 */
export function orderToZIndex(order) {
  return Z_INDEX_OFFSET + ((order + 1) * Z_INDEX_PADDING);
}

/**
 * The position component.
 *
 * @param {{}} positionValues
 * @param {number=} positionValues.leftPosition
 * @param {number=} positionValues.topPosition
 * @param {number=} positionValues.zIndex
 * @param {number=} positionValues.rotate
 * @param {{}=} importComponent
 * @returns {{position: {top: number, left: number, rotate: number, zIndex: number}}}
 */
export function positionComponent(positionValues, importComponent) {
  const safeImport = parseImportComponent(importComponent);
  const newValues = lodash.defaults(positionValues || {}, safeImport);

  const safeRotate = simplifyDegrees(newValues.rotate || 0);

  return {
    position: {
      top: newValues.topPosition || 0,
      left: newValues.leftPosition || 0,
      rotate: safeRotate,
      zIndex: newValues.zIndex || Z_INDEX_OFFSET,
      default: {
        top: newValues.topPosition || 0,
        left: newValues.leftPosition || 0,
        rotate: safeRotate,
      },
    },
  };
}

/**
 * Parses a position component into an importable component.
 *
 * @param {{}} importComponent
 * @returns {{}}
 */
function parseImportComponent(importComponent) {
  return lodash.pickBy(importComponent || {});
}

/**
 * Gets the position component from the source item.
 *
 * @param {{position: {}}} item
 * @param {number} order
 * @returns {{position: {top: number, left: number, zIndex: number}}}
 */
export function getPositionFromSource(item, order) {
  if (order === undefined) {
    throw new Error('positionComponent: getPositionFromSource requires an order.');
  }
  const itemSetup = item.setup || {};

  if (!itemSetup.position) {
    return {};
  }

  const position = itemSetup.position;
  return positionComponent({
    topPosition: position.top,
    leftPosition: position.left,
    zIndex: orderToZIndex(order),
    rotate: position.rotate,
  });
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getPositionForSource(entity) {
  if (!entity.has('position')) {
    return {};
  }

  const position = entity.get('position');
  return {
    setup: {
      position: {
        top: position.default.top,
        left: position.default.left,
        rotate: simplifyDegrees(position.default.rotate),
      }
    },
  };
}
