import {action, observable, reaction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import MultiSlider from '../../common/multiSlider/MultiSlider';
import {ACTIVE_DELAY_MS} from '../../../constants/displayConstants';
import {actionUpdateComponent} from '../../../display/components/action/actionUpdateComponent';
import {timeComponent} from '../../../display/components/common/timeComponent';

import './editTimeControl.scss';

/**
 * The conversion factor for going from milliseconds to seconds.
 *
 * @const {number}
 */
const MS_TO_SECONDS = 1000;

/**
 * The conversion factor for going from seconds to minutes.
 *
 * @const {number}
 */
const SECONDS_TO_MINUTES = 60;

/**
 * The minimum amount of time in milliseconds by which the start and end time can be separated.
 *
 * @const {number}
 */
const MINIMUM_SEPARATION = 1000;

/**
 * Formats the time for the display.
 *
 * @param {number} entityTime
 * @returns {string}
 */
function formatDisplayTime(entityTime) {
  const safeEntityTime = entityTime || 0;

  return (safeEntityTime / MS_TO_SECONDS).toFixed(1);
}

/**
 * Formats the time for an input.
 *
 * @param {number} entityTime
 * @returns {string}
 */
function formatInputTime(entityTime) {
  const safeEntityTime = entityTime || 0;
  if (!parseInt(entityTime, 10)) {
    return '0:0.0';
  }

  const rawSeconds = safeEntityTime / MS_TO_SECONDS;
  const minutes = Math.floor(rawSeconds / SECONDS_TO_MINUTES);
  const seconds = Math.floor(rawSeconds % SECONDS_TO_MINUTES);
  const subSeconds = Math.floor((safeEntityTime % MS_TO_SECONDS));
  const fixedSubSeconds = String(subSeconds).substr(0, 2);

  if (!minutes) {
    return `${seconds}.${fixedSubSeconds}`;
  }

  return `${minutes}:${seconds}.${fixedSubSeconds}`;
}

/**
 * Formats the time back to milliseconds from an input time.
 *
 * @param {string} inputTime
 * @returns {number}
 */
function formatFromInputTime(inputTime) {
  const safeInputTime = String(inputTime);

  const minuteParts = safeInputTime.split(':');
  const hasMinutes = (minuteParts.length > 1);

  const secondsSection = (hasMinutes) ? minuteParts[1] : safeInputTime;
  const secondParts = secondsSection.split('.');
  const hasSubSeconds = (secondParts.length > 1);

  const parsedMinutes = parseInt((hasMinutes) ? (minuteParts[0] || 0) : 0, 10);
  const parsedSeconds = parseInt(secondParts[0] || 0, 10);
  const parsedSubSeconds = parseInt((hasSubSeconds) ? secondParts[1] || 0 : 0, 10);

  const milliSeconds = Math.floor(
    Number.parseFloat(`.${parsedSubSeconds}`) * MS_TO_SECONDS
  );

  return ((parsedMinutes) * SECONDS_TO_MINUTES * MS_TO_SECONDS)
    + ((parsedSeconds) * MS_TO_SECONDS)
    + milliSeconds;
}

/**
 * The EditTimeControl component.
 */
export class EditTimeControl extends React.Component {
  /**
   * The entity start time.
   *
   * @type {number}
   */
  @observable startTime = 0;

  /**
   * The entity end time.
   *
   * @type {number}
   */
  @observable endTime = 0;

  /**
   * The disposer function for the mobX reaction.
   *
   * @type {?function}
   */
  @observable reactionDisposer = null;

  /**
   * @constructor
   * @param {{game: GameStore}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);

    const {entity} = props;
    const currentTime = entity.get('time');
    if (currentTime) {
      this.updateStartTime(currentTime.start, true);
      this.updateEndTime(currentTime.end, true);
    }
  }

  /**
   * Triggered right after the component is added to the page.
   */
  componentDidMount() {
    this.reactionDisposer = reaction(
      () => {
        const entityTimes = this.props.entity.get('time');
        return {
          start: entityTimes.start,
          end: entityTimes.end,
        };
      },
      (entityTimes) => {
        this.updateStartTime(entityTimes.start, true);
        this.updateEndTime(entityTimes.end, true);
      }, {
        fireImmediately: true,
        name: 'updateInputTimesOnSliderChange'
      }
    );
  }

  /**
   * Triggered when the component is about to be removed from the page.
   */
  componentWillUnmount() {
    if (this.reactionDisposer) {
      this.reactionDisposer();
    }
  }

  /**
   * Triggered when the end time slider changes.
   *
   * @param {number} newValue
   */
  onChangeEnd = (newValue) => {
    const {entity, game} = this.props;

    const currentTime = entity.get('time');
    const actionParams = {
      entityId: entity.get('id'),
    };

    let timeInMS = Math.floor(newValue);
    if (timeInMS > game.endTime) {
      timeInMS = game.endTime;
    }
    if (timeInMS < 0) {
      timeInMS = 0;
    }
    if (timeInMS < currentTime.start + MINIMUM_SEPARATION) {
      timeInMS = currentTime.start + MINIMUM_SEPARATION;
    }

    const newActive = (currentTime.active > timeInMS) ? timeInMS : currentTime.active;

    game.addAction(actionParams, actionUpdateComponent(
      timeComponent(
        currentTime.start,
        timeInMS,
        newActive
      ))
    );
  };

  /**
   * Triggered when the start time slider changes.
   *
   * @param {number} newValue
   */
  onChangeStart = (newValue) => {
    const {entity, game} = this.props;

    const currentTime = entity.get('time');
    const actionParams = {
      entityId: entity.get('id'),
    };

    let timeInMS = Math.floor(newValue);
    if (timeInMS > game.endTime) {
      timeInMS = game.endTime;
    }
    if (timeInMS < 0) {
      timeInMS = 0;
    }
    if (timeInMS > currentTime.end - MINIMUM_SEPARATION) {
      timeInMS = currentTime.end - MINIMUM_SEPARATION;
    }

    const activeDiff = Math.max(currentTime.active - currentTime.start, ACTIVE_DELAY_MS);

    let newActive = activeDiff + timeInMS;
    if (newActive > currentTime.end) {
      newActive = currentTime.end;
    }

    game.addAction(actionParams, actionUpdateComponent(
      timeComponent(
        timeInMS,
        currentTime.end,
        newActive
      ))
    );
  };

  /**
   * Updates the start time input value.
   *
   * @param {number} newStartTime
   * @param {boolean=} formatFromMS
   */
  @action updateStartTime = (newStartTime, formatFromMS = false) => {
    if (formatFromMS) {
      this.startTime = formatInputTime(newStartTime);
    } else {
      this.startTime = newStartTime;
    }
  };

  /**
   * Updates the start time input value.
   *
   * @param {number} newEndTime
   * @param {boolean=} formatFromMS
   */
  @action updateEndTime = (newEndTime, formatFromMS = false) => {
    if (formatFromMS) {
      this.endTime = formatInputTime(newEndTime);
    } else {
      this.endTime = newEndTime;
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;

    const entityTime = entity.get('time');
    const startTime = entityTime.start;
    const endTime = entityTime.end;
    const maxTime = game.endTime;

    const elementId = `time-selector-${entity.get('id')}`;

    return (
      <div className="edit-time-controls">
        <div className="form-group">
          <label htmlFor={elementId}>Timeline</label>
          <MultiSlider
            id={elementId}
            className="form-control form-control-sm"
            maxValue={maxTime}
            minValue={0}
            startValue={startTime}
            endValue={endTime}
            onChangeStart={this.onChangeStart}
            onChangeEnd={this.onChangeEnd}
            minSeparation={MINIMUM_SEPARATION}
          />
        </div>

        <div className="edit-time-text-wrapper">
          <div className="edit-time-text edit-time-start">{formatDisplayTime(startTime)} Sec</div>
          <div className="edit-time-text edit-time-end">{formatDisplayTime(endTime)} Sec</div>
        </div>

        <div className="row fine-control-row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="start-time-fine-control">Start Time</label>
              <input
                id="start-time-fine-control"
                className="form-control form-control-sm"
                value={this.startTime}
                onChange={(changeEvent) => {
                  this.updateStartTime(changeEvent.target.value);
                }}
                onBlur={(changeEvent) => {
                  this.onChangeStart(formatFromInputTime(changeEvent.target.value));
                }}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label htmlFor="end-time-fine-control">Stop Time</label>
              <input
                id="end-time-fine-control"
                className="form-control form-control-sm"
                value={this.endTime}
                onChange={(changeEvent) => {
                  this.updateEndTime(changeEvent.target.value);
                }}
                onBlur={(changeEvent) => {
                  this.onChangeEnd(formatFromInputTime(changeEvent.target.value));
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditTimeControl.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,

  minSeparation: PropTypes.number,
};

export default observer(EditTimeControl);
