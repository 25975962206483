import classNames from 'classnames';
import lodash from 'lodash';
import React from 'react';
import {action, observable} from 'mobx';
import PropTypes from 'prop-types';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';

import { CATEGORY_TITLE_LENGTH } from '../../../../../constants/uiConstants';
import CategoryActions from '../categoryActions/CategoryActions';
import inject from '../../../../hoc/injectHoc';

import './categoryItem.scss';

/**
 * The categoryItem component.
 */
export class CategoryItem extends React.Component {
  /**
   * Map of category id to toggle state.
   *
   * @type {ObservableMap<string, boolean>}
   */
  @observable categoryToggleState = observable.map();

  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);
  }

  /**
   * Check whether a category's contents are currently visible
   *
   * @param {number} categoryId
   * @returns {boolean}
   */
  subCategoriesAreVisible = (categoryId) => {
    return Boolean(this.categoryToggleState.get(categoryId));
  };

  /**
   * Toggle visibility of a category's sub-categories
   *
   * @param {{}} clickEvent
   * @param {number} categoryId
   */
  @action toggleSubCategories = (clickEvent, categoryId) => {
    clickEvent.preventDefault();
    const toggleState = this.subCategoriesAreVisible(categoryId);
    this.categoryToggleState.set(categoryId, !toggleState);
  };

  /**
   * Triggered when the category name is clicked.
   */
  @action onClickCategory = () => {
    const {category, onCategorySelected} = this.props;
    const categoryId = category.id;

    if (onCategorySelected) {
      onCategorySelected(categoryId);
    }

    // Show the sub-categories if they are not already visible.
    this.categoryToggleState.set(categoryId, true);
  };

  /**
   * The classes for category buttons.
   *
   * @param {number} categoryId
   * @returns {{'active': boolean}}
   */
  categoryClasses = (categoryId) => {
    const {libraryType} = this.props;
    const activeCategory = this.props.activeContentStore.libraries[libraryType].category;

    return {
      active: activeCategory.id === categoryId
    };
  };

  /**
   * The classes for category expand buttons.
   *
   * @param {number} categoryId
   * @returns {string}
   */
  buttonExpandClasses = (categoryId) => {
    if (this.subCategoriesAreVisible(categoryId)) {
      return 'fad-caret-down';
    }
    return 'fad-caret-up';
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {allowCategoryEdit, category, libraryType, skipSubCategories} = this.props;
    const categoryId = category.id;

    const showSubCategories = Boolean(!skipSubCategories && this.subCategoriesAreVisible(categoryId));

    return (
      <li className="category-item-top-item">
        <div className="category-item-top-item-text">
          <button
            type="button"
            title={category.categoryName}
            className={classNames(
              'category-item-top-item-link',
              this.categoryClasses(categoryId)
            )}
            onClick={this.onClickCategory}
          >
            <i className="fad fad-folder category-item-top-item-icon" />
            <p className="category-item-top-item-name">
              {lodash.truncate(category.categoryName, { length: CATEGORY_TITLE_LENGTH })}
            </p>
          </button>

          <div className="category-action-buttons clearfix">
            {(!skipSubCategories && category.children.length !== 0) && (
              <button
                type="button"
                onClick={(clickEvent) => this.toggleSubCategories(clickEvent, categoryId)}
                className="btn btn-link btn-icon"
              ><i className={classNames('fad', this.buttonExpandClasses(categoryId))} /></button>
            )}

            {(allowCategoryEdit) && (
              <CategoryActions
                category={category}
                libraryId={category.libraryId}
                libraryType={libraryType}
              />
            )}
          </div>
        </div>

        {(showSubCategories) && (
          <ul className="category-item-sub">
            {category.children.map((subCategory) => (
              <li key={subCategory.id} className="category-item-sub-item">
                <button
                  type="button"
                  title={subCategory.categoryName}
                  className={classNames(
                    'category-item-sub-item-link',
                    this.categoryClasses(subCategory.id)
                  )}
                  onClick={() => this.props.onCategorySelected(subCategory.id)}
                >
                  {lodash.truncate(subCategory.categoryName, { length: CATEGORY_TITLE_LENGTH })}
                </button>

                {(allowCategoryEdit) && (
                  <div className="category-action-buttons">
                    <CategoryActions
                      category={subCategory}
                      libraryId={category.libraryId}
                      libraryType={libraryType}
                    />
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  }
}

CategoryItem.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
  category: PropTypes.shape({
    categoryName: PropTypes.string,
    children: PropTypes.array,
    id: PropTypes.number,
    libraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  libraryType: PropTypes.number.isRequired,

  allowCategoryEdit: PropTypes.bool,
  apiContentsStore: MobxPropTypes.observableObject,
  onCategorySelected: PropTypes.func,
  skipSubCategories: PropTypes.bool,
};

CategoryItem.defaultProps = {
  allowCategoryEdit: false,
  skipSubCategories: false,
};

CategoryItem.wrappedComponent = {};
CategoryItem.wrappedComponent.propTypes = {
  apiContentsStore: MobxPropTypes.observableObject.isRequired
};

export default inject(CategoryItem)(
  observer(CategoryItem),
);
