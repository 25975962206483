import {stateFromElement} from 'draft-js-import-element';
import lodash from 'lodash';

import {
  addColorToStyleMap,
  addFontSizeToStyleMap,
  addLetterSpacingToStyleMap,
  addLineHeightToStyleMap,
  rgbToHex
} from './styleHelper';
import {
  COLOR_PREFIX,
  FONT_SIZE_PREFIX,
  LETTER_SPACING_PREFIX,
  LINE_HEIGHT_FIXED,
  LINE_HEIGHT_PREFIX,
  classToStyleMap,
} from '../constants/styleContants';

/**
 * The beginning of the class that indicates a custom markdown element.
 *
 * @const {RegExp}
 */
const CUSTOM_CLASS_PRE = /\b(md-[^\s\b]+)\b/ig;

/**
 * Parses HTML into draftJS content.
 *
 * @param {string} fromHtml
 * @returns {Array.<ContentState>}
 */
export function stateFromHtml(fromHtml) {
  const elements = htmlParser(fromHtml);

  return stateFromElement(elements, {
    customBlockFn: customBlockParser,
    customInlineFn: customInlineParser,
  });
}

/**
 * Parses the given HTML string into HTML elements.
 *
 * @param {string} html
 * @returns {Node[]}
 */
function htmlParser(html) {
  let doc;

  const whiteSpaceHtml = html.replace(/\s{2,}/ig, (matches) => {
    return ' ' + lodash.repeat('&nbsp;', matches.length - 1);
  });

  // DOMParser is a global set by the browser.
  if (typeof DOMParser !== 'undefined') {
    let parser = new DOMParser();
    doc = parser.parseFromString(whiteSpaceHtml, 'text/html');
  } else {
    doc = document.implementation.createHTMLDocument('');
    if (doc.documentElement) {
      doc.documentElement.innerHTML = whiteSpaceHtml;
    }
  }

  return doc.body;
}

/**
 * Parses the html element into a draftJS Block Style.
 *
 * @param {HTMLElement} element
 * @returns {?{}}
 */
function customBlockParser(element) {
  if (element.nodeName.toLowerCase() !== 'div' && !element.className) {
    return null;
  }

  let type = null;

  const elClassName = element.className;
  lodash.forEach(classToStyleMap, (styleName, className) => {
    if (elClassName.indexOf(className) === -1) {
      return true;
    }

    type = styleName;
    return false;
  });

  return {
    type,
    data: null,
  };
}

/**
 * Parses the html element into a draftJS Inline Style.
 *
 * @param {HTMLElement} element
 * @returns {?{}}
 */
function customInlineParser(element) {
  if (!element.className) {
    return null;
  }

  const matchingClasses = element.className.match(CUSTOM_CLASS_PRE);
  if (!matchingClasses || !matchingClasses.length) {
    return null;
  }

  const style = parseStyleFromClassName(matchingClasses, element);
  if (!style) {
    return null;
  }

  return {
    type: 'STYLE',
    style: style,
  };
}

/**
 * Parses the style from the classes.
 *
 * @param {string[]} classNames
 * @param {HTMLElement} element
 * @returns {?string}
 */
function parseStyleFromClassName(classNames, element) {
  let matchingStyle = null;
  classNames.forEach((className) => {
    if (classToStyleMap[className]) {
      matchingStyle = classToStyleMap[className];
    }
  });

  if (matchingStyle === COLOR_PREFIX) {
    const elementStyle = element.getAttribute(('style'));

    const hexColor = getHexColorFromStyle(elementStyle);

    if (hexColor) {
      matchingStyle += hexColor;
      addColorToStyleMap(matchingStyle, hexColor);
    }
  } else if (matchingStyle === FONT_SIZE_PREFIX) {
    const fontSizeMatch = element.getAttribute('style').match(/:\s*([0-9]+)/);

    if (fontSizeMatch && fontSizeMatch.length) {
      matchingStyle += fontSizeMatch[1];
      addFontSizeToStyleMap(matchingStyle, fontSizeMatch[1]);
    }
  } else if (matchingStyle === LINE_HEIGHT_PREFIX) {
    const lineHeightMatch = element.getAttribute('style').match(/:\s*([0-9.]+)/);

    if (lineHeightMatch && lineHeightMatch.length) {
      const matchingFloat = parseFloat(lineHeightMatch[1]).toFixed(LINE_HEIGHT_FIXED);
      matchingStyle += matchingFloat;
      addLineHeightToStyleMap(matchingStyle, matchingFloat);
    }
  } else if (matchingStyle === LETTER_SPACING_PREFIX) {
    const letterSpacingMatch = element.getAttribute('style').match(/:\s*([0-9]+)/);

    if (letterSpacingMatch && letterSpacingMatch.length) {
      matchingStyle += letterSpacingMatch[1];
      addLetterSpacingToStyleMap(matchingStyle, letterSpacingMatch[1]);
    }
  }

  return matchingStyle;
}

/**
 * Parses the hexadecimal color from the element style.
 * Supports both hexadecimal matching and rgb() matching.
 *
 * @param {string} elementStyle
 * @returns {?string}
 */
function getHexColorFromStyle(elementStyle) {
  let colorCodeMatch = elementStyle.match(/:\s*#([0-9a-f]+)/);

  if (colorCodeMatch && colorCodeMatch.length) {
    return colorCodeMatch[1];
  }

  colorCodeMatch = elementStyle.match(/:\s*rgba?\(\s*([0-9]+),\s*([0-9]+),\s*([0-9]+)/);
  if (!colorCodeMatch || !colorCodeMatch.length) {
    return null;
  }

  const [unused, red, green, blue] = colorCodeMatch;
  return rgbToHex(red, green, blue).substr(1);
}

export default stateFromHtml;
