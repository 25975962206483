import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {getMainUrlByFileId} from '../../../utils/contentsHelper';

/**
 * The DisplayViewportImage component.
 */
export class DisplayViewportImage extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game, style, className} = this.props;

    const entityId = entity.get('id');
    const viewport = entity.get('viewport');
    const {file} = viewport;
    if (!file.id) {
      return null;
    }

    // Disable rotation on the svg element.
    const svgStyle = {
      ...style,
      transform: '',
      WebkitTransform: '',
    };

    let imageSource = getMainUrlByFileId(file.id);
    if (game.isRender) {
      imageSource = imageSource.replace('https://', 'http://');
    }

    const preserveAspectRatio = (viewport.maintainAspectRatio) ? 'xMidYMid meet' : 'none';

    return (
      <svg
        id={entityId}
        className={classNames('display-image', className)}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="none"
        preserveAspectRatio="none"
        pointerEvents="none"
        viewBox={`0 0 ${svgStyle.width} ${svgStyle.height}`}
        style={svgStyle}
      >
        <image
          x={0}
          y={0}
          width={svgStyle.width}
          height={svgStyle.height}
          xlinkHref={imageSource}
          preserveAspectRatio={preserveAspectRatio}
        />
      </svg>
    );
  }
}

DisplayViewportImage.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,

  className: PropTypes.string,
};

export default observer(DisplayViewportImage);
