import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactSelect from 'react-select';

/**
 * The Select component.
 */
export class Select extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {onChange, options, theme, value, ...reactSelectProps} = this.props;
    const themeClasses = {
      'theme-dark': (theme === 'dark'),
      'theme-light': (theme === 'light'),
    };

    return (
      <ReactSelect
        className={classNames('w24-select', themeClasses)}
        classNamePrefix="w24-select"
        value={options.filter((option) => (value === option.value))}
        onChange={(selected) => onChange(selected.value)}
        options={options}
        {...reactSelectProps}
      />
    );
  }
}

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.string.isRequired,

  theme: PropTypes.oneOf(['light', 'dark']),
};

Select.defaultProps = {
  theme: 'dark',
};

export default Select;
