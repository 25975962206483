import lodash from 'lodash';
import markdownIt from 'markdown-it';

import {ALIGN_TOP} from '../../../components/controls/editText/constants/styleContants';
import {fontStylesPlugin} from '../../../utils/markdown/markdownStylesPlugin';
import {underlinePlugin} from '../../../utils/markdown/markdownUnderlinePlugin';

const markdownParser = markdownIt({
  html: false,
  xhtmlOut: true,
  breaks: false,
});

// Add the markdown-it plugins.
markdownParser.underlinePluginIsInitialized = false;
markdownParser.use(fontStylesPlugin);
markdownParser.use(underlinePlugin);

/**
 * The text component.
 *
 *
 * @param {{}} textValues
 * @param {string=} textValues.value
 * @param {boolean=} textValues.isHtml
 * @param {string=} textValues.rawValue
 * @param {string=} textValues.verticalAlign
 * @param {{}=} importComponent
 * @returns {{text: {isHtml: boolean, rawValue: string, value: string, verticalAlign: string}}}
 */
export function textComponent(textValues, importComponent) {
  const newValues = lodash.defaults(textValues || {}, lodash.pickBy(importComponent || {}));

  return {
    text: {
      isHtml: Boolean(newValues.isHtml),
      rawValue: newValues.rawValue,
      value: newValues.value,
      verticalAlign: newValues.verticalAlign || ALIGN_TOP,
    },
  };
}

/**
 * Gets the text component from the source item.
 *
 * @param {{text: {plaintext: ?string, markdown: ?string}}} item
 * @returns {{text: {isHtml: boolean, value: string}}}
 */
export function getTextFromSource(item) {
  if (!item.text) {
    return {};
  }

  const text = item.text;

  let finalValue = text.plaintext || '';
  if (text.markdown) {
    finalValue = parseMarkdownToHtml(text.markdown);
  }

  return textComponent({
    value: finalValue,
    isHtml: Boolean(text.markdown),
    rawValue: text.markdown || text.plaintext,
    verticalAlign: text.verticalAlign,
  });
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getTextForSource(entity) {
  if (!entity.has('text')) {
    return {};
  }

  const text = entity.get('text');
  const output = {
    verticalAlign: text.verticalAlign,
  };

  if (text.isHtml) {
    output.markdown = text.rawValue;
  } else {
    output.plaintext = text.value;
  }

  return {
    text: output,
  };
}

/**
 * Parses the given text from markdown to HTML.
 *
 * @param {string} markdownText
 * @returns {string}
 */
export function parseMarkdownToHtml(markdownText) {
  const html = markdownParser.render(markdownText);
  return lodash.trim(html).replace(/<p/g, '<div').replace(/<\/p/g, '</div');
}
