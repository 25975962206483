import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import InfiniteScroll from '../infiniteScroll/InfiniteScroll';
import ContentItem from '../../common/contentItem/ContentItem';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import inject from '../../hoc/injectHoc';

import './contentItems.scss';

/**
 * The ContentItems component.
 */
export class ContentItems extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      activeContentStore,
      contentSize,
      contentStore,
      isLoading,
      libraryType,
      onLoadNextPage,
      storeKey,
    } = this.props;

    const isWriteable = activeContentStore.isActiveLibraryWritable(libraryType);

    const searchTerm = activeContentStore.getContentSearchFilter(libraryType);
    const isSearching = Boolean(searchTerm);

    const pollKeys = {};
    const contentStoreName = contentStore.constructor.name;
    if (contentStoreName === 'ContentsStore') {
      pollKeys.apiContentsStore = storeKey;
    } else if (contentStoreName === 'ContentSearch') {
      pollKeys.apiContentSearchStore = storeKey;
    }

    return (
      <div className="content-items">
        <div className="content-items-list">
          {contentStore.paginationCase(storeKey, {
            pre: () => {
              if (isLoading) {
                return (<LoadingIcon />);
              }

              return (
                <p className="w24-no-results">
                  {(!isSearching) ? 'Please select a category.' : 'Please enter search terms.'}
                </p>
              );
            },
            pending: () => (<LoadingIcon />),
            rejected: () => (
              <p className="w24-error">There was an error loading content for this category.</p>
            ),
            fulfilled: ({canLoadMore, fulfilled}) => {
              if (isLoading) {
                return (<LoadingIcon />);
              }

              if (!fulfilled || !fulfilled.length) {
                return (<p className="w24-no-results">No content found.</p>);
              }

              return (
                <InfiniteScroll
                  className="content-items-scrollable clearfix"
                  hasMore={canLoadMore}
                  initialLoad={false}
                  loadMore={onLoadNextPage}
                  pageStart={1}
                  scrollElSelector=".page-main"
                  useWindow={false}
                >
                  {fulfilled.map((content) => {
                    if (content.notContent) {
                      if (content.isPending) {
                        return (
                          <div className="content-item-card-pending" key={content.id}>
                            <LoadingIcon size="md" />
                          </div>
                        );
                      }

                      return (
                        <div className="content-item-card is-rejected" key={content.id}>
                          <div className="alert alert-warning" role="alert">
                            Could not load content for page {content.page}.
                          </div>
                        </div>
                      );
                    }

                    return (
                      <div className="content-item-card" key={content.id}>
                        <ContentItem
                          content={content}
                          contentSize={contentSize}
                          isWriteable={isWriteable}
                          libraryType={libraryType}
                          pollKeys={pollKeys}
                        />
                      </div>
                    );
                  })}
                </InfiniteScroll>
              );
            },
          })}
        </div>
      </div>
    );
  }
}

ContentItems.propTypes = {
  contentSize: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  contentStore: PropTypes.shape({
    paginationCase: PropTypes.func.isRequired,
    constructor: PropTypes.func,
  }).isRequired,
  libraryType: PropTypes.number.isRequired,
  onLoadNextPage: PropTypes.func.isRequired,

  activeContentStore: MobxPropTypes.observableObject,
  isLoading: PropTypes.bool,
  storeKey: PropTypes.shape({
    key: PropTypes.string.isRequired,
    filters: PropTypes.object,
  }),
};

ContentItems.defaultProps = {
  isLoading: false,
  storeKey: null,
};

ContentItems.wrappedComponent = {};
ContentItems.wrappedComponent.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ContentItems)(
  observer(ContentItems)
);
