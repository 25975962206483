import React from 'react';

import DashboardFigure from '../../common/dashboardFigure/DashboardFigure';
import DashboardHeader from '../../common/dashboardHeader/DashboardHeader';
import {productSelectionRoute, homeRoute, newClipRoute} from '../../routePaths';

import myContentPNG from '../../../assets/images/my-content.png';
import createNewClipPNG from '../../../assets/images/create-new-clip.png';

import './dashboardPage.scss';

/**
 * The DashboardPage component.
 */
export class DashboardPage extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div id="dashboard-page" className="system-page">
        <DashboardHeader title="What Do You Want To Do" prevPage={productSelectionRoute} />
        <div className="container">
          <div className="row">
            <div className="col-md-4 mx-auto">
              <DashboardFigure
                image={myContentPNG}
                linkLabel="Browse My Content"
                url={homeRoute}
              />
            </div>
            <div className="col-md-4 mx-auto">
              <DashboardFigure
                image={createNewClipPNG}
                linkLabel="Create New Clip"
                url={newClipRoute}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DashboardPage.propTypes = {};

export default DashboardPage;
