/**
 * The length to truncate content titles.
 * @const {number}
 */
export const CONTENT_TITLE_LENGTH = 28;

/**
 * The length to truncate category titles.
 * @const {number}
 */
export const CATEGORY_TITLE_LENGTH = 28;

/**
 * The length to stop allowing entities to be added
 * @const {number}
 */
export const ENTITY_MAX_LENGTH = 51;
