import lodash from 'lodash';

import sessionStore from '../stores/active/sessionStore';
import apiLoginStore from '../stores/api/apiLoginStore';
import serverApi from './serverApi';

/**
 * The works24 office account client id.
 *
 * @const {number}
 */
const WORKS24_CLIENT_ID = 1470;

/**
 * Gets all writable libraries and, if there is only a single writable library, that library's writable categories.
 *
 * @param {number} contentLibraryTypeId
 * @param {?number} productId
 * @returns {Promise.<{}>}
 */
export function getWritableLibrariesAndCategories(contentLibraryTypeId, productId) {
  const {userId, clientId} = sessionStore;

  const filter = {
    userId,
    productId,
    clientId,
    contentLibraryTypeId
  };

  const result = {libraries: null, categories: null};
  return serverApi.availableLibraries(filter).then(
    (libraries) => {
      const writableLibraries = libraries.filter(isLibraryWriteable);
      result.libraries = writableLibraries;
      if (writableLibraries.length === 1) {
        return serverApi.categoriesByContentLibraryId(writableLibraries[0].id);
      }
      return {skip: true};
    }
  ).then(
    (fetchResponse) => {
      if (fetchResponse && !fetchResponse.skip) {
        result.categories = fetchResponse;
      }
      return result;
    }
  );
}

/**
 * Gets whether or not the library is writeable.
 *
 * @param {{isGlobal: boolean, writeAccess: boolean}} library
 * @returns {boolean}
 */
export function isLibraryWriteable(library) {
  const canEditGlobals = lodash.get(apiLoginStore, 'currentUser.user.canEditGlobals', false);
  if (library.isGlobal && !canEditGlobals) {
    return false;
  }

  return Boolean(library.writeAccess);
}

/**
 * Whether or not the user has employee permissions and access for the given client.
 *
 * @param {(number|{id: number})=} clientOrId
 * @param {boolean=} skipClientCheck
 * @returns {*}
 */
export function hasEmployeeAccess(clientOrId, skipClientCheck) {
  let clientId = sessionStore.clientId;
  if (clientOrId) {
    clientId = clientOrId.id || clientOrId;
  }

  let isEmployee = lodash.get(apiLoginStore, 'currentUser.user.isEmployee', false);
  if (!isClientWorks24(clientId) && !skipClientCheck) {
    // Employee privilege only counts for the works24 client.
    isEmployee = false;
  }

  return isEmployee;
}

/**
 * Whether or not the library is both local and writeable.
 *
 * @param {{isGlobal: boolean, writeAccess: boolean}} library
 * @returns {boolean}
 */
export function isLibraryLocalAndWriteable(library) {
  return Boolean(!library.isGlobal && library.writeAccess);
}

/**
 * Returns whether or not the given client object or clientId is the works 24 client.
 * This is useful for seeing if an employee user should have access to change global content.
 *
 * @param {number|{id: number}} clientOrId
 * @returns {boolean}
 */
export function isClientWorks24(clientOrId) {
  const clientId = (clientOrId) ? clientOrId.id || clientOrId : null;
  return (Number(clientId) === WORKS24_CLIENT_ID);
}

/**
 * Takes in library filters and makes them writeable only.
 *
 * @param {{}} filters
 * @returns {{writeAccess: boolean, isGlobal: ?boolean}}
 */
export function makeLibraryFiltersWriteableOnly(filters) {
  const safeFilters = {...(filters || {}), writeAccess: true};

  const canEditGlobals = lodash.get(apiLoginStore, 'currentUser.user.canEditGlobals', false);

  if (!canEditGlobals) {
    safeFilters.isGlobal = false;
  }

  return safeFilters;
}
