import lodash from 'lodash';
import {action, observable, runInAction} from 'mobx';

import {IN_PROGRESS_STORAGE_KEY} from '../../constants/sessionConstants';
import {parseSourceFromGame} from '../../display/ecs/sourceHelper';
import {toJSRecursive} from '../../utils/mobxHelper';

/**
 * The debounce time for the add point function.
 * @const {number}
 */
const DEBOUNCE_TIME = 500;

/**
 * Updates the checkpoint in the local storage.
 *
 * @param {GameStore} game
 * @param {GameCheckpointStore} store
 */
function updateCheckpoint(game, store) {
  const source = parseSourceFromGame(game, {forHistory: true});
  const entities = source.entities;
  const gameTime = game.timer.time;

  runInAction('updateHistoryCheckpoint', () => {
    const currentPoint = toJSRecursive({
      contentId: store.contentId,
      contentTitle: store.contentTitle,
      entities,
      gameTime,
    });

    localStorage.setItem(IN_PROGRESS_STORAGE_KEY, JSON.stringify(currentPoint));

    store.sync();
  });
}

const debouncedUpdateCheckpoint = lodash.debounce(updateCheckpoint, DEBOUNCE_TIME, {
  leading: false,
  trailing: true,
});

/**
 * The game checkpoint store.
 */
export class GameCheckpointStore {
  /**
   * Whether or not to also store the current point in local storage.
   *
   * @type {boolean}
   */
  @observable saveCheckpoint = true;

  /**
   * The id for the content.
   *
   * @type {?number}
   */
  @observable contentId = null;

  /**
   * The title for the content.
   *
   * @type {string}
   */
  @observable contentTitle = '';

  /**
   * Whether or not a checkpoint exists.
   *
   * @type {boolean}
   */
  @observable checkpointExists = false;

  /**
   * Syncs the local storage checkpoint with this store.
   */
  @action sync() {
    this.checkpointExists = this.hasCheckpoint();
  }

  /**
   * Updates whether or not to store a checkpoint in the local storage.
   *
   * @param {boolean} newValue
   */
  @action setSaveCheckpoint(newValue) {
    this.saveCheckpoint = Boolean(newValue);
  }

  /**
   * Sets the content id.
   *
   * @param {number} newContentId
   */
  @action setContentId(newContentId) {
    this.contentId = newContentId;
  }

  /**
   * Sets the content title.
   *
   * @param {string} newContentTitle
   */
  @action setContentTitle(newContentTitle) {
    this.contentTitle = newContentTitle;
  }

  /**
   * Updates the checkpoint in local storage.
   *
   * @param {GameStore} game
   */
  @action updateCheckpoint(game) {
    if (!this.saveCheckpoint) {
      return;
    }
    debouncedUpdateCheckpoint(game, this);
  }

  /**
   * Removes the checkpoint from local storage.
   */
  clearCheckpoint() {
    localStorage.removeItem(IN_PROGRESS_STORAGE_KEY);

    this.sync();
  }

  /**
   * Gets whether or not a checkpoint exists.
   *
   * @returns {boolean}
   */
  hasCheckpoint() {
    return Boolean(localStorage.getItem(IN_PROGRESS_STORAGE_KEY));
  }

  /**
   * Gets the checkpoint is it exists.
   *
   * @returns {?{gameTime: number, entities: Array.<{}>}}
   */
  getCheckpoint() {
    let checkpoint = null;

    try {
      const fromStorage = localStorage.getItem(IN_PROGRESS_STORAGE_KEY);
      if (fromStorage) {
        checkpoint = JSON.parse(fromStorage);
      }
    } catch (parseError) {
      // We will just return null.
    }

    return checkpoint;
  }
}

export default new GameCheckpointStore();
