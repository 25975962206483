import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import LineItem from './components/LineItem';
import DisplayLineHandle from './components/lineHandle/DisplayLineHandle';

/**
 * The radius of the handle circles.
 * @const {number}
 */
const HANDLE_RADIUS = 10;

/**
 * The DisplayLine component.
 */
export class DisplayLine extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game, style, className} = this.props;

    // Only show the handles if the entity is active.
    const interaction = entity.get('interaction');
    const showHandles = Boolean(interaction && interaction.isActive);

    const entityId = entity.get('id');
    const line = entity.get('line');
    const {startPoint, endPoint, thickness} = line;

    const gameResolution = game.resolution;

    const newStyle = Object.assign({}, style, {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    });

    const middlePoint = {
      x: ((endPoint.x - startPoint.x) / 2) + startPoint.x,
      y: ((endPoint.y - startPoint.y) / 2) + startPoint.y,
    };

    return (
      <svg
        id={entityId}
        className={classNames('display-line', className)}
        style={newStyle}
        viewBox={`0 0 ${gameResolution.width} ${gameResolution.height}`}
        pointerEvents="none"
      >
        <g pointerEvents="visible">
          <LineItem entity={entity} game={game} />

          <DisplayLineHandle
            x={startPoint.x}
            y={startPoint.y}
            game={game}
            isDrag={false}
            isStart={true}
            radius={thickness + HANDLE_RADIUS}
            show={showHandles}
            entity={entity}
          />
          <DisplayLineHandle
            x={middlePoint.x}
            y={middlePoint.y}
            game={game}
            isDrag={true}
            isStart={false}
            radius={thickness + HANDLE_RADIUS}
            show={showHandles}
            entity={entity}
          />
          <DisplayLineHandle
            x={endPoint.x}
            y={endPoint.y}
            game={game}
            isDrag={false}
            isStart={false}
            radius={thickness + HANDLE_RADIUS}
            show={showHandles}
            entity={entity}
          />
        </g>
      </svg>
    );
  }
}

DisplayLine.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,

  className: PropTypes.string,
};

export default observer(DisplayLine);
