import {action, extendObservable, observable} from 'mobx';

import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../constants/asyncConstants';
import serverApi from '../../utils/serverApi';
import {apiStore, getCase} from '../../utils/apiStore';

/**
 * The PasswordResetStore store.
 */
class PasswordResetStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * Stores the passwordReset state
   *
   * @type {Observable<{error: ?Observable>}
   */
  @observable passwordReset = {
    error: null,
  };

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {boolean}
   */
  getFulfilled() {
    return true;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    return this.passwordReset.error;
  }

  /**
   * Gets password reset from the server.
   *
   * @param {{username: string}} usernameOrEmail
   */
  @action sendPasswordReset(usernameOrEmail) {
    this.state = STATE_PENDING;

    serverApi.passwordResetSend(usernameOrEmail).then(
      action('passwordResetSendSuccess', () => {
        this.passwordReset = {
          error: null,
        };
        this.state = STATE_FULFILLED;
      }),
      action('passwordResetSendError', (error) => {
        this.passwordReset = {
          error,
        };
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Clears all the current info and states
   */
  @action clearAll() {
    this.passwordReset.error = null;
    this.state = STATE_PRE;
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => {
      return this.getFulfilled();
    };
    const getRejected = () => {
      return this.getRejected();
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }
}

export default new PasswordResetStore();
