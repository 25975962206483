import lodash from 'lodash';
import {action, observable} from 'mobx';

import {LIBRARY_TYPES} from '../../constants/libraryTypeConstants';
import apiContentLibrariesStore from '../../stores/api/apiContentLibrariesStore';
import {isLibraryWriteable} from '../../utils/writableLibrariesHelper';

/**
 * The active content store.
 */
export class ActiveContentStore {
  /**
   * The Store-related meta-data about the active content libraries
   *
   * @type {{}}
   */
  @observable libraries = {};

  /**
   * The active content id.
   *
   * @type {number}
   */
  @observable contentId = null;

  /**
   * Whether or not to show only Contents where isEditable === true
   *
   * @type {boolean}
   */
  @observable onlyShowEditable = false;

  /**
   * @constructor
   */
  constructor() {
    this.initLibraries();
  }

  /**
   * Initializes each type of library.
   */
  initLibraries() {
    LIBRARY_TYPES.forEach((value) => {
      this.initLibrary(value);
    });
  }

  /**
   * Resets the active content store.
   */
  @action reset() {
    this.setContentId(null);

    LIBRARY_TYPES.forEach((libraryType) => {
      this.setLibraryId(libraryType, null);
      this.setLibraryStoreKey(libraryType, null);
      this.setCategoryId(libraryType, null);
      this.setCategoryStoreKey(libraryType, null);
      this.setCategorySearchFilter(libraryType, '');
      this.setContentStoreKey(libraryType, null);
      this.setContentSearchFilter(libraryType, '');
      this.setContentFilters(libraryType, {});
    });
  }

  /**
   * Initializes a default scaffold for a library type.
   *
   * @param {number} libraryType
   */
  @action initLibrary(libraryType) {
    this.libraries[libraryType] = observable({
      id: null,
      storeKey: null,
      category: null,
      content: null
    });

    this.libraries[libraryType].category = observable({
      id: null,
      storeKey: null,
      searchFilter: '',
    });

    this.libraries[libraryType].content = observable({
      storeKey: null,
      searchFilter: '',
      filters: {},
    });
  }

  /**
   * Sets the active content id.
   *
   * @param {?number} contentId
   */
  @action setContentId(contentId) {
    if (!contentId) {
      this.contentId = null;
      return;
    }

    const numberContentId = Number(contentId);
    if (numberContentId) {
      this.contentId = numberContentId;
    } else {
      this.contentId = contentId;
    }
  }

  /**
   * Sets the library id for a given library type.
   *
   * @param {number} libraryType
   * @param {?number} libraryId
   */
  @action setLibraryId(libraryType, libraryId) {
    this.libraries[libraryType].id = (libraryId !== null) ? Number(libraryId) : null;
  }

  /**
   * Gets the active library id for the given library type.
   *
   * @param {number} libraryType
   * @returns {?number}
   */
  getLibraryId(libraryType) {
    return lodash.get(this.libraries, `${libraryType}.id`, null);
  }

  /**
   * Sets the library storeKey for a given library type.
   *
   * @param {number} libraryType
   * @param {?{key: string, filters: {}}} storeKey
   */
  @action setLibraryStoreKey(libraryType, storeKey) {
    this.libraries[libraryType].storeKey = storeKey;
  }

  /**
   * Sets the category id for a given library type.
   *
   * @param {number} libraryType
   * @param {?(number|boolean)} categoryId
   */
  @action setCategoryId(libraryType, categoryId) {
    this.libraries[libraryType].category.id = (categoryId === false) ? false : Number(categoryId);
  }

  /**
   * Gets the active category id for the given library type.
   *
   * @param {number} libraryType
   * @returns {?number}
   */
  getCategoryId(libraryType) {
    return lodash.get(this.libraries, `${libraryType}.category.id`, null);
  }

  /**
   * Sets the category storeKey for a given library type.
   *
   * @param {number} libraryType
   * @param {?{key: string, filters: {}}} storeKey
   */
  @action setCategoryStoreKey(libraryType, storeKey) {
    this.libraries[libraryType].category.storeKey = storeKey;
  }

  /**
   * Sets the category searchFilter for a given library type.
   *
   * @param {number} libraryType
   * @param {string} searchFilter
   */
  @action setCategorySearchFilter(libraryType, searchFilter) {
    this.libraries[libraryType].category.searchFilter = String(searchFilter);
  }

  /**
   * Sets the content storeKey for a given library type.
   *
   * @param {number} libraryType
   * @param {?{key: string, filters: {}}} storeKey
   */
  @action setContentStoreKey(libraryType, storeKey) {
    this.libraries[libraryType].content.storeKey = storeKey;
  }

  /**
   * Gets the active content storeKey for the given library type.
   *
   * @param {number} libraryType
   * @returns {?{key: string, filters: {}}}
   */
  getContentStoreKey(libraryType) {
    return lodash.get(this.libraries, `${libraryType}.content.storeKey`, null);
  }

  /**
   * Sets the content searchFilter for a given library type.
   *
   * @param {number} libraryType
   * @param {string} searchFilter
   */
  @action setContentSearchFilter(libraryType, searchFilter) {
    this.libraries[libraryType].content.searchFilter = String(searchFilter);
  }

  /**
   * Gets the search term for the given libraryType.
   *
   * @param {number} libraryType
   * @returns {?string}
   */
  getContentSearchFilter(libraryType) {
    return lodash.get(this.libraries, `${libraryType}.content.searchFilter`, null);
  }

  /**
   * Sets the content filter to only show Content where 'isEditable === true'
   *
   * @param {boolean} onlyShowEditable
   */
  @action setOnlyShowEditable(onlyShowEditable) {
    this.onlyShowEditable = Boolean(onlyShowEditable);
  }

  /**
   * Sets the active library's content filters.
   *
   * @param {number} libraryType
   * @param {?{}} filters
   */
  @action setContentFilters(libraryType, filters) {
    this.libraries[libraryType].content.filters = filters;
  }

  /**
   * Checks whether the active content library is writable.
   *
   * @param {number} libraryType
   * @returns {boolean}
   */
  isActiveLibraryWritable(libraryType) {
    const activeContentLibrary = this.libraries[libraryType];
    if (!activeContentLibrary.storeKey || !activeContentLibrary.id) {
      return false;
    }

    const contentLibrary = apiContentLibrariesStore.getLibrary(
      activeContentLibrary.storeKey,
      activeContentLibrary.id
    );

    if (!contentLibrary) {
      return false;
    }

    return isLibraryWriteable(contentLibrary);
  }
}

export default new ActiveContentStore();
