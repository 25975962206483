import {action, when} from 'mobx';

import serverApi from '../../utils/serverApi';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../constants/asyncConstants';
import SingleResourceBaseStore from './common/apiSingleResourceBaseStore';
import {EXPIRE_TIME, EXPIRES_IN} from '../../constants/storeConstants';

/**
 * The content usage store
 */
class ContentUsageStore extends SingleResourceBaseStore {
  /**
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Fetches whether or not a piece of content is currently in use.
   *
   * @param {number} rawContentId
   */
  @action fetchContentUsageById(rawContentId) {
    const contentId = String(rawContentId);
    this.state = STATE_PENDING;

    if (!this.isResourceAvailable(contentId)) {
      serverApi.contentInUse(contentId).then(
        action('getContentUsageSuccess', (response) => {
          this.resourcesById.set(contentId, {
            [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
            state: STATE_FULFILLED,
            resource: response.inUse,
            error: null
          });
          this.state = STATE_FULFILLED;
        }),
        action('getContentUsageError', (error) => {
          this.resourcesById.set(contentId, {
            [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
            state: STATE_REJECTED,
            resource: null,
            error,
          });
          this.state = STATE_REJECTED;
        })
      );
    }
  }

  /**
   * Gets a promise for this store.
   *
   * @param {string} rawContentId
   * @returns {Promise}
   */
  getPromise(rawContentId) {
    const contentId = String(rawContentId);
    const thisStore = this;

    return new Promise((resolve, reject) => {
      when(
        () => {
          const usageData = thisStore.resourcesById.get(contentId);
          const state = (usageData) ? usageData.state : null;
          return (state === STATE_FULFILLED || state === STATE_REJECTED);
        },
        () => {
          if (thisStore.state === STATE_REJECTED) {
            reject(this.getRejected(contentId));
            return;
          }

          resolve(this.getFulfilled(contentId));
        },
        {name: 'apiContentUsageStoreGetPromise'}
      );
    });
  }
}

export default new ContentUsageStore();
