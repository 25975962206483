import classNames from 'classnames';
import lodash from 'lodash';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {
  THUMBNAIL_MAX_WIDTH,
  THUMBNAIL_MAX_HEIGHT,
  getContentFileHasFile,
  getPreviewUrlForContent,
  getThumbnailUrlForContent,
  getAspectRatioFit,
  hasProcessingError,
  isProcessing,
} from '../../../utils/contentsHelper';

import './displayContentSrc.scss';

/**
 * The DisplayContentSrc component.
 *
 * @constructor
 */
export class DisplayContentSrc extends React.Component {
  /**
   * Renders the display object.
   *
   * @param {{isImage: boolean, url: string}} src
   * @returns {{}}
   */
  renderSrcDisplay = (src) => {
    if (!src.url) {
      return (<div className="content-main content-empty" />);
    }

    const {contentSize, maxSize} = this.props;

    let sizeStyles = {};
    if (contentSize) {
      const aspectRatio = getAspectRatioFit(
        contentSize.width,
        contentSize.height,
        (maxSize && maxSize.width) ? maxSize.width : THUMBNAIL_MAX_WIDTH,
        (maxSize && maxSize.height) ? maxSize.height : THUMBNAIL_MAX_HEIGHT,
      );

      sizeStyles.height = `${aspectRatio.height}px`;
      sizeStyles.width = `${aspectRatio.width}px`;
    }

    return (src.isImage) ? (
      <img className="content-main content-image" src={src.url} style={sizeStyles} />
    ) : (
      <video
        className="content-main content-video"
        src={src.url}
        autoPlay
        controls
        controlsList="nodownload"
        style={sizeStyles}
      />
    );
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {content, isImage, thumbnail} = this.props;

    const firstContentFile = lodash.get(content, 'contentFiles[0]', null);

    let hasPreviewFile = false;
    if (firstContentFile) {
      hasPreviewFile = getContentFileHasFile(
        firstContentFile,
        'previewFileName',
      );
    }

    const hasError = hasProcessingError(content);

    const isContentProcessing = isProcessing(content) || !hasPreviewFile || hasError;
    const src = (thumbnail) ? {
      isImage: true,
      url: getThumbnailUrlForContent(content)
    } : getPreviewUrlForContent(content, !isImage);

    const srcWithCacheBust = {...src};
    if (firstContentFile && src.url) {
      srcWithCacheBust.url = `${src.url}?ts=${firstContentFile.updateDate}`;
    }

    const extraClasses = {
      'is-processing': isContentProcessing,
    };

    return (
      <div className={classNames('display-content-src', extraClasses)}>
        {this.renderSrcDisplay(srcWithCacheBust)}

        {(isContentProcessing) && (
          <div className="processing-title">
            <span className="h2">{(hasError) ? 'Processing Error' : 'Processing...'}</span>
          </div>
        )}
      </div>
    );
  }
}

DisplayContentSrc.propTypes = {
  content: PropTypes.object.isRequired,
  isImage: PropTypes.bool.isRequired,
  libraryType: PropTypes.number.isRequired,

  contentSize: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }),
  maxSize: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }),
  thumbnail: PropTypes.bool,
};

DisplayContentSrc.defaultProps = {
  thumbnail: false,
};

export default observer(DisplayContentSrc);
