/* eslint-disable no-process-env, no-magic-numbers */

import env from './env';

const apiDomains = {};
const protocol = process.env.CREATE24_SKIP_SECURE === '1' ? 'http://' : 'https://';

if (process.env.NODE_ENV === 'production' && (!process.env.IS_STAGING || process.env.IS_STAGING === '0')) {
  apiDomains.auth = `${protocol}auth.api.works24.com`;
  apiDomains.content = `${protocol}content.api.works24.com`;
  apiDomains.admin = `${protocol}admin.api.works24.com`;
  apiDomains.delivery = `${protocol}delivery.api.works24.com`;
  apiDomains.upload = `${protocol}upload.api.works24.com`;
} else {
  apiDomains.auth = `${protocol}auth-dev.api.works24.com`;
  apiDomains.content = `${protocol}content-dev.api.works24.com`;
  apiDomains.admin = `${protocol}admin-dev.api.works24.com`;
  apiDomains.delivery = `${protocol}delivery-dev.api.works24.com`;
  apiDomains.upload = `${protocol}upload-dev.api.works24.com`;
}

export default {
  env: env,

  api: apiDomains,

  googleAnalytics: {
    trackId: process.env.GOOGLE_ANALYTICS_ID || null,
  },
};
