import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line no-shadow
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './transitionWrapper.scss';

// value set for the required timeout property
const timeOutVal = 600;

/**
 * The transitionWrapper component.
 */
class TransitionWrapper extends React.Component {
  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <div id="transitionWrapper">
        <TransitionGroup>
          <CSSTransition
            classNames="slide-in"
            timeout={timeOutVal}
            appear={true}
          >
            {this.props.children}
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}

TransitionWrapper.propTypes = {
  children: PropTypes.element,
};

export default TransitionWrapper;
