import {reaction} from 'mobx';
import lodash from 'lodash';

import sessionStore from '../../stores/active/sessionStore';
import apiClientStore from '../../stores/api/apiClientStore';
import apiProductsStore from '../../stores/api/apiProductsStore';
import apiFeedSummaryStore from '../../stores/api/apiFeedSummaryStore';
import apiLoginStore from '../../stores/api/apiLoginStore';

/**
 * The disposer function for the mobX reaction().
 *
 * @type {?function}
 */
let reactionDispose = null;

/**
 * Initializes reaction for clientId changes.
 */
export function init() {
  reactionDispose = reaction(
    () => sessionStore.clientId,
    onClientChange
  );
}

/**
 * Performs logic when clientId changes.
 *
 * @param {?number} clientId
 */
function onClientChange(clientId) {
  sessionStore.setProduct(null);

  if (clientId) {
    const user = apiLoginStore.preloadCurrentUser();
    apiClientStore.fetchClientById(clientId);
    apiProductsStore.fetchProducts(clientId);
    const includeGlobals = lodash.includes(
      ['Administrator', 'CSR', 'Video Content Contractor', 'Video Content Creator'],
      lodash.get(user, 'user.role')
    );
    apiFeedSummaryStore.fetchFeedsByClientId(clientId, includeGlobals);
  }
}

/**
 * Disposes of the thunk.
 * This is useful for hot-reloading purposes.
 */
export function dispose() {
  if (reactionDispose) {
    reactionDispose();
  }
}

export default init;
