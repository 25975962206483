import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import EditTimelineControls from '../editTimeline/EditTimelineControls';

import './editVideoControls.scss';

/**
 * The EditVideoControls component.
 */
export class EditVideoControls extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;

    return (
      <div className="edit-video-controls">
        <div className="video-group">
          <h5>Video</h5>
        </div>
        <EditTimelineControls entity={entity} game={game} />
      </div>
    );
  }
}

EditVideoControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditVideoControls);
