import lodash from 'lodash';
import {runInAction, toJS} from 'mobx';

import {isLocked} from '../components/common/lockedComponent';
import {clearTransitionCache, updateEntity} from '../ecs/entityHelper';
import {mapShapeToRotatedFrame} from '../../utils/mathHelper';

/**
 * The name of the system.
 * @const {string}
 */
export const POSITIONING_SYSTEM = 'positioningSystem';

/**
 * The padding around the edges of the screen that items must allow.
 * @const {number}
 */
const SIZE_PADDING = 10;

/**
 * Gets a new instance of the position system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function positioningSystem(game) {
  /**
   * Called when the game loop updates.
   *
   * @param {Array.<{}>} actions
   */
  function systemRunActions(actions) {
    actions.forEach((actionEntity) => {
      // First check for required components.
      if (!actionEntity.has('actionPosition')) {
        return;
      }

      const entityId = actionEntity.get('action').entityId;
      const entity = game.getEntity(entityId);
      if (!entity) {
        return;
      }

      const actionPosition = actionEntity.get('actionPosition');
      const moveCrop = Boolean(actionPosition.moveCrop);

      const element = entity.get('element');

      processLocked(actionPosition, entity);

      let checked = {};
      if (element === 'image') {
        checked = checkImageBoundaries(actionEntity, entity, moveCrop, entity.get('image'));
      } else if (element === 'feedImage') {
        checked = checkImageBoundaries(actionEntity, entity, moveCrop, entity.get('feedImage'));
      } else if (element === 'line') {
        checked = parseLineChanges(actionEntity, entity);
      } else {
        checked = checkBoundaries(actionEntity, entity);
      }

      const {safePosition, safeSize, safeCrop, safeImage, safeLine} = checked;

      // Now update the entity.
      runInAction('positioningSystemUpdateEntity', () => {
        if (safePosition) {
          // Make sure the defaults were also updated.
          safePosition.default.top = safePosition.top;
          safePosition.default.left = safePosition.left;
          safePosition.default.rotate = safePosition.rotate;

          updateEntity(entity, 'position', safePosition);
        }
        if (safeSize) {
          updateEntity(entity, 'size', safeSize);
        }
        if (safeImage) {
          if (element === 'feedImage') {
            updateEntity(entity, 'feedImage', safeImage);
          } else {
            updateEntity(entity, 'image', safeImage);
          }
        }
        if (safeCrop) {
          updateEntity(entity, 'crop', safeCrop);
        }
        if (safeLine) {
          updateEntity(entity, 'line', safeLine);
        }

        clearTransitionCache(entity);
      });
    });
  }

  /**
   * Alters the action position data based on locks on the entity.
   * This function alters the action position object by reference.
   *
   * @param {{}} actionPosition
   * @param {{}} entity
   */
  function processLocked(actionPosition, entity) {
    if (!actionPosition.positionDelta) {
      actionPosition.positionDelta = {};
      if (!actionPosition.positionDelta.top) {
        actionPosition.positionDelta.top = 0;
      }
      if (!actionPosition.positionDelta.left) {
        actionPosition.positionDelta.left = 0;
      }
    }

    if (!actionPosition.sizeDelta) {
      actionPosition.sizeDelta = {};
      if (!actionPosition.sizeDelta.height) {
        actionPosition.sizeDelta.height = 0;
      }
      if (!actionPosition.sizeDelta.width) {
        actionPosition.sizeDelta.width = 0;
      }
    }

    processLockedRotation(actionPosition, entity);

    processLockedAspectRatio(actionPosition, entity);

    processLockedToEdge(actionPosition, entity);
  }

  /**
   * Process entities that have a locked aspect ratio.
   *
   * @param {{}} actionPosition
   * @param {{}} entity
   */
  function processLockedRotation(actionPosition, entity) {
    if (!isLocked(entity, 'rotation')) {
      return;
    }

    actionPosition.newRotate = 0;
  }

  /**
   * Process entities that have a locked aspect ratio.
   *
   * @param {{}} actionPosition
   * @param {{}} entity
   */
  function processLockedAspectRatio(actionPosition, entity) {
    if (!actionPosition.isResize || !isLocked(entity, 'aspectRatio')) {
      return;
    }

    const entitySize = entity.get('size');

    const aspectRatio = entitySize.width / entitySize.height;

    const positionDelta = actionPosition.positionDelta || {};
    const sizeDelta = actionPosition.sizeDelta || {};

    if (Math.abs(sizeDelta.height) > Math.abs(sizeDelta.width)) {
      sizeDelta.width = sizeDelta.height * aspectRatio;

      if (positionDelta.left) {
        positionDelta.left = 0 - sizeDelta.width;
      }
    } else {
      sizeDelta.height = sizeDelta.width / aspectRatio;

      if (positionDelta.top) {
        positionDelta.top = 0 - sizeDelta.height;
      }
    }
  }

  /**
   * Process entities that have their position locked to an edge.
   * This function prevents items from leaving the canvas.
   *
   * @param {{}} actionPosition
   * @param {{}} entity
   */
  function processLockedToEdge(actionPosition, entity) {
    if (!isLocked(entity, 'toEdge')) {
      return;
    }

    const entityPosition = entity.get('position');
    const entitySize = entity.get('size');

    const {positionDelta, sizeDelta} = actionPosition;

    const isHeightOverflow = (
      (entityPosition.top + positionDelta.top < 0)
      || (entityPosition.left + positionDelta.left < 0)
      || (entityPosition.top + positionDelta.top + entitySize.height + sizeDelta.height > game.resolution.height)
    );
    const isWidthOverflow = (
      (entitySize.height + sizeDelta.height < 0)
      || (entitySize.width + sizeDelta.width < 0)
      || (entityPosition.left + positionDelta.left + entitySize.width + sizeDelta.width > game.resolution.width)
    );

    if (isHeightOverflow || isWidthOverflow) {
      positionDelta.top = 0;
      positionDelta.left = 0;
      sizeDelta.height = 0;
      sizeDelta.width = 0;
    }
  }

  /**
   * Gets the deltas.
   *
   * @param {{}} actionEntity
   * @returns {{positionDelta: {}, sizeDelta: {}, newRotate: ?number}}
   */
  function getDeltas(actionEntity) {
    const {isResize, positionDelta, sizeDelta, newRotate} = actionEntity.get('actionPosition');

    return {
      isResize,
      positionDelta: (positionDelta) ? toJS(positionDelta) : null,
      sizeDelta: (sizeDelta) ? toJS(sizeDelta) : null,
      newRotate: (newRotate || newRotate === 0) ? newRotate : null,
    };
  }

  /**
   * Checks to see if the size or position changes takes the item over the boundary.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @returns {{safePosition: ?{top: number, left: number}, safeSize: ?{width: number, height: number}}}
   */
  function checkBoundaries(actionEntity, entity) {
    const {isResize} = getDeltas(actionEntity);

    if (!isResize) {
      // The move check is much simpler than the resize check.
      return checkMoveBoundaries(actionEntity, entity);
    }

    return checkResizeBoundaries(actionEntity, entity);
  }

  /**
   * Checks to see if the position changes on move takes the item over the boundary.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @returns {{safePosition: ?{top: number, left: number}, safeSize: ?{width: number, height: number}}}
   */
  function checkMoveBoundaries(actionEntity, entity) {
    const gameResolution = game.resolution;

    const {safePosition, safeSize} = getNewPositionAndSize(actionEntity, entity);

    const isBoundToEdge = enforceEdgeBinding(entity, gameResolution, {
      position: safePosition,
      size: safeSize,
    });

    if (!isBoundToEdge) {
      const boundaryChanges = enforceOutsideBoundaries(
        safeSize,
        safePosition,
        gameResolution
      );

      safePosition.top = boundaryChanges.top;
      safePosition.left = boundaryChanges.left;
    }

    return {
      safePosition,
      safeSize,
    };
  }

  /**
   * Checks to see if the position or size changes on resize takes the item over the boundary.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @returns {{safePosition: ?{top: number, left: number}, safeSize: ?{width: number, height: number}}}
   */
  function checkResizeBoundaries(actionEntity, entity) {
    const {safePosition, safeSize} = getNewPositionAndSize(actionEntity, entity);

    const gameResolution = game.resolution;

    const limitTop = SIZE_PADDING;
    const limitLeft = SIZE_PADDING;
    const limitBottom = gameResolution.height - SIZE_PADDING;
    const limitRight = gameResolution.width - SIZE_PADDING;

    const startWidth = safePosition.left;
    const endWidth = safePosition.left + safeSize.width;
    const startHeight = safePosition.top;
    const endHeight = safePosition.top + safeSize.height;

    let truncateHeight = 0;
    let truncateWidth = 0;

    if (endWidth < limitLeft) {
      truncateWidth = endWidth - limitLeft;
    } else if (startWidth > limitRight) {
      truncateWidth = limitRight - startWidth;
      safePosition.left += truncateWidth;
    }

    if (endHeight < limitTop) {
      truncateHeight = endHeight - limitTop;
    } else if (startHeight > limitBottom) {
      truncateHeight = limitBottom - startHeight;
      safePosition.top += truncateHeight;
    }

    if (safeSize && (truncateWidth || truncateHeight)) {
      safeSize.width -= truncateWidth;
      safeSize.height -= truncateHeight;
    }

    // Make sure the item doesn't become too small.
    const finalSize = enforceMaximumSize(
      enforceMinimumSize(safeSize, entity),
      gameResolution
    );

    return {
      safePosition,
      safeSize: finalSize,
    };
  }

  /**
   * Checks to see if the size or position changes takes the item over the boundary.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @param {boolean} moveCrop
   * @param {{}} imageComponent
   * @returns {{safeCrop: ?{}, safeImage: ?{}, safePosition: ?{}, safeSize: ?{}}}
   */
  function checkImageBoundaries(actionEntity, entity, moveCrop, imageComponent) {
    const {isResize, newRotate} = getDeltas(actionEntity);
    let {safeCrop, safeImage, safePosition} = getNewImageAndPositionAndSize(
      actionEntity,
      entity,
      moveCrop,
      imageComponent
    );

    let {boundaryCrop, boundaryImage} = checkImageCroppingBoundaries(actionEntity, entity, safeImage, safeCrop);

    safeCrop = boundaryCrop;
    safeImage = boundaryImage;

    if (!isResize && !moveCrop && !newRotate && newRotate !== 0) {
      safeCrop = maintainCropPosition(entity, safeImage, safeCrop, safePosition);
    }

    return {
      safeCrop,
      safeImage,
      safePosition,
    };
  }

  /**
   * Checks to see if the size or position extend over a boundary.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @param {{}} safeImage
   * @param {{}} safeCrop
   * @returns {{boundaryCrop: ?{}, boundaryImage: ?{}}}
   */
  function checkImageCroppingBoundaries(actionEntity, entity, safeImage, safeCrop) {
    const {isResize} = getDeltas(actionEntity);

    if (!isResize) {
      // The move check is much simpler than the resize check.
      return checkImageMoveBoundaries(actionEntity, entity, safeImage, safeCrop);
    }

    return checkImageResizeBoundaries(actionEntity, entity, safeImage, safeCrop);
  }

  /**
   * Checks to see if the position changes on move takes the image or crop over the boundary.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @param {{}} safeImage
   * @param {{}} safeCrop
   * @returns {{boundaryImage: {}, boundaryCrop: {}}}
   */
  function checkImageMoveBoundaries(actionEntity, entity, safeImage, safeCrop) {
    const gameResolution = game.resolution;

    const boundaryImage = lodash.cloneDeep(safeImage);
    const boundaryCrop = lodash.cloneDeep(safeCrop);

    const boundaryChanges = enforceOutsideBoundaries(
      boundaryImage,
      boundaryImage,
      gameResolution
    );

    const topDiff = boundaryChanges.top - boundaryImage.top;
    const leftDiff = boundaryChanges.left - boundaryImage.left;

    boundaryImage.top = boundaryChanges.top;
    boundaryImage.left = boundaryChanges.left;
    boundaryCrop.top += topDiff;
    boundaryCrop.left += leftDiff;

    return {boundaryImage, boundaryCrop};
  }

  /**
   * Checks to see if the position or size changes on resize takes the image or crop over the boundary.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @param {{}} safeImage
   * @param {{}} safeCrop
   * @returns {{boundaryImage: {}, boundaryCrop: {}}}
   */
  function checkImageResizeBoundaries(actionEntity, entity, safeImage, safeCrop) {
    const gameResolution = game.resolution;

    let boundaryImage = lodash.cloneDeep(safeImage);
    let boundaryCrop = lodash.cloneDeep(safeCrop);

    const limitTop = SIZE_PADDING;
    const limitLeft = SIZE_PADDING;
    const limitBottom = gameResolution.height - SIZE_PADDING;
    const limitRight = gameResolution.width - SIZE_PADDING;

    const startWidth = boundaryImage.left;
    const endWidth = boundaryImage.left + boundaryImage.width;
    const startHeight = boundaryImage.top;
    const endHeight = boundaryImage.top + boundaryImage.height;

    let truncateHeight = 0;
    let truncateWidth = 0;

    if (endWidth < limitLeft) {
      truncateWidth = endWidth - limitLeft;
    } else if (startWidth > limitRight) {
      truncateWidth = limitRight - startWidth;
      boundaryImage.left += truncateWidth;
      boundaryCrop.left += truncateWidth;
    }

    if (endHeight < limitTop) {
      truncateHeight = endHeight - limitTop;
    } else if (startHeight > limitBottom) {
      truncateHeight = limitBottom - startHeight;
      boundaryImage.top += truncateHeight;
      boundaryCrop.top += truncateHeight;
    }

    if (truncateWidth || truncateHeight) {
      boundaryImage.width -= truncateWidth;
      boundaryImage.height -= truncateHeight;
      boundaryCrop.width -= truncateWidth;
      boundaryCrop.height -= truncateHeight;
    }

    // Make sure the image and crop don't become too small.
    const adjustedImage = enforceMaximumSize(
      enforceMinimumSize(boundaryImage, entity),
      gameResolution
    );
    const adjustedCrop = enforceMaximumSize(
      enforceMinimumSize(boundaryCrop, entity),
      gameResolution
    );

    // Keep the image center in the safe position.
    const centerSafeImage = enforceImageCenterOnResize(entity, adjustedImage);
    adjustedCrop.left += (centerSafeImage.left - boundaryImage.left);
    adjustedCrop.top += (centerSafeImage.top - boundaryImage.top);

    boundaryImage = centerSafeImage;
    boundaryCrop = adjustedCrop;

    return {boundaryImage, boundaryCrop};
  }

  /**
   * Enforces the minimum size of the item.
   *
   * @param {{width: number, height: number, minSizeMultiplier: ?number}} item
   * @param {{}} entity
   * @returns {{width: number, height: number}}
   */
  function enforceMinimumSize(item, entity) {
    const enforcedItem = {...item};

    const isAspectRatioLocked = isLocked(entity, 'aspectRatio');
    let minWidth = SIZE_PADDING;
    let minHeight = SIZE_PADDING;

    if (item.minSizeMultiplier && Number(item.minSizeMultiplier)) {
      minWidth = game.resolution.width * item.minSizeMultiplier;
      minHeight = game.resolution.height * item.minSizeMultiplier;
    } else if (isAspectRatioLocked) {
      minWidth *= 2;
      minHeight *= 2;
    }

    if (entity && isAspectRatioLocked) {
      const entitySize = entity.get('size');

      const aspectMultiplier = entitySize.width / entitySize.height;

      // Convert the current minWidth and minHeight to the new aspect ratio.
      const startingHeight = minWidth / aspectMultiplier;
      const finalHeight = startingHeight + ((minHeight - startingHeight) / 2);
      const finalWidth = finalHeight * aspectMultiplier;

      minWidth = finalWidth;
      minHeight = finalHeight;
    }

    if (enforcedItem.width < minWidth) {
      enforcedItem.width = minWidth;
    }
    if (enforcedItem.height < minHeight) {
      enforcedItem.height = minHeight;
    }

    return enforcedItem;
  }

  /**
   * Enforces the maximum size of the item.
   *
   * @param {{width: number, height: number}} item
   * @param {{width: number, height: number}}resolution
   * @returns  {{width: number, height: number}}
   */
  function enforceMaximumSize(item, resolution) {
    const enforcedItem = {...item};

    const maxMultiplier = 1.65;
    const maxHeight = resolution.height * maxMultiplier;
    const maxWidth = resolution.width * maxMultiplier;

    if (enforcedItem.width > maxWidth) {
      enforcedItem.width = maxWidth;
    }
    if (enforcedItem.height > maxHeight) {
      enforcedItem.height = maxHeight;
    }

    return enforcedItem;
  }

  /**
   * Keeps the image's center coordinates the same as they were before to prevent weird resizing.
   *
   * @param {{}} entity
   * @param {{left: number, top: number, width: number, height: number}} safeImage
   * @returns {{left: number, top: number}}
   */
  function enforceImageCenterOnResize(entity, safeImage) {
    const newItem = {...safeImage};

    /**
     * Disabled for now.
     */
    // const currentImage = entity.get('image');
    // const currentImageCenter = {
    //   x: currentImage.left + (currentImage.width / 2),
    //   y: currentImage.top + (currentImage.height / 2),
    // };
    // const newImageCenter = {
    //   x: safeImage.left + (safeImage.width / 2),
    //   y: safeImage.top + (safeImage.height / 2),
    // };
    //
    // // Keep the image center in the safe position.
    // newItem.left = safeImage.left + (currentImageCenter.x - newImageCenter.x);
    // newItem.top = safeImage.top + (currentImageCenter.y - newImageCenter.y);

    return newItem;
  }

  /**
   * Maintains the position of the crop window
   *
   * @param {{}} entity
   * @param {{left: number, top: number, width: number, height: number}} safeImage
   * @param {{left: number, top: number, width: number, height: number}} safeCrop
   * @param {{rotate: number}} safePosition
   * @returns {{left: number, top: number}}
   */
  function maintainCropPosition(entity, safeImage, safeCrop, safePosition) {
    if (!safeCrop) {
      return safeCrop;
    }

    const currentCrop = toJS(entity.get('crop'));
    const currentImage = entity.get('image') || entity.get('feedImage');
    const currentPosition = entity.get('position');

    const currentOrigin = {
      x: currentImage.left,
      y: currentImage.top,
    };
    const newOrigin = {
      x: safeImage.left,
      y: safeImage.top,
    };

    const originRotated = mapShapeToRotatedFrame(currentPosition.rotate, currentCrop, currentOrigin);
    const newRotated = mapShapeToRotatedFrame(0 - safePosition.rotate, originRotated, newOrigin);

    return newRotated;
  }

  /**
   * Parses the position and size components based on the changes/deltas.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @returns {{safePosition: {}, safeSize: {}}}
   */
  function getNewPositionAndSize(actionEntity, entity) {
    const {positionDelta, sizeDelta, newRotate} = getDeltas(actionEntity);

    const currentPosition = entity.get('position');
    const currentSize = entity.get('size');

    const safePosition = lodash.cloneDeep(currentPosition);
    const safeSize = lodash.cloneDeep(currentSize);

    if (positionDelta && positionDelta.top) {
      safePosition.top += positionDelta.top;
    }
    if (positionDelta && positionDelta.left) {
      safePosition.left += positionDelta.left;
    }
    if (sizeDelta && sizeDelta.width) {
      safeSize.width += sizeDelta.width;
    }
    if (sizeDelta && sizeDelta.height) {
      safeSize.height += sizeDelta.height;
    }
    if (newRotate !== null && newRotate !== undefined) {
      safePosition.rotate = newRotate;
    }

    return {
      safePosition,
      safeSize,
    };
  }

  /**
   * Parses the image and position and size components based on the changes/deltas.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @param {boolean} moveCrop
   * @param {{}} imageComponent
   * @returns {{safeCrop: {}, safeImage: {}, safePosition: {}}}
   */
  function getNewImageAndPositionAndSize(actionEntity, entity, moveCrop, imageComponent) {
    const {positionDelta, sizeDelta, newRotate} = getDeltas(actionEntity);

    const currentCrop = entity.get('crop');
    const currentPosition = entity.get('position');

    const safeCrop = lodash.cloneDeep(currentCrop);
    const safeImage = lodash.cloneDeep(imageComponent);
    const safePosition = lodash.cloneDeep(currentPosition);

    if (positionDelta && positionDelta.top) {
      safeImage.top += positionDelta.top;

      if (moveCrop) {
        safeCrop.top += positionDelta.top;
      }
    }
    if (positionDelta && positionDelta.left) {
      safeImage.left += positionDelta.left;

      if (moveCrop) {
        safeCrop.left += positionDelta.left;
      }
    }
    if (sizeDelta && sizeDelta.width) {
      safeImage.width += sizeDelta.width;

      if (moveCrop) {
        safeCrop.width += sizeDelta.width;
      }
    }
    if (sizeDelta && sizeDelta.height) {
      safeImage.height += sizeDelta.height;

      if (moveCrop) {
        safeCrop.height += sizeDelta.height;
      }
    }
    if (newRotate !== null && newRotate !== undefined) {
      safePosition.rotate = newRotate;
    }

    return {
      safeCrop,
      safeImage,
      safePosition,
    };
  }

  /**
   * Parses updates to the line entity.
   *
   * @param {{}} actionEntity
   * @param {{}} entity
   * @returns {{safeLine: {}}}
   */
  function parseLineChanges(actionEntity, entity) {
    const currentLine = toJS(entity.get('line'));
    const safeLine = lodash.cloneDeep(currentLine);

    const {endPoint, startPoint} = safeLine;

    const {positionDelta, sizeDelta} = getDeltas(actionEntity);

    if (positionDelta && positionDelta.top) {
      startPoint.y += positionDelta.top;
      endPoint.y += positionDelta.top;
    }
    if (positionDelta && positionDelta.left) {
      startPoint.x += positionDelta.left;
      endPoint.x += positionDelta.left;
    }
    if (sizeDelta && sizeDelta.width) {
      startPoint.x += sizeDelta.width;
    }
    if (sizeDelta && sizeDelta.height) {
      startPoint.y += sizeDelta.height;
    }

    return {safeLine};
  }

  return {
    name: POSITIONING_SYSTEM,
    runActions: systemRunActions
  };
}

/**
 * Enforces that items can't entirely leave the viewing area, but allows it to drift outside a bit.
 *
 * @param {{height: number, width: number}} size
 * @param {{left: number, top: number}} position
 * @param {{height: number, width: number}} gameResolution
 * @returns {{left: number, top: number, truncateHeight: number, truncateWidth: number}}
 */
function enforceOutsideBoundaries(size, position, gameResolution) {
  const padding = 10;
  const limitTop = 0 - size.height + padding;
  const limitLeft = 0 - size.width + padding;
  const limitBottom = gameResolution.height - padding;
  const limitRight = gameResolution.width - padding;

  // Make sure the new position won't push the item fully off the display.
  let newTop = position.top;
  if (newTop < limitTop) {
    newTop = limitTop;
  } else if (newTop > limitBottom) {
    newTop = limitBottom;
  }

  let newLeft = position.left;
  if (newLeft < limitLeft) {
    newLeft = limitLeft;
  } else if (newLeft > limitRight) {
    newLeft = limitRight;
  }

  return {
    top: newTop,
    left: newLeft,
    truncateHeight: newTop - position.top,
    truncateWidth: newLeft - position.left,
  };
}

/**
 * Enforces that items can't be moved in any way outside of the game boundaries.
 *
 * @param {{height: number, width: number}} size
 * @param {{left: number, top: number}} position
 * @param {{height: number, width: number}} gameResolution
 * @returns {{left: number, top: number, truncateHeight: number, truncateWidth: number}}
 */
function enforceInsideBoundaries(size, position, gameResolution) { // eslint-disable-line no-unused-vars
  // Make sure the new position won't push the item off the display.
  let newTop = position.top;
  if (newTop < 0) {
    newTop = 0;
  } else if (newTop + size.height > gameResolution.height) {
    newTop = gameResolution.height - size.height;
  }

  let newLeft = position.left;
  if (newLeft < 0) {
    newLeft = 0;
  } else if (newLeft + size.width > gameResolution.width) {
    newLeft = gameResolution.width - size.width;
  }

  return {
    top: newTop,
    left: newLeft,
    truncateHeight: newTop - position.top,
    truncateWidth: newLeft - position.left,
  };
}

/**
 * Forces the entity to stick to one of the canvas edges.
 *
 * @param {{}} entity
 * @param {{height: number, width: number}} gameResolution
 * @param {{}} components
 * @param {{left: number, top: number}} components.position
 * @param {{height: number, width: number}} components.size
 * @returns {boolean}
 */
function enforceEdgeBinding(entity, gameResolution, {position, size}) {
  if (!isLocked(entity, 'toEdge')) {
    return false;
  }

  if (position.top === 0 || position.left === 0) {
    return true;
  } else if (position.top + size.height === gameResolution.height) {
    return true;
  } else if (position.left + size.width === gameResolution.width) {
    return true;
  }

  const diffs = [
    {label: 'top', value: position.top},
    {label: 'left', value: position.left},
    {label: 'right', value: gameResolution.width - position.left - size.width},
    {label: 'bottom', value: gameResolution.height - position.top - size.height},
  ];
  const closestBorder = lodash.minBy(diffs, 'value');

  if (closestBorder.label === 'top') {
    position.top = 0;
  } else if (closestBorder.label === 'left') {
    position.left = 0;
  } else if (closestBorder.label === 'right') {
    position.left = gameResolution.width - size.width;
  } else if (closestBorder.label === 'bottom') {
    position.top = gameResolution.height - size.height;
  }

  return true;
}

/**
 * Gets whether or not the delta has any non-zero values.
 *
 * @param {Object.<string, number>} delta
 * @returns {boolean}
 */
function getDeltaHasChanges(delta) { // eslint-disable-line no-unused-vars
  if (!delta) {
    return false;
  }

  return lodash.some(delta, (value) => {
    return Boolean(value);
  });
}
