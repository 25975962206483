import { action, observable } from 'mobx';
import { Link } from 'react-router-dom';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import React from 'react';

import TransitionWrapper from '../../../../layouts/transitionWrapper/TransitionWrapper';
import inject from '../../../../hoc/injectHoc';
import * as routePaths from '../../../../routePaths';
import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';
import {routeHistory} from '../../../../../utils/history';

import './passwordResetForm.scss';

/**
 * The PasswordResetPage component.
 */
export class PasswordResetForm extends React.Component {
  /**
   * Username form value
   *
   * @type {string}
   */
  @observable username = '';

  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);
  }

  /**
   * Password Reset click handler
   *
   * @param {{}} clickEvent
   */
  @action resetClick = (clickEvent) => {
    clickEvent.preventDefault();
    this.props.apiPasswordResetStore.sendPasswordReset(this.username);
    this.username = '';
  }

  /**
   * Update property [key] to be [value]
   * @param {string} key
   * @param {*} value
   */
  @action updateProperty = (key, value) => {
    const safeSearch = String(value).replace(/[^a-zA-Z0-9@.]/ig, '');
    this[key] = safeSearch;
  }

  /**
   * Login input onChange handler
   *
   * @param {{}} changeEvent
   */
  onChange = (changeEvent) => {
    this.updateProperty(changeEvent.target.name, changeEvent.target.value);
  }

  /**
   * Callback after a successful password reset
   */
  passwordResetSuccess = () => {
    const redirectDelay = 5000;
    setTimeout(() => {
      routeHistory.push(routePaths.loginRoute);
      this.props.apiPasswordResetStore.clearAll();
    }, redirectDelay);
  }

  /**
   * Triggered when part of the component's mobX state is about to change.
   */
  componentWillReact() {
    this.props.apiPasswordResetStore.case({
      fulfilled: () => ( this.passwordResetSuccess() ),
    });
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <form id="password-reset-form" onSubmit={this.resetClick}>
        <TransitionWrapper>
          <div>
            <p className="instructions-format">
              Enter your username or email address and we&apos;ll email you instructions to reset your password.
            </p>
            <div className="form-group">
              <label className="sr-only" htmlFor="username">Username</label>
              <input
                required="required"
                type="text"
                value={this.username}
                name="username"
                onChange={this.onChange}
                className="form-control"
                id="username"
                placeholder="Enter your username"
              />
            </div>
            <input
              type="submit"
              className="btn btn-primary btn-block"
              value="Submit"
              disabled={this.username.length === 0}
            />

            {this.props.apiPasswordResetStore.case({
              pending: () => (<LoadingIcon size="sm" />),
              fulfilled: () => (
                <div
                  className="alert alert-success password-reset-form-alert"
                  role="alert"
                >
                  <strong>Thank you!</strong>
                  {' '}
                  You will be sent an email with instructions to reset your password.
                </div>
              ),
              rejected: () => (
                <div
                  className="alert alert-danger password-reset-form-alert"
                  role="alert"
                >
                  <strong>Sorry!</strong>
                  {' '}
                  Something went wrong with your request.
                </div>
              )
            })
            }
            <footer className="password-reset-form-footer">
              <Link to={routePaths.loginRoute}>Cancel</Link>
            </footer>
          </div>
        </TransitionWrapper>
      </form>
    );
  }
}

PasswordResetForm.propTypes = {
  apiPasswordResetStore: MobxPropTypes.observableObject,
};

PasswordResetForm.wrappedComponent = {
  propTypes: {
    apiPasswordResetStore: MobxPropTypes.observableObject.isRequired,
  }
};

export default inject(PasswordResetForm)(
  observer(PasswordResetForm)
);
