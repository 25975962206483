import lodash from 'lodash';
import {action} from 'mobx';

import SingleResourceBaseStore from './common/apiSingleResourceBaseStore';
import {STATE_FULFILLED, STATE_PENDING, STATE_REJECTED} from '../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN} from '../../constants/storeConstants';
import serverApi from '../../utils/serverApi';
import {cleanResponse} from '../../utils/serverRequest';
import {hasEmployeeAccess} from '../../utils/writableLibrariesHelper';

/**
 * Whether or not to show ticker feeds.
 *
 * @const {boolean}
 */
const SHOW_TICKER_FEEDS = false;

/**
 * The feeds store
 */
class FeedSummaryStore extends SingleResourceBaseStore {
  /**
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   * Fetches feeds from the server by clientId
   *
   * @param {number} rawClientId
   * @param {boolean} includeGlobals
   */
  @action fetchFeedsByClientId(rawClientId, includeGlobals) {
    const clientId = String(rawClientId);

    if (this.isResourceAvailable(clientId)) {
      return;
    }

    this.resourcesById.set(clientId, {
      state: STATE_PENDING,
      resource: null,
      error: null,
    });
    this.state = STATE_PENDING;

    const isEmployee = hasEmployeeAccess(clientId, true);

    serverApi.feedsGetByClientId(clientId, includeGlobals).then(
      action('getFeedsSuccess', (feeds) => {
        this.resourcesById.set(clientId, {
          state: STATE_FULFILLED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          resource: this.fixFeeds(feeds, isEmployee),
          error: null
        });
        this.state = STATE_FULFILLED;
      }),
      action('getFeedsError', (error) => {
        this.resourcesById.set(clientId, {
          state: STATE_REJECTED,
          [EXPIRE_TIME]: Date.now() + EXPIRES_IN,
          resource: null,
          error,
        });
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Turns the feeds into usable objects
   *
   * @param {Array.<{}>} feeds
   * @param {boolean} isUserEmployee
   * @returns {{}}
   */
  fixFeeds(feeds, isUserEmployee) {
    if (!feeds || !Array.isArray(feeds)) {
      return [];
    }

    const results = {};
    feeds.forEach((feed) => {
      if (!SHOW_TICKER_FEEDS && !isUserEmployee && feed.isTicker) {
        return;
      }

      let schema;
      try {
        schema = cleanResponse(JSON.parse(feed.feedSchema || '{}'));
      } catch (parseError) {
        schema = {};
      }

      const data = [];
      const items = lodash.get(feed, 'items');

      if (Array.isArray(items)) {
        items.forEach((item) => {
          try {
            const parsedItem = JSON.parse(lodash.get(item, 'values', '{}'));
            if (!Array.isArray(parsedItem)) {
              data.push(parsedItem);
            } else if (parsedItem.length) {
              data.push(parsedItem[0]);
            }
          } catch (parseError) {
            // no action required
          }
        });
      }

      results[String(feed.id)] = {
        id: feed.id,
        name: feed.name,
        isTicker: feed.isTicker,
        texts: schema.textProperties || [],
        images: schema.imageProperties || [],
        data
      };
    });

    return results;
  }
}

export default new FeedSummaryStore();
