import {action} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {actionUpdateComponent} from '../../../../../display/components/action/actionUpdateComponent';
import {viewportComponent} from '../../../../../display/components/type/viewportComponent';

/**
 * The ViewportRefreshDuration component.
 *
 * @param {{}} params
 * @param {ObservableMap} params.entity
 * @param {GameStore} params.game
 * @returns {React.Component}
 */
export const ViewportRefreshDuration = ({entity, game}) => {
  /**
   * Updates the refresh duration in the viewport component.
   *
   * @returns {function()}
   */
  const onChangeRefreshDuration = () => {
    return action((changeEvent) => {
      const actionParams = {
        entityId: entity.get('id'),
      };

      const newValue = changeEvent.target.value || 0;
      const newRefreshDuration = parseInt(newValue, 10) || 0;

      const currentViewPort = entity.get('viewport');
      const newViewPort = viewportComponent({
        refreshDuration: newRefreshDuration,
      }, currentViewPort);

      game.addAction(actionParams, actionUpdateComponent(newViewPort));
    });
  };

  const viewport = entity.get('viewport');

  return (
    <div className="viewport-refresh-duration">
      <input
        type="number"
        className="form-control form-control-sm"
        value={viewport.refreshDuration}
        min={0}
        onChange={onChangeRefreshDuration()}
      />
    </div>
  );
};

ViewportRefreshDuration.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: PropTypes.object.isRequired,
};

export default observer(ViewportRefreshDuration);
