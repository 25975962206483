import {action, computed, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

import inject from '../../hoc/injectHoc';
import ContentDirectory from '../../common/contentDirectory/ContentDirectory';
import {PLACEABLE_IMAGE} from '../../../constants/libraryTypeConstants';
import {ActiveContentStore} from '../../../stores/active/activeContentStore';
import {initStore, disposeStore} from '../../../thunks/selectCategory/selectCategoryModalThunk';
import {makeLibraryFiltersWriteableOnly} from '../../../utils/writableLibrariesHelper';

import './createContentModal.scss';

/**
 * The default modal title.
 * @const {string}
 */
const DEFAULT_TITLE = 'Save Content To';

/**
 * The CreateContentModal component.
 */
export class CreateContentModal extends React.Component {
  /**
   * A custom active content state for this modal.
   * We don't want to track this content state globally, so we aren't going to use the singleton ActiveContentStore.
   *
   * @type {?ActiveContentStore}
   */
  @observable activeContentStore = null;

  /**
   * The given content name.
   *
   * @type {string}
   */
  @observable contentName = '';

  /**
   * The disposer functions for the mobX reaction().
   *
   * @type {Array.<function>}
   */
  disposers = [];

  /**
   * Triggered when the component just mounted onto the page.
   */
  componentDidMount() {
    if (this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{isOpen: boolean}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Whether or not to disable the 'Ok' button
   */
  @computed get isDisabled() {
    const {activeContentStore, apiCategoriesStore} = this;
    const {allowLibraryOnly, allowNoCategory, libraryType} = this.props;

    if (!activeContentStore) {
      return true;
    }

    if (!this.contentName) {
      return true;
    }

    const activeLibrary = activeContentStore.libraries[libraryType];
    const activeCategoryId = activeLibrary.category.id;

    if (activeLibrary.id && allowNoCategory && activeCategoryId === false) {
      return false;
    } else if (activeLibrary.id && activeCategoryId) {
      return false;
    }

    if (allowLibraryOnly && apiCategoriesStore) {
      const categories = apiCategoriesStore.getFulfilled(activeLibrary.category.storeKey);
      if (categories && !categories.length) {
        return false;
      }
    }

    return true;
  }

  /**
   * Initializes the modal.
   */
  @action initializeModal = () => {
    const {apiContentLibrariesStore, libraryType, sessionStore} = this.props;
    const {userId, clientId, productId} = sessionStore;

    // Create a new version of the active content store specifically for this modal.
    const activeContentStore = new ActiveContentStore();
    this.activeContentStore = activeContentStore;

    // Initialize thunks for the activeContentStore.
    this.disposers = initStore(activeContentStore, libraryType);

    // Make initial call to fetch the content libraries.
    const baseFilters = {
      productId,
      clientId,
      userId,
      contentLibraryTypeId: libraryType,
    };

    if (libraryType === PLACEABLE_IMAGE) {
      baseFilters.productId = null;
    }

    const writeableOnlyFilters = makeLibraryFiltersWriteableOnly(baseFilters);

    const storeKey = apiContentLibrariesStore.fetchContentLibraries(writeableOnlyFilters);
    activeContentStore.setLibraryStoreKey(libraryType, storeKey);
    activeContentStore.setLibraryId(libraryType, this.props.initialContentLibraryId);
  };

  /**
   * Tears down the modal.
   */
  tearDownModal = () => {
    disposeStore(this.disposers);
  };

  /**
   * Called when a category is selected.
   *
   * @param {(number|boolean)} categoryId
   */
  onCategorySelected = (categoryId) => {
    const {libraryType} = this.props;

    this.activeContentStore.setCategoryId(libraryType, categoryId);
  };

  /**
   * Triggered when the modal is closed without a chosen image.
   */
  onCancelModal = () => {
    const {libraryType, onComplete} = this.props;

    onComplete({
      library: null,
      categoryId: null,
      libraryId: null,
      libraryStoreKey: null,
      libraryType,
      contentName: null,
      isGlobal: false,
    });

    this.tearDownModal();
  };

  /**
   * Triggered when the modal is closed after choosing a category.
   */
  onCompleteModal = () => {
    const {activeContentStore} = this;
    const {apiContentLibrariesStore, libraryType, onComplete} = this.props;

    const activeLibrary = activeContentStore.libraries[libraryType];

    const library = apiContentLibrariesStore.getLibrary(activeLibrary.storeKey, activeLibrary.id);
    const isGlobal = Boolean(library && library.isGlobal);

    onComplete({
      library,
      categoryId: activeLibrary.category.id,
      libraryId: activeLibrary.id,
      libraryStoreKey: activeLibrary.storeKey,
      libraryType,
      contentName: this.contentName,
      isGlobal,
    });

    this.tearDownModal();
  };

  /**
   * Triggered when the name input changes.
   *
   * @param {{target: {value: string}}} changeEvent
   */
  @action onNameChange = (changeEvent) => {
    this.contentName = String(changeEvent.target.value);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {activeContentStore} = this;
    const {allowNoCategory, isOpen, libraryType, skipCategoryIds, skipSubCategories, title} = this.props;

    if (!activeContentStore) {
      return null;
    }

    return (
      <Modal isOpen={isOpen} backdrop="static" toggle={this.onCancelModal} className="create-content-modal">
        <p className="w24-label">{title}</p>
        <ModalBody>
          <div className="form-group">
            <label htmlFor="content-name" className="w24-label">Name:</label>
            <div className="w24-text-input">
              <input
                id="content-name"
                type="text"
                value={this.contentName}
                placeholder="New Content"
                name="contentName"
                onChange={this.onNameChange}
              />
            </div>
          </div>

          <p className="w24-label">Where do you want to create this content?</p>
          <ContentDirectory
            activeContentStore={activeContentStore}
            libraryType={libraryType}
            onCategorySelected={this.onCategorySelected}
            allowCategoryEdit={false}
            allowNoCategory={allowNoCategory}
            skipCategoryIds={skipCategoryIds}
            skipSubCategories={skipSubCategories}
          />
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-default"
            onClick={this.onCancelModal}
          >Cancel</button>
          {' '}
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.onCompleteModal}
            disabled={this.isDisabled}
          >Ok</button>
        </ModalFooter>
      </Modal>
    );
  }
}

CreateContentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  libraryType: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,

  allowLibraryOnly: PropTypes.bool,
  allowNoCategory: PropTypes.bool,
  apiCategoriesStore: MobxPropTypes.observableObject,
  apiContentLibrariesStore: MobxPropTypes.observableObject,
  initialContentLibraryId: PropTypes.number,
  sessionStore: MobxPropTypes.observableObject,
  skipCategoryIds: PropTypes.array,
  skipSubCategories: PropTypes.bool,
  title: PropTypes.string,
};

CreateContentModal.defaultProps = {
  allowLibraryOnly: false,
  allowNoCategory: false,
  skipCategoryIds: null,
  skipSubCategories: false,
  title: DEFAULT_TITLE,
};

CreateContentModal.wrappedComponent = {};
CreateContentModal.wrappedComponent.propTypes = {
  apiCategoriesStore: MobxPropTypes.observableObject.isRequired,
  apiContentLibrariesStore: MobxPropTypes.observableObject.isRequired,
  sessionStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(CreateContentModal)(
  observer(CreateContentModal)
);
