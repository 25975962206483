import {reaction, action} from 'mobx';

import sessionStore from '../../stores/active/sessionStore';
import activeContentStore from '../../stores/active/activeContentStore';
import apiContentLibrariesStore from '../../stores/api/apiContentLibrariesStore';
import {PRODUCT_STORAGE_KEY} from '../../constants/sessionConstants';
import {CREATED_CONTENT} from '../../constants/libraryTypeConstants';
import {routeHistory} from '../../utils/history';
import * as routePaths from '../../components/routePaths';

/**
 * The disposer function for the mobX reaction().
 *
 * @type {?function}
 */
let reactionDispose = null;

/**
 * Initializes reaction for productId changes.
 */
export function init() {
  reactionDispose = reaction(
    () => sessionStore.productId,
    action(onProductChange)
  );
}

/**
 * Performs logic when productId changes.
 *
 * @param {?number} productId
 */
function onProductChange(productId) {
  if (!productId) {
    localStorage.removeItem(PRODUCT_STORAGE_KEY);
    if (sessionStore.clientId) {
      routeHistory.push(routePaths.productSelectionRoute);
    }
    return;
  }

  activeContentStore.reset();

  const {userId, clientId} = sessionStore;
  const filters = {
    productId: productId,
    clientId: clientId,
    userId: userId,
    contentLibraryTypeId: CREATED_CONTENT,
  };
  const activeLibrary = activeContentStore.libraries[CREATED_CONTENT];

  activeLibrary.storeKey = apiContentLibrariesStore.fetchContentLibraries(filters);
  activeLibrary.id = null;
}

/**
 * Disposes of the thunk.
 * This is useful for hot-reloading purposes.
 */
export function dispose() {
  if (reactionDispose) {
    reactionDispose();
  }
}

export default init;
