import {action, observable} from 'mobx';

import apiClientSwitchStore from '../api/apiClientSwitchStore';
import {CURRENT_USER_STORAGE_KEY, PRODUCT_STORAGE_KEY} from '../../constants/sessionConstants';
import {CLIENT_USER_DEMO_ACCOUNT_ID, USER_ROLE_SALES} from '../../constants/userConstants';

/**
 * The session store.
 */
class SessionStore {
  /**
   * The id of the active user
   *
   * @type {?number}
   */
  @observable userId = null;

  /**
   * The id of the active client
   *
   * @type {?number}
   */
  @observable clientId = null;

  /**
   * The id of the active product
   *
   * @type {?number}
   */
  @observable productId = null;

  /**
   * Sets the client id.
   *
   * @param {?number} newClientId
   */
  @action setClientId(newClientId) {
    const currentUser = localStorage.getItem(CURRENT_USER_STORAGE_KEY);

    this.clientId = (newClientId) ? Number(newClientId) : null;

    if (!currentUser) {
      return;
    }

    try {
      const parsedUser = JSON.parse(currentUser);
      parsedUser.clientId = newClientId;
      localStorage.setItem(CURRENT_USER_STORAGE_KEY, JSON.stringify(parsedUser));
    } catch (error) {
      localStorage.removeItem(CURRENT_USER_STORAGE_KEY);
    }
  }

  /**
   * Sets the user id.
   *
   * @param {?number} newUserId
   */
  @action setUserId(newUserId) {
    this.userId = (newUserId) ? Number(newUserId) : null;
  }

  /**
   * Sets the active product id.
   *
   * @param {?{}} product
   */
  @action setProduct(product) {
    if (product) {
      this.productId = Number(product.id);
      localStorage.setItem(PRODUCT_STORAGE_KEY, JSON.stringify(product));
    } else {
      this.productId = null;
      localStorage.removeItem(PRODUCT_STORAGE_KEY);
    }
  }

  /**
   * Sets both the user id and client id.
   *
   * @param {?{}} currentUser
   */
  @action setCurrentUser(currentUser) {
    if (!currentUser) {
      this.setUserId(null);
      this.setClientId(null);
      localStorage.removeItem(CURRENT_USER_STORAGE_KEY);
      return;
    }

    localStorage.setItem(CURRENT_USER_STORAGE_KEY, JSON.stringify(currentUser));
    this.setUserId(currentUser.userId);
    this.setClientId(currentUser.clientId);

    // Make sure sales people start off in the user demo account.
    const isSalesPerson = (String(currentUser.role) === USER_ROLE_SALES);
    const currentClientIsDemo = (String(currentUser.clientId) === String(CLIENT_USER_DEMO_ACCOUNT_ID));
    if (isSalesPerson && !currentClientIsDemo) {
      apiClientSwitchStore.getNewClientToken(CLIENT_USER_DEMO_ACCOUNT_ID, true);
    }
  }

  /**
   * Clears the store.
   */
  @action clearAll() {
    this.userId = null;
    this.clientId = null;
    this.productId = null;
  }
}

export default new SessionStore();
