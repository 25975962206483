import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import inject from '../../../../hoc/injectHoc';

import './newClipHeader.scss';

/**
 * The NewClipHeader component.
 */
export class NewClipHeader extends React.Component {
  /**
   * The upload button click handler.
   */
  onClickUploadButton = () => {
    if (this.props.onOpenFileUploadDialog) {
      this.props.onOpenFileUploadDialog();
    }
  };

  /**
   * When the refresh button is clicked.
   */
  onRefreshClick = () => {
    const {activeContentStore, apiContentsStore, libraryType} = this.props;
    const categoryId = activeContentStore.libraries[libraryType].category.id;
    const libraryId = activeContentStore.libraries[libraryType].id;

    if (!categoryId || !libraryId) {
      return;
    }

    apiContentsStore.refreshCurrentContents({categoryId, libraryId});
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {isWriteable, onCreateBlank} = this.props;

    return (
      <aside className="new-clip-header">
        <div className="header-buttons">
          <button
            type="button"
            className="w24-refresh-button fa fa-refresh"
            onClick={this.onRefreshClick}
          />

          {(isWriteable) && (
            <span>
              <button
                type="button"
                onClick={this.onClickUploadButton}
                className="control-btn btn btn-primary"
              >Upload File</button>
            </span>
          )}

          {(onCreateBlank) && (
            <button
              type="button"
              onClick={onCreateBlank}
              className="control-btn blank-canvas-btn btn btn-outline-secondary"
            >
              Blank Canvas <i className="fa fa-plus" />
            </button>
          )}
        </div>
      </aside>
    );
  }
}

NewClipHeader.propTypes = {
  isWriteable: PropTypes.bool.isRequired,
  libraryType: PropTypes.number.isRequired,

  activeContentStore: MobxPropTypes.observableObject,
  apiContentsStore: MobxPropTypes.observableObject,
  onCreateBlank: PropTypes.func,
  onOpenFileUploadDialog: PropTypes.func,
  sessionStore: MobxPropTypes.observableObject,
};

NewClipHeader.wrappedComponent = {};
NewClipHeader.wrappedComponent.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentsStore: MobxPropTypes.observableObject.isRequired,
  sessionStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(NewClipHeader)(
  observer(NewClipHeader)
);
