import {action, computed, observable, runInAction} from 'mobx';

import {STATE_PAUSED, STATE_PLAYING, STATE_STOPPED} from '../../constants/displayItemConstants';

/**
 * The request animation frame function.
 * @type {function}
 */
const requestFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame;

/**
 * The number of seconds to elapse when step is called.
 * @const {number}
 */
const STEP_DURATION = (1 / 60) * 1000; // eslint-disable-line no-magic-numbers

/**
 * The game timer store.
 *
 * @returns {{}}
 */
export class GameTimerStore {
  /**
   * The handler to call on each 'tick'.
   *
   * @type {?function}
   */
  @observable tickHandler = null;

  /**
   * The maximum amount of time (in milliseconds) that can elapse before the timer stops itself.
   *
   * @type {?number}
   */
  @observable maxTime = null;

  /**
   * Whether or not the timer is paused.
   *
   * @type {boolean}
   */
  @observable isPaused = false;

  /**
   * The DOMHighResTimeStamp of when the requestanimationframe (RAF) loop started.
   *
   * @type {number}
   */
  @observable startTime = 0;

  /**
   * The time (in milliseconds) that the timer has been running.
   *
   * @type {number}
   */
  @observable elapsedTime = 0;

  /**
   * The id of the last requestanimationframe (RAF) request.
   *
   * @type {?number}
   */
  @observable rafRequestId = null;

  /**
   * Whether or not the timer continues to fire even when paused.
   *
   * @type {boolean}
   */
  @observable isContinuous = false;

  /**
   * Returns whether or not the timer is running.
   * Started means the timer is greater than zero, it would be stopped only if the time is zero.
   * The timer can be started and paused at the same time.
   *
   * @returns {boolean}
   */
  @computed get isStarted() {
    return (this.elapsedTime > 0);
  }

  /**
   * Gets the state of the timer (stopped, paused, playing).
   *
   * @returns {string}
   */
  @computed get state() {
    if (this.isPaused) {
      return STATE_PAUSED;
    } else if (this.isStarted) {
      return STATE_PLAYING;
    }
    return STATE_STOPPED;
  }

  /**
   * The current timer time.
   *
   * @returns {number}
   */
  @computed get time() {
    return this.elapsedTime;
  }

  /**
   * Fires the tickHandler if it is defined.
   *
   * @param {{}} eventData
   */
  fireTickHandler(eventData) {
    if (!this.tickHandler) {
      return;
    }

    this.tickHandler(Object.assign({
      isPaused: this.isPaused,
      time: this.elapsedTime
    }, eventData || {}));
  }

  /**
   * Initializes the stepping process.
   * This method is designed to be used by phantomJS, not the normal display.
   *
   * @returns {boolean}
   */
  @action initSteps() {
    if (this.elapsedTime > 0) {
      return false;
    }

    this.elapsedTime = 0;
    this.fireTickHandler();
    return true;
  }

  /**
   * Steps through 1/60th of the game loop every time this is called.
   * This method is designed to be used by phantomJS, not the normal display.
   *
   * @param {number} time
   * @param {boolean=} setToTime
   * @returns {boolean}
   */
  @action step(time, setToTime) {
    if (this.maxTime !== null && this.elapsedTime >= this.maxTime) {
      return false;
    }
    if (time) {
      if (setToTime) {
        this.elapsedTime = time;
      } else {
        this.elapsedTime += time;
      }
    } else {
      this.elapsedTime += STEP_DURATION;
    }

    if (this.elapsedTime > this.maxTime) {
      this.elapsedTime = this.maxTime;
    }

    // this.elapsedTime += STEP_DURATION;
    this.fireTickHandler();
    return true;
  }

  /**
   * Starts the timer and game loop.
   */
  @action start() {
    let unpause = Boolean(this.isPaused);
    if (this.maxTime !== null && this.elapsedTime >= this.maxTime) {
      this.startTime = 0;
      this.elapsedTime = 0;
    }

    const self = this; // eslint-disable-line no-shadow

    /**
     * The game time loop.
     *
     * @param {number} currentTime
     */
    function timerLoop(currentTime) {
      runInAction('timerLoop', () => {
        if (self.isPaused && !unpause) {
          if (!self.isContinuous) {
            return;
          }
          self.fireTickHandler();
          self.rafRequestId = requestFrame(timerLoop);
          return;
        }

        if (self.isPaused) {
          self.isPaused = false;
          unpause = false;
          self.startTime = currentTime - self.elapsedTime;
        } else if (!self.startTime) {
          self.startTime = currentTime;
          self.elapsedTime = 0;
        } else {
          self.elapsedTime = currentTime - self.startTime;
        }

        // Check for the maximum time.
        if (self.maxTime !== null && self.elapsedTime >= self.maxTime) {
          self.elapsedTime = self.maxTime;
          self.pause();
          return;
        }

        self.fireTickHandler();
        self.rafRequestId = requestFrame(timerLoop);
      });
    }

    this.rafRequestId = requestFrame(timerLoop);
  }

  /**
   * Pauses the timer.
   */
  @action pause() {
    this.isPaused = true;
    if (this.rafRequestId && !this.isContinuous) {
      cancelAnimationFrame(this.rafRequestId);
      this.rafRequestId = null;
    }
    this.fireTickHandler();
  }

  /**
   * Stops the timer.
   */
  @action stop() {
    if (this.rafRequestId && !this.isContinuous) {
      cancelAnimationFrame(this.rafRequestId);
      this.rafRequestId = null;
    }
    this.isPaused = Boolean(this.isContinuous);

    this.fireTickHandler();
  }

  /**
   * Stops the timer and resets the time to zero.
   */
  @action stopAndReset() {
    if (this.rafRequestId && !this.isContinuous) {
      cancelAnimationFrame(this.rafRequestId);
      this.rafRequestId = null;
    }
    this.isPaused = Boolean(this.isContinuous);

    this.startTime = 0;
    this.elapsedTime = 0;
    this.fireTickHandler();
  }

  /**
   * Sets whether or not the timer will fire continuously, even when paused.
   *
   * @param {boolean} newIsContinuous
   */
  @action setIsContinuous(newIsContinuous) {
    this.isContinuous = Boolean(newIsContinuous);
  }

  /**
   * Updates the timer to a new time.
   *
   * @param {number} newTime The new time in milliseconds.
   */
  @action setTime(newTime) {
    const shouldResume = Boolean(!this.isPaused && this.startTime);
    this.pause();

    this.elapsedTime = newTime;
    this.fireTickHandler({isPaused: !shouldResume});

    if (shouldResume) {
      this.start();
    }
  }

  /**
   * Sets the tick handler.
   *
   * @param {function} timeHandler
   */
  @action setTickHandler(timeHandler) {
    this.tickHandler = timeHandler;
  }

  /**
   * Sets the maximum amount of time (in milliseconds) that can elapse before the timer stops itself.
   *
   * @param {number} newMaxTime
   */
  @action setMaxTime(newMaxTime) {
    if (newMaxTime || newMaxTime === 0) {
      this.maxTime = newMaxTime;
    } else {
      this.maxTime = null;
    }
  }
}

// Tells the system not to use this as an injectable store.
export const doNotInject = true;
