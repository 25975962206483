import classNames from 'classnames';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityActivateHoc from '../../hoc/entityActivateHoc';

/**
 * The DisplayIcon component.
 */
export class DisplayIcon extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, style, className} = this.props;

    const entityId = entity.get('id');
    const icon = entity.get('icon');

    return (
      <div id={entityId} className={classNames('display-icon', className)} style={style}>
        <i className={classNames('fa fa-2x', `fa-${icon.type}`)} />
      </div>
    );
  }
}

DisplayIcon.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  style: PropTypes.object.isRequired,

  className: PropTypes.string,
};

export default entityActivateHoc(
  observer(DisplayIcon)
);
