import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';

import ConfirmModal from '../../modals/confirm/ConfirmModal';

import './fileUpload.scss';

/**
 * The limit on the number of files that can be uploaded at a time.
 * @const {number}
 */
const FILE_UPLOAD_LIMIT = 5;

/**
 * The FileUpload component.
 */
export class FileUpload extends React.Component {
  /**
   * A reference to the file input element.
   *
   * @type {{current: ?HTMLElement}}
   */
  fileInput = React.createRef();

  /**
   * Whether or not the user tried to upload too many files.
   *
   * @type {boolean}
   */
  @observable tooManyError = false;

  /**
   * Triggered when the component props updated.
   *
   * @param {{openUploadDialog: boolean}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.openUploadDialog && this.props.openUploadDialog) {
      this.openFileUploadDialog();
    }
  }

  /**
   * Opens the file upload dialog if it is available.
   */
  openFileUploadDialog = () => {
    if (this.fileInput.current) {
      this.fileInput.current.click();
    }
  };

  /**
   * Limits the number of files when new files are dropped.
   *
   * @param {Array.<{}>} files
   */
  @action limitFilesBeforeUpload = (files) => {
    if (!files || !files.length) {
      return;
    }

    const {onDrop} = this.props;

    if (files.length > FILE_UPLOAD_LIMIT) {
      this.tooManyError = true;
      return;
    }

    this.tooManyError = false;
    onDrop(files);
  };

  /**
   * The change handler after files have been selected from the Upload button.
   */
  onFileInputChange = () => {
    const fileEl = this.fileInput.current;
    const fileList = fileEl.files;

    // Note: IE11 requires this for loop approach, don't try to use something like Object.values().
    let files = [];
    for (let iter = 0; iter < fileList.length; iter += 1) {
      files.push(fileList[iter]);
    }

    fileEl.value = null;

    this.limitFilesBeforeUpload(files || []);
  };

  /**
   * Dismisses the too many error alert.
   */
  @action onDismissError = () => {
    this.tooManyError = false;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {hideDropzone} = this.props;
    const max = FILE_UPLOAD_LIMIT;

    return (
      <div className="file-upload">
        {(!hideDropzone) && (
          <Dropzone
            activeClassName="is-active"
            onDrop={this.limitFilesBeforeUpload}
          >
            {({getRootProps, getInputProps}) => {
              return (
                <section>
                  <div className="upload-zone" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p className="upload-text">
                      Drag &amp; Drop up to {max} files here or {' '}
                      <span className="upload-click-text">Click Here</span>
                    </p>
                  </div>
                </section>
              );
            }}
          </Dropzone>
        )}

        <input
          multiple
          type="file"
          id="file-upload-input"
          className="is-hidden"
          onChange={this.onFileInputChange}
          ref={this.fileInput}
        />

        {(this.tooManyError) && (
          <ConfirmModal
            confirmText={`Only ${max} files can be uploaded at one time.`}
            isOpen={true}
            onComplete={this.onDismissError}
          />
        )}
      </div>
    );
  }
}

FileUpload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  openUploadDialog: PropTypes.bool.isRequired,

  hideDropzone: PropTypes.bool,
};

FileUpload.defaultProps = {
  hideDropzone: false,
};

export default observer(FileUpload);
