import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import {ViewportChangeSource} from './components/changeSource/ViewportChangeSource';
import ViewportOptionButtons from './components/optionButtons/ViewportOptionButtons';
import ViewportRefreshDuration from './components/refreshDuration/ViewportRefreshDuration';
import EditTimelineControls from '../editTimeline/EditTimelineControls';

import './editViewportControls.scss';

/**
 * The EditViewportControls component.
 */
export class EditViewportControls extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;

    return (
      <div className="edit-viewport-controls">
        <div className="viewport-group">
          <h5 className="sidebar-title">Viewport</h5>

          <div className="group-header">
            <span className="group-header-label">Source</span>
          </div>

          <div className="group-controls">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <ViewportChangeSource entity={entity} game={game} />
                </div>
              </div>
            </div>
          </div>

          <div className="group-header">
            <span className="group-header-label">Options</span>
          </div>

          <div className="group-controls">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="viewport-options">Maintain Aspect Ratio</label>
                  <ViewportOptionButtons entity={entity} game={game} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="viewport-refresh-duration">Refresh Duration</label>
                  <ViewportRefreshDuration entity={entity} game={game} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <EditTimelineControls entity={entity} game={game} />
      </div>
    );
  }
}

EditViewportControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditViewportControls);
