import {action, observable} from 'mobx';

/**
 * The display editor store.
 */
class DisplayEditorStore {
  /**
   * The game object for the editor.
   *
   * @type {?{}}
   */
  @observable game = null;

  /**
   * The game timer for the editor.
   *
   * @type {?{}}}
   */
  @observable timer = null;

  /**
   * Sets the game.
   *
   * @param {{}} newGame
   */
  @action setGame(newGame) {
    this.game = newGame || null;
  }

  /**
   * Sets the timer.
   *
   * @param {{}} newTimer
   */
  @action setTimer(newTimer) {
    this.timer = newTimer || null;
  }
}

export default new DisplayEditorStore();
