import {toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import CropCircle from '../../image/components/CropCircle';
import CropEllipse from '../../image/components/CropEllipse';
import CropRectangle from '../../image/components/CropRectangle';
import CropTriangle from '../../image/components/CropTriangle';
import entityActivateHoc from '../../../hoc/entityActivateHoc';
import {CIRCLE, ELLIPSE, RECTANGLE, TRIANGLE} from '../../../../display/components/common/cropShapeComponent';

/**
 * Maps the crop shape names to their components.
 * @const {object.<string, Object>}
 */
const CROP_SHAPE_MAP = {
  [CIRCLE]: CropCircle,
  [ELLIPSE]: CropEllipse,
  [RECTANGLE]: CropRectangle,
  [TRIANGLE]: CropTriangle,
};

/**
 * The ImageGroup component.
 */
export class ImageGroup extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, style, sourceUrl, image} = this.props;

    const httpSourceUrl = sourceUrl.replace('https://', 'http://');

    const centerX = image.left + (image.width / 2);
    const centerY = image.top + (image.height / 2);

    const rotationStyle = {
      transform: (style.transform) ? String(style.transform) : '',
      WebkitTransform: (style.WebkitTransform) ? String(style.WebkitTransform) : '',
      transformOrigin: `${centerX}px ${centerY}px 0px`,
    };

    // Adding transform attribute as well as css because the css will not work in IE11...
    const rotateDeg = (entity.get('position')) ? entity.get('position').rotate : 0;
    const rotationAttributes = {
      transform: `rotate(${rotateDeg}, ${centerX}, ${centerY})`,
    };

    const hasCrop = entity.has('crop');
    const cropId = `crop-image-${entity.get('id')}`;

    const cropShapeComponent = entity.get('cropShape');
    const cropShapeName = (cropShapeComponent) ? cropShapeComponent.name : 'rectangle';
    const CropShape = CROP_SHAPE_MAP[cropShapeName];

    const clipPathProperty = {};
    let cropOptions = {};
    if (hasCrop) {
      const cropComponent = entity.get('crop');

      cropOptions = {...toJS(cropComponent), ...toJS(cropShapeComponent)};
      clipPathProperty.clipPath = `url(#${cropId})`;
    }

    const imageX = image.left || 0;
    const imageY = image.top || 0;

    return (
      <g
        className="image-group rotation-group"
        {...clipPathProperty}
        style={rotationStyle}
        pointerEvents="visible"
        {...rotationAttributes}
      >
        {(hasCrop && CropShape) && (
          <CropShape cropId={cropId} cropOptions={cropOptions} />
        )}

        <image
          x={imageX}
          y={imageY}
          width={image.width}
          height={image.height}
          xlinkHref={httpSourceUrl}
        />
      </g>
    );
  }
}

ImageGroup.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  image: PropTypes.object.isRequired,
  sourceUrl: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
};

export default entityActivateHoc(
  observer(ImageGroup)
);
