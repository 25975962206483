import {cropComponent} from '../../../../../../display/components/common/cropComponent';
import {cropShapeComponent, RECTANGLE} from '../../../../../../display/components/common/cropShapeComponent';
import {positionComponent} from '../../../../../../display/components/common/positionComponent';
import {sizeComponent} from '../../../../../../display/components/common/sizeComponent';
import {imageComponent} from '../../../../../../display/components/type/imageComponent';

/**
 * Gets the image data for a placeholder image.
 *
 * @param {number} fileId
 * @returns {function}
 */
export function getPlaceHolderImageData(fileId) {
  return ({position, resolution, size}) => {
    const defaultSize = 200;
    const top = position.top || 0; // eslint-disable-line no-shadow
    const left = position.left || 0;
    const width = size.width || defaultSize;
    const height = size.height || defaultSize;

    return {
      setup: {
        ...cropComponent(top, left, width, height),
        ...cropShapeComponent(RECTANGLE),
        ...sizeComponent({width: resolution.width, height: resolution.height}),
        ...positionComponent({topPosition: 0, leftPosition: 0}),
      },
      ...imageComponent(Number(fileId), top, left, width, height),
    };
  };
}
