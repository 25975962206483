import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * The infinite scroll component.
 *
 * Forked from {@url https://github.com/CassetteRocks/react-infinite-scroller/blob/master/src/InfiniteScroll.js}
 */
export class InfiniteScroll extends React.Component {
  /**
   * The reference to this component's element.
   *
   * @type {{current: ?HTMLElement}}
   */
  componentRef = React.createRef();

  /**
   * The page loaded.
   *
   * @type {number}
   */
  pageLoaded = 0;

  /**
   * Triggered when the component mounted to the page.
   */
  componentDidMount() {
    const {pageStart} = this.props;

    this.pageLoaded = pageStart;

    this.attachScrollListener();
  }

  /**
   * Triggered when the component has updated.
   */
  componentDidUpdate() {
    this.attachScrollListener();
  }

  /**
   * Triggered when the component was removed from the page.
   */
  componentWillUnmount() {
    this.detachScrollListener();
    this.detachMouseWheelListener();
  }

  /**
   * Determines if the given element matches the given selector.
   *
   * @param {Element} element
   * @param {string} selector
   * @returns {boolean}
   */
  doesElementMatchSelector = (element, selector) => {
    if (element.msMatchesSelector) {
      return element.msMatchesSelector(selector);
    }

    if (element.matches) {
      return element.matches(selector);
    }

    return false;
  };

  /**
   * Gets the element that scrolls.
   *
   * @returns {HTMLElement}
   */
  getScrollingElement = () => {
    const {scrollElSelector, useWindow} = this.props;

    if (useWindow) {
      return window;
    }

    const componentEl = this.componentRef.current;

    if (!scrollElSelector) {
      return componentEl.parentNode;
    }

    const safeSelector = String(scrollElSelector);

    if (this.doesElementMatchSelector(componentEl, safeSelector)) {
      return componentEl;
    }

    let checkElement = componentEl;
    while (checkElement !== document) {
      if (this.doesElementMatchSelector(checkElement, safeSelector)) {
        return checkElement;
      }
      checkElement = checkElement.parentNode;
    }

    throw new Error('InfiniteScroll: Could not find element matching given selector.');
  };

  /**
   * Attaches the scroll listener.
   */
  attachScrollListener = () => {
    const {hasMore, initialLoad, useCapture} = this.props;

    if (!hasMore) {
      return;
    }

    const scrollEl = this.getScrollingElement();

    scrollEl.addEventListener(
      'mousewheel',
      this.mouseWheelListener,
      useCapture,
    );
    scrollEl.addEventListener(
      'scroll',
      this.scrollListener,
      useCapture,
    );
    scrollEl.addEventListener(
      'resize',
      this.scrollListener,
      useCapture,
    );

    if (initialLoad) {
      this.scrollListener();
    }
  };

  /**
   * Removes the mouse wheel listener from the scroll element.
   */
  detachMouseWheelListener = () => {
    const {useCapture} = this.props;

    const scrollEl = this.getScrollingElement();

    scrollEl.removeEventListener(
      'mousewheel',
      this.mouseWheelListener,
      useCapture,
    );
  };

  /**
   * Removes the scroll listeners from the scroll element.
   */
  detachScrollListener = () => {
    const {useCapture} = this.props;

    const scrollEl = this.getScrollingElement();

    scrollEl.removeEventListener(
      'scroll',
      this.scrollListener,
      useCapture,
    );
    scrollEl.removeEventListener(
      'resize',
      this.scrollListener,
      useCapture,
    );
  };

  /**
   * Triggered when the mouse wheel is moved.
   *
   * @param {{}} mouseWheelEvent
   */
  mouseWheelListener = (mouseWheelEvent) => {
    // Prevents Chrome hangups.
    // See: https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
    if (mouseWheelEvent.deltaY === 1) {
      mouseWheelEvent.preventDefault();
    }
  };

  /**
   * Triggered when the scroll element is scrolled.
   */
  scrollListener = () => {
    const {isReverse, loadMore, threshold, useWindow} = this.props;

    const el = this.componentRef.current;
    const scrollEl = this.getScrollingElement();

    let offset;
    if (useWindow) {
      const doc = document.documentElement || document.body.parentNode || document.body;
      const scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : doc.scrollTop;

      if (isReverse) {
        offset = scrollTop;
      } else {
        offset = this.calculateTopPosition(el) + (el.offsetHeight - scrollTop - window.innerHeight);
      }
    } else if (isReverse) {
      offset = scrollEl.scrollTop;
    } else {
      offset = el.scrollHeight - scrollEl.scrollTop - scrollEl.clientHeight;
    }

    // Here we make sure the element is visible as well as checking the offset.
    if (offset < Number(threshold) && el.offsetParent !== null) {
      this.detachScrollListener();

      // Call loadMore after detachScrollListener to allow for non-async loadMore functions.
      this.pageLoaded += 1;

      loadMore(this.pageLoaded);
    }
  };

  /**
   * Calculates the top position of the element.
   *
   * @param {HTMLElement} el
   * @returns {number}
   */
  calculateTopPosition = (el) => {
    if (!el) {
      return 0;
    }
    return el.offsetTop + this.calculateTopPosition(el.offsetParent);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {children, className, hasMore, loader} = this.props;

    const hasLoader = Boolean(loader);

    return (
      <div
        ref={this.componentRef}
        className={classNames('infinite-scroll', className || null)}
      >
        {children}

        {(hasMore && hasLoader) && (loader)}
      </div>
    );
  }
}

InfiniteScroll.propTypes = {
  children: PropTypes.node.isRequired,
  loadMore: PropTypes.func.isRequired,

  className: PropTypes.string,
  element: PropTypes.node,
  hasMore: PropTypes.bool,
  initialLoad: PropTypes.bool,
  isReverse: PropTypes.bool,
  loader: PropTypes.node,
  pageStart: PropTypes.number,
  scrollElSelector: PropTypes.string,
  threshold: PropTypes.number,
  useCapture: PropTypes.bool,
  useWindow: PropTypes.bool,
};

InfiniteScroll.defaultProps = {
  element: 'div',
  hasMore: false,
  initialLoad: true,
  isReverse: false,
  loader: null,
  pageStart: 0,
  scrollElSelector: null,
  threshold: 250,
  useCapture: false,
  useWindow: true,
};

export default InfiniteScroll;
