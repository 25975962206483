import lodash from 'lodash';
import {observable, runInAction, transaction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import ErrorBoundary from '../../common/errorBoundary/ErrorBoundary';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import DisplayControl from '../../controls/display/DisplayControl';
import PlaybackControls from '../../controls/playback/PlaybackControls';
import ZoomControl from '../../controls/zoom/ZoomControl';
import Display from '../../display/display/Display';
import inject from '../../hoc/injectHoc';
import {homeRoute} from '../../routePaths';
import DisplaySidebar from '../../sidebars/display/DisplaySidebar';
import EditorSidebar from '../../sidebars/editor/EditorSidebar';
import {EDITOR_NEW_ALL} from '../../../constants/editorConstants';
import {loadGameFromSource} from '../../../display/game';
import {hasEmployeeAccess} from '../../../utils/writableLibrariesHelper';

// import source from '../../../display/source.json';

import './editorPage.scss';

/**
 * If display height adjustment fails, this will be the default height.
 * @const {number}
 */
const DEFAULT_DISPLAY_HEIGHT = 360;

/**
 * The height of the display will be set to (#main-page height - x) where x is this value.
 * @const {number}
 */
const DISPLAY_SUBTRACT_FROM_HEIGHT = 54;

/**
 * The updateDisplaySize function can only run once per this many milliseconds.
 * @const {number}
 */
const THROTTLE_TIME = 750;

/**
 * The EditorPage component.
 */
export class EditorPage extends React.Component {
  /**
   * The current height of the display.
   * This will change as the window resizes.
   *
   * @type {number}
   */
  @observable displayHeight = DEFAULT_DISPLAY_HEIGHT;

  /**
   * The updateDisplaySize function throttled so it doesn't fire too much while dragging the frame.
   *
   * @type {?function}
   */
  throttledUpdateDisplaySize = null;

  /**
   * The function used to remove the block on router transitions.
   *
   * @type {?function}
   */
  unblockRouterTransitions = null;

  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);

    this.throttledUpdateDisplaySize = lodash.throttle(this.updateDisplaySize, THROTTLE_TIME);

    this.preloadContent(props);
  }

  /**
   * Triggered when the component is added to the page.
   */
  componentDidMount() {
    window.addEventListener('resize', this.throttledUpdateDisplaySize);
    window.addEventListener('beforeunload', this.onPageUnload);

    const {routerStore} = this.props;

    routerStore.blockRouting(() => {
      if (!this.hasChanges()) {
        return undefined;
      }
      return 'You have unsaved changes. Are you sure you want to navigate away?';
    });
  }

  /**
   * Triggered when the component is removed from the page.
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledUpdateDisplaySize);
    window.removeEventListener('beforeunload', this.onPageUnload);

    const {routerStore} = this.props;

    routerStore.unblockRouting();
  }

  /**
   * Whether or not there are changes to the game/video/image.
   *
   * @returns {boolean}
   */
  hasChanges = () => {
    const {displayEditorStore} = this.props;
    if (!displayEditorStore.game || !displayEditorStore.game.history) {
      return false;
    }

    const {hasUndo, hasRedo} = displayEditorStore.game.history;
    return (hasUndo || hasRedo);
  };

  /**
   * Triggered when the page attempts to unload if changes have been made.
   * This will open a confirm dialog using the returned string.
   *
   * @param {{}} unloadEvent
   */
  onPageUnload = (unloadEvent) => {
    if (this.hasChanges()) {
      unloadEvent.returnValue = 'You have unsaved changes. Are you sure you want to navigate away?';
    }
  };

  /**
   * Navigates to the home route.
   *
   * @param {boolean=} isSafe
   */
  goHome = (isSafe) => {
    if (isSafe && this.unblockRouterTransitions) {
      this.unblockRouterTransitions();
    }

    const {routerStore} = this.props;

    routerStore.push(homeRoute);
  };

  /**
   * Gets the content promise.
   *
   * @param {number|string} contentId
   * @param {{}=} props
   * @returns {Promise<{content: {}, library: {}}>}}
   */
  fetchContent = (contentId, props) => {
    const safeProps = props || this.props;

    const {apiProductsStore, editorGetContentStore} = safeProps;

    if (!lodash.includes(EDITOR_NEW_ALL, String(contentId))) {
      editorGetContentStore.fetchContentById(contentId);
      return editorGetContentStore.getPromise(contentId);
    }

    const fromContentId = this.getFromContentId(safeProps);
    const isLayout = this.getIsLayout(props);

    let product = apiProductsStore.getActiveProduct();
    if (!product) {
      product = {widthPx: 1920, heightPx: 1080};
    }

    editorGetContentStore.setProductForContent(contentId, product);
    editorGetContentStore.setNewContent(contentId, fromContentId, product, {isLayout});
    return editorGetContentStore.getPromise(contentId);
  };

  /**
   * Preloads the content for the page.
   *
   * @param {{match: {params: {contentId: number}}}} props
   */
  preloadContent = (props) => {
    const {activeContentStore, apiFeedSummaryStore, displayEditorStore} = props;
    const {clientId} = props.sessionStore;

    const contentId = this.getContentId(props);

    displayEditorStore.setGame(null);
    displayEditorStore.setTimer(null);

    let contentTitle;

    this.fetchContent(
      contentId,
      props
    ).then(({content, library}) => {
      contentTitle = content.title;
      const libraryType = library.contentLibraryTypeId;

      activeContentStore.setContentId(content.id);
      activeContentStore.setLibraryId(libraryType, library.id);
      activeContentStore.setCategoryId(libraryType, content.categoryId || false);

      return apiFeedSummaryStore.getPromise(clientId).catch(() => []);
    }).then((feeds) => {
      this.initGame(contentId, contentTitle, feeds);
    });
  };

  /**
   * Initializes the game from the source and makes sure the timer is setup properly.
   *
   * @param {number} contentId
   * @param {string} contentTitle
   * @param {{}} feeds
   */
  initGame = (contentId, contentTitle, feeds) => {
    const {displayEditorStore, displayZoomStore, editorGetContentStore} = this.props;

    const source = editorGetContentStore.getSource(contentId);
    if (!source) {
      return;
    }

    const {game, timer} = loadGameFromSource(source, true, {fromId: feeds});
    const gameResolution = game.resolution;

    const {checkpointStore} = game;

    checkpointStore.setContentId(contentId);
    checkpointStore.setContentTitle(contentTitle);

    displayEditorStore.setTimer(timer);
    displayEditorStore.setGame(game);

    // We want the requestAnimationFrame going at all times, so prime the timer (start and reset).
    timer.start();
    timer.stopAndReset();

    if (checkpointStore.hasCheckpoint()) {
      game.restoreCheckpoint(contentId);
    }

    transaction(() => {
      displayZoomStore.setGameSize(gameResolution.width, gameResolution.height);
      this.updateDisplaySize();
      displayZoomStore.setZoomLevel(displayZoomStore.controlData.start);
    });
  };

  /**
   * Gets the content id from the url params.
   *
   * @param {{match: {params: {contentId: string}}}=} props
   * @returns {?string}
   */
  getContentId = (props) => {
    return (props || this.props).match.params.contentId;
  };

  /**
   * Gets the new file id from the url params.
   *
   * @param {{match: {params: {fromContentId: string}}}=} props
   * @returns {?string}
   */
  getFromContentId = (props) => {
    return (props || this.props).match.params.fromContentId;
  };

  /**
   * Returns whether or not the clip type is for a layout.
   *
   * @param {{match: {params: {isLayout: string}}}=} props
   * @returns {boolean}
   */
  getIsLayout = (props) => {
    if (!hasEmployeeAccess()) {
      return false;
    }

    return Boolean((props || this.props).match.params.isLayout);
  };

  /**
   * Updates the zoom display size.
   */
  updateDisplaySize = () => {
    const {displayEditorStore, displayZoomStore} = this.props;
    if (!displayEditorStore.game) {
      return;
    }

    const game = displayEditorStore.game;

    const editorPage = document.getElementById('main-page');
    runInAction('editorPageUpdateHeight', () => {
      if (game.endTime > 0) {
        this.displayHeight = (editorPage.clientHeight - DISPLAY_SUBTRACT_FROM_HEIGHT);
      } else {
        this.displayHeight = editorPage.clientHeight;
      }
    });

    const displayEl = document.getElementById('display');

    displayZoomStore.setDisplaySize(displayEl.clientWidth, this.displayHeight);
  };

  /**
   * Renders the page when the contents are not ready.
   *
   * @param {number|string} contentId
   * @returns {{}}
   */
  renderNotReady = (contentId) => {
    const {apiFeedSummaryStore, editorGetContentStore, sessionStore} = this.props;
    const {clientId} = sessionStore;

    const noSource = (
      <div className="alert alert-warning">
        Could not load editor because no source was found.
      </div>
    );

    const afterContentLoaded = () => {
      apiFeedSummaryStore.case(clientId, {
        pre: () => (<LoadingIcon size="lg" />),
        pending: () => (<LoadingIcon size="lg" />),
        rejected: () => noSource,
        fulfilled: () => noSource,
      });
    };

    return (
      <div id="editor-page" className="system-page">
        <div className="col">
          {editorGetContentStore.case(contentId, {
            pre: () => (<LoadingIcon size="lg" />),
            pending: () => (<LoadingIcon size="lg" />),
            rejected: afterContentLoaded,
            fulfilled: afterContentLoaded,
          })}
        </div>
      </div>
    );
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {displayZoomStore} = this.props;
    const {game, timer} = this.props.displayEditorStore;

    const contentId = this.getContentId();
    const hasTimeLine = (game) ? game.hasTimeLine() : false;
    let isLayout = this.getIsLayout();
    if (game) {
      isLayout = game.isLayout || isLayout;
    }

    if (!game) {
      return this.renderNotReady(contentId);
    }

    return (
      <div id="editor-page" className="system-page">
        <ErrorBoundary message="An error occurred and the editor could not be loaded.">
          <EditorSidebar game={game} timer={timer} goHome={this.goHome} isLayout={isLayout} />

          <div className="main-display">
            <div className="display-wrapper">
              <Display game={game} height={this.displayHeight} timer={timer} zoomStore={displayZoomStore} />
            </div>

            {(hasTimeLine) && (
              <PlaybackControls game={game} timer={timer} />
            )}

            <div className="display-controls">
              <div className="controls-wrapper">
                <DisplayControl game={game} zoomStore={displayZoomStore} />
              </div>
              <div className="controls-wrapper">
                <ZoomControl />
              </div>
            </div>
          </div>
          <DisplaySidebar onChangeMinimization={this.updateDisplaySize} />
        </ErrorBoundary>
      </div>
    );
  }
}

EditorPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      contentId: PropTypes.string,
      fromContentId: PropTypes.string,
      isLayout: PropTypes.string,
    }).isRequired,
  }).isRequired,

  activeContentStore: MobxPropTypes.observableObject,
  apiFeedSummaryStore: MobxPropTypes.observableObject,
  apiProductsStore: MobxPropTypes.observableObject,
  displayEditorStore: MobxPropTypes.observableObject,
  displayZoomStore: MobxPropTypes.observableObject,
  editorGetContentStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
  sessionStore: MobxPropTypes.observableObject,
};

EditorPage.wrappedComponent = {};
EditorPage.wrappedComponent.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
  apiFeedSummaryStore: MobxPropTypes.observableObject.isRequired,
  apiProductsStore: MobxPropTypes.observableObject.isRequired,
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
  displayZoomStore: MobxPropTypes.observableObject.isRequired,
  editorGetContentStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
  sessionStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(EditorPage.wrappedComponent.propTypes)(
  observer(EditorPage)
);
