import {action, extendObservable, observable} from 'mobx';

import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN} from '../../constants/storeConstants';
import serverApi from '../../utils/serverApi';
import {apiStore, getCase} from '../../utils/apiStore';

/**
 * The dyanmic image store.
 */
class DynamicImageStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * The map of each page of projects.
   *
   * @type {Observable<{user: ?Observable, error: ?Error}>}
   */
  @observable image = {
    data: null,
    error: null
  };

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {?Array.<{}>}
   */
  getFulfilled() {
    return this.image.data;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    return this.image.error;
  }

  /**
   * Clears all the currentUser info
   */
  @action clearAll() {
    this.image.data = null;
    this.image.error = null;
    this.image[EXPIRE_TIME] = null;
    this.state = STATE_PRE;
  }

  /**
   * Gets the dynamic image from the server.
   */
  @action fetchImage() {
    this.state = STATE_PENDING;

    let currentExpireTime = this.image[EXPIRE_TIME];
    if (currentExpireTime && currentExpireTime <= Date.now()) {
      this.image.data = null;
      this.image.error = null;
      currentExpireTime = null;
    }

    if (!currentExpireTime) {
      this.image[EXPIRE_TIME] = Date.now() + EXPIRES_IN;
    }

    if (this.image.data) {
      this.state = STATE_FULFILLED;
      return;
    }

    serverApi.dynamicImageGet().then(
      action('dynamicImageGetSuccess', (data) => {
        this.image.data = data;
        this.image.error = null;
        this.state = STATE_FULFILLED;
      }),
      action('dynamicImageGetError', (error) => {
        this.image.data = null;
        this.image.error = error;
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => {
      return this.getFulfilled();
    };
    const getRejected = () => {
      return this.getRejected();
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }
}

export default new DynamicImageStore();
