import lodash from 'lodash';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import SnapLineAngleButtons from './components/SnapLineAngleButtons';
import EditTimelineControls from '../editTimeline/EditTimelineControls';
import ColorSelector from '../../common/colorSelector/ColorSelector';
import ThicknessSelector from '../../common/thicknessSelector/ThicknessSelector';
import {actionUpdateComponent} from '../../../display/components/action/actionUpdateComponent';
import {visibleComponent} from '../../../display/components/common/visibleComponent';

import './editLineControls.scss';

/**
 * The EditLineControls component.
 */
export class EditLineControls extends React.Component {
  /**
   * Updates the line entity when it is changed.
   *
   * @param {{color: string, opacity: number, thickness: number}} changes
   */
  onChangeLine = (changes) => {
    const {entity, game} = this.props;

    const currentLine = entity.get('line');
    const currentVisible = entity.get('visible');

    const actionParams = {
      entityId: entity.get('id'),
    };

    const styleUpdates = lodash.defaults({
      color: changes.color,
      opacity: changes.opacity,
    }, currentLine.style || {});

    game.addAction(actionParams, actionUpdateComponent({
      line: {
        thickness: changes.thickness || currentLine.thickness,
        style: {
          color: styleUpdates.color,
        },
      },
      ...visibleComponent(currentVisible.visible, styleUpdates.opacity),
    }));
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;

    const line = entity.get('line');
    const {style} = line;

    return (
      <div className="edit-line-controls">
        <div className="line-group">
          <h5 className="sidebar-title">Line</h5>
          <div className="group-controls">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="color-selector-input">Snap</label>
                  <SnapLineAngleButtons entity={entity} game={game} />
                  <p className="control-group-help">Snaps the line to the nearest half-axis.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="group-header">
            <span className="group-header-label">Style</span>
          </div>
          <div className="group-controls">
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="color-selector-input">Color</label>
                  <ColorSelector
                    colorValue={style.color}
                    opacityValue={style.opacity}
                    onChangeColor={(color, opacity) => this.onChangeLine({color, opacity})}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="thickness-selector-input">Thickness</label>
                  <ThicknessSelector
                    thicknessValue={line.thickness}
                    onChangeThickness={(thickness) => this.onChangeLine({thickness})}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <EditTimelineControls entity={entity} game={game} />
      </div>
    );
  }
}

EditLineControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditLineControls);
