import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import SelectCategoryModal from '../../modals/selectCategory/SelectCategoryModal';
import {moveContentLocation} from '../../../utils/contentsHelper';

/**
 * The ContentMove component.
 */
export class ContentMove extends React.Component {
  /**
   * Whether or not the move modal is open.
   *
   * @type {boolean}
   */
  @observable isMoveModalOpen = false;

  /**
   * The chosen content.
   *
   * @type {?{}}
   */
  activeContent = null;

  /**
   * Handles when a piece of content is selected for being moved.
   *
   * @param {{}} content
   */
  @action onMoveStart = (content) => {
    this.activeContent = content;
    this.isMoveModalOpen = true;
  };

  /**
   * Handles when a content item will be moved to a different category.
   *
   * @param {{categoryId: number, libraryId: number, libraryType: number}} moveData
   */
  @action onMoveContent = (moveData) => {
    this.isMoveModalOpen = false;

    if (!moveData.categoryId) {
      // The user must have cancelled the modal.
      return;
    }

    const activeContent = this.activeContent;
    this.activeContent = null;

    moveContentLocation(activeContent, moveData);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {libraryType} = this.props;

    return (
      <div className="content-move">
        {(this.isMoveModalOpen) && (
          <SelectCategoryModal
            isOpen={true}
            libraryType={libraryType}
            onComplete={this.onMoveContent}
            skipSubCategories={false}
            title="Move Content to:"
          />
        )}
      </div>
    );
  }
}

ContentMove.propTypes = {
  libraryType: PropTypes.number.isRequired,
};

export default observer(ContentMove);
