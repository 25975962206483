/**
 * The crop component.
 *
 * @param {number} cropTop
 * @param {number} cropLeft
 * @param {number} cropWidth
 * @param {number} cropHeight
 * @returns {{crop: {top: number, left: number, width: number, height: number}}}
 */
export function cropComponent(cropTop, cropLeft, cropWidth, cropHeight) {
  return {
    crop: {
      top: cropTop || 0,
      left: cropLeft || 0,
      width: cropWidth || 0,
      height: cropHeight || 0,
      default: {
        top: cropTop || 0,
        left: cropLeft || 0,
        width: cropWidth || 0,
        height: cropHeight || 0,
      },
    }
  };
}

/**
 * Gets the crop component from the source item.
 *
 * @param {{setup: {crop: {}}}} item
 * @returns {{crop: {top: number, left: number, width: number, height: number}}}
 */
export function getCropFromSource(item) {
  const itemSetup = item.setup || {};
  if (!itemSetup.crop) {
    return {};
  }

  const crop = itemSetup.crop;
  return cropComponent(
    crop.top,
    crop.left,
    crop.width,
    crop.height,
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function getCropForSource(entity) {
  if (!entity.has('crop')) {
    return {};
  }

  const crop = entity.get('crop');
  return {
    setup: {
      crop: {
        top: crop.top,
        left: crop.left,
        width: crop.width,
        height: crop.height,
      }
    },
  };
}
