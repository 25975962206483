import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import entityActivateHoc from '../../hoc/entityActivateHoc';

/**
 * The DisplayPlaylist component.
 */
export class DisplayPlaylist extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {style} = this.props;

    style.backgroundColor = 'rgb(0, 120, 120)';
    style.border = '3px dashed white';

    // style.mixBlendMode = 'exclusion';

    return (
      <div className="display-playlist" style={style}>
        <span>Playlist Placeholder</span>
      </div>
    );
  }
}

DisplayPlaylist.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,
};

export default entityActivateHoc(
  observer(DisplayPlaylist)
);
