/**
 * The new text placeholder.
 * @const {string}
 */
const PLACEHOLDER_TEXT = '{\\}{%0}{40}{$1.00}{#000000}{arial}New Text Element{!arial}{!#000000}{!$1.00}{!40}{!%0}{!\\}';

/**
 * Gets the text data for a placeholder text.
 *
 * @returns {{text: {plaintext: string}}}
 */
export function getPlaceHolderTextData() {
  return {
    text: {
      markdown: PLACEHOLDER_TEXT,
    }
  };
}
