import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import {DisplaySource, DroppableDisplaySource} from '../displaySource/DisplaySource';
import Scrollbars from '../../common/scrollbars/Scrollbars';

import './display.scss';

/**
 * The Display component.
 */
class Display extends React.Component {
  /**
   * Updates the display panning when the keyboard arrow keys are pressed.
   * Note: The element this is attached to must have tabIndex and be selected before keyboard events will fire.
   *
   * @param {{}} keyboardEvent
   */
  onKeyboard = (keyboardEvent) => {
    const step = 5;
    let deltaX = 0;
    let deltaY = 0;
    let cancel = false;
    let zoom = null;

    switch (keyboardEvent.key) {
      case 'ArrowDown':
        deltaY = 0 - step;
        break;
      case 'ArrowUp':
        deltaY = step;
        break;
      case 'ArrowLeft':
        deltaX = step;
        break;
      case 'ArrowRight':
        deltaX = 0 - step;
        break;
      case 'Escape':
        cancel = true;
        break;
      case '+':
      case '=':
        zoom = 1;
        break;
      case '-':
      case '_':
        zoom = -1;
        break;
      case '0':
      case ')':
        zoom = 0;
        break;
      default:
        return;
    }

    keyboardEvent.preventDefault();

    const zoomStore = this.props.zoomStore;
    if (zoom) {
      zoomStore.setZoomLevel(zoomStore.zoomLevel + zoom);
    } else if (zoom === 0) {
      zoomStore.setZoomLevel(0);
    } else if (cancel) {
      zoomStore.setPanValues(0, 0);
    } else {
      zoomStore.updatePanDelta(deltaX, deltaY);
    }
  };

  /**
   * Updates the display panning when the scroll wheel is used.
   *
   * @param {{}} wheelEvent
   */
  onWheel = (wheelEvent) => {
    const zoomStore = this.props.zoomStore;
    zoomStore.updatePanDelta(wheelEvent.deltaX, wheelEvent.deltaY);
  };

  /**
   * Updates the display panning on the x-axis.
   *
   * @param {number} newPanValue
   */
  onPanX = (newPanValue) => {
    const zoomStore = this.props.zoomStore;
    zoomStore.setPanValues(-newPanValue, null);
  };

  /**
   * Updates the display panning on the y-axis.
   *
   * @param {number} newPanValue
   */
  onPanY = (newPanValue) => {
    const zoomStore = this.props.zoomStore;
    zoomStore.setPanValues(null, -newPanValue);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {game, height, timer, zoomStore} = this.props;

    const style = {
      height,
    };

    let panData = {};
    const onEvents = {};
    if (zoomStore) {
      panData = zoomStore.panData || {};

      onEvents.onKeyDown = this.onKeyboard;
      onEvents.onWheel = this.onWheel;
    }

    const DisplaySourceComponent = (zoomStore) ? DroppableDisplaySource : DisplaySource;

    return (
      <div id="display" tabIndex="0" style={style} {...onEvents}>
        <Scrollbars
          minHorizontal={panData.minX}
          maxHorizontal={panData.maxX}
          onChangeHorizontal={this.onPanX}
          stepHorizontal={panData.step}
          valueHorizontal={(zoomStore) ? -zoomStore.panX : null}
          minVertical={panData.minY}
          maxVertical={panData.maxY}
          onChangeVertical={this.onPanY}
          stepVertical={panData.step}
          valueVertical={(zoomStore) ? -zoomStore.panY : null}
        >
          <DisplaySourceComponent game={game} timer={timer} zoomStore={zoomStore} />
        </Scrollbars>
      </div>
    );
  }
}

Display.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,
  height: PropTypes.number.isRequired,
  timer: MobxPropTypes.observableObject.isRequired,

  zoomStore: MobxPropTypes.observableObject,
};

export default observer(Display);

