/**
 * The name of the expire time variable.
 * @const {string}
 */
export const EXPIRE_TIME = 'expireTime';

/**
 * Expires in 10 minutes.
 * @const {number}
 */
export const EXPIRES_IN = 1000 * 60 * 10; // eslint-disable-line no-magic-numbers

/**
 * Expires a pending call in 1 minute.
 * @const {number}
 */
export const EXPIRES_PENDING = 1000 * 60; // eslint-disable-line no-magic-numbers
