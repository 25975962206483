import interact from 'interactjs/src/index';
import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';

import * as resizeCorner from '../../constants/resizeConstants';
import {actionCropComponent} from '../../display/components/action/actionCropComponent';
import {getIsCroppingLocked, getIsEntityTransitioning, getScaleFromElement} from '../../utils/dragDropHelper';
import {mapToRotatedFrame} from '../../utils/mathHelper';

import '../../utils/interactjs/actions/resize';

/**
 * Crops the entity.
 *
 * @param {ObservableMap} entity
 * @param {GameStore} game
 * @param {{top: number, left: number}} delta
 * @param {string} position
 */
function resizeCropEntity(entity, game, delta, position) {
  let positionDelta = {
    top: 0,
    left: 0,
  };
  let sizeDelta = {
    width: 0,
    height: 0,
  };

  let rotationDelta = lodash.cloneDeep(delta);
  const currentPosition = entity.get('position') || {};
  if (currentPosition.rotate) {
    rotationDelta = mapToRotatedFrame(currentPosition.rotate, delta);
  }

  if (rotationDelta.dx) {
    if (lodash.includes(resizeCorner.LEFTS, position)) {
      positionDelta.left += rotationDelta.dx;
      sizeDelta.width -= rotationDelta.dx;
    } else if (lodash.includes(resizeCorner.RIGHTS, position)) {
      sizeDelta.width += rotationDelta.dx;
    }
  }

  if (rotationDelta.dy) {
    if (lodash.includes(resizeCorner.TOPS, position)) {
      positionDelta.top += rotationDelta.dy;
      sizeDelta.height -= rotationDelta.dy;
    } else if (lodash.includes(resizeCorner.BOTTOMS, position)) {
      sizeDelta.height += rotationDelta.dy;
    }
  }

  const overallDelta = {
    ...positionDelta,
    ...sizeDelta,
  };

  game.addAction({entityId: entity.get('id')}, actionCropComponent(
    overallDelta,
    true,
    position,
  ));
}

/**
 * Gets whether or not the entity is active.
 *
 * @param {ObservableMap} entity
 * @returns {boolean}
 */
function getIsActive(entity) {
  const interaction = entity.get('interaction');

  if (getIsCroppingLocked(entity)) {
    return false;
  }

  return Boolean(interaction && interaction.isActive);
}

/**
 * A higher order component wrapper that handles making an entity croppable.
 *
 * @param {Object} WrappedComponent
 * @returns {Object}
 */
export default function entityCropResizeHocWrapper(WrappedComponent) {
  /**
   * The EntityCropResizeHoc higher order component.
   */
  class EntityCropResizeHoc extends React.Component {
    /**
     * Whether or not this entity is currently active.
     * Used to track the change.
     *
     * @type {boolean}
     */
    @observable isActive = false;

    /**
     * The DOM element for the element.
     *
     * @type {HTMLElement}
     */
    @observable domEl = null;

    /**
     * The DOM element for the display element.
     *
     * @type {HTMLElement}
     */
    @observable displayEl = null;

    /**
     * The interactJS object initialized on the DOM element.
     *
     * @type {{draggable: function, resizable: function}}
     */
    @observable interaction = null;

    /**
     * Triggered right after the component mounts to the page.
     */
    componentDidMount() {
      this.checkActiveStateChange();
    }

    /**
     * Triggered when the component is about to unmount.
     */
    componentWillUnmount() {
      this.stopInteraction();
    }

    /**
     * Triggered when an observed item updates.
     */
    componentWillReact = () => {
      this.checkActiveStateChange();
    };

    /**
     * Checks whether or not the active state has changed and either allows it to be dragged or stops it from
     * dragging.
     */
    @action checkActiveStateChange = () => {
      const isActive = getIsActive(this.props.entity);

      if (!this.isActive && isActive) {
        this.initInteraction();
        this.isActive = true;
      } else if (this.isActive && !isActive) {
        this.stopInteraction();
        this.isActive = false;
      }
    };

    /**
     * Triggered right after the wrapped component is added to OR removed from the page.
     *
     * @param {{}} domEl
     */
    @action onChangeMount = (domEl) => {
      if (this.domEl) {
        return;
      }

      this.domEl = findDOMNode(domEl);
      this.displayEl = document.getElementById('display-crop');
    };

    /**
     * Handles the resize move event.
     *
     * @param {{deltaRect: {left: number, top: number}}} dragEvent
     */
    onResize = (dragEvent) => {
      const entity = this.props.entity;
      const game = this.props.game;
      const resizePosition = this.props.resizePosition;

      if (getIsEntityTransitioning(entity, game.timer.elapsedTime)) {
        // TODO: Indicate to the user why dragging isn't working.
        return;
      }

      const scale = getScaleFromElement(this.displayEl);

      resizeCropEntity(entity, game, {
        dy: dragEvent.dy / scale,
        dx: dragEvent.dx / scale,
      }, resizePosition);
    };

    /**
     * Starts the interactJS code.
     */
    @action initInteraction = () => {
      const {entity} = this.props;

      if (this.interaction) {
        // Make sure we don't have multiple dragging interactions on this element.
        this.interaction.unset();
      }

      if (getIsCroppingLocked(entity)) {
        return;
      }

      const interaction = interact(this.domEl);
      interaction.draggable({
        onmove: this.onResize,
      });

      this.interaction = interaction;
    };

    /**
     * Unbinds the dragging code.
     */
    @action stopInteraction = () => {
      if (!this.interaction) {
        return;
      }

      this.interaction.unset();
      this.interaction = null;
    };

    /**
     * Renders the WrappedComponent.
     *
     * @returns {Object}
     */
    render() {
      if (!WrappedComponent) {
        return null;
      }

      if (this.props.ref) {
        throw new Error('EntityCropResizeHoc will override ref property given to the wrapped component.');
      }

      return (
        <WrappedComponent
          {...this.props}
          ref={this.onChangeMount}
        />
      );
    }
  }

  EntityCropResizeHoc.propTypes = {
    entity: MobxPropTypes.observableMap.isRequired,
    game: PropTypes.shape({
      addAction: PropTypes.func,
      setEntityComponents: PropTypes.func,
      timer: PropTypes.shape({
        elapsedTime: PropTypes.number,
      }),
    }).isRequired,
    resizePosition: PropTypes.string.isRequired,
    ref: PropTypes.func,
  };

  return observer(EntityCropResizeHoc);
}
