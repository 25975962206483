import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';

import DisplayContentSrc from '../../common/displayContentSrc/DisplayContentSrc';

import './previewContentModal.scss';

/**
 * The maximum content height.
 * @const {number}
 */
const MAX_CONTENT_HEIGHT = 452;

/**
 * The maximum content width.
 * @const {number}
 */
const MAX_CONTENT_WIDTH = 452;

/**
 * The PreviewContentModal component.
 */
class PreviewContentModal extends React.Component {
  /**
   * Triggered when the modal is cancelled.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {content, contentSize, isImage, isOpen, libraryType, title} = this.props;

    let parsedTitle = title || 'Preview';
    if (title && content.title) {
      parsedTitle = title.replace('%t', content.title);
    }

    return (
      <Modal className="preview-content-modal" isOpen={isOpen} backdrop="static" toggle={this.onCancelModal}>
        <ModalHeader toggle={this.onCancelModal}>{parsedTitle}</ModalHeader>
        <ModalBody>
          <div className="modal-body-content">
            <DisplayContentSrc
              content={content}
              contentSize={contentSize}
              maxSize={{width: MAX_CONTENT_WIDTH, height: MAX_CONTENT_HEIGHT}}
              isImage={isImage}
              libraryType={libraryType}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

PreviewContentModal.propTypes = {
  content: PropTypes.object.isRequired,
  isImage: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  libraryType: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,

  contentSize: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }),
  title: PropTypes.string,
};

PreviewContentModal.defaultProps = {
  title: 'Preview'
};

export default PreviewContentModal;
