import interact from 'interactjs/src/index';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {findDOMNode} from 'react-dom';

import {actionAddEntityComponent} from '../../display/components/action/actionAddEntityComponent';
import {actionInteractionComponent} from '../../display/components/action/actionInteractionComponent';
import {getScaleFromElement} from '../../utils/dragDropHelper';

/**
 * The height we will assign to the new entity.
 * @const {number}
 */
const NEW_ENTITY_HEIGHT = 200;

/**
 * The width we will assign to the new entity.
 * @const {number}
 */
const NEW_ENTITY_WIDTH = 200;

/**
 * Adds an entity to the store.
 *
 * @param {{addSourceEntity: function}} game
 * @param {{top: number, left: number}} position
 * @param {string} type
 * @param {{}} entityData
 */
function placeEntity(game, position, type, entityData) {
  const ENTITY_DURATION = 5000;
  const timer = game.timer;
  const start = timer.time;
  const end = timer.time + ENTITY_DURATION;

  const newEntitySource = {
    type,
    startTime: start,
    endTime: end,
    setup: {
      size: {
        height: NEW_ENTITY_HEIGHT,
        width: NEW_ENTITY_WIDTH,
      },
      position,
    },
    [type]: entityData,
  };

  const newEntityComponent = actionAddEntityComponent(newEntitySource, true);
  const actionComponents = Object.assign(
    {},
    newEntityComponent,
    actionInteractionComponent(true, false, false)
  );

  // Now activate the new item.
  const actionParams = {
    entityId: true
  };
  game.addAction(actionParams, actionComponents);
}

/**
 * A higher order component wrapper that handles making an area a dropzone for controls.
 *
 * @param {{}} options
 * @param {Object} WrappedComponent
 * @returns {Object}
 */
export default function controlDropZoneHocWrapper(options, WrappedComponent) {
  const safeOptions = options || {};

  /**
   * The ControlDropZoneHoc higher order component.
   */
  class ControlDropZoneHoc extends React.Component {
    /**
     * The DOM element for the element.
     *
     * @type {HTMLElement}
     */
    @observable domEl = null;

    /**
     * The interactJS object initialized on the DOM element.
     *
     * @type {{draggable: function, resizable: function}}
     */
    @observable interaction = null;

    /**
     * Triggered when the component just mounted to the page.
     */
    componentDidMount() {
      this.initInteraction();
    }

    /**
     * Triggered when the component is about to unmount.
     */
    componentWillUnmount() {
      this.stopInteraction();
    }

    /**
     * Triggered right after the wrapped component is added to OR removed from the page.
     *
     * @param {{}} domEl
     */
    @action onChangeMount = (domEl) => {
      if (this.domEl) {
        return;
      }

      this.domEl = findDOMNode(domEl);
    };

    /**
     * Handles when the drop flow ends.
     *
     * @param {{target: {}, relatedTarget: {}}} dropEndEvent
     */
    onDropDeactivate = (dropEndEvent) => {
      const droppableEl = dropEndEvent.relatedTarget;
      droppableEl.style.webkitTransform = droppableEl.style.transform = '';
    };

    /**
     * Handles the drop event.
     *
     * @param {{target: {}, relatedTarget: {}}} dropEvent
     */
    onDrop = (dropEvent) => {
      const dropZoneEl = dropEvent.target;
      const dropZoneRect = dropZoneEl.getBoundingClientRect();

      const droppableEl = dropEvent.relatedTarget;
      const droppableRect = droppableEl.getBoundingClientRect();

      const scale = getScaleFromElement(dropZoneEl);

      const position = {
        left: this.normalizeDropPosition(
          dropZoneRect.left,
          dropZoneRect.width,
          droppableRect.left,
          NEW_ENTITY_WIDTH,
          scale
        ),
        top: this.normalizeDropPosition(
          dropZoneRect.top,
          dropZoneRect.height,
          droppableRect.top,
          NEW_ENTITY_HEIGHT,
          scale
        ),
      };

      const type = droppableEl.getAttribute('data-element-type');
      const data = JSON.parse(droppableEl.getAttribute('data-element-data'));
      if (!type || !data) {
        throw new Error('Invalid add control type and data set.');
      }

      placeEntity(this.props.game, position, type, data);
    };

    /**
     * Get the position such that its between 0 and dropTargetBound.
     *
     * @param {number} dropZonePosition
     * @param {number} dropZoneSize
     * @param {number} droppedElementPosition The x/y coordinate of left/top drop target.
     * @param {number} droppedElementSize The width/height of drop target.
     * @param {number} scale The scale of the dropZone.
     * @returns {number}
     */
    normalizeDropPosition = (dropZonePosition, dropZoneSize, droppedElementPosition, droppedElementSize, scale) => {
      const scaledDroppedSize = droppedElementSize * scale;
      const dropTargetOffset = droppedElementPosition - dropZonePosition;

      // Check and correct if item was dropped to the left/above the drop target
      if (dropTargetOffset < 0) {
        return 0;
      }

      // Check and correct if item was dropped to the right/below the drop target
      if (dropTargetOffset + scaledDroppedSize > dropZoneSize) {
        return (dropZoneSize - scaledDroppedSize) / scale;
      }

      return dropTargetOffset / scale;
    };

    /**
     * Starts the interactJS code.
     */
    @action initInteraction = () => {
      if (this.interaction) {
        // Make sure we don't have multiple dragging interactions on this element.
        this.interaction.unset();
      }

      const interaction = interact(this.domEl);
      interaction.dropzone({
        accept: safeOptions.dropItemSelector || null,
        ondrop: this.onDrop,
        ondropdeactivate: this.onDropDeactivate
      });

      this.interaction = interaction;
    };

    /**
     * Unbinds the interactJS code.
     */
    @action stopInteraction = () => {
      if (!this.interaction) {
        return;
      }

      this.interaction.unset();
      this.interaction = null;
    };

    /**
     * Renders the WrappedComponent.
     *
     * @returns {Object}
     */
    render() {
      if (this.props.ref) {
        throw new Error('ControlDropZoneHoc will override ref property given to the wrapped component.');
      }

      return (
        <WrappedComponent
          {...this.props}
          ref={this.onChangeMount}
        />
      );
    }
  }

  ControlDropZoneHoc.propTypes = {
    game: PropTypes.shape({
      addAction: PropTypes.func,
      setEntityComponents: PropTypes.func,
      timer: PropTypes.shape({
        elapsedTime: PropTypes.number,
      }),
    }).isRequired,
    ref: PropTypes.func,
  };

  return observer(ControlDropZoneHoc);
}
