/**
 * The image component.
 *
 * @param {number} fileId
 * @param {number} imageTop
 * @param {number} imageLeft
 * @param {number} imageWidth
 * @param {number} imageHeight
 * @returns {{image: {fileId: number, top: number, left: number, width: number, height: number}}}
 */
export function imageComponent(fileId, imageTop, imageLeft, imageWidth, imageHeight) {
  return {
    image: {
      fileId,
      top: imageTop,
      left: imageLeft,
      width: imageWidth,
      height: imageHeight,
    },
  };
}

/**
 * Gets the image component from the source item.
 *
 * @param {{image: {fileId: number, top: number, left: number, width: number, height: number}}} item
 * @returns {{image: {fileId: number, top: number, left: number, width: number, height: number}}}
 */
export function getImageFromSource(item) {
  if (!item.image) {
    return {};
  } else if (!item.image.fileId) {
    throw new Error('Invalid image fileId for imageComponent.');
  }

  const image = item.image;

  return imageComponent(
    Number(image.fileId),
    Number(image.top),
    Number(image.left),
    Number(image.width),
    Number(image.height),
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getImageForSource(entity) {
  if (!entity.has('image')) {
    return {};
  }

  const image = entity.get('image');
  return {
    image: {
      fileId: image.fileId,
      top: image.top,
      left: image.left,
      width: image.width,
      height: image.height,
    },
  };
}
