/* eslint-disable */

import {parseFramesPerSecond} from './ecs/timeHelper';
import {getTimerEntity} from './ecs/entityHelper';
import {parseSourceFromGame} from './ecs/sourceHelper';
import {validateSource} from './ecs/sourceValidator';
import {systemProrities} from './ecs/systemProrities';

/* Stores */
import {GameStore, parseGameConfigFromSource} from '../stores/game/gameStore';
import {GameFeedStore} from '../stores/game/gameFeedStore';
import {GameHistoryStore} from '../stores/game/gameHistoryStore';
import {GameTimerStore} from '../stores/game/gameTimerStore';

/* Systems */
import {actionAspectRatioSystem} from './systems/actionAspectRatioSystem';
import {actionEntitySystem} from './systems/actionEntitySystem';
import {actionPurgeSystem} from './systems/actionPurgeSystem';
import {actionUpdatingSystem} from './systems/actionUpdatingSystem';
import {alignmentSystem} from './systems/alignmentSystem';
import {croppingSystem} from './systems/croppingSystem';
import {groupingSystem} from './systems/groupingSystem';
import {interactionSystem} from './systems/interactionSystem';
import {linePositioningSystem} from './systems/linePositioningSystem';
import {orderingSystem} from './systems/orderingSystem';
import {positioningSystem} from './systems/positioningSystem';
import {trackingSystem} from './systems/trackingSystem';
import {transitionSystem} from './systems/transitionSystem';
import {visibilitySystem} from './systems/visibilitySystem';

/**
 * Loads up a game using a source file.
 *
 * @param {{}} source
 * @param {boolean=} isContinuous
 * @param {?Array.<{}>} feeds
 * @returns {GameStore}
 */
export function loadGameFromSource(source, isContinuous, feeds) {
  // First make sure the source file is valid.
  const validatedSource = validateSource(source);

  const gameConfig = parseGameConfigFromSource(validatedSource);

  const game = new GameStore(gameConfig);
  game.setFps(parseFramesPerSecond(gameConfig.fps));
  game.addSystem(actionEntitySystem(game), systemProrities.entity);
  game.addSystem(visibilitySystem(game), systemProrities.visibility);
  game.addSystem(groupingSystem(game), systemProrities.visibility);
  game.addSystem(interactionSystem(game), systemProrities.visibility);
  game.addSystem(alignmentSystem(game), systemProrities.aligning);
  game.addSystem(actionAspectRatioSystem(game), systemProrities.positioning);
  game.addSystem(linePositioningSystem(game), systemProrities.positioning);
  game.addSystem(orderingSystem(game), systemProrities.positioning);
  game.addSystem(positioningSystem(game), systemProrities.positioning);
  game.addSystem(croppingSystem(game), systemProrities.cropping);
  game.addSystem(trackingSystem(game), systemProrities.tracking);
  game.addSystem(transitionSystem(game), systemProrities.transition);
  game.addSystem(actionUpdatingSystem(game), systemProrities.typeUpdate);
  game.addSystem(actionPurgeSystem(game), systemProrities.cleanup);

  const gameFeed = new GameFeedStore();
  game.setFeed(gameFeed);

  if (feeds.fromId) {
    gameFeed.setIdFeeds(feeds.fromId);
  }
  if (feeds.fromCustom) {
    gameFeed.setCustomFeed(feeds.fromCustom)
  }
  if (feeds.fromApi) {
    gameFeed.setApiFeed(feeds.fromApi);
  }

  if (validatedSource.feedId) {
    gameFeed.setFeedId(validatedSource.feedId);
  } else if (validatedSource.feedSource) {
    gameFeed.setFeedSource(validatedSource.feedSource);
    gameFeed.fetchFeedDataFromSource();
  }

  if (validatedSource.feedIndex) {
    gameFeed.setFeedIndex(validatedSource.feedIndex);
  }

  validatedSource.entities.forEach((sourceEntity) => {
    game.addSourceEntity(sourceEntity);
  });
  game.addEntity(
    getTimerEntity(game.endTime),
    true,
    'entity__main-timer'
  );

  const timer = new GameTimerStore();
  timer.setIsContinuous(Boolean(isContinuous));
  timer.setTickHandler(function handleTick(event) {
    game.update(event.time);
  });

  /**
   * Attach the timer and history to the game.
   */
  game.setTimer(timer);
  game.setHistory(new GameHistoryStore());
  timer.setMaxTime(game.endTime);

  // Initializes the display and loads everything into js memory.
  game.update(0);

  // Store the start of the game undo point.
  game.addUndoPoint();

  return {game, timer};
}

/**
 * Gets the final source JSON using the game object.
 *
 * @param {GameStore} game
 * @returns {{resolution: {width: number, height: number}, endTime: number, entities: Array<{type: string}>}}
 */
export function getSourceFromGame(game) {
  return parseSourceFromGame(game);
}
