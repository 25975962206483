import lodash from 'lodash';

/**
 * Transforms the keys of the body to PascalCase.
 *
 * @param {{}} body
 * @returns {{}}
 */
function pascalCase(body) {
  return lodash.reduce(body, (reduced, keyValue, keyName) => {
    const newName = lodash.upperFirst(keyName);
    reduced[newName] = keyValue;
    return reduced;
  }, {});
}

export default pascalCase;
