import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import {STATE_PAUSED, STATE_PLAYING, STATE_STOPPED} from '../../../constants/displayItemConstants';
import {actionInteractionComponent} from '../../../display/components/action/actionInteractionComponent';

import './playerControls.scss';

/**
 * The PlayerControls component.
 *
 * @constructor
 */
export class PlayerControls extends React.Component {
  /**
   * @constructor
   * @param {{}} props
   * @param {{}} componentContext
   */
  constructor(props, componentContext) {
    super(props, componentContext);
  }

  /**
   * Plays the video.
   */
  onPlay = () => {
    const {game, timer} = this.props;

    timer.start();

    const activeEntity = game.activeEntity;
    if (!activeEntity) {
      return;
    }

    const actionParams = {
      entityId: activeEntity.get('id'),
      skipHistory: true,
    };

    game.addAction(actionParams, actionInteractionComponent(false, false, false));
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {timer} = this.props;
    const timerState = timer.state;

    return (
      <div className="player-controls">
        {(timerState === STATE_PAUSED || timerState === STATE_STOPPED) && (
          <a
            className="control-button button-play"
            onClick={this.onPlay}
          ><i className="fad fad-play" /></a>
        )}

        {(timerState === STATE_PLAYING) && (
          <a
            className="control-button button-pause"
            onClick={() => {
              timer.pause();
            }}
          ><i className="fad fad-pause" /></a>
        )}

        {(timerState === STATE_PAUSED) && (
          <a
            className="control-button button-back"
            onClick={() => {
              timer.stopAndReset();
            }}
          ><i className="fad fad-step-backward" /></a>
        )}
      </div>
    );
  }
}

PlayerControls.propTypes = {
  game: MobxPropTypes.observableObject,
  timer: MobxPropTypes.observableObject,
};

export default observer(PlayerControls);
