import classNames from 'classnames';
import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

import inject from '../../../../hoc/injectHoc';
import entityReorderHoc from '../../../../hoc/entityReorderHoc';
import InputStringModal from '../../../../modals/inputString/InputStringModal';
import {actionDuplicateEntityComponent} from '../../../../../display/components/action/actionDuplicateEntityComponent';
import {actionInteractionComponent} from '../../../../../display/components/action/actionInteractionComponent';
import {actionRemoveEntityComponent} from '../../../../../display/components/action/actionRemoveEntityComponent';
import {actionRenameEntityComponent} from '../../../../../display/components/action/actionRenameEntityComponent';
import {ENTITY_MAX_LENGTH} from '../../../../../constants/uiConstants';

import './editorEntityListItem.scss';

/**
 * The EditorEntityListItem component.
 *
 * @constructor
 */
export class EditorEntityListItem extends React.Component {
  /**
   * Whether or not the menu is open.
   *
   * @type {boolean}
   */
  @observable isMenuOpen = false;

  /**
   * Whether or not the rename modal is open.
   *
   * @type {boolean}
   */
  @observable isRenameModalOpen = false;

  /**
   * Activates the entity.
   *
   * @param {boolean} onlyActivate If true, the entity can't be deselected.
   * @param {{}=} clickEvent
   * @param {boolean=} preventTimeChange
   */
  activateEntity = (onlyActivate, clickEvent, preventTimeChange) => {
    const {entity} = this.props;
    const {game, timer} = this.props.displayEditorStore;

    const holdingShift = Boolean(clickEvent && clickEvent.shiftKey);

    const interaction = entity.get('interaction');
    const isActive = Boolean(interaction && interaction.isActive);

    const actionParams = {
      entityId: entity.get('id'),
      skipHistory: true,
    };

    /**
     * Activates the entity.
     *
     * @param {boolean=} setTime
     */
    function activate(setTime = true) {
      game.addAction(actionParams, actionInteractionComponent(true, holdingShift, false));

      if (setTime) {
        const activeTime = entity.get('time').active;
        timer.setTime(activeTime);
      }
    }

    /**
     * Deactivates the entity.
     */
    function deactivate() {
      game.addAction(actionParams, actionInteractionComponent(false, holdingShift, false));
    }

    if (onlyActivate) {
      activate(!preventTimeChange);
    } else if (isActive) {
      deactivate();
    } else {
      activate(!preventTimeChange);
    }
  };

  /**
   * Triggered when the dropdown open button is clicked.
   *
   * @param {{stopPropagation: function}} clickEvent
   */
  @action onClickDropdown = (clickEvent) => {
    // We want to prevent the click on the list-item, so stop propagation.
    clickEvent.stopPropagation();

    this.isMenuOpen = !this.isMenuOpen;
    if (this.isMenuOpen) {
      // If the menu is now open, make sure to activate this entity to avoid zIndex stacking context issues.
      this.activateEntity(true);
    }
  };

  /**
   * Triggered when the actions dropdown button is clicked.
   *
   * @param {{stopPropagation: function}} clickEvent
   */
  onClickStopPropagation = (clickEvent) => {
    clickEvent.stopPropagation();
  };

  /**
   * Triggered when the the entity delete option is clicked.
   *
   * @param {{}} clickEvent
   */
  onEntityDelete = (clickEvent) => {
    clickEvent.stopPropagation();

    const {entity} = this.props;
    const {game} = this.props.displayEditorStore;

    const actionParams = {
      entityId: entity.get('id')
    };

    game.addAction(actionParams, actionRemoveEntityComponent());
  };

  /**
   * Triggered when the the entity duplicate option is clicked.
   *
   * @param {{}} clickEvent
   */
  onEntityDuplicate = (clickEvent) => {
    clickEvent.stopPropagation();

    const {entity} = this.props;
    const {game} = this.props.displayEditorStore;

    const actionParams = {
      entityId: entity.get('id')
    };

    game.addAction(actionParams, Object.assign(
      {},
      actionDuplicateEntityComponent(),
      actionInteractionComponent(true)
    ));
  };

  /**
   * Triggered when the the entity rename option is clicked.
   */
  @action onOpenRenameModal = () => {
    this.isRenameModalOpen = true;
  };

  /**
   * Triggered when the the entity rename option is clicked.
   *
   * @param {string} newName
   */
  @action onEntityRename = (newName) => {
    this.isRenameModalOpen = false;

    if (!newName) {
      // The modal must have been cancelled.
      return;
    }

    // The maximum character length for entity names.
    const maxLength = 39;

    let safeName = String(newName);
    if (safeName.length > maxLength) {
      safeName = safeName.substr(0, maxLength) + '...';
    }

    const {entity} = this.props;
    const {game} = this.props.displayEditorStore;

    const actionParams = {
      entityId: entity.get('id')
    };

    game.addAction(actionParams, Object.assign(
      {},
      actionRenameEntityComponent(safeName),
      actionInteractionComponent(true)
    ));
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {game, entity, entityOrder, disableOrdering, isLast} = this.props;
    const interaction = entity.get('interaction');
    const active = Boolean(interaction && interaction.isActive);
    const locked = (entity.has('locked') && lodash.includes(entity.get('locked'), 'order'));

    const topClasses = {
      active,
      locked
    };

    let entityName = lodash.upperFirst(entity.get('element'));
    let preventTimeChange = false;
    if (isLast) {
      entityName = `Background ${entityName}`;
      preventTimeChange = true;
    } else if (entity.has('name')) {
      entityName = entity.get('name');
    }

    return (
      <a
        className={classNames('editor-entity-list-item list-group-item list-group-item-action', topClasses)}
        onClick={(clickEvent) => this.activateEntity(false, clickEvent, preventTimeChange)}
      >
        <div className="entity-name-wrapper clearfix">
          <span className="entity-order">
            <span className="badge badge-pill badge-pill-circle badge-outline-dark">{entityOrder}</span>
          </span>
          <span className="entity-name">
            {entityName}
          </span>
        </div>

        <div className="entity-actions-wrapper">
          {(!locked) && (
            <span className="entity-actions">
              <Dropdown isOpen={this.isMenuOpen} toggle={this.onClickDropdown}>
                <DropdownToggle className="btn btn-sm btn-link btn-icon" tag="button">
                  <i className="fa icon-bold fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={this.onOpenRenameModal}
                  >
                    <i className="fa fa-font" />
                    Change Name
                  </DropdownItem>

                  {(game.entities && game.entities.length < ENTITY_MAX_LENGTH) && (
                    <DropdownItem
                      onClick={this.onEntityDuplicate}
                    >
                      <i className="fa fa-clone" />
                      Duplicate
                    </DropdownItem>
                  )}

                  <DropdownItem onClick={this.onEntityDelete}>
                    <i className="fa fa-trash" />
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </span>
          )}

          {(!disableOrdering) && (
            <span className="entity-reorder-action" onClick={this.onClickStopPropagation}>
              <i className="fa icon-bold fa-bars" />
            </span>
          )}

          {(this.isRenameModalOpen) && (
            <InputStringModal
              isOpen={true}
              title="Rename Layer"
              onComplete={this.onEntityRename}
              startingValue={entityName}
            />
          )}
        </div>
      </a>
    );
  }
}

EditorEntityListItem.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  entityOrder: PropTypes.number.isRequired,
  game: MobxPropTypes.observableObject.isRequired,

  disableOrdering: PropTypes.bool,
  displayEditorStore: MobxPropTypes.observableObject,
  isLast: PropTypes.bool,
};

EditorEntityListItem.defaultProps = {
  disableOrdering: false,
  isLast: false,
};

EditorEntityListItem.wrappedComponent = {};
EditorEntityListItem.wrappedComponent.propTypes = {
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
};

export default entityReorderHoc(
  inject(EditorEntityListItem.wrappedComponent.propTypes)(
    observer(EditorEntityListItem)
  )
);
