import {RichUtils} from 'draft-js';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {MIN_LETTER_SPACING, MAX_LETTER_SPACING, LETTER_SPACING_PREFIX} from '../../constants/styleContants';
import {addLetterSpacingToStyleMap, getAllActiveStylesWithPrefix, removeFromStyleMap} from '../../utils/styleHelper';
import Select from '../../../../common/select/Select';

import './letterSpacingSelect.scss';

/**
 * The default letter spacing to fall back to.
 * @const {string}
 */
const DEFAULT_LETTER_SPACING = '0';

/**
 * The list of letter spacing values.
 *
 * @type {Array.<{label: string, value: string}>}
 */
const LETTER_SPACING_VALUES = lodash.range(MIN_LETTER_SPACING, MAX_LETTER_SPACING + 1).map((letterSpacing) => {
  const label = String(letterSpacing) + 'px';
  return {label: label, value: String(letterSpacing)};
});

/**
 * Converts a letter spacing into the draftJS style name.
 *
 * @param {string} letterSpacing
 * @returns {string}
 */
function convertLetterSpacingToStyleName(letterSpacing) {
  return `${LETTER_SPACING_PREFIX}${letterSpacing}`;
}

/**
 * Gets the active letter spacing from a list of styles.
 *
 * @param {string[]} allStyles
 * @param {string} defaultValue
 * @returns {number} The letter spacing
 */
function getLetterSpacingFromStyles(allStyles, defaultValue) {
  if (!allStyles || !allStyles.forEach) {
    return defaultValue;
  }

  let foundLetterSpacing = defaultValue;
  allStyles.forEach((style) => {
    if (style.substr(0, LETTER_SPACING_PREFIX.length) === LETTER_SPACING_PREFIX) {
      foundLetterSpacing = style.substr(LETTER_SPACING_PREFIX.length);
    }
  });
  return foundLetterSpacing;
}

/**
 * The LetterSpacingSelect component.
 *
 * @param {{}} params
 * @param {{}} params.editorState
 * @param {function(EditorState)} params.onChangeTextState
 * @param {function()=} params.beforeChange
 * @returns {React.Component}
 */
export const LetterSpacingSelect = ({editorState, onChangeTextState, beforeChange}) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  const currentValue = getLetterSpacingFromStyles(currentStyle.toArray(), DEFAULT_LETTER_SPACING);

  /**
   * Updates the editor state with a new style when the selection changes.
   *
   * @param {string} newLetterSpacing
   */
  const onChange = (newLetterSpacing) => {
    let changeState = editorState;
    if (beforeChange) {
      changeState = beforeChange();
    }

    const newStyleName = convertLetterSpacingToStyleName(newLetterSpacing);

    const beforeChangeStyle = changeState.getCurrentInlineStyle();
    const activeLetterSpacings = getAllActiveStylesWithPrefix(changeState, LETTER_SPACING_PREFIX);

    // First remove any letter spacings currently set.
    let newEditorState = changeState;
    lodash.forEach(activeLetterSpacings, (unused, activeLetterSpacing) => {
      if (beforeChangeStyle.has(activeLetterSpacing)) {
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, activeLetterSpacing);
      } else {
        /*
         * Because of a bug in detecting active styles in mixed blocks, we need to turn on this letter spacing
         * everywhere, and then off again. This will make sure all the currently set letter spacings are removed.
         */
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, activeLetterSpacing);
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, activeLetterSpacing);
      }

      const newActiveLetterSpacings = getAllActiveStylesWithPrefix(newEditorState, LETTER_SPACING_PREFIX);
      if (!newActiveLetterSpacings[activeLetterSpacing]) {
        // In order to prevent a memory build up, remove this style if it is no longer in use.
        removeFromStyleMap(activeLetterSpacing);
      }
    });

    addLetterSpacingToStyleMap(newStyleName, newLetterSpacing);

    newEditorState = RichUtils.toggleInlineStyle(newEditorState, newStyleName);
    onChangeTextState(newEditorState);
  };

  return (
    <div className="letter-spacing-select">
      <Select
        theme="dark"
        onChange={onChange}
        options={LETTER_SPACING_VALUES}
        value={currentValue}
      />
    </div>
  );
};

LetterSpacingSelect.propTypes = {
  editorState: PropTypes.object.isRequired,
  onChangeTextState: PropTypes.func.isRequired,

  beforeChange: PropTypes.func,
};

export default LetterSpacingSelect;
