import lodash from 'lodash';

import {GAME_TYPE_IMAGE} from '../../stores/game/gameStore';

/**
 * Validates each part of the source json.
 *
 * @param {{}} source
 * @returns {{}} The validated source (changes might be made to the source to support version updates).
 */
export function validateSource(source) {
  if (!source) {
    throw new Error('Invalid Source: No source found.');
  } else if (!lodash.isPlainObject(source)) {
    throw new Error('Invalid Source: The source is not a plain javascript object.');
  }

  const validatedSource = lodash.cloneDeep(source);

  checkResolution(validatedSource);
  checkEndTime(validatedSource);
  checkEntities(validatedSource);

  validatedSource.entities.forEach((entity, index) => {
    checkEntity(entity, index, source);
  });

  return validatedSource;
}

/**
 * Checks the `resolution` property of the source.
 *
 * @param {{resolution: {width: number, height: number}}} source
 */
function checkResolution(source) {
  if (!source.resolution) {
    throw new Error('Invalid Source: `resolution` property must be an object with `width` and `height`.');
  }

  const width = parseInt(source.resolution.width, 10);
  const height = parseInt(source.resolution.height, 10);

  if (!width || width < 1) {
    throw new Error('Invalid Source: `resolution.width` property must a valid positive integer.');
  } else if (!height || height < 1) {
    throw new Error('Invalid Source: `resolution.height` property must a valid positive integer.');
  }
}

/**
 * Checks the `endTime` property of the source.
 *
 * @param {{endTime: number}} source
 */
function checkEndTime(source) {
  if (source.type && source.type === GAME_TYPE_IMAGE) {
    return;
  }

  const endTime = parseInt(source.endTime, 10);
  if (!endTime || endTime < 1) {
    throw new Error('Invalid Source: `endTime` property must a valid positive integer.');
  }
}

/**
 * Validates the `entities` property of the source.
 *
 * @param {{entities: Array.<{}>}} source
 */
function checkEntities(source) {
  if (!source.entities) {
    throw new Error('Invalid Source: `entities` property must be an array of entity objects.');
  }
}

/**
 * Validates an entity.
 *
 * @param {{}} entity
 * @param {number} index
 * @param {{}} source
 */
function checkEntity(entity, index, source) {
  const starterErrorMessage = `Invalid Source: Entity at index ${index} `;
  if (!entity || !lodash.isPlainObject(entity)) {
    throw new Error(starterErrorMessage + 'is not a plain javascript object.');
  }

  if (!entity.type) {
    throw new Error(starterErrorMessage + 'has no `type` parameter.');
  } else if (!entity[entity.type]) {
    throw new Error(
      starterErrorMessage + `has no \`${entity.type}\` parameter (required for ${entity.type} entities).`
    );
  }

  checkEntityTimes(entity, starterErrorMessage);
  checkEntitySetup(entity, starterErrorMessage);
  checkEntityTransitions(entity, starterErrorMessage);
  checkEntityGroup(entity, starterErrorMessage);

  checkEntityText(entity, starterErrorMessage);
  checkEntityIcon(entity, starterErrorMessage);
  checkEntityLine(entity, starterErrorMessage);
  checkEntityVideo(entity, starterErrorMessage);
  checkEntityImage(entity, starterErrorMessage, source);
}

/**
 * Validates an entity's time parameters.
 *
 * @param {{}} entity
 * @param {string} errorMsg
 */
function checkEntityTimes(entity, errorMsg) {
  const startTime = parseInt(entity.startTime, 10);
  if (startTime < 0 || isNaN(startTime)) {
    throw new Error(errorMsg + '- `startTime` parameter must a valid positive integer or zero.');
  }

  if (entity.endTime) {
    const endTime = parseInt(entity.endTime, 10);
    if (endTime < 1 || isNaN(endTime)) {
      throw new Error(errorMsg + '- `endTime` parameter must a valid positive integer or not specified.');
    }
  }
}

/**
 * Validates an entity's setup parameters.
 *
 * @param {{}} entity
 * @param {string} errorMsg
 */
function checkEntitySetup(entity, errorMsg) {
  if (!entity.setup) {
    throw new Error(errorMsg + ' - `setup` parameter must be defined.');
  }
  if (entity.setup.locked) {
    if (!Array.isArray(entity.setup.locked)) {
      throw new Error(errorMsg + '- `setup.locked` parameter must an array.');
    }
  }
  if (entity.setup.position) {
    const topPosition = parseInt(entity.setup.position.top, 10);
    const leftPosition = parseInt(entity.setup.position.left, 10);

    if (isNaN(topPosition)) {
      throw new Error(errorMsg + '- `setup.position.top` parameter must a valid integer.');
    }
    if (isNaN(leftPosition)) {
      throw new Error(errorMsg + '- `setup.position.left` parameter must a valid integer.');
    }
  }
  if (entity.setup.opacity) {
    const opacity = parseInt(entity.setup.opacity, 10);
    if (opacity < 0 || isNaN(opacity)) {
      throw new Error(errorMsg + '- `setup.opacity` parameter must a valid positive integer or not specified.');
    }
  }

  checkEntitySetupSize(entity, errorMsg);
  checkEntitySetupCrop(entity, errorMsg);
}

/**
 * Validates an entity's setup size parameters.
 *
 * @param {{}} entity
 * @param {string} errorMsg
 */
function checkEntitySetupSize(entity, errorMsg) {
  if (!entity.setup.size) {
    return;
  }
  if (!lodash.isPlainObject(entity.setup.size)) {
    throw new Error(errorMsg + '- `setup.size` parameter must a plain javascript object or not specified.');
  }
  if (entity.setup.size.width) {
    const widthErrorMsg = checkWidthOrHeight(entity.setup.size.width);
    if (widthErrorMsg) {
      throw new Error(errorMsg + '- `setup.size.width` parameter ' + widthErrorMsg);
    }
  }
  if (entity.setup.size.height) {
    const heightErrorMsg = checkWidthOrHeight(entity.setup.size.height);
    if (heightErrorMsg) {
      throw new Error(errorMsg + '- `setup.size.height` parameter ' + heightErrorMsg);
    }
  }
}

/**
 * Checks the width or height to make sure it is valid.
 *
 * @param {string|number} value
 * @returns {?string}
 */
function checkWidthOrHeight(value) {
  if (value === 'auto') {
    return null;
  }

  if (String(value).match(/^[0-9.]+(%|px|em)?$/)) {
    return null;
  }

  return 'must be a number or string (# or #% or #px or #em) or "auto".';
}

/**
 * Validates an entity's setup crop parameters.
 *
 * @param {{}} entity
 * @param {string} errorMsg
 */
function checkEntitySetupCrop(entity, errorMsg) {
  if (!entity.setup.crop) {
    return;
  }

  const crop = entity.setup.crop;
  if (crop.top === undefined) {
    throw new Error(errorMsg + '- `setup.crop.top` parameter is missing.');
  }
  if (crop.left === undefined) {
    throw new Error(errorMsg + '- `setup.crop.left` parameter is missing.');
  }

  const size = lodash.get(entity, 'setup.size', null);
  if (!crop.width) {
    if (!size) {
      throw new Error(errorMsg + '- `setup.crop` parameter must have a `width` parameter.');
    } else {
      crop.width = size.width - crop.left;
    }
  }
  if (!crop.height) {
    if (!size) {
      throw new Error(errorMsg + '- `setup.crop` parameter must have a `height` parameter.');
    } else {
      crop.height = size.height - crop.top;
    }
  }
}

/**
 * Validates an entity's group parameters.
 *
 * @param {{}} entity
 * @param {string} errorMsg
 */
function checkEntityGroup(entity, errorMsg) {
  if (!entity.group) {
    return;
  }

  if (!entity.group.id) {
    throw new Error(errorMsg + '- `group` parameter must have a valid id.');
  }
}

/**
 * Validates an entity's transition parameters.
 *
 * @param {{}} entity
 * @param {string} errorMsg
 */
function checkEntityTransitions(entity, errorMsg) {
  if (!entity.transitions) {
    return;
  } else if (!Array.isArray(entity.transitions)) {
    throw new Error(errorMsg + '- `transitions` parameter must be an array of objects.');
  }

  entity.transitions.forEach((transition, index) => {
    if (!transition.details && !transition.preset) {
      // An empty string is how the editor turns off a preset.
      if (transition.preset !== '') {
        throw new Error(`${errorMsg}- \`transition[${index}]\` must have a \`preset\` or \`details\` parameter.`);
      }
    }

    if (transition.details) {
      lodash.forEach(transition.details, (detailRange, detailName) => {
        if (!detailRange || !Array.isArray(detailRange)) {
          throw new Error(
            `${errorMsg}- \`transition[${index}].details[${detailName}]\` parameter must be an array of 2 numbers.`
          );
        }
        const startRange = parseInt(detailRange[0], 10);
        const endRange = parseInt(detailRange[1], 10);
        if (isNaN(startRange)) {
          throw new Error(
            `${errorMsg}- \`transition[${index}].details[${detailName}][0]\` must be a valid integer.`
          );
        } else if (isNaN(endRange)) {
          throw new Error(
            `${errorMsg}- \`transition[${index}].details[${detailName}][1]\` must be a valid integer.`
          );
        }
      });
    }
  });
}

/**
 * Checks a text entity.
 *
 * @param {{text: {plaintext: ?string, markdown: ?string}}} entity
 * @param {string} errorMsg
 */
function checkEntityText(entity, errorMsg) {
  if (!entity.text) {
    return;
  }
  if (!entity.text.plaintext && !entity.text.markdown) {
    throw new Error(errorMsg + '- `text` parameter must have a `plaintext` or `markdown` parameter.');
  }
}

/**
 * Checks a icon entity.
 *
 * @param {{icon: {type: string}}} entity
 * @param {string} errorMsg
 */
function checkEntityIcon(entity, errorMsg) {
  if (!entity.icon) {
    return;
  }
  if (!entity.icon.type) {
    throw new Error(errorMsg + '- `icon` parameter must have a `type` parameter.');
  }
}

/**
 * Checks a line entity.
 *
 * @param {{line: {startPoint: {}, endPoint: {}}}} entity
 * @param {string} errorMsg
 */
function checkEntityLine(entity, errorMsg) {
  if (!entity.line) {
    return;
  }
  if (!entity.line.startPoint) {
    throw new Error(errorMsg + '- `line` parameter must have a `startPoint` parameter.');
  }
  if (!entity.line.endPoint) {
    throw new Error(errorMsg + '- `line` parameter must have a `endPoint` parameter.');
  }

  const startPoint = entity.line.startPoint;
  if (startPoint.x === undefined || startPoint.y === undefined) {
    throw new Error(errorMsg + '- `line.startPoint` parameter must have an `x` and `y` parameter.');
  }

  const endPoint = entity.line.endPoint;
  if (endPoint.x === undefined || endPoint.y === undefined) {
    throw new Error(errorMsg + '- `line.endPoint` parameter must have an `x` and `y` parameter.');
  }
}

/**
 * Checks a video entity.
 *
 * @param {{video: {fileId: number}}} entity
 * @param {string} errorMsg
 */
function checkEntityVideo(entity, errorMsg) {
  if (!entity.video) {
    return;
  }
  if (!entity.video.fileId) {
    throw new Error(errorMsg + '- `video` parameter must have a `fileId` parameter.');
  }
}

/**
 * Checks a image entity.
 *
 * @param {{image: {fileId: number}}} entity
 * @param {string} errorMsg
 * @param {{}} source
 */
function checkEntityImage(entity, errorMsg, source) {
  if (!entity.image) {
    return;
  }
  if (!entity.image.fileId) {
    throw new Error(errorMsg + '- `image` parameter must have a `fileId` parameter.');
  }

  const image = entity.image;

  const position = lodash.get(entity, 'setup.position', null);
  if (!image.top) {
    if (!position) {
      throw new Error(errorMsg + '- `image` parameter must have a `top` parameter.');
    } else {
      image.top = position.top;
    }
  }
  if (!image.left) {
    if (!position) {
      throw new Error(errorMsg + '- `image` parameter must have a `left` parameter.');
    } else {
      image.left = position.left;
    }
  }

  const size = lodash.get(entity, 'setup.size', null);
  if (!image.width) {
    if (!size) {
      throw new Error(errorMsg + '- `image` parameter must have a `width` parameter.');
    } else {
      image.width = size.width;
    }
  }
  if (!image.height) {
    if (!size) {
      throw new Error(errorMsg + '- `image` parameter must have a `height` parameter.');
    } else {
      image.height = size.height;
    }
  }

  const gameWidth = parseInt(source.resolution.width, 10);
  const gameHeight = parseInt(source.resolution.height, 10);

  // Update the setup position and size to take up the whole view screen.
  if (!position) {
    entity.setup.position = {};
  }
  if (!entity.setup.size) {
    entity.setup.size = {};
  }

  entity.setup.position.top = 0;
  entity.setup.position.left = 0;
  entity.setup.size.width = gameWidth;
  entity.setup.size.height = gameHeight;
}
