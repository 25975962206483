var content = require("!!../../../../node_modules/mini-css-extract-plugin/dist/loader.js!../../../../node_modules/css-loader/dist/cjs.js??ref--5-2!../../../../node_modules/postcss-loader/src/index.js??ref--5-3!../../../../node_modules/sass-loader/dist/cjs.js??ref--5-4!./editTimeControl.scss");
content = content.__esModule ? content.default : content;

if (typeof content === 'string') {
  content = [[module.id, content, '']];
}

var options = {}

options.insert = "head";
options.singleton = false;

var update = require("!../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js")(content, options);

if (content.locals) {
  module.exports = content.locals;
}
