import {reaction} from 'mobx';

import activeContentStore from '../../stores/active/activeContentStore';
import {BACKGROUND_VIDEO} from '../../constants/libraryTypeConstants';
import {contentLibraryChange} from '../content/contentCommon';

/**
 * The disposer function for the mobX reaction().
 *
 * @type {?function}
 */
let reactionDispose = null;

/**
 * Initializes reaction for content library id changes.
 */
export function init() {
  reactionDispose = reaction(
    () => activeContentStore.libraries[BACKGROUND_VIDEO].id,
    onContentLibraryChange
  );
}

/**
 * Performs logic when content library id changes.
 *
 * @param {?number} contentLibraryId
 */
function onContentLibraryChange(contentLibraryId) {
  contentLibraryChange(contentLibraryId, BACKGROUND_VIDEO);
}

/**
 * Disposes of the thunk.
 * This is useful for hot-reloading purposes.
 */
export function dispose() {
  if (reactionDispose) {
    reactionDispose();
  }
}

export default init;
