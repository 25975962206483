import classNames from 'classnames';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

import {actionAlignComponent} from '../../../../../display/components/action/actionAlignComponent';

import './alignEntitiesButtons.scss';

import {
  ALIGN_LEFT, ALIGN_CENTER, ALIGN_RIGHT,
  ALIGN_TOP, ALIGN_MIDDLE, ALIGN_BOTTOM,
  CENTER_HORIZONTALLY, CENTER_VERTICALLY,
  DISTRIBUTE_HORIZONTALLY, DISTRIBUTE_VERTICALLY
} from '../../../../../constants/entityOptionConstants';
import {getIsPositionLocked} from '../../../../../utils/dragDropHelper';

/**
 * The list of alignment buttons.
 *
 * @type {Array.<{label: string, style: string}>}
 */
const ALIGN_BUTTON_TYPES = [
  {id: 10, label: 'Align Left', style: ALIGN_LEFT, icon: 'fad-align-left'},
  {id: 20, label: 'Align Center', style: ALIGN_CENTER, icon: 'fad-align-center'},
  {id: 30, label: 'Align Right', style: ALIGN_RIGHT, icon: 'fad-align-right'},
  {id: 40, label: 'Distribute Horizontally', style: DISTRIBUTE_HORIZONTALLY, icon: 'fad-align-justify'},
  {id: 50, divider: true},

  {id: 60, label: 'Align Top', style: ALIGN_TOP, icon: 'fad-align-left fa-rotate-90'},
  {id: 70, label: 'Align Middle', style: ALIGN_MIDDLE, icon: 'fad-align-center fa-rotate-90'},
  {id: 80, label: 'Align Bottom', style: ALIGN_BOTTOM, icon: 'fad-align-right fa-rotate-90'},
  {id: 90, label: 'Distribute Vertically', style: DISTRIBUTE_VERTICALLY, icon: 'fad-align-justify fa-rotate-90'},
  {id: 100, divider: true},

  {id: 110, label: 'Center Horizontally', style: CENTER_HORIZONTALLY, icon: 'fad-align-center'},
  {id: 120, label: 'Center Vertically', style: CENTER_VERTICALLY, icon: 'fad-align-center fa-rotate-90'},
];

/**
 * The AlignEntitiesButtons component.
 */
export class AlignEntitiesButtons extends React.Component {
  /**
   * Whether or not the dropdown is open.
   *
   * @type {boolean}
   */
  @observable isDropdownOpen = false;

  /**
   * The last align type selected.
   *
   * @type {{}}
   */
  @observable lastItemUsed = ALIGN_BUTTON_TYPES[1];

  /**
   * Opens the align drop down.
   */
  @action toggleDropdown = () => {
    this.isDropdownOpen = !this.isDropdownOpen;
  };

  /**
   * Aligns the entities.
   *
   * @param {{}} clicked
   * @returns {function()}
   */
  onClick = (clicked) => {
    return action(() => {
      this.lastItemUsed = clicked;

      const {game} = this.props;

      const entityIds = game.allActiveEntities.filter((activeEntity) => {
        return !getIsPositionLocked(activeEntity);
      }).map((activeEntity) => {
        return activeEntity.get('id');
      });
      const actionParams = {
        entityId: entityIds,
      };

      game.addAction(actionParams, actionAlignComponent(clicked.style));
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {id} = this.props;

    const buttonIcon = {
      [this.lastItemUsed.icon]: true,
    };

    return (
      <div id={id} className="align-entities-buttons">
        <button
          type="button"
          className={classNames('btn btn-sm btn-dark form-button align-last-button')}
          onClick={this.onClick(this.lastItemUsed)}
        >
          <i className={classNames('fad', buttonIcon)} />
        </button>

        <Dropdown isOpen={this.isDropdownOpen} toggle={this.toggleDropdown}>
          <DropdownToggle
            className={classNames('btn btn-sm btn-dark form-button align-dropdown')}
          >
            <i className="fad fad-caret-down" />
          </DropdownToggle>
          <DropdownMenu right>
            {ALIGN_BUTTON_TYPES.map((align) => {
              if (align.divider) {
                return (
                  <DropdownItem key={align.id} divider />
                );
              }

              return (
                <DropdownItem
                  key={align.id}
                  onClick={this.onClick(align)}
                  toggle={false}
                >{align.label}</DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

AlignEntitiesButtons.propTypes = {
  game: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default observer(AlignEntitiesButtons);
