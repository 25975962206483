import {observable, action, extendObservable} from 'mobx';

import serverApi from '../../../utils/serverApi';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {apiStore, getCase} from '../../../utils/apiStore';

/**
 * Manages Edit/Copy Content flow
 */
class ContentMoverStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * The content id of the moved/copied content.
   * @type {?number}
   */
  @observable contentId = null;

  /**
   * Error
   * @type {?Error}
   */
  @observable error = null;

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {?number}
   */
  getFulfilled() {
    return this.contentId;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    return this.error;
  }

  /**
   * Sets the contentId of the store.
   *
   * @param {?number} contentId
   */
  @action setContentId(contentId) {
    this.contentId = contentId;
  }

  /**
   * Move selected content to the specified Category
   *
   * @param {number} contentId
   * @param {number} destinationCategoryId
   * @param {?number} contentFileId
   * @param {{id: number, defaultDirectory: string}} library
   */
  @action move(contentId, destinationCategoryId, contentFileId, library) {
    this.contentId = null;
    this.state = STATE_PENDING;

    const libraryDirectory = library.defaultDirectory;

    serverApi.contentsPatch({id: contentId, categoryId: destinationCategoryId}).then(
      () => {
        if (!contentFileId) {
          return true;
        }

        return serverApi.contentFilesPatch({
          id: contentFileId,
          directory: libraryDirectory,
        });
      },
    ).then(
      action('moveContentSuccess', () => {
        this.contentId = contentId;
        this.error = null;
        this.state = STATE_FULFILLED;
      }),
      action('moveContentError', (error) => {
        this.error = error;
        this.state = STATE_REJECTED;
      }),
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => {
      return this.getFulfilled();
    };
    const getRejected = () => {
      return this.getRejected();
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }
}

export default new ContentMoverStore();
