import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody} from 'reactstrap';

import FileUploadingProgress from '../../common/fileUploadingProgress/FileUploadingProgress';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import inject from '../../hoc/injectHoc';
import {STATE_PENDING} from '../../../constants/asyncConstants';
import {blockTransition} from '../../../utils/history';

import './fileUploadingModal.scss';

/**
 * The timeout for the modal auto-close.
 *
 * @const {number}
 */
const MODAL_CLOSE_TIMEOUT = 5000;

/**
 * The FileUploadingModal component.
 */
class FileUploadingModal extends React.Component {
  /**
   * The ID of the auto-closing timeout.
   *
   * @type {?number}
   */
  modalCloseTimeoutId = null;

  /**
   * The function used to remove the block on router transitions.
   *
   * @type {?function}
   */
  unblockRouterTransitions = null;

  /**
   * Triggered when the component is added to the page.
   */
  componentDidMount() {
    const {apiCreateContentStore} = this.props;
    window.addEventListener('beforeunload', this.onPageUnload);

    this.unblockRouterTransitions = blockTransition(() => {
      if (!this.isUploadingInProgress()) {
        return undefined;
      }
      return 'You have uploads in progress that will be canceled. Are you sure you want to navigate away?';
    });

    apiCreateContentStore.getUploadPromise().then(() => {
      this.modalCloseTimeoutId = setTimeout(this.onCancelModal, MODAL_CLOSE_TIMEOUT);
    });
  }

  /**
   * Triggered when the component is about to be removed from the page.
   */
  componentWillUnmount() {
    // We want to cancel all the uploading files.
    const {apiCreateContentStore} = this.props;

    window.removeEventListener('beforeunload', this.onPageUnload);

    if (this.unblockRouterTransitions) {
      this.unblockRouterTransitions();
    }

    if (this.modalCloseTimeoutId) {
      clearTimeout(this.modalCloseTimeoutId);
    }

    apiCreateContentStore.cancelAll();
    apiCreateContentStore.clearAll();
  }

  /**
   * Whether or not there are changes to the game/video/image.
   *
   * @returns {boolean}
   */
  isUploadingInProgress = () => {
    const {apiCreateContentStore} = this.props;
    return (apiCreateContentStore.uploadingState === STATE_PENDING);
  };

  /**
   * Triggered when the page attempts to unload if uploads are in progress.
   * This will open a confirm dialog using the returned string.
   *
   * @param {{}} unloadEvent
   */
  onPageUnload = (unloadEvent) => {
    if (this.isUploadingInProgress()) {
      unloadEvent.returnValue = 'You have uploads in progress that will be canceled.'
        + ' Are you sure you want to navigate away?';
    }
  };

  /**
   * Triggered when the modal is cancelled.
   */
  onCancelModal = () => {
    const {apiCreateContentStore, onComplete} = this.props;

    apiCreateContentStore.clearAll();

    onComplete();
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiCreateContentStore, isOpen} = this.props;

    const canClose = (apiCreateContentStore.uploadingState !== STATE_PENDING);

    return (
      <Modal className="file-uploading-modal" isOpen={isOpen}>
        <ModalBody>
          <div className="modal-body-content">
            <h3>Uploading...</h3>

            <div className="file-progress-wrapper">
              <FileUploadingProgress onComplete={this.onCancelModal} />
            </div>

            <div className="close-modal">
              {(canClose) ? (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.onCancelModal}
                >Close</button>
              ) : (
                <LoadingIcon size="sm" />
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

FileUploadingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,

  apiCreateContentStore: MobxPropTypes.observableObject,
};

FileUploadingModal.wrappedComponent = {
  propTypes: {
    apiCreateContentStore: MobxPropTypes.observableObject.isRequired,
  }
};

export default inject(FileUploadingModal)(
  observer(FileUploadingModal)
);
