import classNames from 'classnames';
import {toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import DisplayCircle from '../circle/DisplayCircle';
import DisplayFeedImage from '../feedImage/DisplayFeedImage';
import DisplayFeedText from '../feedText/DisplayFeedText';
import DisplayIcon from '../icon/DisplayIcon';
import DisplayImage from '../image/DisplayImage';
import DisplayLine from '../line/DisplayLine';
import DisplayPlaceholder from '../placeholder/DisplayPlaceholder';
import DisplayPlaylist from '../playlist/DisplayPlaylist';
import DisplayRectangle from '../rectangle/DisplayRectangle';
import DisplayText from '../text/DisplayText';
import DisplayTimer from '../timer/DisplayTimer';
import DisplayVideo from '../video/DisplayVideo';
import DisplayViewport from '../viewport/DisplayViewport';
import DisplayWidget from '../widget/DisplayWidget';
import {isLocked} from '../../../display/components/common/lockedComponent';

import './displayEntity.scss';

const entityMap = {
  icon: DisplayIcon,
  image: DisplayImage,
  circle: DisplayCircle,
  feedImage: DisplayFeedImage,
  feedText: DisplayFeedText,
  line: DisplayLine,
  placeholder: DisplayPlaceholder,
  playlist: DisplayPlaylist,
  rectangle: DisplayRectangle,
  text: DisplayText,
  timer: DisplayTimer,
  video: DisplayVideo,
  viewport: DisplayViewport,
  widget: DisplayWidget,
};

/**
 * Active entities need have the highest zIndex in the display.
 * So this needs to be a very high number and higher than any other zIndex.
 *
 * @const {number}
 */
const ACTIVE_ENTITY_ZINDEX = 1000000;

/**
 * The DisplayEntity component.
 */
class DisplayEntity extends React.Component {
  /**
   * Gets the styles from the entity.
   *
   * @param {{}} entity
   * @returns {{}}
   */
  getStylesFromEntity = (entity) => {
    const safeEntity = toJS(entity);

    const isSizeLocked = Boolean(
      safeEntity.locked
      && isLocked(safeEntity, 'size')
      && isLocked(safeEntity, 'position')
    );

    const styles = {};
    if (safeEntity.size) {
      styles.width = safeEntity.size.width;
      styles.height = safeEntity.size.height;
    }
    if (safeEntity.position) {
      styles.position = 'absolute';
      styles.top = safeEntity.position.top;
      styles.left = safeEntity.position.left;
      styles.zIndex = safeEntity.position.zIndex;
      if (safeEntity.position.rotate) {
        styles.transform = `rotate(${safeEntity.position.rotate}deg)`;
        styles.WebkitTransform = `rotate(${safeEntity.position.rotate}deg)`;
      }
    }
    if (safeEntity.visible) {
      if (!safeEntity.visible.isVisible) {
        styles.visibility = 'hidden';
      }
      if (safeEntity.visible.opacity !== undefined) {
        styles.opacity = safeEntity.visible.opacity;
      }
    }
    if (safeEntity.interaction && safeEntity.interaction.isActive) {
      if (!isSizeLocked) {
        styles.zIndex = ACTIVE_ENTITY_ZINDEX;
      }
    }

    return styles;
  };

  /**
   * Gets whether or not the current entity is active.
   *
   * @param {observableMap} entity
   * @returns {boolean}
   */
  getIsEntityActive = (entity) => {
    if (!entity.has('interaction')) {
      return false;
    }

    const interaction = entity.get('interaction');
    return Boolean(interaction.isActive);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {game, entity} = this.props;

    const elementType = entity.get('element') || null;
    if (!entity.has('visible')) {
      return null;
    } else if (!entityMap[elementType]) {
      throw new Error(`Invalid entity found, type ${elementType} has no map.`);
    }

    const DisplayEl = entityMap[elementType];

    return (
      <DisplayEl
        key={entity.get('id')}
        className={classNames({'is-active-entity': this.getIsEntityActive(entity)})}
        entity={entity}
        style={this.getStylesFromEntity(entity)}
        game={game}
      />
    );
  }
}

DisplayEntity.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(DisplayEntity);
