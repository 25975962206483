/**
 * The key used to store the current user in local storage or a cookie.
 *
 * @const {string}
 */
export const CURRENT_USER_STORAGE_KEY = 'CU';

/**
 * The key used to store the product in local storage or a cookie.
 *
 * @const {string}
 */
export const PRODUCT_STORAGE_KEY = 'P';

/**
 * The key used to store the in progress current history point in local storage or a cookie.
 * @const {string}
 */
export const IN_PROGRESS_STORAGE_KEY = 'IPH';
