import {action, extendObservable, observable, when} from 'mobx';

import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {EXPIRE_TIME} from '../../../constants/storeConstants';
import {apiStore, getCase} from '../../../utils/apiStore';

/**
 * The base single resource store. Should be extended.
 */
class SingleResourceBaseStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * The map of each page of resources.
   *
   * @type {ObservableMap<string, {resources: ?ObservableArray, error: ?Error}>}
   */
  @observable resourcesById = observable.map();

  /**
   * Clears all the resource info
   */
  @action clearAll() {
    this.resourcesById.clear();
    this.state = STATE_PRE;
  }

  /**
   * Gets an resource by id.
   *
   * @param {number} rawResourceId
   * @returns {({id: number}|null)}
   */
  getResourceById(rawResourceId) {
    const resourceId = String(rawResourceId);
    const foundResource = this.resourcesById.get(resourceId);
    if (!foundResource || !foundResource.resource) {
      return null;
    }
    return foundResource.resource;
  }

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @param {number} resourceId
   * @returns {?Array.<{}>}
   */
  getFulfilled(resourceId) {
    return this.getResourceById(resourceId);
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @param {number} rawResourceId
   * @returns {?Error}
   */
  getRejected(rawResourceId) {
    const resourceId = String(rawResourceId);
    const foundResource = this.resourcesById.get(resourceId);
    if (!foundResource || !foundResource.error) {
      return null;
    }
    return foundResource.error;
  }

  /**
   * Gets the state of an item in the store.
   *
   * @param {number} resourceId
   * @returns {string}
   */
  getState(resourceId) {
    if (!resourceId) {
      return STATE_PRE;
    }

    const safeId = String(resourceId);

    const dataMapItem = this.resourcesById.get(safeId);
    if (dataMapItem && dataMapItem.state) {
      return dataMapItem.state;
    }

    return this.state || STATE_PRE;
  }

  /**
   * Check if resource is already available via unexpired cache
   *
   * @param {number} rawResourceId
   * @returns {boolean}
   */
  @action isResourceAvailable(rawResourceId) {
    const resourceId = String(rawResourceId);
    const currentResource = this.resourcesById.get(resourceId);
    if (currentResource && currentResource[EXPIRE_TIME] <= Date.now()) {
      this.resourcesById.delete(resourceId);
    } else if (currentResource && !currentResource.error) {
      this.state = STATE_FULFILLED;
      return true;
    }
    this.state = STATE_PENDING;

    return false;
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {number} resourceId
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(resourceId, handlers) {
    const getFulfilled = () => {
      return this.getFulfilled(resourceId);
    };
    const getRejected = () => {
      return this.getRejected(resourceId);
    };

    const state = this.getState(resourceId);

    return getCase(state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for this store.
   *
   * @param {string|number} id
   * @returns {Promise}
   */
  getPromise(id) {
    const safeId = String(id);

    return new Promise((resolve, reject) => {
      when(
        () => {
          const state = this.getState(safeId);
          return (state === STATE_FULFILLED || state === STATE_REJECTED);
        },
        () => {
          const state = this.getState(safeId);
          if (state === STATE_REJECTED) {
            reject(this.getRejected(safeId));
            return;
          }

          resolve(this.getFulfilled(safeId));
        },
        {name: `${this.constructor.name}GetPromise`}
      );
    });
  }
}

export default SingleResourceBaseStore;
