import {lockedComponent} from '../../../../../../display/components/common/lockedComponent';
import {positionComponent} from '../../../../../../display/components/common/positionComponent';
import {sizeComponent} from '../../../../../../display/components/common/sizeComponent';
import {decimalToFraction} from '../../../../../../utils/mathHelper';

/**
 * Gets the source data for a placeholder widget.
 *
 * @param {?number} contentId
 * @returns {function}
 */
export function getPlaceHolderWidgetData(contentId) {
  return ({game, resolution}) => {
    // The widget should be the 90 degree rotation of the aspect ratio, so the width and height below are correct.
    const aspectRatioParts = decimalToFraction(resolution.height / resolution.width);
    const startingHeight = resolution.width / 2;
    const startingWidth = resolution.height / 2;

    return {
      startTime: 0,
      endTime: game.endTime || 0,
      activeTime: 0,
      setup: {
        ...sizeComponent({width: startingWidth, height: startingHeight, minSizeMultiplier: 0.25}),
        ...positionComponent({
          topPosition: 0,
          leftPosition: resolution.width - startingWidth,
        }),
        ...lockedComponent(['aspectRatio', 'rotation']),
      },
      transitions: null,
      widget: {
        contentId: contentId || null,
        aspectRatio: aspectRatioParts.ratio,
      },
    };
  };
}
