import {reaction} from 'mobx';

import activeContentStore from '../../stores/active/activeContentStore';
import {PLACEABLE_IMAGE} from '../../constants/libraryTypeConstants';
import {categoryChange} from '../content/contentCommon';

/**
 * The disposer function for the mobX reaction().
 *
 * @type {?function}
 */
let reactionDispose = null;

/**
 * Initializes reaction for content category id changes.
 */
export function init() {
  reactionDispose = reaction(
    () => activeContentStore.libraries[PLACEABLE_IMAGE].category.id,
    onCategoryChange
  );
}

/**
 * Performs logic when category id changes.
 *
 * @param {?number} categoryId
 */
function onCategoryChange(categoryId) {
  const libraryId = activeContentStore.libraries[PLACEABLE_IMAGE].id;
  categoryChange(PLACEABLE_IMAGE, categoryId, libraryId);
}

/**
 * Disposes of the thunk.
 * This is useful for hot-reloading purposes.
 */
export function dispose() {
  if (reactionDispose) {
    reactionDispose();
  }
}

export default init;
