/**
 * Gets the source data for a placeholder placeholder entity.
 *
 * @returns {{}}
 */
export function getPlaceHolderPlaceholderData() {
  return {
    placeholder: {},
  };
}
