import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

import ContentDelete from '../../../contentDelete/ContentDelete';
import ContentMove from '../../../contentMove/ContentMove';

import './clipActions.scss';

/**
 * The ClipActions component.
 */
export class ClipActions extends React.Component {
  /**
   * Whether or not the current dropdown is open.
   *
   * @type {boolean}
   */
  @observable isOpen = false;

  /**
   * A reference to the delete content component.
   * This will be used to call onDeleteStart() from that component.
   *
   * @type {{current: ?{}}}
   */
  deleteContentRef = React.createRef();

  /**
   * A reference to the move content component.
   * This will be used to call onMoveStart() from that component.
   *
   * @type {{current: ?{}}}
   */
  moveContentRef = React.createRef();

  /**
   * Triggered when the dropdown is closed.
   */
  @action onDropDownToggle = () => {
    const {isOpen} = this;
    const {onOpen, onClose} = this.props;

    if (isOpen) {
      this.isOpen = false;
      if (onClose) {
        onClose();
      }
    } else {
      this.isOpen = true;
      if (onOpen) {
        onOpen();
      }
    }
  };

  /**
   * Triggered when the delete button is clicked.
   */
  onDelete = () => {
    const {content} = this.props;

    this.deleteContentRef.current.onDeleteStart(content);
  };

  /**
   * Handles when content is selected to be moved.
   */
  onMove = () => {
    const {content} = this.props;

    this.moveContentRef.current.onMoveStart(content);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {libraryType, hideMove} = this.props;

    return (
      <div className="clip-actions">
        <Dropdown isOpen={this.isOpen} toggle={this.onDropDownToggle}>
          <DropdownToggle
            className="btn btn-icon fa fa-ellipsis-v clip-actions-open"
            tag="button"
          />
          <span className="dropdown-filler" />

          <DropdownMenu right>
            {(!hideMove) && (
              <DropdownItem onClick={this.onMove}>
                <i className="fa fa-sign-out" />
                Move
              </DropdownItem>
            )}

            <DropdownItem onClick={this.onDelete}>
              <i className="fa fa-trash" />
              Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <ContentDelete libraryType={libraryType} ref={this.deleteContentRef} />

        {(!hideMove) && (
          <ContentMove libraryType={libraryType} ref={this.moveContentRef} />
        )}
      </div>
    );
  }
}

ClipActions.propTypes = {
  content: PropTypes.shape({
    contentFiles: PropTypes.oneOfType([MobxPropTypes.observableArray, PropTypes.array]),
    id: PropTypes.number
  }).isRequired,
  libraryType: PropTypes.number.isRequired,

  hideMove: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
};

ClipActions.defaultProps = {
  hideMove: false,
};

export default observer(ClipActions);
