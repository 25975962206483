import {getFeedPathValue} from './feedPathHelper';

const lodash = require('lodash');
const moment = require('moment');
const parseFormat = require('moment-parseformat');

/**
 * Matches the tags.
 * @const {RegExp}
 */
const TAG_REGEXP = /\[\[(.*?)\]\]/ig;

/**
 * The text returned when a replacement fails.
 * @const {string}
 */
const FAILED_TEXT = '';

/**
 * The text to display when a replacement is unavailable
 * @type {string}
 */
const NOT_AVAILABLE_TEXT = 'NA';

/**
 * The tag meta delimiter.
 * @const {string}
 */
const META_DELIMITER = ':';

/**
 * The filter delimiter.
 * @const {string}
 */
const FORMAT_DELIMITER = '|';

/**
 * The filter meta delimiter.
 * @const {string}
 */
const FORMAT_META_DELIMITER = ':';

/**
 * Replaces `[[*]]` markers with new text in the given source string.
 *
 * @param {string} rawSource
 * @param {{feedData: {}, slideNumber: number}=} metaData
 * @returns {string}
 */
export function replaceText(rawSource, metaData) {
  const safeSource = String(rawSource);

  // Parse feed tags first because they can contain tags that need to be parsed.
  const afterFeeds = replaceFeedTags(safeSource, metaData);

  return replaceTags(afterFeeds);
}

/**
 * Replaces `[[feed*]]` markers with new feed text in the given source string.
 *
 * @param {string} source
 * @param {{feedData: {}, slideNumber: number}=} metaData
 * @returns {string}
 */
export function replaceFeedTags(source, metaData) {
  const safeSource = String(source);

  const FEED_TAG = 'feed';

  const variableValues = {};
  const safeMetaData = metaData || {};

  return safeSource.replace(TAG_REGEXP, (fullMatch, matchedContents) => {
    const [tag, ...formatters] = matchedContents.split(FORMAT_DELIMITER);

    if (!lodash.startsWith(tag, FEED_TAG)) {
      return fullMatch;
    } else if (!lodash.startsWith(tag, `${FEED_TAG}${META_DELIMITER}`)) {
      return String(safeMetaData.feedData);
    } else if (!safeMetaData.feedData) {
      return FAILED_TEXT;
    }

    const [unused, ...feedPathParts] = tag.split(META_DELIMITER);
    if (!feedPathParts[0]) {
      return FAILED_TEXT;
    }

    const newValue = getFeedPathValue(
      feedPathParts.join(META_DELIMITER),
      safeMetaData.feedData,
      safeMetaData,
      variableValues,
    );

    if (!newValue) {
      return NOT_AVAILABLE_TEXT;
    }

    if (!formatters || !formatters.length) {
      return newValue;
    }

    return applyFormats(newValue, formatters);
  });
}

/**
 * Replaces `[[*]]` markers with new text in the given source string.
 *
 * @param {string} source
 * @returns {string}
 */
function replaceTags(source) {
  const safeSource = String(source);

  return safeSource.replace(TAG_REGEXP, (fullMatch, matchedContents) => {
    const [tagName, ...formatters] = matchedContents.split(FORMAT_DELIMITER);

    if (!tagName) {
      return FAILED_TEXT;
    }

    const newValue = replaceTag(tagName);

    if (!newValue) {
      return NOT_AVAILABLE_TEXT;
    }

    if (!formatters || !formatters.length) {
      return newValue;
    }

    return applyFormats(newValue, formatters);
  });
}

/**
 * Replaces a tag with its value.
 *
 * @param {string} tagName
 * @returns {string}
 */
function replaceTag(tagName) {
  const tagMeta = String(tagName || '').split(META_DELIMITER);
  const safeTag = tagMeta.shift();

  if (!safeTag) {
    return FAILED_TEXT;
  }

  if (safeTag === 'date') {
    return moment().format('M/D/YYYY');
  } else if (safeTag === 'time') {
    return moment().format('h:mm A');
  } else if (safeTag === 'dateformat') {
    return replaceTagDateFormat(tagMeta);
  } else if (safeTag === 'daysto') {
    return replaceTagDaysTo(tagMeta);
  } else if (safeTag === 'latlon') {
    return replaceTagLatLon(tagMeta);
  } else if (safeTag === 'location') {
    return replaceTagLocation(tagMeta);
  } else if (safeTag === 'text') {
    return tagMeta.join(META_DELIMITER);
  }

  return tagName;
}

/**
 * Applies the given formatters to a source string.
 *
 * @param {string} source
 * @param {Array} formatters
 * @returns {string}
 */
function applyFormats(source, formatters) {
  const safeSource = String(source);

  if (!formatters || !formatters.length || !formatters[0]) {
    return safeSource;
  }

  // Only allow one format for now.
  return applyFormat(source, formatters[0] || '');
}

/**
 * Applies a single format.
 *
 * @param {string} source
 * @param {string} formatter
 * @returns {string}
 */
function applyFormat(source, formatter) {
  const filterMeta = String(formatter || '').split(FORMAT_META_DELIMITER);
  const safeFilter = filterMeta.shift();

  if (!safeFilter) {
    return source;
  }

  if (safeFilter === 'unixdate' || safeFilter === 'date' || safeFilter === 'time') {
    return formatDate(source, filterMeta, safeFilter);
  } else if (safeFilter === 'round') {
    return formatRound(source, filterMeta);
  } else if (safeFilter === 'money') {
    return formatMoney(source);
  }

  return source;
}

/**
 * Maps the C# date format to a MomentJS compatible format.
 *
 * @param {string} format
 * @returns {string}
 */
function mapDateFormatFromCSharp(format) {
  const safeFormat = String(format);

  const replacements = [
    ['yy', 'YY'], // Fix short year.
    ['yyyy', 'YYYY'], // Fix long year.
    ['tt', 'A'], // Fix AM/PM.
    ['d', 'D'], // Fix short month.
    ['dd', 'DD'], // Fix long month.
  ];

  return replacements.reduce((finalFormat, [fromCode, toCode]) => {
    return finalFormat.replace(new RegExp(`\b${fromCode}\b`, 'g'), toCode);
  }, safeFormat);
}

/**
 * Replaces the date format tag.
 *
 * @param {string[]} tagMeta
 * @returns {string}
 */
function replaceTagDateFormat(tagMeta) {
  if (!tagMeta.length || !tagMeta[0]) {
    return moment().format('M/D/YYYY');
  }

  const metaFormat = tagMeta.join(META_DELIMITER);
  const safeFormat = mapDateFormatFromCSharp(metaFormat);
  return moment().format(safeFormat);
}

/**
 * Replaces the days until tag.
 *
 * @param {string[]} tagMeta
 * @returns {string}
 */
function replaceTagDaysTo(tagMeta) {
  if (!tagMeta.length || !tagMeta[0]) {
    return '0';
  }

  const metaDate = tagMeta.join(META_DELIMITER);
  const detectedFormat = parseFormat(metaDate);

  const now = moment();
  const checkDate = (detectedFormat) ? moment(metaDate, detectedFormat) : moment(metaDate);

  if (!checkDate.isValid()) {
    return '0';
  } else if (checkDate.isBefore(now)) {
    return '0';
  }

  // Optional code that will push the date forward a year at a time until it is after now.
  // let limit = 100;
  // while (limit > 0 && checkDate.isBefore(now)) {
  //   checkDate.add('1', 'year');
  //   limit -= 1;
  // }

  const diffInMS = checkDate.diff(now);
  const days = Math.floor(diffInMS / 1000 / 60 / 60 / 24); // eslint-disable-line no-magic-numbers

  return (days <= 0) ? '0' : String(days);
}

/**
 * Replaces the latlon format tag.
 *
 * @param {string[]} tagMeta
 * @returns {string}
 */
function replaceTagLatLon(tagMeta) {
  if (!tagMeta.length) {
    return '[[latlon]]';
  }
  switch (tagMeta[0]) {
    case 'Latitude':
      return '35.6528';
    case 'Longitude':
      return '-97.4781';
    case 'latitude':
      return '"latitude" must be uppercased as "Latitude"';
    case 'longitude':
      return '"longitude" must be uppercased as "Longitude"';
    default:
      return NOT_AVAILABLE_TEXT;
  }
}

/**
 * Replaces the location format tag.
 *
 * @param {string[]} tagMeta
 * @returns {string}
 */
function replaceTagLocation(tagMeta) {
  if (!tagMeta.length) {
    return '[[location]]';
  }
  switch (tagMeta[0]) {
    case 'City':
      return 'Edmond';
    case 'State':
      return 'OK';
    case 'city':
      return '"city" must be uppercased as "City"';
    case 'state':
      return '"state" must be uppercased as "State"';
    default:
      return NOT_AVAILABLE_TEXT;
  }
}

/**
 * Formats a date string.
 *
 * @param {string} source
 * @param {string[]} filterMeta
 * @param {string} sourceType
 * @returns {string}
 */
function formatDate(source, filterMeta, sourceType) {
  const defaultFormat = (sourceType === 'time') ? 'h:mm:ss A' : 'M/D/YYYY h:mm:ss A';

  const detectedFormat = parseFormat(source);

  let sourceDate = null;
  if (sourceType === 'unixdate') {
    sourceDate = moment.unix(parseInt(source, 10));
  } else if (detectedFormat) {
    sourceDate = moment(source, detectedFormat);
  } else {
    sourceDate = moment(source);
  }

  if (!sourceDate.isValid()) {
    return source;
  } else if (!filterMeta[0]) {
    return sourceDate.format(defaultFormat);
  }

  const metaFormat = filterMeta.join(FORMAT_META_DELIMITER);
  const safeFormat = mapDateFormatFromCSharp(metaFormat);
  return sourceDate.format(safeFormat);
}

/**
 * Formats a number, rounding to the given digit or to an integer.
 *
 * @param {string} source
 * @param {string[]} filterMeta
 * @returns {string}
 */
function formatRound(source, filterMeta) {
  const safeSource = Number.parseFloat(source);
  if (isNaN(safeSource)) {
    return source;
  } else if (!filterMeta[0]) {
    return String(Math.round(safeSource));
  }

  const leftSide = String(Math.abs(safeSource)).split('.')[0];
  const leftSideDigits = (leftSide === '0') ? 0 : leftSide.length;

  const precision = Number.parseInt(filterMeta[0], 10) || 0;
  return safeSource.toPrecision(leftSideDigits + precision);
}

/**
 * Formats a number as money.
 *
 * @param {string} source
 * @returns {string}
 */
function formatMoney(source) {
  return Number.parseFloat(
    String(source).replace(/[$,]+/g, '')
  ).toFixed(2);
}

export default replaceText;
