import classNames from 'classnames';
import React from 'react';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';

import ImageGroup from './components/ImageGroup';
import {getMainUrlByFileId, getStaticFileUrl} from '../../../utils/contentsHelper';

import './displayFeedImage.scss';

/**
 * Display component for Feed Image
 */
export class DisplayFeedImage extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game, style, className} = this.props;

    const feedImage = entity.get('feedImage');
    const {source} = feedImage;

    const feedContent = game.feed.getFeedData(feedImage, {
      replace: false,
      default: '',
    })[0];

    let imageUrl = null;
    if (feedContent && source === 'ContentFileId') {
      imageUrl = getMainUrlByFileId(feedContent, false);
    } else if (feedContent) {
      imageUrl = getStaticFileUrl(feedContent, true);
    }

    // Disable rotation on the svg element.
    const svgStyle = {
      ...style,
      transform: '',
      WebkitTransform: '',
    };

    return (
      <svg
        id={entity.id}
        className={classNames('display-feed-image', className)}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        fill="none"
        stroke="none"
        preserveAspectRatio="none"
        pointerEvents="none"
        viewBox={`0 0 ${svgStyle.width} ${svgStyle.height}`}
        style={svgStyle}
      >
        {(imageUrl) && (
          <ImageGroup entity={entity} style={style} sourceUrl={imageUrl} image={feedImage} game={game} />
        )}
      </svg>
    );
  }
}

DisplayFeedImage.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
  style: PropTypes.object.isRequired,

  className: PropTypes.string,
};

export default observer(DisplayFeedImage);
