import eases from 'eases';
import lodash from 'lodash';
import {runInAction} from 'mobx';

import {parsePositionToLineUpdates} from '../components/type/lineComponent';
import {updateEntity} from '../ecs/entityHelper';
import {
  EASING_LINEAR,
  PARSE_OFF,
  PARSE_POSITION,
  parseOff,
  parsePosition,
  transitionPresets,
} from '../ecs/transitionPresets';
import {TRANSITION_TIME_MS} from '../../constants/displayConstants';

/**
 * The name of the system.
 * @const {string}
 */
export const TRANSITION_SYSTEM = 'transitionSystem';

/**
 * Allows the transitions to process for this amount of time after the end time.
 * This allows transitions to reach 100% even if the time increment doesn't hit exactly at 100%.
 * Allowing a max fps of 60, the maximum overshoot would be [(1000 ms / 60 frame) = 16.6 ms/frame =>] 17 ms.
 * @const {number}
 */
const OVERSHOOT_BUFFER = 17;

/**
 * Gets a new instance of the transition system.
 *
 * @param {GameStore} game
 * @returns {{name: string, update: systemUpdate}}
 */
export function transitionSystem(game) {
  /**
   * Gets the start and end time for the given transition.
   *
   * @param {{time: {start: number, end: number}, preset: string}} transition
   * @param {{start: number, end: number}} entityTime
   * @returns {{start: number, end: number}}
   */
  function getTransitionTimes(transition, entityTime) {
    const time = transition.time || {};

    // We need to offset the start and end by 1 to make sure the entity doesn't pop up at full opacity for 1 ms
    // (though this effect only seems to happen in phantomJS).
    let transitionStart = ((time.start) ? time.start : entityTime.start) - 1;
    let transitionEnd = ((time.end) ? time.end : entityTime.end) + 1;

    const preset = transition.preset;
    if (preset && preset.match(/In$/)) {
      transitionEnd = transitionStart + TRANSITION_TIME_MS;
    } else if (preset && preset.match(/Out$/)) {
      transitionStart = transitionEnd - TRANSITION_TIME_MS;
    }

    return {
      start: transitionStart,
      end: transitionEnd,
    };
  }

  /**
   * Called when the game loop updates.
   *
   * @param {Array.<{}>} entities
   * @param {number} time
   */
  function systemUpdate(entities, time) {
    entities.forEach((entity) => {
      // First check for required components.
      if (!entity.has('transition')) {
        return;
      }

      const entityTime = entity.get('time') || {};

      let hasActiveTransition = false;
      entity.get('transition').forEach((transition) => {
        const times = getTransitionTimes(transition, entityTime);

        const isActive = Boolean(times.start <= time && (times.end + OVERSHOOT_BUFFER) >= time);
        if (!isActive) {
          return;
        }

        hasActiveTransition = true;

        const fullTime = times.end - times.start;
        if (!fullTime || fullTime < 1) {
          // Prevent divide by zero and negative time issues.
          return;
        }

        const elapsedTime = time - times.start;
        let percentComplete = (elapsedTime / fullTime);
        if (percentComplete > 1) {
          // If we overshot 100% complete, then take it back to 100%.
          percentComplete = 1;
        }

        let preset = null;
        if (transition.preset && transition.loadedPreset) {
          preset = transition.loadedPreset;
        } else {
          preset = loadPresetDetails(transition.preset, entity) || {};
          runInAction('saveLoadedPreset', () => {
            transition.loadedPreset = preset;
          });
        }

        let details = preset.details || transition.details;
        if (!details) {
          return;
        }

        if (details.opacity) {
          details = parseSpecialOpacityDetails(entity, details);
        }

        const easingName = preset.easing || transition.easing || EASING_LINEAR;
        const easing = loadEasing(easingName);
        const easingPercentComplete = easing(percentComplete);

        const diffs = lodash.reduce(details, (final, detailParams, detailName) => {
          const diff = detailParams[1] - detailParams[0];
          final[detailName] = detailParams[0] + (diff * easingPercentComplete);
          return final;
        }, {});

        // Now update the entity.
        updateEntityForTransition(entity, diffs);
      });

      if (!hasActiveTransition) {
        const defaultPosition = entity.get('position').default;
        const defaultVisibility = entity.get('visible').default;

        updateEntityForTransition(entity, {
          opacity: defaultVisibility.opacity,
          top: defaultPosition.top,
          left: defaultPosition.left,
          rotate: defaultPosition.rotate,
        }, true);
      }
    });
  }

  /**
   * Updates the given entity with the differences given.
   *
   * @param {{}} entity
   * @param {{}} diffs
   * @param {boolean=} isReset
   */
  function updateEntityForTransition(entity, diffs, isReset) {
    runInAction('transitionSystemUpdateEntity', () => {
      const element = entity.get('element');
      const currentPosition = entity.get('position');
      const isLine = (element === 'line');

      if (diffs.opacity !== undefined) {
        const currentOpacity = entity.get('visible').opacity;
        if (currentOpacity !== diffs.opacity) {
          updateEntity(entity, 'visible', {opacity: diffs.opacity});
        }
      }

      if (diffs.top !== undefined && !isLine) {
        if (currentPosition.top !== diffs.top) {
          updateEntity(entity, 'position', {top: diffs.top});
        }
      }

      if (diffs.left !== undefined && !isLine) {
        if (currentPosition.left !== diffs.left) {
          updateEntity(entity, 'position', {left: diffs.left});
        }
      }
      if (diffs.rotate !== undefined) {
        if (currentPosition.rotate !== diffs.rotate) {
          updateEntity(entity, 'position', {rotate: diffs.rotate});
        }
      }
      if (!isReset && isLine) {
        updateLinePosition(entity, diffs);
      }
    });
  }

  /**
   * Updates the line coordinates based on the position changes.
   *
   * @param {{}} entity
   * @param {{top: number, left: number}} diffs
   */
  function updateLinePosition(entity, diffs) {
    if (!entity.has('line')) {
      return;
    }

    const lineUpdates = parsePositionToLineUpdates(entity.get('line'), diffs);
    if (!lineUpdates) {
      return;
    }

    const {startPoint, endPoint} = lineUpdates;

    updateEntity(entity, 'line', {startPoint, endPoint});
  }

  /**
   * Parses special opacity properties.
   * For elements that have their own opacity, this takes into account where opacity should end up.
   *
   * @param {{}} entity
   * @param {{}} details
   * @returns {{}}
   */
  function parseSpecialOpacityDetails(entity, details) {
    if (!details.opacity || !Array.isArray(details.opacity)) {
      return details;
    }

    const element = entity.get('element');
    if (element === 'line') {
      const visible = entity.get('visible');
      const defaultOpacity = lodash.get(visible, 'default.opacity', 1);

      const newOpacity = details.opacity.map((opacityDetail) => {
        if (opacityDetail === 1 || opacityDetail === '1') {
          return defaultOpacity;
        }
        return opacityDetail;
      });

      return {
        ...details,
        opacity: newOpacity,
      };
    }

    return details;
  }

  /**
   * Loads the details for the preset.
   *
   * @param {string} presetName
   * @param {ObservableMap} entity
   * @returns {{easing: string, details: {}}}
   */
  function loadPresetDetails(presetName, entity) {
    if (!presetName) {
      return null;
    }
    const presetDetails = transitionPresets[presetName] || null;
    if (!presetDetails) {
      return presetDetails;
    }

    const preset = lodash.cloneDeep(presetDetails);

    if (!preset.parse) {
      return preset;
    }

    lodash.forEach(preset.details, ([from, to], trait) => {
      preset.details[trait] = [parseTrait(from, entity), parseTrait(to, entity)];
    });

    return preset;
  }

  /**
   * Parses trait data into numbers.
   *
   * @param {string|number} traitValue
   * @param {ObservableMap} entity
   * @returns {number}
   */
  function parseTrait(traitValue, entity) {
    const safeTraitValue = String(traitValue);

    if (lodash.startsWith(safeTraitValue, `${PARSE_OFF}.`)) {
      return parseOff(safeTraitValue, entity, game);
    }

    if (lodash.startsWith(safeTraitValue, `${PARSE_POSITION}.`)) {
      return parsePosition(safeTraitValue, entity);
    }

    return traitValue;
  }

  /**
   * Loads the easing function.
   *
   * @param {string} easingName
   * @returns {function}
   */
  function loadEasing(easingName) {
    if (!eases[easingName]) {
      return eases.linear;
    }
    return eases[easingName];
  }

  return {
    name: TRANSITION_SYSTEM,
    update: systemUpdate
  };
}
