import lodash from 'lodash';
import {action, computed, extendObservable, observable, when} from 'mobx';

import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../constants/asyncConstants';
import {CURRENT_USER_STORAGE_KEY} from '../../constants/sessionConstants';
import serverApi from '../../utils/serverApi';
import {apiStore, getCase} from '../../utils/apiStore';
import sessionStore from '../active/sessionStore';

/**
 * Array of user roles that have permission to access Create24.
 *
 * @const {string[]}
 */
const AUTHORIZED_USER_ROLES = [
  'Administrator',
  'Technician',
  'CSR',
  'Salesperson',
  'Supervisor',
  'Video Content Contractor',
  'Video Content Creator',
  'Content Manager',

  // 'Service',
  // 'User',
  // 'Voice Talent',
  // 'Audio Content Creator',
  // 'Script Writer',
];

/**
 * Rolls that are allowed to edit or create slides in global libraries.
 *
 * @type {string[]}
 */
const GLOBAL_EDITOR_ROLES = [
  'Administrator',
  'Video Content Contractor',
  'Video Content Creator',
];

/**
 * The login store.
 */
class LoginStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * The map of each page of projects.
   *
   * @type {Observable<{user: ?Observable, error: ?Error}>}
   */
  @observable currentUser = {
    user: null,
    error: null,
  };

  /**
   * Gets all the projects.
   *
   * @returns {?Array.<{}>}
   */
  @computed get user() {
    if (!this.currentUser.error) {
      return null;
    }
    return this.currentUser.user;
  }

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {?Array.<{}>}
   */
  getFulfilled() {
    return this.currentUser.user;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    return this.currentUser.error;
  }

  /**
   * Clears all the currentUser info
   */
  @action clearAll() {
    this.currentUser = {
      user: null,
      error: null,
    };
    this.state = STATE_PRE;
  }

  /**
   * Gets the current user data from persistent storage if it is available.
   *
   * @returns {Observable<{user: ?Observable, error: ?Error}>}
   */
  @action preloadCurrentUser() {
    if (this.currentUser && this.currentUser.user) {
      return this.currentUser;
    }

    const storedCurrentUser = localStorage.getItem(CURRENT_USER_STORAGE_KEY);
    if (storedCurrentUser) {
      const currentUser = JSON.parse(storedCurrentUser);

      this.currentUser.user = currentUser;
      sessionStore.setCurrentUser(currentUser);
      this.state = STATE_FULFILLED;
    }

    return this.currentUser;
  }

  /**
   * Posts login to the server.
   *
   * @param {{username: string, password: string}} body
   */
  @action postLogin(body) {
    this.state = STATE_PENDING;

    serverApi.loginPost(body).then(
      action('postLoginSuccess', (foundUser) => {
        let currentUser = {};
        if (foundUser) {
          if (lodash.includes(AUTHORIZED_USER_ROLES, foundUser.role)) {
            foundUser.canEditGlobals = lodash.includes(GLOBAL_EDITOR_ROLES, foundUser.role);
            currentUser = {
              user: foundUser,
              error: null,
            };
            sessionStore.setCurrentUser(foundUser);
            this.state = STATE_FULFILLED;
          } else {
            currentUser = {
              user: null,
              error: new Error('You do not have permission to access Create24. For more information, ' +
                'contact your administrator or Works24 representative.')
            };
            sessionStore.setCurrentUser(null);
            this.state = STATE_REJECTED;
          }
        } else {
          currentUser = {
            user: null,
            error: new Error('There was an error logging you in.'),
          };
          sessionStore.setCurrentUser(null);
          this.state = STATE_REJECTED;
        }
        this.currentUser = currentUser;
      }),
      action('postLoginError', () => {
        this.currentUser = {
          user: null,
          error: new Error('There was an error logging you in.'),
        };
        sessionStore.setCurrentUser(null);
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => {
      return this.getFulfilled();
    };
    const getRejected = () => {
      return this.getRejected();
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for this store.
   *
   * @returns {Promise}
   */
  getPromise() {
    const thisStore = this;

    return new Promise((resolve, reject) => {
      when(
        () => {
          return (thisStore.state === STATE_FULFILLED || thisStore.state === STATE_REJECTED);
        },
        () => {
          if (thisStore.state === STATE_REJECTED) {
            reject(this.getRejected());
            return;
          }

          resolve(this.getFulfilled());
        },
        {name: 'apiLoginStoreGetPromise'}
      );
    });
  }
}

export default new LoginStore();
