/**
 * Defines the route path for each page.
 */
export const homeRoute = '/';
export const dashboardRoute = '/select/action';
export const productSelectionRoute = '/select/product';
export const passwordResetRoute = '/login/password-reset';
export const editorRoute = '/editor/:contentId/:fromContentId?/:isLayout?';
export const loginRoute = '/login';
export const logoutRoute = '/logout';
export const newClipRoute = '/select/new-clip';
export const newClipTypeRoute = '/select/new-clip/:clipType/:isLayout?';
export const renderRoute = '/render';
