import classNames from 'classnames';
import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

import ContentMove from '../contentMove/ContentMove';
import DisplayContentSrc from '../../common/displayContentSrc/DisplayContentSrc';
import inject from '../../hoc/injectHoc';
import PreviewContentModal from '../../modals/previewContent/PreviewContentModal';
import ConfirmModal from '../../modals/confirm/ConfirmModal';
import InputStringModal from '../../modals/inputString/InputStringModal';
import {editorRoute} from '../../routePaths';
import {READY} from '../../../constants/contentConstants';
import {EDITOR_NEW_COPY} from '../../../constants/editorConstants';
import {VIDEO_CONTENT_TYPES} from '../../../constants/libraryTypeConstants';
import {CONTENT_TITLE_LENGTH} from '../../../constants/uiConstants';
import {isProcessing as getIsProcessing, hasProcessingExpired, forceContentState} from '../../../utils/contentsHelper';
import {routeHistory} from '../../../utils/history';
import {replaceRouteParams} from '../../../utils/routeHelper';

import './contentItem.scss';

/**
 * The ContentItem component.
 *
 * @constructor
 */
export class ContentItem extends React.Component {
  /**
   * Whether or not to display the preview modal.
   *
   * @type {boolean}
   */
  @observable isPreviewModalOpen = false;

  /**
   * Whether or not the delete confirmation modal is open.
   *
   * @type {boolean}
   */
  @observable isDeleteModalOpen = false;

  /**
   * Whether or not the edit confirmation modal is open.
   *
   * @type {boolean}
   */
  @observable isEditModalOpen = false;

  /**
   * Whether or not the renaming modal is open.
   *
   * @type {boolean}
   */
  @observable isRenameModalOpen = false;

  /**
   * Whether or not the action dropdown is open.
   *
   * @type {boolean}
   */
  @observable isDropdownOpen = false;

  /**
   * A reference to the move content component.
   * This will be used to call onMoveStart() from that component.
   *
   * @type {{current: ?{}}}
   */
  moveContentRef = React.createRef();

  /**
   * Triggered when the component is mounted to the page.
   */
  componentDidMount() {
    this.checkForProcessing();
  }

  /**
   * Triggered when the component updates.
   */
  componentDidUpdate() {
    this.checkForProcessing();
  }

  /**
   * Triggered when the component is about to be removed from the page.
   */
  componentWillUnmount() {
    const {apiPollContentStore, content} = this.props;

    apiPollContentStore.cancelPollForContent(content.id);
  }

  /**
   * Checks to see if the content is processing, and starts polling it if true.
   */
  checkForProcessing() {
    const {apiPollContentStore, content, pollKeys} = this.props;

    const isProcessing = getIsProcessing(content, {allowNew: true});
    if (!isProcessing) {
      return;
    }

    const firstContentFile = lodash.get(content, 'contentFiles[0]', null);
    if (!firstContentFile) {
      return;
    }

    const {updateDate} = firstContentFile;
    const updated = moment(updateDate);
    const limit = moment().subtract(2, 'hours');

    if (!updated.isAfter(limit)) {
      return;
    }

    apiPollContentStore.pollThenUpdateContent(content.id, pollKeys);
  }

  /**
   * Handles when a content item will start being edited.
   */
  @action onEditContent = () => {
    const {apiContentUsageStore, content} = this.props;
    if (!content.isEditable) {
      return;
    }
    apiContentUsageStore.fetchContentUsageById(content.id);

    apiContentUsageStore.getPromise(content.id).then((inUse) => {
      if (inUse) {
        this.updateEditModalOpen(true);
      } else {
        this.onEditCompleted(true);
      }
    }).catch(() => {
      this.updateEditModalOpen(true);
    });
  };

  /**
   * Updates isEditModalOpen
   *
   * @param {boolean} isOpen
   */
  @action updateEditModalOpen(isOpen) {
    this.isEditModalOpen = isOpen;
  }

  /**
   * Triggered when the edit is confirmed.
   *
   * @param {boolean} wasConfirmed
   */
  @action onEditCompleted = (wasConfirmed) => {
    const {content} = this.props;
    this.isEditModalOpen = false;

    if (!wasConfirmed) {
      return;
    }

    routeHistory.push(
      replaceRouteParams(editorRoute, {
        contentId: content.id,
        fromContentId: '',
        isLayout: '',
      })
    );
  };

  /**
   * Handles when a content item's processing has expired and they want to open it back up to editing.
   *
   * @returns {Promise}
   */
  onForceEditContent = () => {
    const {content} = this.props;
    if (!content.isEditable) {
      return Promise.resolve(false);
    }

    return forceContentState(content, READY).then(() => {
      this.onEditContent();
    });
  };

  /**
   * Handles when the content needs to be cloned.
   */
  onCloneContent = () => {
    const {content, routerStore} = this.props;

    routerStore.push(editorRoute, {
      params: {
        contentId: EDITOR_NEW_COPY,
        fromContentId: content.id,
        isLayout: '',
      }
    });
  };

  /**
   * Triggered when the rename button is clicked.
   */
  @action onRename = () => {
    this.isRenameModalOpen = true;
  };

  /**
   * Triggered when the new name for the category rename has been chosen.
   *
   * @param {string} newContentName
   */
  @action onRenameCompleted = (newContentName) => {
    const {content, libraryType, apiUpdateContentsStore} = this.props;

    this.isRenameModalOpen = false;

    if (!newContentName) {
      return;
    }

    const contentBody = {
      id: content.id,
      title: newContentName
    };
    const contentFileBody = {
      id: content.contentFiles[0].id
    };

    apiUpdateContentsStore.updateContent(
      contentBody,
      contentFileBody,
      content.categoryId,
      content.libraryId,
      libraryType
    );
  };

  /**
   * Handles when a content item will be moved to a different category.
   */
  onMoveContent = () => {
    const {content} = this.props;

    this.moveContentRef.current.onMoveStart(content);
  };

  /**
   * Triggered when the delete button is clicked.
   */
  @action onDelete = () => {
    this.isDeleteModalOpen = true;
  };

  /**
   * Triggered when the delete is confirmed.
   *
   * @param {boolean} wasConfirmed
   */
  @action onDeleteCompleted = (wasConfirmed) => {
    this.isDeleteModalOpen = false;

    if (!wasConfirmed) {
      return;
    }

    const {content, libraryType, apiDeleteContentStore} = this.props;
    apiDeleteContentStore.deleteContent(content, libraryType);
  };

  /**
   * Preview the video
   *
   * @param {{}} clickEvent
   */
  @action previewVideo = (clickEvent) => {
    clickEvent.preventDefault();

    this.isPreviewModalOpen = true;
  };

  /**
   * Closes the preview modal.
   */
  @action onClosePreviewModal = () => {
    this.isPreviewModalOpen = false;
  };

  /**
   * Toggle the dropdown menu.
   */
  @action onDropDownToggle = () => {
    this.isDropdownOpen = !this.isDropdownOpen;
  };

  /**
   * gets the formatted updated date or, if it doesn't exist, the formatted created date
   *
   * @returns {string}
   */
  addedUpdatedDate = () => {
    const content = this.props.content;
    const firstContentFile = lodash.get(content, 'contentFiles[0]');
    if (!firstContentFile) {
      return null;
    }

    const formatString = 'LL';
    if (firstContentFile.updateDate) {
      return 'Updated ' + (moment(firstContentFile.updateDate).format(formatString));
    } else if (firstContentFile.createDate) {
      return 'Added ' + (moment(firstContentFile.createDate).format(formatString));
    }
    return null;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() { // eslint-disable-line complexity
    const {apiContentUsageStore, content, contentSize, isWriteable, libraryType} = this.props;

    // This is important to keep! It will make sure MobX re-renders this component on state change.
    // eslint-disable-next-line no-unused-expressions
    content.contentFiles && content.contentFiles[0] && content.contentFiles[0].contentFileStateId;

    const isContentAnImage = !lodash.includes(VIDEO_CONTENT_TYPES, content.contentTypeId);
    const {isEditable} = content;
    const isProcessing = getIsProcessing(content, {allowNew: true});
    const processingExpired = hasProcessingExpired(content);

    const classes = {
      'non-editable': !content.isEditable
    };

    return (
      <figure className={classNames('content-item', classes)}>
        <div className="content-item-button">
          <div className="content-item-image-wrapper">
            <DisplayContentSrc
              content={content}
              contentSize={contentSize}
              isImage={isContentAnImage}
              libraryType={libraryType}
              thumbnail={true}
            />

            <div className="content-item-image-overlay">
              <div className="actions-wrapper">
                <button
                  type="button"
                  className="btn btn-primary action-button"
                  disabled={isProcessing}
                  onClick={this.previewVideo}
                >Preview
                </button>

                {(!isEditable) && (
                  <button
                    type="button"
                    disabled
                    className="btn btn-primary action-button"
                  >Non-Editable</button>
                )}

                {(isEditable && isWriteable) && (
                  apiContentUsageStore.case(content.id, {
                    'pre': () => (
                      <button
                        type="button"
                        className="btn btn-primary action-button"
                        disabled={isProcessing}
                        onClick={this.onEditContent}
                      >Edit</button>
                    ),
                    'pending': () => (
                      <button
                        type="button"
                        className="btn btn-primary action-button"
                        disabled={true}
                        onClick={this.onEditContent}
                      >Loading...</button>
                    ),
                    'rejected': () => (
                      <button
                        type="button"
                        className="btn btn-primary action-button"
                        disabled={isProcessing}
                        onClick={this.onEditContent}
                      >Edit</button>
                    ),
                    'fulfilled': () => (
                      <button
                        type="button"
                        className="btn btn-primary action-button"
                        disabled={isProcessing}
                        onClick={this.onEditContent}
                      >Edit</button>
                    )
                  })
                )}

                {(isEditable) && (
                  <button
                    type="button"
                    className="btn btn-primary action-button"
                    disabled={isProcessing}
                    onClick={this.onCloneContent}
                  >Make A Copy</button>
                )}
              </div>

              {(isWriteable && (!isProcessing || processingExpired)) && (
                <Dropdown className="content-menu" isOpen={this.isDropdownOpen} toggle={this.onDropDownToggle}>
                  <DropdownToggle
                    className="menu-button fa fa-ellipsis-v"
                    tag="button"
                    id={`content-menu-${content.id}`}
                  />
                  <DropdownMenu right>
                    {(!isProcessing) && (
                      <DropdownItem onClick={this.onRename}>
                        <i className="fa fa-font" />
                        Rename
                      </DropdownItem>
                    )}

                    {(!isProcessing) && (
                      <DropdownItem onClick={this.onMoveContent}>
                        <i className="fa fa-sign-out" />
                        Move
                      </DropdownItem>
                    )}

                    {(isEditable && processingExpired) && (
                      <DropdownItem onClick={this.onForceEditContent}>
                        <i className="fa fa-pencil" />
                        Force Edit
                      </DropdownItem>
                    )}

                    <DropdownItem onClick={this.onDelete}>
                      <i className="fa fa-trash" />
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
            </div>
          </div>
          <div className="content-item-text-wrapper">
            <figcaption title={content.title} className="content-item-text">
              {lodash.truncate(content.title, {length: CONTENT_TITLE_LENGTH})}
            </figcaption>
            <p className="content-item-subtext">{this.addedUpdatedDate()}</p>
          </div>
        </div>

        {(isWriteable) && (
          <ContentMove libraryType={libraryType} ref={this.moveContentRef} />
        )}

        {(this.isPreviewModalOpen) && (
          <PreviewContentModal
            contentSize={contentSize}
            isOpen={true}
            onComplete={this.onClosePreviewModal}
            content={content}
            isImage={isContentAnImage}
            libraryType={libraryType}
            title="Preview - %t"
          />
        )}

        {(this.isRenameModalOpen) && (
          <InputStringModal
            isOpen={true}
            onComplete={this.onRenameCompleted}
            placeholder="Content Name"
            title="Rename Content"
            confirmText="Rename Content"
            helpText={(content.title) ? `Choose a new name for the '${content.title}' content.` : null}
          />
        )}

        {(this.isDeleteModalOpen) && (
          <ConfirmModal
            isOpen={true}
            onComplete={this.onDeleteCompleted}
            title="Delete Modal?"
            confirmText="Warning"
            helpText={`Are you sure you want to delete '${content.title}'?`}
            isYesNo={true}
          />
        )}

        {(this.isEditModalOpen) && (
          <ConfirmModal
            isOpen={true}
            onComplete={this.onEditCompleted}
            title="Edit Content?"
            confirmText="Caution"
            helpText={`If you edit content that is currently used in a live playlist, it will affect live players.
            Do you want to continue to edit "${content.title}"?`}
            isYesNo={false}
            yesText="Edit"
          />
        )}
      </figure>
    );
  }
}

ContentItem.propTypes = {
  content: PropTypes.shape({
    categoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    contentFiles: PropTypes.oneOfType([MobxPropTypes.observableArray, PropTypes.array]),
    contentTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    createDate: PropTypes.string,
    id: PropTypes.number,
    isEditable: PropTypes.bool,
    libraryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    updateDate: PropTypes.string,
  }).isRequired,
  contentSize: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  isWriteable: PropTypes.bool.isRequired,
  libraryType: PropTypes.number.isRequired,
  pollKeys: PropTypes.object.isRequired,

  apiContentUsageStore: MobxPropTypes.observableObject,
  apiDeleteContentStore: MobxPropTypes.observableObject,
  apiPollContentStore: MobxPropTypes.observableObject,
  apiUpdateContentsStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
  sessionStore: MobxPropTypes.observableObject,
};

ContentItem.wrappedComponent = {
  propTypes: {
    apiContentUsageStore: MobxPropTypes.observableObject.isRequired,
    apiDeleteContentStore: MobxPropTypes.observableObject.isRequired,
    apiPollContentStore: MobxPropTypes.observableObject.isRequired,
    apiUpdateContentsStore: MobxPropTypes.observableObject.isRequired,
    routerStore: MobxPropTypes.observableObject.isRequired,
    sessionStore: MobxPropTypes.observableObject.isRequired,
  }
};

export default inject(ContentItem)(
  observer(ContentItem)
);
