import lodash from 'lodash';
import {action, computed, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Modal, ModalBody, ModalFooter} from 'reactstrap';

import Select from '../../common/select/Select';
import inject from '../../hoc/injectHoc';
import {FEED_TYPE_API, FEED_TYPE_CUSTOM} from '../../../stores/game/gameFeedStore';

import './feedSelectModal.scss';

/**
 * The list of api types formatted for react select.
 *
 * @const {Array.<{label: string, value: string}>}
 */
const API_TYPES = [
  {label: 'Content API', value: 'contentapi'},
  {label: 'Delivery API', value: 'deliveryapi'},
  {label: 'Admin API', value: 'adminapi'},
];

/**
 * The FeedSelectModal component.
 */
class FeedSelectModal extends React.Component {
  /**
   * When changing from false to true, this will open the file uploading dialog.
   *
   * @type {boolean}
   */
  @observable openUploadDialog = false;

  /**
   * The API URL.
   *
   * @type {string}
   */
  @observable apiUrl = '';

  /**
   * The API type (from contentapi, deliveryapi, adminapi).
   *
   * @type {string}
   */
  @observable apiType = '';

  /**
   * The custom URL.
   *
   * @type {string}
   */
  @observable customUrl = '';

  /**
   * Triggered when the component just mounted onto the page.
   */
  componentDidMount() {
    if (this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{isOpen: boolean}} prevProps
   */
  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.initializeModal();
    }
  }

  /**
   * Initializes the modal by getting the valid content libraries.
   */
  @action initializeModal = () => {
    const {currentValue} = this.props;

    const safeCurrentValue = (currentValue) ? String(currentValue) : '';

    let currentApiType = '';
    let currentApiUrl = '';

    if (safeCurrentValue) {
      lodash.forEach(API_TYPES, (apiType) => {
        if (lodash.startsWith(safeCurrentValue, apiType.value)) {
          currentApiType = apiType.value;
          currentApiUrl = lodash.trim(safeCurrentValue.substring(apiType.value.length), ' /');
        }
      });
    }

    this.apiUrl = currentApiUrl;
    this.apiType = currentApiType;

    if (!currentApiUrl && !currentApiType) {
      this.customUrl = safeCurrentValue;
    }
  };

  /**
   * Whether or not to disable the submit button.
   */
  @computed get isDisabled() {
    if (this.apiUrl && this.apiType) {
      return false;
    } else if (this.customUrl) {
      return false;
    }

    return true;
  }

  /**
   * Sets the api url.
   *
   * @param {{}} changeEvent
   */
  @action onApiUrlChange = (changeEvent) => {
    const apiUrl = changeEvent.target.value;
    if (!apiUrl) {
      this.apiUrl = '';
      return;
    }

    this.apiUrl = apiUrl.replace(/https?:\/\//ig, '');
  };

  /**
   * Sets the api type.
   *
   * @param {string} newApiType
   */
  @action onApiTypeChange = (newApiType) => {
    this.apiType = newApiType;
  };

  /**
   * Sets the custom url.
   *
   * @param {{}} changeEvent
   */
  @action onCustomUrlChange = (changeEvent) => {
    this.customUrl = changeEvent.target.value || '';
  };

  /**
   * Clears the custom url if the api url was set.
   * Triggered when the api url field blurs.
   *
   * @param {{}} changeEvent
   */
  @action onApiUrlBlur = (changeEvent) => {
    if (!changeEvent.target.value) {
      return;
    }

    this.customUrl = '';
  };

  /**
   * Clears the api url if the custom url was set.
   * Triggered when the custom url field blurs.
   *
   * @param {{}} changeEvent
   */
  @action onCustomUrlBlur = (changeEvent) => {
    if (!changeEvent.target.value) {
      return;
    }

    this.apiUrl = '';
  };

  /**
   * Triggered when the modal is closed/cancelled.
   */
  onCancelModal = () => {
    const {onComplete} = this.props;

    onComplete(null);
  };

  /**
   * Triggered when the modal is submitted.
   */
  onCompleteModal = () => {
    const {onComplete} = this.props;

    let submitValue = null;
    if (this.apiUrl) {
      submitValue = {
        type: FEED_TYPE_API,
        apiUrl: lodash.trim(this.apiUrl, ' /'),
        apiType: this.apiType,
      };
    } else if (this.customUrl) {
      submitValue = {
        type: FEED_TYPE_CUSTOM,
        customUrl: lodash.trim(this.customUrl, ' '),
      };
    }

    onComplete(submitValue);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      isOpen,
      title,
    } = this.props;

    return (
      <Modal isOpen={isOpen} backdrop="static" toggle={this.onCancelModal} className="feed-select-modal">
        <ModalBody>
          <div className="container">
            <p className="w24-label">{title}</p>

            <div className="api-input">
              <div className="form-group">
                <label htmlFor="feed-api-url" className="w24-label">Works24 API URL:</label>
                <div className="w24-text-input">
                  <input
                    id="feed-api-url"
                    type="text"
                    value={this.apiUrl}
                    placeholder="API URL"
                    onBlur={this.onApiUrlBlur}
                    onChange={this.onApiUrlChange}
                  />
                </div>

                <Select
                  theme="dark"
                  value={this.apiType}
                  onChange={this.onApiTypeChange}
                  options={API_TYPES}
                />
              </div>
            </div>

            <hr />

            <div className="custom-input">
              <div className="form-group">
                <label htmlFor="custom-api-url" className="w24-label">Custom URL:</label>
                <div className="w24-text-input">
                  <input
                    id="custom-api-url"
                    type="text"
                    value={this.customUrl}
                    placeholder="Custom URL"
                    onBlur={this.onCustomUrlBlur}
                    onChange={this.onCustomUrlChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-default"
            onClick={this.onCancelModal}
          >Cancel</button>
          {' '}
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.onCompleteModal}
            disabled={this.isDisabled}
          >Ok</button>
        </ModalFooter>
      </Modal>
    );
  }
}

FeedSelectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,

  activeContentStore: MobxPropTypes.observableObject,
  apiFeedSummaryStore: MobxPropTypes.observableObject,
  currentValue: PropTypes.string,
  displayEditorStore: MobxPropTypes.observableObject,
  sessionStore: MobxPropTypes.observableObject,
  title: PropTypes.string,
};

FeedSelectModal.defaultProps = {
  title: 'Create New Feed',
};

FeedSelectModal.wrappedComponent = {};
FeedSelectModal.wrappedComponent.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
  apiFeedSummaryStore: MobxPropTypes.observableObject.isRequired,
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
  sessionStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(FeedSelectModal)(
  observer(FeedSelectModal)
);
