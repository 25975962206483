import {action, computed, extendObservable, observable} from 'mobx';

import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../constants/asyncConstants';
import {EXPIRE_TIME, EXPIRES_IN} from '../../constants/storeConstants';
import serverApi from '../../utils/serverApi';
import {apiStore, getCase} from '../../utils/apiStore';

/**
 * The projects store.
 */
class ProjectsStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * The page that is loading.
   *
   * @type {number}
   */
  @observable page = 1;

  /**
   * The total number of pages of projects.
   *
   * @type {number}
   */
  @observable totalPagesCount = 0;

  /**
   * The map of each page of projects.
   *
   * @type {ObservableMap<string, {projects: ?ObservableArray, error: ?Error}>}
   */
  @observable projectsByPage = observable.map();

  /**
   * Gets all the projects.
   *
   * @returns {?Array.<{}>}
   */
  @computed get projects() {
    if (!this.projectsByPage.has(this.page)) {
      return null;
    }
    return this.projectsByPage.get(this.page).projects;
  }

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {?Array.<{}>}
   */
  getFulfilled() {
    return this.projects;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    if (!this.projectsByPage.has(this.page)) {
      return null;
    }
    return this.projectsByPage.get(this.page).error;
  }

  /**
   * Clears all the pages and projects.
   */
  @action clearAll() {
    this.projectsByPage.clear();
    this.state = STATE_PRE;
  }

  /**
   * Sets the page number.
   *
   * @param {number} page
   */
  @action setPage(page) {
    if (!page || page < 1) {
      return;
    }
    this.page = Number(page);
  }

  /**
   * Fetches projects from the server.
   *
   * @param {number=} page
   */
  @action fetchProjects(page) {
    if (page && page > 0) {
      this.setPage(page);
    }

    let currentExpireTime = this.projectsByPage.get(EXPIRE_TIME);
    if (currentExpireTime && currentExpireTime <= Date.now()) {
      this.projectsByPage.clear();
      currentExpireTime = null;
    }

    if (!currentExpireTime) {
      this.projectsByPage.set(EXPIRE_TIME, Date.now() + EXPIRES_IN);
    }

    const pageNumber = this.page;
    const currentProjectsByPage = this.projectsByPage.get(pageNumber);
    if (currentProjectsByPage && currentProjectsByPage.projects) {
      this.state = STATE_FULFILLED;
      return;
    }

    this.state = STATE_PENDING;

    serverApi.projectsGetAll(page).then(
      action('fetchProjectsSuccess', (foundProjects) => {
        this.projectsByPage.set(pageNumber, {
          projects: foundProjects,
          error: null,
        });
        this.state = STATE_FULFILLED;
      }),
      action('fetchProjectError', (error) => {
        this.projectsByPage.set(pageNumber, {
          projects: null,
          error,
        });
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => {
      return this.getFulfilled();
    };
    const getRejected = () => {
      return this.getRejected();
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }
}

export default new ProjectsStore();
