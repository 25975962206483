import lodash from 'lodash';
import {action, computed, observable, toJS} from 'mobx';

import apiFeedFromApiStore from '../api/apiFeedFromApiStore';
import apiFeedFromCustomStore from '../api/apiFeedFromCustomStore';
import {getFeedPathValue, hasVariables} from '../../utils/feedPathHelper';
import {replaceFeedTags, replaceText} from '../../utils/textReplacer';

/**
 * The normal feed type that comes from a feed id.
 * @const {Symbol}
 */
export const FEED_TYPE_ID = Symbol('FEED_TYPE_ID');

/**
 * The api feed type that comes from a system api call.
 * @const {Symbol}
 */
export const FEED_TYPE_API = Symbol('FEED_TYPE_API');

/**
 * The custom feed type that comes from a custom url.
 * @const {Symbol}
 */
export const FEED_TYPE_CUSTOM = Symbol('FEED_TYPE_CUSTOM');

/**
 * The list of all feed types.
 * @const {Symbol[]}
 */
export const ALL_FEED_TYPES = [
  FEED_TYPE_ID,
  FEED_TYPE_API,
  FEED_TYPE_CUSTOM,
];

/**
 * The default values for the feed summary.
 * @const {{texts: Array, images: Array, data: Array.<{}>}}
 */
const FEED_SUMMARY_DEFAULT = {
  type: FEED_TYPE_ID,
  texts: [],
  images: [],
  data: [{}],
};

/**
 * The game feed store.
 */
export class GameFeedStore {
  /**
   * The feed type (id, api, or custom).
   *
   * @type {?Symbol}
   */
  @observable feedType = null;

  /**
   * The feed id for this game.
   *
   * @type {?number}
   */
  @observable feedId = null;

  /**
   * The index of the feed item to display or the starting index for `repeatCount`.
   * ID feeds come in as arrays and so this index must be defined to get to actual feed data.
   *
   * @type {number}
   */
  @observable feedIndex = 0;

  /**
   * The feed source for this game.
   *
   * @type {?string}
   */
  @observable feedSource = null;

  /**
   * The collection of feeds with an id source.
   *
   * @type {?{}}
   */
  @observable feedsById = null;

  /**
   * The data for the feedSource if it from an api.
   *
   * @type {?{}}
   */
  @observable apiFeedData = null;

  /**
   * The data for the feedSource if it from a custom url.
   *
   * @type {?{}}
   */
  @observable customFeedData = null;

  /**
   * Gets the sample values for the selected feed.
   *
   * @returns {{
   *   type: Symbol,
   *   data: (Object|Array),
   *   images: Array,
   *   text: Array,
   * }}
   */
  @computed get feedSummary() {
    if (this.feedType === FEED_TYPE_ID) {
      return lodash.get(toJS(this.feedsById), String(this.feedId), FEED_SUMMARY_DEFAULT);
    } else if (this.feedType === FEED_TYPE_API) {
      return lodash.defaults({
        type: FEED_TYPE_API,
        data: toJS(this.apiFeedData || {}),
      }, FEED_SUMMARY_DEFAULT);
    } else if (this.feedType === FEED_TYPE_CUSTOM) {
      return lodash.defaults({
        type: FEED_TYPE_CUSTOM,
        data: toJS(this.customFeedData || {}),
      }, FEED_SUMMARY_DEFAULT);
    }

    return FEED_SUMMARY_DEFAULT;
  }

  /**
   * Gets the feed data for the render process.
   *
   * @returns {{fromId: {}}|{fromApi: {}}|{fromCustom: {}}|{}}
   */
  getFeedDataForRender() {
    if (this.feedType === FEED_TYPE_ID) {
      if (!this.feedId || !this.feedsById || !this.feedsById[this.feedId]) {
        return {};
      }

      return {
        fromId: {
          [this.feedId]: toJS(this.feedsById)[this.feedId],
        },
      };
    } else if (this.feedType === FEED_TYPE_API) {
      return {
        fromApi: toJS(this.apiFeedData),
      };
    } else if (this.feedType === FEED_TYPE_CUSTOM) {
      return {
        fromCustom: toJS(this.customFeedData),
      };
    }

    return {};
  }

  /**
   * Gets the feed data
   *
   * @param {{}} keys
   * @param {string} keys.source The path to or key for the returned item.
   * @param {string=} keys.format The way the returned item(s) will be formatted.
   * @param {number=} keys.repeatCount The number of items from the source to return (if the source is an array).
   * @param {{default: string, replace: boolean, slideNumber: number}} options
   * @returns {string[]}
   */
  getFeedData(keys, options) {
    const validKeys = ['source', 'format', 'repeatCount'];

    const safeKeys = lodash.pick(keys || {}, validKeys);
    const safeOptions = options || {};
    const safeDefault = (safeOptions.default) ? String(safeOptions.default) : '';

    let feedContents;
    if (this.feedType === FEED_TYPE_ID) {
      feedContents = this.getIdFeed(safeKeys, safeOptions);
    } else if (this.feedType === FEED_TYPE_API) {
      feedContents = this.getApiFeed(safeKeys, safeOptions);
    } else if (this.feedType === FEED_TYPE_CUSTOM) {
      feedContents = this.getCustomFeed(safeKeys, safeOptions);
    } else {
      feedContents = [safeDefault];
    }

    if (!safeOptions.replace) {
      return feedContents;
    }

    return feedContents.map((feedContent) => {
      return replaceText(feedContent, {
        feedData: this.feedSummary.data,
        slideNumber: safeOptions.slideNumber || 0,
      });
    });
  }

  /**
   * Gets the id feed by feedIndex and source.
   *
   * @param {{}} keys
   * @param {string} keys.source The path to the returned item.
   * @param {{default: string}} options
   * @returns {string[]}
   */
  getIdFeed(keys, options) {
    const safeOptions = options || {};
    const safeDataIndex = this.feedIndex || 0;
    const safeSource = keys.source || '';

    const feedData = lodash.get(this.feedSummary, `data[${safeDataIndex}]`, {});
    const feedContent = lodash.get(feedData, safeSource, safeOptions.default);

    return [String(feedContent)];
  }

  /**
   * Gets the api feed data by source.
   *
   * @param {{}} keys
   * @param {string} keys.source The path to the returned item.
   * @param {string=} keys.format The way the returned item(s) will be formatted.
   * @param {number=} keys.repeatCount The number of items from the source to return (if the source is an array).
   * @param {{default: string, slideNumber: number, getCustom: boolean}} options
   * @returns {string[]}
   */
  getApiFeed(keys, options) {
    const safeOptions = options || {};

    if (!options.getCustom && !this.apiFeedData) {
      return [];
    } else if (options.getCustom && !this.customFeedData) {
      return [];
    }

    const safeSource = keys.source || '';
    const feedData = this.feedSummary.data;
    const safeRepeatCount = parseInt(keys.repeatCount, 10) || 0;
    const willRepeat = Boolean(safeRepeatCount && hasVariables(safeSource));

    const feedMetaData = {slideNumber: options.slideNumber || 0};

    const formatItem = (itemToFormat) => {
      return this.formatFeedItem(itemToFormat || safeOptions.default, keys.format, safeOptions.slideNumber);
    };

    let items;
    if (!safeSource) {
      items = feedData;
    } else if (!willRepeat) {
      items = getFeedPathValue(
        safeSource,
        feedData,
        feedMetaData
      );
    } else {
      return this.parseRepeatCount(
        safeRepeatCount,
        safeSource,
        feedData,
        feedMetaData,
        formatItem,
        this.feedIndex || 0
      );
    }

    if (items === null || items === undefined) {
      return [safeOptions.default];
    }

    return [formatItem(items)];
  }

  /**
   * Parses the items for each repeatCount.
   *
   * @param {number} repeatCount
   * @param {string} source
   * @param {*} feedData
   * @param {{}} feedMetaData
   * @param {function} formatItem
   * @returns {string[]}
   */
  parseRepeatCount(repeatCount, source, feedData, feedMetaData, formatItem) {
    const safeDataIndex = parseInt(this.feedIndex, 10) || 0;
    const totalCount = safeDataIndex + repeatCount;

    const variableValues = {};
    let stopParsing = false;

    const items = lodash.range(0, totalCount).reduce((final, itemNumber) => {
      if (stopParsing) {
        return final;
      }

      const feedValue = getFeedPathValue(
        source,
        feedData,
        feedMetaData,
        variableValues
      );

      if (feedValue === null) {
        // Null means there are no more items to parse, so we need to skip this one and stop parsing future items.
        stopParsing = true;
        return final;
      }

      if (itemNumber >= safeDataIndex) {
        final.push(feedValue);
      }
      return final;
    }, []);

    return items.map(formatItem);
  }

  /**
   * Gets the custom feed data by source.
   *
   * @param {{}} keys
   * @param {string} keys.source The path to the returned item.
   * @param {string=} keys.format The way the returned item(s) will be formatted.
   * @param {number=} keys.repeatCount The number of items from the source to return (if the source is an array).
   * @param {{default: string, slideNumber: number}} options
   * @returns {string[]}
   */
  getCustomFeed(keys, options) {
    const safeOptions = options || {};
    safeOptions.getCustom = true;

    // For now, the custom feed logic is the same as the api feed logic.
    return this.getApiFeed(keys, safeOptions);
  }

  /**
   * Updates the feed type.
   *
   * @param {?Symbol} newFeedType
   */
  @action setFeedType(newFeedType) {
    if (ALL_FEED_TYPES.indexOf(newFeedType) !== -1) {
      this.feedType = newFeedType;
      return;
    }

    this.feedType = null;
  }

  /**
   * Updates the feed id.
   *
   * @param {number} newFeedId
   */
  @action setFeedId(newFeedId) {
    this.feedId = newFeedId || null;
    this.feedSource = null;
    this.setFeedType(FEED_TYPE_ID);
  }

  /**
   * Updates the feed source.
   *
   * @param {string} newFeedSource
   * @param {Symbol} newFeedType
   */
  @action setFeedSource(newFeedSource, newFeedType) {
    this.feedSource = newFeedSource || null;
    this.feedId = null;

    if (newFeedType) {
      this.setFeedType(newFeedType);
    } else if (newFeedSource.match(/^[^/]+api\//ig)) {
      this.setFeedType(FEED_TYPE_API);
    } else {
      this.setFeedType(FEED_TYPE_CUSTOM);
    }
  }

  /**
   * Updates the feed index.
   *
   * @param {number} newFeedIndex
   */
  @action setFeedIndex(newFeedIndex) {
    this.feedIndex = parseInt(newFeedIndex, 10) || 0;
  }

  /**
   * Sets the feeds that are id based.
   *
   * @param {{}} newIdFeeds
   */
  @action setIdFeeds(newIdFeeds) {
    this.feedsById = newIdFeeds || null;
  }

  /**
   * Sets the feeds that come from a server based api.
   *
   * @param {{}} newApiFeedData
   */
  @action setApiFeed(newApiFeedData) {
    this.apiFeedData = newApiFeedData || null;
  }

  /**
   * Sets the feeds that come from a custom url.
   *
   * @param {{}} newCustomFeedData
   */
  @action setCustomFeed(newCustomFeedData) {
    this.customFeedData = newCustomFeedData || null;
  }

  /**
   * Loads the feed data from the current feed source.
   *
   * @param {boolean=} force Forces an update even if the data already exists.
   * @returns {boolean}
   */
  fetchFeedDataFromSource(force) {
    if (this.feedType === FEED_TYPE_API) {
      // If we already have this data, don't try to reload it.
      if (this.apiFeedData && !force) {
        return true;
      }

      apiFeedFromApiStore.fetch(this.feedSource);
      apiFeedFromApiStore.getPromise(this.feedSource).then((feedData) => {
        this.setApiFeed(feedData);
      }).catch(() => {
        this.setApiFeed(null);
      });

      return true;
    } else if (this.feedType === FEED_TYPE_CUSTOM) {
      // If we already have this data, don't try to reload it.
      if (this.customFeedData && !force) {
        return true;
      }

      apiFeedFromCustomStore.fetch(this.feedSource);
      apiFeedFromCustomStore.getPromise(this.feedSource).then((feedData) => {
        this.setCustomFeed(feedData);
      }).catch(() => {
        this.setCustomFeed(null);
      });

      return true;
    }

    return false;
  }

  /**
   * Formats the given item using the given format string.
   *
   * @param {object|string} item
   * @param {string} format
   * @param {number=} slideNumber
   * @returns {string}
   */
  formatFeedItem(item, format, slideNumber) {
    if (!format) {
      return String(item);
    }

    return replaceFeedTags(String(format), {
      feedData: item,
      slideNumber: slideNumber || 0,
    });
  }
}

// Tells the system not to use this as an injectable store.
export const doNotInject = true;
