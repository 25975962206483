import lodash from 'lodash';

import acs from '../../stores/active/activeContentStore';
import apiCategoriesStore from '../../stores/api/apiCategoriesStore';
import apiContentsStore from '../../stores/api/apiContentsStore';

/**
 * Performs logic when content library id changes.
 *
 * @param {?number} contentLibraryId
 * @param {number} libraryType
 * @param {?{}} contentStore
 */
export function contentLibraryChange(contentLibraryId, libraryType, contentStore) {
  const activeContentStore = contentStore || acs;
  activeContentStore.setCategoryId(libraryType, null);

  if (!contentLibraryId) {
    activeContentStore.setCategoryStoreKey(libraryType, null);
    return;
  }

  const filters = {
    contentLibraryId
  };

  const categoryStoreKey = apiCategoriesStore.fetchCategories(filters);

  activeContentStore.setCategoryStoreKey(libraryType, categoryStoreKey);
  activeContentStore.setCategorySearchFilter(libraryType, '');
}

/**
 * Performs logic when category search filter changes.
 *
 * @param {?number} searchFilter
 * @param {number} libraryType
 * @param {?{}} contentStore
 */
export function categorySearchChange(searchFilter, libraryType, contentStore) {
  const activeContentStore = contentStore || acs;
  const activeLibrary = activeContentStore.libraries[libraryType];

  const filters = {
    contentLibraryId: activeLibrary.id,
  };

  if (searchFilter) {
    filters.categoryName = {
      method: 'contains',
      value: searchFilter
    };
  }

  const categoryStoreKey = apiCategoriesStore.fetchCategories(filters);

  activeContentStore.setCategoryStoreKey(libraryType, categoryStoreKey);
}

/**
 * Performs logic when category id changes.
 *
 * @param {number} libraryType
 * @param {?number} categoryId
 * @param {?number} libraryId
 */
export function categoryChange(libraryType, categoryId, libraryId) {
  if (!categoryId) {
    acs.setContentStoreKey(libraryType, null);
    return;
  }

  const activeLibrary = acs.libraries[libraryType];
  const filters = lodash.clone(activeLibrary.content.filters);
  filters.categoryId = categoryId;
  filters.libraryId = libraryId;
  filters.editableOnly = Boolean(acs.onlyShowEditable);

  acs.setContentFilters(libraryType, filters);
  const contentStoreKey = apiContentsStore.fetchContents(filters);

  acs.setContentStoreKey(libraryType, contentStoreKey);
  acs.setContentSearchFilter(libraryType, '');
}

/**
 * Performs logic when content 'onlyShowEditable' filter changes.
 *
 * @param {boolean} onlyShowEditable
 * @param {number} libraryType
 */
export function contentOnlyShowEditableChange(onlyShowEditable, libraryType) {
  const activeLibrary = acs.libraries[libraryType];

  if (!activeLibrary.category.id) {
    return;
  }

  const filters = lodash.clone(activeLibrary.content.filters);

  if (onlyShowEditable) {
    filters.editableOnly = true;
  } else {
    delete filters.editableOnly;
  }
  acs.setContentFilters(libraryType, filters);

  // Clear all the cached filters for this category.
  apiContentsStore.expireCacheForCategory(filters.categoryId);

  const contentStoreKey = apiContentsStore.fetchContents(filters);

  acs.setContentStoreKey(libraryType, contentStoreKey);
}
