import lodash from 'lodash';

import {cropComponent} from '../../../../../../display/components/common/cropComponent';
import {cropShapeComponent, RECTANGLE} from '../../../../../../display/components/common/cropShapeComponent';
import {positionComponent} from '../../../../../../display/components/common/positionComponent';
import {sizeComponent} from '../../../../../../display/components/common/sizeComponent';
import {feedImageComponent, getFeedImageFromSource} from '../../../../../../display/components/type/feedImageComponent';

/**
 * The default size (width and height) for the entity.
 *
 * @const {number}
 */
const DEFAULT_SIZE = 200;

/**
 *
 * Gets the image data for a placeholder image.
 *
 * @param {string} source
 * @returns {{}}
 */
export function getPlaceHolderFeedImageData(source) {
  return ({position, resolution, size}) => {
    const top = position.top || 0; // eslint-disable-line no-shadow
    const left = position.left || 0;
    const width = size.width || DEFAULT_SIZE;
    const height = size.height || DEFAULT_SIZE;

    return {
      setup: {
        ...cropComponent(top, left, width, height),
        ...cropShapeComponent(RECTANGLE),
        ...sizeComponent({width: resolution.width, height: resolution.height}),
        ...positionComponent({topPosition: 0, leftPosition: 0}),
      },
      ...feedImageComponent({
        source: source || null,
        top,
        left,
        width,
        height,
      }),
    };
  };
}

/**
 * Returns a source for when the feed image is reset.
 *
 * @param {{}=} currentFeedImage
 * @param {GameStore} game
 * @returns {{feedImage: {}}}
 */
export function resetFeedImageSource(currentFeedImage, game) {
  const source = lodash.get(game, 'feed.feedSummary.images[0]', '');

  const resetData = {
    source: source,
    top: 0,
    left: 0,
    width: DEFAULT_SIZE,
    height: DEFAULT_SIZE,
  };

  if (currentFeedImage) {
    resetData.top = currentFeedImage.top;
    resetData.left = currentFeedImage.left;
    resetData.width = currentFeedImage.width;
    resetData.height = currentFeedImage.height;
  }

  return {
    feedImage: resetData
  };
}

/**
 * Returns a new feed image component after resetting it.
 *
 * @param {{}=} currentFeedImage
 * @param {GameStore} game
 * @returns {{feedImage: {}}}
 */
export function resetFeedImageComponent(currentFeedImage, game) {
  return getFeedImageFromSource(
    resetFeedImageSource(currentFeedImage, game)
  );
}
