import {STATE_STOPPED} from '../../../constants/displayItemConstants';

/**
 * The video component.
 *
 * @param {number} fileId
 * @param {string} type
 * @param {boolean} loop
 * @param {string} state
 * @param {number} time
 * @returns {{video: {fileId: number, type: ?string, time: number, state: string, loop: boolean}}}
 */
export function videoComponent(fileId, type, loop, state, time) {
  return {
    video: {
      fileId,
      type,
      time: time || 0,
      state: state || STATE_STOPPED,
      loop: Boolean(loop)
    },
  };
}

/**
 * Gets the video component from the fileId item.
 *
 * @param {{video: {fileId: number, type: ?string, loop: ?boolean}}} item
 * @returns {{video: {fileId: number, type: ?string, time: number, state: string, loop: boolean}}}
 */
export function getVideoFromSource(item) {
  if (!item.video) {
    return {};
  } else if (!item.video.fileId) {
    throw new Error('Invalid video fileId for videoComponent.');
  }

  const video = item.video;

  return videoComponent(
    Number(video.fileId),
    video.type || null,
    video.loop || false
  );
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getVideoForSource(entity) {
  if (!entity.has('video')) {
    return {};
  }

  const video = entity.get('video');
  return {
    video: {
      fileId: video.fileId,
      type: video.type,
      loop: video.loop,
    },
  };
}
