import {RichUtils} from 'draft-js';
import lodash from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {MIN_FONT_SIZE, MAX_FONT_SIZE, FONT_SIZE_PREFIX} from '../../constants/styleContants';
import {addFontSizeToStyleMap, getAllActiveStylesWithPrefix, removeFromStyleMap} from '../../utils/styleHelper';
import Select from '../../../../common/select/Select';

import './fontSizeSelect.scss';

/**
 * The default font size to fall back to.
 * @const {string}
 */
const DEFAULT_FONT_SIZE = '20';

/**
 * The list of style buttons.
 *
 * @type {Array.<{label: string, value: string}>}
 */
const FONT_SIZE_VALUES = lodash.range(MIN_FONT_SIZE, MAX_FONT_SIZE + 1).map((fontSize) => {
  const label = String(fontSize) + 'px';
  return {label: label, value: String(fontSize)};
});

/**
 * Converts a font size into the draftJS style name.
 *
 * @param {string} fontSize
 * @returns {string}
 */
function convertFontSizeToStyleName(fontSize) {
  return `${FONT_SIZE_PREFIX}${fontSize}`;
}

/**
 * Gets the active color from a list of styles.
 *
 * @param {string[]} allStyles
 * @param {string} defaultValue
 * @returns {number} The font size
 */
function getFontSizeFromStyles(allStyles, defaultValue) {
  if (!allStyles || !allStyles.forEach) {
    return defaultValue;
  }

  let foundFontSize = defaultValue;
  allStyles.forEach((style) => {
    if (style.substr(0, FONT_SIZE_PREFIX.length) === FONT_SIZE_PREFIX) {
      foundFontSize = style.substr(FONT_SIZE_PREFIX.length);
    }
  });
  return foundFontSize;
}

/**
 * The FontSizeSelect component.
 *
 * @param {{}} params
 * @param {{}} params.editorState
 * @param {function(EditorState)} params.onChangeTextState
 * @param {function()=} params.beforeChange
 * @returns {React.Component}
 */
export const FontSizeSelect = ({editorState, onChangeTextState, beforeChange}) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  const currentValue = getFontSizeFromStyles(currentStyle.toArray(), DEFAULT_FONT_SIZE);

  /**
   * Updates the editor state with a new style when the selection changes.
   *
   * @param {string} newFontSize
   */
  const onChange = (newFontSize) => {
    let changeState = editorState;
    if (beforeChange) {
      changeState = beforeChange();
    }

    const newStyleName = convertFontSizeToStyleName(newFontSize);

    const beforeChangeStyle = changeState.getCurrentInlineStyle();
    const activeFontSizes = getAllActiveStylesWithPrefix(changeState, FONT_SIZE_PREFIX);

    // First remove any font sizes currently set.
    let newEditorState = changeState;
    lodash.forEach(activeFontSizes, (unused, activeFontSize) => {
      if (beforeChangeStyle.has(activeFontSize)) {
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, activeFontSize);
      } else {
        /*
         * Because of a bug in detecting active styles in mixed blocks, we need to turn on this font size everywhere,
         * and then off again. This will make sure all the currently set font sizes are removed.
         */
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, activeFontSize);
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, activeFontSize);
      }

      const newActiveFontSizes = getAllActiveStylesWithPrefix(newEditorState, FONT_SIZE_PREFIX);
      if (!newActiveFontSizes[activeFontSize]) {
        // In order to prevent a memory build up, remove this style if it is no longer in use.
        removeFromStyleMap(activeFontSize);
      }
    });

    addFontSizeToStyleMap(newStyleName, newFontSize);

    // Now add the new font size.
    newEditorState = RichUtils.toggleInlineStyle(newEditorState, newStyleName);
    onChangeTextState(newEditorState);
  };

  return (
    <div className="font-size-select">
      <Select
        theme="dark"
        onChange={onChange}
        options={FONT_SIZE_VALUES}
        value={currentValue}
      />
    </div>
  );
};

FontSizeSelect.propTypes = {
  editorState: PropTypes.object.isRequired,
  onChangeTextState: PropTypes.func.isRequired,

  beforeChange: PropTypes.func,
};

export default FontSizeSelect;
