import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import inject from '../../../../hoc/injectHoc';
import {homeRoute} from '../../../../routePaths';
import {getThumbnailUrlForContent} from '../../../../../utils/contentsHelper';
import {routeHistory} from '../../../../../utils/history';

import './editorNavItem.scss';

/**
 * The editor nav item
 *
 * @param {{}} param
 * @param {{}} param.activeContentStore
 * @param {{}} param.editorGetContentStore
 * @param {{}} param.gameCheckpointStore
 * @returns {{}}
 */
const EditorNavItem = ({
  activeContentStore,
  editorGetContentStore,
  gameCheckpointStore,
}) => {
  /**
   * The currently selected content
   *
   * @type {{}}
   */
  const activeContent = editorGetContentStore.getContentById(
    activeContentStore.contentId
  ).content;

  /**
   * Handles the back button click event.
   */
  const onBackClick = () => {
    gameCheckpointStore.clearCheckpoint();

    routeHistory.push(homeRoute);
  };

  let imageSrc = null;
  if (activeContent) {
    imageSrc = getThumbnailUrlForContent(activeContent);
  }

  return (
    <div id="editor-nav-item">
      <button
        className="fad fad-caret-left w24-navbar-control w24-transparent-button nav-back"
        type="button"
        onClick={onBackClick}
      />

      {(activeContent) && (
        <div
          className="w24-navbar-control nav-content"
        >
          {(imageSrc) && (
            <img
              className="content-image"
              src={imageSrc}
              alt="Editor Thumbnail"
            />
          )}

          <p className="content-name">{activeContent.title}</p>
        </div>
      )}
    </div>
  );
};

EditorNavItem.propTypes = {
  activeContentStore: MobxPropTypes.observableObject,
  editorGetContentStore: MobxPropTypes.observableObject,
  gameCheckpointStore: MobxPropTypes.observableObject,
};

EditorNavItem.wrappedComponent = {
  propTypes: {
    activeContentStore: MobxPropTypes.observableObject.isRequired,
    editorGetContentStore: MobxPropTypes.observableObject.isRequired,
    gameCheckpointStore: MobxPropTypes.observableObject.isRequired,
  }
};

export default inject(EditorNavItem)(
  observer(EditorNavItem)
);
