import lodash from 'lodash';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import Select from '../../../../common/select/Select';
import {actionUpdateComponent} from '../../../../../display/components/action/actionUpdateComponent';
import {feedTextComponent} from '../../../../../display/components/type/feedTextComponent';
import {FEED_TYPE_API, FEED_TYPE_CUSTOM} from '../../../../../stores/game/gameFeedStore';

/**
 * The FeedSourceControls component.
 */
export class FeedSourceControls extends React.Component {
  /**
   * The input source.
   *
   * @type {string}
   */
  @observable inputSource = '';

  /**
   * The input format.
   *
   * @type {string}
   */
  @observable inputFormat = '';

  /**
   * The input repeat count.
   *
   * @type {number}
   */
  @observable inputRepeatCount = 0;

  /**
   * Triggered when the component first mounts to the page.
   */
  @action componentDidMount() {
    const {
      /** @type ObservableMap */ entity,
    } = this.props;

    const feedText = entity.get('feedText');

    this.inputSource = feedText.source;
    this.inputFormat = feedText.format;
    this.inputRepeatCount = feedText.repeatCount;
  }

  /**
   * Triggered when the component has just updated.
   *
   * @param {{}} prevProps
   */
  @action componentDidUpdate(prevProps) {
    if (prevProps.entity && this.props.entity && prevProps.entity.get('id') === this.props.entity.get('id')) {
      return;
    }

    const feedText = this.props.entity.get('feedText');

    this.inputSource = feedText.source;
    this.inputFormat = feedText.format;
    this.inputRepeatCount = feedText.repeatCount;
  }

  /**
   * Updates the source in the feedText component.
   *
   * @param {{}} changes
   * @param {string=} changes.source
   */
  @action onChange(changes) {
    const {
      /** @type ObservableMap */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    const current = entity.get('feedText');
    const actionParams = {entityId: entity.get('id')};

    game.addAction(actionParams, actionUpdateComponent(
      feedTextComponent(changes, current)
    ));
  }

  /**
   * Updates an input when its value changes.
   *
   * @param {{}} changeEvent
   */
  @action inputUpdate = (changeEvent) => {
    const target = changeEvent.target;

    this[target.name] = target.value;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type ObservableMap */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    const {feedType, feedSummary} = game.feed;

    const feedText = entity.get('feedText');
    const options = lodash.sortBy(feedSummary.texts || []).map((value) => {
      return {label: value, value: value};
    });

    const showAdvanced = (feedType === FEED_TYPE_API || feedType === FEED_TYPE_CUSTOM);

    const showFormat = true;
    const showRepeatCount = Boolean(this.inputSource);

    return (
      <div className="feed-source-controls">
        {(showAdvanced) ? (
          <div className="advanced-source-controls">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="enter-source">Feed Source Path</label>
                  <input
                    className="source theme-dark"
                    id="enter-source"
                    name="inputSource"
                    value={this.inputSource}
                    onChange={this.inputUpdate}
                    onBlur={(blurEvent) => this.onChange({source: blurEvent.target.value})}
                  />
                </div>
              </div>
            </div>

            {(showFormat) && (
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="enter-format">Feed Item Format</label>
                    <input
                      className="source theme-dark"
                      id="enter-format"
                      name="inputFormat"
                      value={this.inputFormat}
                      onChange={this.inputUpdate}
                      onBlur={(blurEvent) => this.onChange({format: blurEvent.target.value})}
                    />
                  </div>
                </div>
              </div>
            )}

            {(showRepeatCount) && (
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="enter-format">Number of items to display</label>
                    <input
                      className="source theme-dark"
                      id="enter-format"
                      type="number"
                      name="inputRepeatCount"
                      min={0}
                      value={feedText.repeatCount || 0}
                      onChange={(changeEvent) => this.onChange({repeatCount: changeEvent.target.value})}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="regular-options">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="choose-source-label">Choose Feed Source</label>
                  <Select
                    theme="dark"
                    value={feedText.source}
                    onChange={(selectedValue) => this.onChange({source: selectedValue})}
                    options={options}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

FeedSourceControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(FeedSourceControls);
