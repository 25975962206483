import {observable, action, extendObservable, when} from 'mobx';

import apiCategoriesStore from '../apiCategoriesStore';
import {STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../../constants/asyncConstants';
import {apiStore, getCase} from '../../../utils/apiStore';
import serverApi from '../../../utils/serverApi';

/**
 * The CreateCategory store.
 */
class CreateCategoryStore {
  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * Error
   * @type {?Error}
   */
  @observable error = null;

  /**
   * Gets the fulfilled value of the store.
   * This is used in case().
   *
   * @returns {boolean}
   */
  getFulfilled() {
    return true;
  }

  /**
   * Gets the rejected value of the store.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    return this.error;
  }

  /**
   * Creates a new category for the given libraryId.
   *
   * @param {number} categoryName
   * @param {number} contentLibraryId
   * @param {{}=} extraProperties
   * @returns {Promise}
   */
  @action createCategory(categoryName, contentLibraryId, extraProperties) {
    this.state = STATE_PENDING;

    return serverApi.categoryCreate(
      categoryName,
      contentLibraryId,
      extraProperties
    ).then(
      action('categoryCreateSuccess', () => {
        if (contentLibraryId) {
          apiCategoriesStore.expireCacheForLibrary(contentLibraryId);
          apiCategoriesStore.fetchCategories({contentLibraryId});
        }

        this.error = null;
        this.state = STATE_FULFILLED;
      }),
      action('categoryCreateError', (error) => {
        this.error = error;
        this.state = STATE_REJECTED;
      })
    );
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(handlers) {
    const getFulfilled = () => {
      return this.getFulfilled();
    };
    const getRejected = () => {
      return this.getRejected();
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }

  /**
   * Gets a promise for this store.
   *
   * @returns {Promise}
   */
  getPromise() {
    const thisStore = this;

    return new Promise((resolve, reject) => {
      when(
        () => {
          return (thisStore.state === STATE_FULFILLED || thisStore.state === STATE_REJECTED);
        },
        () => {
          if (thisStore.state === STATE_REJECTED) {
            reject(this.getRejected());
            return;
          }

          resolve(this.getFulfilled());
        },
        {name: 'apiCreateCategoryStoreGetPromise'}
      );
    });
  }
}

export default new CreateCategoryStore();
