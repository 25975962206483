import React from 'react';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';

import {homeRoute} from '../../routePaths';
import inject from '../../hoc/injectHoc';
import ProductItem from './components/ProductItem';
import LoadingIcon from '../../common/loadingIcon/LoadingIcon';
import {STATE_FULFILLED} from '../../../constants/asyncConstants';
import {routeHistory} from '../../../utils/history';

import './productSelectionPage.scss';

/**
 * The ProductSelectionPage component.
 */
export class ProductSelectionPage extends React.Component {
  /**
   * Triggered after the component mounts.
   */
  componentDidMount() {
    const {activeContentStore} = this.props;
    activeContentStore.setContentId(null);

    this.autoSelectProduct();
  }

  /**
   * Triggered when part of the component's mobX state is about to change.
   */
  componentWillReact() {
    this.autoSelectProduct();
  }

  /**
   * Auto selects a product if it is the only one.
   */
  autoSelectProduct = () => {
    const {apiProductsStore} = this.props;

    if (apiProductsStore.state !== STATE_FULFILLED) {
      return;
    }

    const products = apiProductsStore.getFulfilled();

    if (!products || products.length !== 1) {
      return;
    }

    this.setProduct(products[0]);
  };

  /**
   * Sets the product to the selected product.
   *
   * @param {{}} product
   */
  setProduct = (product) => {
    const {sessionStore} = this.props;

    sessionStore.setProduct(product);
    routeHistory.push(homeRoute);
  };

  /**
   * Re-queries the products.
   */
  onRetryClick = () => {
    const {sessionStore, apiProductsStore} = this.props;
    const clientId = sessionStore.clientId;

    apiProductsStore.expireCacheForClient(clientId);
    apiProductsStore.fetchProducts(clientId);
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const apiProductsStore = this.props.apiProductsStore;

    return (
      <div id="product-selection-page" className="system-page">
        <h1 className="page-heading">Select a Product:</h1>
        <div className="page-items container">
          <div className="row">
            {apiProductsStore.case({
              pending: () => (<LoadingIcon />),
              rejected: () => (
                <div className="page-error">
                  <p className="w24-error">There was an error retrieving the products.</p>
                  <button
                    type="button"
                    onClick={this.onRetryClick}
                    className="btn btn-secondary btn-rounded item-button retry-btn"
                  >Retry</button>
                </div>
              ),
              fulfilled: (value) => (
                (!value || value.length === 0) ? (
                  <p className="w24-no-results">No products found.</p>
                ) : (
                  value.map((product) => (
                    <div className="col-sm-3" key={product.id}>
                      <ProductItem onClick={this.setProduct} product={product} />
                    </div>
                  ))
                )
              )
            })}
          </div>
        </div>
      </div>
    );
  }
}

ProductSelectionPage.propTypes = {
  activeContentStore: MobxPropTypes.observableObject,
  apiProductsStore: MobxPropTypes.observableObject,
  sessionStore: MobxPropTypes.observableObject,
};

ProductSelectionPage.wrappedComponent = {};
ProductSelectionPage.wrappedComponent.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
  apiProductsStore: MobxPropTypes.observableObject.isRequired,
  sessionStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ProductSelectionPage)(
  observer(ProductSelectionPage)
);
