import lodash from 'lodash';
import {runInAction} from 'mobx';

import {cropComponent} from '../components/common/cropComponent';
import {clearTransitionCache, updateEntity} from '../ecs/entityHelper';
import {mapToRotatedFrame} from '../../utils/mathHelper';

/**
 * The name of the system.
 * @const {string}
 */
export const CROPPING_SYSTEM = 'croppingSystem';

/**
 * The smallest the crop window can get in each dimension.
 * @const {number}
 */
export const MINIMUM_CROP_SIZE = 10;

/**
 * Gets a new instance of the cropping system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function croppingSystem(game) {
  /**
   * Called when the game loop updates.
   *
   * @param {Array.<{}>} actions
   */
  function systemRunActions(actions) {
    actions.forEach((actionEntity) => {
      // First check for required components.
      if (!actionEntity.has('actionCrop')) {
        return;
      }

      const entityId = actionEntity.get('action').entityId;
      const entity = game.getEntity(entityId);
      if (!entity) {
        return;
      }

      const {safeCrop, safePosition, safeSize} = checkCropBoundaries(actionEntity, entity);

      // Now update the entity.
      runInAction('croppingSystemUpdateEntity', () => {
        if (safeCrop) {
          if (!entity.has('crop')) {
            entity.set('crop', safeCrop);
          } else {
            updateEntity(entity, 'crop', safeCrop);
          }
        }

        if (safePosition) {
          if (!entity.has('position')) {
            entity.set('position', safePosition);
          } else {
            updateEntity(entity, 'position', safePosition);
          }
        }

        if (safeSize) {
          if (!entity.has('size')) {
            entity.set('size', safeSize);
          } else {
            updateEntity(entity, 'size', safeSize);
          }
        }

        clearTransitionCache(entity);
      });
    });
  }

  /**
   * Checks to see if the size or position changes takes the item over the boundary.
   *
   * @param {ObservableMap} actionEntity
   * @param {ObservableMap} entity
   * @returns {{safeCrop: ?{top: number, left: number, width: number, height: number}}}
   */
  function checkCropBoundaries(actionEntity, entity) {
    const actionCrop = actionEntity.get('actionCrop');

    const currentPosition = entity.get('position');
    const currentCrop = entity.get('crop') || {
      top: currentPosition.top,
      left: currentPosition.left
    };

    let rotatedCropDelta = lodash.defaultsDeep(actionCrop.cropDelta, {top: 0, left: 0, width: 0, height: 0});

    // See if we need to adjust the delta to the rotated reference frame
    // (except for resizing where it was already done in the resizeHOC).
    if (!actionCrop.isResize && currentPosition.rotate) {
      rotatedCropDelta = mapToRotatedFrame(currentPosition.rotate, rotatedCropDelta);
    }

    // The new cropping position and size values.
    const newCrop = ({
      top: currentCrop.top + rotatedCropDelta.top,
      left: currentCrop.left + rotatedCropDelta.left,
      width: Math.max(currentCrop.width + rotatedCropDelta.width, MINIMUM_CROP_SIZE),
      height: Math.max(currentCrop.height + rotatedCropDelta.height, MINIMUM_CROP_SIZE),
    });

    // Prevents the cropping window from going fully off the viewing window.
    const cropLimits = checkCroppingBoundaries(newCrop, newCrop, game.resolution);
    newCrop.top = cropLimits.top;
    newCrop.left = cropLimits.left;

    if (actionCrop.isResize) {
      if (rotatedCropDelta.width || rotatedCropDelta.height) {
        newCrop.left -= cropLimits.truncateWidth;
        newCrop.top -= cropLimits.truncateHeight;
        newCrop.width += cropLimits.truncateWidth;
        newCrop.height += cropLimits.truncateHeight;
      } else {
        newCrop.width -= cropLimits.truncateWidth;
        newCrop.height -= cropLimits.truncateHeight;
      }
    }

    return {
      safeCrop: cropComponent(newCrop.top, newCrop.left, newCrop.width, newCrop.height).crop,
    };
  }

  return {
    name: CROPPING_SYSTEM,
    runActions: systemRunActions
  };
}

/**
 * Checks the cropping boundaries if the position change.
 *
 * @param {{height: number, width: number}} size
 * @param {{left: number, top: number}} position
 * @param {{height: number, width: number}} gameResolution
 * @returns {{left: number, top: number, truncateHeight: number, truncateWidth: number}}
 */
function checkCroppingBoundaries(size, position, gameResolution) {
  const padding = 10;
  const limitTop = 0 - size.height + padding;
  const limitLeft = 0 - size.width + padding;
  const limitBottom = gameResolution.height - padding;
  const limitRight = gameResolution.width - padding;

  // Make sure the new position won't push the item fully off the display.
  let newTop = position.top;
  if (newTop < limitTop) {
    newTop = limitTop;
  } else if (newTop > limitBottom) {
    newTop = limitBottom;
  }

  let newLeft = position.left;
  if (newLeft < limitLeft) {
    newLeft = limitLeft;
  } else if (newLeft > limitRight) {
    newLeft = limitRight;
  }

  return {
    top: newTop,
    left: newLeft,
    truncateHeight: newTop - position.top,
    truncateWidth: newLeft - position.left,
  };
}
