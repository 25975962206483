import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import PlaylistAspectRatio from './components/playlistAspectRatio/PlaylistAspectRatio';
import EditTimelineControls from '../editTimeline/EditTimelineControls';

import './editPlaylistControls.scss';

/**
 * The EditPlaylistControls component.
 */
export class EditPlaylistControls extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {entity, game} = this.props;

    return (
      <div className="edit-viewport-controls">
        <div className="viewport-group">
          <h5 className="sidebar-title">Playlist</h5>

          <div className="group-header">
            <span className="group-header-label">Options</span>
          </div>

          <div className="group-controls">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="playlist-aspect-ratio-input">Aspect Ratio</label>
                  <PlaylistAspectRatio id="playlist-aspect-ratio-input" entity={entity} game={game} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <EditTimelineControls entity={entity} game={game} />
      </div>
    );
  }
}

EditPlaylistControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditPlaylistControls);
