import lodash from 'lodash';

import {STATE_STOPPED} from '../../../constants/displayItemConstants';

/**
 * The type name for an viewport showing an image.
 * @const {string}
 */
export const VIEWPORT_TYPE_IMAGE = 'image';

/**
 * The type name for an viewport showing a viewport.
 * @const {string}
 */
export const VIEWPORT_TYPE_VIDEO = 'video';

/**
 * The viewport component.
 * Viewports display other slide contents allowing a fractal nature to slides.
 *
 * @param {{}} viewportValues
 * @param {number=} viewportValues.contentId
 * @param {string=} viewportValues.type
 * @param {number=} viewportValues.fileId
 * @param {string=} viewportValues.fileType
 * @param {string=} viewportValues.fileAspectRatio
 * @param {boolean=} viewportValues.maintainAspectRatio
 * @param {number=} viewportValues.refreshDuration
 * @param {number=} viewportValues.playbackTime
 * @param {string=} viewportValues.playbackState
 * @param {{}=} importComponent
 * @returns {{viewport: {contentId: number, type: ?string, file: {}, playback: ?{}}}}
 */
export function viewportComponent(viewportValues, importComponent) {
  const safeImport = parseImportComponent(importComponent);
  const newValues = lodash.defaults(viewportValues || {}, safeImport);

  return {
    viewport: {
      contentId: newValues.contentId || null,
      type: (newValues.type === VIEWPORT_TYPE_VIDEO) ? VIEWPORT_TYPE_VIDEO : VIEWPORT_TYPE_IMAGE,
      maintainAspectRatio: Boolean(newValues.maintainAspectRatio),
      refreshDuration: Number(newValues.refreshDuration) || 0,
      file: {
        id: newValues.fileId || null,
        type: newValues.fileType || null,
        aspectRatio: newValues.fileAspectRatio || null,
      },
      playback: (newValues.type === VIEWPORT_TYPE_VIDEO) ? {
        time: newValues.playbackTime || 0,
        state: newValues.playbackState || STATE_STOPPED,
      } : null,
    },
  };
}

/**
 * Parses a viewport component into an importable component.
 *
 * @param {{}} importComponent
 * @returns {{}}
 */
function parseImportComponent(importComponent) {
  const safeImport = lodash.pickBy(importComponent || {});
  if (safeImport.file) {
    safeImport.fileId = safeImport.file.id;
    safeImport.fileType = safeImport.file.type;
    safeImport.fileAspectRatio = safeImport.file.aspectRatio;
  }
  if (safeImport.playback) {
    safeImport.playbackTime = safeImport.playback.time;
    safeImport.playbackState = safeImport.playback.state;
  }
  return safeImport;
}

/**
 * Gets the viewport component from the source.
 *
 * @param {{viewport: {contentId: number, type: ?string, file: {}, playback: ?{}}}} item
 * @returns {{viewport: {contentId: number, type: ?string, file: {}, playback: ?{}}}}
 */
export function getViewportFromSource(item) {
  if (!item.viewport) {
    return {};
  } else if (!item.viewport.file || !item.viewport.file.id) {
    throw new Error('Invalid content fileId for viewportComponent.');
  } else if (!item.viewport.contentId) {
    throw new Error('Invalid contentId for viewportComponent.');
  }

  const viewport = item.viewport;
  const file = viewport.file || {};

  return viewportComponent({
    contentId: viewport.contentId,
    type: viewport.type,
    fileId: file.id,
    fileType: file.type,
    fileAspectRatio: file.aspectRatio,
    maintainAspectRatio: viewport.maintainAspectRatio,
    refreshDuration: viewport.refreshDuration
  });
}

/**
 * Parses an entity back into source JSON.
 *
 * @param {{}} entity
 * @returns {{}}
 */
export function getViewportForSource(entity) {
  if (!entity.has('viewport')) {
    return {};
  }

  const viewport = entity.get('viewport');
  const file = viewport.file || {};

  return {
    viewport: {
      contentId: viewport.contentId || null,
      type: viewport.type || VIEWPORT_TYPE_IMAGE,
      file: {
        id: file.id || null,
        type: file.type || null,
        aspectRatio: file.aspectRatio || null,
      },
      maintainAspectRatio: viewport.maintainAspectRatio || false,
      refreshDuration: viewport.refreshDuration || 0,
    },
  };
}
