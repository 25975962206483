import classNames from 'classnames';
import lodash from 'lodash';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';
import inject from '../../../../hoc/injectHoc';

import './libraryDropdown.scss';

/**
 * The LibraryDropdown component.
 */
export class LibraryDropdown extends React.Component {
  /**
   * Triggered when the component was just mounted onto the page.
   */
  componentDidMount() {
    this.setDefaultActiveLibrary();
  }

  /**
   * Triggered when the component is about to react to mobx changes.
   */
  componentWillReact() {
    this.setDefaultActiveLibrary();
  }

  /**
   * Sets the default active library if no library is already set.
   */
  setDefaultActiveLibrary = () => {
    const {activeContentStore, apiContentLibrariesStore, apiLoginStore, libraryType} = this.props;

    const activeContentLibrary = activeContentStore.libraries[libraryType];
    const storeKey = activeContentLibrary.storeKey;
    const currentUser = apiLoginStore.getFulfilled();

    apiContentLibrariesStore.case(storeKey, {
      fulfilled: (contentLibraries) => {
        if (activeContentLibrary.id || !contentLibraries || !contentLibraries.length) {
          return;
        }

        // Set the content library to the first writeable library.
        const firstWriteable = lodash.find(contentLibraries, (library) => {
          return (library.writeAccess && (currentUser.canEditGlobals || !library.isGlobal));
        });
        this.selectContentLibrary(firstWriteable || contentLibraries[0]);
      }
    });
  };

  /**
   * Currently selected ContentLibrary
   *
   * @param {?{}} contentLibrary
   */
  selectContentLibrary = (contentLibrary) => {
    const {activeContentStore, libraryType, onLibrarySelected} = this.props;

    const libraryId = (contentLibrary) ? contentLibrary.id : null;

    activeContentStore.setCategoryId(libraryType, null);
    activeContentStore.setLibraryId(libraryType, libraryId);

    if (onLibrarySelected) {
      onLibrarySelected(libraryId);
    }
  };

  /**
   * The dynamic classes for content library dropdown items.
   *
   * @param {number} libraryId
   * @returns {?{}}
   */
  contentLibraryItemClasses = (libraryId) => {
    const {activeContentStore, libraryType} = this.props;

    const activeContentLibrary = activeContentStore.libraries[libraryType];

    return {
      active: String(activeContentLibrary.id) === String(libraryId)
    };
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      activeContentStore,
      apiContentLibrariesStore,
      libraryType,
    } = this.props;

    const activeContentLibrary = activeContentStore.libraries[libraryType];
    const storeKey = activeContentLibrary.storeKey;

    const library = apiContentLibrariesStore.getLibrary(
      storeKey,
      activeContentLibrary.id,
    );
    const libraryName = library ? library.libraryName : '';

    return (
      <div className="library-dropdown dropdown">
        <button
          className="btn dropdown-toggle library-dropdown-open"
          type="button"
          id="dropdown-menu-button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {(library && libraryName) ? libraryName : 'Select Content Library'}
        </button>

        <div className="dropdown-menu" aria-labelledby="dropdown-menu-button">
          {storeKey && apiContentLibrariesStore.case(storeKey, {
            pending: () => (<LoadingIcon size="sm" />),
            rejected: () => (
              <p className="w24-error small-text">There was an error loading the content directories.</p>
            ),
            fulfilled: (contentLibraries) => {
              if (!contentLibraries) {
                return null;
              }

              if (contentLibraries.length === 0) {
                return (
                  <p className="w24-no-results small-text">No libraries found.</p>
                );
              }

              return contentLibraries.map((contentLibrary) => (
                <button
                  type="button"
                  key={contentLibrary.id}
                  className={classNames(
                    'dropdown-item item-radio',
                    this.contentLibraryItemClasses(contentLibrary.id)
                  )}
                  onClick={() => this.selectContentLibrary(contentLibrary)}
                >
                  {contentLibrary.libraryName}
                </button>
              ));
            }
          })}
        </div>
      </div>
    );
  }
}

LibraryDropdown.propTypes = {
  activeContentStore: MobxPropTypes.observableObject.isRequired,
  libraryType: PropTypes.number.isRequired,

  apiContentLibrariesStore: MobxPropTypes.observableObject,
  apiLoginStore: MobxPropTypes.observableObject,
  onLibrarySelected: PropTypes.func,
};

LibraryDropdown.wrappedComponent = {};
LibraryDropdown.wrappedComponent.propTypes = {
  apiContentLibrariesStore: MobxPropTypes.observableObject.isRequired,
  apiLoginStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(LibraryDropdown)(
  observer(LibraryDropdown),
);
