import lodash from 'lodash';
import {action, extendObservable, observable} from 'mobx';

import apiContentsStore from './apiContentsStore';
import apiContentSearchStore from './apiContentSearchStore';
import SessionStore from '../active/sessionStore';
import {STATE_PRE, STATE_PENDING, STATE_FULFILLED, STATE_REJECTED} from '../../constants/asyncConstants';
import {CAN_NOT_RENDER, QUEUED, READY} from '../../constants/contentConstants';
import {apiStore, getCase} from '../../utils/apiStore';
import serverApi from '../../utils/serverApi';

/**
 * The RenderSource store.
 */
class RenderSourceStore {
  /**
   * The error from the render.
   *
   * @type {?Error}
   */
  @observable error = null;

  /**
   * @constructor
   */
  constructor() {
    extendObservable(this, apiStore);
  }

  /**
   * Clears the store.
   */
  @action clearAll() {
    this.state = STATE_PRE;
  }

  /**
   * Gets the fulfilled value.
   * This is used in case().
   *
   * @returns {boolean}
   */
  getFulfilled() {
    return true;
  }

  /**
   * Gets the rejected value.
   * This is used in case().
   *
   * @returns {?Error}
   */
  getRejected() {
    return this.error;
  }

  /**
   * Saves the source json to the content file.
   *
   * @param {number} contentFileId
   * @param {number} feedId
   * @param {{}} source
   * @returns {Promise}
   */
  saveSource(contentFileId, feedId, source) {
    return serverApi.contentFilesPatch({
      id: contentFileId,
      feedId,
      create24ProjectJson: JSON.stringify(source),
    });
  }

  /**
   * Gets whether or not the content file for the given content id is processing.
   * We need a non-cached version to make sure it isn't already processing.
   *
   * @param {number} contentId
   * @returns {Promise.<boolean>}
   */
  checkIsContentFileProcessing(contentId) {
    return serverApi.contentFilesByContentId(contentId).then((foundContentFiles) => {
      if (!foundContentFiles || !foundContentFiles.length) {
        return false;
      }
      return lodash.includes(CAN_NOT_RENDER, foundContentFiles[0].contentFileStateId);
    });
  }

  /**
   * Puts the content in a processing state.
   *
   * @param {number} contentFileId
   * @param {number} newState
   * @returns {Promise}
   */
  updateContentState(contentFileId, newState) {
    const safeState = newState || QUEUED;

    return serverApi.contentFilesPatch({
      id: contentFileId,
      contentFileStateId: safeState,
    });
  }

  /**
   * Fetches projects from the server.
   *
   * @param {{}} source
   * @param {{}} content
   * @param {GameFeedStore} gameFeed
   * @returns {Promise}
   */
  @action renderSourceToVideo(source, content, gameFeed) {
    const contentFile = content.contentFiles[0];

    this.state = STATE_PENDING;

    const feedData = gameFeed.getFeedDataForRender();

    return this.saveSource(
      contentFile.id,
      gameFeed.feedId,
      source
    ).then(() => {
      return this.checkIsContentFileProcessing(content.id);
    }).then((isProcessing) => {
      if (isProcessing) {
        const processingError = new Error('The content is already in a processing state.');
        processingError.isProcessing = true;
        throw processingError;
      }

      // Before we begin, we need to make the content file processing.
      return this.updateContentState(contentFile.id, QUEUED);
    }).then(() => {
      const queueData = {
        clientId: SessionStore.clientId,
        userId: SessionStore.userId,
        productId: SessionStore.productId,
        contentId: content.id,
        contentFileId: contentFile.id,
        contentTypeId: content.contentTypeId,
        targetDirectory: contentFile.directory,
        resizeMethod: 'CENTER',
      };
      const title = content.title || 'no title';

      return serverApi.sourceRenderToVideo(source, title, queueData, feedData);
    }).then(action('renderSourceToVideoSuccess', () => {
      // Refresh the content records to reflect the changes we have made.
      apiContentsStore.refreshCurrentContents({
        categoryId: content.categoryId,
        libraryId: content.libraryId,
      });
      apiContentSearchStore.expireCacheForLibrary(content.libraryId);

      this.state = STATE_FULFILLED;
    }, (renderError) => {
      // On error, remove the processing state from the content.
      return this.updateContentState(contentFile.id, READY).then(() => {
        throw renderError;
      }, () => {
        throw renderError;
      });
    })).catch(action('renderSourceToVideoError', (error) => {
      this.error = error;
      this.state = STATE_REJECTED;
    }));
  }

  /**
   * Runs handlers based on changes in the state.
   *
   * @param {number} projectId
   * @param {{pre: function, pending: function, fulfilled: function, rejected: function}} handlers
   * @returns {{}}
   */
  case(projectId, handlers) {
    const getFulfilled = () => {
      return this.getFulfilled(projectId);
    };
    const getRejected = () => {
      return this.getRejected(projectId);
    };

    return getCase(this.state, getFulfilled, getRejected, handlers);
  }
}

export default new RenderSourceStore();
